import { PropType } from 'vue'
import { AddOrEditTransitionsOfCareForm, ManageTocDisplay } from '../types'

export const addOrEditTransitionsOfCareProps = {
  patientId: {
    type: String,
    required: true,
  },
  creating: {
    type: Boolean,
    required: true,
  },
  initialData: {
    type: Object as PropType<Partial<AddOrEditTransitionsOfCareForm>>,
    required: true,
  },
  editingToc: {
    type: Object as PropType<ManageTocDisplay | null>,
    required: false,
  },
} as const
