import { enumValueToKey } from '@/legacy/libs/enum'
import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------
export type OncologyTreatment = {
  oncologyTreatmentId: string
  entityId: string //aka patientId
  oncologyTreatmentRefId: string
  lastClaimDate: string
  firstClaimDate: string
} & Timestamps

export type OncologyTreatmentReference = {
  oncologyTreatmentRefId: string
  codeType: string
  code: string
  description: string
} & Timestamps

// ENUMS --------------------
export enum CodeType {
  hcpcs = 'hcpcs',
  ndc = 'ndc',
}

export enum DrugType {
  injectable = 'Injectable',
  oral = 'Oral',
}

// CONSTANTS --------------------
export const codeTypeToDrugType = {
  hcpcs: enumValueToKey(DrugType, DrugType.injectable),
  ndc: enumValueToKey(DrugType, DrugType.oral),
}
