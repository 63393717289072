import router from '@/legacy/router'
import { useTextingStore } from '@/legacy/store/modules/textingV2'

/**
 * Function to handle rerouting to member's texting inbox view in texting thymeline
 * by pushing URL query params.
 * If we've never texted a phone number before, use phone number ID to help identify
 * selected conversation in texting inbox view.
 * @param phoneNumberId
 * @param patientId
 * @param subtaskId
 */
export async function handleRerouteToTextingInbox(
  phoneNumberId: string,
  patientId: string | undefined,
  subtaskId?: string | undefined
) {
  const results = await useTextingStore().getSmsCommByPhoneNumberIds({
    phoneNumberIds: [phoneNumberId],
    entityIds: patientId ? [patientId] : [],
  })
  if (results?.length) {
    await router.push({
      query: {
        commId: results[0].communicationId,
        type: 'sms',
        ...(subtaskId ? { subtaskId } : {}),
      },
    })
  } else {
    await router.push({
      query: {
        phoneNumberId: phoneNumberId,
        type: 'sms',
        ...(subtaskId ? { subtaskId } : {}),
      },
    })
  }
}
