<template>
  <div class="overflow-visible px-5 card-style w-full mb-5">
    <div class="flex flex-row justify-between w-full">
      <h3 class="font-medium py-2 print-h3">Goals</h3>
      <div class="flex flex-row">
        <div v-tooltip.left="'Manage goals'">
          <TMSecondaryButton
            icon="listBullet"
            class="w-9 h-10 p-9 ml-3 p-4"
            @click="toggleManageGoalsModel"
          />
        </div>
        <div>
          <ManagePathwaysModal
            v-tooltip.left="'Manage playbooks'"
            :pathways="pathwayArray"
          />
        </div>
      </div>
    </div>
    <PatientGoal
      v-for="goal in sortedGoals"
      :key="goal.goalId"
      :goal="goal"
      :goal-pathways="getGoalPathways(goal)"
      :goal-tasks="getGoalTasks(goal)"
      :completed-tasks="getCompletedGoalTasks(goal)"
    />
    <OngoingSupport :pathways="ongoingPathways" />
    <div class="mt-5 ml-4">
      {{ formattedCarePlanUpdateString }}
    </div>
  </div>
  <ManageGoalsModal
    :goals="goalsArray"
    :all-patient-pathways="goalPathways"
    :show-manage-goals="showManageGoalsModal"
    @close="toggleManageGoalsModel"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import ManageGoalsModal from '@/legacy/components/patient/carePlans/modals/ManageGoalsModal/ManageGoalsModal.vue'
import OngoingSupport from '@/legacy/components/patient/carePlans/PatientCarePlan/PatientGoals/OngoingSupport/OngoingSupport.vue'
import PatientGoal from '@/legacy/components/patient/carePlans/PatientCarePlan/PatientGoals/PatientGoal/PatientGoal.vue'
import ManagePathwaysModal from '@/legacy/components/patient/pathways/ManagePathwaysModal.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TMSecondaryButton,
    ManagePathwaysModal,
    PatientGoal,
    ManageGoalsModal,
    OngoingSupport,
  },
  setup,
})
</script>
