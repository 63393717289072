import apiStore from '@/legacy/store/modules/apiBuilder'
import { Surgery } from '@/pages/PatientProfile/CarePlans/shared/types'

export const usePreviousCancerSurgeriesApi = apiStore<Surgery>(
  'previousCancerSurgeriesApi',
  '/api/care_plan/patient_surgeries',
  {
    transformData: (d: { data: Surgery[] }) => d,
  }
)
