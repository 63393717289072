<template>
  <div data-cy="appointments-card">
    <THeading :size="HeadingSize.H3">
      Oncology Appointments

      <template #actions>
        <LegacyTCheckbox
          v-model:checked="showPastAppointments"
          label="Show past appointments"
          data-cy="past-appointments-checkbox"
        />
      </template>
    </THeading>

    <div v-if="visits && visits.length">
      <div
        v-for="visit in visits"
        :key="visit.visitId"
        class="flex flex-column"
        @click="
          isShowing(visit.visitId) ? hide(visit.visitId) : show(visit.visitId)
        "
      >
        <!-- show/hide icon -->
        <TIcon
          :icon="isShowing(visit.visitId) ? 'chevronDown' : 'chevronRight'"
          class="inline"
        />

        <div>
          <!-- visit header -->
          <div class="font-bold">
            {{ printDate(visit.visitDatetime) }} - {{ visit.practiceName }} -
            {{ visit.locationName }}
          </div>

          <!-- expandable content -->
          <div v-if="isShowing(visit.visitId)">
            <div>{{ visit.providerName }}</div>
            <div class="text-sm">{{ visit.orders }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>No appointments.</div>

    <div class="text-sm mt-4">All times local to practice.</div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import LegacyTCheckbox from '@/legacy/nashville/LegacyTCheckbox.vue'
import THeading from '@/legacy/nashville/THeading.vue'
import setup from './controller'

export default defineComponent({
  components: { LegacyTCheckbox, THeading, TIcon },

  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup,
})
</script>
