<template>
  <TOTableButton
    :label="filter.modalOptions?.label"
    :badge="badge"
    size="md"
    expanded
    class="p-3"
    icon="chevronRight"
    :icon-pos="IconPosition.RIGHT"
    justify-between
  />
</template>

<script lang="ts">
import { IconPosition } from '@thyme/nashville/src/types/icons'
import { Filter, FilterType } from '@thyme/nashville/src/types/tables'
import { computed, defineComponent, PropType } from 'vue'
import TOTableButton from '@/legacy/nashville/table/TOTableButton.vue'
export default defineComponent({
  components: {
    TOTableButton,
  },
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const badge = computed(() => {
      if (props.filter.modalOptions?.type === FilterType.Multiselect) {
        if (props.data?.length) {
          return props.data.length
        }
        return ''
      }
      return props.data?.length ? '1' : ''
    })
    return { badge, IconPosition }
  },
})
</script>
