import { SymptomIdAnswer } from '@/legacy/types/pathways/symptomIdHistory'

/**
 * Setup the SymptomHistory component
 * @param props
 */
export default function (props: any) {
  // The minimum value (inclusive) that an integer answer to symptom id question can take on
  const INTEGER_ANSWER_MIN_VALUE = 0
  // The maximum value (inclusive) that an integer answer to symptom id question can take on
  const INTEGER_ANSWER_MAX_VALUE = 4
  /**
   * Convert a datetime string to a short date string
   * suitable for display on the chart's x-axis
   * e.g. "2021-09-01T00:00:00.000Z" -> "Sep 1, 21"
   * @param dateTimeString
   */
  function toShortDate(dateTimeString: string): string {
    const parsedDate = new Date(dateTimeString)
    return parsedDate.toLocaleString('en-US', {
      year: '2-digit',
      month: 'short',
      day: 'numeric',
    })
  }

  /**
   * Some older form answers have scores of 100 as a placeholder for missing data,
   * to handle this we drop any scores outside the range [0, 10]
   * @param answersHistory
   */
  function dropIntegerScoresOutsideRange(
    answersHistory: SymptomIdAnswer[]
  ): SymptomIdAnswer[] {
    return answersHistory?.filter((answer) => {
      if (typeof answer.answerData === 'boolean') {
        return true
      } else {
        return (
          answer.answerData >= INTEGER_ANSWER_MIN_VALUE &&
          answer.answerData <= INTEGER_ANSWER_MAX_VALUE
        )
      }
    })
  }

  /**
   * Perform several transformations on the raw API data to prepare it for the chart
   * - Drop integer scores outside the range 0-10
   * - Transform datetimes to short date strings
   * @param answersHistory
   */
  function transformAnswersHistory(
    answersHistory: SymptomIdAnswer[]
  ): SymptomIdAnswer[] {
    const inRangeAnswersHistory = dropIntegerScoresOutsideRange(answersHistory)
    return inRangeAnswersHistory?.map((answer) => {
      return {
        updatedAt: toShortDate(answer.updatedAt),
        answerData: answer.answerData,
      }
    })
  }

  const chartData = {
    datasets: [
      {
        label: 'Score',
        data: transformAnswersHistory(props.symptomAnswers),
      },
    ],
  }

  // IMPROVEME MT-3116 use chart options to make the charts look nicer
  const chartOptions = {
    parsing: {
      xAxisKey: 'updatedAt',
      yAxisKey: 'answerData',
    },
    scales: {
      // Y axis should be 0, 1, ..., 10
      y: {
        min: INTEGER_ANSWER_MIN_VALUE,
        max: INTEGER_ANSWER_MAX_VALUE,
        ticks: {
          step: 1,
        },
      },
    },
  }

  return {
    chartData,
    chartOptions,
  }
}
