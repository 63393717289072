<template>
  <div
    class="text-messages-container bg-nash-neutral000 overflow-scroll px-5 overflow-x-hidden"
  >
    <div v-if="neverTextedMember" class="sms-container-message my-5">
      You are starting a new conversation. Type your first message below.
    </div>
    <div v-if="noUnknownTexts" class="sms-container-message my-20">
      No unknown texts
    </div>
    <div v-if="hasErrors" class="py-5 w-full space-y-2">
      <TMessage
        v-if="conversationsClientError"
        :expanded="false"
        :severity="MessageSeverity.ERROR"
        name="active-conversation-shared-member"
        :label="(conversationsClientError as Error).message"
      />
      <TMessage
        v-if="conversationsError"
        :expanded="false"
        :severity="MessageSeverity.ERROR"
        name="active-conversation-shared-member"
        :label="(conversationsError as Error).message ?? (conversationsError as string)"
      />
      <TMessage
        v-if="messagesError"
        :expanded="false"
        :severity="MessageSeverity.ERROR"
        name="active-conversation-shared-member"
        :label="(messagesError as Error).message ?? (messagesError as string)"
      />
    </div>

    <TSpinner
      v-if="!allMessageDataLoaded"
      name="all-sms"
      :partial-page="true"
    />
    <div v-if="allMessageDataLoaded">
      <div v-if="showLoadMore" class="flex justify-center my-1">
        <TMTertiaryButton label="Load more" @click="loadPrevTenSmsComms" />
      </div>
      <div
        v-for="messagesAndDate in messagesByDateArray"
        :key="messagesAndDate[0]"
        class="flex flex-col grow"
      >
        <div class="flex justify-center p-2 text-sm text-nash-neutral900">
          {{ messagesAndDate[0] }}
        </div>
        <div
          v-for="message in messagesAndDate[1]"
          :key="`${message.sid}${message.dateUpdated?.toLocaleTimeString()}`"
          :class="{
            'my-message': !isPatient(message.author, currCommPhoneNumberStr),
            'patient-message': isPatient(
              message.author,
              currCommPhoneNumberStr
            ),
            'text-message': true,
            'auto-or-bulk-text': isAutoOrBulkText(message),
          }"
        >
          <div class="block text-left">
            <div class="metadata">
              <div class="leading-none pb-1">
                <div
                  class="inline-flex items-center font-bold text-nash-neutral800"
                >
                  <div
                    v-if="
                      isPatient(message.author, currCommPhoneNumberStr) &&
                      isUnknownTextsPage
                    "
                  >
                    {{ message.author ? formatPhone(message.author) : '' }}
                  </div>
                  <div v-else>
                    {{
                      isPatient(message.author, currCommPhoneNumberStr)
                        ? getTexteeNameOrFormatNumber(
                            message.author,
                            activeCommunication?.smsThread.people
                          )
                        : getStaffNameFromId(message.author ?? '') ??
                          'Thyme Care Staff'
                    }}
                  </div>
                  <div class="bullet px-1 font-normal"></div>
                  <div class="font-normal text-nash-neutral700">
                    {{
                      message.dateUpdated?.toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      }) ?? ''
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="message.attachedMedia?.length"
              class="bubble bg-nash-neutral000 hover:bg-gray-300 cursor-pointer"
            >
              <div v-for="media in message.attachedMedia" :key="media.sid">
                <img
                  v-if="isPhoto(media.contentType)"
                  :src="mediaLinks[media.sid]"
                  @click="download(media)"
                />
                <div v-else class="flex" @click="download(media)">
                  <TIcon icon="download" class="mr-2" />
                  {{ media.filename }}
                </div>
              </div>
            </div>
            <div
              v-if="!message.attachedMedia || message.body"
              class="bubble bg-nash-neutral000 break-words"
            >
              {{ message.body }}
            </div>
            <div
              v-if="messageDeliveryStatusMapBySid[message.sid]?.length"
              class="message-status"
            >
              <span
                :class="messageDeliveryStatusMapBySid[message.sid][0].status"
              >
                {{ messageDeliveryStatusMapBySid[message.sid][0].status }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'

import TMessage from '@/legacy/nashville/message/TMessage.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'

import setup from './controller'

export default defineComponent({
  components: { TIcon, TMTertiaryButton, TSpinner, TMessage },
  setup,
})
</script>

<style scoped lang="scss">
.text-messages-container {
  @apply flex flex-col shrink grow h-full;
}

.bubble {
  @apply p-2.5 mx-0 mt-2.5 mb-0 border border-solid border-nash-neutral300 rounded-lg;
  max-width: 40%;
}

.metadata {
  @apply leading-none relative pt-5 pb-0 pl-0 pr-0 mb-0 flex flex-col;
}

.my-message .metadata {
  @apply items-end;
}

.my-message .bubble {
  @apply float-right border-2 border-solid border-nash-teal700 text-nash-neutral000 bg-nash-teal700 rounded-tr-none;
}

.my-message,
.my-message .message-status {
  @apply block float-right;
}

.patient-message,
.patient-message .message-status {
  @apply block float-left;
}

.patient-message .bubble {
  @apply float-left border border-solid border-nash-neutral300 bg-nash-neutral200 text-nash-neutral800 rounded-tl-none;
}

.my-message.auto-or-bulk-text .bubble {
  @apply float-right border-2 border-solid border-nash-midnightBlue700 text-nash-neutral000 bg-nash-midnightBlue700 rounded-tr-none;
}

.message-status {
  @apply text-sm pt-1;
  clear: both;
  text-transform: capitalize;
}

.message-status .sent,
.message-status .delivered,
.message-status .read {
  @apply text-nash-neutral700;
}

.message-status .failed,
.message-status .undelivered {
  @apply text-nash-brick700;
}

::-webkit-scrollbar {
  @apply w-2.5;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-200;
}

.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}

.error-container {
  @apply border-0 rounded-lg py-2.5 px-3 mb-5 bg-nash-tigerlily100 text-nash-tigerlily800 space-x-2 font-medium;
}

.sms-container-message {
  @apply flex justify-center text-sm text-nash-neutral700;
}
</style>
