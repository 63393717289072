<template>
  <TCollapse
    v-if="filteredPhoneNumberMaps"
    :disable-justify-between="true"
    class="w-full px-1 p-message p-message-warn"
  >
    <template #trigger>
      <div class="w-full">
        <TMessage
          :expanded="true"
          :severity="MessageSeverity.WARN"
          name="edit-other-contacts-shared-member"
          :label="triggerString"
        />
      </div>
    </template>
    <template #content>
      <div class="space-y-2 pt-2 pb-4">
        <div
          v-for="(
            sharedNumberDetails, phoneNumStr, i
          ) in filteredPhoneNumberMaps"
          :key="i"
          class="warning-content px-4 font-normal"
        >
          {{ `${i + 1}. ${renderLabel(sharedNumberDetails, phoneNumStr)}` }}
        </div>
      </div>
    </template>
  </TCollapse>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'
import TCollapse from '@/legacy/nashville/TCollapse.vue'
import { PhoneNumberNameMap } from '@/legacy/types/entities/phoneNumbers'
import setup from './controller'

export default defineComponent({
  components: { TCollapse, TMessage },
  props: {
    phoneNumberMaps: {
      type: Object as PropType<PhoneNumberNameMap>,
      required: true,
    },
    isContactModal: {
      type: Boolean,
      default: false,
    },
  },
  setup,
})
</script>
