import {
  apiRequest as _apiRequest,
  getApiUrl,
} from '@thyme/libs/src/api/apiRequest'
import { Method, RequestOptions } from '@thyme/libs/src/api/types/api'
import { getActivePinia } from 'pinia'
import { getAccessToken } from '@/auth'
import { DEPLOY_VERSION } from '@/version'

/**
 *
 */
async function getAuthHeaderValue(): Promise<string> {
  return `Bearer ${await getAccessToken()}`
}

/**
 * Generates a valid API URL given an endpoint and request parameters.
 * @param endpoint
 * @param params
 */
export function apiUrl<P extends object>(endpoint: string, params?: P) {
  return getApiUrl(getBaseUrl(), endpoint, params, {
    arrayFormat: 'repeat',
  })
}

/**
 *
 */
function getBaseUrl(): string {
  const configVals = getActivePinia()?.state?.value?.config?.configVals
  if (!configVals) {
    throw new Error('Cannot generate API URL before config is loaded')
  }

  return configVals.apiUrl
}

/**
 *
 * @param method
 * @param endpoint
 * @param options
 */
export async function apiRequest<R>(
  method: Method,
  endpoint: string,
  options: RequestOptions = {}
): Promise<R | null> {
  let headers: { [key: string]: string } = {}
  headers['Authorization'] = await getAuthHeaderValue()

  // To avoid a circular import, we do not use useProfileStore here
  const userId = getActivePinia()?.state.value?.profile?.selfEntity?.entityId

  if (userId) {
    headers['Thymebox-User-Id'] = userId
  }

  headers['X-Thymebox-Version'] = DEPLOY_VERSION

  headers = {
    ...headers,
    ...options.headers,
  }

  return await _apiRequest(
    method,
    endpoint,
    {
      ...options,
      headers,
      paramSerializationOptions: {
        arrayFormat: 'repeat',
        ...options.paramSerializationOptions,
      },
    },
    getBaseUrl()
  )
}

export const Timeout = (time: number) => {
  const controller = new AbortController()
  const ms = 1000
  const timeout_ms = ms * time
  setTimeout(() => controller.abort(), timeout_ms)
  return controller
}
