<template>
  <section class="p-4">
    <div id="debugPage" class="h-full flex">
      <div class="is-fullwidth mx-auto my-0 space-y-4">
        <DebugOurColors />
        <DebugHeaders />
        <DebugTButtons />
        <div>
          <h2>TIcon Debug</h2>
          <DebugTIcons label="Icon Size Extra Small" size="xs" />
          <DebugTIcons label="Icon Size Small" size="sm" />
          <DebugTIcons label="Icon Size Medium (Default)" size="md" />
          <DebugTIcons label="Icon Size Large" size="lg" />
          <DebugTIcons label="Icon Size Extra Large" size="xl" />
          <DebugTIcons
            label="Fill Color (not all icons can be filled)"
            fill-type="warning"
          />
        </div>
        <DebugTCheckbox />
        <DebugTCollapse />
        <DebugTDatePicker />
        <!-- DEPRECATED COMPONENT <DebugTHeading /> -->
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DebugHeaders from '@/legacy/nashville/debug/DebugHeaders.vue'
import DebugOurColors from '@/legacy/nashville/debug/DebugOurColors.vue'
import DebugTButtons from '@/legacy/nashville/debug/DebugTButtons.vue'
import DebugTCheckbox from '@/legacy/nashville/debug/DebugTCheckbox.vue'
import DebugTCollapse from '@/legacy/nashville/debug/DebugTCollapse.vue'
import DebugTDatePicker from '@/legacy/nashville/debug/DebugTDatePicker.vue'
import DebugTIcons from '@/legacy/nashville/debug/DebugTIcons.vue'

export default defineComponent({
  components: {
    DebugOurColors,
    DebugHeaders,
    DebugTButtons,
    DebugTIcons,
    DebugTCheckbox,
    DebugTCollapse,
    DebugTDatePicker,
  },
})
</script>
