import { omit, pick } from 'lodash'
import { storeToRefs } from 'pinia'
import { useInsurancesApi } from '@/legacy/store/modules/insurances'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { NotificationType } from '@/legacy/types/notifications'
import {
  AddInsuranceForm,
  Insurance,
  updateInsuranceFields,
} from '@/legacy/types/patients/insurances'
import { EditInsuranceModel } from '../types'

/**
 * convert EditInsuranceModel to Insurance
 * @param insurance
 */
export function convertToApiInsurance(
  insurance: EditInsuranceModel
): Insurance {
  return omit(insurance, 'payerName')
}

/**
 * POST new insurance entry
 * @param insuranceChange
 */
export async function createInsurance(insuranceChange: AddInsuranceForm) {
  const { entity } = storeToRefs(usePatientStore())

  if (!entity.value) {
    useNotificationStore().setNotification({
      message: 'Failed to create insurance. No entity selected.',
      type: NotificationType.DANGER,
    })
    return
  }

  // Convert insuranceform to insurance partial
  const insurance: Partial<Insurance> = {
    ...pick(insuranceChange, updateInsuranceFields),
    planType: '',
    isPrimary: insuranceChange.type === 'primary',
    isActive: insuranceChange.status === 'active',
    planName: '',
    isManuallyAdded: true,
    entityId: entity.value.entityId,
  }
  let upserted
  try {
    upserted = await useInsurancesApi().create({
      body: insurance,
      metaOptions: {
        bubbleErrorThrow: true,
      },
    })
    useNotificationStore().setNotification({
      message: 'Successfully created insurance.',
      type: NotificationType.SUCCESS,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to create insurance.',
      type: NotificationType.DANGER,
    })
    console.error(err)
  }
  return upserted
}

/**
 * PATCH the existing insurance entry
 * @param existingInsurance
 * @param insuranceChange
 */
export async function updateInsurance(
  existingInsurance: EditInsuranceModel,
  insuranceChange: AddInsuranceForm
) {
  // Convert insuranceform to insurance partial
  const insurance = {
    ...convertToApiInsurance(existingInsurance),
    ...pick(insuranceChange, updateInsuranceFields),
    isPrimary: insuranceChange.type === 'primary',
    isActive: insuranceChange.status === 'active',
  }

  let upserted
  try {
    upserted = await useInsurancesApi().partialUpdate({
      body: insurance,
      ids: [insurance.insuranceId],
      metaOptions: {
        bubbleErrorThrow: true,
      },
    })
    useNotificationStore().setNotification({
      message: 'Successfully updated insurance.',
      type: NotificationType.SUCCESS,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update insurance.',
      type: NotificationType.DANGER,
    })
    console.error(err)
  }
  return upserted
}
