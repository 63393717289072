import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ExtractPropTypes } from 'vue'
import { editOptionsArray } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientTreatment/shared/types'
import { deleteToc } from './queries'
import { deleteTocProps } from './types'

type PropsType = ExtractPropTypes<typeof deleteTocProps>

/**
 * Set up the DeleteRadiation component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * close delete modal
   */
  function close() {
    context.emit('close')
  }

  /**
   * delete radiation
   */
  async function onDelete() {
    if (props.deletingToc) {
      await deleteToc(props.deletingToc.transitionsOfCareId)
      context.emit('refetch')
    }
  }

  return {
    editOptionsArray,
    ModalSize,
    onDelete,
    close,
  }
}
