<template>
  <section>
    <Transition>
      <TNotification v-if="notification" />
    </Transition>

    <div v-if="practice" class="columns h-full is-marginless">
      <div
        data-cy="practice-sidebar"
        class="column is-one-fifth has-background-nash-neutral000 overflow-y-auto"
      >
        <PracticeSidebar :practice="practice" />
      </div>

      <div class="column overflow-y-auto overflow-x-hidden space-y-4">
        <PracticeNotes :practice="practice" />
        <PracticeLocations />
        <PracticeProviders />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import PracticeLocations from '@/legacy/components/practice/locations/PracticeLocations.vue'
import PracticeNotes from '@/legacy/components/practice/PracticeNotes.vue'
import PracticeProviders from '@/legacy/components/practice/PracticeProviders.vue'
import PracticeSidebar from '@/legacy/components/practice/PracticeSidebar.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  usePracticeApi,
  usePracticeStore,
} from '@/legacy/store/modules/practice'

export default defineComponent({
  components: {
    PracticeSidebar,
    PracticeNotes,
    PracticeLocations,
    PracticeProviders,
    TNotification,
  },
  setup() {
    const route = useRoute()
    const { notification } = storeToRefs(useNotificationStore())
    const practiceStore = usePracticeStore()
    const practiceId = computed(() => route.params.practiceId as string)
    const { datum: practice } = storeToRefs(usePracticeApi())

    onMounted(async () => {
      await Promise.all([practiceStore.fetchPractice(practiceId.value)])
    })

    return {
      practice,
      notification,
    }
  },
})
</script>
