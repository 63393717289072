import { storeToRefs } from 'pinia'
import { onBeforeMount, Ref } from 'vue'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { ContractingEntity } from '@/legacy/types/patients/contractingEntities'

export const useContractingEntitiesApi = apiStore<ContractingEntity>(
  'contractingEntitiesApi',
  '/api/contracting_entities',
  {
    transformData: (d: { data: ContractingEntity[] }) => d,
  }
)

/**
 * A Vue composable that provides the catalog of contracting entities for use
 * in component setup functions. It makes the listAll call and returns a ref
 * which will be updated with the contracting entities when the call completes.
 */
export function useContractingEntities(): Ref<ContractingEntity[] | null> {
  const { data: contractingEntities } = storeToRefs(useContractingEntitiesApi())

  onBeforeMount(async () => {
    // Populate store with available contracting entities
    await useContractingEntitiesApi().listAll({
      params: {},
    })
  })

  return contractingEntities
}
