<template>
  <div>
    <div
      class="progress-bar-text"
      :class="{
        'mx-0': isFullWidth,
        'ml-4': !isFullWidth,
        'mr-px': !isFullWidth,
      }"
    >
      <div v-if="isCompleted">
        {{ `${total}/${total} ${label}` }}
      </div>
      <div v-else>
        {{ `${completed}/${total} ${label}` }}
      </div>
      <div v-if="showPercentage" class="progress-bar-percentage">
        {{ percentageComplete }}%
      </div>
    </div>
    <div
      class="mb-4 relative"
      :class="{ 'mx-0': isFullWidth, 'mx-4': !isFullWidth }"
    >
      <div
        class="h-1 bg-nash-neutral300 min-w-full rounded-full absolute"
      ></div>
      <div
        :class="`h-1 rounded-full absolute ${barStatus}`"
        :style="{ width: `${barPercentage}%` }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { GENERIC_COMPLETED_STATUS } from '@/legacy/types/pathways/pathways'

export default defineComponent({
  props: {
    showPercentage: {
      type: Boolean,
      default: true,
    },
    completed: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'hold',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isCompleted = computed(
      () =>
        props.status === GENERIC_COMPLETED_STATUS ||
        props.status === 'completed'
    )
    const percentageComplete = computed(() => {
      if (
        props.status === GENERIC_COMPLETED_STATUS ||
        props.status === 'completed'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 100
      }
      if (props.completed === 0 && props.total === 0) {
        return 0
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return Math.floor((props.completed / props.total) * 100)
    })

    const barPercentage = computed(() => {
      const minValue = 5
      const maxValue = 100
      if (
        props.status === GENERIC_COMPLETED_STATUS ||
        props.status === 'completed'
      ) {
        return maxValue
      }
      if (percentageComplete.value === 0) {
        return 0
      }
      return Math.max(percentageComplete.value, minValue)
    })

    const barStatus = computed(() => {
      if (props.status === 'hold') {
        return 'bg-nash-neutral600'
      } else if (
        props.status === 'go' ||
        props.status === GENERIC_COMPLETED_STATUS ||
        props.status === 'completed'
      ) {
        return 'bg-nash-fern600'
      } else if (props.status === 'overdue') {
        return 'bg-nash-brick600'
      } else if (props.status === 'cancelled') {
        return 'bg-nash-brick700'
      } else if (props.status === 'lock') {
        return 'bg-nash-neutral300'
      }
      return ''
    })

    return {
      percentageComplete,
      barPercentage,
      barStatus,
      isCompleted,
    }
  },
})
</script>
<style lang="scss">
.progress-bar-percentage,
.progress-bar-text {
  @apply flex justify-between text-gray-500 relative text-sm mb-px;
}

.progress-bar-percentage {
  @apply mr-0;
}
</style>
