<template>
  <div>
    <!-- Manage Comorbidities -->
    <TModal
      :is-visible="isVisible && !isAddModalOpen"
      title="Manage Comorbidities"
      :size="ModalSize.LG"
      :flex="true"
      :allow-overflow="true"
      @close="close"
    >
      <div class="flex justify-between items-center">
        <TTab :tabs="tabs" :active="activeTab" @select="selectTab" />
        <LegacyTButton
          value="+ Add"
          :size="ButtonSize.SM"
          @click="isAddModalOpen = true"
        />
      </div>
      <div class="overflow-y-auto h-full flex flex-col gap-4">
        <div
          v-for="comorbidity in filteredComorbidities"
          :key="comorbidity.comorbidityId"
          class="comorbidity-wrapper"
        >
          <div class="flex justify-between items-center">
            <div>
              <div class="flex flex-col">
                <h5>
                  {{ getComorbidityDisplayName(comorbidity.comorbidityRefId) }}
                </h5>
              </div>
            </div>
            <div class="flex">
              <TOIconDropdown
                icon="meatball"
                :options="comorbidityActions(comorbidity)"
                @change="triggerComorbidityOptions($event.value, comorbidity)"
              />
            </div>
          </div>
        </div>
      </div>
    </TModal>
    <TModal
      :is-visible="!!confirmMarkComorbidity"
      :size="ModalSize.MD"
      @close="confirmMarkComorbidity = null"
    >
      <div class="flex justify-between items-center -m-2 mb-4">
        <div class="flex items-center">
          <LegacyTButton
            name="back-add-comorbidity"
            icon="chevronLeft"
            type="blackAndWhite"
            :size="ButtonSize.MD"
            @click="confirmMarkComorbidity = null"
          />
          <h2 class="mt-1">Mark Comorbidity as Inaccurate</h2>
        </div>
        <LegacyTButton
          name="close-add-comorbidity"
          icon="close"
          inline
          type="blackAndWhite"
          :size="ButtonSize.MD"
          @click="confirmMarkComorbidity = null"
        />
      </div>
      <p>Are you sure you want to mark this comorbidity as inaccurate?</p>
      <div class="comorbidity-wrapper mb-4">
        <h5>
          {{
            getComorbidityDisplayName(
              confirmMarkComorbidity?.comorbidityRefId ?? ''
            )
          }}
        </h5>
      </div>
      <template #actions>
        <LegacyTButton
          type="Tertiary"
          value="Cancel"
          @click="confirmMarkComorbidity = null"
        />
        <LegacyTButton
          value="Mark as Inaccurate"
          type="danger"
          @click="markInaccurate"
        />
      </template>
    </TModal>
    <AddComorbiditiesModal
      :is-visible="isAddModalOpen"
      @close="close"
      @back="isAddModalOpen = false"
      @cancel="close"
      @save="saveComorbidities"
    />
  </div>
</template>

<script lang="ts">
import TTab from '@nashville/tab/TTab.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import filter from 'lodash/filter'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import { safeLookup } from '@/legacy/libs/lookup'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import { useComorbidityRefApi } from '@/legacy/store/modules/comorbiditiesReferences'
import { useComorbiditiesApi } from '@/legacy/store/modules/patient'
import { Comorbidity } from '@/legacy/types/patients/comorbidities'
import AddComorbiditiesModal from './AddComorbiditiesModal.vue'

export default defineComponent({
  components: {
    AddComorbiditiesModal,
    TModal,
    LegacyTButton,
    TTab,
    TOIconDropdown,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['markAccuracy', 'save', 'close', 'refresh'],
  setup(props, context) {
    const isAddModalOpen = ref(false)

    const tabs = ref<{ label: string; value: string }[]>([
      { label: 'All Active', value: 'active' },
      { label: 'From Claims', value: 'payer' },
      { label: 'Manually Added', value: 'thymebox' },
      { label: 'Marked Inaccurate', value: 'inaccurate' },
    ])
    const activeTab = ref(tabs.value[0]?.value)
    const selectTab = (tab: Tab) => (activeTab.value = tab.value)

    const confirmMarkComorbidity = ref<Comorbidity | null>(null)
    const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
    const { data: comorbidityReferences } = storeToRefs(useComorbidityRefApi())

    const filteredComorbidities = computed(() => {
      let retComorbidities: Comorbidity[] = []
      switch (activeTab.value) {
        case 'active':
          retComorbidities = filter(
            comorbidities.value,
            ({ isInaccurate }) => !isInaccurate
          )
          break
        case 'payer':
          retComorbidities = filter(
            comorbidities.value,
            ({ source, isInaccurate }) => !isInaccurate && source === 'payer'
          )
          break
        case 'thymebox':
          retComorbidities = filter(
            comorbidities.value,
            ({ source, isInaccurate }) => !isInaccurate && source === 'thymebox'
          )
          break
        case 'inaccurate':
          retComorbidities = filter(
            comorbidities.value,
            ({ isInaccurate }) => isInaccurate
          )
          break
      }
      return retComorbidities.sort((a, b) =>
        (getComorbidityDisplayName(a.comorbidityRefId) ?? '').localeCompare(
          getComorbidityDisplayName(b.comorbidityRefId) ?? ''
        )
      )
    })

    const comorbidityActions = (comorbidity: Comorbidity) =>
      comorbidity.isInaccurate
        ? [{ value: 'accurate', label: 'Restore' }]
        : [{ value: 'inaccurate', label: 'Mark Inaccurate' }]

    const addedComorbidities = ref<string[]>([])

    const isAddedComorbidity = (comorbidityRefId: string) =>
      addedComorbidities.value.indexOf(comorbidityRefId) >= 0

    const addComorbidity = (comorbidityRefId: string) => {
      if (isAddedComorbidity(comorbidityRefId)) {
        addedComorbidities.value.splice(
          addedComorbidities.value.indexOf(comorbidityRefId),
          1
        )
      } else {
        addedComorbidities.value.push(comorbidityRefId)
      }
    }

    const getComorbidityDisplayName = (comorbidityId: string) =>
      safeLookup(comorbidityId, comorbidityReferences.value)?.description

    const triggerComorbidityOptions = (
      value: string,
      comorbidity: Comorbidity
    ) => {
      if (value === 'inaccurate') {
        confirmMarkComorbidity.value = comorbidity
      } else if (value === 'accurate') {
        context.emit('markAccuracy', {
          ...comorbidity,
          isInaccurate: false,
        })
      }
    }

    const markInaccurate = () => {
      context.emit('markAccuracy', {
        ...confirmMarkComorbidity.value,
        isInaccurate: true,
      })

      confirmMarkComorbidity.value = null
    }

    const saveComorbidities = (addedComorbidityRefIds: string[]) => {
      context.emit('save', addedComorbidityRefIds)
    }

    const close = () => {
      isAddModalOpen.value = false
      context.emit('close')
    }
    const refresh = () => context.emit('refresh')

    return {
      ButtonSize,
      ModalSize,
      isAddModalOpen,
      activeTab,
      confirmMarkComorbidity,
      comorbidities,
      filteredComorbidities,
      tabs,
      getComorbidityDisplayName,
      comorbidityActions,
      addComorbidity,
      isAddedComorbidity,
      markInaccurate,
      selectTab,
      close,
      refresh,
      saveComorbidities,
      triggerComorbidityOptions,
    }
  },
})
</script>

<style lang="scss">
.comorbidity-wrapper {
  @apply border border-nash-neutral400 rounded-lg py-6 px-4;

  &.added {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
