import { defineStore } from 'pinia'

import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { OutcomeReference, OutcomeTaskMapping } from '@/legacy/types/outcomes'

export const useOutcomeRefStore = defineStore('outcomeReferences', {
  state: () => ({}),
  actions: {
    async fetchOutcomeRefs(filter_outcome_ref_ids: string[] = []) {
      await useOutcomeRefApi().listAll({
        params: { filter_outcome_ref_ids: filter_outcome_ref_ids },
      })
    },
  },
})

export const useOutcomeRefApi = apiStore<
  OutcomeReference,
  IdMap<OutcomeReference>
>('outcomeReferenceApi', '/api/outcomes/reference', {
  transformData: (d: { data: OutcomeReference[] }): IdMap<OutcomeReference> =>
    idMapTransform({}, 'data', 'outcomeRefId', d.data),
})

export const useOutcomeTaskMappingApi = apiStore<OutcomeTaskMapping>(
  'outcomeTaskMappingApi',
  '/api/task_templates/outcomes/maps',
  {}
)
