<template>
  <div
    v-for="(answers, symptom) in symptomIdHistory?.answersHistory"
    :key="symptom"
  >
    <!-- IMPROVEME MT-3112 - render symptom histories in same order as form questions -->
    <SymptomHistory
      :key="symptom"
      :symptom-name="symptomIdDisplayNames[symptom]"
      :symptom-answers="answers"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import setup from './controller'
import SymptomHistory from './SymptomHistory/SymptomHistory.vue'

export default defineComponent({
  components: {
    SymptomHistory,
  },
  setup,
})
</script>
