<template>
  <TModal
    :is-visible="true"
    :size="ModalSize.LG"
    :title="modalTitle"
    @close="close"
  >
    <template #preTitle>
      <TIcon class="mr-2 cursor-pointer" icon="chevronLeft" @click="close" />
    </template>
    <div v-for="(field, index) in fields" :key="field.key">
      <div
        v-if="!field.value.removed"
        class="flex justify-between items-center"
      >
        <div class="w-full">
          <TCalendar
            :name="`radiationPayload[${index}].radiationDate`"
            :disable-holidays="false"
            label="Radiation Date"
            class="w-full my-2"
            :min-date="null"
            :model-value="field.value?.radiationDate ?? undefined"
          />
          <TTextarea
            v-if="showCarePlanImprovements"
            label="Additional Information"
            :name="`radiationPayload[${index}].additionalInformation`"
            :model-value="field.value?.additionalInformation ?? ''"
          />
        </div>
        <TMSecondaryButton
          v-if="showAdd"
          icon="close"
          class="ml-3 mt-5"
          @click="removeRadiationForm(index)"
        />
      </div>
    </div>

    <TMTertiaryButton
      v-if="showAdd"
      label="Add Radiation"
      class="pt-2 mb-3"
      icon="add"
      @click="addRadiationForm"
    />
    <template #actions>
      <TMSecondaryButton
        label="Cancel"
        name="radiation-add-or-edit-cancel"
        @click="close"
      />
      <TMPrimaryButton
        v-if="showAdd"
        name="radiation-add"
        label="Save"
        @click="onAddSubmit"
      />
      <TMPrimaryButton
        v-if="!showAdd"
        name="radiation-edit"
        label="Save"
        @click="onEditSubmit"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { addOrEditRadiationProps } from './types'

export default defineComponent({
  components: {
    TIcon,
    TMTertiaryButton,
    TModal,
    TMSecondaryButton,
    TMPrimaryButton,
    TTextarea,
    TCalendar,
  },
  props: addOrEditRadiationProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
