import { PropType } from 'vue'
import { MedicalConditionReference } from '@/pages/PatientProfile/CarePlans/shared/types'

export const addMedicalConditionInnerProps = {
  medicalConditionReference: {
    type: Object as PropType<MedicalConditionReferenceDisplay>,
    required: true,
  },
} as const

export type MedicalConditionReferenceDisplay = {
  patientId: string
  attributeOptions: { label: string; value: string }[] | null
} & MedicalConditionReference
