import { defineStore } from 'pinia'
import {
  DocumentCategory,
  DocumentDownload,
  Document,
} from '@/legacy/types/documents'
import { NotificationType } from '@/legacy/types/notifications'
import apiStore from './apiBuilder'
import { useNotificationStore } from './notification'

export const useDocumentStore = defineStore('document', {
  state: () => ({
    isUploadingDocument: false,
  }),
  getters: {},
  actions: {
    getDocumentCategoryId(id: string) {
      if (!id || id === 'other') {
        return (id =
          useDocumentCategoryApi().data?.filter(
            ({ name }) => name === 'other'
          )[0].documentCategoryId ?? '')
      }
      return id
    },
    async startUpload(
      patientId: string,
      documentCategoryId: string,
      name: string,
      file: File
    ) {
      this.isUploadingDocument = true
      const documentApi = useDocumentApi()
      documentCategoryId = this.getDocumentCategoryId(documentCategoryId)
      const response = await documentApi.retrieve({
        ids: ['upload_url'],
        params: {
          patientId,
          documentCategoryId,
          fileName: file.name,
          name,
        },
      })
      if (documentApi.error) {
        // error handling
        this.isUploadingDocument = false
        useNotificationStore().setNotification({
          message: 'Failed to upload file',
          description: `${file.name} failed: ${documentApi.error}`,
          type: NotificationType.DANGER,
        })
        return
      }
      const { documentId, url } = response
      await this.finishUpload(url, documentId, file)
    },
    async finishUpload(url: string, documentId: string, file: File) {
      try {
        /**
         * Raw Fetch because we are pulling from an external (s3) link
         * and all of our apiRequest functions append our apiUrl
         */
        await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        })
      } catch (e) {
        this.isUploadingDocument = false
        useNotificationStore().setNotification({
          message: 'Failed to upload file',
          description: `${file.name} failed: ${e}`,
          type: NotificationType.DANGER,
        })
        return
      }

      await useDocumentApi().partialUpdate({
        ids: [documentId],
        body: {
          status: 'UPLOADED',
        },
      })
    },
    async downloadFile(d: Document) {
      try {
        const documentApi = useDocumentApi()
        const response: DocumentDownload = await documentApi.retrieve({
          ids: ['download_url'],
          params: {
            document_id: d.documentId,
          },
        })
        this.downloadFileByUrl(
          response.url,
          `${d.documentCategory?.displayName} ${d.name}`
        )
      } catch (e) {
        console.error(e)
        this.isUploadingDocument = false
        return
      }
      this.isUploadingDocument = false
    },
    downloadFileByUrl(url: string, name: string) {
      const elem = document.createElement('a')
      elem.href = url
      elem.setAttribute('download', name)
      elem.setAttribute('target', '_blank')
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    },
  },
})

export const useDocumentApi = apiStore<Document>(
  'documentApi',
  '/api/documents',
  {
    transformData: (d: { data: Document[] }) => d,
  }
)

export const useArchivedDocumentApi = apiStore<Document>(
  'archivedDocumentApi',
  '/api/documents',
  {
    transformData: (d: { data: Document[] }) => d,
  }
)

export const useDocumentCategoryApi = apiStore<DocumentCategory>(
  'documentCategoryApi',
  '/api/documents/categories',
  {
    transformData: (d: { data: DocumentCategory[] }) => d,
  }
)
