<template>
  <Badge
    :class="badgeClasses"
    :data-cy="name && `${kebabCase(name)}-badge`"
    v-bind="$attrs"
    :value="value"
    @click="(e) => $emit('click', e)"
  />
</template>

<script lang="ts">
import { tokenizedComponentClasses } from '@thyme/nashville/src/utils/designTokens'
import kebabCase from 'lodash/kebabCase'
import Badge from 'primevue/badge'

import { PropType, computed, defineComponent } from 'vue'
// this needs to be relative until vue-component-meta bug fixed
// https://github.com/vuejs/language-tools/issues/3896
import { paddingProp } from '../../types/shared'

const sharedProps = {
  ...paddingProp,
}

export default defineComponent({
  components: {
    Badge,
  },
  props: {
    ...sharedProps,
    /** for test attr naming */
    name: {
      type: String,
      default: null,
    },
    /** value that goes into the badge */
    value: {
      // converts anything here into a string
      type: String as PropType<string | number>,
      default: null,
    },
  },
  emits: ['click'],
  setup(props) {
    /** PVClasses for button props */
    const tokenClasses = tokenizedComponentClasses(
      'button',
      props,
      Object.keys(sharedProps)
    )

    /** computed wrapper for token classes */
    const badgeClasses = computed(() => [...tokenClasses.value])

    return {
      badgeClasses,
      kebabCase,
    }
  },
})
</script>
