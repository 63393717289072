import { DropdownKVWithDetail } from '@thyme/nashville/src/types/dropdowns'
import { computed } from 'vue'

import { formatNameFromEntity } from '@/legacy/libs/format'
import {
  compareIsPrimary,
  isTextableAndOptedIn,
  isTextableAndUnknownOptIn,
  isTextableAndNotOptedOut,
} from '@/legacy/store/modules/lib/phoneNumbers'
import { Entity } from '@/legacy/types/entities/entities'
import {
  MapEntityPhoneNumber,
  PhoneNumber,
} from '@/legacy/types/entities/phoneNumbers'
import { idMapToString, idMapFromString } from '../lib/phoneDropdown'

export const MISSING_CONSENT_DETAIL_TEXT = 'Has not consented to SMS'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const entityToDropdownOptions = (
    entity: Entity,
    mustBeTextableAndOptedIn: boolean,
    mustBeTextableIncludingUnconsented: boolean,
    includeNonContactable = true
  ) => {
    const sortedMaps: MapEntityPhoneNumber[] = (entity.phoneNumbers ?? []).sort(
      compareIsPrimary
    )
    let entityPhones: PhoneNumber[] = sortedMaps.map(
      (mapEntityPhoneNumber) => mapEntityPhoneNumber.phoneNumber
    )
    if (mustBeTextableIncludingUnconsented) {
      entityPhones = entityPhones.filter(isTextableAndNotOptedOut)
    } else if (mustBeTextableAndOptedIn) {
      entityPhones = entityPhones.filter(isTextableAndOptedIn)
    }
    if (!entityPhones.length) {
      if (includeNonContactable) {
        return [
          {
            value: idMapToString({ entityId: entity.entityId }),
            label: `${formatNameFromEntity(entity)}: N/A`,
            detail: null,
          } as DropdownKVWithDetail,
        ]
      }
      return []
    }
    return entityPhones.map(
      (phone) =>
        ({
          value: idMapToString({
            entityId: entity.entityId,
            phoneNumberId: phone.phoneNumberId,
          }),
          label: `${formatNameFromEntity(entity)}: ${phone.phoneNumber}`,
          detail: isTextableAndUnknownOptIn(phone)
            ? MISSING_CONSENT_DETAIL_TEXT
            : null,
        } as DropdownKVWithDetail)
    )
  }

  const phoneDropdownOptions = computed(() => {
    const options = props.entities
      .map((e: Entity) =>
        entityToDropdownOptions(
          e,
          props.mustBeTextableAndOptedIn,
          props.mustBeTextableIncludingUnconsented,
          props.includeNonContactable
        )
      )
      .flat(1)
    if (props.emptyOptionLabel || !options.length) {
      options.push({
        value: idMapToString({}),
        label: props.emptyOptionLabel ?? 'None available',
        detail: null,
      } as DropdownKVWithDetail)
    }
    return options
  })

  const updateSelection = (v: string) => {
    context.emit('update:model-value', idMapFromString(v))
  }

  const initialValue = computed(() => {
    return idMapToString(props.modelValue)
  })

  return {
    entityToDropdownOptions,
    phoneDropdownOptions,
    updateSelection,
    initialValue,
  }
}
