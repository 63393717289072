import omit from 'lodash/omit'
import { defineStore } from 'pinia'
import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { DataType } from '@/legacy/types/api/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import {
  Practice,
  PracticeLocation,
  PracticeState,
} from '@/legacy/types/practices'

/**
 *
 * @param practiceState
 * @param data
 */
function transformPractices(
  practiceState: Partial<PracticeState>,
  data: Practice[] | undefined
): Partial<PracticeState> {
  return {
    ...omit(practiceState, 'data'),
    ...(data ? { data: toIdMap(data, 'entityId') } : {}),
  }
}

/**
 *
 * @param practiceState
 * @param data
 */
function transformPracticeLocations(
  practiceState: Partial<PracticeState>,
  data: PracticeLocation[] | undefined
): Partial<PracticeState> {
  const mappedLocationsByPracticeId: any = {}

  data?.forEach((location) => {
    const { practiceId, entityId } = location
    mappedLocationsByPracticeId[practiceId] = {
      [entityId]: location,
      ...mappedLocationsByPracticeId[practiceId],
    }
  })

  return {
    ...omit(practiceState, 'data'),
    ...(data ? { data: mappedLocationsByPracticeId } : {}),
  }
}

export const usePracticeStore = defineStore('practice', {
  state: () => ({}),
  getters: {},
  actions: {
    async fetchPractices(
      practiceIds: string[] | null = null,
      doFetchPhoneNumbers = true
    ) {
      await usePracticeApi().listAll({
        params: practiceIds ? { filter_practice_ids: practiceIds } : {},
      })
      await this.fetchPracticeLocations(practiceIds, doFetchPhoneNumbers)
    },

    async fetchPractice(practiceId: string) {
      await usePracticeApi().retrieve({
        ids: [practiceId],
      })
      if (usePracticeApi().error) {
        useNotificationStore().setNotification({
          message: 'Failed to fetch practice.',
          type: NotificationType.DANGER,
        })
      }
    },
    async fetchAndSetPracticeLocationPhones() {
      const practiceLocationApi = usePracticeLocationApi()
      const data = practiceLocationApi.data
      if (data) {
        const locationsById = toIdMap(data, 'entityId')
        const practicePhones = await usePracticePhoneNumberApi().list({
          params: {
            filter_entity_ids: Object.keys(locationsById),
          },
        })
        practicePhones.data.forEach((phoneNumberMap: MapEntityPhoneNumber) => {
          const location = locationsById[phoneNumberMap.entityId]
          if (location && location.entity) {
            if (location.entity.phoneNumbers) {
              location.entity.phoneNumbers?.push(phoneNumberMap)
            } else {
              location.entity.phoneNumbers = [phoneNumberMap]
            }
          }
        })
      }
      practiceLocationApi.updateData(data, DataType.PLURAL)
    },
    async fetchPracticeLocations(
      practiceIds: string[] | null = null,
      doFetchPhoneNumbers = true
    ) {
      const practiceLocationApi = usePracticeLocationApi()
      await practiceLocationApi.listAll({
        params: practiceIds ? { filter_practice_ids: practiceIds } : {},
      })
      if (doFetchPhoneNumbers) {
        await this.fetchAndSetPracticeLocationPhones()
      }
    },
  },
})

export const usePracticeApi = apiStore<Practice, IdMap<Practice>>(
  'practiceApi',
  '/api/practices',
  {
    transformData: (d: { data: Practice[] | undefined }) =>
      transformPractices({}, d.data),
  }
)

export const usePracticeListApi = apiStore<Practice>(
  'practiceListApi',
  '/api/practices',
  {
    transformData: (d: { data: Practice[] | undefined }) => d,
  }
)

export const useLocationsByPracticeApi = apiStore<
  PracticeLocation,
  IdMap<IdMap<PracticeLocation>>
>('locationsByPracticeApi', '/api/practices/locations', {
  transformData: (d: { data: PracticeLocation[] | undefined }) =>
    transformPracticeLocations({}, d.data),
  params: { parts: ['entity.addresses'] },
})

export const usePracticeLocationApi = apiStore<PracticeLocation>(
  'practiceLocationsApi',
  '/api/practices/locations',
  {
    transformData: (d: { data: PracticeLocation[] }) => d,
    params: { parts: ['entity.addresses'] },
  }
)

export const usePracticePhoneNumberApi = apiStore<
  MapEntityPhoneNumber,
  IdMap<MapEntityPhoneNumber>
>('phoneNumberApi', '/api/phone_numbers/entity_maps', {
  transformData: (d: { data: MapEntityPhoneNumber[] }) =>
    idMapTransform({}, 'data', 'mapEntityPhoneNumberId', d.data),
})
