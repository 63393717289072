/**
 * We are currently setting page length to 50
 * for all oncology diagnosis related reference calls (spread, onc diagnoses, onc diagnosis categories).
 *
 * Currently, we only have roughly 45 cancer diagnosis references,
 * 20 oncology diagnosis categories, and 15 oncology spread references.
 
 * Will need to officially implement pagination and set page length to a lower
 * number in the future when and if the team decides to significantly increase
 * the number of oncology references.
 */
export const ONCOLOGY_REFERENCES_PAGE_LENGTH = 50
