import { HeadingSize } from '@thyme/nashville/src/types/headings'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'

import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeUTC } from '@/legacy/libs/format'

import { useVisitsApi } from '@/legacy/store/modules/visits'

/**
 *
 * @param props
 */
export default function (props: any) {
  const visitsApi = useVisitsApi()
  const { data: visits } = storeToRefs(visitsApi)
  const expanded = ref(new Set())
  const showPastAppointments = ref(false)

  /**
   *
   */
  async function loadAppointments() {
    await visitsApi.list({
      params: {
        filter_patient_ids: [props.patientId],
        filter_upcoming_only: !showPastAppointments.value,
        page_length: 100,
      },
    })
  }

  // fetch visits on mount and on past appointments toggle
  onMounted(loadAppointments)
  watch(showPastAppointments, loadAppointments)

  return {
    HeadingSize,
    // the data
    visits,

    // utilities for formatting the data
    printDate: (date: string) => {
      const luxonDate = stringToDateTime(date)
      // we use `formatDateTimeUTC` because the incoming date string is timezone-less,
      // assumed to be in the local time of the practice. Luxon will interpret this
      // as a UTC time, so we want to display it as a UTC time in order to retain
      // the original timezone.
      return formatDateTimeUTC(luxonDate, DateTime.DATETIME_SHORT)
    },

    // utilities for showing/hiding individual rows
    showPastAppointments,
    isShowing: (id: string) => expanded.value.has(id),
    show: (id: string) => expanded.value.add(id),
    hide: (id: string) => expanded.value.delete(id),
  }
}
