// TYPES --------------------

// state to track save status for a call
export type SaveState = {
  isSaving: boolean
  error: Error | null
  lastCallAt: Date | undefined
}

// Constructors

/**
 *
 */
export function newSaveState(): SaveState {
  return {
    isSaving: false,
    error: null,
    lastCallAt: undefined,
  }
}
