<template>
  <TTable
    ref="table"
    :title="title"
    :name="name"
    :columns="columns"
    :api-store="apiStore"
    :rows="10"
    class="overflow-hidden"
    v-bind="$attrs"
  >
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
    <template #tableTopActions>
      <slot name="tableButton"></slot>
      <TMPrimaryButton
        v-if="addable"
        :name="`add-new-${name}`"
        :label="`+ Add ${name}`"
        :unmask="true"
        @click="addRow"
      />
    </template>
    <template #lastColumnBody="slotProps">
      <span class="flex gap-2">
        <TMSecondaryButton
          v-if="editable"
          icon="pencil"
          size="sm"
          :disabled="
            editOptions?.disableFn && editOptions?.disableFn(slotProps.data)
          "
          @click="editRow(slotProps.data)"
        />
        <TMSecondaryButton
          v-if="deletable"
          icon="close"
          size="sm"
          @click="openDeleteModal(slotProps.data)"
        />
      </span>
    </template>
  </TTable>
  <TAdminAddRow
    :is-open="addModalOpen"
    :editing-row="currentEditingRow"
    :columns="editColumns"
    :name="name"
    @save="saveRow"
    @close="closeAddModal"
  />
  <TMConfirmationModal
    :is-open="deleteModalOpen"
    action="delete"
    :action-target="name"
    @save="deleteRow"
    @close="closeDeleteModal"
  />
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { defineComponent, defineExpose, PropType, ref } from 'vue'
import TMConfirmationModal from '@/legacy/nashville/modal/TMConfirmationModal.vue'
import TAdminAddRow from '../TAdminAddRow.vue'

import {
  createEditRefs,
  createDeleteRefs,
  tableEditProps,
} from './editableTableSetup'
import TTable from './TTable.vue'

export default defineComponent({
  components: {
    TTable,
    TAdminAddRow,
    TMSecondaryButton,
    TMPrimaryButton,
    TMConfirmationModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    columns: {
      type: Array as PropType<ColumnOptions[]>,
      required: true,
    },
    apiStore: {
      type: Function,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: String,
      default: null,
    },
    ...tableEditProps,
  },
  emits: ['save', 'delete'],
  setup(props, context) {
    const table = ref<{ getData: () => object } | null>(null)
    const getData = () => table.value?.getData()

    const { addModalOpen, currentEditingRow, editRow, addRow, closeAddModal } =
      createEditRefs()

    const {
      deleteModalOpen,
      currentDeletingRow,
      openDeleteModal,
      closeDeleteModal,
    } = createDeleteRefs()

    const saveRow = (form: any) => {
      context.emit('save', { form, existing: currentEditingRow })
    }

    const deleteRow = async () => {
      try {
        await props.apiStore().delete({
          ids: [currentDeletingRow.value[props.rowId]],
        })
        context.emit('delete', currentDeletingRow)
        table.value?.getData()
      } catch (e) {
        console.error(e)
      }
    }

    defineExpose({
      getData,
      addRow,
    })

    return {
      table,
      saveRow,
      deleteRow,
      addModalOpen,
      currentEditingRow,
      editRow,
      addRow,
      closeAddModal,
      getData, // has to be returned to be exposed
      deleteModalOpen,
      currentDeletingRow,
      openDeleteModal,
      closeDeleteModal,
    }
  },
})
</script>
