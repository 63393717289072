<template>
  <TChip
    v-tooltip="hoverText"
    v-bind="iconProps"
    :label="contractingEntityName"
    @click="copyContractIdToClipboard"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TChip from '@/legacy/nashville/chip/TChip.vue'
import { setup } from './controller'
import { contractingChipProps } from './types'

export default defineComponent({
  name: 'ContractingChip',
  components: {
    TChip,
  },
  props: contractingChipProps,
  setup,
})
</script>
