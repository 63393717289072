import { PropType } from 'vue'
import { object, string, InferType, date } from 'yup'

export const schema = object({
  preferredName: string().nullable(),
  language: string().nullable(),
  dob: date().nullable(),
  dod: date().nullable(),
  gender: string().required(),
  carePodId: string().nullable(),
  contractingEntityId: string().nullable(),
  contractExternalId: string().nullable(),
})

export type EditPatientDetailsForm = InferType<typeof schema>

export const editPatientDetailsProps = {
  patientId: {
    type: String,
    required: true,
  },
  patientContractId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditPatientDetailsForm>>,
    required: true,
  },
} as const
