<template>
  <!-- IMPROVEME(MT-3417) Use newer form patterns. -->
  <!-- @vue-ignore Submit generics not compatible with specifics -->
  <VForm
    v-slot="{ values, setFieldValue }"
    :initial-values="initialValues"
    @submit="submit"
  >
    <label class="font-bold">Insurance name</label>
    <TDropdown
      v-model="values.payerId"
      class="w-full bg-white"
      input-id="payerId"
      option-label="label"
      option-value="value"
      :options="payerChoices"
      :show-clear="false"
      :disabled="isNotEditable"
      @update:model-value="setFieldValue('payerId', $event)"
    />
    <!-- IMPROVEME(): This check is brittle and will break if the payer name changes in the database -->
    <div v-if="payers?.[values.payerId]?.payerName === 'Other insurance'">
      <TInputText
        :model-value="initialValues.otherInsuranceName"
        name="otherInsuranceName"
        rules="required"
        placeholder="Type in name..."
      />
    </div>
    <div>
      <label class="font-bold">Network Tier</label>
      <TDropdown
        v-model="values.networkTier"
        class="w-full bg-white"
        input-id="networkTier"
        :options="['Local Value', 'AmeriHealth Advantage']"
        :show-clear="false"
        @update:model-value="setFieldValue('networkTier', $event)"
      />
      <label class="font-bold">Plan Type</label>
      <TDropdown
        v-model="values.marketSegment"
        class="w-full bg-white"
        input-id="marketSegment"
        option-label="label"
        option-value="value"
        :options="planTypeOptions"
        :show-clear="false"
        @update:model-value="setFieldValue('marketSegment', $event)"
      />
    </div>
    <TInputText
      :model-value="initialValues.memberId"
      label="Member ID"
      name="memberId"
      rules="required"
      :disabled="isNotEditable"
    />
    <TInputText
      :model-value="initialValues.groupId"
      label="Group ID"
      name="groupId"
      :disabled="isNotEditable"
    />
    <label class="font-bold">Type</label>
    <TDropdown
      v-model="values.type"
      class="w-full bg-white"
      input-id="type"
      option-label="label"
      option-value="value"
      :options="[
        { label: 'Primary', value: 'primary' },
        { label: 'Secondary', value: 'secondary' },
      ]"
      :show-clear="false"
      :disabled="isNotEditable"
      @update:model-value="setFieldValue('type', $event)"
    />
    <label class="font-bold">Status</label>
    <TDropdown
      v-model="values.status"
      class="w-full bg-white"
      input-id="status"
      option-label="label"
      option-value="value"
      :options="[
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]"
      :show-clear="false"
      :disabled="isNotEditable"
      @update:model-value="setFieldValue('status', $event)"
    />
    <div class="mt-2 flex flex-row-reverse">
      <button class="p-button p-component mt-2" name="save-patient-insurance">
        Save
      </button>
    </div>
  </VForm>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { storeToRefs } from 'pinia'
import { Form as VForm } from 'vee-validate'
import { computed, defineComponent, PropType, ref, onBeforeMount } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'

import { usePayerApi } from '@/legacy/store/modules/payers'
import { ContactsFormData } from '@/legacy/types/patients/contacts'
import {
  AddInsuranceForm,
  MarketSegment,
} from '@/legacy/types/patients/insurances'
import { EditInsuranceModel } from '@/pages/PatientProfile/PatientSidebar/PatientInsurances/EditInsurances/types'

export default defineComponent({
  components: {
    VForm,
    TInputText,
    TDropdown,
  },
  props: {
    creating: {
      type: Boolean,
      default: false,
    },
    insurance: {
      type: Object as PropType<EditInsuranceModel | null>,
      default: null,
    },
  },
  emits: ['submit'],
  setup(props, context) {
    const { data: payers } = storeToRefs(usePayerApi())
    const isNotEditable = computed(
      () => (props.insurance && !props.insurance.isManuallyAdded) ?? false
    )

    const payerChoices = computed(() =>
      payers.value
        ? Object.values(payers.value).map(({ payerId, payerName }) => {
            return {
              value: payerId,
              label: payerName,
            }
          })
        : []
    )

    const planTypeOptions = computed(() => {
      return Object.entries(MarketSegment).map(([k, v]) => {
        return { value: v, label: k }
      })
    })

    const emptyForm = () => ({
      payerId: '',
      memberId: '',
      groupId: '',
      type: 'primary',
      status: 'active',
      otherInsuranceName: '',
      marketSegment: '',
      networkTier: '',
    })
    const initialValues = ref<AddInsuranceForm>(emptyForm())

    onBeforeMount(() => {
      if (!props.creating && props.insurance) {
        const {
          payerId,
          memberId,
          groupId,
          isPrimary,
          isActive,
          otherInsuranceName,
          marketSegment,
          networkTier,
        } = props.insurance

        initialValues.value.payerId = payerId
        initialValues.value.memberId = memberId
        initialValues.value.groupId = groupId
        initialValues.value.type = isPrimary ? 'primary' : 'secondary'
        initialValues.value.status = isActive ? 'active' : 'inactive'
        initialValues.value.otherInsuranceName = otherInsuranceName
        initialValues.value.marketSegment = marketSegment
        initialValues.value.networkTier = networkTier
      }
    })

    /**
     * Function to emit back form submit to parent component
     * @param data
     */
    function submit(data: ContactsFormData) {
      context.emit('submit', data)
    }
    return {
      isNotEditable,
      payers,
      planTypeOptions,
      initialValues,
      payerChoices,
      submit,
      close,
    }
  },
})
</script>
