import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { Store } from 'pinia'
import { SaveState } from './api'
import { IdMap } from './store'

// TYPES --------------------
// Pinia uses 'this' instead of 'state' parameter, so we need some typings to
// ensure API mutations can access the right properties on 'this'.
export type ApiMutationsType<S extends ApiStatePartial, T> = {
  loading: () => void
  success: (data: T) => void
  setError: (error: ApiError | Error) => void
} & ThisType<Store<'', S>>

export type ApiMutations<T> = {
  setSuccess: (data: T | T[]) => void
  setError: (error: ApiError | Error) => void
}

type ApiActions<T> = {
  list<H extends object, P extends object>(
    headers?: H,
    params?: P,
    ids?: string[]
  ): Promise<T[] | null>
  listAll<H extends object, P extends object>(
    headers?: H,
    params?: P,
    ids?: string[]
  ): Promise<T[] | null>
  retrieve<H extends object, P extends object>(
    id: string,
    headers?: H,
    params?: P
  ): Promise<T | null>
  create<H extends object, B extends object>(
    headers?: H,
    body?: B
  ): Promise<T | null>
  upsert<H extends object, B extends object>(
    headers?: H,
    body?: B
  ): Promise<T | null>
  update<H extends object, B extends object>(
    headers?: H,
    body?: B
  ): Promise<T | null>
  partialUpdate<H extends object, B extends object, P extends object>(
    headers?: H,
    body?: B,
    params?: P
  ): Promise<T | null>
  delete<H extends object>(id: string, headers?: H): Promise<void>
}

export type ApiStore<T, S> = {
  $state?: ApiState<T, S>
} & ApiState<T, S> &
  ApiMutations<T> &
  ApiActions<T>

export type ApiActionOptions = {
  headers?: { [key: string]: any }
  params?: { [key: string]: any }
  body?: { [key: string]: any }
  file?: File | null
}

export type SetupOptions = {
  fetchAll?: boolean
  /**
   * saveState: tracker for save status
   */
  saveState?: SaveState
  /**
   *  bubbleErrorThrow: true -> bubble error for try/catch workflows
   *  WARNING WILL CAUSE SERIOUS PAGE BREAKAGE IF NOT CAUGHT
   */
  bubbleErrorThrow?: boolean
}

export type PassedSetupOptions = Omit<SetupOptions, 'fetchAll'>

export type ApiStoreOptions<T, S> = {
  transformData?: (data: any, state?: any) => Partial<ApiState<T, S>>
  transformDatum?: (datum: any, state?: any) => Partial<ApiState<T, S>>
  headers?: { [key: string]: unknown }
  params?: { [key: string]: unknown }
}

// INTERFACES --------------------
export interface ApiStatePartial {
  isLoading: boolean
  error: ApiError | Error | null
}

export interface Selectable {
  selectedId: string | null
}

export interface ApiState<T, S = T[]> {
  data: S | null
  datum: T | null
  isLoading: boolean
  isLoaded: boolean
  error: ApiError | Error | null
  queryMetadata: { total: number } | null
  idMap: IdMap<T> | null
}

// ENUMS --------------------
/** Determines where to store returned data/datum from apiResponse */
export enum DataType {
  SINGULAR = 'singular',
  PLURAL = 'plural',
  PATCH = 'patch',
}

// CONSTANTS --------------------
/**
 * Maximum amount of items to grab from a single API call
 *  Used by ListAll command to split api calls into smaller chunks
 */
export const MAX_PAGE_SIZE = 100

export const initialApiState = () => ({
  isLoading: false,
  error: null,
})

export const initialSelectableState = () => ({
  selectedId: null,
})

export const selectMutation = <S extends Selectable>() => ({
  select(state: S, id: string) {
    state.selectedId = id
  },
})

export const apiMutations = <S extends ApiStatePartial, T>(
  transform: (data: T) => Partial<S>
): ApiMutationsType<S, T> => ({
  loading() {
    this.isLoading = true
  },
  success(data: T) {
    Object.assign(this.$state, transform(data))
    this.isLoading = false
    this.error = null
  },
  setError(error: ApiError | Error) {
    this.error = error
    this.isLoading = false
  },
})
