import {
  ColumnOptions,
  SORT_DESCENDING,
} from '@thyme/nashville/src/types/tables'
import { storeToRefs } from 'pinia'

import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { fetchOncReferences } from '@/legacy/components/patient/carePlans/lib/sharedOncologyTreatmentParts'
import { sqlDateToFormattedDate } from '@/legacy/libs/date'
import { safeLookup } from '@/legacy/libs/lookup'
import { useOncologyTreatmentRefApi } from '@/legacy/store/modules/oncologyTreatmentsReferences'
import { useOncologyTreatmentApi } from '@/legacy/store/modules/patient'
import {
  CodeType,
  codeTypeToDrugType,
} from '@/legacy/types/patients/oncologyTreatments'

/**
 *
 */
export default function () {
  const table = ref<{ getData: () => object } | null>(null)
  const columns: ColumnOptions[] = [
    {
      field: 'oncologyTreatmentRefId',
      header: 'Drug Name',
      display: (oncoRefId: string) =>
        readableOncologyTreatmentRef(oncoRefId).description,
    },
    {
      field: 'oncologyTreatmentRefId',
      header: 'Drug Type',
      display: (oncoRefId: string) =>
        readableOncologyTreatmentRef(oncoRefId).code,
    },
    {
      field: 'firstClaimDate',
      header: 'First Claim',
      display: (date: string) => date && sqlDateToFormattedDate(date),
    },
    {
      field: 'lastClaimDate',
      header: 'Last Claim',
      display: (date: string) => date && sqlDateToFormattedDate(date),
    },
  ]
  const { data: oncologyTreatmentCatalog } = storeToRefs(
    useOncologyTreatmentRefApi()
  )
  const route = useRoute()
  const patientId = route.params.patientId

  /**
   *
   * @param codeType
   */
  function getDrugTypeFromCodeType(codeType: CodeType | keyof CodeType) {
    return codeTypeToDrugType[codeType as keyof typeof CodeType] ?? '<Unknown>'
  }

  /**
   *
   * @param oncologyTreatmentRefId
   */
  function readableOncologyTreatmentRef(oncologyTreatmentRefId: string) {
    const ref = safeLookup(
      oncologyTreatmentRefId,
      oncologyTreatmentCatalog.value
    )
    if (ref) {
      return {
        description: ref.description,
        code: getDrugTypeFromCodeType(ref.codeType as keyof CodeType),
      }
    }
    return { description: '<Unknown>', code: '<Unknown>' }
  }

  return {
    sqlDateToFormattedDate,
    table,
    columns,
    fetchOncReferences,
    patientId,
    useOncologyTreatmentApi,
    SORT_DESCENDING,
  }
}
