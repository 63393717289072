<template>
  <div class="space-y-4">
    <div v-if="wasUnknownNumber">
      This new contact has text messages that need to be synced to the member
      profile. Please sync to view the entire message thread.
    </div>
    <div v-else-if="otherTCMembers.length">
      <p class="mb-4">
        {{ sharedNumberWithTCMember }}
      </p>
      <div class="space-y-4">
        <div v-for="(otherTCMember, id) in otherTCMembers" :key="id">
          <a
            :href="buildUrlToOtherTCMember(otherTCMember.entityId)"
            target="_blank"
          >
            <TMTertiaryButton
              :label="
                nameEntityIdMaps[otherTCMember.entityId] ??
                'Unknown Member Name'
              "
              icon-pos="right"
              icon="heroicons:arrow-top-right-on-square"
            />
          </a>
        </div>
      </div>
    </div>
    <div v-if="memberContacts.length">
      <p class="mb-4">
        {{ sharedNumberWithContact }}
      </p>

      <div class="space-y-4">
        <div
          v-for="(contact, id) in memberContacts"
          :key="id"
          class="patient-contact-wrapper bg-nash-neutral000"
          :class="{
            editing: editingId === contact.entityId,
          }"
        >
          <div
            v-if="!editingId || editingId !== contact.entityId"
            class="flex flex-row justify-between w-full items-center"
          >
            <div class="flex flex-row items-center">
              <div lass="flex flex-col mr-2">
                <p class="font-bold">
                  {{
                    nameEntityIdMaps[contact.entityId] ?? 'Unknown Contact Name'
                  }}
                </p>
              </div>
            </div>
            <LegacyTDropdown
              icon="meatball"
              model-value=""
              size="sm"
              position="bottom-left"
              :expanded="false"
              :unmask="true"
              :show-elements-selected="true"
              :options="memberContactActions"
              @update:model-value="triggerOptions($event, contact)"
            />
          </div>
          <div v-else-if="editingId && editingId === contact.entityId">
            <OtherContactForm
              :contact="getContactFromStore(contact.entityId, contacts ?? {})"
              @submit="submitContact"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 space-x-4 flex justify-end">
      <TMQuaternaryButton
        name="cancel"
        label="Cancel"
        @click="exitCreateOrEdit"
      />
      <TMPrimaryButton
        v-if="wasUnknownNumber"
        class="h-12"
        icon="refresh"
        name="sync-and-save-number"
        label="Sync and Save Number"
        @click="submit"
      />
      <TMDangerButton
        v-else
        name="confirm-shared-number"
        label="Confirm Shared Number"
        @click="submit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMDangerButton from '@nashville/button/TMDangerButton.vue'
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent, PropType } from 'vue'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'
import {
  MapEntityPhoneNumber,
  PatientPhoneNumberForm,
} from '@/legacy/types/entities/phoneNumbers'
import { ContactsFormData } from '@/legacy/types/patients/contacts'
import OtherContactForm from '../../../forms/OtherContactForm.vue'
import setup from './controller'

export default defineComponent({
  components: {
    OtherContactForm,
    TMDangerButton,
    LegacyTDropdown,
    TMTertiaryButton,
    TMQuaternaryButton,
    TMPrimaryButton,
  },
  props: {
    data: {
      type: Array as PropType<MapEntityPhoneNumber[]>,
      required: true,
    },
    savedFormData: {
      type: Object as PropType<PatientPhoneNumberForm | ContactsFormData>,
      required: true,
    },
    wasUnknownNumber: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['exitCreateOrEdit', 'submit'],
  setup,
})
</script>
