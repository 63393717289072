import apiStore from '@/legacy/store/modules/apiBuilder'
import { PatientExternalLink } from './types'

export const useExternalLinksApi = apiStore<PatientExternalLink>(
  'externalLinksApi',
  '/api/patients/external_links',
  {
    transformData: (d: { data: PatientExternalLink[] }) => d,
  }
)
