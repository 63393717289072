<template>
  <div>
    <!-- Manage Diagnoses -->
    <TModal
      :is-visible="isVisible && !isAddModalOpen"
      title="Manage Diagnoses"
      :size="ModalSize.LG"
      :flex="true"
      :allow-overflow="true"
      @close="close"
    >
      <div class="flex justify-between items-center">
        <TTab :tabs="tabs" :active="activeTab" @select="selectTab" />
        <LegacyTButton
          value="+ Add"
          :size="ButtonSize.SM"
          @click="isAddModalOpen = true"
        />
      </div>
      <div class="overflow-y-auto h-full flex flex-col gap-4">
        <div
          v-for="diagnosis in filteredDiagnoses"
          :key="diagnosis.diagnosisId"
          class="diagnosis-wrapper"
          :class="diagnosis.diagnosisId === editingId ? 'added' : ''"
        >
          <div class="flex justify-between items-center">
            <div>
              <div class="flex flex-col">
                <h5>
                  {{ getDiagnosisDisplayName(diagnosis.diagnosisRefId) }}
                </h5>
                <div
                  v-if="diagnosis.diagnosisId !== editingId"
                  class="flex w-full ml-0 text-sm mt-2"
                >
                  {{ dxListDetails(diagnosis) }}
                </div>
                <div
                  v-else-if="diagnosis.diagnosisId === editingId"
                  class="flex gap-3 text-nash-neutral600"
                >
                  <TDropdown
                    class="bg-nash-neutral000 min-w-fit w-1/3"
                    name="diagnosis-type"
                    :options="typeOptions"
                    option-label="label"
                    option-value="value"
                    :model-value="dxType"
                    @update:model-value="setType($event)"
                  />
                  <TDropdown
                    class="bg-nash-neutral000 min-w-fit w-1/3"
                    placeholder="Stage"
                    name="diagnosis-stage"
                    :options="stageOptions"
                    option-label="label"
                    option-value="value"
                    :model-value="dxStage"
                    @update:model-value="setStage($event)"
                  />

                  <TDatePicker
                    placeholder="Diagnosis date"
                    name="diagnosis-date"
                    :max-date="today"
                    :is-datetime="false"
                    :hug-content="true"
                    :model-value="diagnosisDate ?? undefined"
                    @update:model-value="setDiagnosisDate($event)"
                  />
                </div>
              </div>
            </div>
            <div class="flex">
              <TOIconDropdown
                v-if="diagnosis.diagnosisId !== editingId"
                icon="meatball"
                :options="diagnosisActions(diagnosis)"
                @change="triggerDiagnosisOptions($event.value, diagnosis)"
              />
              <TMSecondaryButton
                v-else-if="
                  diagnosis.diagnosisId === editingId && editingId !== null
                "
                icon="check"
                class="w-10 h-10"
                @click="updateSave"
              />
            </div>
          </div>
        </div>
      </div>
    </TModal>
    <TModal
      :is-visible="!!confirmMarkDiagnosis"
      :size="ModalSize.MD"
      @close="confirmMarkDiagnosis = null"
    >
      <div class="flex justify-between items-center -m-2 mb-4">
        <div class="flex items-center">
          <LegacyTButton
            name="back-add-diagnosis"
            icon="chevronLeft"
            type="blackAndWhite"
            :size="ButtonSize.MD"
            @click="confirmMarkDiagnosis = null"
          />
          <h2 class="mt-1">Mark diagnosis as Inaccurate</h2>
        </div>
        <LegacyTButton
          name="close-add-diagnosis"
          icon="close"
          inline
          type="blackAndWhite"
          :size="ButtonSize.MD"
          @click="confirmMarkDiagnosis = null"
        />
      </div>
      <p>Are you sure you want to mark this diagnosis as inaccurate?</p>
      <div class="diagnosis-wrapper mb-4">
        <h5>
          {{
            getDiagnosisDisplayName(confirmMarkDiagnosis?.diagnosisRefId ?? '')
          }}
        </h5>
      </div>
      <template #actions>
        <LegacyTButton
          type="Tertiary"
          value="Cancel"
          @click="confirmMarkDiagnosis = null"
        />
        <LegacyTButton
          value="Mark as Inaccurate"
          type="danger"
          @click="markInaccurate"
        />
      </template>
    </TModal>
    <AddDiagnosesModal
      :is-visible="isAddModalOpen"
      @close="close"
      @back="isAddModalOpen = false"
      @cancel="close"
      @save="saveDiagnoses"
    />
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TTab from '@nashville/tab/TTab.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import { uniqBy } from 'lodash'
import filter from 'lodash/filter'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  sqlDateToFormattedDate,
  sqlDateToLuxonDateTime,
} from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import { safeLookup } from '@/legacy/libs/lookup'
import { compareNullableStrings } from '@/legacy/libs/string'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import { useDiagnosisRefApi } from '@/legacy/store/modules/diagnosesReferences'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  useDiagnosesApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { today } from '@/legacy/types/global/dates'

import { NotificationType } from '@/legacy/types/notifications'
import { Diagnosis, Stage, DxType } from '@/legacy/types/patients/diagnoses'
import AddDiagnosesModal from './AddDiagnosesModal.vue'

export default defineComponent({
  components: {
    TModal,
    LegacyTButton,
    TTab,
    TOIconDropdown,
    TDropdown,
    AddDiagnosesModal,
    TMSecondaryButton,
    TDatePicker,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['markAccuracy', 'close', 'refresh', 'save'],
  setup(props, context) {
    const isAddModalOpen = ref(false)
    const editingId = ref<any>(null)
    const tabs = ref<{ label: string; value: string }[]>([
      { label: 'All Active', value: 'active' },
      { label: 'From Claims', value: 'payer' },
      { label: 'Manually Added', value: 'thymebox' },
      { label: 'Marked Inaccurate', value: 'inaccurate' },
    ])
    const activeTab = ref(tabs.value[0]?.value)
    const selectTab = (tab: Tab) => (activeTab.value = tab.value)
    const diagnosisUpdateInfo = ref<Partial<Diagnosis>>({})
    const confirmMarkDiagnosis = ref<Diagnosis | null>(null)
    const { data: diagnoses } = storeToRefs(useDiagnosesApi())
    const { data: diagnosisCatalog } = storeToRefs(useDiagnosisRefApi())
    const diagnosisDate = ref<Date | null>(null)
    const dxStage = ref<string | null>(null)
    const dxType = ref<string | null>(null)
    const route = useRoute()
    const typeOptions = [
      { label: 'Primary', value: DxType.PRIMARY },
      { label: 'Secondary', value: DxType.SECONDARY },
    ]
    const stageOptions = [
      { label: Stage.stage_0, value: 'stage_0' },
      { label: Stage.stage_i, value: 'stage_i' },
      { label: Stage.stage_ii, value: 'stage_ii' },
      { label: Stage.stage_iii, value: 'stage_iii' },
      { label: Stage.stage_iv, value: 'stage_iv' },
    ]

    const filteredDiagnoses = computed(() => {
      let retDiagnoses: Diagnosis[] = []
      switch (activeTab.value) {
        case 'active':
          retDiagnoses = filter(
            diagnoses.value,
            ({ isInaccurate }) => !isInaccurate
          )
          break
        case 'payer':
          retDiagnoses = filter(
            diagnoses.value,
            ({ source, isInaccurate }) => !isInaccurate && source === 'payer'
          )
          break
        case 'thymebox':
          retDiagnoses = filter(
            diagnoses.value,
            ({ source, isInaccurate }) => !isInaccurate && source === 'thymebox'
          )
          break
        case 'inaccurate':
          retDiagnoses = filter(
            diagnoses.value,
            ({ isInaccurate }) => isInaccurate
          )
          break
      }
      const sortedDx = Object.values(retDiagnoses ?? {}).sort(
        (a, b) =>
          (a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1) ||
          // Sort dates in descending order (most recent first), with dateless
          // items last.
          -compareNullableStrings(a.diagnosisDate, b.diagnosisDate, false)
      )
      // remove duplicate claims
      return uniqBy(sortedDx, (dx) => dx.diagnosisRefId)
    })

    /**
     *
     * @param stageEnum
     */
    function readableStage(stageEnum: string) {
      return lookupEnum(Stage, stageEnum)
    }

    /**
     *
     * @param dx
     */
    function dxListDetails(dx: Diagnosis) {
      const details = []
      if (dx.isPrimary !== null) {
        details.push(dx.isPrimary ? 'Primary' : 'Secondary')
      }
      if (dx.stage) {
        details.push(`Stage ${readableStage(dx.stage)}`)
      }
      if (dx.diagnosisDate) {
        details.push(`Diagnosed on ${sqlDateToFormattedDate(dx.diagnosisDate)}`)
      }
      return details.join(' · ')
    }

    const diagnosisActions = (diagnosis: Diagnosis) =>
      diagnosis.isInaccurate
        ? [{ value: 'accurate', label: 'Restore' }]
        : [
            { value: 'inaccurate', label: 'Mark Inaccurate' },
            { value: 'edit', label: 'Edit' },
          ]

    const getDiagnosisDisplayName = (diagnosisId: string) =>
      safeLookup(diagnosisId, diagnosisCatalog.value)?.description

    const triggerDiagnosisOptions = (value: string, diagnosis: Diagnosis) => {
      if (value === 'inaccurate') {
        confirmMarkDiagnosis.value = diagnosis
      } else if (value === 'accurate') {
        context.emit('markAccuracy', {
          ...diagnosis,
          isInaccurate: false,
        })
      }
      if (value === 'edit') {
        editingId.value = diagnosis.diagnosisId
        dxStage.value = diagnosis.stage
        dxType.value = diagnosis.isPrimary.toString()
        diagnosisDate.value = diagnosis.diagnosisDate
          ? sqlDateToLuxonDateTime(diagnosis.diagnosisDate)?.toJSDate()
          : today
      }
    }
    const markInaccurate = () => {
      context.emit('markAccuracy', {
        ...confirmMarkDiagnosis.value,
        isInaccurate: true,
      })
      confirmMarkDiagnosis.value = null
    }

    const saveDiagnoses = (addedDiagnosisInfo: string[]) => {
      context.emit('save', addedDiagnosisInfo)
      isAddModalOpen.value = false
      resetAll()
    }

    const close = () => {
      isAddModalOpen.value = false
      context.emit('close')
      resetAll()
    }

    const resetAll = () => {
      editingId.value = null
      dxStage.value = null
      dxType.value = null
      diagnosisUpdateInfo.value = {}
      diagnosisDate.value = null
    }

    const updateSave = async () => {
      if (diagnosisUpdateInfo.value) {
        try {
          await useDiagnosesApi().partialUpdate({
            body: diagnosisUpdateInfo.value,
            ids: [editingId.value],
          })
          void usePatientStore().fetchDiagnoses(
            route.params.patientId as string
          )
          useNotificationStore().setNotification({
            message: 'Successfully saved diagnosis',
            type: NotificationType.SUCCESS,
          })
        } catch (err) {
          useNotificationStore().setNotification({
            message: 'Failed to save diagnosis.',
            type: NotificationType.DANGER,
          })
        }
      }

      resetAll()
    }

    /**
     *
     * @param val
     */
    function setType(val: string) {
      dxType.value = val
      diagnosisUpdateInfo.value.isPrimary =
        dxType.value === 'true' ? true : false
    }

    /**
     *
     * @param val
     */
    function setStage(val: string) {
      dxStage.value = val
      diagnosisUpdateInfo.value.stage = dxStage.value
    }

    /**
     * Set datetime for diagnosis date
     * @param selectedDate
     */
    function setDiagnosisDate(selectedDate: Date | null) {
      diagnosisDate.value = selectedDate
      if (diagnosisDate.value) {
        diagnosisUpdateInfo.value.diagnosisDate = (
          DateTime.fromJSDate(diagnosisDate.value).toSQLDate() as string
        ).toString()
      } else {
        diagnosisUpdateInfo.value.diagnosisDate = undefined
      }
    }

    const refresh = () => context.emit('refresh')

    return {
      ButtonSize,
      ModalSize,
      isAddModalOpen,
      activeTab,
      confirmMarkDiagnosis,
      filteredDiagnoses,
      tabs,
      getDiagnosisDisplayName,
      diagnosisActions,
      markInaccurate,
      selectTab,
      close,
      dxListDetails,
      refresh,
      triggerDiagnosisOptions,
      readableStage,
      saveDiagnoses,
      setType,
      setStage,
      setDiagnosisDate,
      updateSave,
      editingId,
      dxType,
      dxStage,
      diagnosisDate,
      typeOptions,
      stageOptions,
      today,
    }
  },
})
</script>

<style lang="scss">
.diagnosis-wrapper {
  @apply border border-nash-neutral400 rounded-lg py-6 px-4;

  &.added {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}
.selected {
  @apply bg-nash-purple100 border-nash-purple400;
}
bg-nash-purple100 border-nash-purple400 .svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
