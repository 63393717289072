export enum TierLevel {
  COMPLEX = 'Complex',
  STANDARD = 'Standard',
  ESSENTIAL = 'Essential',
}

const _HIGH_ACUITY_THRESHOLD = 49
const _MEDIUM_ACUITY_THRESHOLD = 25
export enum AcuityThreshold {
  High = _HIGH_ACUITY_THRESHOLD,
  Medium = _MEDIUM_ACUITY_THRESHOLD,
}
