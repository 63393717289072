import { datadogLogs } from '@datadog/browser-logs'
import { DatadogLogsConfig } from './config'
import type { LogsInitConfiguration } from '@datadog/browser-logs'

/**
 * Initializes the browser logs client.
 * @param config
 * @param options
 */
export function initDatadogLogs(
  config: DatadogLogsConfig,
  options?: Omit<LogsInitConfiguration, keyof DatadogLogsConfig>
) {
  datadogLogs.init({
    ...config,
    ...(options ? options : {}),
  })
}
