import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useManageTransitionsOfCareApi } from '../store'
import { AddOrEditTransitionsOfCareForm } from '../types'

/**
 *
 * @param transitionsOfCareId
 * @param dirtyData
 */
export async function updateCarePlanTransitionsOfCare(
  transitionsOfCareId: string,
  dirtyData: AddOrEditTransitionsOfCareForm
) {
  let upserted
  try {
    upserted = await useManageTransitionsOfCareApi().partialUpdate({
      ids: [transitionsOfCareId],
      body: dirtyData,
      metaOptions: { bubbleErrorThrow: true },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update transitions of care.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated transitions of care.',
    type: NotificationType.SUCCESS,
  })

  const tocData = useManageTransitionsOfCareApi().data
  if (
    transitionsOfCareId?.length &&
    tocData?.[transitionsOfCareId as keyof typeof tocData]
  ) {
    tocData[transitionsOfCareId] = upserted
  }

  return upserted
}

/**
 *
 * @param patientId
 * @param dirtyData
 */
export async function createCarePlanTransitionsOfCare(
  patientId: string,
  dirtyData: AddOrEditTransitionsOfCareForm
) {
  let upserted
  try {
    upserted = await useManageTransitionsOfCareApi().create({
      body: { patientId: patientId, ...dirtyData },
      metaOptions: { bubbleErrorThrow: true },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to create transitions of care.',
      type: NotificationType.DANGER,
    })
    return null
  }
  useNotificationStore().setNotification({
    message: 'Successfully created transitions of care.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}
