import { ColumnOptions, TablePadding } from '@thyme/nashville/src/types/tables'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeWithTime } from '@/legacy/libs/format'
import { useAppointmentsApi } from '@/legacy/store/modules/appointments'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { Appointment } from '@/legacy/types/patients/appointments'

/**
 *
 */
export default function () {
  const route = useRoute()
  const { patient } = storeToRefs(usePatientStore())

  const columns: ColumnOptions[] = [
    {
      field: 'date',
      header: 'Date',
      sortable: true,
      style: { 'flex-wrap': 'wrap' },
      display: (datetime): string => showTimeInMemberTimezone(datetime),
      truncateLength: 400,
    },
    {
      field: 'provider',
      header: 'Provider',
      sortable: true,
      style: { 'flex-wrap': 'wrap' },
      truncateLength: 400,
    },
    {
      field: 'appointmentType',
      header: 'Type',
      sortable: true,
      style: { 'flex-wrap': 'wrap' },
      truncateLength: 400,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      style: { 'flex-wrap': 'wrap' },
      truncateLength: 200,
    },
    {
      field: 'visitNoteId',
      header: 'Documentation',
      linkFn: (_: any, row: Appointment) => [
        {
          path: `/patient/${params.value.patient_id}/`,
          query: { visitNoteId: row.visitNoteId },
        },
        {
          path: `/patient/${params.value.patient_id}/`,
          query: { visitNoteId: row.visitNoteId, isVisitSummary: true },
        },
        {
          path: `/patient/${params.value.patient_id}/`,
          query: { referralIds: row.referralIds },
        },
      ],
      style: { 'flex-wrap': 'wrap' },
    },
  ]

  const params = computed(() => ({
    patient_id: route.params.patientId,
  }))

  /**
   * return formatted date and time that reflects
   * the member's timezone
   * @param datetime
   */
  function showTimeInMemberTimezone(datetime: string) {
    const formattedDatetime = formatDateTimeWithTime(stringToDateTime(datetime))
    const date = new Date(formattedDatetime)
    if (patient.value?.timezone) {
      const time = date.toLocaleTimeString('en-US', {
        timeZone: patient.value?.timezone,
        hour: '2-digit',
        minute: '2-digit',
      })
      const day = formattedDatetime.split(',')[0]
      return `${day}, ${time}`
    }
    return formattedDatetime
  }

  return {
    columns,
    patient,
    useAppointmentsApi,
    TablePadding,
    params,
    showTimeInMemberTimezone,
  }
}
