import { defineStore } from 'pinia'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { apiMutations, initialApiState } from '@/legacy/types/api/apiBuilder'
import {
  Config,
  ConfigState,
  CONFIG_FILE_NAME,
} from '@/legacy/types/api/config'
import { NotificationType } from '@/legacy/types/notifications'
import { ProfileState } from '@/legacy/types/profile'

const initialState = (): ConfigState => ({
  ...initialApiState(),
  configVals: null,
  rumClient: null,
  logsClient: null,
})

/**
 *
 * @param configVals
 */
function transform(configVals: Config | null): Partial<ConfigState> {
  return { configVals }
}

export const useConfigStore = defineStore('config', {
  state: initialState,
  getters: {
    _isNonProd: (state) =>
      !!state.configVals && state.configVals.env !== 'prod',
    shouldShowEnvironmentBanner(): boolean {
      return this._isNonProd
    },
    shouldAllowApiUrlSwitch: (state) => state.configVals?.env === 'dev',
  },
  actions: {
    ...apiMutations<ProfileState, Config>(transform),
    async get() {
      this.loading()
      let response
      try {
        response = await fetch(`/${CONFIG_FILE_NAME}`)
      } catch (err) {
        console.error(err)
        if (err instanceof Error) {
          this.setError(err)
        }

        useNotificationStore().setNotification({
          message: 'Failed to load configuration.',
          type: NotificationType.DANGER,
        })
        return
      }

      const config = await response.json()
      this.success(config)
      if (import.meta.env.VITE_API_URL) {
        this.setApiUrl(import.meta.env.VITE_API_URL)
      }
    },

    setApiUrl(apiUrl: string) {
      if (this.configVals) {
        this.success({ ...this.configVals, apiUrl })
        console.debug(`Api URL set to: ${apiUrl}`)
      } else {
        console.error('Cannot set API url without config loaded')
      }
    },
  },
})
