import { PropType } from 'vue'
import { object, string, InferType, boolean, array } from 'yup'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

export const schema = object({
  ecogStatus: string().nullable(),
  iadlsInability: array().of(string().required()).nullable(),
  dmeAtHome: array().of(string().required()).nullable(),
  unableToWalkTwoBlocksOrAFlightOfStairs: boolean().nullable(),
  adlsInability: array().of(string().required()).nullable(),
  unableToLeaveHomeOtherThanMedicalAppts: boolean().nullable(),
  proceduralSupport: array().of(string().required()).nullable(),
  additionalContext: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
})

export type EditPhysicalFunctionAssessmentForm = InferType<typeof schema>

export const editPhysicalFunctionAssessmentProps = {
  patientId: {
    type: String,
    required: true,
  },
  physicalFunctionAssessmentId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditPhysicalFunctionAssessmentForm>>,
    required: true,
  },
} as const
