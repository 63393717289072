import apiStore from '@/legacy/store/modules/apiBuilder'
import { PreferredContactTime } from '@/legacy/types/patients/contactTimes'

export const useContactTimesApi = apiStore<PreferredContactTime>(
  'contactTimesApi',
  '/api/patients/contact_preferences/times',
  {
    transformData: (d: { data: PreferredContactTime[] }) => d,
  }
)
