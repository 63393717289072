import { defineStore } from 'pinia'

import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { NotificationType } from '@/legacy/types/notifications'
import {
  ComorbidityReference,
  ComorbidityRefsState,
} from '@/legacy/types/patients/comorbidities'
import { useNotificationStore } from './notification'

/**
 *
 * @param data
 * @param total
 * @param useModalApi
 */
function transformComorbidityRefs(
  data: ComorbidityReference[],
  total: number,
  useModalApi = false
): Partial<ComorbidityRefsState> {
  const comorbidityRefApi = useModalApi
    ? useModalComorbidityRefApi()
    : useComorbidityRefApi()
  const currentTemplatesMapping = comorbidityRefApi.data ?? {}
  const newDataMap = toIdMap(data, 'comorbidityRefId')
  const newData = Object.assign({}, currentTemplatesMapping, newDataMap)
  const newVals = Object.values(newData)

  return {
    queryMetadata: { total },
    ...idMapTransform({}, 'data', 'comorbidityRefId', newVals),
    ...idMapTransform({}, 'idMap', 'comorbidityRefId', newVals),
  }
}

export const useComorbidityRefStore = defineStore('comorbidityReferences', {
  state: () => ({}),
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} task(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} comorbidity reference: ${error}`,
      })
    },

    async fetchAllComorbidityRefs(filter_comorbidity_ref_ids: string[] = []) {
      await useComorbidityRefApi().listAll({
        params: { filter_comorbidity_ref_ids: filter_comorbidity_ref_ids },
      })
    },
  },
})

export const useComorbidityRefApi = apiStore<
  ComorbidityReference,
  IdMap<ComorbidityReference>
>('comorbidityReferenceApi', '/api/comorbidities/reference', {
  transformData: (d: {
    data: ComorbidityReference[]
    queryMetadata: { total: number }
  }) => transformComorbidityRefs(d.data, d.queryMetadata.total),
})

export const useModalComorbidityRefApi = apiStore<
  ComorbidityReference,
  IdMap<ComorbidityReference>
>('modalComorbidityReferenceApi', '/api/comorbidities/reference', {
  transformData: (d: {
    data: ComorbidityReference[]
    queryMetadata: { total: number }
  }) => transformComorbidityRefs(d.data, d.queryMetadata.total, true),
})
