/**
 *
 * @param dischargeDx
 * @param dischargeLocation
 */
export function formatTocMetadataString(
  dischargeDx: string | null,
  dischargeLocation: string | null
) {
  if (!dischargeDx && !dischargeLocation) {
    return null
  }
  const metadata = []
  if (dischargeDx) {
    metadata.push(dischargeDx)
  }
  if (dischargeLocation) {
    metadata.push(
      `Location is ${dischargeLocation.toLowerCase().replace(/_/g, ' ')}`
    )
  }
  return metadata.join(' • ')
}
