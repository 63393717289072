import apiStore from '@/legacy/store/modules/apiBuilder'
import { HolidayResponse, Holiday } from '@/legacy/types/holidays'

// Holiday does not have a single PK, so rather than use an IdMap<Holiday> as the
// plural state type we store the default Holiday[]
export const useHolidaysApi = apiStore<Holiday>(
  'holidaysApi',
  '/api/holidays',
  {
    transformData: (d: HolidayResponse) => ({ data: d.data }),
  }
)
