<template>
  <div class="space-y-2">
    <h3 v-if="subtaskStack.length" class="mt-4">
      <span class="mr-3">Queued Subtasks</span>
    </h3>
    <div
      v-for="subtask in subtaskStack"
      :key="subtask.subtaskId"
      class="space-y-2 border py-2 px-3 w-full rounded"
    >
      <div class="flex flex-col mr-2 w-full">
        <div class="cursor-pointer" @click="goToSubtask(subtask.subtaskId)">
          <b>{{
            subtask.customSubject ? `${subtask.title}: ` : subtask.title
          }}</b>
          <p v-if="subtask.customSubject" class="inline">
            {{ subtask.customSubject }}
          </p>
        </div>
        <SubtaskRow
          :subtask="subtask"
          :display-type="SubtaskRowDisplayType.SIDEBAR_QUEUE_STACK"
          :is-overdue="isSubtaskOverdue(subtask)"
          @callback-fn="fetchSubtaskStacks"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
        />
      </div>
    </div>
    <SubtaskReattemptModal
      :subtask="(selectedSubtask as Subtask)"
      :is-open="showReattemptModal"
      @close="closeReattemptModal"
      @callback-fn="fetchSubtaskStacks"
    />
    <SubtaskSubStatusModal
      v-if="showSurfacedStatusReason && subtaskStatus"
      :subtask="(selectedSubtask as Subtask)"
      :status="subtaskStatus"
      :is-open="showSubStatusModal"
      @close="showSubStatusModal = false"
      @callback-fn="fetchSubtaskStacks"
    />
  </div>
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { isSubtaskOverdue } from '@/legacy/components/patient/pathways/lib/subtask'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import SubtaskSubStatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import { dateTimeToString, maxDateWithWeekends } from '@/legacy/libs/date'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useAssignedSubtaskStacksApi } from '@/legacy/store/modules/subtasks'
import { today } from '@/legacy/types/global/dates'
import {
  SubtaskRowDisplayType,
  Subtask,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'
import { queueSortBy } from '@/legacy/types/reminders'
import SubtaskReattemptModal from './SubtaskReattemptModal.vue'

// CONST
const STACK_ADDITIONAL_DAYS = 11

export default defineComponent({
  components: { SubtaskSubStatusModal, SubtaskReattemptModal, SubtaskRow },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const { selfEntity } = storeToRefs(useProfileStore())

    const showReattemptModal = ref(false)
    const selectedSubtask = ref<Subtask | null>(null)
    const showSubStatusModal = ref(false)
    const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
    const subtaskStatus = ref<SubtaskStatus | null>(null)
    const stack = ref<Subtask[] | null>(null)

    /**
     * Function to hide/close reattempt modal
     */
    function closeReattemptModal() {
      showReattemptModal.value = false
      selectedSubtask.value = null
    }
    /**
     * Function to show/open reattempt modal
     * @param subtask
     */
    function openReattemptModal(subtask: Subtask) {
      selectedSubtask.value = subtask
      showReattemptModal.value = true
    }

    /**
     * Function to show/open SubStatusModal modal
     * @param subtask
     * @param status
     */
    function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
      showSubStatusModal.value = true
      subtaskStatus.value = status
      selectedSubtask.value = subtask
    }

    const patientId = computed(() => `${route.params.patientId ?? ''}`)
    const querySubtaskId = computed(() => `${route.query.subtaskId ?? ''}`)

    const goToSubtask = (subtaskId: string) => {
      void router.push({
        query: { subtaskId },
      })
    }

    // Note: we are filtering out any subtask in the stack
    // that is currently open in the subtask sidebar as it is
    // duplicative.
    const subtaskStack = computed(() =>
      Object.values(stack.value ?? {}).filter(
        (subtask: Subtask) =>
          subtask.memberId === patientId.value &&
          subtask.subtaskId !== querySubtaskId.value
      )
    )

    /**
     *
     * Fetch subtask stacks
     */
    async function fetchSubtaskStacks() {
      const dueBefore = maxDateWithWeekends(today, STACK_ADDITIONAL_DAYS, false)
      const results = await useAssignedSubtaskStacksApi().list({
        params: {
          page_length: 5,
          sort_by: queueSortBy,
          filter_due_before: dateTimeToString(
            DateTime.fromJSDate(dueBefore).set({
              hour: 0,
              minute: 0,
              second: 0,
            })
          ),
          filter_staff_ids: [selfEntity.value?.entityId],
          filter_patient_ids: [patientId.value],
          limit_per_member: 5,
          filter_subtask_status: [
            SubtaskStatus.IN_PROGRESS,
            SubtaskStatus.OPEN_ASSIGNED,
          ],
        },
      })

      if (results && results.data) {
        stack.value = results.data
      }
    }

    onMounted(async () => {
      await fetchSubtaskStacks()
    })

    return {
      selectedSubtask,
      openReattemptModal,
      showReattemptModal,
      closeReattemptModal,
      openSubStatusModal,
      showSubStatusModal,
      showSurfacedStatusReason,
      subtaskStatus,
      fetchSubtaskStacks,
      SubtaskRowDisplayType,
      isSubtaskOverdue,
      goToSubtask,
      subtaskStack,
    }
  },
})
</script>
