<template>
  <div
    class="cursor-pointer pathway-card mb-0"
    @click="() => goToPathway(task.pathwayIds[0])"
  >
    <h5 class="card-header-title">{{ task.title }}</h5>
    <div class="flex w-full text-sm font-normal ml-4">
      {{ completedSubtasksNum }}/{{ subtaskIdsNum }} Subtasks
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { Task } from '@/legacy/types/pathways/tasks'
import setup from './controller'

export default defineComponent({
  components: {},
  props: {
    task: {
      type: Object as PropType<Task>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup,
})
</script>

<style lang="scss">
.pathway-card {
  @apply border rounded-lg w-full relative bg-nash-neutral000 border-solid border-nash-neutral300 mb-3 ml-3;
}
</style>
