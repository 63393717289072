import { IdMap } from '@/legacy/types/api/store'
import { UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

import {
  OutcomeTaskMapping,
  Outcome,
  OutcomeReference,
} from '@/legacy/types/outcomes'
import {
  TaskStatus,
  PathwayTemplate,
  StatusReason,
} from '@/legacy/types/pathways/pathways'

import {
  Subtask,
  SubtaskPriority,
  SubtaskTemplate,
} from '@/legacy/types/pathways/subtasks'
import { CommonTemplateBase, StatusReasonMetadata } from './common'

// TYPES --------------------
export type GetTasksParams = {
  filter_patient_ids: UUID[] | null
  filter_task_ids?: UUID[] | null
  page_length?: number | null
  page_number?: number | null
  filter_free_text?: string | null
}

export type CreateTaskInfo = {
  variantId: string
  dueDatetime: string
  priority?: SubtaskPriority
  customSubject?: string | null
}

export type TaskPayload = {
  outcomeNote: string
  outcomeQuotes: string
  status: TaskStatus
  goalIds?: string[]
}

export type MapTaskSubtask = {
  mappingId: UUID
  mappingTemplateId: UUID
  subtaskId: UUID
  taskId: UUID
  sortNumber: number
}

export type MapTaskSubtaskTemplate = {
  mappingTemplateId: UUID
  subtaskVariantId: UUID
  taskVariantId: UUID
  sortNumber: number
}

export type ApiTask = {
  description: string | null
  dueDatetime: string
  minStartDatetime: string
  memberId: string
  outcomeNote: string | null
  outcomeQuotes: string | null
  status: string
  statusReason: StatusReason
  statusUpdatedAt: string
  taskId: string
  taskVariantId: string
  title: string
  subtasks?: Subtask[]
  subtaskMaps?: MapTaskSubtask[]
  subtaskIds?: string[]
  outcomes?: Outcome[]
  taskTemplate?: TaskTemplate
  pathwayIds: string[]
} & Timestamps

export type Task = {
  description: string | null
  dueDatetime: string
  minStartDatetime: string
  memberId: string
  outcomeNote: string | null
  outcomeQuotes: string | null
  status: string
  statusReason: StatusReason | null
  statusReasonJson: StatusReasonMetadata | null
  statusUpdatedAt: string
  taskId: string
  taskVariantId: string
  title: string
  generatingTriggerId: UUID | null
  subtasks?: Subtask[]
  subtaskIds?: string[]
  subtaskMaps?: MapTaskSubtask[]
  outcomes?: Outcome[]
  taskTemplate?: TaskTemplate
  pathwayIds: string[]
  goalIds: string[]
} & Timestamps

export type CreateTasksAndLinkCommunicationRequest = {
  tasks: CreateTaskInfo[]
  communicationId: string
}

export type CreateTasksAndLinkCommunicationResponse = {
  tasksCreated: Task[]
  subtasksLinked: Subtask[]
  tasksNotCreated?: Task[]
  subtasksNotLinked?: Subtask[]
}

export type TaskTemplate = {
  baseTemplateId: string
  description: string
  isDeleted?: boolean
  legacyActivityCategoryId: string
  subtaskTemplates?: SubtaskTemplate[]
  subtaskTemplateMaps?: MapTaskSubtaskTemplate[]
  taskKey: string
  taskTemplateId: string
  templateStatus: string
  title: string
  variantId: string
  variantKey: string | null
  possibleOutcomeMappings?: OutcomeTaskMapping[]
  possibleOutcomes?: OutcomeReference[]
  variantDisplay: string
  isManuallyAddable: boolean
} & Timestamps

export type TaskTemplateBase = {
  taskKey: string
  possibleOutcomes?: OutcomeReference[]
  variants?: TaskTemplate[]
} & CommonTemplateBase

// INTERFACES --------------------
export interface TaskState {
  isLoading: boolean
  error: Error | null
  data: IdMap<Task> | null
  isLoaded: boolean
}

export interface TaskTemplateDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<PathwayTemplate> | any
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

// ENUMS --------------------
export enum TaskIds {
  Outcomes = 'outcomes',
}

export enum TaskType {
  Subtask = 'subtasks',
  Task = 'tasks',
}

// CONSTANTS --------------------
export const MAX_NUM_TASKS = 10
