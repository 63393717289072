import apiStore from '@/legacy/store/modules/apiBuilder'
import { Consent } from '@/legacy/types/patients/consents'

export const useConsentsApi = apiStore<Consent>(
  'consentsApi',
  '/api/consents',
  {
    transformData: (d: { data: Consent[] }) => d,
  }
)
