import { UUID } from '../global/base'

// TYPES --------------------
export type CarePlanSummary = {
  carePlanSummaryId: UUID
  patientId: UUID

  // Social Summary
  barriersDescription: string
  livingEnvDescription: string
  supportDescription: string
  additionalSocialContext: string

  // Clinical Summary
  functionalStatus: string
  fallRisk: string
  transitionsOfCare: string
  prognosis: string
  highRiskMedications: string
  additionalClinicalContext: string

  // Offboarding Summary
  reasonOffboarding: string
  reengagementAttemptsDescription: string
}

// ENUMS --------------------
export enum CaseConferenceUpdates {
  highRiskMedications = 'highRiskMedications',
  functionalStatus = 'functionalStatus',
  fallRisk = 'fallRisk',
  transitionsOfCare = 'transitionsOfCare',
  prognosis = 'prognosis',
  additionalClinicalContext = 'additionalClinicalContext',
  barriersDescription = 'barriersDescription',
  livingEnvDescription = 'livingEnvDescription',
  supportDescription = 'supportDescription',
  additionalSocialContext = 'additionalSocialContext',
  reengagementAttemptsDescription = 'reengagementAttemptsDescription',
  reasonOffboarding = 'reasonOffboarding',
}

export enum CaseConferenceStrings {
  highRiskMedications = 'high risk medications',
  functionalStatus = 'functional status',
  fallRisk = 'fall risk',
  transitionsOfCare = 'recent transitions of care',
  prognosis = 'prognosis',
  additionalClinicalContext = 'additional clinical context',
  barriersDescription = 'barriers/needs',
  livingEnvDescription = 'living environment',
  supportDescription = 'support system',
  additionalSocialContextsupportDescription = 'support system',
  additionalSocialContext = 'additional social context',
  reengagementAttemptsDescription = 'reengagement attempts',
  reasonOffboarding = 'reason for offboarding',
}

export enum CaseConferenceMode {
  // views
  NAV_REPORT_VIEW = 'NAV_REPORT_VIEW',
  INTERNAL_VIEW = 'INTERNAL_VIEW',
  // other modes
  EDIT = 'EDIT',
}
