<template>
  <div>
    <AddOrEditTransitionsOfCare
      v-if="showAddOrEditModal"
      :creating="creating"
      :patient-id="patientId"
      :editing-toc="editingToc"
      :initial-data="addOrEditModalInitialData"
      data-testid="add-or-edit-toc-modal"
      @close="clearStates"
      @refetch="refetchTocs"
    />
    <DeleteTransitionOfCare
      v-else-if="deletingToc"
      :deleting-toc="deletingToc"
      @close="clearStates"
      @refetch="refetchTocs"
    />
    <TModal
      v-else
      :flex="true"
      :is-visible="true"
      title="Manage TOCs"
      :allow-overflow="true"
      :size="ModalSize.LG"
      class="toc-modal"
      @close="close"
    >
      <div class="h-full overflow-auto">
        <div class="flex justify-start mb-4">
          <TMSecondaryButton
            label="+ Add TOC"
            name="add-medical-conditions"
            @click="creating = true"
          />
        </div>
        <div>
          <div class="overflow-y-auto flex flex-col space-y-4">
            <div
              v-for="toc in manageTocDisplay"
              :key="toc?.transitionsOfCareId"
              class="toc-wrapper"
            >
              <div class="flex justify-between items-center w-full">
                <div class="flex flex-col overflow-hidden">
                  <div class="space-y-2 flex flex-col">
                    <div class="flex flex-row items-center">
                      <h5>
                        {{ toc.dateDisplay }}
                      </h5>
                    </div>
                    <div v-if="toc.metadataDisplay">
                      {{ toc.metadataDisplay }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <TOIconDropdown
                    icon="meatball"
                    :options="tocActions"
                    @change="triggerTocAction($event.value, toc)"
                  />
                </div>
              </div>
            </div>
            <div v-if="!manageTocDisplay.length">
              No transitions of care documented.
            </div>
          </div>
        </div>
        <div v-if="showMoreTocsButton">
          <TMTertiaryButton
            label="Show More"
            class="pt-2 mb-3"
            icon-pos="right"
            :icon="'chevronDown'"
            @click="loadMore"
          />
        </div>
      </div>
      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="transitions-of-care-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="transitions-of-care-save"
          label="Save"
          @click="close"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import AddOrEditTransitionsOfCare from './AddOrEditTransitionsOfCare/AddOrEditTransitionsOfCare.vue'
import { setup } from './controller'
import DeleteTransitionOfCare from './DeleteTransitionOfCare/DeleteTransitionOfCare.vue'
import { manageTransitionsOfCareProps } from './types'

export default defineComponent({
  components: {
    DeleteTransitionOfCare,
    TOIconDropdown,
    AddOrEditTransitionsOfCare,
    TMTertiaryButton,
    TMPrimaryButton,
    TModal,
    TMSecondaryButton,
  },
  props: manageTransitionsOfCareProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.toc-wrapper {
  @apply flex justify-between items-center;
  @apply py-6 px-4;
  @apply border border-nash-neutral400 rounded-lg;
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
