// ENUMS
export enum TreatmentField {
  TREATMENT = 'treatment',
  SURGERY = 'surgery',
  RADIATION = 'radiation',
}

export enum ManageTabLabel {
  PREVIOUS = 'previous',
  CURRENT = 'current',
}

export enum EditOptions {
  EDIT = 'edit',
  DELETE = 'delete',
}

// CONSTS

export const editOptionsArray = [
  { label: 'Edit', value: EditOptions.EDIT },
  { label: 'Delete', value: EditOptions.DELETE },
]

export const tabLabels = [
  { label: 'Current Cancer', value: ManageTabLabel.CURRENT },
  { label: 'Previous Cancer', value: ManageTabLabel.PREVIOUS },
]

export const PAGE_LENGTH = 3
