<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Offboarding Summary"
    :size="ModalSize.MD"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5 overflow-scroll">
      <div class="flex flex-col space-y-3">
        <label>Offboarding Reason</label>
        <TDropdown
          class="bg-nash-neutral000"
          name="offboarding-summary-reasons-for-offboarding"
          :model-value="dirtyModel.reasonOffboarding"
          :options="reasonOffboardingOptions"
          option-label="label"
          option-value="value"
          @update:model-value="(v) => (dirtyModel.reasonOffboarding = v)"
        />
        <label>Reengagement Attempts</label>
        <TTextarea
          v-model="dirtyModel.reengagementAttemptsDescription"
          name="offboarding-summary-reengagement-attempts"
          @update:model-value="dirtyModel.reengagementAttemptsDescription"
        />
        <label>Additional Details</label>
        <TTextarea
          v-model="dirtyModel.offboardingAdditionalDetails"
          name="offboarding-summary-additional-details"
          @update:model-value="dirtyModel.offboardingAdditionalDetails"
        />
      </div>
    </div>

    <template #actions>
      <div class="w-full flex flex-row justify-end space-x-4">
        <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
        <TMPrimaryButton label="Save" name="save" @click="save" />
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { PropType, defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { SocialSummary } from '@/legacy/types/patients/socialSummaries'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TTextarea,
    TDropdown,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    patientSocialSummary: {
      type: Object as PropType<SocialSummary | null>,
      required: true,
    },
    patientId: {
      type: String as PropType<string | null>,
      required: true,
    },
  },
  emits: ['close', 'refetchSocialSummary'],
  setup,
})
</script>
