import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { NotificationType } from '@/legacy/types/notifications'
import { Patient } from '@/legacy/types/patients/patients'
import { useDomainApi } from '../stores'
import { formatOverallTierScore } from './format'
import { usePinnedAcuityApi } from './stores'

/**
 *
 * @param patientId
 * @param domains
 */
export async function upsertDomain(patientId: string, domains: string[]) {
  try {
    const response = await useDomainApi().upsert({
      body: {
        patientId: patientId,
        domains: domains,
      },
    })

    return response
  } catch (error) {
    useNotificationStore().setNotification({
      message: 'Error updating domains for Care Plan view',
      type: NotificationType.DANGER,
      error: `${error}`,
    })
  }
}

/**
 * create pinned acuity score
 * @param tierLevel
 * @param patientId
 */
export async function createPinnedScore(tierLevel: string, patientId: string) {
  try {
    await usePinnedAcuityApi().create({
      body: {
        overallScore: formatOverallTierScore(tierLevel),
        patientId: patientId,
        metaOptions: { bubbleErrorThrow: true },
      },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error creating pinned acuity score',
      type: NotificationType.DANGER,
      error: `Error creating pinned acuity score: ${err}`,
    })
  }
}

/**
 * update existing pinned acuity score
 * @param tierLevel
 * @param patientId
 * @param pinnedAcuityScoreId
 */
export async function updatePinnedScore(
  tierLevel: string,
  patientId: string,
  pinnedAcuityScoreId: string
) {
  try {
    await usePinnedAcuityApi().partialUpdate({
      ids: [pinnedAcuityScoreId],
      body: {
        patientId: patientId,
        overallScore: formatOverallTierScore(tierLevel),
        metaOptions: { bubbleErrorThrow: true },
      },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error updating pinned acuity score',
      type: NotificationType.DANGER,
      error: `Error updating pinned acuity score: ${err}`,
    })
  }
}

/**
 * update program or treatment status and treatment intent
 * @param patientId
 * @param changes
 */
export async function updatePatient(
  patientId: string | null | undefined,
  changes: Partial<Patient>
) {
  if (!patientId) {
    return
  }
  try {
    await usePatientsApi().partialUpdate({
      ids: [patientId],
      body: changes,
      metaOptions: { bubbleErrorThrow: true },
    })
    void usePatientStore().patientApiCall(patientId)
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update status.',
      type: NotificationType.DANGER,
      error: `${err}`,
    })
    return
  }
}
