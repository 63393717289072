import random from 'lodash/random'
import startCase from 'lodash/startCase'
import { Charset } from '@/legacy/types/global/strings'

/**
 * Returns a number indicating whether nullable string a comes before, after, or
 * is the same as string b.
 * @param a
 * @param b
 * @param treatNullAsAfter Controls whether null values are treated as before or
 * after non-null values.
 * @returns -1, 0, 1 (sort direction int)
 */
export function compareNullableStrings(
  a: string | undefined | null,
  b: string | undefined | null,
  treatNullAsAfter = true
) {
  // If treating null as "before", flip return value.
  const multiplier = treatNullAsAfter ? 1 : -1

  if (!a && !b) return 0
  if (!a) {
    return 1 * multiplier
  }
  if (!b) {
    return -1 * multiplier
  }
  return a.localeCompare(b)
}

/**
 * Given a string in TitleCase, return the string with the capitalized words separated by a space.
 * @example
 * input:  "ThisStringIsInTitleCase"
 * output: "This String Is In Title Case"
 * @param str string to space
 * @returns spaced string
 */
export function splitTitleCaseWithSpaces(str: string | undefined | null) {
  return str ? startCase(str.replace(/([a-z])([A-Z])/g, '$1 $2')) : undefined
}

/**
 * get a random string
 * @param len length of string returned
 * @param charset charset to use for randomization
 * @returns random string with length "len" from charset "charset"
 */
export function randomStr(
  len: number,
  charset: string = Charset.ASCII_LOWERCASE
) {
  let s = ''
  for (let i = 0; i < len; i++) {
    s += charset.charAt(random(charset.length - 1))
  }
  return s
}

/**
 * coerces falsy (empty, null, undefined) strings to null
 * @param str string to check
 * @returns string or null
 */
export function nullifyIfEmptyString(
  str: string | null | undefined
): null | string {
  // intentional || instead of ?? because we want to filter
  // out empty strings which are falsy but don't trigger ??
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return str || null
}
