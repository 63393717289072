import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useSymptomIdHistoryApi } from '@/legacy/store/modules/symptomIdHistory'
import { SymptomIdHistoryResponse } from '@/legacy/types/pathways/symptomIdHistory'

/**
 * Setup the SymptomsThymeline component
 */
export default function () {
  // Read patientId from the route
  // IMPROVEME MT-3111 - receive patientId in component props instead of route
  const route = useRoute()
  const patientIdFromRoute = computed(() => route.params.patientId as string)

  const symptomIdHistory = ref<SymptomIdHistoryResponse>()

  // IMPROVEME MT-3110 - show loading spinner while waiting for api response
  // instead of blocking component mount
  onBeforeMount(async () => {
    symptomIdHistory.value = await useSymptomIdHistoryApi().list({
      params: {
        patient_id: patientIdFromRoute.value,
      },
    })
  })

  const symptomIdDisplayNames = {
    constipationSeverity: 'Constipation Severity',
    diarrhea: 'Diarrhea Frequency',
    nausea: 'Nausea Frequency',
    nauseaSeverity: 'Nausea Severity',
    pain: 'Pain',
    painInterfere: 'Pain Interference with Daily Activities',
    painSeverity: 'Pain Severity',
    sob: 'Shortness of Breath Frequency',
    sobSeverity: 'Shortness of Breath Severity',
    urinary: 'Pain with Urination Severity',
    vomit: 'Vomiting Frequency',
    vomitSeverity: 'Vomiting Severity',
  }

  return {
    symptomIdHistory,
    symptomIdDisplayNames,
  }
}
