<template>
  <div v-if="visitSummary">
    <div
      class="bg-nash-neutral000 shadow rounded-lg p-5 h-full overflow-y-auto relative"
    >
      <LegacyTButton
        name="close-visit-note-sidebar"
        icon="close"
        inline
        type="blackAndWhite"
        class="absolute top-4 right-2"
        :size="ButtonSize.MD"
        @click="closeSidebar"
      />
      <VisitSummary :visit-summary="visitSummary" :person="person" />
    </div>
  </div>
</template>

<script lang="ts">
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import VisitSummary from '@/legacy/components/patient/visitNote/VisitSummary.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatDateTime,
  formatDateTimeWithTime,
  formatName,
} from '@/legacy/libs/format'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useVisitSummariesApi } from '@/legacy/store/modules/visitNotes'

export default defineComponent({
  components: { LegacyTButton, VisitSummary },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { person } = storeToRefs(usePatientStore())

    const visitNoteId = computed(() => route.query.visitNoteId)

    // IMPROVEME (MT-2675) VisitSummary list being used as retrieve
    const { data: visitSummary } = storeToRefs(useVisitSummariesApi())

    onMounted(() => {
      const id = visitNoteId.value
      if (id) {
        void useVisitSummariesApi().list({ ids: [id.toString()] })
      }
    })

    const closeSidebar = () => {
      void router.push({ query: { visitNoteId: undefined, reason: undefined } })
    }

    return {
      closeSidebar,
      visitSummary,
      person,
      formatDateTime,
      formatName,
      stringToDateTime,
      formatDateTimeWithTime,
      ButtonSize,
    }
  },
})
</script>
