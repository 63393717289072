<template>
  <div
    v-if="!isLoading && dupePatientsList.length > 0"
    id="duplicate-patient-warning"
    class="relative px-5"
  >
    <TIcon
      v-if="dupePatientsList.length > 1"
      class="absolute right-9 top-2 link-pointer"
      :icon="
        showExpandedWarning ? IconifyIcons.chevronUp : IconifyIcons.chevronDown
      "
      @click="toggleExpandWarning"
    />
    <div>
      <TMessage
        name="duplicate-members-warning-message"
        :severity="MessageSeverity.WARN"
        :custom-icon="IconifyIcons.exclamationTriangle"
        :label="getDuplicateMessage(dupePatientsList)"
      >
        <template #extracontent>
          <span v-if="dupePatientsList.length > 1">
            <div id="multi-patient-warning">
              <div v-if="showExpandedWarning" class="relative left-5">
                <ol>
                  <li v-for="pat in dupePatientsList" :key="pat.entityId">
                    <a :href="buildPatientHref(pat)">{{ pat.entityId }}</a>
                  </li>
                </ol>
              </div>
            </div>
          </span>
          <span
            v-if="dupePatientsList.length === 1"
            id="single-patient-warning"
          >
            <a
              v-for="pat in dupePatientsList"
              :key="pat.entityId"
              :href="buildPatientHref(pat)"
              >{{ pat.entityId }}
            </a>
          </span>
        </template>
      </TMessage>
    </div>
  </div>
</template>
<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent, PropType } from 'vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'

import { Person } from '@/legacy/types/entities/people'

import setup from './controller'

export default defineComponent({
  components: { TMessage, TIcon },
  props: {
    patientPerson: {
      type: Object as PropType<Person>,
      required: true,
    },
  },
  setup,
})
</script>
<style>
.link-pointer {
  cursor: pointer;
}
</style>
