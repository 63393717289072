import { Nullable, UUID } from '@/legacy/types/global/base'

// TYPES --------------------
export type TransitionOfCare = {
  transitionOfCareId: UUID
  patientId: UUID
  transitionDate: string
  source: string
  status: string
  facilityType: Nullable<string>
  facilityName: string
  facilityCity: Nullable<string>
  facilityState: Nullable<string>
  facilityNpi: Nullable<string>
  setting: string
  primaryDiagnosisCode: Nullable<string>
  primaryDiagnosisDescription: Nullable<string>
  subsequentDiagnosisCodes: Nullable<string[]>
  dischargedDisposition: Nullable<string>
  dischargeLocation: Nullable<string>
  lengthOfStayDays: Nullable<number>
  attendingProviderName: Nullable<string>
  attendingProviderNpi: Nullable<string>
  recentSnfStayFlag: Nullable<boolean>
  recentInpatientStayFlag: Nullable<boolean>
}

// ENUMS --------------------
export enum TocSettingDisplay {
  unknown = '',
  skilled = 'Skilled Nursing Facility (SNF)',
  inpatient = 'Inpatient',
  observation = 'Observation',
  emergency = 'ED',
}

export enum TocStatusDisplay {
  presented = 'Presentation',
  admitted = 'Admission',
  discharged = 'Discharge',
  transferred = 'Transfer',
  deceased = 'Deceased',
}
