import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useSurgeryApi } from '@/pages/PatientProfile/CarePlans/shared/store'

/**
 *
 * @param surgeryId
 */
export async function deleteSurgery(surgeryId: string) {
  const surgeryApi = useSurgeryApi()
  let deleted
  try {
    deleted = await surgeryApi.delete({
      ids: [surgeryId],
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to delete surgery.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully deleted surgery.',
    type: NotificationType.SUCCESS,
  })

  const surgeryData = surgeryApi.data
  if (surgeryData && surgeryData[surgeryId]) {
    delete surgeryData[surgeryId]
  }
  return deleted
}
