import { IconifyIcons, IconPosition } from '@thyme/nashville/src/types/icons'
import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { storeToRefs } from 'pinia'
import { ref, onBeforeMount, computed } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { NotificationType } from '@/legacy/types/notifications'
import { Patient } from '@/legacy/types/patients/patients'

export const singleDupeWarning = 'Potential duplicate member record found: '
export const multiDupeWarning = 'Potential duplicate member records found'

/**
 * Builds warning message based on number of duplicate patients
 * @param patientsList - array of patients
 */
export function getDuplicateMessage(
  patientsList: Array<Patient>
): string | undefined {
  if (patientsList.length === 1) {
    return singleDupeWarning
  } else if (patientsList.length > 1) {
    return multiDupeWarning
  }
}

/**
 * Builds the patient href based on the patient entity id
 * @param patient
 */
export function buildPatientHref(patient: Patient): string | undefined {
  if (patient) {
    return '/patient/' + patient.entityId + '/'
  }
}

/**
 *
 * @param props
 */
export default function (props: any) {
  const showExpandedWarning = ref(false)
  const { isLoading } = storeToRefs(usePatientsApi())

  const patientsList = ref<Patient[]>([])
  const dupePatientsList = computed<Patient[]>(() =>
    patientsList.value.filter(
      (pat: Patient) => pat.entityId !== props.patientPerson.entityId
    )
  )

  /**
   * function to open/close the warning if multiple dupes are found
   */
  function toggleExpandWarning() {
    showExpandedWarning.value = !showExpandedWarning.value
  }

  /**
   * fetches patients with matching first name, last name, and date of birth
   *
   */
  async function refreshPatientList() {
    const person = props.patientPerson
    try {
      const response = await usePatientsApi().list({
        params: {
          filter_free_text_name: [person.firstName, person.lastName].join(' '),
          filter_free_text_dob: person.dateOfBirth,
        },
      })
      patientsList.value = response.data
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        useNotificationStore().setNotification({
          message: 'Error checking for duplicate patient records.',
          type: NotificationType.DANGER,
        })
      }
    }
  }

  onBeforeMount(async () => {
    await refreshPatientList()
    if (dupePatientsList.value.length > 0) {
      showExpandedWarning.value = true
    }
  })

  return {
    buildPatientHref,
    dupePatientsList,
    getDuplicateMessage,
    IconifyIcons,
    IconPosition,
    isLoading,
    MessageSeverity,
    showExpandedWarning,
    toggleExpandWarning,
  }
}
