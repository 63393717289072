import { datadogRum } from '@datadog/browser-rum'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { NotificationType } from '@/legacy/types/notifications'
import {
  ReportIssueForm,
  ReportIssuePersistence,
  ReportIssueSeverity,
  reportIssueTypesForRouteMap,
} from '@/legacy/types/reporting'
import { reportIssueToSlack } from '../reporting/queries/reportIssueToSlack'

/**
 *
 * Setup function for ReportIssueModal.vue
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const dirtyValues = ref<ReportIssueForm>(emptyForm())

  const selectedIssueType = ref('')
  const route = useRoute()

  const close = () => {
    context.emit('close')
  }

  const persistenceOptions = Object.values(ReportIssuePersistence)

  const severityOptions = Object.values(ReportIssueSeverity)

  const issueTypeOptions = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return reportIssueTypesForRouteMap[route.name!.toString()] ?? ['Other']
  })

  const { selfEntity } = storeToRefs(useProfileStore())

  /**
   * Sends a metric to Datadog for the specific page and issue type,
   * used to track the number of issues reported by users and page if threshold met
   * @param data
   */
  async function reportIssue(data: ReportIssueForm) {
    try {
      datadogRum.addAction('reportIssueFromTB', {
        route: String(route.name),
        issueType: data.issueType,
      })
    } catch (err) {
      console.error('Failed to report issue to Datadog', err)
    }
  }

  /**
   * Sends request body to /reporting/thymebox_issue
   * Which posts to #thymebox-reported-issues Slack channel
   * @param data
   */
  async function sendToSlack(data: ReportIssueForm) {
    try {
      data.route = String(route.name)
      if (data.route === '#patientProfile') {
        data.fullUrl = window.location.href
      } else {
        data.fullUrl = ''
      }

      if (selfEntity.value && selfEntity.value.person) {
        data.reporter = `${selfEntity.value.person.firstName} ${selfEntity.value.person.lastName}`
      }

      await reportIssueToSlack(data)

      useNotificationStore().setNotification({
        message: 'Successfully reported issue',
        type: NotificationType.SUCCESS,
      })
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to report issue',
        type: NotificationType.WARNING,
      })
    }
  }

  /**
   *
   * @param data
   */
  async function submit(data: ReportIssueForm) {
    await reportIssue(data)
    await sendToSlack(data)
    close()
  }

  /**
   *
   * Function called to return intial values for form
   *
   */
  function emptyForm(): ReportIssueForm {
    return {
      route: '',
      issueType: '',
      fullUrl: '',
      description: '',
      persistence: ReportIssuePersistence.SOMETIMES,
      severity: ReportIssueSeverity.STANDARD,
      sendToSlack: true,
      reporter: '',
    }
  }

  return {
    ButtonSize,
    ModalSize,
    persistenceOptions,
    severityOptions,
    selectedIssueType,
    dirtyValues,
    route,
    issueTypeOptions,
    selfEntity,
    submit,
    reportIssue,
    sendToSlack,
    close,
  }
}
