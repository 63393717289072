import { storeToRefs } from 'pinia'
import { ExtractPropTypes, Ref, computed, watch } from 'vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useExternalLinksApi } from './queries'
import {
  PatientExternalLink,
  patientExternalLinksProps,
  PatientExternalLinkTarget,
} from './types'

type PropsType = ExtractPropTypes<typeof patientExternalLinksProps>

/**
 *
 * @param props
 * @param props.patientId
 */
export function setup(props: PropsType): {
  shouldShowLinks: Ref<boolean>
  patientLinks: Ref<
    {
      label: string
      url: string
    }[]
  >
} {
  watch(
    () => props.patientId,
    async () => {
      useExternalLinksApi().reset()
      await useExternalLinksApi().list({
        params: {
          filter_patient_ids: [props.patientId],
        },
      })
    },
    { immediate: true }
  )

  const { data: patientExternalLinks } = storeToRefs(useExternalLinksApi())
  const { showZusProfileLinks } = storeToRefs(useFlagStore())

  /**
   *
   * @param target
   */
  function getLabel(target: PatientExternalLinkTarget) {
    switch (target) {
      case PatientExternalLinkTarget.ONCO_EMR:
        return 'OncoEMR Profile'
      case PatientExternalLinkTarget.ELATION:
        return 'Elation Profile'
      case PatientExternalLinkTarget.CANOPY:
        return 'Canopy Profile'
      case PatientExternalLinkTarget.ZUS:
        return 'Zus Profile'
    }
  }

  const shouldRenderLink = (link: PatientExternalLink) =>
    link.target !== PatientExternalLinkTarget.ZUS || showZusProfileLinks.value

  const linkToViewModel = (link: PatientExternalLink) => ({
    label: getLabel(link.target),
    url: link.url,
  })

  const patientLinks = computed(() => {
    if (!patientExternalLinks.value) {
      return []
    }

    return patientExternalLinks.value
      .filter(shouldRenderLink)
      .map(linkToViewModel)
  })
  const shouldShowLinks = computed(() => !!patientLinks.value.length)

  return {
    shouldShowLinks,
    patientLinks,
  }
}
