import { storeToRefs } from 'pinia'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeWithTime, formatName } from '@/legacy/libs/format'
import { useStaffApi } from '@/legacy/store/modules/staff'

/**
 *
 * @param data
 */
export function formatLastUpdatedStr(data: any) {
  const { data: staff } = storeToRefs(useStaffApi())

  let lastUpdatedAtStr = ''
  let lastUpdatedByStr = ''
  const lastUpdatedAt = data?.updatedAt
  const lastUpdatedBy = data?.updatedBy

  if (lastUpdatedAt) {
    lastUpdatedAtStr = formatDateTimeWithTime(stringToDateTime(lastUpdatedAt))
  }
  if (lastUpdatedBy && staff.value?.[lastUpdatedBy]?.person) {
    const staffPerson = staff.value[lastUpdatedBy].person
    lastUpdatedByStr = formatName(staffPerson?.firstName, staffPerson?.lastName)
  }

  if (lastUpdatedAtStr && lastUpdatedByStr) {
    return `${lastUpdatedAtStr} by ${lastUpdatedByStr}`
  } else if (lastUpdatedAtStr && !lastUpdatedByStr) {
    return `${lastUpdatedAtStr} by Unknown Staff`
  } else if (!lastUpdatedAtStr && lastUpdatedByStr) {
    return `Unknown date/time by ${lastUpdatedByStr}`
  }
  return null
}
