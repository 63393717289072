<template>
  <div class="bg-nash-neutral000">
    <TTable
      ref="table"
      title="Assigned to Me | Enrollment"
      name="MyEnrollmentQueue"
      :rows="10"
      :columns="columns"
      :params="params"
      :rows-per-page="[10, 25]"
      :api-store="useAssignedToMeEnrollmentQueueApiWithScheduledCalls"
      :filters="filters"
      :free-text-filter="true"
      :free-text-options="freeTextOptions"
      free-text-filter-name="freeTextName"
      free-text-placeholder="Search..."
      @data-loaded="getSubtaskAndCommDetailsForPatientStack"
    >
      <template #filterOptions>
        <TDropdown
          :options="freeTextOptions"
          initial-value="freeTextName"
          :show-clear="false"
          class="bg-nash-neutral000"
          option-label="label"
          option-value="value"
        />
      </template>
      <template #column-patient="slotProps">
        <router-link
          :to="slotProps.column.linkFn(slotProps.column, slotProps.row)"
          data-cy="patients-table-name"
        >
          {{ slotProps.column.display(slotProps.row.patient) }}
        </router-link>
      </template>
      <template #column-preferredContactTime="slotProps">
        <div v-if="slotProps.row?.communicationId">
          <a :href="buildCommunicationUrl(slotProps.row)">{{
            slotProps.column.display(null, slotProps.row)
          }}</a>
        </div>
        <div v-else>
          {{ slotProps.column.display(null, slotProps.row) }}
        </div>
      </template>
      <template #column-subtasks="slotProps">
        <div class="space-y-4">
          <div
            v-if="
              !slotProps.column.displayData(slotProps.row) &&
              slotProps.row?.communicationId
            "
          >
            <i>No subtasks associated with this scheduled call.</i>
          </div>
          <div
            v-for="subtask in slotProps.column.displayData(slotProps.row)"
            :key="subtask.subtaskId"
          >
            <SubtaskRow
              class="w-full"
              :is-overdue="isSubtaskOverdue(subtask)"
              :subtask="subtask"
              :display-type="SubtaskRowDisplayType.ENROLLMENT"
              @open-reattempt-modal="openReattemptModal"
              @callback-fn="onSubtaskStatusChanged"
            />
          </div>
        </div>
      </template>
    </TTable>
    <SubtaskReattemptModal
      v-if="selectedReattemptSubtask"
      :subtask="selectedReattemptSubtask"
      :is-open="showReattemptModal"
      @close="closeReattemptModal"
      @callback-fn="getData"
    />
    <SubtaskSubstatusModal
      v-if="
        showSurfacedStatusReason && subtaskStatus && selectedSubStatusSubtask
      "
      :subtask="selectedSubStatusSubtask"
      :status="subtaskStatus"
      :is-open="showSubStatusModal"
      @close="showSubStatusModal = false"
      @callback-fn="getData"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SubtaskReattemptModal from '@/legacy/components/patient/pathways/SubtaskReattemptModal.vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TTable from '@/legacy/nashville/table/TTable.vue'

import setup from './controller'

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TDropdown,
    SubtaskRow,
    SubtaskReattemptModal,
    TTable,
  },
  props: {
    customApiStore: {
      type: Function,
      default: () => true,
    },
    responsibleStaffId: {
      type: String,
      default: null,
    },
  },
  emits: ['subtaskStatusChanged'],
  setup,
})
</script>

<style lang="scss" scoped>
.chip {
  @apply flex gap-1 px-2 mx-2 rounded-full border border-solid cursor-default;
}

.language-chip {
  @apply border-nash-purple200;
}
</style>
