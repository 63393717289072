<template>
  <TModal
    :is-visible="isVisible"
    :flex="true"
    :size="ModalSize.LG"
    :allow-overflow="true"
    @close="close"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <LegacyTButton
          name="back-add-comorbidity"
          icon="chevronLeft"
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="goBack"
        />
        <span class="font-bold text-2xl">Add Comorbidities</span>
      </div>
      <div>
        <LegacyTButton
          name="close-add-comorbidity"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="close"
        />
      </div>
    </div>
    <div v-if="isLoading">Loading...</div>
    <div class="overflow-auto space-y-4 h-full">
      <AddComorbidityInner
        v-for="comorbidityRef in comorbidityReferenceArray"
        :key="comorbidityRef.comorbidityRefId"
        :comorbidity-reference="comorbidityRef"
        @add="addComorbidity(comorbidityRef.comorbidityRefId)"
        @remove="removeComorbidity(comorbidityRef.comorbidityRefId)"
      />
      <TMTertiaryButton
        v-if="
          totalComorbiditiesReferences &&
          totalComorbiditiesReferences - activeRefs.length >
            comorbidityReferenceArray.length
        "
        label="Load more"
        @click="loadMore"
      />
    </div>
    <template #actions>
      <TMQuaternaryButton label="Cancel" @click="cancelAdd" />
      <TMPrimaryButton label="Save" :disabled="isLoading" @click="saveAdd" />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { map } from 'lodash'
import remove from 'lodash/remove'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, watch, ref, onMounted } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import { useModalComorbidityRefApi } from '@/legacy/store/modules/comorbiditiesReferences'
import { useComorbiditiesApi } from '@/legacy/store/modules/patient'
import { IdMap } from '@/legacy/types/api/store'
import { ComorbidityReference } from '@/legacy/types/patients/comorbidities'
import AddComorbidityInner from './AddComorbidityInner.vue'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    LegacyTButton,
    TModal,
    TMTertiaryButton,
    TMPrimaryButton,
    AddComorbidityInner,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'back', 'save', 'cancel'],
  setup(props, context) {
    const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
    const { data: comorbidityReferences, isLoading } = storeToRefs(
      useModalComorbidityRefApi()
    )
    const addedComorbidities = ref<string[]>([])
    const perPage = 10
    const pageNumber = ref(1)
    const totalComorbiditiesReferences = ref(0)

    const fetchComorbidityRefs = async () => {
      const results = await useModalComorbidityRefApi().list({
        params: {
          page_length: perPage,
          page_number: pageNumber.value,
          sort_order: 'description',
        },
      })
      totalComorbiditiesReferences.value = results?.queryMetadata.total
    }

    onMounted(fetchComorbidityRefs)
    watch(props, async () => {
      if (!comorbidityReferences.value && props.isVisible) {
        await fetchComorbidityRefs()
      }
    })

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      await fetchComorbidityRefs()
    }

    const activeRefs = computed(() =>
      map(comorbidities.value ?? {}, (comorb) => comorb.comorbidityRefId)
    )

    const comorbidityReferenceArray = computed(() => {
      return Object.values(
        comorbidityReferences.value as IdMap<ComorbidityReference>
      )
        .filter((ref) => activeRefs.value.indexOf(ref.comorbidityRefId) < 0)
        .sort((a, b) => (a.description < b.description ? -1 : 1))
    })

    const addComorbidity = (comorbidityRefId: string) =>
      addedComorbidities.value.push(comorbidityRefId)

    const removeComorbidity = (comorbidityRefId: string) =>
      remove(addedComorbidities.value, (v) => v === comorbidityRefId)

    const resetAll = () => {
      addedComorbidities.value = []
      pageNumber.value = 1
      totalComorbiditiesReferences.value = 0
    }

    const close = () => {
      context.emit('close')
      resetAll()
    }
    const goBack = () => {
      context.emit('back')
      resetAll()
    }
    const saveAdd = async () => {
      context.emit('save', addedComorbidities.value)
      resetAll()
      await fetchComorbidityRefs()
    }
    const cancelAdd = () => {
      context.emit('cancel')
      resetAll()
    }

    return {
      ButtonSize,
      ModalSize,
      comorbidityReferences,
      activeRefs,
      totalComorbiditiesReferences,
      addComorbidity,
      comorbidityReferenceArray,
      cancelAdd,
      close,
      goBack,
      removeComorbidity,
      saveAdd,
      isLoading,
      loadMore,
    }
  },
})
</script>
