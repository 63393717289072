import { PropType } from 'vue'
import { object, string, InferType, array, date, boolean } from 'yup'

// add modal schema
export const schema = object({
  cancerDiagnosisId: string().nullable(),
  radiationDate: date()
    .nullable()
    .when(['removed'], (keys: any[], schema: { [k: string]: any }) => {
      if (keys[0]) {
        return schema.nullable()
      } else {
        return schema.required('Radiation date is required.')
      }
    }),
  additionalInformation: string().nullable(),
  removed: boolean().nullable(),
})

export const listRadiationSchemas = object({
  radiationPayload: array().of(schema).required(),
})

export type AddOrEditRadiationsForm = InferType<typeof listRadiationSchemas>
export type AddOrEditRadiationForm = InferType<typeof schema>

export const addOrEditRadiationProps = {
  cancerDiagnosisId: {
    type: String,
    required: true,
  },
  radiationId: {
    type: String,
    default: '',
  },
  initialData: {
    type: Object as PropType<Partial<AddOrEditRadiationForm>>,
    default: () => ({}),
  },
  showAdd: {
    type: Boolean,
    default: false,
  },
} as const
