import { useProvidersPatientsMapApi } from '@/legacy/store/modules/mapProvidersPatients'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { UUID } from '@/legacy/types/global/base'
import { NotificationType } from '@/legacy/types/notifications'

// CONST
const PHONE_NUMBER_LENGTH = 10

/**
 * Update an existing mapProviderPatient phoneNumber
 * @param mapProviderPatient
 */
export const _updateExistingMapProviderPatient = async (
  mapProviderPatient: UpdateMapProviderPatient
) => {
  const providersPatientsMapApi = useProvidersPatientsMapApi()

  await providersPatientsMapApi.partialUpdate({
    body: { phoneNumber: mapProviderPatient.phoneNumber },
    ids: [mapProviderPatient.mapProviderPatientId],
  })
  if (mapProviderPatient.phoneNumber.length !== PHONE_NUMBER_LENGTH) {
    useNotificationStore().setNotification({
      message: 'Failed to save, provider phone number must be ten digits',
      type: NotificationType.DANGER,
    })
    throw providersPatientsMapApi.error
  } else if (providersPatientsMapApi.error) {
    useNotificationStore().setNotification({
      message: 'Failed to save provider phone number',
      type: NotificationType.DANGER,
    })
    throw providersPatientsMapApi.error
  } else {
    useNotificationStore().setNotification({
      message: 'Successfully saved provider phone number',
      type: NotificationType.SUCCESS,
    })
  }
}

export type UpdateMapProviderPatient = {
  mapProviderPatientId: UUID
  isPcp: boolean | null
  providerId: string
  patientId: string
  lastSeen: string
  phoneNumber: string
}
