import {
  Filter,
  FilterMetaType,
  FilterType,
} from '@thyme/nashville/src/types/tables'
import each from 'lodash/each'
import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import omit from 'lodash/omit'
import { DataTableStateEvent } from 'primevue/datatable'
import { computed, ComputedRef, ref, watch } from 'vue'
import { setupAppliedFilters } from './lib/filters'

export const setupFiltersForTable = (
  stateKey: ComputedRef<string>,
  props: any,
  getData: () => Promise<void> | undefined
) => {
  const filterMeta = ref<FilterMetaType>({})
  const sortOrderState = ref<number | undefined>(undefined)
  const sortFieldState = ref<string | undefined>(undefined)
  const firstIndexState = ref<number>(0)

  const resetPageNumber = () => {
    firstIndexState.value = 0
  }

  const resetSortsAndPage = () => {
    sortOrderState.value = props.sortOrder
    sortFieldState.value = props.sortField
    resetPageNumber()
  }
  const resetFilters = (refreshTable = true) => {
    filterMeta.value = {
      freeText: { value: null },
      ...props.filters,
    }
    resetSortsAndPage()

    // apply any default preset values to a filter
    for (const [key, filterOpts] of Object.entries(filterMeta.value)) {
      if (filterOpts.modalOptions?.preEnabledOptionsFn) {
        filterMeta.value[key].value = filterMeta.value[key].value?.length
          ? filterMeta.value[key].value
          : filterOpts.modalOptions.preEnabledOptionsFn()
      }
    }

    if (refreshTable) {
      // timeout to wait for vue cycle to complete and cookie to be updated
      setTimeout(getData, 0)
    }
  }
  resetFilters(false)
  watch(() => props.filters, resetFilters, { deep: true })

  const clearCache = () => {
    each(filterMeta.value, (filter) => {
      filter.value = filter.modalOptions?.preEnabledOptionsFn
        ? filter.modalOptions.preEnabledOptionsFn()
        : null
    })
    resetSortsAndPage()
    window.localStorage.removeItem(stateKey.value)
  }

  const resetAndClear = () => {
    clearCache()
    setTimeout(getData, 0)
  }

  const filterColumns = computed(() =>
    filter(
      map(filterMeta.value, (f: any, key: string) => ({
        parentName: 'filter',
        field: key,
        data: f.value,
        ...(f.modalOptions ?? {}),
      })),
      'type'
    )
  )

  const editingFilters = ref(false)

  const appliedFilters = computed(() => setupAppliedFilters(filterMeta.value))
  /**
   * show all filters from the modal which are currently applied
   *  NOTE: some filters show by default
   */
  const filterCount = computed(() => appliedFilters.value.length)

  const saveFilters = (newVals: any) => {
    each(newVals, (v, k) => {
      const noVal = !v || !v.length
      if (noVal && filterMeta.value[k].modalOptions?.preEnabledOptionsFn) {
        filterMeta.value[k].value =
          filterMeta.value[k].modalOptions?.preEnabledOptionsFn?.()
      } else {
        filterMeta.value[k].value = v
      }
    })
    resetPageNumber()
    // timeout to wait for vue cycle to complete and cookie to be updated
    setTimeout(getData, 0)
  }

  const restoreFilters = ({
    filters,
    sortField,
    sortOrder,
    first,
  }: DataTableStateEvent) => {
    // only restore filters which the current table accepts
    const acc = {
      freeText: { value: null },
      ...props.filters,
    }

    // keep modal options from props but overwrite all other values
    for (const [key, value] of Object.entries(filters as FilterMetaType)) {
      acc[key]
        ? (acc[key] = { ...acc[key], ...omit(value, 'modalOptions') })
        : null
    }

    filterMeta.value = acc
    sortOrderState.value = sortOrder ?? undefined
    sortFieldState.value = sortField
    firstIndexState.value = first
  }

  return {
    resetPageNumber,
    filterMeta,
    resetFilters,
    clearCache,
    resetAndClear,
    filterColumns,
    editingFilters,
    filterCount,
    saveFilters,
    restoreFilters,
    appliedFilters,
    updateFilterSet,
    sortFieldState,
    sortOrderState,
    firstIndexState,
  }
}

export const updateFilterSet = (
  field: string,
  data: string | any[],
  filter: Filter,
  filters: { [key: string]: any }
) => {
  const modalOpts = filter.modalOptions
  let dataLocations: string[] = []
  if (modalOpts) {
    dataLocations = modalOpts.dataLocations ?? []
  }
  const formattedData = isArray(data)
    ? data
    : typeof data === 'string'
    ? data.split(',')
    : []
  if (modalOpts?.type === FilterType.Multiselect && dataLocations) {
    dataLocations.forEach((loc: string) => (filters[loc] = []))
    formattedData.forEach((v: any[]) =>
      dataLocations.forEach((loc: string, i: number) =>
        v[i] ? filters[loc].push(v[i]) : null
      )
    )
  } else if (dataLocations?.length) {
    if (formattedData.length) {
      formattedData.forEach((d, i) => (filters[dataLocations[i]] = d))
    } else {
      dataLocations.forEach((d) => (filters[d] = []))
    }
  }
  filters[field] = data
}
