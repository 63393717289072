<template>
  <div>
    <div class="relative flex py-5 items-center">
      <LegacyTChip
        class="flex-shrink mr-2 bg-nash-midnightBlue100 text-nash-midnightBlue700"
        :label="`Ongoing Support`"
      />
      <div class="flex-grow border-t border-nash-neutral500"></div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-4">
      <PatientTreatmentPlans
        v-for="item in pathways"
        :key="item.pathwayId"
        :pathway="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import PatientTreatmentPlans from '@/legacy/components/patient/pathways/PatientTreatmentPlans.vue'
import LegacyTChip from '@/legacy/nashville/LegacyTChip.vue'
import { Pathway } from '@/legacy/types/pathways/pathways'

export default defineComponent({
  components: {
    PatientTreatmentPlans,
    LegacyTChip,
  },
  props: {
    pathways: {
      type: Array as PropType<Pathway[]>,
      default: () => [],
    },
  },
  setup() {
    return {}
  },
})
</script>
