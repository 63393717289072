import debounce from 'lodash/debounce'
import { reactive, watch } from 'vue'

export const DEBOUNCE_INTERVAL = 1000

/**
 * Sets up a watcher to call apiCall when returned reactive object is updated
 * @param apiCall function to call when watcher is triggered
 * @param initial initial value of data (an object)
 * @returns reactive object to trigger watcher
 */
export function autosave<T extends { [key: string]: any }>(
  apiCall: (...args: any) => void,
  initial: T
) {
  const data = reactive(initial)

  watch(data, debounce(apiCall, DEBOUNCE_INTERVAL), { deep: true })
  return data
}
