import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { fromISOWithZone, maybeAddTimezone } from '@/legacy/libs/date'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  DirtyPlannedCall,
  ScheduledTimeBlock,
} from '@/legacy/types/communications/plannedCalls'
import {
  SCHEDULED_CALL_TIME_NOT_SET_DURATION,
  SCHEDULED_CALL_TIME_SET_DURATION,
} from '../../lib/plannedCalls'
import { timeBlockDropdownOptions } from '../lib/scheduleDueDatetimeDropdowns'

export enum DueDateTimePart {
  DATE = 'date',
  TIME = 'time',
}

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { patient } = storeToRefs(usePatientStore())
  const { showScheduledCallNoTime } = storeToRefs(useFlagStore())

  const dirtyPlannedCall = ref<Partial<DirtyPlannedCall>>({
    dueDatetime: props.dueDatetime ?? DateTime.now().toISO(),
    timeBlock: ScheduledTimeBlock.CUSTOM,
    duration: showScheduledCallNoTime.value
      ? SCHEDULED_CALL_TIME_NOT_SET_DURATION
      : SCHEDULED_CALL_TIME_SET_DURATION,
    showTime: true,
  })
  const shouldSetCallTime = computed(() => props.shouldSetTime)
  const patientTimezone = computed(() => patient.value?.timezone ?? 'local')

  const displayUserTimezoneString = computed(
    () => `Your local timezone: ${DateTime.now().offsetNameShort}`
  )

  const displayMemberTimezoneString = computed(() => {
    if (
      dirtyPlannedCall.value.dueDatetime &&
      dirtyPlannedCall.value.timeBlock === ScheduledTimeBlock.CUSTOM
    ) {
      const localTime = fromISOWithZone(
        dirtyPlannedCall.value.dueDatetime
      ).setZone(patientTimezone.value)
      return `Member's time: ${localTime.toLocaleString(
        DateTime.TIME_SIMPLE
      )} ${localTime.offsetNameShort}`
    }
    return null
  })

  /**
   * Update either the date or time portion of the dueDatetime. Leave the other part intact
   * @param value Date to set from
   * @param part are we setting the time or the date portion
   */
  function setDueDatetime(value: Date, part: DueDateTimePart) {
    let newDueDateTime: DateTime | undefined
    if (!dirtyPlannedCall.value.dueDatetime) {
      newDueDateTime = DateTime.fromJSDate(value)
    } else if (part === DueDateTimePart.DATE) {
      // convert to local because VanillaJS Dates work in local
      newDueDateTime = fromISOWithZone(
        dirtyPlannedCall.value.dueDatetime
      ).toLocal()
      newDueDateTime = newDueDateTime.set({
        year: value.getFullYear(),
        // ANGRY NOTE
        // + 1 because javascript Date months are 0 indexed
        // and luxon DateTime months are NOT
        month: value.getMonth() + 1,
        day: value.getDate(),
      })
    } else if (part === DueDateTimePart.TIME) {
      // convert to local because VanillaJS Dates work in local
      newDueDateTime = fromISOWithZone(
        dirtyPlannedCall.value.dueDatetime
      ).toLocal()
      newDueDateTime = newDueDateTime.set({
        hour: value.getHours(),
        minute: value.getMinutes(),
      })
    }
    dirtyPlannedCall.value.dueDatetime = newDueDateTime
      ? newDueDateTime.toUTC().toISO() ?? undefined
      : undefined

    context.emit('update', dirtyPlannedCall.value)
  }

  watch(
    () => shouldSetCallTime.value,
    () => {
      if (shouldSetCallTime.value) {
        dirtyPlannedCall.value.duration = SCHEDULED_CALL_TIME_SET_DURATION
      } else {
        dirtyPlannedCall.value.duration = SCHEDULED_CALL_TIME_NOT_SET_DURATION
      }
      context.emit('update', dirtyPlannedCall.value)
    }
  )

  return {
    patientTimezone,
    dirtyPlannedCall,
    timeBlockDropdownOptions,
    displayUserTimezoneString,
    displayMemberTimezoneString,
    ScheduledTimeBlock,
    DueDateTimePart,
    setDueDatetime,
    maybeAddTimezone,
    shouldSetCallTime,
  }
}
