import { Communication } from '@/legacy/types/communications/communications'
import { Timestamps } from '@/legacy/types/global/dates'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import { Patient } from '@/legacy/types/patients/patients'
import { AcuityScore } from './acuity'
import { EntityRole } from './entities/entities'
import { Gender } from './entities/people'

// TYPES --------------------
export type SortBy = {
  sort_by: string[]
}

export type ReminderPatient = {
  preferredLanguage?: string
  dateOfBirth?: string
  firstName?: string
  lastName?: string
  middleInitial?: string
  gender?: Gender
  suffix?: string
} & Patient

export type Reminder = {
  responsibleRole: EntityRole
  reminderId: string
  patientId: string
  ownerId: string
  note: string
  reminderType: ReminderType
  dueDatetime?: string
  subtask?: Subtask
  call?: Communication
  patient?: ReminderPatient
  acuityScore?: AcuityScore
  disableDueDate: boolean
  disablePriority: boolean
} & Timestamps

export type Stack = {
  stackId: string
  patient: ReminderPatient
  patientId: string
  communicationId?: string
}

export type StackData = {
  scheduled_call?: Communication
  subtasks?: Subtask[]
}

export type SubtaskStaffStats = {
  staffId: string
  subtaskCount: number
  memberCount: number
  carePodCount: number
}

// ENUMS --------------------
export enum ReminderType {
  activity = 'activity',
  subtask = 'subtask',
  planned_call = 'planned_call',
}

export enum RemindersSubtaskStatus {
  OPEN_UNASSIGNED = 'OPEN_UNASSIGNED',
  OPEN_ASSIGNED = 'OPEN_ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  CLOSED_IN_ERROR = 'CLOSED_IN_ERROR',
  BLOCKED = 'BLOCKED',
}

// CONST --------------------
export const myRemindersPagePresort = [
  { field: 'priority', order: -1 },
  { field: 'dueDatetime', order: -1 },
  { field: 'acuityScore', order: -1 },
]

export const queueSortBy = [
  'is_urgent,asc',
  'dueDatetime,asc',
  'priority,asc',
  'acuityScore,desc',
]
