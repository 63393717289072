import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { InputType } from '@thyme/nashville/src/types/inputs'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Size } from '@thyme/nashville/src/types/shared'
import capitalize from 'lodash/capitalize'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  DirtyReattempt,
  RescheduleCommPartial,
  RescheduleReattemptSavePayload,
} from '@/legacy/components/patient/communicationV2/shared/types'

import {
  getPriorityIcon,
  datepickerPlaceholder,
  renderRoleOrStaff,
} from '@/legacy/components/patient/pathways/lib/subtask'
import { maxDateWithWeekends } from '@/legacy/libs/date'
import { formatDateToCST } from '@/legacy/libs/format'

import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useStaffApi } from '@/legacy/store/modules/staff'
import { useSubtaskApi } from '@/legacy/store/modules/subtasks'
import { DirtyPlannedCall } from '@/legacy/types/communications/plannedCalls'
import { EntityRole } from '@/legacy/types/entities/entities'
import {
  MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS,
  minDateTomorrow,
  unselectableDaysOfWeek,
} from '@/legacy/types/global/dates'

/**
 *
 * @param props
 * @param context
 */
export default function setup(props: any, context: any) {
  const { data: subtasks } = storeToRefs(useSubtaskApi())
  const { data: staff } = storeToRefs(useStaffApi())
  const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())

  const showSelectedSubtasksView = ref(false)

  const linkedSubtasks = computed(() => {
    if (props.subtaskIds) {
      return props.subtaskIds.map((id: string) => {
        if (subtasks.value) {
          return subtasks.value[`${id}`]
        }
      })
    }
    return []
  })

  const dirtyPlannedCall = ref<DirtyPlannedCall>({
    calleeEntityId: props.calleeEntityId ?? undefined,
    communicationId: props.communicationId,
    dueDatetime: DateTime.now().toISO(),
    timeBlock: null,
    duration: 30,
    showTime: true,
  })

  // If callee changes from sidebar UI, we need to update it for modal
  watch(props, () => {
    dirtyPlannedCall.value = {
      ...dirtyPlannedCall.value,
      calleeEntityId: props.calleeEntityId ?? undefined,
    }
  })

  const dirtyCommunication = ref<RescheduleCommPartial>({
    responsibleStaffId: null,
    responsibleRole: null,
  })

  const dirtyReattempt = ref<DirtyReattempt>({
    reattemptReason: '',
    reattemptDatetime: DateTime.now().toJSDate(),
  })

  const reasonOptions = ['No Answer', 'Member Callback Request', 'Other']

  const otherReason = ref('')

  const maxReattemptDays = computed(() =>
    maxDateWithWeekends(
      minDateTomorrow,
      MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS,
      true
    )
  )

  const responsible = computed(
    () =>
      dirtyCommunication.value.responsibleStaffId ??
      dirtyCommunication.value.responsibleRole ??
      undefined
  )

  /**
   * Update assigned to either entityId or entityRole
   * NULL out the other
   */
  function updateAssigned({
    responsibleStaffId,
    responsibleRole,
  }: {
    [key: string]: string | null
  }) {
    dirtyCommunication.value.responsibleStaffId = responsibleStaffId
    dirtyCommunication.value.responsibleRole =
      responsibleRole as EntityRole | null
  }

  /**
   * Update Datetime fields from
   * ScheduledDueDatetimeDropdowns
   * @param DirtyPlannedCall
   * @param DirtyPlannedCall.dueDatetime
   * @param DirtyPlannedCall.timeBlock
   * @param DirtyPlannedCall.duration
   * @param DirtyPlannedCall.showTime
   */
  function updateDateTime({
    dueDatetime,
    timeBlock,
    duration,
    showTime,
  }: Partial<DirtyPlannedCall>) {
    dirtyPlannedCall.value.dueDatetime = dueDatetime as string
    dirtyReattempt.value.reattemptDatetime = DateTime.fromISO(
      dueDatetime as string,
      { zone: 'utc' }
    )
      .setZone('local')
      .toJSDate()
    dirtyPlannedCall.value.timeBlock = timeBlock
    dirtyPlannedCall.value.duration = duration
    dirtyPlannedCall.value.showTime = showTime
  }

  // validator for confirm button
  const hasValidData = computed(() => {
    const timeIsValid =
      dirtyReattempt.value.reattemptDatetime && dirtyPlannedCall.value.timeBlock
    const assignedIsValid =
      dirtyCommunication.value.responsibleStaffId ??
      dirtyCommunication.value.responsibleRole
    if (props.reattempt && props.reschedule) {
      return !!(timeIsValid && assignedIsValid)
    }
    if (props.reattempt && !props.reschedule) {
      return !!dirtyReattempt.value.reattemptDatetime
    }
    return !!(dirtyPlannedCall.value.timeBlock && assignedIsValid)
  })

  const setReattemptDate = (selectedDate?: Date) => {
    if (!selectedDate) {
      return null
    }
    // guaranteed string since selectedDate is checked above
    const dateTime = formatDateToCST(selectedDate) as string
    dirtyReattempt.value.reattemptDatetime = new Date(dateTime)
  }

  /**
   * Show/hide selected subtasks view
   */
  function toggleSelectedSubtasksView() {
    showSelectedSubtasksView.value = !showSelectedSubtasksView.value
  }

  /**
   * Close the modal
   */
  function close() {
    context.emit('close')
    showSelectedSubtasksView.value = false
  }

  /**
   * Save current scheduled call
   * Closing this modal happens at the end of save
   * So no need to explicitly emit here
   */
  function save() {
    let reason: string | undefined = dirtyReattempt.value.reattemptReason
    if (reason === 'Other' && otherReason.value) {
      reason += `: ${otherReason.value}`
    } else if (!reason) {
      reason = 'Other'
    }

    const emitContent: RescheduleReattemptSavePayload = {
      dirtyReattempt: {
        ...dirtyReattempt.value,
        reattemptReason: reason,
      },
      dirtyPlannedCall: dirtyPlannedCall.value,
      dirtyCommunication: dirtyCommunication.value,
      linkedSubtaskIds: props.subtaskIds,
    }

    context.emit('save', emitContent)
  }

  return {
    dirtyCommunication,
    dirtyPlannedCall,
    ModalSize,
    Size,
    responsible,
    ButtonSize,
    dirtyReattempt,
    reasonOptions,
    otherReason,
    hasValidData,
    InputType,
    minDateTomorrow,
    unselectableDaysOfWeek,
    datepickerPlaceholder,
    linkedSubtasks,
    showSelectedSubtasksView,
    staff,
    maxReattemptDays,
    capitalize,
    renderRoleOrStaff,
    getPriorityIcon,
    toggleSelectedSubtasksView,
    setReattemptDate,
    updateAssigned,
    updateDateTime,
    close,
    save,
    hideSubtaskEstimatedCompletionTime,
  }
}
