import apiStore from '@/legacy/store/modules/apiBuilder'
import { Referral } from '@/legacy/types/patients/referrals'

export const useReferralsApi = apiStore<Referral>(
  'referralsApi',
  '/api/patients/referrals',
  {
    transformData: (d: { data: Referral[] }) => d,
  }
)
