<template>
  <span ref="rootEl">
    <span v-if="direction === 'left'" v-tooltip.left="tooltipOptions">{{
      text
    }}</span>
    <span v-else-if="direction === 'top'" v-tooltip.top="tooltipOptions">{{
      text
    }}</span>
    <span
      v-else-if="direction === 'bottom'"
      v-tooltip.bottom="tooltipOptions"
      >{{ text }}</span
    >
    <span v-else v-tooltip.right="tooltipOptions">{{ text }}</span>
  </span>
</template>

<script lang="ts">
import { directionProp } from '@thyme/nashville/src/types/tooltips'
import truncate from 'lodash/truncate'
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { thymeListen } from '@/legacy/libs/eventBus'

export default defineComponent({
  props: {
    ...directionProp,
    label: {
      type: String,
      default: '',
    },
    maxChars: {
      type: Number,
      default: undefined,
    },
    parentSelector: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const rootEl = ref<Element | null>(null)

    /**
     * parentWidth ref forces vue to watch resize and trigger computed updates on resize
     * which is otherwise not watched by vue computed
     */
    const parentWidth = ref<number | null>(null)
    const onResize = () => {
      let parentElement = rootEl.value?.parentNode
      if (props.parentSelector) {
        parentElement = rootEl.value?.closest(props.parentSelector)
      }
      // @ts-ignore clientWidth is a valid attr of ParentNode
      parentWidth.value = parentElement?.clientWidth ?? null
    }
    onMounted(() => {
      thymeListen('resize', onResize)
    })
    onUnmounted(() => window.removeEventListener('resize', onResize))

    /**
     * dynamic character limit based on width of parent container (e.g. sidebar or table cell)
     * setting maxChars will override default limit for scaling purposes
     */
    const charLimit = computed(() => {
      // manually decided on pixel testing
      const arbitraryDefaultCharacterLimit = 180
      // default laptop screen width to scale off of (could be anything but this is what we picked)
      const arbitraryBaseWidth = 1920
      let maxChars = props.maxChars ?? arbitraryDefaultCharacterLimit
      if (rootEl.value) {
        // @ts-ignore clientWidth is a valid attr of parentNode
        const pWidth = parentWidth.value ?? rootEl.value.parentNode?.clientWidth
        const sizePercentage = pWidth ? pWidth / arbitraryBaseWidth : 1
        maxChars = Math.floor(maxChars * sizePercentage)
      }
      return maxChars
    })

    const tooltipOptions = computed(() => ({
      value: props.label,
      separator: ' ',
      disabled: props.label?.length <= charLimit.value,
    }))

    const text = computed(() =>
      truncate(props.label, { length: charLimit.value })
    )

    return {
      tooltipOptions,
      text,
      charLimit,
      rootEl,
    }
  },
})
</script>
