<template>
  <div>
    <h2>TCheckbox Debug</h2>
    <LegacyTCheckbox :checked="base" label="Default" />
    <LegacyTCheckbox :checked="checked" label="Checked" />
    <LegacyTCheckbox
      :checked="disabledUnchecked"
      :disabled="true"
      label="Disabled unchecked"
    />
    <LegacyTCheckbox
      :checked="disabledChecked"
      :disabled="true"
      label="Disabled checked"
    />
    <LegacyTCheckbox :checked="tile1" :tile="true" label="tiled 1" />
    <LegacyTCheckbox :checked="tile2" :tile="true" label="tiled 2" />
    <LegacyTCheckbox :checked="tile3" :tile="true" label="tiled 3" />
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import LegacyTCheckbox from '@/legacy/nashville/LegacyTCheckbox.vue'

export default defineComponent({
  components: { LegacyTCheckbox },
  setup() {
    const checkStates = {
      base: false, // Default
      checked: true, // Checked
      disabledUnchecked: false, // Disabled Unchecked
      disabledChecked: true, // Disabled Checked
      tile1: false, // Tile 1
      tile2: false, // Tile 2
      tile3: false, // Tile 3
    }
    return {
      ...toRefs(checkStates),
    }
  },
})
</script>
