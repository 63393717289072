<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Physical Function Assessment"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div class="">
          <TMDropdownField
            name="ecogStatus"
            label="ECOG Status"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="ecogStatusOptions"
            :model-value="initialData.ecogStatus ?? undefined"
          />
          <TMDropdownField
            name="adlsInability"
            class="w-full my-2"
            label="Unable to Perform the Following ADLs"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="ADLsInabilityOptions"
            :model-value="initialData.adlsInability ?? undefined"
          />
          <TMDropdownField
            name="dmeAtHome"
            label="DME"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="DMEOptions"
            :model-value="initialData.dmeAtHome ?? undefined"
          />
          <TMDropdownField
            name="unableToWalkTwoBlocksOrAFlightOfStairs"
            label="Walks 2 Blocks or 1 Flight of Stairs"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
            :model-value="
              initialData.unableToWalkTwoBlocksOrAFlightOfStairs ?? undefined
            "
          />
        </div>
        <div>
          <TMDropdownField
            name="iadlsInability"
            label="Unable to Perform the Following IADLs"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="IADLsInabilityOptions"
            :model-value="initialData.iadlsInability ?? undefined"
          />
          <TMDropdownField
            name="unableToLeaveHomeOtherThanMedicalAppts"
            label="Homebound"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :options="booleanOptions"
            :model-value="
              initialData.unableToLeaveHomeOtherThanMedicalAppts ?? undefined
            "
          />
          <TMDropdownField
            name="proceduralSupport"
            label="Procedural Support"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="proceduralSupportOptions"
            :model-value="initialData.proceduralSupport ?? undefined"
          />
        </div>
      </div>

      <label class="font-bold mb-2">Additional Information</label>
      <TTextarea
        name="additionalContext"
        :model-value="initialData.additionalContext ?? ''"
      />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="physical-function-assessment-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="physical-function-assessment-save"
          label="Save"
          @click="onSubmit"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'

import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editPhysicalFunctionAssessmentProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TTextarea,
    TMSecondaryButton,
  },
  props: editPhysicalFunctionAssessmentProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
