<template>
  <!-- @vue-ignore Submit generics not compatible with specifics -->
  <VForm
    v-slot="{ values, setFieldValue }"
    :initial-values="initialValues"
    @submit="submit"
  >
    <div class="flex flex-col">
      <h5>{{ formatConsentTitle(conType) }}</h5>
    </div>
    <button
      v-if="!creating"
      class="flex w-full flex-row-reverse font-bold text-nash-purple500"
      name="edit-patient-consent"
    >
      Save
    </button>
    <label class="font-bold"> Consent Status </label>
    <TDropdown
      v-model="values.status"
      class="w-full bg-white"
      input-id="status"
      option-label="label"
      option-value="value"
      :options="statusChoices"
      :show-clear="false"
      @update:model-value="
                (v: any) => setFieldValue('status', v)
              "
    />
    <TDatePicker
      v-if="!isSent(values.status)"
      label="Date Signed"
      :is-datetime="false"
      name="signedAt"
    />
    <div v-if="!isSent(values.status)">
      <label class="font-bold"> Relationship to Member </label>
      <TDropdown
        v-model="values.consentingRelationship"
        class="w-full bg-white"
        input-id="consentingRelationship"
        option-label="label"
        option-value="value"
        :options="relationshipChoices"
        :show-clear="false"
        @update:model-value="
                (v: any) => setFieldValue('consentingRelationship', v)
              "
      />
    </div>
    <TInputText
      v-if="showRelationshipName(values.consentingRelationship)"
      :model-value="initialValues.consentingName ?? ''"
      label="Name"
      name="consentingName"
    />

    <div v-if="creating" class="mt-2 flex flex-row-reverse">
      <button class="p-button p-component mt-2" name="save-patient-consent">
        Save
      </button>
    </div>
  </VForm>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { Form as VForm } from 'vee-validate'
import { computed, defineComponent, PropType, ref, onBeforeMount } from 'vue'
import { lookupEnum, enumValueToKey } from '@/legacy/libs/enum'
import { formatConsentTitle } from '@/legacy/libs/format'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'

import {
  Consent,
  ConsentType,
  ConsentStatus,
  AddConsentForm,
} from '@/legacy/types/patients/consents'
import { NewRelationshipToPatient } from '@/legacy/types/patients/contacts'

export default defineComponent({
  components: {
    VForm,
    TInputText,
    TDatePicker,
    TDropdown,
  },
  props: {
    creating: {
      type: Boolean,
      default: false,
    },
    consent: {
      type: Object as PropType<Consent | null>,
      default: null,
    },
    conType: {
      type: String as PropType<ConsentType>,
      required: true,
    },
  },
  emits: ['submit'],
  setup(props, context) {
    /**
     *
     */
    function emptyForm() {
      // @ts-ignore (MT-2673)
      return {
        patientConsentId: '',
        // IMPROVEME (MT-2673) status is required for type but not guaranteed set
        status: '',
        consentingRelationship: '',
        consentingName: '',
        signedAt: '',
      } as AddConsentForm
    }

    const initialValues = ref<AddConsentForm>(emptyForm())

    /**
     * Fucntion to convert an enum to dropdown options
     * @param e
     */
    function enumToOptions(e: any) {
      return Object.keys(e).map((k: string) => ({
        value: k,
        label: lookupEnum(e, k),
      }))
    }

    /**
     *
     * @param relationship
     */
    function showRelationshipName(
      relationship: NewRelationshipToPatient | null
    ) {
      return relationship && relationship in NewRelationshipToPatient
    }

    const statusChoices = enumToOptions(ConsentStatus)

    const relationshipChoices = computed(() => {
      const opts = enumToOptions(NewRelationshipToPatient)
      opts.unshift({ value: 'self', label: 'Self' })
      return opts
    })

    onBeforeMount(() => {
      if (!props.creating && props.consent) {
        const {
          patientConsentId,
          status,
          consentingRelationship,
          consentingName,
          signedAt,
        } = props.consent

        initialValues.value.patientConsentId = patientConsentId
        initialValues.value.status = status
        initialValues.value.consentingRelationship =
          consentingRelationship ?? 'self'
        initialValues.value.consentingName = consentingName
        // IMPROVEME (MT-2673) signedAt is string, not Date
        // @ts-expect-error signedAt is string, not Date
        initialValues.value.signedAt = signedAt ? new Date(signedAt) : null
      }
    })

    /**
     * Function to emit back form submit to parent component
     * @param data
     */
    function submit(data: AddConsentForm) {
      if (data.consentingRelationship === 'self' || isSent(data.status)) {
        data.consentingRelationship = null
        data.consentingName = null
      }

      if (isSent(data.status)) {
        data.signedAt = null
      }

      context.emit('submit', data, props.conType)
    }

    /**
     *
     * @param status
     */
    function isSent(status: ConsentStatus | null) {
      return status === enumValueToKey(ConsentStatus, ConsentStatus.SENT)
    }

    return {
      initialValues,
      statusChoices,
      submit,
      close,
      relationshipChoices,
      formatConsentTitle,
      ConsentStatus,
      showRelationshipName,
      enumValueToKey,
      isSent,
    }
  },
})
</script>
