import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import {
  updateCommunication,
  deleteCommunication,
} from '@/legacy/components/patient/communicationV2/lib/communications'
import {
  deletePlannedCall,
  SCHEDULED_CALL_TIME_SET_DURATION,
  updatePlannedCall,
} from '@/legacy/components/patient/communicationV2/lib/plannedCalls'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatCleanDateTime,
  formatCleanTime,
  formatNameFromPerson,
} from '@/legacy/libs/format'
import router from '@/legacy/router'
import {
  createDefaultDatetime,
  useCommunicationApi,
  usePlannedCallApi,
} from '@/legacy/store/modules/communications'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  BaseCommunicationPayload,
  CommSidebarActionEnums,
  CommunicationType,
  DEFAULT_NOTES,
} from '@/legacy/types/communications/communications'
import { DirtyPlannedCall } from '@/legacy/types/communications/plannedCalls'

/**
 * return vars and functions for modifying communications and planned calls
 */
export function getCommunicationVars() {
  const scheduledCallModalOpen = ref(false)
  const { datum: communication, isLoading: isLoadingComm } = storeToRefs(
    useCommunicationApi()
  )
  const { datum: plannedCall, isLoading: isLoadingPlannedCall } = storeToRefs(
    usePlannedCallApi()
  )

  const { patient, person } = storeToRefs(usePatientStore())
  const patientIds = computed(() =>
    communication.value
      ? communication.value.patientIds
      : patient.value
      ? [patient.value.entityId]
      : []
  )

  const dirtyPlannedCall = ref<DirtyPlannedCall>({
    ...(plannedCall.value ?? {}),
    dueDatetime: plannedCall.value?.dueDatetime ?? createDefaultDatetime(),
  })
  watch(plannedCall, () => {
    setDirtyStates('planned')
  })

  /**
   *
   * Function to set editing states for either planned call or communication.
   * @param type
   */
  function setDirtyStates(type: string) {
    if (type === 'planned') {
      dirtyPlannedCall.value = {
        ...(plannedCall.value ?? {}),
        dueDatetime: plannedCall.value?.dueDatetime ?? createDefaultDatetime(),
      }
    }
    if (type === 'communication') {
      dirtyCommunication.value = {
        ...(communication.value ?? {}),
        completedDatetime: null,
        notes: communication.value?.notes ?? DEFAULT_NOTES,
        patientIds: patientIds.value,
        type: communication.value?.type ?? CommunicationType.Call,
      }
    }
  }

  const dirtyCommunication = ref<BaseCommunicationPayload>({
    ...(communication.value ?? {}),
    completedDatetime: null,
    notes: communication.value?.notes ?? DEFAULT_NOTES,
    patientIds: patientIds.value,
    type: communication.value?.type ?? CommunicationType.Call,
  })
  watch(communication, () => {
    setDirtyStates('communication')
  })

  const memberName = computed(() =>
    person.value ? formatNameFromPerson(person.value) : 'Member'
  )

  const commId = computed(() => communication.value?.communicationId ?? null)

  const responsible = computed(
    () =>
      dirtyCommunication.value.responsibleStaffId ??
      dirtyCommunication.value.responsibleRole ??
      ''
  )

  /**
   * send create/update call to sidebar
   * triggers api call in sidebar
   * @param updatedField
   */
  async function updateCallAndComm(updatedField?: string) {
    await updateCommunication(
      commId.value,
      dirtyCommunication.value,
      updatedField ?? '',
      communication.value?.updatedAt
    )
    await updatePlannedCall(commId.value, dirtyPlannedCall.value)
  }

  /**
   *
   * Function to delete both communication and planned call.
   */
  async function deleteCallAndComm() {
    if (
      window.confirm(
        'This action will delete this communication. Do you wish to continue?'
      )
    ) {
      await deletePlannedCall(commId.value)
      await deleteCommunication(commId.value)
      await router.push({
        query: {
          commId: undefined,
        },
      })
    }
  }

  /**
   * trigger update to Callee
   * @param updatedCalleeEntityId
   */
  async function updatePlannedCallee(updatedCalleeEntityId: string) {
    dirtyPlannedCall.value.calleeEntityId = updatedCalleeEntityId
    await updateCallAndComm(CommSidebarActionEnums.callee)
  }

  /**
   * update notes
   * @param val
   */
  function updateNotesForDirtyComm(val: string) {
    dirtyCommunication.value.notes = val
  }

  /**
   * Save a scheduled call from pop-up modal
   * runs through the same create function with the additional payload
   * @param updatePayloads
   * @param updatePayloads.dirtyPlannedCall
   * @param updatePayloads.dirtyCommunication
   */
  function updateFromModal(updatePayloads: {
    dirtyPlannedCall: DirtyPlannedCall
    dirtyCommunication: BaseCommunicationPayload
  }) {
    dirtyPlannedCall.value = updatePayloads.dirtyPlannedCall
    dirtyCommunication.value = updatePayloads.dirtyCommunication
    void updateCallAndComm()
    scheduledCallModalOpen.value = false
  }

  return {
    isLoadingPlannedCall,
    isLoadingComm,
    scheduledCallModalOpen,
    memberName,
    responsible,
    dirtyCommunication,
    dirtyPlannedCall,
    updateCallAndComm,
    deleteCallAndComm,
    updatePlannedCallee,
    updateNotesForDirtyComm,
    updateFromModal,
  }
}

/**
 * return vars and fns for direct template display
 */
export function getDisplayVars() {
  const componentTitle = 'Scheduled'

  /**
   * Convert isoDateTime string to displayable date
   * @param isoDate
   */
  function displayDate(isoDate: string) {
    return formatCleanDateTime(stringToDateTime(isoDate)?.setZone('local'))
  }

  /**
   *
   * Convert isoDateTime string to displayable time
   * @param duration
   * @param isoDate
   */
  function displayTime(duration: number | null | undefined, isoDate: string) {
    return duration === SCHEDULED_CALL_TIME_SET_DURATION
      ? formatCleanTime(
          DateTime.fromISO(isoDate, { zone: 'utc' }).setZone('local')
        )
      : 'Not set'
  }

  return {
    componentTitle,
    displayDate,
    displayTime,
  }
}
