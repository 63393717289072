import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { computed, ExtractPropTypes, onMounted } from 'vue'
import { splitTitleCaseWithSpaces } from '@/legacy/libs/string'
import { useCarePodsApi } from '@/legacy/store/catalog/carePods'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { Gender, Language } from '@/legacy/types/entities/people'
import { updatePatientDetails, fetchCarePods } from './queries'
import {
  editPatientDetailsProps,
  EditPatientDetailsForm,
  schema,
} from './types'

type PropsType = ExtractPropTypes<typeof editPatientDetailsProps>

/**
 * Set up the EditPatientDetails component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const {
    canEditPatientContract,
    hasAdminPrivileges,
    canViewPageAdminCarePods,
  } = storeToRefs(useProfileStore())
  const contractingEntities = useContractingEntities()
  const { enablePatientContracts } = storeToRefs(useFlagStore())
  const { data: carePods } = storeToRefs(useCarePodsApi())

  const canEditPatientCarePod =
    hasAdminPrivileges.value || canViewPageAdminCarePods.value

  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  const languageOptions = Object.entries(Language).map(([k, v]) => {
    return { value: k, label: v }
  })
  const genderOptions = Object.entries(Gender).map(([label, value]) => {
    return { value: value, label: splitTitleCaseWithSpaces(label) as string }
  })

  const { handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  /**
   *
   * @param values
   */
  async function savePatientDetails(values: EditPatientDetailsForm) {
    await updatePatientDetails(
      props.patientId,
      props.patientContractId,
      enablePatientContracts.value,
      props.initialData,
      values
    )

    if (enablePatientContracts.value) {
      context.emit('refreshContractingChip')
    }
    close()
  }

  const sortedCarePods = computed(() =>
    carePods.value?.sort((a, b) => a.name.localeCompare(b.name))
  )

  const onSubmit = handleSubmit(savePatientDetails)

  onMounted(async () => {
    await fetchCarePods()
  })

  return {
    close,
    ModalSize,
    onSubmit,
    languageOptions,
    genderOptions,
    contractingEntities,
    canEditPatientContract,
    enablePatientContracts,
    canEditPatientCarePod,
    sortedCarePods,
  }
}
