<template>
  <div
    v-tooltip.left="pathway.description"
    data-cy="pathway-card"
    class="pathway-card cursor-pointer"
    @click="() => goToPathway(pathway.pathwayId)"
  >
    <div class="flex justify-between">
      <div class="flex items-center">
        <div class="pl-3">
          <TIcon icon="pathway" color="nash-midnightBlue700" />
        </div>
        <h5 class="card-header-title">
          {{ pathway.title }}
          {{ pathway.variantDisplay ? getVariantDisplay(pathway) : '' }}
        </h5>
      </div>

      <TIcon
        v-if="isEvergreen"
        icon="padlock"
        color="nash-neutral600"
        class="mt-2 mr-4 cursor-not-allowed w-6"
      />
      <LegacyTDropdown
        v-else-if="allowStatusUpdate && !isPathwayClosed"
        name="pathway-status"
        class="h-6 p-1 mt-1.5"
        icon="meatball"
        :expanded="false"
        :model-value="''"
        :options="pathwayStatusOptions"
        @update:model-value="(v) => updatePathwayStatus(v)"
      />
    </div>
    <div class="flex mt-4">
      <ProgressBar
        v-if="showProgressBar"
        class="absolute inset-x-0 bottom-0"
        :completed="completedTasks"
        :total="totalTasks"
        :label="label"
        :status="status"
      />
      <div v-else>
        <div class="absolute inset-x-0 bottom-0">
          <div v-if="isEvergreen" class="progress-bar-text mb-4">
            {{ totalTasks }} Tasks
          </div>
          <div class="placeholder-progress-bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import filter from 'lodash/filter'
import { computed, defineComponent, PropType, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getVariantDisplay } from '@/legacy/components/patient/pathways/lib/sharedPathwayParts'
import ProgressBar from '@/legacy/components/patient/pathways/ProgressBar.vue'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'
import {
  usePathwaysStore,
  isEvergreenPathway,
} from '@/legacy/store/modules/pathways'

import { useTaskApi } from '@/legacy/store/modules/tasks'
import {
  Pathway,
  PathwayStatus,
  closedPathwayStatuses,
  suggestionPathwayStatuses,
  pathwayStatusLabels,
} from '@/legacy/types/pathways/pathways'
import { Task } from '@/legacy/types/pathways/tasks'
export default defineComponent({
  components: {
    TIcon,
    ProgressBar,
    LegacyTDropdown,
  },
  props: {
    pathway: {
      type: Object as PropType<Pathway>,
      required: true,
    },
    allowStatusUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const pathwayTasks = ref(null)

    /**
     * Helper function to fetch and set pathway's tasks
     */
    async function setPathwayTasks() {
      const results = await getTasksForPathway()
      pathwayTasks.value = results.data
    }

    onMounted(async () => {
      if (!isEvergreen.value) {
        await setPathwayTasks()
      }
    })

    // Computing props.pathway as it loses its reactivity on watch()
    const computedPathway = computed(() => props.pathway)
    watch(computedPathway, async () => {
      if (!isEvergreen.value) {
        const results = await getTasksForPathway()
        pathwayTasks.value = results.data
      }
    })

    const currentStatus = computed(() => computedPathway.value.status)
    /**
     * Function to fetch all tasks for pathway prop
     */
    async function getTasksForPathway() {
      return await useTaskApi().listAll({
        params: {
          filter_patient_ids: [route.params.patientId as string],
          filter_task_ids: computedPathway.value.taskIds,
        },
      })
    }

    const pathwayStatusOptions = computed(() => {
      const allOptions = pathwayStatusLabels.map(({ label, value }) => [
        value,
        label,
      ])
      if (currentStatus.value === PathwayStatus.ACTIVE) {
        return allOptions.filter(
          (option: string[]) =>
            option[0] === PathwayStatus.ON_HOLD ||
            option[0] === PathwayStatus.CANCELLED
        )
      } else if (currentStatus.value === PathwayStatus.ON_HOLD) {
        return allOptions.filter(
          (option: string[]) =>
            option[0] === PathwayStatus.ACTIVE ||
            option[0] === PathwayStatus.CANCELLED
        )
      } else if (currentStatus.value === PathwayStatus.SUGGESTED) {
        return [
          { value: PathwayStatus.ACTIVE, label: 'Add Playbook' },
          {
            value: PathwayStatus.SUGGESTION_REJECTED,
            label: 'Reject Suggestion',
          },
        ]
      }
      return []
    })

    const isEvergreen = computed(() =>
      isEvergreenPathway(computedPathway.value.title)
    )

    const showProgressBar = computed(
      () =>
        !isEvergreen.value &&
        !suggestionPathwayStatuses.includes(
          currentStatus.value as PathwayStatus
        )
    )

    const isPathwayClosed = computed(() =>
      closedPathwayStatuses.includes(currentStatus.value as PathwayStatus)
    )

    /**
     *
     * @param v
     */
    async function updatePathwayStatus(v: PathwayStatus) {
      await usePathwaysStore().updatePathway(
        computedPathway.value.pathwayId,
        {
          status: v,
        },
        true
      )
    }

    const status = computed(() => {
      if (isEvergreen.value) {
        return 'lock'
      }
      if (computedPathway.value.status === PathwayStatus.CANCELLED) {
        return 'cancelled'
      }
      if (computedPathway.value.status === PathwayStatus.ON_HOLD) {
        return 'hold'
      }
      if (computedPathway.value.status === PathwayStatus.ACTIVE) {
        return 'go'
      }
      if (computedPathway.value.status === PathwayStatus.COMPLETED) {
        return 'completed'
      }

      return ''
    })

    const totalTasks = computed(() => computedPathway.value.taskIds?.length)

    const completedTasks = computed(
      () =>
        filter(
          pathwayTasks.value,
          (task: Task) => task.status === PathwayStatus.COMPLETED
        ).length
    )

    const label = 'Tasks'

    const goToPathway = (pathwayId: string) => {
      void router.push({
        query: { pathwayId },
      })
    }

    return {
      pathwayTasks,
      showProgressBar,
      isEvergreen,
      goToPathway,
      isPathwayClosed,
      pathwayStatusOptions,
      updatePathwayStatus,
      currentStatus,
      status,
      label,
      totalTasks,
      completedTasks,
      getVariantDisplay,
    }
  },
})
</script>

<style lang="scss">
.pathway-card {
  @apply border rounded-lg w-full relative bg-nash-neutral000 border-solid border-nash-neutral300 mb-3 h-full;
  min-height: 84px;
}

.progress-bar-text {
  @apply mx-4 text-gray-500 text-sm;
}

.placeholder-progress-bar {
  @apply h-1 bg-nash-neutral300 absolute rounded-full inset-x-0 bottom-3 mx-4;
}
</style>
