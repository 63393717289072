<template>
  <div>
    <TModal
      :is-visible="isVisible"
      :title="creating ? 'Add Contact' : 'Other Contacts'"
      :size="
        (!showPhoneNumberVerification && creating) || editingId
          ? ModalSize.LG
          : ModalSize.SM
      "
      :flex="true"
      :allow-overflow="true"
      @close="close"
    >
      <template #preTitle>
        <TIcon
          v-if="creating"
          class="mr-2 cursor-pointer"
          icon="chevronLeft"
          @click="
            () => {
              creating = false
              showPhoneNumberVerification = false
            }
          "
        />
      </template>
      <div v-if="!creating" class="mb-4">
        <TMSecondaryButton
          label="+ Add"
          name="add-contact"
          @click="setIsCreating"
        />
      </div>
      <div v-if="creating" class="h-full">
        <div v-if="showPhoneNumberVerification">
          <PhoneNumberVerification
            :data="nonContactPhoneNumberMaps"
            :saved-form-data="(savedFormData as ContactsFormData)"
            @exit-create-or-edit="reset"
            @submit="submitContactData"
          />
        </div>
        <OtherContactForm v-else :creating="true" @submit="submit" />
      </div>
      <div
        v-if="!creating && !deletingId"
        class="min-h-300 overflow-y-auto h-full flex flex-col gap-4"
      >
        <div v-if="contactPhoneNumberMaps" class="space-y-4">
          <SharedPhoneNumberWarnings
            :phone-number-maps="contactPhoneNumberMaps"
            :is-contact-modal="true"
          />
        </div>
        <div
          v-for="contact in contacts"
          :key="contact.contactId"
          :class="`contact-wrapper ${
            editingId === contact.contactId ? 'editing' : ''
          }`"
        >
          <div
            v-if="!editingId || editingId !== contact.contactId"
            class="flex justify-between items-center"
          >
            <div>
              <h5>
                {{
                  formatName(
                    contactPersonObj(contact)?.firstName,
                    contactPersonObj(contact)?.lastName
                  )
                }}
              </h5>
            </div>
            <LegacyTDropdown
              icon="meatball"
              model-value=""
              size="sm"
              position="bottom-left"
              :expanded="false"
              :unmask="true"
              :show-elements-selected="true"
              :options="contactActions"
              @update:model-value="triggerOptions($event, contact)"
            />
          </div>
          <div v-else>
            <OtherContactForm
              :contact="contact"
              :contact-phone-number-maps="contactPhoneNumberMaps"
              @submit="submit"
            />
          </div>
        </div>
      </div>
      <template #actions>
        <div v-if="!creating" class="mt-3">
          <TMQuaternaryButton name="cancel" label="Cancel" @click="close" />
          <TMPrimaryButton
            :disabled="editingId || creating"
            class="ml-4"
            name="confirm-contact-address"
            label="Confirm"
            @click="close"
          />
        </div>
      </template>
    </TModal>
    <!-- delete warning -->
    <TModal :is-visible="!!deletingId" @close="deletingId = null">
      Delete the following contact from this patient? This action cannot be
      undone.

      <div v-if="deletingContact" class="mt-2 font-bold">
        {{
          formatName(
            contactPersonObj(deletingContact)?.firstName,
            contactPersonObj(deletingContact)?.lastName
          )
        }}
        ({{ deletingContact.relationshipToPatient }})
      </div>

      <template #actions>
        <TMQuaternaryButton
          name="cancel"
          label="Cancel"
          @click="deletingId = null"
        />
        <TMPrimaryButton
          class="ml-4"
          name="delete-contact-address"
          label="Delete"
          @click="deleteContact(deletingId)"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { DropdownTriggerOptions } from '@thyme/nashville/src/types/dropdowns'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { formatName } from '@/legacy/libs/format'

import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useContactsApi, usePatientStore } from '@/legacy/store/modules/patient'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import { USState } from '@/legacy/types/entities/entities'
import {
  MapEntityPhoneNumber,
  PhoneNumberNameMap,
} from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import { Contact, ContactsFormData } from '@/legacy/types/patients/contacts'
import OtherContactForm from '../forms/OtherContactForm.vue'
import { contactPersonObj, upsertContactData } from '../lib/contacts'
import {
  getPhoneNumberEntityMaps,
  mapPhoneNumberToNames,
} from '../lib/sharedPhoneNumberVerificationParts'
import PhoneNumberVerification from '../parts/view/PhoneNumberVerification/PhoneNumberVerification.vue'
import SharedPhoneNumberWarnings from '../parts/view/SharedPhoneNumberWarnings/SharedPhoneNumberWarnings.vue'

export default defineComponent({
  components: {
    SharedPhoneNumberWarnings,
    PhoneNumberVerification,
    TMPrimaryButton,
    TIcon,
    OtherContactForm,
    TMQuaternaryButton,
    TMSecondaryButton,
    TModal,
    LegacyTDropdown,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, context) {
    const deletingId = ref<string | null>(null)
    const editingId = ref<string | null>(null)
    const creating = ref(false)

    const { patient, contacts, person, phoneNumbers } = storeToRefs(
      usePatientStore()
    )

    const showPhoneNumberVerification = ref(false)
    const nonContactPhoneNumberMaps = ref<MapEntityPhoneNumber[]>([])
    const savedFormData = ref<ContactsFormData | null>(null)

    const contactPhoneNumberMaps = ref<PhoneNumberNameMap | null>(null)

    const contactEntityIds = computed(() =>
      Object.values(contacts.value ?? {}).map(
        (contact: Contact) => contact.contactEntityId
      )
    )
    const contactEntityPhoneNumberStrings = computed(() =>
      Object.values(contacts.value ?? {})
        .filter(
          (contact: Contact) => !!contact.contactEntity.phoneNumbers?.length
        )
        .map((contact: Contact) => {
          if (contact.contactEntity.phoneNumbers?.length) {
            return contact.contactEntity.phoneNumbers[0].phoneNumber.phoneNumber
          }
        })
        .filter((el) => el !== undefined)
    )

    /**
     * Function to set up phone number mappings for
     * contacts on patient profile.
     */
    async function setContactPhoneNumberMaps() {
      contactPhoneNumberMaps.value = await mapPhoneNumberToNames(
        contactEntityPhoneNumberStrings.value as string[],
        contactEntityIds.value,
        person.value,
        contacts.value,
        patient.value,
        true
      )
    }
    watch(contactEntityIds, setContactPhoneNumberMaps)
    watch(contacts, setContactPhoneNumberMaps)
    watch(phoneNumbers, setContactPhoneNumberMaps)

    const deletingContact = computed(() => {
      return deletingId.value ? contacts.value?.[deletingId.value] : undefined
    })
    const contactActions = [
      [DropdownTriggerOptions.edit, 'Edit Contact'],
      [DropdownTriggerOptions.delete, 'Delete Contact'],
    ]

    const close = () => {
      reset()
      context.emit('close')
    }

    /**
     * Function to set refs based on delete or edit contact
     * @param value
     * @param contact
     */
    function triggerOptions(value: DropdownTriggerOptions, contact: Contact) {
      if (value === DropdownTriggerOptions.delete) {
        deletingId.value = contact.contactId
      } else if (value === DropdownTriggerOptions.edit) {
        editingId.value = contact.contactId
      }
    }

    /**
     * Function to set create contact mode
     */
    function setIsCreating() {
      creating.value = true
      editingId.value = null
      deletingId.value = null
    }

    /**
     * Function to reset ref values
     */
    function reset() {
      editingId.value = null
      deletingId.value = null
      creating.value = false
      showPhoneNumberVerification.value = false
    }

    /**
     * Function to execute contact deletion
     * @param id
     */
    async function deleteContact(id: string | null) {
      if (!contacts.value || !patient.value || !id) {
        return
      }

      const contactsToKeep = Object.values(contacts.value).filter(
        ({ contactId }) => contactId !== id
      )
      try {
        const contactToDelete = Object.values(contacts.value).find(
          ({ contactId }) => contactId === id
        )
        const contactToDeletePhoneNumbers =
          contactToDelete?.contactEntity?.phoneNumbers
        if (contactToDeletePhoneNumbers?.length) {
          await useMapEntityPhoneNumberApi().delete({
            ids: [contactToDeletePhoneNumbers[0].mapEntityPhoneNumberId],
          })
        }
        await useContactsApi().delete({ ids: [id] })
      } catch (err) {
        console.error(err)
        useNotificationStore().setNotification({
          message: 'Failed to delete contact.',
          type: NotificationType.DANGER,
        })
        return
      }

      usePatientStore().updateContacts(contactsToKeep)
      useNotificationStore().setNotification({
        message: 'Successfully deleted contact.',
        type: NotificationType.SUCCESS,
      })

      reset()
    }

    /**
     *
     * Function to check if passed phone number mappings have for a phone number string
     * are mapped to any other member of contact.
     * @param phoneNumberMappings
     */
    function hasPhoneNumberEntityMaps(
      phoneNumberMappings: MapEntityPhoneNumber[]
    ) {
      if (phoneNumberMappings.length > 0) {
        const contactsArr = Object.values(contacts.value ?? {})
        const contactMapEntityPhoneNumberIds = contactsArr.map(
          (contact) =>
            contact.contactEntity.phoneNumbers &&
            contact.contactEntity.phoneNumbers[0].phoneNumber.phoneNumber
        )

        const nonContactMaps = phoneNumberMappings.filter(
          (mapEntityPhone) =>
            !contactMapEntityPhoneNumberIds.includes(
              mapEntityPhone.mapEntityPhoneNumberId
            )
        )
        nonContactPhoneNumberMaps.value = nonContactMaps
        return !!nonContactMaps.length
      }
      return false
    }

    /**
     * Function to submit udpated/created contact
     * @param data
     */
    async function submit(data: ContactsFormData) {
      if (!editingId.value) {
        if (data.phoneNumber.length) {
          const phoneNumberMappings = await getPhoneNumberEntityMaps(
            data.phoneNumber,
            true
          )
          const phoneIsMappedToOtherEntities =
            hasPhoneNumberEntityMaps(phoneNumberMappings)
          if (phoneIsMappedToOtherEntities) {
            savedFormData.value = data
            showPhoneNumberVerification.value = true
            return
          }
        }
      }

      await submitContactData(data)
    }

    /**
     *
     * Passthrough function to submit contact data for edits/creates.
     * @param data
     */
    async function submitContactData(data: ContactsFormData) {
      await upsertContactData(
        data,
        patient.value,
        contacts.value,
        editingId.value
      )
      reset()
    }

    return {
      contactPhoneNumberMaps,
      contacts,
      patient,
      submitContactData,
      showPhoneNumberVerification,
      nonContactPhoneNumberMaps,
      savedFormData,
      submit,
      USState,
      setIsCreating,
      creating,
      reset,
      deleteContact,
      editingId,
      triggerOptions,
      deletingContact,
      deletingId,
      contactActions,
      close,
      contactPersonObj,
      formatName,
      ModalSize,
    }
  },
})
</script>
<style lang="scss">
.contact-wrapper {
  @apply border border-nash-neutral400 rounded-lg py-6 px-4;
  &.editing {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
