// API RESPONSE TYPES

export enum PatientExternalLinkTarget {
  ONCO_EMR = 'onco_emr',
  ELATION = 'elation',
  CANOPY = 'canopy',
  ZUS = 'zus',
}

export type PatientExternalLink = {
  target: PatientExternalLinkTarget
  url: string
}

// COMPONENT PROPS

export const patientExternalLinksProps = {
  patientId: {
    type: String,
    required: true,
  },
} as const
