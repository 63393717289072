import { DropdownKV, LegacyDropdownKV } from './dropdowns'

export type SelectOptions =
  | string[]
  | string[][]
  | LegacyDropdownKV[]
  | DropdownKV[]
  | null
  | [null, string][]
  | (string | null)[][]

export enum SelectStyle {
  Black = 'black',
  Gray = 'gray',
}
