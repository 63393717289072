<template>
  <TDropdown
    name="phone-select"
    :options="phoneDropdownOptions"
    :initial-value="initialValue"
    :label="label"
    option-label="label"
    option-value="value"
    placeholder="Choose Contact"
    @update:model-value="updateSelection"
    @blur="$emit('blur', $event)"
  >
    <template #option="slotProps">
      <div class="bold">{{ slotProps.option.label }}</div>
      <label class="text-gray-400 text-xs">{{ slotProps.option.detail }}</label>
    </template>
  </TDropdown>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import { Entity } from '@/legacy/types/entities/entities'

import { PhoneDropdownValue } from '../lib/phoneDropdown'
import setup from './controller'

export default defineComponent({
  components: {
    TDropdown,
  },
  props: {
    modelValue: {
      type: Object as PropType<PhoneDropdownValue>,
      required: true,
    },
    label: { type: String, default: '' },
    entities: {
      type: Array as PropType<Entity[]>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    includeNonContactable: {
      type: Boolean,
      default: true,
    },
    mustBeTextableAndOptedIn: {
      type: Boolean,
      default: false,
    },
    mustBeTextableIncludingUnconsented: {
      type: Boolean,
      default: false,
    },
    emptyOptionLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['update:model-value', 'blur'],
  setup,
})
</script>
