<template>
  <div>
    <TModal
      v-if="!showAddOrEditModal"
      :is-visible="true"
      :size="ModalSize.LG"
      title="Manage Radiations"
      @close="close"
    >
      <div>
        <div class="flex justify-between pb-2 items-center">
          <TTab
            :tabs="statuses"
            :active="selectedStatus"
            @select="selectStatus"
          />
          <div v-tooltip.left="addButtonDisabledMessage">
            <TMSecondaryButton
              label="+ Add Radiation"
              :disabled="addButtonDisabled"
              @click="toggleAddOrEditModal(null)"
            />
          </div>
        </div>
        <div class="space-y-4">
          <div
            v-for="radiation in radiationsByStatus"
            :key="radiation.patientRadiationId"
          >
            <div class="date-container">
              <div>
                <div class="font-medium text-sm">
                  {{ radiation.radiationDateDisplay }}
                </div>
                <div class="font-normal text-sm mt-2">
                  {{ radiation.additionalInfo }}
                </div>
              </div>
              <TOIconDropdown
                v-if="showEditOptionDropdown"
                name="radiation-edit-menu"
                icon="meatball"
                :options="editOptionsArray"
                @change="
                  (v) => openModal(v.value, radiation.patientRadiationId)
                "
              />
            </div>
          </div>
        </div>
        <div v-if="showMoreCurrentRadiationDatesButton">
          <TMTertiaryButton
            label="Show More"
            class="pt-2 mb-3"
            icon-pos="right"
            :icon="'chevronDown'"
            @click="loadMore"
          />
        </div>
      </div>
      <template #actions>
        <TMQuaternaryButton
          label="Cancel"
          name="manage-radiation-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="manage-radiation-save"
          label="Save"
          @click="onSave"
        />
      </template>
    </TModal>
    <DeleteRadiation
      v-if="showDeleteModal && deleteRadiationId"
      :radiation-id="deleteRadiationId"
      @close="toggleDeleteModal"
      @refetch="refetchCurrentRadiation"
    />
    <AddOrEditRadiation
      v-if="showAddOrEditModal"
      :cancer-diagnosis-id="cancerDxId"
      :show-add="!editRadiationId"
      :radiation-id="editRadiationId ?? ''"
      :initial-data="editModalInitialData"
      @close="toggleAddOrEditModal"
      @refetch="refetchCurrentRadiation"
    />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TTab from '@nashville/tab/TTab.vue'
import { defineComponent } from 'vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import AddOrEditRadiation from './AddOrEditRadiation/AddOrEditRadiation.vue'
import { setup } from './controller'
import DeleteRadiation from './DeleteRadiation/DeleteRadiation.vue'
import { manageRadiationProps } from './types'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    TMTertiaryButton,
    TMPrimaryButton,
    TModal,
    TTab,
    TMSecondaryButton,
    DeleteRadiation,
    TOIconDropdown,
    AddOrEditRadiation,
  },
  props: manageRadiationProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.date-container {
  @apply flex justify-between border border-nash-neutral400 p-4 rounded-lg;
}
</style>
