import { PropType } from 'vue'
import { object, string, InferType, array, date } from 'yup'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

// edit modal schema
export const schema = object({
  diagnosisDate: date().nullable(),
  otherOncologyDxType: string().nullable(),
  oncologyDiagnosisRefId: string().nullable(),
  oncologyDiagnosisCategoryRefId: string().nullable(),
  oncologySpreadRefIds: array().of(string().required()).nullable(),
  medications: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
  additionalContext: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
})

export type EditCancerDetailsForm = InferType<typeof schema>

export const editCancerDetailsProps = {
  patientId: {
    type: String,
    required: true,
  },
  cancerDetailsId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditCancerDetailsForm>>,
    required: true,
  },
} as const

export const NO_CANCER_SET_MSG =
  'Please select a cancer diagnosis or enter a custom one if set to OTHER.'
