<template>
  <div>
    <h2>TDatePicker Debug</h2>
    <TDatePicker data-cy="tDatePicker-base" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'

export default defineComponent({
  components: { TDatePicker },
  setup() {
    return {}
  },
})
</script>
