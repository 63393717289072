import apiStore from '@/legacy/store/modules/apiBuilder'
import { Radiation } from '@/pages/PatientProfile/CarePlans/shared/types'

export const usePreviousCancerRadiationApi = apiStore<Radiation>(
  'previousCancerRadiationApi',
  '/api/care_plan/patient_radiations',
  {
    transformData: (d: { data: Radiation[] }) => d,
  }
)
