import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { Method, NO_CACHE } from '@thyme/libs/src/api/types/api'
import { isArray } from 'lodash'
import omit from 'lodash/omit'
import { defineStore } from 'pinia'
import { apiRequest } from '@/legacy/libs/api'
import { toIdMap } from '@/legacy/libs/store'
import { SaveState } from '@/legacy/types/api/api'
import {
  ApiActionOptions,
  ApiState,
  ApiStoreOptions,
  DataType,
  MAX_PAGE_SIZE,
  PassedSetupOptions,
  SetupOptions,
} from '@/legacy/types/api/apiBuilder'
import { createUrlWithId } from '@/libs/api/url'

export const defaultHeaders = { 'Cache-Control': NO_CACHE }

/**
 *
 * @param storeName
 */
export function setDefaultHeaders(storeName: string) {
  return {
    'thymebox-api-store': storeName,
    ...defaultHeaders,
  }
}

/**
 * Transform list of items into combination of
 * IdMap and list for ease of updating list in place
 * @param idLocation
 */
export function transformListTo<
  T extends { [key: string | number | symbol]: any }
>(idLocation: keyof T) {
  return (d: { data: T[] }) => ({
    ...d,
    data: d.data.map((item: T) => item[idLocation] as string),
    idMap: toIdMap<T>(d.data, idLocation),
  })
}

/**
 * Types:
 *   T - Singular Output in datum (SINGULAR TYPE) post-transform
 *   S - Output in data (PLURAL TYPE) post-transform
 * State:
 *   data - reactive data from PLURAL type calls
 *   datum - reactive datum from SINGULAR type calls
 *   isLoading - request in transit (useful for loading indicators)
 *   isLoaded - at least one request has succeeded (useful for keeping components alive after first load)
 *   error - Error object if an error occurred
 * Recommended External Actions:
 *   updateData - replace data or datum in state with new data
 *   list - GET LIST call to API -> PLURAL
 *   retrieve - GET call to API/id -> SINGULAR
 *   create - POST call to API -> SINGULAR
 *   upsert - PUT call to API -> SINGULAR
 *   update - PUT call to API/id -> SINGULAR
 *   partialUpdate - PATCH call to API/id -> SINGULAR
 *   delete - DELETE call to API/id -> SINGULAR
 * Url ID Replacement:
 *   putting ":id" in your url string will allow direct replacement of ids when being called with ids argument
 *   Ex:  url= "api/foo/:id/bar/:id/"  ids = ["1", "2"]  final url = "api/foo/1/bar/2/"
 * @param storeName
 * @param url
 * @param options
 */
export default <T, S = T[]>(
  storeName: string,
  url: string,
  options: ApiStoreOptions<T, S> = {}
) =>
  defineStore(storeName, {
    state: (): ApiState<T, S> => ({
      data: null,
      datum: null,
      isLoading: false,
      isLoaded: false,
      error: null,
      queryMetadata: null,
      idMap: null,
    }),
    getters: {
      computedData: (state) => {
        if (isArray(state.data) && state.idMap) {
          return state.data.map((id: string) => (state.idMap ?? {})[id])
        }
        return state.data
      },
    },
    actions: {
      reset() {
        this.isLoading = false
        this.isLoaded = false
        this.error = null
        this.queryMetadata = null
        this.data = null
        this.datum = null
      },
      transformData(data: T[] | null): Partial<ApiState<T, S | T[] | null>> {
        return options.transformData
          ? options.transformData(data, this.$state)
          : {
              ...omit(this.$state, 'data', 'datum'),
              // explicitly append for UnWrap<T> Type issues
              ...(this.$state.datum as T | null),
              data,
            }
      },
      transformDatum(
        datum: T | null
      ): Partial<ApiState<T | null, S | unknown>> {
        return options.transformDatum
          ? options.transformDatum(datum, this.$state)
          : {
              ...omit(this.$state, 'datum'),
              datum,
            }
      },
      transform(data: any, type: DataType) {
        if (type === DataType.SINGULAR) {
          return this.transformDatum(data as T)
        } else if (type === DataType.PLURAL) {
          return this.transformData(data as T[])
        }
      },
      setPending(saveState?: SaveState) {
        this.isLoading = true
        if (saveState) {
          saveState.isSaving = true
          saveState.lastCallAt = new Date()
        }
      },
      setSuccess(data: T | T[] | null, type: DataType, saveState?: SaveState) {
        this.updateData(data, type)
        this.isLoading = false
        this.isLoaded = true
        this.error = null

        // since saveState may be monitoring some subset of calls
        // by a consumer, update it separately from the store's
        // error handling
        if (saveState) {
          saveState.isSaving = false
          saveState.error = null
        }
      },
      setError(error: ApiError | Error, saveState?: SaveState) {
        this.error = error
        this.isLoading = false
        this.isLoaded = true

        // since saveState may be monitoring some subset of calls
        // by a consumer, update it separately from the store's
        // error handling
        if (saveState) {
          saveState.isSaving = false
          saveState.error = error
        }
      },
      isQueued() {
        return !this.isLoaded && this.isLoading
      },
      /**
       * Break large global "fetch all" list calls into segments
       *  DOES NOT WORK WITH PAGE_TOKEN
       * @param method
       * @param url
       * @param options
       */
      async splitApiActionCall(
        method: Method,
        url: string,
        options: ApiActionOptions
      ) {
        // ensure check passes first time
        let itemsToGrab = MAX_PAGE_SIZE + 1
        // @ts-ignore params is guaranteed here
        options.params.page_length = options.params.page_length ?? MAX_PAGE_SIZE
        // @ts-ignore params is guaranteed here
        options.params.page_number = options.params.page_number ?? 1
        let grabbedItems = 0
        let data: any[] = []
        while (itemsToGrab - grabbedItems > 0) {
          grabbedItems += MAX_PAGE_SIZE
          const response = await apiRequest<any>(method, url, options)
          data = [...data, ...response.data]
          itemsToGrab = response.queryMetadata?.total ?? itemsToGrab
          // @ts-ignore params is guaranteed here
          options.params.page_number++
        }
        return { data, queryMetadata: { total: itemsToGrab } }
      },
      async apiAction(
        method: Method,
        url: string,
        dataType: DataType,
        options: ApiActionOptions,
        setup?: SetupOptions
      ) {
        const saveState = setup?.saveState
        this.setPending(saveState)
        try {
          let data
          if (setup?.fetchAll) {
            data = await this.splitApiActionCall(method, url, options)
          } else {
            data = await apiRequest<any>(method, url, options)
          }
          this.setSuccess(data, dataType, saveState)
          return data
        } catch (err) {
          if (err instanceof Error) {
            console.error(err.message)
            this.setError(err, saveState)
          } else {
            console.error(err)
          }
          /**
           *  bubbleErrorThrow: true -> bubble error for try/catch workflows
           *  WARNING WILL CAUSE SERIOUS PAGE BREAKAGE IF NOT CAUGHT
           */
          if (setup?.bubbleErrorThrow) {
            throw err
          }
        }
      },
      updateData(newData: T | T[] | null, type: DataType) {
        Object.assign(this.$state, this.transform(newData, type))
      },
      /**
       * Bypass transform
       * @param data
       * @deprecated use normal updateData flow instead
       */
      setData(data: T | T[]) {
        Object.assign(this.$state, {
          ...omit(this.$state, 'data', 'datum'),
          // explicitly append for UnWrap<T> Type issues
          ...(this.$state.datum as T | null),
          data,
        })
      },
      /**
       * replace item in idMap with newly updated version
       * @param param0
       * @param param0.id
       * @param param0.item
       */
      async updateInPlace({ id, item }: { id: string; item: T }) {
        if (this.idMap && this.idMap[id]) {
          this.idMap[id] = item
        }
      },
      async list({
        headers = setDefaultHeaders(storeName),
        params = {},
        ids = null,
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        ids?: string | string[] | null
        metaOptions?: PassedSetupOptions
      }) {
        return await this.apiAction(
          'GET' as Method,
          createUrlWithId(url, ids),
          DataType.PLURAL,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
          },
          metaOptions
        )
      },
      async listAll({
        headers = setDefaultHeaders(storeName),
        params = {},
        ids = null,
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        ids?: string | string[] | null
        metaOptions?: PassedSetupOptions
      }) {
        return await this.apiAction(
          'GET' as Method,
          createUrlWithId(url, ids),
          DataType.PLURAL,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
          },
          { ...metaOptions, fetchAll: true }
        )
      },
      async retrieve({
        headers = setDefaultHeaders(storeName),
        params = {},
        ids = null,
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        ids?: string | string[] | null
        metaOptions?: PassedSetupOptions
      }) {
        if (!ids) {
          this.setError(new Error(`Id required for retrieve on ${url}`))
          return
        }
        return await this.apiAction(
          'GET' as Method,
          createUrlWithId(url, ids),
          DataType.SINGULAR,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
          },
          metaOptions
        )
      },
      async create({
        headers = setDefaultHeaders(storeName),
        params = {},
        body = {},
        ids = null,
        file = null,
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        body?: { [key: string]: any }
        ids?: string | string[] | null
        file?: File | null
        metaOptions?: PassedSetupOptions
      }) {
        return await this.apiAction(
          'POST' as Method,
          createUrlWithId(url, ids),
          DataType.SINGULAR,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
            body,
            file,
          },
          metaOptions
        )
      },
      async upsert({
        headers = setDefaultHeaders(storeName),
        params = {},
        body = {},
        ids = null,
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        body?: { [key: string]: any }
        ids?: string | string[] | null
        metaOptions?: PassedSetupOptions
      }) {
        return await this.apiAction(
          'PUT' as Method,
          createUrlWithId(url, ids),
          DataType.SINGULAR,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
            body,
          },
          metaOptions
        )
      },
      async update({
        headers = setDefaultHeaders(storeName),
        params = {},
        body = {},
        ids = null,
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        body?: { [key: string]: any }
        ids?: string | string[] | null
        metaOptions?: PassedSetupOptions
      }) {
        if (!ids) {
          this.setError(new Error(`Id required for update on ${url}`))
          return
        }
        return await this.apiAction(
          'PUT' as Method,
          createUrlWithId(url, ids),
          DataType.SINGULAR,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
            body,
          },
          metaOptions
        )
      },
      async partialUpdate({
        headers = setDefaultHeaders(storeName),
        params = {},
        body = {},
        ids = null,
        extraHeaders = {},
        metaOptions,
      }: {
        headers?: { [key: string]: string }
        params?: { [key: string]: any }
        body?: { [key: string]: any }
        ids?: string | string[] | null
        extraHeaders?: { [key: string]: string }
        metaOptions?: PassedSetupOptions
      }) {
        if (!ids) {
          this.setError(new Error(`Id required for partialUpdate on ${url}`))
          return
        }

        return await this.apiAction(
          'PATCH' as Method,
          createUrlWithId(url, ids),
          DataType.SINGULAR,
          {
            headers: {
              ...(options.headers ?? {}),
              ...headers,
              ...extraHeaders,
            },
            params: { ...(options.params ?? {}), ...params },
            body,
          },
          metaOptions
        )
      },
      async delete({
        headers = setDefaultHeaders(storeName),
        ids = null,
        metaOptions,
        params = {},
      }: {
        headers?: { [key: string]: string }
        ids?: string | string[] | null
        metaOptions?: PassedSetupOptions
        params?: { [key: string]: any }
      }) {
        if (!ids) {
          this.setError(new Error(`Id required for delete on ${url}`))
          return
        }
        return await this.apiAction(
          'DELETE' as Method,
          createUrlWithId(url, ids),
          DataType.SINGULAR,
          {
            headers: { ...(options.headers ?? {}), ...headers },
            params: { ...(options.params ?? {}), ...params },
          },
          metaOptions
        )
      },
    },
  })
