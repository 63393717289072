import { UUID } from '@/legacy/types/global/base'

// TYPES --------------------
export type DateTimeUTC = string
export type DateTimeLocal = string
export type Timestamps = {
  createdAt: DateTimeUTC
  updatedAt: DateTimeUTC
  createdBy: UUID
  updatedBy: UUID
}

// CONSTANTS --------------------
// max 10 business days
export const MAX_HIGH_PRIORITY_REATTEMPT_BUSINESS_DAYS = 10
// max 60 business days including weekends
export const MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS = 60
// max 90 days including weekends
export const MAX_ENROLLMENT_REATTEMPT_DAYS = 90

// Enables date picker to disable weekend days
export const WEEK_START = 0
export const WEEK_END = 6
export const unselectableDaysOfWeek = [WEEK_START, WEEK_END]

export const today = new Date()

export const minDateToday = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate()
)

export const minDateTomorrow = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + 1
)
