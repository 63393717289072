import apiStore from '@/legacy/store/modules/apiBuilder'
import { MapOncDxRefOncTherapyRef } from '@/pages/PatientProfile/CarePlans/shared/types'

export const useMapOncDxRefOncTherapyRefApi =
  apiStore<MapOncDxRefOncTherapyRef>(
    'mapOncDxRefOncTherapyRefApi',
    '/api/map_onc_dx_ref_onc_therapy_ref',
    {
      transformData: (d: { data: MapOncDxRefOncTherapyRef[] }) => d,
    }
  )
