<template>
  <div
    class="bg-nash-neutral000 shadow rounded-lg p-4 border border-stone-300 gap-4 inline-flex whitespace-pre-wrap relative"
  >
    <LegacyTButton
      name="toggle-referral"
      :icon="buttonIcon"
      type="blackAndWhite"
      class="text-thyme-darkGray absolute top-4 right-4"
      @click="toggleExpansion"
    />
    <div v-if="!isExpanded">
      <h2 class="font-bold">
        {{ formatCollapsedTitle(referral.consultant, referral.specialty) }}
      </h2>
    </div>
    <div v-else>
      <PrintableComponent
        :title="referral.consultant"
        :print-title="false"
        :primary="false"
        :unique-id="'referralPrintElement-' + referral.id"
      >
        <i>Member Information</i>
        <div>
          <b>For: </b
          >{{
            formatName(referral.patient.firstName, referral.patient.lastName)
          }}
          <br />
          <b>DOB: </b
          >{{ formatCleanDateTime(stringToDateTime(referral.patient.dob)) }}
        </div>
        <br />

        <i>Referral Information</i>
        <div>
          <b>Consultant: </b>{{ referral.consultant }}<br />
          <b>Date of Referral: </b
          >{{ formatDateTime(stringToDateTime(referral.referralDate)) }}<br />
          <b>Reason for Referral: </b>{{ referral.referralReason }}<br />
          <b>Authorization For: </b>{{ referral.authorizationFor }}<br />
          <b>Dxs: </b><br />{{ formatDxs(referral.diagnoses) }}
        </div>
        <br />

        <div>
          Signed Electronically By
          {{ referral.signedBy }},
          {{ formatDateTimeWithTime(stringToDateTime(referral.signedAt)) }}
        </div>
      </PrintableComponent>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'

import PrintableComponent from '@/legacy/components/PrintableComponent.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatCleanDateTime,
  formatDateTime,
  formatName,
  formatDateTimeWithTime,
} from '@/legacy/libs/format'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import { ElationDiagnoses, Referral } from '@/legacy/types/patients/referrals'

export default defineComponent({
  components: { PrintableComponent, LegacyTButton },
  props: {
    referral: { type: Object as PropType<Referral>, required: true },
  },
  setup() {
    const isExpanded = ref(false)

    /**
     *
     * @param dxs list of diagnoses objects to format
     */
    function formatDxs(dxs: ElationDiagnoses[]): string {
      return dxs.map((dx) => `- ${dx.description} (${dx.code})`).join('\n')
    }

    /**
     *
     * @param consultant consultant name
     * @param specialty specialty name
     */
    function formatCollapsedTitle(
      consultant: string,
      specialty?: string
    ): string {
      if (specialty) {
        return `${consultant} (${specialty})`
      } else {
        return consultant
      }
    }

    /**
     * Toggles the visibility of the referral's info
     */
    function toggleExpansion() {
      isExpanded.value = !isExpanded.value
    }

    const buttonIcon = computed(() =>
      isExpanded.value ? 'chevronUp' : 'chevronDown'
    )

    return {
      formatCleanDateTime,
      formatCollapsedTitle,
      toggleExpansion,
      isExpanded,
      buttonIcon,
      formatDxs,
      formatDateTime,
      formatName,
      stringToDateTime,
      formatDateTimeWithTime,
    }
  },
})
</script>
