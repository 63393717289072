<template>
  <TMActionDropdown class="p-icon-dropdown" v-bind="$attrs">
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
  </TMActionDropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TMActionDropdown from './TMActionDropdown.vue'

export default defineComponent({
  components: { TMActionDropdown },
  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped></style>
