<template>
  <!-- @vue-ignore Submit generics not compatible with specifics -->
  <VForm
    v-slot="{ values, setFieldValue }"
    :initial-values="initialValues"
    @submit="submit"
  >
    <button
      v-if="!creating"
      class="flex w-full flex-row-reverse font-bold text-nash-purple500"
      name="edit-other-contact"
    >
      Save
    </button>
    <div class="columns space-y-2">
      <div class="column is-half">
        <div>
          <TInputText
            :model-value="initialValues.firstName"
            name="firstName"
            label="First name"
            rules="required"
          />
          <TInputText
            :model-value="initialValues.lastName"
            name="lastName"
            label="Last name"
            rules="required"
          />
        </div>

        <div>
          <TDatePicker
            name="dateOfBirth"
            label="Date of birth"
            :is-datetime="false"
            color="black"
          />

          <div>
            <label class="font-bold">Relationship to patient</label>
            <TDropdown
              v-model="values.relationshipToPatient"
              class="w-full bg-white"
              input-id="relationshipToPatient"
              option-label="label"
              option-value="value"
              :options="relationshipToPatientOptions"
              @update:model-value="
                (v: any) => setFieldValue('relationshipToPatient', v)
              "
            />
          </div>
        </div>
        <div>
          <label class="font-bold"
            >Has permission to receive information about patient's care?</label
          >
          <TDropdown
            v-model="values.approvedToAccessMedInfo"
            class="w-full bg-white"
            input-id="approvedToAccessMedInfo"
            option-label="label"
            option-value="value"
            :options="approvedToAccessMedInfoOptions"
            :show-clear="false"
            @update:model-value="
                (v: any) => setFieldValue('approvedToAccessMedInfo', v)
              "
          />
        </div>
        <div>
          <label class="font-bold"
            >Has permission to speak on behalf of the patient?</label
          >
          <TDropdown
            v-model="values.approvedToSpeak"
            class="w-full bg-white"
            input-id="approvedToSpeak"
            option-label="label"
            option-value="value"
            :show-clear="false"
            :options="[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]"
            @update:model-value="
                (v: any) => setFieldValue('approvedToSpeak', v)
              "
          />
        </div>

        <div>
          <label class="font-bold">Is this the primary caregiver?</label>
          <TDropdown
            v-model="values.isPrimaryCaregiver"
            class="w-full bg-white"
            input-id="isPrimaryCaregiver"
            option-label="label"
            option-value="value"
            :show-clear="false"
            :options="[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]"
            @update:model-value="
                (v: any) => setFieldValue('isPrimaryCaregiver', v)
              "
          />
        </div>

        <div>
          <label class="font-bold"
            >Is this caregiver the patient's Healthcare Proxy or Healthcare
            Power of Attorney?</label
          >
          <TDropdown
            v-model="values.caregiverPoa"
            class="w-full bg-white"
            input-id="caregiverPoa"
            option-label="label"
            option-value="value"
            :show-clear="false"
            :options="[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]"
            @update:model-value="
                (v: any) => setFieldValue('caregiverPoa', v)
              "
          />
        </div>
        <TInputText
          :model-value="initialValues.phoneNumber"
          name="phoneNumber"
          label="Phone number"
          rules="phone"
        />
        <div class="mt-2">
          <label class="font-bold">Texting Consent Status</label>
          <TDropdown
            class="bg-nash-neutral000 w-full mt-1.5 mb-1"
            name="texting-consent"
            :disabled="
              contact && contact.contactEntity?.phoneNumbers?.length
                ? contact.contactEntity.phoneNumbers[0].phoneNumber.type ===
                  PhoneNumberType.LANDLINE
                : false
            "
            :options="consentStatusOptions"
            :initial-value="
              values.messagingOptInStatus === null
                ? NULL_STRING
                : values.messagingOptInStatus
            "
            option-label="label"
            option-value="value"
            @update:model-value="
              (e) => {
                setFieldValue(
                  'messagingOptInStatus',
                  e === NULL_STRING ? null : e
                )
              }
            "
          />
        </div>

        <label class="font-bold"> Preferred language </label>
        <TDropdown
          v-model="values.preferredLanguage"
          class="w-full bg-white"
          input-id="preferredLanguage"
          option-label="label"
          option-value="value"
          :options="languageOptions"
          :show-clear="false"
          @update:model-value="
                (v: any) => setFieldValue('preferredLanguage', v)
              "
        />
      </div>

      <div class="column space-y-2">
        <TInputText
          :model-value="initialValues.streetAddressLine1"
          name="streetAddressLine1"
          label="Street address"
          rules="required_if_any:@streetAddressLine1,@streetAddressLine2,@city,@state,@zip"
        />

        <TInputText
          :model-value="initialValues.streetAddressLine2"
          name="streetAddressLine2"
          label="Apartment, suite, etc"
          help="(optional)"
        />

        <TInputText
          :model-value="initialValues.city"
          name="city"
          label="City"
          rules="required_if_any:@streetAddressLine1,@streetAddressLine2,@city,@state,@zip"
        />

        <label class="font-bold"> State </label>
        <TDropdown
          v-model="values.state"
          class="w-full bg-white"
          input-id="state"
          option-label="label"
          option-value="value"
          :options="stateOptions"
          :show-clear="false"
          @update:model-value="
                (v: any) => setFieldValue('state', v)
              "
        />

        <TInputText
          :model-value="initialValues.zip"
          name="zip"
          label="ZIP code"
          rules="required_if_any:@streetAddressLine1,@streetAddressLine2,@city,@state,@zip|digits:5"
        />
      </div>
    </div>
    <div v-if="creating" class="mt-2 flex flex-row-reverse">
      <button class="p-button p-component mt-2" name="save-other-contact">
        Save
      </button>
    </div>
  </VForm>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { DateTime } from 'luxon'
import { Form as VForm } from 'vee-validate'
import { computed, defineComponent, PropType } from 'vue'
import { enumValueToKey, lookupEnum } from '@/legacy/libs/enum'
import { formatName } from '@/legacy/libs/format'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'

import { USState } from '@/legacy/types/entities/entities'
import { Language } from '@/legacy/types/entities/people'
import { PhoneNumberType } from '@/legacy/types/entities/phoneNumbers'
import { NULL_STRING } from '@/legacy/types/global/strings'
import {
  Contact,
  ContactsFormData,
  NewRelationshipToPatient,
  ApprovedToAccessMedInfo,
} from '@/legacy/types/patients/contacts'

export default defineComponent({
  components: {
    TInputText,
    VForm,
    TDropdown,
    TDatePicker,
  },
  props: {
    creating: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object as PropType<Contact>,
      default: null,
    },
  },
  emits: ['submit'],
  setup(props, context) {
    const languageOptions = computed(() => {
      return Object.entries(Language).map(([k, v]) => {
        return { value: k, label: v }
      })
    })
    const relationshipToPatientOptions = computed(() => {
      return Object.entries(NewRelationshipToPatient).map(([k, v]) => {
        return { value: k, label: v }
      })
    })
    const approvedToAccessMedInfoOptions = computed(() => {
      return Object.entries(ApprovedToAccessMedInfo).map(([k, v]) => {
        return { value: k, label: v }
      })
    })
    const stateOptions = computed(() => {
      return [{ value: NULL_STRING, label: '' }].concat(
        Object.entries(USState).map(([k, v]) => {
          return { value: k, label: v }
        })
      )
    })

    const consentStatusOptions = [
      { label: 'Waiting for Response/Unknown', value: NULL_STRING },
      { label: 'Consented to Texting', value: true },
      { label: 'Opted Out of Texting', value: false },
    ]

    /**
     * Relationship to patient is optional so return nothing if undefined
     * Otherwise, options have changed in structured onboarding so
     * fallback to "Other" if current value not available in options
     * @param relationshipToPatient
     */
    function getRelationshipToPatientWithFallback(
      relationshipToPatient: string | null
    ) {
      const maybeRelationship = lookupEnum(
        NewRelationshipToPatient,
        relationshipToPatient?.toLowerCase()
      )
      if (maybeRelationship) {
        return relationshipToPatient?.toLowerCase()
      } else {
        return enumValueToKey(
          NewRelationshipToPatient,
          NewRelationshipToPatient.other
        )
      }
    }

    const initialValues = computed((): ContactsFormData => {
      const initialForm: ContactsFormData = {
        streetAddressLine1: '',
        streetAddressLine2: '',
        city: '',
        state: '',
        zip: '',

        firstName: '',
        lastName: '',
        preferredLanguage: 'english',

        phoneNumber: '',
        dateOfBirth: undefined,
        messagingOptInStatus: null,

        approvedToSpeak: 'false',
        isPrimaryCaregiver: undefined,
        caregiverPoa: undefined,
        relationshipToPatient: '',
        approvedToAccessMedInfo: 'UNKNOWN',
      }

      if (props.creating || !props.contact) {
        return initialForm
      }

      const {
        isPrimaryCaregiver,
        caregiverPoa,
        approvedToSpeak,
        relationshipToPatient,
        approvedToAccessMedInfo,
        contactEntity,
      } = props.contact

      initialForm.approvedToSpeak = approvedToSpeak.toString()
      initialForm.isPrimaryCaregiver = isPrimaryCaregiver?.toString()
      initialForm.caregiverPoa = caregiverPoa?.toString()
      initialForm.relationshipToPatient = getRelationshipToPatientWithFallback(
        relationshipToPatient
      )
      initialForm.approvedToAccessMedInfo = approvedToAccessMedInfo ?? undefined

      if (contactEntity.phoneNumbers) {
        const phone = contactEntity.phoneNumbers[0]
        if (phone) {
          initialForm.phoneNumber = phone.phoneNumber.phoneNumber
          initialForm.messagingOptInStatus =
            phone.phoneNumber.messagingOptInStatus
        }
      }

      if (contactEntity.addresses) {
        const address = contactEntity.addresses[0]
        if (address) {
          initialForm.streetAddressLine1 = address.streetAddressLine1
          initialForm.streetAddressLine2 = address.streetAddressLine2
          initialForm.city = address.city
          initialForm.state = address.state
          initialForm.zip = address.zip
        }
      }

      if (contactEntity.person) {
        initialForm.firstName = contactEntity.person.firstName
        initialForm.lastName = contactEntity.person.lastName
        initialForm.preferredLanguage =
          contactEntity.person.preferredLanguage?.toLowerCase() ?? ''
        if (contactEntity.person.dateOfBirth) {
          const dob = DateTime.fromISO(contactEntity.person.dateOfBirth)
          initialForm.dateOfBirth = dob.toJSDate()
        }
      }

      return initialForm
    })

    /**
     * Function to emit back form submit to parent component
     * @param data
     */
    function submit(data: ContactsFormData) {
      context.emit('submit', data)
    }

    return {
      PhoneNumberType,
      relationshipToPatientOptions,
      approvedToAccessMedInfoOptions,
      getRelationshipToPatientWithFallback,
      languageOptions,
      submit,
      USState,
      initialValues,
      close,
      formatName,
      consentStatusOptions,
      NULL_STRING,
      stateOptions,
    }
  },
})
</script>
