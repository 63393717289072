<template>
  <label>
    <div v-if="label" class="font-bold mt-2">{{ label }}</div>
    <div v-if="label && help">{{ help }}</div>
    <div :data-cy="name && `${name}-datepicker`" :class="[...pickerClasses()]">
      <component
        :is="isDatetime ? 'o-datetimepicker' : 'o-datepicker'"
        :model-value="inputValue ? inputValue : undefined"
        :expanded="expanded"
        :inline="inline"
        :disabled="disabled"
        :min-date="minDate"
        :max-date="maxDate"
        :unselectable-days-of-week="unselectableDaysOfWeek"
        :range="range"
        :placeholder="placeholder"
        @update:model-value="setValue"
        @blur="
          (e: Event) => {
            handleBlur(e)
            $emit('blur', e)
          }
        "
      >
        <template #footer>
          <LegacyTButton
            v-if="showNowButton"
            :name="`${name}-show-now`"
            value="Now"
            type="primary"
            :icon="isDatetime ? 'clock' : 'calendar'"
            @click="setValue(new Date())"
          />
          <LegacyTButton
            v-if="clearable"
            :name="`${name}-clear`"
            value="Clear"
            type="danger"
            icon="close"
            @click="clear()"
          />
        </template>
      </component>
    </div>
    <ErrorMessage :name="name" data-cy="warning" class="text-red-500 text-sm" />
  </label>
</template>

<script lang="ts">
import { DateTimePickerStyle } from '@thyme/nashville/src/types/datepicker'
import { ErrorMessage, useField } from 'vee-validate'
import { defineComponent, PropType, watch } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

export default defineComponent({
  components: {
    ErrorMessage,
    LegacyTButton,
  },
  props: {
    minDate: { type: Date, default: null },
    maxDate: { type: Date, default: null },
    unselectableDaysOfWeek: {
      type: Array as PropType<number[]>,
      default: null,
    },
    placeholder: { type: String, default: '' },
    isDatetime: { type: Boolean, default: true },
    hugContent: { type: Boolean, default: false },
    modelValue: {
      type: Date as PropType<Date | null | undefined>,
      default: undefined,
    },
    inline: { type: Boolean, default: false },
    standalone: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    range: { type: Boolean, default: false },
    // if range showNowButton will break
    showNowButton: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    help: { type: String, default: '' },
    name: { type: String, default: '' },
    rules: { type: String, default: '' },
    color: {
      type: String,
      default: DateTimePickerStyle.Gray,
      validator: (v: string) =>
        Object.values<string>(DateTimePickerStyle).includes(v),
    },
  },
  emits: ['blur', 'update:modelValue'],
  setup(props, context) {
    const {
      value: inputValue,
      handleChange,
      handleBlur,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.modelValue,
      standalone: props.standalone,
    })

    const setValue = (v: Date | Date[] | null) => {
      handleChange(v)
      context.emit('update:modelValue', inputValue.value)
    }

    const pickerClasses = () => {
      const classes = [
        'border',
        { 'border-red-500': meta.touched && !meta.valid },
      ]

      switch (props.color) {
        case DateTimePickerStyle.Gray:
          classes.push('border-gray-200')
          break
        case DateTimePickerStyle.Black:
          classes.push('border-nash-neutral900')
          break
      }

      if (props.hugContent) {
        classes.push('min-w-fit', 'min-w-145')
      }

      return classes
    }

    const clear = () => {
      setValue(null)
    }

    /*
    This watch function ensures updates happen outside component.
    Without this, if updating a TDatePicker that is sharing values with
    another TDatePicker in a different parent component, it'll only
    update for the current TDatePicker in current parent component
    */
    watch(
      () => props.modelValue,
      (modelValue) => handleChange(modelValue)
    )

    return {
      inputValue,
      handleBlur,
      pickerClasses,
      setValue,
      clear,
    }
  },
})
</script>
