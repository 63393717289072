import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useAppVersionChecker } from '@/legacy/store/modules/versionChecker'
import { BannerStatus } from '@/legacy/types/bannerStatus'

/**
 *
 */
export default function () {
  const { configVals, shouldShowEnvironmentBanner } = storeToRefs(
    useConfigStore()
  )
  const { showInformationBanner, showNewVersionAvailableBanner } = storeToRefs(
    useFlagStore()
  )
  const { isNewVersionAvailable } = storeToRefs(useAppVersionChecker())

  const getProductBannerStyles = (status?: BannerStatus) => {
    switch (status) {
      case BannerStatus.RESOLVED:
        return 'bg-nash-fern600 text-nash-neutral000'
      case BannerStatus.INCIDENT:
        return 'bg-nash-tigerlily800 text-nash-neutral000'
      case BannerStatus.INFO:
      default:
        return 'bg-nash-mustard600 text-nash-mustard700'
    }
  }

  const getProductBannerMessage = (message?: string, status?: BannerStatus) => {
    if (message && message !== '') {
      switch (status) {
        case BannerStatus.RESOLVED:
          return `Incident Resolved: ${message}`
        case BannerStatus.INCIDENT:
          return `Active Incident: ${message}`
        case BannerStatus.INFO:
        default:
          return message
      }
    }
    return null
  }

  const getProductBannerIcon = (status?: BannerStatus) => {
    switch (status) {
      case BannerStatus.RESOLVED:
        return 'check'
      case BannerStatus.INCIDENT:
        return 'exclamationTriangle'
      case BannerStatus.INFO:
      default:
        return 'infoNotification'
    }
  }

  const productWarningConfig = computed(() => {
    if (showInformationBanner.value) {
      const config = useFlagStore().getFlagConfig('showInformationBanner')
      // @ts-ignore
      const message = config?.message
      // @ts-ignore
      const status = config?.status as BannerStatus | undefined

      return {
        icon: getProductBannerIcon(status),
        message: getProductBannerMessage(message, status),
        styles: getProductBannerStyles(status),
      }
    }
    return null
  })

  const devWarning = computed(() => {
    if (showNewVersionAvailableBanner.value && isNewVersionAvailable.value) {
      return 'A new version of Thyme Box is available. Please refresh your browser to update.'
    }

    if (!shouldShowEnvironmentBanner.value) {
      return ''
    }

    return `You are in the ${configVals.value?.env} environment.`
  })

  return {
    BannerStatus,
    devWarning,
    productWarningConfig,
    showInformationBanner,
  }
}
