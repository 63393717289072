import { AcuityThreshold, TierLevel } from './types'

/**
 * format acuity score for selected tier level
 * @param tierLevel
 */
export function formatOverallTierScore(tierLevel: string) {
  if (!tierLevel.length) {
    return 0
  }
  return tierLevel === TierLevel.ESSENTIAL
    ? 1
    : tierLevel === TierLevel.STANDARD
    ? /* eslint-disable @typescript-eslint/restrict-plus-operands*/
      AcuityThreshold.Medium + 1
    : AcuityThreshold.High + 1
}
