import { computed, ExtractPropTypes, ref } from 'vue'
import { AddModalActions } from '@/legacy/types/pathways/pathways'
import { addMedicalConditionInnerProps } from './types'

type PropsType = ExtractPropTypes<typeof addMedicalConditionInnerProps>

/**
 *
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const active = ref(false)
  const otherMedicalCondition = ref<string | null>(null)
  const selectedAttributes = ref<string[]>([])

  const showActiveFields = computed(
    () =>
      active.value &&
      (props.medicalConditionReference.attributeOptions?.length ||
        props.medicalConditionReference.description === 'Other')
  )
  const defaultPartialPayload = computed(() => ({
    patientId: props.medicalConditionReference.patientId,
    medicalConditionRefId:
      props.medicalConditionReference.medicalConditionRefId,
    isArchived: false,
  }))

  /**
   *
   */
  function resetValues() {
    otherMedicalCondition.value = null
    selectedAttributes.value = []
  }

  /**
   *
   * @param activeVal
   */
  function selectMedicalCondition(activeVal: boolean) {
    if (activeVal) {
      context.emit(AddModalActions.ADD, {
        ...defaultPartialPayload.value,
        otherMedicalCondition: otherMedicalCondition.value,
        conditionSpecificAttributes: selectedAttributes.value,
      })
    } else if (!activeVal) {
      context.emit(
        AddModalActions.REMOVE,
        props.medicalConditionReference.medicalConditionRefId
      )
      resetValues()
    }
    active.value = activeVal
  }

  /**
   * Set other medical condition string
   * @param val
   */
  function setSelectedAttributes(val: string[]) {
    selectedAttributes.value = val
    context.emit('setMedicalConditionPayload', {
      ...defaultPartialPayload.value,
      otherMedicalCondition: null,
      conditionSpecificAttributes: selectedAttributes.value,
    })
  }

  /**
   * Set other medical condition string
   * @param val
   */
  function setOtherMedicalCondition(val: string) {
    otherMedicalCondition.value = val
    if (!otherMedicalCondition.value.length) {
      otherMedicalCondition.value = null
    }

    context.emit('setMedicalConditionPayload', {
      ...defaultPartialPayload.value,
      otherMedicalCondition: otherMedicalCondition.value,
      conditionSpecificAttributes: [],
    })
  }

  return {
    active,
    showActiveFields,
    // data
    selectedAttributes,
    otherMedicalCondition,
    // actions
    setOtherMedicalCondition,
    setSelectedAttributes,
    selectMedicalCondition,
  }
}
