<template>
  <Chip
    :class="chipClasses"
    :data-cy="name && `${kebabCase(name)}-chip`"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <TIcon
      v-if="loading || icon"
      v-bind="$attrs"
      :icon="loading ? 'prime:spinner' : icon"
      :icon-pos="iconPos"
      :color="iconFill"
      :icon-classes="iconClasses"
    />
    <span v-if="label" class="p-chip-label">{{ label }}</span>
  </Chip>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { iconProp, iconPositionProp } from '@thyme/nashville/src/types/icons'
import {
  paddingProp,
  actionProp,
  ColorPalettes,
} from '@thyme/nashville/src/types/shared'
import kebabCase from 'lodash/kebabCase'
import Chip from 'primevue/chip'
import { computed, defineComponent } from 'vue'
import { createProp, tokenizedComponentClasses } from '../designTokens'

const sharedProps = {
  ...paddingProp,
  ...actionProp,
}

export default defineComponent({
  components: {
    Chip,
    TIcon,
  },
  props: {
    ...sharedProps,
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    ...iconProp,
    ...iconPositionProp,
    iconFill: createProp(ColorPalettes),
    badge: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  setup(props) {
    const tokenClasses = tokenizedComponentClasses(
      'chip',
      props,
      Object.keys(sharedProps)
    )
    const chipClasses = computed(() => [
      ...tokenClasses.value,
      props.expanded ? 'p-chip-expanded' : '',
      !props.label && props.icon ? 'p-chip-icon-only' : '',
      props.disabled ? 'cursor-not-allowed' : '',
    ])

    // Ported from PV to customize icon v3.21.0
    const iconClasses = computed(() => [
      'p-chip-icon',
      props.loading ? 'p-chip-loading-icon pi-spin' : props.icon ?? '',
      props.disabled ? 'opacity-50' : '',
    ])

    return {
      chipClasses,
      iconClasses,
      kebabCase,
    }
  },
})
</script>
