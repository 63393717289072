import apiStore from '@/legacy/store/modules/apiBuilder'
import { FormsortData } from '@/legacy/types/forms/formSort'

export const useFormResponsesApi = apiStore<FormsortData>(
  'FormResponsesApi',
  '/api/forms/responses',
  {
    transformData: (d: { data: FormsortData[] }) => d,
  }
)
