<template>
  <div class="care-plan-wrapper">
    <div class="page-content">
      <div class="header">
        <img class="logo" :src="logo" alt="Thyme Care" />
        <TOPrintButton />
      </div>

      <div class="care-plan-header">
        <h1 class="care-plan-title">
          Care Plan | {{ formatDateTime(stringToDateTime(latestUpdate)) }}
        </h1>
        <div class="patient-info">
          <div v-if="person?.firstName || person?.lastName" class="info-row">
            <span class="info-label">Patient Name:</span>
            <span class="info-value">{{
              formatName(person?.firstName, person?.lastName)
            }}</span>
          </div>
          <div v-if="person?.dateOfBirth" class="info-row">
            <span class="info-label">DOB:</span>
            <span class="info-value">{{
              formatDateTime(stringToDateTime(person?.dateOfBirth))
            }}</span>
          </div>
          <div v-if="formattedAddress" class="info-row">
            <span class="info-label">Address:</span>
            <span class="info-value">{{ formattedAddress }}</span>
          </div>
        </div>
        <p class="thyme-care-description">
          Thyme Care offers support to members with cancer throughout their
          cancer journey - from diagnosis to survivorship. Our nurses and
          resource specialists connect members to clinical, behavioral health,
          and social resources - to ensure members receive the right care at the
          right time in a way that respects their preferences.
          <br /><br />
          If you are a member or provider with questions, call Thyme Care at
          {{ READABLE_CARE_TEAM_PHONE }} or email {{ CARE_TEAM_EMAIL }}.
          <br /><br />
          If you are a member, you can also discuss questions with your
          healthcare provider. For emergencies, such as difficulty breathing or
          chest pain, call 911.
        </p>
      </div>

      <section class="care-plan-section">
        <h2 class="section-title">
          Care Plan
          <span class="update-date"
            >(Last updated
            {{ formatDateTime(stringToDateTime(latestUpdate)) }})</span
          >
        </h2>
        <div class="overview-section">
          <h3 class="subsection-title">Overview</h3>
          <div class="overview-content">
            <div class="info-row">
              <span class="info-label">Domain(s):</span>
              <div class="domain-list">
                <span class="domain-item">Addressing Barriers to Care</span>
                <span class="domain-separator">∙</span>
                <span class="domain-item">Managing Symptoms</span>
                <span class="domain-separator">∙</span>
                <span class="domain-item">Planning for Future Care</span>
              </div>
            </div>
            <div v-if="treatmentStatus" class="info-row">
              <span class="info-label">Treatment Status:</span>
              <span class="info-value">{{ treatmentStatus }}</span>
            </div>
            <div v-if="treatmentIntent" class="info-row">
              <span class="info-label">Treatment Intent:</span>
              <span class="info-value">{{ treatmentIntent }}</span>
            </div>
          </div>
        </div>
      </section>

      <section v-if="hasClinicalData" class="care-plan-section">
        <h2 class="section-title">Clinical Summary</h2>
        <div class="subsection">
          <h3 class="subsection-title">Diagnosis</h3>
          <div v-if="cancerDisplay" class="info-row">
            <span class="info-label">Cancer:</span>
            <span class="info-value">{{ cancerDisplay }}</span>
          </div>
          <div v-if="spreadDisplay" class="info-row">
            <span class="info-label">Spread:</span>
            <span class="info-value">{{ spreadDisplay }}</span>
          </div>
          <div v-if="diagnosisDateDisplay" class="info-row">
            <span class="info-label">Diagnosis Date:</span>
            <span class="info-value">{{ diagnosisDateDisplay }}</span>
          </div>
        </div>
      </section>

      <section v-if="hasMedicalTeamData" class="care-plan-section">
        <h3 class="subsection-title">Medical Team</h3>
        <div v-if="lastOncologyAppt" class="info-row">
          <span class="info-label">Last Oncology Appointment:</span>
          <span class="info-value">{{ lastOncologyAppt }}</span>
        </div>
        <div v-if="nextOncologyAppt" class="info-row">
          <span class="info-label">Next Oncology Appointment:</span>
          <span class="info-value">{{ nextOncologyAppt }}</span>
        </div>
        <div v-if="oncologistName" class="info-row">
          <span class="info-label">Oncologist Name:</span>
          <span class="info-value">{{ oncologistName }}</span>
        </div>
        <div v-if="surgeonName" class="info-row">
          <span class="info-label">Surgeon Name:</span>
          <span class="info-value">{{ surgeonName }}</span>
        </div>
        <div v-if="radiationOncologistName" class="info-row">
          <span class="info-label">Radiation Oncologist Name:</span>
          <span class="info-value">{{ radiationOncologistName }}</span>
        </div>
        <div v-if="palliativeMedicineName" class="info-row">
          <span class="info-label">Palliative Medicine Name:</span>
          <span class="info-value">{{ palliativeMedicineName }}</span>
        </div>
        <div v-if="primaryCareName" class="info-row">
          <span class="info-label">Primary Care Name:</span>
          <span class="info-value">{{ primaryCareName }}</span>
        </div>
      </section>

      <section v-if="hasTreatmentData" class="care-plan-section">
        <h2 class="section-title">Treatment</h2>
        <div v-if="treatmentDisplay" class="subsection">
          <h3 class="subsection-title">Systematic Therapy</h3>
          <div class="info-value">{{ treatmentDisplay }}</div>
        </div>
        <div v-if="surgeryDisplay" class="subsection">
          <h3 class="subsection-title">Surgery</h3>
          <div class="info-value">{{ surgeryDisplay }}</div>
        </div>
        <div v-if="radiationDisplay" class="subsection">
          <h3 class="subsection-title">Radiation</h3>
          <div class="info-value">{{ radiationDisplay }}</div>
        </div>
      </section>

      <section v-if="hasMedicalConditions" class="care-plan-section">
        <h2 class="section-title">Medical Conditions</h2>
        <div
          v-for="condition in medicalConditionsWithAttrsDisplay"
          :key="condition.medicalConditionId"
          class="info-row flex items-center"
        >
          <span class="info-label font-medium"
            >{{ condition.description }}:</span
          >
          <div class="flex items-center gap-1.5">
            <span v-if="condition.attributesString" class="info-value">
              {{ condition.attributesString }}
            </span>
            <TIcon
              v-if="condition.isSensitive"
              icon="sensitive"
              size="sm"
              class="ml-2"
              color="nash-tigerlily600"
            />
          </div>
        </div>

        <div
          v-for="condition in medicalConditionsWithoutAttrsDisplay"
          :key="condition.medicalConditionId"
          class="info-row"
        >
          <span class="info-label font-medium">{{
            condition.description
          }}</span>
          <TIcon
            v-if="condition.isSensitive"
            icon="sensitive"
            size="sm"
            class="ml-2"
            color="nash-tigerlily600"
          />
        </div>
      </section>

      <section v-if="fallRiskId" class="care-plan-section">
        <h2 class="section-title">Fall Risk</h2>
        <div v-if="displayFallInTheLastTwelveMonths" class="info-row">
          <span class="info-label">Fall in the last 12 months:</span>
          <span class="info-value">{{ fallInTheLastTwelveMonthsDisplay }}</span>
        </div>
        <div v-if="displayUnsteadyWithStandingOrWalking" class="info-row">
          <span class="info-label">Unsteady With Standing or Walking:</span>
          <span class="info-value">{{
            unsteadyWithStandingOrWalkingDisplay
          }}</span>
        </div>
        <div v-if="displayFearOfFalling" class="info-row">
          <span class="info-label">Fear of Falling:</span>
          <span class="info-value">{{ fearOfFallingDisplay }}</span>
        </div>
        <div v-if="displayUsesDMEToAssistWithAmbulation" class="info-row">
          <span class="info-label">Uses DME to Assist with Ambulation:</span>
          <span class="info-value">{{
            usesDMEToAssistWithAmbulationDisplay
          }}</span>
        </div>
        <div v-if="displayPeripheralNeuropathy" class="info-row">
          <span class="info-label">Peripheral Neuropathy:</span>
          <span class="info-value">{{ peripheralNeuropathyDisplay }}</span>
        </div>
        <div v-if="anticoagulationDisplay" class="info-row">
          <span class="info-label">Anticoagulation:</span>
          <span class="info-value">{{ anticoagulationDisplay }}</span>
        </div>
        <div v-if="sedatingMedicationsDisplay" class="info-row">
          <span class="info-label">Sedating Medications:</span>
          <span class="info-value">{{ sedatingMedicationsDisplay }}</span>
        </div>
        <div v-if="additionalInformationDisplay" class="info-row">
          <span class="info-label">Additional Information:</span>
          <span class="info-value">{{ additionalInformationDisplay }}</span>
        </div>
      </section>

      <section v-if="physicalFunctionAssessmentId" class="care-plan-section">
        <h2 class="section-title">Physical Function Assessment</h2>
        <div v-if="ecogStatusDisplay" class="info-row">
          <span class="info-label">ECOG Status:</span>
          <span class="info-value">{{ ecogStatusDisplay }}</span>
        </div>
        <div v-if="unableToPerformTheFollowingIADLsDisplay" class="info-row">
          <span class="info-label">Unable to Perform the Following IADLs:</span>
          <span class="info-value">{{
            unableToPerformTheFollowingIADLsDisplay
          }}</span>
        </div>
        <div v-if="unableToPerformTheFollowingADLsDisplay" class="info-row">
          <span class="info-label">Unable to Perform the Following ADLs:</span>
          <span class="info-value">{{
            unableToPerformTheFollowingADLsDisplay
          }}</span>
        </div>
        <div v-if="displayHomebound" class="info-row">
          <span class="info-label">Homebound:</span>
          <span class="info-value">{{ homeboundDisplay }}</span>
        </div>
        <div v-if="dmeDisplay" class="info-row">
          <span class="info-label">DME:</span>
          <span class="info-value">{{ dmeDisplay }}</span>
        </div>
        <div v-if="proceduralSupportDisplay" class="info-row">
          <span class="info-label">Procedural Support:</span>
          <span class="info-value">{{ proceduralSupportDisplay }}</span>
        </div>
        <div v-if="displayWalksTwoBlocksOrOneFlightOfStairs" class="info-row">
          <span class="info-label"
            >Unable to Walk Two Blocks or One Flight of Stairs:</span
          >
          <span class="info-value">{{
            walksTwoBlocksOrOneFlightOfStairsDisplay
          }}</span>
        </div>
        <div
          v-if="physicalFunctionAdditionalInformationDisplay"
          class="info-row"
        >
          <span class="info-label">Additional Information:</span>
          <span class="info-value">{{
            physicalFunctionAdditionalInformationDisplay
          }}</span>
        </div>
      </section>

      <section v-if="transitionsOfCareId" class="care-plan-section">
        <h2 class="section-title">Transition of Care</h2>
        <div v-if="admissionDateDisplay" class="info-row">
          <span class="info-label">Admission Date:</span>
          <span class="info-value">{{ admissionDateDisplay }}</span>
        </div>
        <div v-if="dischargeDateDisplay" class="info-row">
          <span class="info-label">Discharge Date:</span>
          <span class="info-value">{{ dischargeDateDisplay }}</span>
        </div>
        <div v-if="eventTypeDisplay" class="info-row">
          <span class="info-label">Event Type:</span>
          <span class="info-value">{{ eventTypeDisplay }}</span>
        </div>
        <div v-if="dischargeDiagnosisDisplay" class="info-row">
          <span class="info-label">Discharge Diagnosis:</span>
          <span class="info-value">{{ dischargeDiagnosisDisplay }}</span>
        </div>
        <div v-if="dischargeLocationDisplay" class="info-row">
          <span class="info-label">Discharge Location:</span>
          <span class="info-value">{{ dischargeLocationDisplay }}</span>
        </div>
        <div v-if="displayIsReadmissionRisk" class="info-row">
          <span class="info-label">Readmission Risk:</span>
          <span class="info-value">{{ isReadmissionRiskDisplay }}</span>
        </div>
        <div v-if="displayHasNewDmeOnDischarge" class="info-row">
          <span class="info-label">New DME on Discharge:</span>
          <span class="info-value">{{ hasNewDmeOnDischargeDisplay }}</span>
        </div>
        <div v-if="servicesOnDischargeDisplay" class="info-row">
          <span class="info-label">Services on Discharge:</span>
          <span class="info-value">{{ servicesOnDischargeDisplay }}</span>
        </div>
        <div
          v-if="displayMoreThanThreeAdmissionsInLastSixMonths"
          class="info-row"
        >
          <span class="info-label">>3 Admissions in 6 Months:</span>
          <span class="info-value">{{
            moreThanThreeAdmissionsInLastSixMonthsDisplay
          }}</span>
        </div>
        <div v-if="summaryOfEventsDisplay" class="info-row">
          <span class="info-label">Summary of Events:</span>
          <span class="info-value">{{ summaryOfEventsDisplay }}</span>
        </div>
        <div v-if="displayElectiveAdmission" class="info-row">
          <span class="info-label">Elective Admission:</span>
          <span class="info-value">{{ hasElectiveAdmissionDisplay }}</span>
        </div>
      </section>

      <section v-if="socialSummaryId" class="care-plan-section">
        <h2 class="section-title">Social Summary</h2>
        <div v-if="transportationBarrierDisplay" class="info-row">
          <span class="info-label">Transportation Barrier:</span>
          <span class="info-value">{{ transportationBarrierDisplay }}</span>
        </div>
        <div v-if="foodBarrierDisplay" class="info-row">
          <span class="info-label">Food Barrier:</span>
          <span class="info-value">{{ foodBarrierDisplay }}</span>
        </div>
        <div v-if="housingBarrierDisplay" class="info-row">
          <span class="info-label">Housing Barrier:</span>
          <span class="info-value">{{ housingBarrierDisplay }}</span>
        </div>
        <div v-if="financialBarrierDisplay" class="info-row">
          <span class="info-label">Financial Barrier:</span>
          <span class="info-value">{{ financialBarrierDisplay }}</span>
        </div>
        <div v-if="socialSupportDisplay" class="info-row">
          <span class="info-label">Social Support:</span>
          <span class="info-value">{{ socialSupportDisplay }}</span>
        </div>
        <div v-if="inHomeSupportDisplay" class="info-row">
          <span class="info-label">In-Home Support:</span>
          <span class="info-value">{{ inHomeSupportDisplay }}</span>
        </div>
        <div v-if="caregiverMappingDisplay" class="info-row">
          <span class="info-label">Caregiver Mapping:</span>
          <span class="info-value">{{ caregiverMappingDisplay }}</span>
        </div>
        <div v-if="benefitsProgramsServicesDisplay" class="info-row">
          <span class="info-label">Benefits, Programs, and Services:</span>
          <span class="info-value">{{ benefitsProgramsServicesDisplay }}</span>
        </div>
        <div v-if="displayHealthcareProxyOrPOA" class="info-row">
          <span class="info-label">Healthcare Proxy:</span>
          <span class="info-value">{{ isHealthcareProxyOrPOA }}</span>
        </div>
        <div v-if="additionalContextDisplay" class="info-row">
          <span class="info-label">Additional Information:</span>
          <span class="info-value">{{ additionalContextDisplay }}</span>
        </div>
      </section>

      <section v-if="hasGoals" class="care-plan-section">
        <h2 class="section-title">Goals</h2>
        <div
          v-for="goal in sortedGoals"
          :key="goal.goalId"
          class="goal-section"
        >
          <h3 class="subsection-title">
            Domain:
            {{ goal.domain ? formatDomain(goal.domain.domain) : 'N/A' }}
          </h3>
          <div class="goal-content">
            <div class="goal-text">
              <span class="goal-label">Goal:</span>
              <span>{{
                goal.freeTextTitle || goal.goalReference.titleDisplay
              }}</span>
            </div>
            <p v-if="goal.details" class="goal-description">
              {{ goal.details }}
            </p>

            <div
              v-if="getGoalPathways(goal).length > 0"
              class="playbooks-section"
            >
              <h4 class="playbooks-label">Playbook(s)</h4>
              <div class="playbooks-list">
                <div
                  v-for="pathway in getGoalPathways(goal)"
                  :key="pathway.pathwayId"
                  class="playbook-item"
                >
                  <div class="playbook-header">
                    <TIcon
                      icon="pathway"
                      color="nash-midnightBlue700"
                      class="playbook-icon"
                    />
                    <span class="playbook-title">{{ pathway.title }}</span>
                    <span class="playbook-progress">
                      {{ pathway.completedTaskCount }}/{{
                        pathway.totalTaskCount
                      }}
                      Tasks ({{ getPathwayPercentage(pathway) }}%)
                    </span>
                  </div>
                  <p v-if="pathway.description" class="playbook-description">
                    {{ pathway.description }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="getGoalTasks(goal).length > 0" class="tasks-section">
              <h4 class="tasks-label">Additional Task(s)</h4>
              <div class="task-items">
                {{
                  getGoalTasks(goal)
                    .map((task) => task.title)
                    .join(', ')
                }}
              </div>
            </div>

            <div
              v-if="getCompletedGoalTasks(goal).length > 0"
              class="completed-section"
            >
              <h4 class="completed-label">Completed</h4>
              <div
                v-for="task in getCompletedGoalTasks(goal)"
                :key="task.taskId"
                class="completed-item"
              >
                <span class="completed-title">{{ task.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="hasCommunications" class="care-plan-section">
        <h2 class="section-title">Patient Communications</h2>
        <div class="communications-list">
          <div
            v-for="(comm, index) in communications"
            :key="index"
            class="communication-item"
          >
            {{ formatDateTime(stringToDateTime(comm.completedDatetime)) }}
            {{ comm.type }}
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script lang="ts">
import TIcon from '@thyme/nashville/src/components/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TOPrintButton from '@/legacy/nashville/button/TOPrintButton.vue'
import setup from './controller'

export default defineComponent({
  name: 'CarePlanReport',
  components: { TIcon, TOPrintButton },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup,
})
</script>

<style lang="scss" scoped>
.care-plan-wrapper {
  @apply flex flex-col items-center min-h-screen bg-nash-neutral000;
  font-family: 'Roboto', sans-serif;
}

.page-content {
  @apply w-full max-w-4xl p-12 bg-white;
}

.header {
  @apply flex justify-between items-center mb-6;
}

.logo {
  @apply w-24;
}

.care-plan-header {
  @apply mb-8;
}

.care-plan-title {
  @apply text-2xl font-bold text-[#1F1D24] mb-3 font-['P22_Mackinac_Pro'];
}

.patient-info {
  @apply mb-3;
}

.info-row {
  @apply flex items-center mb-1;
}

.info-label {
  @apply text-xs font-medium text-[#534E5E] mr-1;
}

.info-value {
  @apply text-xs text-[#534E5E];
}

.thyme-care-description {
  @apply text-xs text-[#534E5E] leading-relaxed;
}

.care-plan-section {
  @apply mb-8;
}

.section-title {
  @apply text-xl font-bold text-[#1F1D24] mb-4 font-['P22_Mackinac_Pro'];
}

.update-date {
  @apply text-sm font-normal text-[#75717E];
}

.subsection-title {
  @apply text-sm font-medium text-[#1F1D24] mb-2 font-['P22_Mackinac_Pro'];
}

.overview-content {
  @apply mb-4;
}

.domain-list {
  @apply flex items-center flex-wrap;
}

.domain-item {
  @apply text-xs text-[#534E5E];
}

.domain-separator {
  @apply mx-1 text-[#534E5E];
}

.goal-section {
  @apply mb-6;
}

.goal-content {
  @apply mb-4;
}

.goal-text {
  @apply mb-2;
}

.goal-label {
  @apply font-medium italic;
}

.goal-description {
  @apply text-xs text-[#534E5E] mb-4;
}

.playbooks-section,
.tasks-section,
.completed-section {
  @apply mb-4;
}

.playbooks-label,
.tasks-label,
.completed-label {
  @apply text-xs font-medium text-[#75717E] mb-2;
}

.playbooks-list {
  @apply space-y-3;
}

.playbook-item {
  @apply mb-3;
}

.playbook-header {
  @apply flex items-center mb-1;
}

.playbook-icon {
  @apply mr-2;
}

.playbook-title {
  @apply text-xs font-medium text-[#1F1D24];
}

.playbook-progress {
  @apply text-xs text-[#534E5E] ml-2;
}

.playbook-description {
  @apply text-xs text-[#534E5E] mt-1;
}

.task-items {
  @apply text-xs text-[#534E5E];
}

.task-item,
.completed-item {
  @apply mb-3;
}

.task-title,
.completed-title {
  @apply text-xs font-medium text-[#1F1D24];
}

.task-progress,
.completed-progress {
  @apply text-xs text-[#534E5E];
}

.communications-list {
  @apply text-xs text-[#534E5E];
}

.communication-item {
  @apply mb-1;
}

.no-communications {
  @apply text-xs text-[#534E5E];
}

.overview-section {
  @apply pb-2 border-b border-[#C2BECA];
}
</style>
