<template>
  <TCollapse :class="`border py-2 px-3 w-full rounded`">
    <template #trigger>
      <div class="flex justify-between items-center">
        <span>{{ symptomName }}</span>
        <TIcon class="text-nash-neutral600" icon="chevronDown" />
      </div>
    </template>
    <template #content>
      <Chart type="line" :data="chartData" :options="chartOptions" />
    </template>
  </TCollapse>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import Chart from 'primevue/chart'
import { PropType, defineComponent } from 'vue'
import TCollapse from '@/legacy/nashville/TCollapse.vue'
import { SymptomIdAnswer } from '@/legacy/types/pathways/symptomIdHistory'
import setup from './controller'

export default defineComponent({
  components: {
    Chart,
    TCollapse,
    TIcon,
  },
  props: {
    symptomName: {
      type: String,
      required: true,
    },
    symptomAnswers: {
      type: Array as PropType<SymptomIdAnswer[] | null>,
      default: null,
    },
  },
  setup,
})
</script>
