import { Conversation, Message } from '@twilio/conversations'
import { values, filter } from 'lodash'
import { DateTime } from 'luxon'
import { formatNameFromPerson, formatPhone } from '@/legacy/libs/format'
import {
  useOpenTextingSubtaskApi,
  useTextingStore,
  useTextingSubtaskByIdApi,
} from '@/legacy/store/modules/textingV2'
import { IdMap } from '@/legacy/types/api/store'
import { TextCommunication } from '@/legacy/types/communications/communications'
import { ContactEntityWithRelationship } from '@/legacy/types/entities/entities'
import { Person } from '@/legacy/types/entities/people'
import {
  MapEntityPhoneNumberPersonSms,
  MapEntityPhoneNumber,
} from '@/legacy/types/entities/phoneNumbers'
import { Contact } from '@/legacy/types/patients/contacts'

export const LIMIT_PER_PHONE_NUMBER = 1
export const SMS_COMM_BY_PHONE_NUMBER_ID_SORT_BY = 'updatedAt,desc'
export const SMS_COMMS_FOR_ACTIVE_THREAD_SORT_BY = 'updatedAt,desc'
export const UNKNOWN_TEXTS_PAGE_URL_NAME = '#unknownTexts'
/**
 * Function that takes in phone numbers and person off the patient and returns both in an array
 * @param phoneNumbers
 * @param person
 */
export function getPatientPhoneNumbersList(
  phoneNumbers: IdMap<MapEntityPhoneNumber>,
  person: Person
) {
  return values(phoneNumbers).map((phoneNumber) => ({
    ...phoneNumber,
    person,
  }))
}

/**
 *
 * Function that scrolls to:
 * - the last/most recent messages in the sms view
 * - the message box in the overall texting inbox view
 */
export function scrollIntoViewToLatestMessage() {
  const messageElements = document.getElementsByClassName('text-message')
  const textingThymelineContainer = document.getElementsByClassName(
    'texting-thymeline-container'
  )
  if (messageElements?.length) {
    const lastMsg = messageElements[messageElements.length - 1]
    lastMsg.scrollIntoView(false)
  }
  if (textingThymelineContainer?.length) {
    const messageBox = textingThymelineContainer[0]
    messageBox.scrollIntoView(true)
  }
}

/**
 * Function that takes in an idmap of contacts and returns
 * a list of contact phone numbers and person object
 * @param contacts
 */
export function getContactsPhoneNumbersList(contacts: IdMap<Contact>) {
  const filteredContacts: ContactEntityWithRelationship[] = filter(
    values(contacts ?? {}),
    (contact: Contact) => !!contact.contactEntity && !!contact.approvedToSpeak
  ).map((contact: Contact) => ({
    ...contact.contactEntity,
    relationshipToPatient: contact.relationshipToPatient,
  }))

  return filteredContacts
    .map((contactEntity: ContactEntityWithRelationship) =>
      contactEntity.phoneNumbers?.map((phoneNumber: MapEntityPhoneNumber) => ({
        ...phoneNumber,
        person: contactEntity.person,
        ...(contactEntity.relationshipToPatient
          ? {
              relationshipToPatient: contactEntity.relationshipToPatient,
            }
          : {}),
      }))
    )
    .flat() as MapEntityPhoneNumberPersonSms[]
}

/**
 * This function takes sms communications and retrieves all conversations
 * given an sms communication's sms thread's twilio conversation ids.
 *
 * IMPROVEME(MT-2751): there is no test for this functon as it uses twilio sdk methods.
 * @param smsComms
 */
export async function getConvosForSmsComms(smsComms: TextCommunication[]) {
  const smsCommsWithConversationIds = smsComms?.filter(
    (comm: TextCommunication) => !!comm.smsThread?.twilioConversationIds?.length
  )

  if (!smsCommsWithConversationIds?.length) {
    return
  }

  let errorMsg = null

  const convos = await Promise.all(
    smsCommsWithConversationIds
      ?.map(async (comm: TextCommunication) => {
        const sidArray = comm.smsThread.twilioConversationIds ?? []
        const hasMoreThanOneSid = sidArray.length && sidArray.length > 1
        try {
          if (hasMoreThanOneSid) {
            const conversations = await Promise.all(
              sidArray.map(
                async (conversationSid: string) =>
                  await useTextingStore().getConversation(conversationSid)
              )
            )
            return conversations
          }
          const convo = await useTextingStore().getConversation(sidArray[0])
          return convo
        } catch (err) {
          errorMsg = `Couldn't fetch conversations ... ${err}, Convo: ${sidArray}`
          return
        }
      })
      .flat()
  )

  const flattenedConvos = convos
    .flat()
    .filter((convo) => !!convo) as Conversation[]
  const sortedConvos = flattenedConvos.sort(
    (a: Conversation, b: Conversation) => {
      if (!b.dateUpdated?.toISOString()) {
        return 1
      }
      if (!a.dateUpdated?.toISOString()) {
        return -1
      }
      return (
        DateTime.fromISO(a.dateUpdated?.toISOString()).toMillis() -
        DateTime.fromISO(b.dateUpdated?.toISOString()).toMillis()
      )
    }
  )
  return { convos: sortedConvos, error: errorMsg }
}

/**
 * This function takes a list of twilio conversations and gets messsages for each
 * conversation.
 *
 * IMPROVEME(MT-2751): there is no test for this functon as it uses twilio sdk methods.
 * @param convos
 */
export async function getMessagesForConvos(convos: Conversation[]) {
  try {
    const messages = await Promise.all(
      convos
        .sort(
          (a: any, b: any) =>
            a?.lastMessage?.dateCreated - b?.lastMessage?.dateCreated
        )
        .map(async (convo: Conversation) => {
          const messagesData = await convo.getMessages()
          return messagesData.items
        })
    )
    return { messages: messages.flat(), error: null }
  } catch (err) {
    console.error("Couldn't fetch messages...", err)
    return {
      messages: [],
      error: `Couldn't fetch messages... ${err}`,
    }
  }
}

/**
 *
 * Function that returns a map of attached media to its sid from the messages passed
 * @param messages
 * @param mediaLinks
 */
export async function getMediaFromMessages(
  messages: Message[],
  mediaLinks: Record<string, string>
) {
  for (const message of messages) {
    if (message.attachedMedia) {
      for (const media of message.attachedMedia) {
        mediaLinks[media.sid] = (await media.getContentTemporaryUrl()) as string
      }
    }
  }
  return mediaLinks
}

/**
 *
 * Function to check if textee is a patient based on their phone number
 * @param author
 * @param currentPhoneNumberStr
 */
export function isPatient(
  author: string | null,
  currentPhoneNumberStr: string
): boolean {
  return !!author && author === currentPhoneNumberStr
}

/**
 *
 * Function to return name of texteee or their phone number
 * @param author
 * @param people
 */
export function getTexteeNameOrFormatNumber(
  author: string | null,
  people: Person[] | undefined
): string {
  if (!author) {
    return 'N/A'
  }
  if (people?.length) {
    let nameStr = ''
    people.forEach((person: Person, idx: number) => {
      if (idx !== 0) {
        nameStr += ', '
      }
      nameStr += formatNameFromPerson(person)
    })
    return nameStr
  }
  return formatPhone(author)
}

/**
 *
 * Function to check if content passed is a photo
 * @param contentType
 */
export function isPhoto(contentType: string) {
  return contentType.match(/^image\//g)
}

/**
 *
 * Function to check current communication has
 * shared numbers between different members.
 * @param smsCommunication
 * @param contacts
 */
export function hasSharedNumberWithAnotherMember(
  smsCommunication: TextCommunication,
  contacts: Contact[]
) {
  const { patientIds, smsThread } = smsCommunication
  if (patientIds.length > 1) {
    return true
  }

  // If patientIds.length === 1, check sms threads people list
  const { people } = smsThread
  if (people && people.length > 1) {
    const peopleEntityIds = people.map((person: Person) => person.entityId)
    const contactEntityIds = contacts.map(
      (contact: Contact) => contact.contactEntityId
    )
    // If a person entity id is not in patient ids list nor contact entity list,
    // it is another member entirely
    return !peopleEntityIds.every(
      (personEntityId) =>
        contactEntityIds.includes(personEntityId) ||
        patientIds.includes(personEntityId)
    )
  }
  return false
}

/**
 *
 * Function to check current communication has
 * shared numbers between different members.
 * @param smsCommunication
 * @param contacts
 */
export function hasSharedNumberWithAnotherContact(
  smsCommunication: TextCommunication,
  contacts: Contact[]
) {
  const { smsThread } = smsCommunication
  if (smsThread.people && smsThread.people.length > 1) {
    const peopleEntityIds = smsThread.people.map(
      (person: Person) => person.entityId
    )
    const contactEntityIds = contacts.map(
      (contact: Contact) => contact.contactEntityId
    )
    return !!contactEntityIds.find((contactEntityId: string) =>
      peopleEntityIds.includes(contactEntityId)
    )
  }
  return false
}

/**
 *
 * This function takes a conversation sid and calls `getUnreadMessagesCount()`.
 * Get count of unread messages for the user if they are a participant of this conversation.
 * Rejects if the user is not a participant of the conversation.
 * @param convoSid
 */
export async function getUnreadMessageCount(convoSid: string | null) {
  if (convoSid) {
    const convo = await useTextingStore().getConversation(convoSid)
    if (convo) {
      const unreadMessages = await convo.getUnreadMessagesCount()
      return unreadMessages
    }
  }
  return null
}

/**
 *
 * Function to fetch open pointer subtasks for a patient
 * @param patientId
 */
export async function getOpenPointerSubtasks(patientId: string) {
  await useOpenTextingSubtaskApi().list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
}

/**
 * fetch subtask by its pointerToCommId
 * @param commId
 * @param patientId
 */
export async function getPointerSubtaskByCommunicationId(
  commId: string,
  patientId: string
) {
  await useTextingSubtaskByIdApi().list({
    params: {
      filter_pointer_comm_ids: [commId],
      filter_patient_ids: [patientId],
    },
  })
}
