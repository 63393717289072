import { toDateDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { Treatment } from '@/pages/PatientProfile/CarePlans/shared/types'

export const formatTreatmentDuration = (treatment: Treatment) => {
  if (!treatment.treatmentStartDate && !treatment.treatmentEndDate) {
    return 'unknown'
  }
  return `${
    treatment.treatmentStartDate
      ? toDateDisplay(treatment.treatmentStartDate)
      : 'unknown'
  } - ${
    treatment.treatmentEndDate
      ? toDateDisplay(treatment.treatmentEndDate)
      : 'current'
  }`
}
