import { Message } from '@twilio/conversations'

import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, onBeforeUnmount, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getValFromQuery } from '@/legacy/libs/lookup'
import { useConfigStore } from '@/legacy/store/modules/config'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useTextingStore } from '@/legacy/store/modules/textingV2'

import { TextCommunication } from '@/legacy/types/communications/communications'
import { SMSThread } from '@/legacy/types/communications/texting'
import { MapEntityPhoneNumberPersonSms } from '@/legacy/types/entities/phoneNumbers'
import { UNKNOWN_TEXTS_PAGE_URL_NAME } from '../lib/sharedTextingParts'

/**
 *
 * Setup function for TextingInbox.vue
 */
export default function () {
  const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
    useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId
  const { phoneNumbers, contacts } = storeToRefs(usePatientStore())

  const route = useRoute()
  const router = useRouter()
  const queryData = computed(() => route.query)
  const commId = computed(() => getValFromQuery(queryData.value, 'commId'))
  const phoneNumId = computed(() =>
    getValFromQuery(queryData.value, 'phoneNumberId')
  )

  const shouldDefaultSelect = computed(() => !commId.value && !phoneNumId.value)

  const isUnknownTextsPage = computed(
    () => route.name === UNKNOWN_TEXTS_PAGE_URL_NAME
  )
  const patientId = computed(() =>
    isUnknownTextsPage.value
      ? TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      : `${route.params.patientId ?? ''}`
  )
  const textingInboxTitle = computed(() => {
    if (isUnknownTextsPage.value) {
      return 'Unknown Texts Inbox'
    }
    return route.params.patientId ? 'Inbox' : 'Other'
  })

  const {
    clientLoading,
    smsCommsByPhoneNumberId,
    textableIndividuals,
    messageMapByConvoSid,
    messagesError,
    conversationsLoading,
    inboxMessagesLoading,
    conversationsError,
    conversationsClientError,
  } = storeToRefs(useTextingStore())

  const hasErrors = computed(
    () =>
      !!conversationsError.value ||
      !!messagesError.value ||
      !!conversationsClientError.value
  )

  const textingInboxDataLoaded = computed(() => {
    if (phoneNumId.value?.length) {
      return !clientLoading.value && !!inboxDataWithLastMessage.value.length
    }

    if (hasErrors.value) {
      return true
    }

    if (isUnknownTextsPage.value) {
      return (
        !inboxMessagesLoading.value &&
        !clientLoading.value &&
        !conversationsLoading.value
      )
    }
    return (
      !inboxMessagesLoading.value &&
      !clientLoading.value &&
      !!inboxDataWithLastMessage.value.length &&
      !conversationsLoading.value
    )
  })

  /**
   *
   * This function takes a list of twilio conversation ids and
   * references the messageMapByConvoSid ref to grab latest message details.
   * @param twilioConversationIds
   */
  function getLatestMessageDetails(twilioConversationIds: string[]) {
    if (twilioConversationIds.length > 1) {
      const messages: Message[][] = []
      twilioConversationIds.forEach((id: string) => {
        if (messageMapByConvoSid.value[id]) {
          messages.push(messageMapByConvoSid.value[id])
        }
      })
      const sortedMessages = messages.flat()
      return sortedMessages[sortedMessages.length - 1]
    } else {
      const messages = messageMapByConvoSid.value[twilioConversationIds[0]]
      if (messages?.length) {
        return messages[messages.length - 1]
      }
      return null
    }
  }

  const smsThreadByPhoneNumberMap = computed(() => {
    const map: { [key: string]: SMSThread } = {}
    if (smsCommsByPhoneNumberId.value) {
      const smsThreads = Object.values(smsCommsByPhoneNumberId.value.data).map(
        (smsComm: TextCommunication) => smsComm.smsThread
      )
      smsThreads.forEach(
        (smsThread: SMSThread) => (map[smsThread.phoneNumberId] = smsThread)
      )
    }
    return map
  })

  const inboxDataWithLastMessage = computed(() => {
    return textableIndividuals.value
      .map((textable) => {
        if (smsThreadByPhoneNumberMap.value[textable.phoneNumberId]) {
          const {
            turn,
            twilioConversationIds,
            updatedAt,
            communicationId,
            people,
          } = smsThreadByPhoneNumberMap.value[textable.phoneNumberId]
          if (twilioConversationIds?.length) {
            const latestMessage = getLatestMessageDetails(twilioConversationIds)
            return {
              lastTextMessageAuthor: latestMessage?.author ?? null,
              lastUpdateDate: latestMessage?.dateUpdated?.toISOString(),
              lastTextMessage:
                latestMessage?.body ??
                latestMessage?.media?.state?.contentType ??
                '',
              people,
              turn,
              twilioConversationIds,
              updatedAt,
              communicationId,
              ...textable,
            }
          }

          return {
            people,
            turn,
            twilioConversationIds,
            updatedAt,
            communicationId,
            ...textable,
          }
        }
        return textable
      })
      .sort(
        (
          a: MapEntityPhoneNumberPersonSms,
          b: MapEntityPhoneNumberPersonSms
        ) => {
          if (!b.lastUpdateDate) {
            return -1
          }
          if (!a.lastUpdateDate) {
            return 1
          }
          return (
            DateTime.fromISO(b.lastUpdateDate).toMillis() -
            DateTime.fromISO(a.lastUpdateDate).toMillis()
          )
        }
      )
  })

  /**
   *
   * This function triggers when different textable individuals
   * are selected in the texting inbox UI (left side panel) of the texting thymeline
   * @param selectedTextableIndividual
   */
  function select(selectedTextableIndividual: MapEntityPhoneNumberPersonSms) {
    const { phoneNumberId, communicationId } = selectedTextableIndividual
    if (communicationId) {
      // Delete phone number ID in query as this will conflict with UI logic
      delete route.query['phoneNumberId']
      void router.push({
        query: {
          ...route.query,
          commId: communicationId,
          type: 'sms',
        },
      })
    } else {
      // Delete comm ID in query as this will conflict with UI logic
      delete route.query['commId']
      void router.push({
        query: {
          ...route.query,
          phoneNumberId: phoneNumberId,
          type: 'sms',
        },
      })
    }
  }

  /**
   *
   * This function sets the default selected state for the
   * texting inbox UI (left side panel) of the texting thymeline tab is clicked.
   */
  function setDefaultQueryIdIfNoneInURL() {
    if (shouldDefaultSelect.value && inboxDataWithLastMessage.value.length) {
      const selected = inboxDataWithLastMessage.value[0]
      if (selected.communicationId) {
        void router.push({
          query: {
            ...route.query,
            commId: selected.communicationId,
            type: 'sms',
          },
        })
      } else {
        void router.push({
          query: {
            ...route.query,
            phoneNumberId: selected.phoneNumberId,
            type: 'sms',
          },
        })
      }
    }
  }

  /**
   *
   * This function is called to get and set all needed data for texting inbox component.
   */
  async function getAndSetData() {
    await useTextingStore().init()
    await useTextingStore().fetchAndSetSMSCommByPhoneNumberIds(patientId.value)
    setDefaultQueryIdIfNoneInURL()
  }

  onBeforeUnmount(
    () =>
      void router.push({
        query: {
          commId: undefined,
          phoneNumberId: undefined,
        },
      })
  )
  onBeforeMount(getAndSetData)

  // this watches for query url changes
  watch(queryData, getAndSetData)
  watch(phoneNumbers, getAndSetData)
  watch(contacts, getAndSetData)

  return {
    textingInboxDataLoaded,
    inboxDataWithLastMessage,
    textingInboxTitle,
    select,
  }
}
