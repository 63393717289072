import { v4 as uuid } from 'uuid'
import {
  FORMSORT_ENV_KEY,
  INLINE_STEPS_KEY,
  RESPONDER_UUID_KEY,
  SUBTASK_ID_KEY,
  VARIANT_REVISION_KEY,
} from '@/legacy/types/forms/formSort'
/**
 * Set the query parameters that will be passed to Formsort to customize
 * the way the flow/variant loads
 * Potential params include:
 * formsortEnv --> (string):  set the Formsort environment the form will be loaded from
 * variantRevisionUuid --> (string): the particular version of the form to load
 * responderUuid --> (string): the Formsort id associated with this form completion
 * Any answer variable associated with the given form --> (string): data to prefill with
 * @param subtaskId
 * @param currentEnv
 * @param variantRevisionUuid
 * @param prefillData
 */
export function getQueryParams(
  subtaskId: string,
  currentEnv: string,
  variantRevisionUuid: string | null | undefined,
  prefillData: Array<[string, string]> | null
) {
  const data: Array<[string, string]> = [
    [RESPONDER_UUID_KEY, uuid()],
    [SUBTASK_ID_KEY, subtaskId],
    [INLINE_STEPS_KEY, 'true'],
  ]

  // Formsort allows you to create various environments for a flow to allow
  // data processing, styling, etc., to be handled differently across environments.
  // We have set 'dev', 'staging' and 'production' environments to coincide with ours
  // Only specify to formsort which environment version of flow to load if not prod
  // as it will load the prod version by default
  if (currentEnv && currentEnv !== 'prod') {
    data.push([FORMSORT_ENV_KEY, currentEnv])
  }
  if (variantRevisionUuid) {
    data.push([VARIANT_REVISION_KEY, variantRevisionUuid])
  }
  if (prefillData) {
    data.push(...prefillData)
  }

  return data
}
