import { UUID } from '@/legacy/types/global/base'

export type CarePlanUpdate = {
  lastCarePartnerUpdatedBy: UUID | null
  lastCarePartnerUpdatedAt: Date | null
  lastNurseUpdatedBy: UUID | null
  lastNurseUpdatedAt: Date | null
  lastLeadershipUpdatedBy: UUID | null
  lastLeadershipUpdatedAt: Date | null
}

export type Domain = {
  domainId: UUID
  patientId: UUID
  domain: string
}

export enum DomainsEnum {
  PLANNING_FOR_FUTURE_CARE = 'planning_for_future_care',
  MAINTAINING_PSYCHOLOGICAL_HEALTH = 'maintaining_psychological_health',
  CONTROLLING_MEDICAL_CONDITIONS = 'controlling_medical_conditions',
  SUPPORTING_PHYSICAL_FUNCTION = 'supporting_physical_function',
  ADDRESSING_BARRIERS_TO_CARE = 'addressing_barriers_to_care',
  MANAGING_SYMPTOMS = 'managing_symptoms',
  ESSENTIAL_SUPPORT = 'essential_support',
}
