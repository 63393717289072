import _truncate from 'lodash/truncate'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import { displayRelationship } from '@/legacy/components/patient/sidebar/lib/contacts'
import { Communication } from '@/legacy/types/communications/communications'
import { Entity } from '@/legacy/types/entities/entities'
import { Person } from '@/legacy/types/entities/people'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import { ConsentType } from '@/legacy/types/patients/consents'
import { Contact } from '@/legacy/types/patients/contacts'
import {
  treatmentStatusMap,
  programStatusMap,
  Patient,
} from '@/legacy/types/patients/patients'
import { Provider } from '@/legacy/types/providers'
import { stringToDateTime } from './date'
import { lookupEnum } from './enum'

/**
 * Cleans and formats a ten digit phone number.
 * @param phone
 */
export function formatPhone(phone: string | number) {
  if (!phone) return ''
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const cleaned = phone.toString().replace(/\D/g, '').slice(-10)
  const match = cleaned.match(/^(\d{1,3})?(\d{1,3})?(\d{1,4})?$/)

  // if the number matches a 10 digit phone number (or part of one), return
  // the digits separated by '-', otherwise, return just the cleaned number
  return match
    ? match
        .slice(1)
        .filter((x) => x)
        .join('-')
    : cleaned
}

// IMPROVEME(MT-1776): consolidate below name format fns into 1?
/**
 *
 * @param firstName
 * @param lastName
 * @param middleInitial
 */
export function formatName(
  firstName: string | undefined,
  lastName: string | undefined,
  middleInitial: string | undefined = undefined
): string {
  const initial = middleInitial ? middleInitial + '. ' : ''
  return `${firstName ?? ''} ${initial}${lastName ?? ''}`
}

/**
 *
 * @param firstName
 * @param lastName
 * @param preferredName
 */
export function formatNameWithPreferredName(
  firstName: string | undefined,
  lastName: string | undefined,
  preferredName: string | undefined = undefined
): string {
  const preferred = preferredName ? `"${preferredName}"` : ''
  return `${firstName ?? ''} ${preferred} ${lastName ?? ''}`
}

/**
 *
 * @param root0
 * @param root0.firstName
 * @param root0.lastName
 * @param root0.middleInitial
 */
export function formatNameFromPerson({
  firstName,
  lastName,
  middleInitial = undefined,
}: Person): string {
  return formatName(firstName, lastName, middleInitial)
}

/**
 *
 * @param entity
 */
export function formatNameFromEntity(entity: Entity | Patient | null): string {
  if (entity?.person) {
    return formatName(entity.person.firstName, entity.person.lastName)
  } else {
    return ''
  }
}

/**
 *
 * @param contact
 */
export function formatContactNameAndRelationship(contact: Contact): string {
  const contactEntity = contact.contactEntity
  if (contactEntity?.person) {
    const contactName = formatName(
      contactEntity.person.firstName,
      contactEntity.person.lastName
    )
    const relationshipToPatient = displayRelationship(
      contact.relationshipToPatient
    )
    return relationshipToPatient
      ? `${contactName} (${relationshipToPatient})`
      : contactName
  } else {
    return ''
  }
}

/**
 *
 * @param provider
 */
export function formatNameFromProvider(provider: Provider | null): string {
  if (provider?.person) {
    return formatName(provider.person.firstName, provider.person.lastName)
  } else {
    return ''
  }
}

/**
 * Truncates the given string at word boundaries.
 * @param s
 * @param characters
 * @deprecated use lodash truncate directly, it has more options
 */
export function truncate(s: string, characters: number) {
  return _truncate(s, { length: characters })
}

/**
 * Creates a "|" separated list of string values, each truncated to the length of truncateTo
 * ex: separateAndTruncate(['foobar', 'fizzbuzz'], 3)
 * output: 'foo... | fiz...'
 * @param values
 * @param truncateTo
 */
export function separateAndTruncate(values: unknown[], truncateTo = 0) {
  return values
    .map((v) => (truncateTo && v ? truncate(v as string, truncateTo) : v))
    .join(' | ')
}

/**
 * convert datetime to display string local
 * @param d Luxon DateTime
 * @param locale locale string
 * @returns string
 */
export function formatDateTime(
  d: DateTime | undefined,
  locale?: DateTimeFormatOptions
) {
  if (d) {
    return d.setZone('local').toLocaleString(locale ?? DateTime.DATE_SHORT)
  }
  return ''
}

/**
 * convert datetime to display string
 * NO ZONE CONVERSION
 * @param d Luxon DateTime
 * @param locale locale string
 * @returns string
 */
export function formatCleanDateTime(
  d: DateTime | undefined,
  locale?: DateTimeFormatOptions
) {
  if (d) {
    return d.toLocaleString(locale ?? DateTime.DATE_SHORT)
  }
  return ''
}

/**
 * convert datetime to display time string
 * NO ZONE CONVERSION
 * @param d Luxon DateTime
 * @param locale locale string
 * @returns string
 */
export function formatCleanTime(
  d: DateTime | undefined,
  locale?: DateTimeFormatOptions
) {
  if (d) {
    return d.toLocaleString(locale ?? DateTime.TIME_SIMPLE)
  }
  return ''
}

/**
 * convert datetime to display string local with time
 * @param d Luxon DateTime
 * @param locale locale string
 * @returns string
 */
export function formatDateTimeWithTime(
  d: DateTime | undefined,
  locale?: DateTimeFormatOptions
) {
  if (d) {
    return `${d
      .setZone('local')
      .toLocaleString(locale ?? DateTime.DATE_SHORT)}, ${d
      .setZone('local')
      .toLocaleString(locale ?? DateTime.TIME_SIMPLE)}`
  }
  return ''
}

/**
 * convert datetime to display string UTC
 * @param d Luxon DateTime
 * @param locale locale string
 * @returns string
 */
export function formatDateTimeUTC(
  d: DateTime | undefined,
  locale?: DateTimeFormatOptions
) {
  if (d) {
    return d.setZone('utc').toLocaleString(locale ?? DateTime.DATE_SHORT)
  }
  return ''
}

/**
 * Find program status display from mapping
 * @param programStatus
 * @param programSubstatus
 * @returns display value or empty string
 */
export function formatProgramStatus(
  programStatus: string,
  programSubstatus: string
): string {
  return programStatusMap[programStatus]?.[programSubstatus] ?? ''
}

/**
 * find treatment status display from mapping
 * @param treatmentStatus
 * @param treatmentSubstatus
 * @returns display value or empty string
 */
export function formatTreatmentStatus(
  treatmentStatus: string,
  treatmentSubstatus: string
): string {
  return treatmentStatusMap[treatmentStatus]?.[treatmentSubstatus] ?? ''
}

/**
 * This function returns a date string set to
 * 8:59PM CST timezone
 * @param date JSDate object
 * @returns 8:59PM CST timezone for date
 */
export function formatDateToCST(date: Date) {
  const zone = 'America/Chicago'
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return DateTime.fromObject({}, { zone })
    .set({
      day,
      month,
      year,
      hour: 20,
      minute: 59,
      second: 59,
      millisecond: 0,
    })
    .toISO()
}

/**
 *
 * @param phoneNumber
 */
export function onlyDigits(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, '')
}

/**
 * Get formatted date of subtask
 * @param subtask
 */
export function formatSubtaskDate(subtask: Subtask) {
  return formatDateTime(stringToDateTime(subtask.statusUpdatedAt))
}

/**
 * Build URL to specific subtask
 * @param urlPath
 * @param subtask
 */
export function buildSubtaskUrl(urlPath: string, subtask: Subtask) {
  return urlPath + '?subtaskId=' + subtask.subtaskId
}

/**
 * Returns human readable format for given consent enum value
 * @param conType
 */
export function formatConsentTitle(conType: ConsentType) {
  return lookupEnum(ConsentType, conType)
}

/**
 * Build URL to specific communication
 * @param communication
 */
export function buildCommunicationUrl(communication: Communication) {
  const patientId = communication.patient?.entityId
  const commId = communication.communicationId

  return `${window.location.origin}/patient/${patientId}?commId=${commId}`
}
