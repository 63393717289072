import { useField } from 'vee-validate'
import { ref, onMounted } from 'vue'
import { fromISOStringWithoutTimeZone } from '@/legacy/libs/date'
import { useHolidaysApi } from '@/legacy/store/modules/holidays'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { handleChange } = useField(props.name, props.rules, {
    initialValue: props.modelValue,
    standalone: props.standalone,
  })

  const holidays = ref<Date[]>([])

  /**
   *
   */
  async function fetchHolidays() {
    try {
      const response = await useHolidaysApi().list({})
      holidays.value = response.data.map((holiday: { date: string }) => {
        return fromISOStringWithoutTimeZone(holiday.date)
      })
    } catch (error) {
      console.error('Error fetching holidays:', error)
    }
  }

  onMounted(fetchHolidays)

  /**
   *
   * @param v
   */
  function setDateValue(v: string | string[] | Date | Date[] | undefined) {
    if (v instanceof Date) {
      handleChange(v)
      context.emit('update:modelValue', v)
    }
  }

  return {
    holidays,
    setDateValue,
  }
}
