import { truncate } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { displayRelationship } from '@/legacy/components/patient/sidebar/lib/contacts'
import { formatDatetime } from '@/legacy/components/patient/transitionOfCare/lib/transitionOfCare'
import { formatNameFromPerson, formatPhone } from '@/legacy/libs/format'
import { getValFromQuery } from '@/legacy/libs/lookup'
import {
  useOpenTextingSubtaskApi,
  useTextingStore,
} from '@/legacy/store/modules/textingV2'
import {
  UNKNOWN_TEXTS_PAGE_URL_NAME,
  getUnreadMessageCount,
} from '../lib/sharedTextingParts'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { messageDeliveryStatusMapBySid } = storeToRefs(useTextingStore())
  const { data: openPointerSubtasks } = storeToRefs(useOpenTextingSubtaskApi())
  const route = useRoute()

  const queryData = computed(() => route.query)
  const commId = computed(() => getValFromQuery(queryData.value, 'commId'))
  const phoneNumId = computed(() =>
    getValFromQuery(queryData.value, 'phoneNumberId')
  )
  const isUnread = ref(false)
  const unreadMessageCount = ref<number | null>(null)

  const click = () => context.emit('select', props.textableIndividual)
  const active = computed(() => {
    const { communicationId, phoneNumberId } = props.textableIndividual
    if (commId.value?.length && commId.value[0]) {
      return communicationId === commId.value[0]
    } else if (phoneNumId.value?.length && phoneNumId.value[0]) {
      return phoneNumberId === phoneNumId.value[0]
    }
    return null
  })

  const isUnknownTextsPage = computed(
    () => route.name === UNKNOWN_TEXTS_PAGE_URL_NAME
  )

  const hasOpenPointerSubtask = computed(() => {
    if (openPointerSubtasks.value && commId.value) {
      return !!openPointerSubtasks.value[
        props.textableIndividual.communicationId
      ]
    }
    return false
  })

  /**
   *
   */
  async function setUnreadMessageDetails() {
    if (props.textableIndividual?.twilioConversationIds?.length) {
      isUnread.value = !!(await getUnreadMessageCount(
        props.textableIndividual.twilioConversationIds[0]
      ))
    }
  }

  watch(messageDeliveryStatusMapBySid, setUnreadMessageDetails)
  onMounted(setUnreadMessageDetails)

  return {
    formatPhone,
    isUnread,
    unreadMessageCount,
    truncate,
    formatDatetime,
    displayRelationship,
    formatNameFromPerson,
    active,
    click,
    hasOpenPointerSubtask,
    isUnknownTextsPage,
  }
}
