import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useGoalApi, useGoalStore } from '@/legacy/store/modules/goals'
import { usePathwaysStore } from '@/legacy/store/modules/pathways'
import { GoalStatus, goalStatuses } from '@/legacy/types/pathways/goals'
import { Pathway } from '@/legacy/types/pathways/pathways'

export enum SortDirection {
  MOVE_UP = 'up',
  MOVE_DOWN = 'down',
}

/**
 *
 * @param props
 */
export default function (props: any) {
  const { isLoading } = storeToRefs(useGoalApi())

  const isCompletedOrCancelled = computed(
    () =>
      props.goal.status === GoalStatus.CANCELLED ||
      props.goal.status === GoalStatus.COMPLETED
  )

  const goalStatusOptions = computed(() => {
    if (props.goal.status === GoalStatus.ACTIVE) {
      return goalStatuses.filter(
        ({ value }) =>
          value === GoalStatus.ON_HOLD ||
          value === GoalStatus.CANCELLED ||
          value === GoalStatus.COMPLETED
      )
    } else if (props.goal.status === GoalStatus.ON_HOLD) {
      return goalStatuses.filter(
        ({ value }) =>
          value === GoalStatus.ACTIVE || value === GoalStatus.CANCELLED
      )
    }
    return []
  })

  const sortOptions = computed(() => {
    if (props.goal.sortNumber === 1) {
      return [{ label: 'Move down', value: SortDirection.MOVE_DOWN }]
    } else if (props.goal.sortNumber === props.goalsNum) {
      return [{ label: 'Move up', value: SortDirection.MOVE_UP }]
    }
    return [
      { label: 'Move up', value: SortDirection.MOVE_UP },
      { label: 'Move down', value: SortDirection.MOVE_DOWN },
    ]
  })

  const goalTitle = computed(
    () =>
      props.goal.freeTextTitle ??
      props.goal.goalReference?.titleDisplay ??
      'Please select goal title'
  )

  /**
   *
   *update goal status from manageGoalsModal
   *update goalIds field on pathways associated to the goal
   * @param changeEvent { originalEvent, value }
   * @param changeEvent.originalEvent
   * @param changeEvent.value
   */
  async function updateGoalStatus({
    value,
  }: {
    originalEvent: Event
    value: GoalStatus
  }) {
    if (props.goal.status === value) {
      return
    }
    const promises: Promise<void>[] = []
    promises.push(
      useGoalStore().updateGoal({
        goalId: props.goal.goalId,
        status: value,
      })
    )
    if (props.goalPathways.length) {
      props.goalPathways.forEach((goalPathway: Pathway) =>
        promises.push(
          usePathwaysStore().updatePathway(goalPathway.pathwayId, {
            goalIds: [],
          })
        )
      )
    }
    await Promise.all(promises)
  }

  /**
   *
   * update sort number on goal when moving a goal
   * up or down in the manage goals modal
   * @param changeEvent { originalEvent, value }
   * @param changeEvent.originalEvent
   * @param changeEvent.value
   */
  async function updateSort({
    value,
  }: {
    originalEvent: Event
    value: string
  }) {
    if (isLoading.value) {
      return
    }
    let sortNum = props.goal.sortNumber
    if (value === SortDirection.MOVE_UP && sortNum > 1) {
      sortNum -= 1
      await useGoalStore().updateGoal({
        goalId: props.goal.goalId,
        sortNumber: sortNum,
      })
    } else if (value === SortDirection.MOVE_DOWN && sortNum < props.goalsNum) {
      sortNum += 1
      await useGoalStore().updateGoal({
        goalId: props.goal.goalId,
        sortNumber: sortNum,
      })
    }
  }

  return {
    goalStatusOptions,
    isCompletedOrCancelled,
    sortOptions,
    updateSort,
    updateGoalStatus,
    SortDirection,
    goalTitle,
  }
}
