<template>
  <h5 class="border-b border-nash-neutral300">Reattempt History</h5>

  <div v-for="(reattempt, index) in reattemptsArray" :key="index" class="mt-1">
    <span
      >{{ index + 1 }}. Reattempt set on
      {{ dateTime(reattempt.createdAt, 'date') }} by
      {{ staffName(reattempt.createdBy) }},
      {{ dateTime(reattempt.createdAt, 'time') }}</span
    >
    <div class="ml-4 text-nash-neutral700">{{ reattempt.reattemptReason }}</div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent, onMounted } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatNameFromEntity,
  formatDateTime,
  formatDateTimeWithTime,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import {
  StaffMap,
  useReattemptApi,
  useReattemptStore,
} from '@/legacy/store/modules/reattempts'
import { useStaffApi } from '@/legacy/store/modules/staff'
import { Reattempt } from '@/legacy/types/pathways/reattempts'
import { Subtask } from '@/legacy/types/pathways/subtasks'

export default defineComponent({
  components: {},
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
  },
  setup(props) {
    const { data: reattempts } = storeToRefs(useReattemptApi())
    const { data: staff } = storeToRefs(useStaffApi())

    const reattemptsArray = computed(() => {
      return Object.values(reattempts.value ?? {})
    })

    onMounted(async () => {
      await useReattemptStore().getReattempts({
        filter_subtask_ids: [props.subtask.subtaskId],
      })
    })

    const sortedReattempts = computed(() => {
      const arr = Array.from(reattemptsArray.value)
      const sorted = arr.sort((a: Reattempt, b: Reattempt) => {
        // ensure sorted by first created
        return new Date(a.createdAt) > new Date(b.createdAt)
          ? 1
          : new Date(b.createdAt) > new Date(a.createdAt)
          ? -1
          : 0
      })
      return sorted
    })

    /**
     *
     * @param staffId
     * Return formatted name of staff
     */
    function staffName(staffId: string): string {
      return formatNameFromEntity(safeLookup(staffId, staff.value as StaffMap))
    }

    /**
     *
     * @param timeStamp
     * @param part
     * Return formatted time or date from timestamp
     */
    function dateTime(timeStamp: string, part: string) {
      const formattedTimeStamp = formatDateTimeWithTime(
        stringToDateTime(timeStamp)
      )
      const [datePart, timePart] = formattedTimeStamp.split(', ')
      if (part === 'date') {
        return datePart
      } else {
        return timePart
      }
    }

    return {
      sortedReattempts,
      reattemptsArray,
      formatDateTime,
      stringToDateTime,
      staffName,
      formatNameFromEntity,
      safeLookup,
      formatDateTimeWithTime,
      dateTime,
    }
  },
})
</script>
