import { computed } from 'vue'
import {
  getTexteeNameOrFormatNumber,
  isPatient,
  isPhoto,
} from '@/legacy/components/texting/lib/sharedTextingParts'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'

/**
 * Setup function for MessagesView.vue
 * @param props
 */
export default function (props: any) {
  const totalMessages = computed(() => props.messages?.length)
  return {
    totalMessages,
    getStaffNameFromId,
    isPhoto,
    getTexteeNameOrFormatNumber,
    isPatient,
  }
}
