<template>
  <AllRemindersTable class="pb-12" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AllRemindersTable from '@/legacy/components/reminders/AllRemindersTable/AllRemindersTable.vue'

export default defineComponent({
  components: { AllRemindersTable },
  setup() {
    return {}
  },
})
</script>
