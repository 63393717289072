import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import capitalize from 'lodash/capitalize'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import {
  getPriorityIcon,
  subtaskPriorityOptionsV2,
  unsetPriority,
  unsetAssignee,
  unsetRole,
  unsetStatus,
  allTasksBulkEditSubtaskStatusOptions,
  datepickerPlaceholder,
  reattemptReasonPlaceholder,
  renderRoleOrStaff,
  unsetSubStatus,
} from '@/legacy/components/patient/pathways/lib/subtask'
import { maxDateWithWeekends } from '@/legacy/libs/date'
import { humanizeRole, staffRoles } from '@/legacy/libs/entity'
import { formatDateToCST, formatNameFromEntity } from '@/legacy/libs/format'

import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePathwaysJobsApi } from '@/legacy/store/modules/jobs'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useStaffApi } from '@/legacy/store/modules/staff'
import { useBulkEditSubtasksApi } from '@/legacy/store/modules/subtasks'
import { EntityRole } from '@/legacy/types/entities/entities'
import {
  minDateTomorrow,
  unselectableDaysOfWeek,
  MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS,
  MAX_ENROLLMENT_REATTEMPT_DAYS,
} from '@/legacy/types/global/dates'
import { JobStatus } from '@/legacy/types/jobs/jobs'
import { NotificationType } from '@/legacy/types/notifications'
import { subStatusLabels } from '@/legacy/types/pathways/pathways'
import {
  BulkEditSubtaskData,
  BulkEditSubtaskPayload,
  Subtask,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'

// CONST
// max 60 business days including weekends
const MAX_BUSINESS_DAYS_WITH_WEEKENDS = 60
// check job status every 3000 milliseconds
const JOB_STATUS_CHECK_INTERVAL_MS = 3000

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { data: staff } = storeToRefs(useStaffApi())
  const { canExtendReattempt } = storeToRefs(useProfileStore())
  const { showSurfacedStatusReason } = storeToRefs(useFlagStore())

  const reasonPlaceholder = 'Reason for change(s)...'

  const selectedPriorityObj = ref<any>(unsetPriority)
  const selectedAssigneeObj = ref<any>(unsetAssignee)
  const selectedRoleObj = ref<any>(unsetRole)
  const selectedStatusObj = ref<any>(unsetStatus)
  const selectedSubStatusObj = ref<any>(unsetSubStatus)

  const runId = ref<string | null>(null)
  const jobStatus = ref<JobStatus | null>(null)
  const isLoading = ref(false)

  const bulkEditReason = ref('')
  const reattemptReason = ref('')

  const reattemptDate = ref<Date | null>(null)
  const isReattempt = ref(false)
  const showSelectedSubtasksView = ref(false)

  const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())

  const showSubStatusDropdown = computed(
    () => selectedStatusObj.value?.val === SubtaskStatus.CANCELLED
  )

  const cancelledSubStatusOptions = computed(() =>
    subStatusLabels.filter((label) => label.status === SubtaskStatus.CANCELLED)
  )

  // Value to determine if 'Confirm' button is disabled or enabled
  const confirmEnabled = computed(() => {
    if (
      showSurfacedStatusReason.value &&
      selectedStatusObj.value?.val === SubtaskStatus.CANCELLED
    ) {
      return selectedSubStatusObj.value?.value
    } else if (selectedStatusObj.value.val === SubtaskStatus.REATTEMPT) {
      return !!reattemptDate.value && reattemptReason.value.length
    } else {
      return (
        !!selectedPriorityObj.value.val ||
        !!selectedAssigneeObj.value.val ||
        !!selectedRoleObj.value.val ||
        !!selectedStatusObj.value.val
      )
    }
  })

  const statusOptions = computed(() =>
    props.disableReattempt
      ? allTasksBulkEditSubtaskStatusOptions.filter(
          (statusObj) => statusObj.val !== SubtaskStatus.REATTEMPT
        )
      : allTasksBulkEditSubtaskStatusOptions
  )

  const assigneeOptions = computed(() => {
    return Object.values(staff.value ?? {})
      .map((staffEntity) => ({
        val: staffEntity.entityId,
        label: formatNameFromEntity(staffEntity),
        icon: null,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  })

  const roleOptions = computed(() => {
    return Object.values(staffRoles ?? {})
      .map((staffRole) => ({
        val: staffRole,
        label: humanizeRole(staffRole),
        icon: null,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  })

  const currentPriorityIcon = computed(() =>
    selectedPriorityObj.value
      ? getPriorityIcon(selectedPriorityObj.value.val)
      : null
  )

  const currentStatusIcon = computed(() =>
    selectedStatusObj.value ? selectedStatusObj.value.icon : null
  )

  const currentStatusIconFill = computed(() =>
    selectedStatusObj.value ? selectedStatusObj.value.fillType : null
  )

  const totalSelectedSubtasksText = computed(() => {
    const totalSubtasks = props.selectedSubtasks.length
    const hasSingular = totalSubtasks === 1
    return `View Selected ${
      hasSingular ? ' Subtask' : ' Subtasks'
    } (${totalSubtasks})`
  })

  const displayMsg = computed(() => {
    if (canExtendReattempt.value) {
      return `Choose a reattempt date for the subtask above. Please note, you can only select a date that falls within the next 90 days.`
    }
    return `Choose a reattempt date for the subtask above. Please note, you can only select a date that falls within the next ${MAX_BUSINESS_DAYS_WITH_WEEKENDS} days.`
  })

  const jobIsRunning = computed(
    () =>
      runId.value &&
      (jobStatus.value === JobStatus.QUEUED ||
        jobStatus.value === JobStatus.RUNNING)
  )
  const jobFailure = computed(
    () =>
      runId.value &&
      (jobStatus.value === JobStatus.REJECTED ||
        jobStatus.value === JobStatus.ERROR)
  )

  const maxReattemptDays = computed(() => {
    if (canExtendReattempt.value) {
      //max 90 business days including weekends
      return maxDateWithWeekends(
        minDateTomorrow,
        MAX_ENROLLMENT_REATTEMPT_DAYS,
        true
      )
    }
    //max 60 business days including weekends
    return maxDateWithWeekends(
      minDateTomorrow,
      MAX_OPTIONAL_NORMAL_PRIORITY_REATTEMPT_DAYS,
      true
    )
  })

  /**
   * Set isLoading boolean if a bulk edit job has started or finished
   * @param loading
   */
  function setIsLoading(loading: boolean) {
    isLoading.value = loading
  }

  /**
   * Function to clear all selected values
   */
  function clearAll() {
    selectedPriorityObj.value = unsetPriority
    selectedAssigneeObj.value = unsetAssignee
    selectedRoleObj.value = unsetRole
    selectedStatusObj.value = unsetStatus
    selectedSubStatusObj.value = unsetSubStatus
    bulkEditReason.value = ''
    reattemptReason.value = ''
    reattemptDate.value = null
    isReattempt.value = false
    showSelectedSubtasksView.value = false
  }

  /**
   * Function to close bulk edit modal if bulk edit job if finished
   * or show window popup prompting user to wait until edits are done
   */
  function close() {
    if (isLoading.value) {
      window.confirm('Please wait until subtask updates are complete.')
    } else {
      clearAll()
      context.emit('close')
      context.emit('clearSelectedSubtasks')
    }
  }

  /**
   * Helper function to set up bulk edit payload
   */
  function setupBulkEditPayload(): BulkEditSubtaskPayload {
    const data: BulkEditSubtaskData[] = []
    props.selectedSubtasks.forEach((selectedSubtask: Subtask) => {
      const selectedPriority = selectedPriorityObj.value.val
      const selectedAssignee = selectedAssigneeObj.value.val
      const selectedRole = selectedRoleObj.value.val
      const selectedStatus = selectedAssignee
        ? SubtaskStatus.OPEN_ASSIGNED
        : selectedRole
        ? SubtaskStatus.OPEN_UNASSIGNED
        : isReattempt.value
        ? null
        : selectedStatusObj.value.val

      data.push({
        subtaskId: selectedSubtask.subtaskId,
        responsibleStaffId: selectedAssignee,
        responsibleRole: selectedRole,
        isBulkEdited: true,
        subStatusReason: selectedSubStatusObj.value?.value,
        ...(selectedPriority ? { priority: selectedPriority } : {}),
        ...(selectedStatus ? { status: selectedStatus } : {}),
        ...(isReattempt.value
          ? { hideDatetime: reattemptDate.value ?? undefined }
          : {}),
        ...(isReattempt.value
          ? { reattemptReason: reattemptReason.value }
          : {}),
      })
    })

    return {
      data,
      ...(bulkEditReason.value.length
        ? { bulkEditNote: bulkEditReason.value }
        : {}),
    }
  }

  /**
   * Function to confirm bulk edit values
   */
  async function confirm() {
    if (confirmEnabled.value) {
      const bulkEditJobRes = await useBulkEditSubtasksApi().create({
        body: setupBulkEditPayload(),
      })

      setIsLoading(true)
      if (bulkEditJobRes) {
        setJobDetails(bulkEditJobRes.runId, bulkEditJobRes.status)
        if (runId.value) {
          const idForJob = runId.value
          useNotificationStore().setNotification({
            message: 'Bulk update in progress',
            type: NotificationType.INFO,
          })
          const getJobStatus = setInterval(async () => {
            const asyncJobRes = await usePathwaysJobsApi().retrieve({
              ids: [idForJob],
            })

            setJobDetails(asyncJobRes.runId, asyncJobRes.status)

            if (!jobIsRunning.value) {
              clearInterval(getJobStatus)
              completeAndCloseModal()
            }
          }, JOB_STATUS_CHECK_INTERVAL_MS)
        }
      }
    }
  }

  /**
   *
   * Helper function to trigger succes/failure and reset state
   */
  function completeAndCloseModal() {
    triggerSuccessOrFailure()
    setIsLoading(false)
    close()
  }

  /**
   *
   * Function to set current runId and jobStatus
   * @param id
   * @param status
   */
  function setJobDetails(id: string, status: JobStatus) {
    runId.value = id
    jobStatus.value = status
  }

  /**
   *
   * Helper function to trigger success or failure notification
   */
  function triggerSuccessOrFailure() {
    if (jobFailure.value) {
      useNotificationStore().setNotification({
        message: 'Failed to bulk edit selected subtasks',
        type: NotificationType.DANGER,
      })
    }

    if (jobStatus.value === JobStatus.COMPLETED) {
      useNotificationStore().setNotification({
        message: 'Successfully bulk edited selected subtasks',
        type: NotificationType.SUCCESS,
      })
      context.emit('callbackFn')
    }
  }

  /**
   * Set selected priority
   * @param event
   * @param event.value
   */
  function setPriorityObj(event: any) {
    selectedPriorityObj.value = subtaskPriorityOptionsV2.find(
      (priorityObj) => priorityObj.val === event.value
    )
  }

  /**
   * Set selected assignee and unset status and role
   * @param event
   * @param event.value
   */
  function setAssigneeObj(event: any) {
    selectedAssigneeObj.value = assigneeOptions.value.find(
      (assigneeObj) => assigneeObj.val === event.value
    )
    selectedStatusObj.value = unsetStatus
    selectedRoleObj.value = unsetRole
  }

  /**
   * Set selected role, unset assignee and status
   * @param event
   * @param event.value
   */
  function setRoleObj(event: any) {
    selectedRoleObj.value = roleOptions.value.find(
      (roleObj) => roleObj.val === event.value
    )
    selectedAssigneeObj.value = unsetAssignee
    selectedStatusObj.value = unsetStatus
  }

  /**
   * Set selected status and unset assignee
   * @param event
   * @param event.value
   */
  function setStatusObj(event: any) {
    if (event.value === SubtaskStatus.REATTEMPT) {
      isReattempt.value = true
    } else if (
      showSurfacedStatusReason.value &&
      event.value === SubtaskStatus.CANCELLED
    ) {
      selectedPriorityObj.value = unsetPriority
      isReattempt.value = false
      reattemptDate.value = null
      reattemptReason.value = ''
    } else {
      isReattempt.value = false
      reattemptDate.value = null
      reattemptReason.value = ''
      selectedSubStatusObj.value = unsetSubStatus
    }

    selectedStatusObj.value = allTasksBulkEditSubtaskStatusOptions.find(
      (statusObj) => statusObj.val === event.value
    )
    selectedAssigneeObj.value = unsetAssignee
    selectedRoleObj.value = unsetRole
  }

  /**
   *
   * @param event
   */
  function setSubStatusObj(event: any) {
    selectedSubStatusObj.value = cancelledSubStatusOptions.value.find(
      (option) => option.value === event.value
    )
  }

  /**
   * Set reattempt date value
   * @param selectedDate
   */
  function setReattemptDate(selectedDate: Date) {
    if (!selectedDate) {
      return null
    }
    const dateTime = formatDateToCST(selectedDate) as string
    reattemptDate.value = new Date(dateTime)
  }

  /**
   * Show/hide selected subtasks view
   */
  function toggleSelectedSubtasksView() {
    showSelectedSubtasksView.value = !showSelectedSubtasksView.value
  }

  return {
    staff,
    isLoading,
    renderRoleOrStaff,
    clearAll,
    EntityRole,
    capitalize,
    getPriorityIcon,
    showSelectedSubtasksView,
    toggleSelectedSubtasksView,
    totalSelectedSubtasksText,
    setReattemptDate,
    displayMsg,
    reattemptReasonPlaceholder,
    datepickerPlaceholder,
    unselectableDaysOfWeek,
    minDateTomorrow,
    maxReattemptDays,
    reattemptDate,
    reattemptReason,
    currentStatusIcon,
    currentStatusIconFill,
    isReattempt,
    setStatusObj,
    selectedStatusObj,
    statusOptions,
    setAssigneeObj,
    assigneeOptions,
    selectedAssigneeObj,
    setRoleObj,
    roleOptions,
    selectedRoleObj,
    reasonPlaceholder,
    bulkEditReason,
    setPriorityObj,
    currentPriorityIcon,
    selectedPriorityObj,
    subtaskPriorityOptionsV2,
    confirmEnabled,
    confirm,
    close,
    ModalSize,
    ButtonSize,
    showSubStatusDropdown,
    showSurfacedStatusReason,
    cancelledSubStatusOptions,
    selectedSubStatusObj,
    setSubStatusObj,
    hideSubtaskEstimatedCompletionTime,
  }
}
