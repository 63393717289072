<template>
  <component
    :is="column.link || column.linkFn ? 'router-link' : 'div'"
    :to="column.link || (column.linkFn && column.linkFn(column, row))"
  >
    <TTruncate
      :label="displayText"
      :class="textClasses"
      :max-chars="column.truncateLength ?? undefined"
      parent-selector="[role=cell]"
    />
  </component>
</template>

<script lang="ts">
import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { computed, defineComponent, PropType } from 'vue'
import TTruncate from '../text/TTruncate.vue'

export default defineComponent({
  components: { TTruncate },
  props: {
    row: {
      type: Object,
      required: true,
    },
    column: {
      type: Object as PropType<ColumnOptions>,
      required: true,
    },
  },
  setup(props) {
    const getField = (field: ColumnOptions['field']) =>
      typeof field === 'function' ? field(props.row) : field ?? ''
    const cellVal = computed(() => props.row[getField(props.column.field)])

    const displayText = computed<string>(() => {
      if (props.column.type === 'list' && props.column.display) {
        return (
          cellVal.value
            // @ts-ignore column.display exists, if statement checks it already
            .map((v: ColumnOptions) => props.column.display(v, props.row))
            .join(', ')
        )
      }
      if (props.column.display) {
        return props.column.display(cellVal.value, props.row)
      }
      if (!cellVal.value) {
        return '--'
      }
      return `${cellVal.value}`
    })

    const textClasses = computed(() =>
      displayText.value === '--' ? 'text-nash-neutral600 italic' : ''
    )

    return {
      getField,
      displayText,
      textClasses,
    }
  },
})
</script>
