import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  ZoomCall,
  ZoomCallRecording,
  ZoomCallTranscript,
} from '@/legacy/types/communications/zoomData'

export const useZoomDataApi = apiStore<ZoomCall>(
  'zoomDataApi',
  '/api/communications/calls/zoom_data',
  {
    transformData: (d: { data: ZoomCall[] }) => d,
  }
)

// IMPROVEME (MT-2647) Endpoint setup incorrectly, needs to use datum
export const useZoomRecordingDataApi = apiStore<
  ZoomCallRecording,
  ZoomCallRecording
>('zoomRecordingDataApi', '/api/communications/calls/zoom_data/recordings')

// IMPROVEME (MT-2647) Endpoint setup incorrectly, needs to use datum
export const useZoomTranscriptDataApi = apiStore<
  ZoomCallTranscript,
  ZoomCallTranscript
>('zoomTranscriptDataApi', '/api/communications/calls/zoom_data/transcripts')
