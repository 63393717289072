import { IdMap } from '@/legacy/types/api/store'
import { UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------
export type OutcomeTaskMapping = {
  outcomeRefId: string
  taskId: string
  outcomeRef?: OutcomeReference
} & Timestamps

export type OutcomePayload = {
  taskId: string
  outcomes: Outcome[]
}

export type DirtyOutcome = Partial<Outcome>

export type OutcomeCatalog = IdMap<OutcomeReference>

export type OutcomeValidation = {
  outcomeValidationId: string
  status: string | undefined
  story: string | null
} & Timestamps

export type Outcome = {
  outcomeId: string
  taskId: string
  outcomeRefId: string
  outcomeValidationId: string | null
  outcomeValidation?: OutcomeValidation | Partial<OutcomeValidation>
} & Timestamps

export type OutcomeReference = {
  outcomeRefId: string
  title: string
  description: string | null
  requiresReview: boolean
} & Timestamps

export type TaskOutcome = {
  outcomeNote: string | null
  outcomeQuotes: string | null
  taskId: UUID
  pathwayId: UUID
  title: string
  statusUpdatedAt: string
} & Outcome

// INTERFACES --------------------
export interface OutcomeFilters {
  freeTextSearch: string
  typeSearchIds: string[]
}

// CONSTANTS --------------------
export const OutcomeValidationStatuses = [
  ['not_reviewed', 'not reviewed'],
  ['needs_more_info', 'needs more info'],
  ['internally_verified', 'internally validated'],
  ['internally_externally_verified', 'internally & externally validated'],
  ['invalid_outcome', 'invalid outcome'],
]
