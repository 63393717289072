import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { createCommunication } from '@/legacy/components/patient/communicationV2/lib/communications'
import {
  createPlannedCall,
  SCHEDULED_CALL_TIME_NOT_SET_DURATION,
  SCHEDULED_CALL_TIME_SET_DURATION,
} from '@/legacy/components/patient/communicationV2/lib/plannedCalls'
import { ISOToUTC } from '@/legacy/libs/date'
import { formatNameFromEntity } from '@/legacy/libs/format'
import router from '@/legacy/router'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  BaseCommunicationPayload,
  Communication,
  SCHEDULED_CALL_TYPE,
} from '@/legacy/types/communications/communications'
import {
  DirtyPlannedCall,
  ScheduledTimeBlock,
} from '@/legacy/types/communications/plannedCalls'
import { Entity, EntityRole } from '@/legacy/types/entities/entities'
import { NotificationType } from '@/legacy/types/notifications'
import {
  MANUAL_CALL_ID,
  patientAndContactIdDropdown,
} from '../../dropdowns/lib/phoneDropdown'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { showScheduledCallNoTime } = storeToRefs(useFlagStore())

  const dirtyPlannedCall = ref<DirtyPlannedCall>(
    props.existingPlannedCall &&
      Object.keys(props.existingPlannedCall)?.length > 0
      ? props.existingPlannedCall
      : {
          // differentiate between null (manual call) and undefined (no selection)
          calleeEntityId: null,
          communicationId: null,
          dueDatetime: DateTime.now().toISO(),
          timeBlock: null,
          duration: showScheduledCallNoTime.value
            ? SCHEDULED_CALL_TIME_NOT_SET_DURATION
            : SCHEDULED_CALL_TIME_SET_DURATION,
          showTime: true,
        }
  )

  const dirtyCommunication = ref<BaseCommunicationPayload>(
    props.existingCommunication &&
      Object.keys(props.existingCommunication).length > 0
      ? props.existingCommunication
      : {
          responsibleStaffId: null,
          responsibleRole: null,
        }
  )

  const shouldSetCallTime = ref(false)

  watch(
    () => props.isOpen,
    () => {
      if (props.isOpen) {
        dirtyPlannedCall.value =
          props.existingPlannedCall &&
          Object.keys(props.existingPlannedCall)?.length > 0
            ? props.existingPlannedCall
            : {
                // differentiate between null (manual call) and undefined (no selection)
                calleeEntityId: null,
                communicationId: null,
                dueDatetime: DateTime.now().toISO(),
                timeBlock: null,
                duration: showScheduledCallNoTime.value
                  ? SCHEDULED_CALL_TIME_NOT_SET_DURATION
                  : SCHEDULED_CALL_TIME_SET_DURATION,
                showTime: true,
              }

        dirtyCommunication.value =
          props.existingCommunication &&
          Object.keys(props.existingCommunication).length > 0
            ? props.existingCommunication
            : {
                responsibleStaffId: null,
                responsibleRole: null,
              }
      }
    }
  )

  /**
   * Update assigned to either entityId or entityRole
   * NULL out the other
   */
  function updateAssigned({
    responsibleStaffId,
    responsibleRole,
  }: {
    [key: string]: string | null
  }) {
    dirtyCommunication.value = {
      ...dirtyCommunication.value,
      responsibleStaffId,
      responsibleRole: responsibleRole as EntityRole | null,
    }
  }

  /**
   * Update Datetime fields from
   * ScheduledDueDatetimeDropdowns
   * @param DirtyPlannedCall
   * @param DirtyPlannedCall.dueDatetime
   * @param DirtyPlannedCall.timeBlock
   * @param DirtyPlannedCall.duration
   * @param DirtyPlannedCall.showTime
   */
  function updateDateTime({
    dueDatetime,
    timeBlock,
    duration,
    showTime,
  }: Partial<DirtyPlannedCall>) {
    dirtyPlannedCall.value = {
      ...dirtyPlannedCall.value,
      dueDatetime: dueDatetime ?? undefined,
      timeBlock: timeBlock,
      duration: duration,
      showTime: showTime,
    }
  }

  // Code for Callee Dropdown

  const { person, entity, phoneNumbers, contacts } = storeToRefs(
    usePatientStore()
  )

  const callOptionEntities = computed(() => {
    if (contacts.value && entity.value && phoneNumbers.value && person.value) {
      return patientAndContactIdDropdown(
        contacts.value,
        entity.value,
        person.value
      )
    }
    return []
  })

  /**
   *
   * @param entity
   */
  function callOptionDisplay(entity: Entity) {
    return `${formatNameFromEntity(entity)} ()`
  }

  // Misc

  // validator for confirm button
  const hasValidData = computed(
    () =>
      !!(
        dirtyPlannedCall.value.dueDatetime &&
        (dirtyCommunication.value.responsibleStaffId ||
          dirtyCommunication.value.responsibleRole) &&
        dirtyPlannedCall.value.calleeEntityId != null
      )
  )

  /**
   * Close the modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param communication
   * @param dirtyCall
   */
  async function createPlannedCallForComm(
    communication: Communication,
    dirtyCall: Partial<DirtyPlannedCall>
  ) {
    const plannedCall = await createPlannedCall(
      communication.communicationId,
      dirtyCall
    )
    if (plannedCall) {
      useCommunicationsStore().communicationType = SCHEDULED_CALL_TYPE
      return
    } else {
      useNotificationStore().setNotification({
        message: 'Failed to create planned call from schedule modal.',
        type: NotificationType.DANGER,
      })
      return
    }
  }

  /**
   * Update current scheduled call
   */
  async function save() {
    const dirtyCall: Partial<DirtyPlannedCall> = {
      ...dirtyPlannedCall.value,
      ...(dirtyPlannedCall.value.calleeEntityId === MANUAL_CALL_ID
        ? { calleeEntityId: undefined }
        : {}),
      dueDatetime: dirtyPlannedCall.value.dueDatetime
        ? ISOToUTC(dirtyPlannedCall.value.dueDatetime) ?? undefined
        : undefined,
      timeBlockStart: dirtyPlannedCall.value.timeBlockStart
        ? ISOToUTC(dirtyPlannedCall.value.timeBlockStart) ?? undefined
        : undefined,
      timeBlockEnd: dirtyPlannedCall.value.timeBlockEnd
        ? ISOToUTC(dirtyPlannedCall.value.timeBlockEnd) ?? undefined
        : undefined,
    }

    if (!Object.keys(props.existingCommunication).length) {
      const communication = await createCommunication(
        SCHEDULED_CALL_TYPE,
        dirtyCommunication.value
      )
      if (communication) {
        await createPlannedCallForComm(communication, dirtyCall)
        await router.push({
          query: {
            commId: communication.communicationId,
          },
        })
      } else {
        useNotificationStore().setNotification({
          message: 'Failed to create communication from schedule modal.',
          type: NotificationType.DANGER,
        })
        return
      }
    }

    context.emit('updateFromModal', {
      dirtyPlannedCall: dirtyCall,
      dirtyCommunication: dirtyCommunication.value,
    })
  }

  return {
    dirtyPlannedCall,
    dirtyCommunication,
    ScheduledTimeBlock,
    callOptionEntities,
    hasValidData,
    callOptionDisplay,
    updateAssigned,
    updateDateTime,
    close,
    save,
    shouldSetCallTime,
    showScheduledCallNoTime,
  }
}
