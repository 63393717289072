<template>
  <div>
    <TModal
      :is-visible="isOpen"
      title="Report Thyme Box Issue"
      :flex="true"
      :allow-overflow="true"
      @close="close"
    >
      <VForm
        v-slot="{ setFieldValue }"
        :initial-values="dirtyValues"
        @submit="submit(dirtyValues)"
      >
        <h4>Issue Type</h4>
        <TDropdown
          v-model="dirtyValues.issueType"
          name="issue-type"
          class="w-full mb-4"
          :options="issueTypeOptions"
          @update:model-value="
                (v: any) => setFieldValue('issueType', v)
              "
        />
        <h4>Severity</h4>
        <TDropdown
          v-model="dirtyValues.severity"
          label="Severity"
          name="severity"
          class="w-full mb-4"
          :options="severityOptions"
          @update:model-value="
                (v: any) => setFieldValue('severity', v)
              "
        />
        <h4>Is this happening always or just sometimes?</h4>
        <TDropdown
          v-model="dirtyValues.persistence"
          name="is-persistent"
          class="w-full mb-4"
          :options="persistenceOptions"
          @update:model-value="
                (v: any) => setFieldValue('persistence', v)
              "
        />
        <h4>Description</h4>
        <TTextarea
          v-model="dirtyValues.description"
          class="w-full mb-4"
          name="description"
          @update:model-value="
            (v: any) => setFieldValue('description', v)
          "
        />
        <LegacyTButton class="flex mt-4" name="submit" value="Submit" />
      </VForm>
    </TModal>
  </div>
</template>

<script lang="ts">
import { Form as VForm } from 'vee-validate'
import { defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TTextarea,
    TModal,
    TDropdown,
    LegacyTButton,
    VForm,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup,
})
</script>

<style lang="scss">
.comorbidity-wrapper {
  @apply border border-nash-neutral400 rounded-lg py-6 px-4;

  &.added {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
