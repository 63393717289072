import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import {
  updateCommunication,
  deleteCommunication,
} from '@/legacy/components/patient/communicationV2/lib/communications'
import { formatNameFromPerson } from '@/legacy/libs/format'
import router from '@/legacy/router'
import { useCommunicationApi } from '@/legacy/store/modules/communications'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  BaseCommunicationPayload,
  CommunicationType,
  DEFAULT_NOTES,
} from '@/legacy/types/communications/communications'

/**
 *
 */
export default function () {
  const scheduledCallModalOpen = ref(false)
  const { datum: communication, isLoading: isLoadingComm } = storeToRefs(
    useCommunicationApi()
  )

  const { patient, person } = storeToRefs(usePatientStore())
  const patientIds = computed(() =>
    communication.value
      ? communication.value.patientIds
      : patient.value
      ? [patient.value.entityId]
      : []
  )

  /**
   *
   * Function to set editing states for communication.
   */
  function setDirtyStates() {
    dirtyCommunication.value = {
      ...(communication.value ?? {}),
      completedDatetime: null,
      notes: communication.value?.notes ?? DEFAULT_NOTES,
      patientIds: patientIds.value,
      type: communication.value?.type ?? CommunicationType.Call,
    }
  }

  const dirtyCommunication = ref<BaseCommunicationPayload>({
    ...(communication.value ?? {}),
    completedDatetime: null,
    notes: communication.value?.notes ?? DEFAULT_NOTES,
    patientIds: patientIds.value,
    type: communication.value?.type ?? CommunicationType.Call,
  })
  watch(communication, () => {
    setDirtyStates()
  })

  const memberName = computed(() =>
    person.value ? formatNameFromPerson(person.value) : 'Member'
  )

  const commId = computed(() => communication.value?.communicationId ?? null)

  const responsible = computed(
    () =>
      dirtyCommunication.value.responsibleStaffId ??
      dirtyCommunication.value.responsibleRole ??
      ''
  )

  /**
   * send create/update call to sidebar
   * triggers api call in sidebar
   * @param updatedField
   */
  async function updateComm(updatedField?: string) {
    await updateCommunication(
      commId.value,
      dirtyCommunication.value,
      updatedField ?? ''
    )
  }

  /**
   *
   * Function to delete both communication
   */
  async function deleteComm() {
    if (
      window.confirm(
        'This action will delete this communication. Do you wish to continue?'
      )
    ) {
      await deleteCommunication(commId.value)
      await router.push({
        query: {
          commId: undefined,
        },
      })
    }
  }

  /**
   * update notes
   * @param val
   */
  function updateNotesForDirtyComm(val: string) {
    dirtyCommunication.value.notes = val
  }

  return {
    isLoadingComm,
    scheduledCallModalOpen,
    memberName,
    responsible,
    dirtyCommunication,
    updateComm,
    deleteComm,
    updateNotesForDirtyComm,
  }
}
