<template>
  <div>
    <CarePlan />
    <ClinicalSummary v-if="showNewClinicalSummary" />
    <SocialSummary v-if="showSocialSummary" />
    <PatientGoals />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'
import PatientGoals from '@/legacy/components/patient/carePlans/PatientCarePlan/PatientGoals/PatientGoals.vue'
import SocialSummary from '@/legacy/components/patient/carePlans/PatientCarePlan/SocialSummary/SocialSummary.vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import CarePlan from '@/pages/PatientProfile/CarePlans/CarePlan/CarePlan.vue'
import ClinicalSummary from '@/pages/PatientProfile/CarePlans/ClinicalSummary/ClinicalSummary.vue'
export default defineComponent({
  components: {
    CarePlan,
    PatientGoals,
    SocialSummary,
    ClinicalSummary,
  },
  setup() {
    const { showSocialSummary, showClinicalSummary: showNewClinicalSummary } =
      storeToRefs(useFlagStore())
    return {
      showSocialSummary,
      showNewClinicalSummary,
    }
  },
})
</script>
<style lang="scss"></style>
