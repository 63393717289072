<template>
  <div class="relative">
    <TMSecondaryButton
      class="add-comms-btn"
      :disabled="isCreating || !!queryCommId"
      label="+ Communication"
      name="add-communication"
      @click="createCommunication"
    />
    <div class="flex space-x-4">
      <TDropdown
        :options="freeTextOptions"
        :initial-value="freeTextFilterName"
        :show-clear="false"
        class="bg-nash-neutral000 h-1/4 mt-5"
        option-label="label"
        option-value="value"
        @update:model-value="setFreeTextFilterName"
      />
      <TSearchAndFilter
        search-placeholder="Search"
        class="mt-5 w-1/3"
        @update-search="updateSearch"
      />
    </div>
    <div v-if="lastSuccessfulCall && showLastSuccessfulCall" class="space-y-4">
      <h5 data-cy="thymeline-header" class="pb-1 flex items-end">
        Last Successful Call &nbsp;<span
          v-if="lastSuccessfulCall?.completedDatetime"
        >
          ({{ getReadableTimeDiff(lastSuccessfulCall?.completedDatetime) }})
        </span>
      </h5>
      <CommunicationsRow
        class="last-successful-call-container"
        :communication="lastSuccessfulCall"
        :patient-id="patientId"
        name="communications"
      />
    </div>
    <div class="space-y-4">
      <!-- Completed thymeline -->
      <TMThymeline
        v-if="useThymelineV2Api"
        v-slot="{ item, getData }"
        :name="`${patientId}-CompletedThymelineV2`"
        class="history-thymeline"
        title="Completed"
        :filters="filters"
        :rows="perPage"
        :columns="columns"
        :params="completedParams"
        :api-store="useCompletedThymelineV2Api"
        :show-rows-per-page="false"
        :show-header-container="true"
      >
        <div
          v-if="!item.subtask && !item.communication && !item.transitionOfCare"
        >
          <span class="text-nash-brick600">ERROR:</span>Item not loaded.
        </div>
        <SubtaskRow
          v-else-if="item.subtask"
          class="w-full"
          :subtask="item.subtask"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.communication"
          class="w-full"
          :communication="item.communication"
          :patient-id="patientId"
          name="communications"
        />
        <TransitionOfCareRow
          v-else-if="item.transitionOfCare"
          :toc="item.transitionOfCare"
        />
      </TMThymeline>
      <!-- IMPROVEME(MT-3404): remove v1 -->
      <TMThymeline
        v-else
        v-slot="{ item, getData }"
        :name="`${patientId}-CompletedThymeline`"
        class="history-thymeline"
        title="Completed"
        :filters="filters"
        :rows="perPage"
        :columns="columns"
        :params="completedParams"
        :api-store="useCompletedThymelineApi"
        :show-rows-per-page="false"
        :show-header-container="true"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          :patient-id="patientId"
          name="communications"
        />
        <TransitionOfCareRow
          v-else-if="item.itemType === ThymelineItemType.TRANSITION_OF_CARE"
          :toc="item.item"
        />
      </TMThymeline>
      <!-- Cancelled thymeline -->
      <LegacyTButton
        type="tertiary"
        class="pt-2"
        icon-position="right"
        :icon="showCancelled ? 'chevronUp' : 'chevronDown'"
        @click="showCancelled = !showCancelled"
        >{{
          showCancelled ? 'Hide Cancelled' : 'Show Cancelled'
        }}</LegacyTButton
      >
      <TMThymeline
        v-if="useThymelineV2Api && showCancelled"
        v-slot="{ item, getData }"
        class="history-thymeline"
        :name="`${patientId}-CancelledThymelineV2`"
        title="Cancelled"
        :rows="perPage"
        :columns="columns"
        :params="cancelledParams"
        :api-store="useCancelledThymelineV2Api"
        :show-rows-per-page="false"
      >
        <div v-if="!item.subtask && !item.communication && !item.toc">
          <span class="text-nash-brick600">ERROR:</span>Item not loaded.
        </div>
        <SubtaskRow
          v-else-if="item.subtask"
          class="w-full"
          :subtask="item.subtask"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.communication"
          class="w-full"
          :communication="item.communication"
          :patient-id="patientId"
          name="communications"
        />
      </TMThymeline>
      <!-- IMPROVEME(MT-3404): remove v1 -->
      <TMThymeline
        v-else-if="showCancelled"
        v-slot="{ item, getData }"
        class="history-thymeline"
        :name="`${patientId}-CancelledThymeline`"
        title="Cancelled"
        :rows="perPage"
        :columns="columns"
        :params="cancelledParams"
        :api-store="useCancelledThymelineApi"
        :show-rows-per-page="false"
      >
        <div v-if="!item.item || !item.itemType">
          <span class="text-nash-brick600">ERROR:</span>Item of type
          {{ (item.itemType ?? 'UNKNOWN').toLowerCase() }} not loaded.
          {{ item.itemId }}
        </div>
        <SubtaskRow
          v-else-if="item.itemType === ThymelineItemType.SUBTASK"
          class="w-full"
          :subtask="item.item"
          :display-type="SubtaskRowDisplayType.THYMELINE"
          :is-overdue="false"
          @open-reattempt-modal="openReattemptModal"
          @open-sub-status-modal="openSubStatusModal"
          @callback-fn="getData"
        />
        <CommunicationsRow
          v-else-if="item.itemType === ThymelineItemType.COMMUNICATION"
          class="w-full"
          :communication="item.item"
          :patient-id="patientId"
          name="communications"
        />
      </TMThymeline>
      <SubtaskReattemptModal
        v-if="selectedReattemptSubtask"
        :subtask="selectedReattemptSubtask"
        :is-open="showReattemptModal"
        @close="closeReattemptModal"
      />
      <SubtaskSubstatusModal
        v-if="
          showSurfacedStatusReason && subtaskStatus && selectedSubStatusSubtask
        "
        :subtask="selectedSubStatusSubtask"
        :status="subtaskStatus"
        :is-open="showSubStatusModal"
        @close="showSubStatusModal = false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'

import { defineComponent } from 'vue'
import SubtaskReattemptModal from '@/legacy/components/patient/pathways/SubtaskReattemptModal.vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import CommunicationsRow from '@/legacy/components/patient/thymeline/CommunicationsRow/CommunicationsRow.vue'
import TransitionOfCareRow from '@/legacy/components/patient/transitionOfCare/TransitionOfCareRow.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TMThymeline from '@/legacy/nashville/table/TMThymeline.vue'
import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TMThymeline,
    SubtaskReattemptModal,
    LegacyTButton,
    SubtaskRow,
    CommunicationsRow,
    TMSecondaryButton,
    TSearchAndFilter,
    TDropdown,
    TransitionOfCareRow,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.add-comms-btn {
  position: absolute;
  top: -40px;
  right: 0;
}

.last-successful-call-container {
  @apply w-full bg-nash-purple100 border border-nash-purple400 rounded-lg px-4 py-3;
}
</style>
