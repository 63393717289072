// TYPES --------------------
export type PreferredContactTime = {
  preferredContactTimeId: string
  patientId: string
  dayPart: ContactTime
}

// ENUMS --------------------

export enum ContactTime {
  MORNING = 'Mornings (8am - 12pm)',
  AFTERNOON = 'Afternoons (12pm - 5pm)',
  EVENING = 'Evenings (5pm - 8pm)',
  ANY = 'Any',
}
