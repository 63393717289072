<template>
  <div v-if="visitNote">
    <PrintableComponent title="Visit Note" unique-id="visitNotePrintElement">
      <div>
        <b>Patient Name: </b>
        {{ formatName(person?.firstName, person?.lastName) }}
      </div>
      <div>
        <b>DOB: </b>
        {{ formatCleanDateTime(stringToDateTime(person?.dateOfBirth)) }}
      </div>
      <div>
        <b>Visit Date: </b>
        {{ formatDateTimeWithTime(stringToDateTime(visitNote.date)) }}
      </div>
      <div v-if="visitNote.signature">
        <b>Provider: </b>
        {{ visitNote.signature.signed_by }}
      </div>
      <div>
        <b>Visit Type: </b>
        {{ visitType }}
      </div>
      <div v-if="visitNote.exam_reason">
        <b>Exam Reason: </b>
        {{ visitNote.exam_reason[0].parent_text }}
      </div>

      <br />

      <VisitNoteList
        :section="visitNote.hpi"
        is-singular
        title="History of Present Illness"
      />
      <VisitNoteList :section="visitNote.allergies" title="Allergies" />
      <VisitNoteList
        :section="visitNote.medical_history"
        title="Past Medical History"
      />
      <VisitNoteList
        :section="visitNote.surgical_history"
        title="Past Surgical History"
      />
      <VisitNoteList :section="visitNote.family" title="Family History" />
      <VisitNoteList
        :section="visitNote.social"
        is-singular
        title="SocialHistory"
      />
      <VisitNoteList :section="visitNote.habits" is-singular title="Habits" />
      <VisitNoteList
        :section="visitNote.medications"
        title="Current Medications"
      />

      <div v-if="visitNote.review_of_systems">
        <b>Review of Systems</b>
        <ul class="list-none">
          <li v-for="review in visitNote.review_of_systems" :key="review.name">
            <i>{{ review.name }}</i
            >: {{ review.value }}
          </li>
        </ul>
        <br />
      </div>

      <div v-if="visitNote.physical_exam">
        <b>Physical Exam</b>
        <ul class="list-none">
          <li v-for="exam in visitNote.physical_exam" :key="exam.name">
            <i>{{ exam.name }}</i
            >: {{ exam.value }}
          </li>
        </ul>
        <br />
      </div>

      <VisitNoteList :section="visitNote.data" title="Data" />
      <VisitNoteList
        :section="visitNote.assessment_plan"
        title="Assessment/Plan"
      />
      <VisitNoteList :section="visitNote.orders" title="Orders" />
      <VisitNoteList
        :section="visitNote.procedure"
        is-singular
        title="Procedure"
      />
      <VisitNoteList :section="visitNote.care_plan" title="Care Plan" />
      <VisitNoteList
        :section="visitNote.follow_up"
        is-singular
        title="Follow Up"
      />

      <div v-if="visitNote.signature">
        <b>Signed Electronically By</b>
        <div>
          {{ visitNote.signature.signed_by }},
          {{
            formatDateTimeWithTime(
              stringToDateTime(visitNote.signature.signed_date)
            )
          }}
        </div>
      </div>
    </PrintableComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import VisitNoteList from '@/legacy/components/patient/visitNote/VisitNoteList.vue'
import PrintableComponent from '@/legacy/components/PrintableComponent.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatCleanDateTime,
  formatName,
  formatDateTimeWithTime,
} from '@/legacy/libs/format'
import { Person } from '@/legacy/types/entities/people'
import { VisitNote } from '@/legacy/types/patients/visitNotes'

export default defineComponent({
  components: { VisitNoteList, PrintableComponent },
  props: {
    visitNote: { type: Object as PropType<VisitNote>, required: true },
    person: { type: Object as PropType<Person | null>, default: null },
    visitType: { type: String, required: true },
  },
  setup() {
    return {
      formatCleanDateTime,
      formatName,
      stringToDateTime,
      formatDateTimeWithTime,
    }
  },
})
</script>
