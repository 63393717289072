<template>
  <TTable
    ref="table"
    name=""
    sort-field="date"
    :sort-order="1"
    :rows="10"
    :columns="columns"
    :api-store="useAppointmentsApi"
    :params="params"
  >
    <template #column-visitNoteId="slotProps">
      <ul v-if="slotProps.row.visitNoteId">
        <li>
          <router-link
            :to="slotProps.column.linkFn(slotProps.column, slotProps.row)[0]"
          >
            Note
          </router-link>
        </li>
        <li>
          <router-link
            :to="slotProps.column.linkFn(slotProps.column, slotProps.row)[1]"
          >
            Patient Summary
          </router-link>
        </li>
        <li v-if="slotProps.row.referralIds.length">
          <router-link
            :to="slotProps.column.linkFn(slotProps.column, slotProps.row)[2]"
          >
            Referrals
          </router-link>
        </li>
      </ul>
    </template>
  </TTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TTable,
  },
  setup,
})
</script>

<style lang="scss" scoped>
:deep(.p-datatable) {
  padding: 0;
}
</style>
