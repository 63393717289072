// ENUMS --------------------
export enum ReportIssueSeverity {
  STANDARD = 'Standard',
  NON_URGENT = 'Non-Urgent',
}

export enum ReportIssuePersistence {
  ALWAYS = 'Always',
  SOMETIMES = 'Sometimes',
}

// TYPES --------------------
export type ReportIssueForm = {
  route: string
  issueType: string
  fullUrl: string | null
  description: string
  persistence: string
  severity: string | null
  sendToSlack: boolean
  reporter: string
}

export const reportIssueTypesForRouteMap: Record<string, string[]> = {
  '#myQueue': [
    'Page not loading',
    'Assigned queue',
    'Unassigned queue',
    'Filters not working',
    'My Schedule not working',
    'Other',
  ],
  '#enrollmentQueue': [
    'Page not loading',
    'Assigned queue',
    'Unassigned queue',
    'Filters not working',
    'My Schedule not working',
    'Other',
  ],
  '#leadQueue': [
    'Page not loading',
    'Assigned queue',
    'Unassigned queue',
    'Filters not working',
    'Other',
  ],
  '#enrollmentLeadQueue': [
    'Page not loading',
    'Assigned queue',
    'Unassigned queue',
    'Filters not working',
    'Other',
  ],
  '#patients': [
    'Page not loading',
    'Search Issue',
    'Filters not working',
    'Other',
  ],
  '#allTasks': [
    'Page not loading',
    'Search not working',
    'Filters not working',
    'Other',
  ],
  '#patientProfile': [
    'Page not loading',
    'Thymeline not working',
    'Texting not working',
    'Calling not working',
    'Patient sidebar issue',
    'Playbook/Task issue',
    'Care Plan issue',
    'Other',
  ],
  // IMPROVEME(MT-3265): post-pilot, add Admin pages
  '#admin/users': ['Page not loading', 'Other'],
  // Default/catch-all case
  '': ['Other'],
}
