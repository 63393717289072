import { requiredInject } from '@thyme/libs/src/vue/inject'
import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { ComputedRef, Ref, computed, onBeforeMount, ref } from 'vue'
import { formatTierScore } from '@/legacy/components/patient/acuity/lib/acuityFns'
import { stringToDateTime, utcDatetimeToDate } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import { mapToLongFormGender } from '@/legacy/libs/patients'
import { useAdminCarePodApi } from '@/legacy/store/modules/admin'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientContractsApi } from '@/legacy/store/modules/patientContracts'
import { usePrioritySegmentApi } from '@/legacy/store/modules/prioritySegments'
import { CarePod } from '@/legacy/types/carePods'
import { Language } from '@/legacy/types/entities/people'
import { CarePodStatus } from '@/legacy/types/patients/carePods'
import { PatientContract } from '@/legacy/types/patients/patientContracts'
import { MemberAppAccess } from '@/legacy/types/patients/patients'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { EditPatientDetailsForm } from './EditPatientDetails/types'
import { getPatientContract } from './queries'

// Defined only for convenience and use in testing.
export type PatientDetailsViewModel = {
  patientContract: Ref<null | PatientContract>
  toggleEditPatientDetailsModal: () => void
  showEditPatientDetails: Ref<boolean>
  age: Ref<string>
  dobDateString: Ref<string | null>
  dodDateString: Ref<string | null>
  enablePatientContracts: Ref<boolean>
  languageString: Ref<string>
  genderString: Ref<string>
  acuityScoreText: Ref<string | null>
  enrollmentSegment: Ref<string | null>
  contractingChipKey: Ref<number>
  refreshContractingChip: () => Promise<void>
  editModalInitialData: Ref<Partial<EditPatientDetailsForm>>
  patientId: Ref<string>
  carePodString: Ref<string>
  memberAppAccessString: Ref<string>
}

/**
 * Component setup for PatientDetails.vue
 */
export function setup(): PatientDetailsViewModel {
  const { patient, person } = storeToRefs(usePatientStore())
  const { data: carePods } = storeToRefs(useAdminCarePodApi())

  // CLEANUP MT-3032
  const { enablePatientContracts } = storeToRefs(useFlagStore())
  const showEditPatientDetails = ref(false)
  const { data: prioritySegments } = storeToRefs(usePrioritySegmentApi())
  const { data: patientContactData } = storeToRefs(usePatientContractsApi())

  const contractingChipKey = ref(0)
  const patientId = requiredInject(PATIENT_ID_KEY)

  onBeforeMount(async () => {
    await getPatientContract(patientId.value)
    await usePrioritySegmentApi().list({
      params: { filter_patient_ids: [patientId.value] },
    })
    if (!carePods.value) {
      await useAdminCarePodApi().listAll({
        params: { filter_care_pod_ids: [patient.value?.carePodId] },
      })
    }
  })

  const patientContract = computed(() => patientContactData.value?.[0] ?? null)

  const enrollmentSegment = computed(() => {
    if (
      prioritySegments.value &&
      Object.keys(prioritySegments.value).length > 0
    ) {
      const enrollmentSegment = Object.values(prioritySegments?.value)[0]
        .enrollmentSegment
      // convert to title case
      return capitalize(enrollmentSegment)
    }
    return null
  })

  const acuityScoreText = computed(() => {
    const pinnedAcuityScore = patient.value?.pinnedAcuityScore
    return pinnedAcuityScore
      ? `${formatTierScore(pinnedAcuityScore.overallScore)} Tier`
      : null
  })

  const dobDatetime = computed(() => {
    if (!person.value?.dateOfBirth) {
      return null
    }
    return stringToDateTime(person.value.dateOfBirth)
  })

  const dodDatetime = computed(() => {
    if (!patient.value?.deceasedDate) {
      return null
    }
    return stringToDateTime(patient.value.deceasedDate)
  })

  const dobDateString = computed(
    () => dobDatetime.value?.toLocaleString(DateTime.DATE_SHORT) ?? null
  )
  const dodDateString = computed(
    () => dodDatetime.value?.toLocaleString(DateTime.DATE_SHORT) ?? null
  )

  const memberAppAccessString = computed(() => {
    if (
      patient.value?.memberAppAccess &&
      patient.value.memberAppAccess !== MemberAppAccess.DISABLED
    ) {
      return 'TC Connect Enabled'
    } else {
      return ''
    }
  })

  const age = computed(() => {
    if (dobDatetime.value) {
      if (patient.value?.deceasedDate) {
        const deceasedAge = stringToDateTime(patient.value.deceasedDate)
        return Math.floor(
          deceasedAge.diff(dobDatetime.value, 'years').years
        ).toString()
      }
      return Math.floor(-dobDatetime.value.diffNow('years').years).toString()
    }
    return 'n/a'
  })

  const languageString: ComputedRef<string> = computed(() => {
    return person.value?.preferredLanguage
      ? lookupEnum(Language, person.value.preferredLanguage)
      : 'N/A'
  })

  const genderString = computed(() => {
    return person.value?.gender
      ? mapToLongFormGender(person.value.gender) ?? 'N/A'
      : 'N/A'
  })

  const carePodString = computed(() => {
    let matchedCarePod
    if (patient.value?.carePodId && carePods.value?.length) {
      matchedCarePod = carePods.value.find(
        ({ carePodId }: CarePod) => carePodId === patient.value?.carePodId
      )
    }
    if (matchedCarePod) {
      return matchedCarePod.status === CarePodStatus.ACTIVE
        ? matchedCarePod.name
        : matchedCarePod.name + '(Archived)'
    } else {
      return 'Unknown Care Pod'
    }
  })

  const editModalInitialData = computed(() => ({
    preferredName: person.value?.preferredName,
    language: person.value?.preferredLanguage,
    dob: utcDatetimeToDate(dobDatetime.value),
    dod: utcDatetimeToDate(dodDatetime.value),
    gender: person.value?.gender,
    carePodId: patient.value?.carePodId,
    contractingEntityId:
      patientContract.value?.contractingEntity?.contractingEntityId,
    contractExternalId: patientContract.value?.contractExternalId,
  }))

  /**
   * Fetch the patient contract and force the contracting chip to re-render
   *
   * IMPROVEME MT-3187: this approach of manual re-renders is a bit hacky,
   * can we use a more reactive approach?
   * Perhaps creating a pinia store for patient contracts
   * and using a computed property in the chip to trigger re-renders
   * when the patient contract is updated
   */
  const refreshContractingChip = async () => {
    await getPatientContract(patientId.value)
  }

  /**
   * Show/hide the EditPatientDetails modal
   */
  function toggleEditPatientDetailsModal() {
    showEditPatientDetails.value = !showEditPatientDetails.value
  }

  return {
    patientContract,
    toggleEditPatientDetailsModal,
    showEditPatientDetails,
    age,
    dobDateString,
    dodDateString,
    enablePatientContracts,
    languageString,
    genderString,
    acuityScoreText,
    enrollmentSegment,
    contractingChipKey,
    refreshContractingChip,
    editModalInitialData,
    patientId,
    carePodString,
    memberAppAccessString,
  }
}
