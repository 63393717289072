import { requiredInject } from '@thyme/libs/src/vue/inject'
import { camelCase, startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { getMostRecentTransitionOfCare } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { useCarePlanTransitionsOfCareApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  arrayToStringDisplay,
  formatStringDisplay,
} from '@/pages/PatientProfile/CarePlans/shared/utils'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { getLastUpdatedTransitionOfCare } from './queries'

import { useLastUpdatedCarePlanTransitionsOfCareApi } from './shared/store'

export type TransitionsOfCareViewModel = {
  patientId: Ref<string>
  transitionsOfCareId: Ref<string | undefined>
  admissionDateDisplay: Ref<string>
  dischargeDateDisplay: Ref<string>
  eventTypeDisplay: Ref<string>
  dischargeDiagnosisDisplay: Ref<string>
  dischargeLocationDisplay: Ref<string>
  isReadmissionRiskDisplay: Ref<string>
  hasNewDmeOnDischargeDisplay: Ref<string>
  lastUpdatedDisplay: Ref<string>
  servicesOnDischargeDisplay: Ref<string>
  moreThanThreeAdmissionsInLastSixMonthsDisplay: Ref<string>
  summaryOfEventsDisplay: Ref<string | null>
  hasElectiveAdmissionDisplay: Ref<string>
  showEditModal: Ref<boolean>
  toggleTransitionsOfCareEdit: () => void
  arrayToStringDisplay: (arr: string[]) => string
  formatStringDisplay: (str: string) => string
  fetchTransitionsOfCare: () => Promise<void>
}

/**
 *
 */
export function setup(): TransitionsOfCareViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { data: transitionsOfCareData } = storeToRefs(
    useCarePlanTransitionsOfCareApi()
  )
  const { data: lastUpdatedToc } = storeToRefs(
    useLastUpdatedCarePlanTransitionsOfCareApi()
  )
  const transitionsOfCare = computed(
    () => transitionsOfCareData.value?.[0] ?? null
  )
  const transitionsOfCareId = computed(
    () => transitionsOfCare.value?.transitionsOfCareId
  )

  const admissionDateDisplay = computed(() =>
    transitionsOfCare.value?.admissionDate
      ? new Date(transitionsOfCare.value.admissionDate).toLocaleDateString()
      : '-'
  )

  const dischargeDateDisplay = computed(() =>
    transitionsOfCare.value?.dischargeDate
      ? new Date(transitionsOfCare.value.dischargeDate).toLocaleDateString()
      : '-'
  )

  const eventTypeDisplay = computed(() =>
    transitionsOfCare.value?.eventType
      ? startCase(camelCase(transitionsOfCare.value?.eventType))
      : '-'
  )

  const dischargeDiagnosisDisplay = computed(() =>
    transitionsOfCare.value?.dischargeDiagnosis
      ? startCase(camelCase(transitionsOfCare.value?.dischargeDiagnosis))
      : '-'
  )

  const dischargeLocationDisplay = computed(() =>
    transitionsOfCare.value?.dischargeLocation
      ? startCase(camelCase(transitionsOfCare.value?.dischargeLocation))
      : '-'
  )

  const isReadmissionRiskDisplay = computed(() =>
    transitionsOfCare.value?.isReadmissionRisk
      ? startCase(camelCase(transitionsOfCare.value?.isReadmissionRisk))
      : '-'
  )

  const hasNewDmeOnDischargeDisplay = computed(() => {
    const hasNewDme = transitionsOfCare.value?.hasNewDmeOnDischarge
    if (hasNewDme === undefined || hasNewDme === null) {
      return '-'
    }
    return hasNewDme ? 'Yes' : 'No'
  })

  const servicesOnDischargeDisplay = computed(() => {
    const services = transitionsOfCare.value?.servicesOnDischarge
    return services && services.length ? arrayToStringDisplay(services) : '-'
  })

  const moreThanThreeAdmissionsInLastSixMonthsDisplay = computed(() => {
    const moreThanThree =
      transitionsOfCare.value?.moreThanThreeAdmissionsInLastSixMonths
    if (moreThanThree === undefined || moreThanThree === null) {
      return '-'
    }
    return moreThanThree ? 'Yes' : 'No'
  })

  const summaryOfEventsDisplay = computed(() => {
    return transitionsOfCare.value?.summaryOfEvents ?? null
  })

  const hasElectiveAdmissionDisplay = computed(() => {
    const hasElective = transitionsOfCare.value?.hasElectiveAdmission
    if (hasElective === undefined || hasElective === null) {
      return '-'
    }
    return hasElective ? 'Yes' : 'No'
  })

  const lastUpdatedDisplay = computed(() => {
    const lastUpdated = lastUpdatedToc.value?.[0]
    const lastUpdatedAtStr = formatLastUpdatedStr(lastUpdated)
    return lastUpdatedAtStr ?? '-'
  })

  const fetchTransitionsOfCare = async () => {
    await getMostRecentTransitionOfCare(patientId.value)
    await getLastUpdatedTransitionOfCare(patientId.value)
  }

  /**
   *
   */
  function toggleTransitionsOfCareEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(async () => {
    await getMostRecentTransitionOfCare(patientId.value)
    await getLastUpdatedTransitionOfCare(patientId.value)
  })

  return {
    patientId,
    transitionsOfCareId,
    admissionDateDisplay,
    dischargeDateDisplay,
    eventTypeDisplay,
    lastUpdatedDisplay,
    dischargeDiagnosisDisplay,
    dischargeLocationDisplay,
    isReadmissionRiskDisplay,
    hasNewDmeOnDischargeDisplay,
    servicesOnDischargeDisplay,
    moreThanThreeAdmissionsInLastSixMonthsDisplay,
    summaryOfEventsDisplay,
    hasElectiveAdmissionDisplay,
    arrayToStringDisplay,
    formatStringDisplay,
    toggleTransitionsOfCareEdit,
    showEditModal,
    fetchTransitionsOfCare,
  }
}
