<template>
  <TMAddableItem :active="active" :disabled="!canAdd" @select="selectPathway">
    <div class="flex flex-col">
      <h5>
        {{ pathwayTemplate.title }}
        {{
          pathwayTemplate.variantDisplay
            ? getVariantDisplay(pathwayTemplate)
            : ''
        }}
      </h5>
      <div class="py-2">
        <TMarkdown :source="pathwayTemplate.description" />
      </div>
    </div>
  </TMAddableItem>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { getVariantDisplay } from '@/legacy/components/patient/pathways/lib/sharedPathwayParts'
import TMAddableItem from '@/legacy/nashville/molecules/TMAddableItem.vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import {
  AddModalActions,
  PathwayTemplate,
} from '@/legacy/types/pathways/pathways'

export default defineComponent({
  components: {
    TMAddableItem,
    TMarkdown,
  },
  props: {
    pathwayTemplate: {
      type: Object as PropType<PathwayTemplate>,
      required: true,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const active = ref(false)

    const selectPathway = (activeVal: boolean) => {
      if (activeVal && props.canAdd) {
        context.emit(
          AddModalActions.ADD,
          props.pathwayTemplate.pathwayTemplateId
        )
      } else if (!activeVal) {
        context.emit(
          AddModalActions.REMOVE,
          props.pathwayTemplate.pathwayTemplateId
        )
      }
      active.value = activeVal
    }

    return {
      active,
      selectPathway,
      getVariantDisplay,
    }
  },
})
</script>
