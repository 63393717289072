import { startCase, toLower } from 'lodash'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { setRoleOrStaff } from '@/legacy/components/patient/pathways/lib/sharedSubtaskParts'
import {
  getPriorityIcon,
  getSubtaskIconName,
  renderDatePretext,
  renderDateString,
} from '@/legacy/components/patient/pathways/lib/subtask'
import { stringToDateTime } from '@/legacy/libs/date'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { EntityRole } from '@/legacy/types/entities/entities'
import { UUID } from '@/legacy/types/global/base'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import { SubtaskStatus } from '@/legacy/types/pathways/subtasks'

/**
 *
 * Setup function for PointerSubtask.vue
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { selfEntity } = storeToRefs(useProfileStore())
  const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())
  const currentIcon = computed(
    () =>
      Object.values(SubtaskStatuses).filter(
        (taskStatus) => taskStatus.value === props.subtask.status
      )[0]?.icon ?? 'openStatus'
  )
  const currentIconFill = computed(
    () =>
      Object.values(SubtaskStatuses).filter(
        (taskStatus) => taskStatus.value === props.subtask.status
      )[0]?.fillType ?? 'openStatus'
  )

  // status options for pointer subtasks only include open assigned, open unassigned, and completed
  const statusOptions = Object.values(SubtaskStatuses)
    .map(({ icon, text, value, fillType }) => ({
      icon: icon,
      label: text,
      value: value,
      fillType: fillType,
    }))
    .filter((statusOption) =>
      [
        SubtaskStatus.OPEN_UNASSIGNED,
        SubtaskStatus.OPEN_ASSIGNED,
        SubtaskStatus.COMPLETED,
      ].includes(statusOption.value)
    )

  const currentSubtaskStatus = computed(() => ({
    icon: getSubtaskIconName(props.subtask.status),
    label: SubtaskStatuses[props.subtask.status].text,
    value: props.subtask.status,
  }))

  /**
   *
   */
  async function updateSubtask(payload: { [key: string]: any }) {
    await useSubtasksStore().updateSubtask(props.subtask.subtaskId, payload)
    context.emit('refetchCommsAndSubtasks')
  }

  /**
   *
   * @param status
   */
  async function setStatus(status: SubtaskStatus) {
    if (status === SubtaskStatus.OPEN_UNASSIGNED) {
      await updateSubtask({
        responsibleRole: props.subtask.responsibleRole,
        responsibleStaffId: null,
        status,
      })
    } else if (status === SubtaskStatus.OPEN_ASSIGNED) {
      await updateSubtask({
        responsibleStaffId: selfEntity.value?.entityId,
        status,
      })
    } else {
      await updateSubtask({
        responsibleStaffId: selfEntity.value?.entityId,
        status,
      })
    }
  }

  const currentStaff = computed(
    () =>
      props.subtask.responsibleStaffId ??
      props.subtask.responsibleRole ??
      'NULL'
  )

  const isOverdue = computed(
    () => stringToDateTime(props.subtask.dueDatetime) < DateTime.now()
  )

  /**
   *
   * @param val
   */
  async function updateRoleOrStaff(val: EntityRole | UUID | null) {
    const selfEntityId = selfEntity.value ? selfEntity.value?.entityId : ''
    const updateSubtaskPayload = setRoleOrStaff(
      val,
      props.subtask,
      selfEntityId
    )

    await updateSubtask(updateSubtaskPayload)
  }

  return {
    startCase,
    toLower,
    renderDatePretext,
    renderDateString,
    isOverdue,
    getPriorityIcon,
    setStatus,
    statusOptions,
    currentIcon,
    currentIconFill,
    currentSubtaskStatus,
    currentStaff,
    updateRoleOrStaff,
    hideSubtaskEstimatedCompletionTime,
  }
}
