<template>
  <PracticesTable class="pb-12" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PracticesTable from '@/legacy/components/practiceList/PracticesTable.vue'

export default defineComponent({
  components: { PracticesTable },
  setup() {
    return {}
  },
})
</script>
