<template>
  <div class="relative h-full">
    <div class="sms-message-box-wrapper flex shrink-1 flex-col">
      <div
        class="flex shrink-0 p-3 border-b border-nash-neutral300 justify-center flex-col align-center items-center space-y-2"
      >
        <h4 class="font-bold">
          {{ currentTextableIndividual }}
        </h4>
        <div
          v-if="isSharedNumberWithAnotherMember || isSharedNumberWithContact"
          class="w-full space-y-2"
        >
          <div v-if="isSharedNumberWithAnotherMember">
            <TMessage
              :expanded="false"
              :severity="MessageSeverity.WARN"
              name="active-conversation-shared-member"
              :label="sharedPhoneNumberWithAnotherMemberMsg"
            />
          </div>
          <div v-if="isSharedNumberWithContact">
            <TMessage
              :expanded="false"
              :severity="MessageSeverity.WARN"
              name="active-conversation-shared-contact"
              :label="sharedPhoneNumberWithAnotherMemberMsg"
            />
          </div>
        </div>
      </div>
      <AllSms />
      <div class="flex bg-nash-neutral000 px-5 pb-5 flex-col shrink-0 w-full">
        <MessageBox v-if="showMessageBox" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AllSms from '@/legacy/components/texting/AllSms/AllSms.vue'
import MessageBox from '@/legacy/components/texting/MessageBox/MessageBox.vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'

import setup from './controller'

export default defineComponent({
  components: { MessageBox, AllSms, TMessage },
  setup,
})
</script>
<style scoped lang="scss">
.sms-message-box-wrapper {
  max-height: 100%;
  flex: auto;
}
</style>
<style lang="scss" scoped>
.warning-container {
  @apply border-0 rounded-lg py-2.5 px-3 w-full font-medium bg-nash-mustard100 text-nash-mustard700;
}
</style>
