import apiStore from '@/legacy/store/modules/apiBuilder'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'

export const useLastUpdatedMedicalConditionApi = apiStore<MedicalCondition>(
  'lastUpdatedMedicalConditionApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)
