/**
 * IMPROVEME(SPC-1409) convert to validation schema style
 * This file extends shared validation rules from Nashville.
 * This is now deprecated in favor of useForm schema validation and will be removed in the future.
 */

import { localize } from '@vee-validate/i18n'
import { url } from '@vee-validate/rules'
import { configure, defineRule } from 'vee-validate'
import { formatStringToArray } from '@/legacy/components/reminders/lib/sharedRemindersParts'

// Define and use shared vee-validate rules
import '@thyme/nashville/src/components/forms/shared/validationRules'

defineRule('urls', (value: string) => {
  if (value) {
    const errors = []
    const urls = formatStringToArray(value)
    for (let i = 0; i < urls.length; i++) {
      if (!url(urls[i], {})) {
        errors.push(
          `${urls[i]}: must be a valid URL (including http:// or https://).`
        )
      }
    }
    return errors.join('\n')
  } else {
    return true
  }
})

configure({
  generateMessage: localize('en', {
    messages: {
      url: 'This field must contain a valid URL (including http:// or https://).',
    },
  }),
})
