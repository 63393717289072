import apiStore from '@/legacy/store/modules/apiBuilder'
import { MapProviderPatient } from '@/legacy/types/patients/mapProvidersPatients'

export const useMapProvidersPatientsApi = apiStore<
  MapProviderPatient,
  MapProviderPatient[]
>('mapProvidersPatientsApi', '/api/patients/provider_maps', {
  transformData: (d: { data: MapProviderPatient[] }) => d,
})

// Used for PatientMedicalProviders to get Map, Patient, and Provider data
export const useProvidersPatientsMapApi = apiStore<
  MapProviderPatient,
  MapProviderPatient[]
>('providersPatientsMapApi', '/api/patients/provider_maps', {
  transformData: (d: { data: MapProviderPatient[] }) => d,
})
