<template>
  <div class="mb-5">
    <div class="space-y-2 mt-5">
      <div
        v-for="commType in communicationTypeList"
        :key="commType.label"
        data-cy="communication-type-container"
      >
        <TMSecondaryButton
          :disabled="commType.disabled"
          :label="commType.label"
          name="communication-type-option"
          expanded
          @click="() => selectCommType(commType)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import setup from './controller'

export default defineComponent({
  components: { TMSecondaryButton },
  emits: ['select', 'scheduledCallModalOpen'],
  setup,
})
</script>
