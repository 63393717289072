<template>
  <div class="t-collapse">
    <div
      :class="`collapse__trigger flex items-center ${
        disableJustifyBetween ? '' : 'justify-between'
      }`"
      @click="toggle"
    >
      <slot name="trigger"></slot>
      <TIcon
        class="text-nash-neutral600"
        :class="`${isLeft ? 'is-pulled-left' : 'is-pulled-right'} is-vcentered`"
        :icon="visible ? 'chevronUp' : 'chevronDown'"
      />
    </div>
    <transition
      name="collapse"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-if="useVShow">
        <div v-show="visible" class="collapse__wrapper">
          <div class="collapse__content">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="visible" class="collapse__wrapper">
          <div class="collapse__content">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: { TIcon },
  props: {
    disableJustifyBetween: { type: Boolean, default: false },
    isVisible: { type: Boolean, default: false },
    isLeft: { type: Boolean, default: true },
    // Whether to use v-show or v-if for collapsing logic
    // v-show is lower performance than v-if but avoids re-renders
    useVShow: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  setup(props, context) {
    const visible = ref(props.isVisible)
    watch(
      () => props.isVisible,
      (isVisible: boolean) => (visible.value = isVisible)
    )
    const toggle = () => {
      visible.value = !visible.value
      context.emit('toggle', visible.value)
    }
    const start = (el: Element) => {
      ;(el as HTMLElement).style.height = `${el.scrollHeight}px`
    }
    const end = (el: Element) => {
      ;(el as HTMLElement).style.height = ''
    }

    return {
      visible,
      toggle,
      start,
      end,
    }
  },
})
</script>

<style lang="scss" scoped>
.collapse-enter-active,
.collapse-leave-active {
  will-change: height, opacity;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
}
.collapse-enter,
.collapse-leave-to {
  height: 0 !important;
  opacity: 0;
}

.t-collapse {
  @apply my-1.5 cursor-pointer;
  &__content {
    @apply my-1.5 cursor-default;
  }
}
</style>
