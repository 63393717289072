<template>
  <span
    class="px-2 py-1 rounded-full border border-green-400 text-green-400 font-medium text-base w-max"
  >
    {{ label }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: { type: String, default: '' },
  },
})
</script>
