<template>
  <div class="space-y-2">
    <TDropdown
      v-model="dirtyTimes"
      class="w-full"
      input-id="contactTimeEdit"
      placeholder="Contact Time(s)"
      option-label="label"
      option-value="value"
      :options="times"
      :multiple="true"
      :max-selected-labels="0"
      :selected-items-label="`Contact Time(s) ({0})`"
    />
    <TDropdown
      v-model="dirtyMethods"
      class="w-full"
      input-id="contactMethodEdit"
      placeholder="Method(s) of Contact"
      option-label="label"
      option-value="value"
      :options="methods"
      :multiple="true"
      :max-selected-labels="0"
      :selected-items-label="`Method(s) of Contact ({0})`"
    />
    <div class="mt-2 flex flex-row-reverse">
      <button
        class="p-button p-component mt-2"
        name="save-contact-preferences"
        @click="save"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineComponent, ref, PropType } from 'vue'

import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import { useContactMethodsApi } from '@/legacy/store/modules/contactMethods'
import { useContactTimesApi } from '@/legacy/store/modules/contactTimes'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { NotificationType } from '@/legacy/types/notifications'
import { ContactMethod } from '@/legacy/types/patients/contactMethods'
import { ContactTime } from '@/legacy/types/patients/contactTimes'
export default defineComponent({
  components: { TDropdown },
  props: {
    outreachPreferences: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  emits: ['fetchOutreachPreferences'],
  setup(props, context) {
    const { entity } = storeToRefs(usePatientStore())
    const times = Object.entries(ContactTime).map(([key, display]) => {
      return {
        label: display,
        value: key,
      }
    })
    const methods = Object.entries(ContactMethod).map(([key, display]) => {
      return {
        label: display,
        value: key,
      }
    })

    const preferredTimes = ref<ContactTime[]>(props.outreachPreferences[0])
    const preferredMethods = ref<ContactMethod[]>(props.outreachPreferences[1])
    const dirtyTimes = ref<ContactTime[]>(props.outreachPreferences[0])
    const dirtyMethods = ref<ContactMethod[]>(props.outreachPreferences[1])

    const save = async () => {
      if (
        preferredTimes.value.sort().join(',') !==
        dirtyTimes.value.sort().join(',')
      ) {
        const response = await useContactTimesApi().upsert({
          body: {
            patientId: entity.value?.entityId,
            dayParts: dirtyTimes.value,
          },
        })
        if (response) {
          preferredTimes.value = response.dayParts
          useNotificationStore().setNotification({
            message: 'Successfully created/updated preferred times.',
            type: NotificationType.SUCCESS,
          })
        }
      }
      if (
        preferredMethods.value.sort().join(',') !==
        dirtyMethods.value.sort().join(',')
      ) {
        const response = await useContactMethodsApi().upsert({
          body: {
            patientId: entity.value?.entityId,
            methods: dirtyMethods.value,
          },
        })
        if (response) {
          preferredMethods.value = response.methods
          useNotificationStore().setNotification({
            message: 'Successfully created/updated preferred methods.',
            type: NotificationType.SUCCESS,
          })
        }
      }
      context.emit('fetchOutreachPreferences')
    }

    return {
      ContactTime,
      ContactMethod,
      dirtyTimes,
      dirtyMethods,
      entity,
      times,
      methods,
      save,
    }
  },
})
</script>

<style lang="scss">
.sidebar-title {
  @apply text-gray-900 border-b border-nash-neutral300 pb-1 mb-2 flex items-end justify-between;
}
</style>
