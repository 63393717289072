import { requiredInject } from '@thyme/libs/src/vue/inject'
import debounce from 'lodash/debounce'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import { useRoute } from 'vue-router'
import { stringToDateTime } from '@/legacy/libs/date'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useOpenThymelineApi,
  useInProgressThymelineApi,
  useBlockedThymelineApi,
  useOpenThymelineV2Api,
  useInProgressThymelineV2Api,
  useBlockedThymelineV2Api,
} from '@/legacy/store/modules/thymelines'

import {
  Subtask,
  SubtaskRowDisplayType,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'
import {
  ThymelineItemType,
  ThymelineStatus,
} from '@/legacy/types/patients/thymelines'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import {
  columns,
  perPage,
  overloadSearchServiceList,
} from '../lib/sharedThymelineParts'

// CONST
const DEBOUNCE_INTERVAL_MS = 300

/**
 *
 */
export function setup() {
  const route = useRoute()

  const showBlocked = ref(false)

  const showReattemptModal = ref(false)
  const selectedReattemptSubtask = ref<Subtask | null>(null)
  const showSubStatusModal = ref(false)
  const { showSurfacedStatusReason, useThymelineV2Api } = storeToRefs(
    useFlagStore()
  )
  const selectedSubStatusSubtask = ref<Subtask | null>(null)
  const subtaskStatus = ref<SubtaskStatus | null>(null)

  const patientId = requiredInject(PATIENT_ID_KEY)

  // IMPROVEME(MT-3404): remove all overload-related code
  if (!useThymelineV2Api.value) {
    overloadSearchServiceList(useOpenThymelineApi())
    overloadSearchServiceList(useInProgressThymelineApi())
    overloadSearchServiceList(useBlockedThymelineApi())
  }

  const openParams: { [key: string]: any } = computed(() => {
    return {
      patient_id: patientId.value,
      filter_status: ThymelineStatus.OPEN,
      filter_free_text_title: '',
      filter_free_text_notes: '',
    }
  })

  const inProgressParams: { [key: string]: any } = computed(() => {
    return {
      patient_id: patientId.value,
      filter_status: ThymelineStatus.IN_PROGRESS,
      filter_free_text_title: '',
      filter_free_text_notes: '',
    }
  })

  const blockedParams: { [key: string]: any } = computed(() => {
    return {
      patient_id: patientId.value,
      filter_status: ThymelineStatus.BLOCKED,
      filter_free_text_title: '',
      filter_free_text_notes: '',
    }
  })

  /**
   * Trigger close reattempt modal
   */
  function closeReattemptModal() {
    showReattemptModal.value = false
  }
  /**
   * Trigger open reattempt modal
   * @param subtask
   */
  function openReattemptModal(subtask: Subtask) {
    showReattemptModal.value = true
    selectedReattemptSubtask.value = subtask
  }

  /**
   * Function to show/open SubStatusModal modal
   * @param subtask
   * @param status
   */
  function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
    showSubStatusModal.value = true
    subtaskStatus.value = status
    selectedSubStatusSubtask.value = subtask
  }

  /**
   * Validate if subtask is past due, compared to current datetime
   * @param root0 Subtask
   * @param root0.dueDatetime string
   */
  function isOverdue({ dueDatetime }: Subtask) {
    return stringToDateTime(dueDatetime) < DateTime.now()
  }

  /** Add Communication Button Code */

  const communicationsStore = useCommunicationsStore()

  const isCreating = computed(() => communicationsStore.isCreating)
  const queryCommId = computed(() => `${route.query.commId ?? ''}`)

  /**
   *
   */
  function createCommunication() {
    void communicationsStore.setIsCreating()
  }

  /** End Add Communication Button Code */

  /** Search Filter Code */

  const currentSearch = ref('')
  const freeTextFilterName = ref('filter_free_text_title')
  const freeTextOptions = [
    { label: 'Subtask Title', value: 'filter_free_text_title' },
    { label: 'Notes', value: 'filter_free_text_notes' },
  ]

  const updateSearch = (newSearch: string) =>
    (currentSearch.value = newSearch ?? '')

  const debouncedSearch = debounce(() => {
    thymeDispatch('thymeline-update')
  }, DEBOUNCE_INTERVAL_MS)

  /**
   *
   */
  function setFilterValues() {
    openParams.value[freeTextFilterName.value] = currentSearch.value
    inProgressParams.value[freeTextFilterName.value] = currentSearch.value
    blockedParams.value[freeTextFilterName.value] = currentSearch.value
  }

  const setFreeTextFilterName = (val: string) => {
    currentSearch.value = ''
    setFilterValues()
    freeTextFilterName.value = val
  }

  watch(currentSearch, async () => {
    setFilterValues()
    debouncedSearch()
  })

  /** End Search Filter Code */

  return {
    selectedReattemptSubtask,
    showReattemptModal,
    openReattemptModal,
    closeReattemptModal,
    showSurfacedStatusReason,
    subtaskStatus,
    showSubStatusModal,
    selectedSubStatusSubtask,
    openSubStatusModal,
    patientId: patientId.value,
    perPage,
    columns,
    showBlocked,
    isOverdue,

    ThymelineItemType,
    SubtaskRowDisplayType,

    useOpenThymelineApi,
    useInProgressThymelineApi,
    useBlockedThymelineApi,

    openParams,
    inProgressParams,
    blockedParams,

    isCreating,
    queryCommId,
    createCommunication,

    setFreeTextFilterName,
    updateSearch,
    currentSearch,
    freeTextOptions,
    freeTextFilterName,

    // V2 API
    useThymelineV2Api,
    useOpenThymelineV2Api,
    useInProgressThymelineV2Api,
    useBlockedThymelineV2Api,
  }
}
