<template>
  <TMAddableItem :active="active" @select="select">
    <div class="inner-wrapper">
      <h5>
        {{ subtaskTemplate.title }}
        {{
          subtaskTemplate.variantDisplay
            ? `${subtaskTemplate.variantDisplay}`
            : ''
        }}
      </h5>
      <div class="py-2">
        <TMarkdown :source="subtaskTemplate.description" />
      </div>
      <div class="flex gap-3 mt-1 text-nash-neutral700">
        <div
          v-if="!hideSubtaskEstimatedCompletionTime"
          class="flex items-center space-x-1 gap-1"
        >
          {{ subtaskTemplate.estimatedMinutesToComplete ?? 0 }} min
        </div>
        <div v-if="!hideSubtaskEstimatedCompletionTime" class="bullet"></div>
        <div class="flex items-center space-x-1 gap-1">
          <div>{{ humanizeRole(subtaskTemplate.responsibleRole) }}</div>
        </div>
      </div>
      <div
        v-if="active && subtaskTemplate.queueStyle !== QueueStyle.ENROLLMENT"
        class="space-y-4 mt-4 max-w-sm"
      >
        <div class="flex gap-3">
          <TMActionDropdown
            class="bg-nash-neutral000 min-w-fit min-w-155"
            :icon="currentPriorityIcon"
            name="subtask-priority"
            :initial-value="selectedPriorityObj"
            :options="subtaskPriorityOptionsV2"
            option-label="label"
            option-value="val"
            :show-clear="false"
            @change="setPriorityObj"
          />
          <TCalendar
            id="subtask-due-date"
            placeholder="Set Due Date"
            name="subtask-due-date"
            :min-date="minDateToday"
            :unselectable-days-of-week="unselectableDaysOfWeek"
            :clearable="false"
            :hug-content="true"
            :model-value="dueDatetime ?? undefined"
            @update:model-value="setSubtaskCreatePayload"
          />
        </div>
        <div>
          <TInputText
            class="max-w-sm"
            placeholder="Subject line"
            :max-length="MAX_CHAR_SUBTASK_SUBJECT_LINE"
            name="subtask-custom-subject-line"
            :model-value="subjectLine ?? ''"
            @update:model-value="setSubjectLine"
          />
        </div>
      </div>
      <div v-else-if="active">
        <div class="flex gap-3">
          <div>
            <TInputText
              class="max-w-sm"
              placeholder="Subject line"
              :max-length="MAX_CHAR_SUBTASK_SUBJECT_LINE"
              name="subtask-custom-subject-line"
              :model-value="subjectLine ?? ''"
              @update:model-value="setSubjectLine"
            />
          </div>
        </div>
      </div>
    </div>
  </TMAddableItem>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType, ref, computed } from 'vue'
import { stringTojsDateTime } from '@/legacy/libs/date'
import { humanizeRole } from '@/legacy/libs/entity'
import { formatDateToCST } from '@/legacy/libs/format'
import TMActionDropdown from '@/legacy/nashville/dropdown/TMActionDropdown.vue'
import TMAddableItem from '@/legacy/nashville/molecules/TMAddableItem.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'

import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  minDateToday,
  unselectableDaysOfWeek,
} from '@/legacy/types/global/dates'
import { AddModalActions } from '@/legacy/types/pathways/pathways'
import {
  MAX_CHAR_SUBTASK_SUBJECT_LINE,
  QueueStyle,
  SubtaskPriority,
  SubtaskTemplate,
} from '@/legacy/types/pathways/subtasks'
import {
  getPriorityIcon,
  subtaskPriorityOptions,
  subtaskPriorityOptionsV2,
} from './lib/subtask'

export default defineComponent({
  components: {
    TInputText,
    TCalendar,
    TMAddableItem,
    TMActionDropdown,
    TMarkdown,
  },
  props: {
    subtaskTemplate: {
      type: Object as PropType<SubtaskTemplate>,
      required: true,
    },
  },
  emits: ['setSubtaskPayload', 'add', 'remove'],
  setup(props, context) {
    const active = ref(false)
    const dueDatetime = ref<Date | null>(null)
    const subjectLine = ref<string | null>(null)
    const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())

    const selectedPriorityObj = ref<any>({
      val: props.subtaskTemplate.priority,
      label:
        SubtaskPriority[
          props.subtaskTemplate.priority as keyof typeof SubtaskPriority
        ],
      icon: getPriorityIcon(props.subtaskTemplate.priority),
    })

    const currentPriorityIcon = computed(() =>
      getPriorityIcon(selectedPriorityObj.value.val)
    )

    /**
     * Set selected priority
     * @param event
     * @param event.value
     */
    function setPriorityObj(event: any) {
      selectedPriorityObj.value = subtaskPriorityOptionsV2.find(
        (priorityObj) => priorityObj.val === event.value
      )
      if (
        props.subtaskTemplate.queueStyle !== QueueStyle.ENROLLMENT &&
        dueDatetime.value
      ) {
        context.emit('setSubtaskPayload', {
          customSubject: subjectLine.value,
          dueDatetime: dueDatetime.value,
          variantId: props.subtaskTemplate.variantId,
          priority: selectedPriorityObj.value.val,
        })
      }
    }

    /**
     * Set subtask create payload
     * @param selectedDate
     */
    function setSubtaskCreatePayload(selectedDate: Date) {
      const dateTime = formatDateToCST(selectedDate) as string

      dueDatetime.value = new Date(dateTime)

      context.emit('setSubtaskPayload', {
        dueDatetime: dateTime,
        customSubject: subjectLine.value,
        variantId: props.subtaskTemplate.variantId,
        priority: selectedPriorityObj.value.val,
      })
    }

    const select = (activeVal: boolean) => {
      if (activeVal) {
        context.emit(AddModalActions.ADD, props.subtaskTemplate.variantId)
      } else if (!activeVal) {
        context.emit(AddModalActions.REMOVE, props.subtaskTemplate.variantId)
        dueDatetime.value = null
        subjectLine.value = null
        selectedPriorityObj.value = {
          val: props.subtaskTemplate.priority,
          label:
            SubtaskPriority[
              props.subtaskTemplate.priority as keyof typeof SubtaskPriority
            ],
          icon: getPriorityIcon(props.subtaskTemplate.priority),
        }
      }
      active.value = activeVal
    }

    /**
     * Set customSubject line text
     * @param val
     */
    function setSubjectLine(val: string) {
      subjectLine.value = val
      if (subjectLine.value.length === 0) {
        subjectLine.value = null
      }

      if (
        props.subtaskTemplate.queueStyle !== QueueStyle.ENROLLMENT &&
        dueDatetime.value
      ) {
        context.emit('setSubtaskPayload', {
          dueDatetime: dueDatetime.value,
          customSubject: subjectLine.value,
          variantId: props.subtaskTemplate.variantId,
          priority: selectedPriorityObj.value.val,
        })
      } else if (props.subtaskTemplate.queueStyle === QueueStyle.ENROLLMENT) {
        context.emit('setSubtaskPayload', {
          dueDatetime: dueDatetime.value,
          customSubject: subjectLine.value,
          variantId: props.subtaskTemplate.variantId,
          priority: selectedPriorityObj.value.val,
        })
      }
    }

    return {
      MAX_CHAR_SUBTASK_SUBJECT_LINE,
      subjectLine,
      setSubjectLine,
      setPriorityObj,
      subtaskPriorityOptionsV2,
      selectedPriorityObj,
      currentPriorityIcon,
      subtaskPriorityOptions,
      minDateToday,
      unselectableDaysOfWeek,
      active,
      dueDatetime,
      humanizeRole,
      select,
      setSubtaskCreatePayload,
      stringTojsDateTime,
      QueueStyle,
      hideSubtaskEstimatedCompletionTime,
    }
  },
})
</script>
<style lang="scss" scoped>
.inner-wrapper {
  @apply flex flex-col w-full;
}
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
