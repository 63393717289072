import { requiredInject } from '@thyme/libs/src/vue/inject'
import startCase from 'lodash/startCase'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, Ref } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime } from '@/legacy/libs/format'
import { addECOGStatusLevel } from '@/pages/PatientProfile/CarePlans/shared/format'
import { getPatientClinicalSummary } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { usePatientClinicalSummaryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { ECOGStatus } from '@/pages/PatientProfile/CarePlans/shared/types'
import { arrayToStringDisplay } from '@/pages/PatientProfile/CarePlans/shared/utils'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'

// Defined only for convenience and use in testing.
export type PatientClinicalSummaryViewModel = {
  cancerDisplay: Ref<string>
  spreadDisplay: Ref<string>
  currentTreatmentsDisplay: Ref<string>
  medicalConditionsDisplay: Ref<string>
  ecogStatusDisplay: Ref<string | ECOGStatus>
  tocLastDischargeDateDisplay: Ref<string>
}

/**
 *
 */
export function setup(): PatientClinicalSummaryViewModel {
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { datum: clinicalSummaryData } = storeToRefs(
    usePatientClinicalSummaryApi()
  )

  onBeforeMount(async () => {
    await getPatientClinicalSummary(patientId.value)
  })

  const cancerDisplay = computed(() => {
    const cancerDx = clinicalSummaryData.value?.cancerDiagnosis
    if (cancerDx == null) {
      return '-'
    }

    if (cancerDx.otherOncologyDxType) {
      return `${startCase(cancerDx.otherOncologyDxType)}`
    }

    return startCase(cancerDx.oncologyDiagnosisReference.description)
  })

  const spreadDisplay = computed(() => {
    const cancerDx = clinicalSummaryData.value?.cancerDiagnosis
    if (
      cancerDx == null ||
      cancerDx.otherOncologyDxType ||
      !cancerDx.oncologySpreadRefMaps.length
    ) {
      return '-'
    }

    const spreadRefs = cancerDx.oncologySpreadRefMaps.map(
      (spread: any) => spread.oncologySpreadReference
    )
    const spreadRefDescriptions = spreadRefs.map(
      (spreadRef: any) => spreadRef.description
    )
    return arrayToStringDisplay(spreadRefDescriptions)
  })

  const currentTreatmentsDisplay = computed(() => {
    const currentTreatments = clinicalSummaryData.value?.currentTreatments
    if (currentTreatments == null) {
      return '-'
    }
    return `${currentTreatments}`
  })

  const medicalConditionsDisplay = computed(() => {
    const DO_NOT_MUTATE_STRINGS = true
    const medicalConditions = clinicalSummaryData.value?.medicalConditions
    if (!medicalConditions?.length) {
      return '-'
    }
    const medicalConditionTypes = medicalConditions.map((mc) =>
      mc.medicalConditionReference
        ? mc.medicalConditionReference.description
        : mc.otherMedicalCondition
    ) as string[]

    return arrayToStringDisplay(medicalConditionTypes, DO_NOT_MUTATE_STRINGS)
  })

  const ecogStatusDisplay = computed(() => {
    const ecogStatus = clinicalSummaryData.value?.ecogStatus
    if (ecogStatus == null) {
      return '-'
    }
    return addECOGStatusLevel(ecogStatus)
  })

  const tocLastDischargeDateDisplay = computed(() => {
    const tocLastDischargeDate = clinicalSummaryData.value?.tocLastDischargeDate
    if (tocLastDischargeDate == null) {
      return '-'
    }
    return `Last discharge on ${formatDateTime(
      stringToDateTime(tocLastDischargeDate)
    )}`
  })

  return {
    cancerDisplay,
    spreadDisplay,
    currentTreatmentsDisplay,
    medicalConditionsDisplay,
    ecogStatusDisplay,
    tocLastDischargeDateDisplay,
  }
}
