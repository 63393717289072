<template>
  <div
    v-if="devWarning"
    class="p-1 bg-nash-mustard600 font-semibold text-center"
  >
    {{ devWarning }}
  </div>
  <div
    v-if="showInformationBanner && productWarningConfig?.message"
    :class="`flex justify-center p-2 ${productWarningConfig?.styles} font-semibold text-center`"
  >
    <TIcon :icon="productWarningConfig?.icon" class="mx-1" />
    {{ productWarningConfig?.message }}
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import setup from './controller'

export default defineComponent({
  components: {
    TIcon,
  },
  setup,
})
</script>
