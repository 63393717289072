<template>
  <TMAddableItem :active="active" @select="selectComorbidity">
    <div class="flex flex-col">
      <h5>
        {{ comorbidityReference.description }}
      </h5>
    </div>
  </TMAddableItem>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import TMAddableItem from '@/legacy/nashville/molecules/TMAddableItem.vue'
import { AddModalActions } from '@/legacy/types/pathways/pathways'
import { ComorbidityReference } from '@/legacy/types/patients/comorbidities'

export default defineComponent({
  components: { TMAddableItem },
  props: {
    comorbidityReference: {
      type: Object as PropType<ComorbidityReference>,
      required: true,
    },
  },
  setup(props, context) {
    const active = ref(false)

    const selectComorbidity = (activeVal: boolean) => {
      if (activeVal) {
        context.emit(
          AddModalActions.ADD,
          props.comorbidityReference.comorbidityRefId
        )
      } else if (!activeVal) {
        context.emit(
          AddModalActions.REMOVE,
          props.comorbidityReference.comorbidityRefId
        )
      }
      active.value = activeVal
    }

    return {
      active,
      selectComorbidity,
    }
  },
})
</script>
