// ENUMS

export enum JobStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  // Error statuses
  ERROR = 'ERROR',
  REJECTED = 'REJECTED',
}

export enum OperationType {
  TRIGGER_IMPORT = 'TRIGGER_IMPORT',
  PATHWAY_IMPORT = 'PATHWAY_IMPORT',
  PATHWAY_EXPORT = 'PATHWAY_EXPORT',
  BULK_SUBTASK_EDIT = 'BULK_SUBTASK_EDIT',
}

// TYPES

export type Job = {
  runId: string
  jobId: string
  status: JobStatus
  operationType: OperationType | string
  startTime: string
  endTime: string | null
  requesterId: string
  inputMetadata: JSON | string | null
  output: JSON | string | null
  s3Files?: string[]
}

export type S3FileDownload = {
  url: string
}
