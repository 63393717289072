import cloneDeep from 'lodash/cloneDeep'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useCarePlanSummaryApi } from '@/legacy/store/modules/carePlanSummaries'
import { useComorbidityRefApi } from '@/legacy/store/modules/comorbiditiesReferences'
import { useDiagnosisRefApi } from '@/legacy/store/modules/diagnosesReferences'
import { useOncologyTreatmentRefApi } from '@/legacy/store/modules/oncologyTreatmentsReferences'
import {
  useCommunicationApi,
  useTaskApi,
  useGoalApi,
  usePathwayApi,
} from '@/legacy/store/modules/page/navigationReport'
import {
  useComorbiditiesApi,
  useDiagnosesApi,
  usePatientStore,
  useOncologyTreatmentApi,
} from '@/legacy/store/modules/patient'
import { IdMap } from '@/legacy/types/api/store'
import { GoalStatus } from '@/legacy/types/pathways/goals'
import { Pathway } from '@/legacy/types/pathways/pathways'
import { Comorbidity } from '@/legacy/types/patients/comorbidities'
import { Diagnosis } from '@/legacy/types/patients/diagnoses'

/**
 *
 * @param patientId id of patient to retrieve
 */
export async function loadAllData(patientId: string) {
  await Promise.all([
    usePatientStore().fetchPatient(patientId),
    useCommunicationApi().listAll({
      params: {
        filter_patient_ids: [patientId],
        filter_is_completed: true,
      },
    }),
    useTaskApi().listAll({
      params: {
        filter_patient_ids: [patientId],
      },
    }),
    useGoalApi().listAll({
      params: {
        filter_patient_ids: [patientId],
        parts: ['goal_reference'],
      },
    }),
    usePathwayApi().listAll({
      params: {
        filter_patient_ids: [patientId],
        parts: ['task_maps'],
      },
    }),
    useCarePlanSummaryApi().list({
      params: {
        filter_patient_ids: [patientId],
      },
    }),
    useComorbiditiesApi().listAll({
      params: {
        filter_patient_ids: [patientId],
      },
    }),
    useDiagnosesApi().listAll({
      params: {
        filter_patient_ids: [patientId],
      },
    }),
    useOncologyTreatmentApi().listAll({
      params: {
        filter_patient_ids: [patientId],
      },
    }),
  ])

  // Reference Catalogs
  const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
  const { data: diagnoses } = storeToRefs(useDiagnosesApi())
  const { data: oncologyTreatments } = storeToRefs(useOncologyTreatmentApi())
  /**
   * Convert an idMap of (OBJ type T) into
   * a list of ref ids for those OBJs
   * @param idMap IdMap of OBJ <T>
   * @param refIdField string field to find ref
   * returns string[] refId list
   */
  function getRefIdsFromIdMap<T>(idMap: IdMap<T> | null, refIdField: keyof T) {
    return Object.values(idMap ?? {}).map(
      (obj: T) => obj[refIdField]
    ) as string[]
  }

  const promises: Promise<void>[] = []

  /**
   * Populate promise array with list calls for reference stores
   * @param store Reference Store
   * @param ids list of ids to filter by
   * @param filter filter by ids (optional, return ALL if not passed)
   * returns void
   */
  function getRefsFromStore(
    store: any,
    ids: string[] | null = null,
    filter: string | null = null
  ) {
    if (filter && !ids?.length) {
      console.debug(`no refs to grab for store using filter ${filter}`)
      return
    }

    promises.push(
      store().listAll(filter ? { params: { [`filter_${filter}`]: ids } } : {})
    )
  }

  const idMaps = {
    comorbidity: getRefIdsFromIdMap<Comorbidity>(
      comorbidities.value,
      'comorbidityRefId'
    ),
    diagnosis: getRefIdsFromIdMap<Diagnosis>(diagnoses.value, 'diagnosisRefId'),
  }

  getRefsFromStore(
    useComorbidityRefApi,
    idMaps.comorbidity,
    'comorbidity_ref_ids'
  )
  getRefsFromStore(useDiagnosisRefApi, idMaps.diagnosis, 'diagnosis_ref_ids')

  getRefsFromStore(
    useOncologyTreatmentRefApi,
    oncologyTreatments.value?.map(
      (treatment) => treatment.oncologyTreatmentRefId
    ) ?? [],
    'oncology_treatment_ref_ids'
  )
  await Promise.all(promises)
}

/**
 * filter and sort list of items by datetime range
 * @param data list of items to sort
 * @param field location of datetime on datum object
 * @param start filter out anything before this datetime
 * @param end filter out anything after this datetime
 */
export function filterAndSortByTimeframe(
  data: any[],
  field: string,
  start: DateTime | null,
  end: DateTime | null
) {
  let finalList = cloneDeep(data)
  /**
   *
   * @param v
   */
  function toDateTime(v: string | DateTime) {
    if (typeof v === 'string') {
      return DateTime.fromISO(v)
    }
    return v
  }

  if (start || end) {
    finalList = finalList.filter(
      (d) =>
        d[field] &&
        // remove items before start
        (!start || toDateTime(d[field]) >= start) &&
        // remove items after end
        (!end || toDateTime(d[field]) <= end)
    )
  }
  const sortDates = (a: any, b: any) => (a[field] < b[field] ? 1 : -1)
  return finalList.sort(sortDates)
}

/**
 *
 * @param patientId id of patient to retrieve
 * @param startDateTime filter out anything before this datetime
 * @param endDateTime filter out anything after this datetime
 */
export function getDataByTimeframe(
  patientId: string,
  startDateTime: DateTime | null = null,
  endDateTime: DateTime | null = null
) {
  void loadAllData(patientId)

  const { patient, person } = storeToRefs(usePatientStore())
  const { data: communications } = storeToRefs(useCommunicationApi())
  const { data: tasks } = storeToRefs(useTaskApi())
  const { data: pathways } = storeToRefs(usePathwayApi())
  const { data: goals } = storeToRefs(useGoalApi())
  const { data: carePlanSummaries } = storeToRefs(useCarePlanSummaryApi())

  const filteredComms = computed(() =>
    communications.value
      ? filterAndSortByTimeframe(
          communications.value,
          'completedDatetime',
          startDateTime,
          endDateTime
        )
      : []
  )
  const filteredTasks = computed(() =>
    tasks.value
      ? filterAndSortByTimeframe(
          tasks.value,
          'statusUpdatedAt',
          startDateTime,
          endDateTime
        )
      : []
  )

  const filteredSortedGoals = computed(() =>
    goals.value
      ? goals.value
          .filter((goal) => goal.status === GoalStatus.ACTIVE)
          .sort((goalA, goalB) => goalA.sortNumber - goalB.sortNumber)
      : []
  )

  const goalToPathwayMap = computed(() =>
    goalIdToPathwayIdMapping(Object.values(pathways.value ?? {}))
  )

  return {
    patient,
    person,
    communications: filteredComms,
    tasks: filteredTasks,
    pathways,
    goals: filteredSortedGoals,
    goalToPathwayMap,
    carePlanSummaries,
  }
}

/**
 * Get mapping of goal id to pathway ids for use in goals section
 * @param pathways
 * @returns aggregated mapping of goal id to pathway ids
 */
export function goalIdToPathwayIdMapping(pathways: Pathway[]) {
  return pathways.reduce<{ [key: string]: string[] }>((mappingObj, pathway) => {
    ;(pathway.goalIds ?? []).forEach((goalId) =>
      mappingObj[goalId]
        ? mappingObj[goalId].push(pathway.pathwayId)
        : (mappingObj[goalId] = [pathway.pathwayId])
    )
    return mappingObj
  }, {})
}
