<template>
  <StaffQueueTable
    v-if="useStacksIncident121225"
    ref="staffQueue"
    :is-enrollment-specialist="true"
  />
  <UnassignedEnrollmentQueueTable
    v-if="useStacksIncident121225"
    :has-bulk-edit="true"
    :is-enrollment-lead-queue="true"
    @assign="refreshMembers"
  />
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import UnassignedEnrollmentQueueTable from '@/legacy/components/queue/enrollmentQueue/UnassignedEnrollmentQueueTable/UnassignedEnrollmentQueueTable.vue'
import StaffQueueTable from '@/legacy/components/queue/StaffQueueTable/StaffQueueTable.vue'
import { useFlagStore } from '../store/modules/flags/flags'

export default defineComponent({
  components: { StaffQueueTable, UnassignedEnrollmentQueueTable },
  setup() {
    const staffQueue = ref<{ getData: () => object } | null>(null)
    const { useStacksIncident121225 } = storeToRefs(useFlagStore())

    // We need to pass this to the unassigned table because when users assign
    // themselves a certain subtask from unassigned queue, we also need to
    // refresh the my members table along with unassigned table.
    const refreshMembers = () => staffQueue.value?.getData()

    return {
      useStacksIncident121225,
      staffQueue,
      refreshMembers,
    }
  },
})
</script>
