import { toIdMap } from '@/legacy/libs/store'
import { MeosCharge } from '@/legacy/types/admin/meosCharges'
import {
  MapPathwaysTasks,
  MapTasksSubtasks,
  TaskSubtaskDependency,
  PathwayTaskDependency,
} from '@/legacy/types/admin/pathwaysAdmin'
import { IdMap } from '@/legacy/types/api/store'
import { CarePod, CarePodStaff } from '@/legacy/types/carePods'
import {
  ThymePhoneNumber,
  ThymePhoneNumberConfiguration,
  GroupedThymePhoneNumberConfiguration,
} from '@/legacy/types/communications/thymePhoneNumbers'
import { DocumentCategory } from '@/legacy/types/documents'
import {
  ApiEntityRole,
  Entity,
  EntityRole,
} from '@/legacy/types/entities/entities'
import { Person } from '@/legacy/types/entities/people'
import {
  PathwayTemplate,
  PathwayTemplateBase,
} from '@/legacy/types/pathways/pathways'
import {
  SubtaskTemplate,
  SubtaskTemplateBase,
} from '@/legacy/types/pathways/subtasks'
import { TaskTemplate, TaskTemplateBase } from '@/legacy/types/pathways/tasks'
import { TriggerOutcome } from '@/legacy/types/pathways/triggers/outcomes'
import { Trigger } from '@/legacy/types/pathways/triggers/triggers'
import { Patient } from '@/legacy/types/patients/patients'
import { TransitionOfCare } from '@/legacy/types/patients/transitionsOfCare'
import apiStore from './apiBuilder'

export const useAdminEntityApi = apiStore<Entity>(
  'adminEntityApi',
  '/api/entities',
  {
    transformData: (d: { data: Entity[] }) => {
      d.data = d.data.map<Entity>((entity): Entity => {
        if (entity.roles?.length) {
          entity.userRoles = entity.roles.map(
            (role: ApiEntityRole) => role.role
          )
        }
        return entity
      })
      return d
    },
  }
)

export const useAdminEntityRolesApi = apiStore<EntityRole>(
  'adminEntityRoleApi',
  '/api/entities/roles'
)

export const useAdminCarePodApi = apiStore<CarePod>(
  'adminCarePodApi',
  '/api/care_pods',
  {
    transformData: (d: { data: CarePod[] }) => {
      d.data = d.data.map<CarePod>((pod): CarePod => {
        if (pod.staff?.length) {
          pod.podMembers = pod.staff.map(
            (staff: CarePodStaff) => staff.staffEntityId
          )
        }
        return pod
      })
      return d
    },
  }
)

export const useAdminPatientApi = apiStore<Patient>(
  'adminPatientApi',
  '/api/patients',
  {
    transformData: (d: { data: Patient[] }) => d,
  }
)

export const useDocumentCategoryApi = apiStore<DocumentCategory>(
  'adminDocumentCategoryApi',
  '/api/documents/categories',
  {
    transformData: (d: { data: DocumentCategory[] }) => d,
  }
)

export const useTriggerApi = apiStore<Trigger>('triggerApi', '/api/triggers', {
  transformData: (d: { data: Trigger[] }) => d,
})

export const useTriggerOutcomeApi = apiStore<TriggerOutcome>(
  'triggerOutcomeApi',
  '/api/trigger_outcomes',
  {
    transformData: (d: { data: TriggerOutcome[] }) => d,
  }
)

export const useThymePhoneNumberApi = apiStore<ThymePhoneNumber>(
  'thymePhoneNumberApi',
  '/api/thyme_phone_numbers',
  {
    transformData: (d: { data: ThymePhoneNumber[] }) => d,
  }
)

export const createThymePhoneNumberConfigurationApi = (state: string) =>
  apiStore<ThymePhoneNumberConfiguration>(
    `${state}ThymePhoneNumberConfigurationApi`,
    '/api/thyme_phone_number_configurations',
    {
      transformData: (d: { data: ThymePhoneNumberConfiguration[] }) => d,
    }
  )

export const useThymePhoneNumberConfigurationApi =
  apiStore<ThymePhoneNumberConfiguration>(
    'thymePhoneNumberConfigurationApi',
    '/api/thyme_phone_number_configurations',
    {
      transformData: (d: { data: ThymePhoneNumberConfiguration[] }) => d,
    }
  )

export const useGroupedThymePhoneNumberConfigurationApi =
  apiStore<GroupedThymePhoneNumberConfiguration>(
    'groupedThymePhoneNumberConfigurationApi',
    '/api/thyme_phone_number_configurations/grouped',
    {
      transformData: (d: { data: GroupedThymePhoneNumberConfiguration[] }) => d,
    }
  )

export const useAdminPeopleApi = apiStore<Person, IdMap<Person>>(
  'adminPeopleApi',
  '/api/people',
  {
    transformData: (d: { data: Person[] }) => ({
      data: toIdMap(d.data, 'entityId'),
    }),
  }
)

// Admin Subtask Template Bases/Variants ----------------------------------
export const useAdminSubtaskTemplateBaseApi = apiStore<SubtaskTemplateBase>(
  'adminSubtaskTemplateBaseApi',
  '/api/subtask_templates/bases',
  {
    transformData: (d: { data: SubtaskTemplateBase[] }) => d,
  }
)

export const useSubtaskTemplateBaseApi = apiStore<SubtaskTemplateBase>(
  'subtaskTemplateBaseApi',
  '/api/subtask_templates/bases',
  {
    transformData: (d: { data: SubtaskTemplateBase[] }) => d,
  }
)

export const useSubtaskTemplateApi = apiStore<SubtaskTemplate>(
  'subtaskTemplateApi',
  '/api/subtask_templates',
  {
    transformData: (d: { data: SubtaskTemplate[] }) => d,
    params: {
      parts: ['staff_completable_forms', 'member_completable_forms'],
    },
  }
)

export const createAdminSubtaskTemplateBaseApi = (state: string) =>
  apiStore<SubtaskTemplate>(
    `${state}SubtaskTemplateApi`,
    '/api/subtask_templates',
    {
      transformData: (d: { data: SubtaskTemplate[] }) => d,
      params: {
        parts: ['staff_completable_forms', 'member_completable_forms'],
      },
    }
  )

// Admin Task Template Bases/Variants ----------------------------------
export const useAdminTaskTemplateBaseApi = apiStore<TaskTemplateBase>(
  'adminTaskTemplateBaseApi',
  '/api/task_templates/bases',
  {
    transformData: (d: { data: TaskTemplateBase[] }) => d,
  }
)

export const useTaskTemplateBaseApi = apiStore<TaskTemplateBase>(
  'taskTemplateBaseApi',
  '/api/task_templates/bases',
  {
    transformData: (d: { data: TaskTemplateBase[] }) => d,
  }
)

export const useTaskTemplateApi = apiStore<TaskTemplate>(
  'taskTemplateApi',
  '/api/task_templates',
  {
    transformData: (d: { data: TaskTemplate[] }) => d,
  }
)

export const useMapTasksSubtasksApi = apiStore<MapTasksSubtasks>(
  'mapTasksSubtasksApi',
  '/api/task_templates/map_tasks_subtasks',
  {
    transformData: (d: { data: MapTasksSubtasks[] }) => d,
  }
)

export const useTaskSubtaskDependenciesApi = apiStore<TaskSubtaskDependency>(
  'taskSubtaskDependenciesApi',
  '/api/subtask_dependency_templates',
  {
    transformData: (d: { data: TaskSubtaskDependency[] }) => d,
  }
)

export const createAdminTaskTemplateBaseApi = (state: string) =>
  apiStore<TaskTemplate>(`${state}TaskTemplateApi`, '/api/task_templates', {
    transformData: (d: { data: TaskTemplate[] }) => d,
  })

// Admin Pathway Template Bases/Variants ----------------------------------
export const useAdminPathwayTemplateBaseApi = apiStore<PathwayTemplateBase>(
  'pathwayTemplateBaseApi',
  '/api/pathway_templates/bases',
  {
    transformData: (d: { data: PathwayTemplateBase[] }) => d,
  }
)

export const useAdminPathwayTemplateApi = apiStore<PathwayTemplateBase>(
  'adminPathwayTemplateBaseApi',
  '/api/pathway_templates',
  {
    transformData: (d: { data: PathwayTemplateBase[] }) => d,
  }
)

export const usePathwayTemplateApi = apiStore<PathwayTemplateBase>(
  'pathwayTemplateApi',
  '/api/pathway_templates',
  {
    transformData: (d: { data: PathwayTemplateBase[] }) => d,
  }
)

export const useMapPathwaysTasksApi = apiStore<MapPathwaysTasks>(
  'mapPathwaysTasksApi',
  '/api/pathway_templates/map_pathways_tasks',
  {
    transformData: (d: { data: MapPathwaysTasks[] }) => d,
  }
)

export const usePathwayTaskDependenciesApi = apiStore<PathwayTaskDependency>(
  'pathwayTaskDependenciesApi',
  '/api/task_dependency_templates',
  {
    transformData: (d: { data: PathwayTaskDependency[] }) => d,
  }
)

export const createAdminPathwayTemplateBaseApi = (state: string) =>
  apiStore<PathwayTemplate>(
    `${state}PathwayTemplateApi`,
    '/api/pathway_templates',
    {
      transformData: (d: { data: PathwayTemplate[] }) => d,
    }
  )

export const useTransitionOfCareApi = apiStore<TransitionOfCare>(
  'transitionsOfCareApi',
  '/api/transitions_of_care',
  {
    transformData: (d: { data: TransitionOfCare[] }) => d,
  }
)

export const createTransitionOfCareApi = (state: string) =>
  apiStore<TransitionOfCare>(
    `${state}TransitionOfCareApi`,
    '/api/transitions_of_care',
    {
      transformData: (d: { data: TransitionOfCare[] }) => d,
    }
  )

export const useMeosChargeApi = apiStore<MeosCharge>(
  'meosChargeApi',
  '/api/meos_charges',
  {
    transformData: (d: { data: MeosCharge[] }) => d,
  }
)
