<template>
  <TDropdown
    :model-value="modelValue"
    class="w-full bg-white"
    :input-id="name"
    option-label="label"
    option-value="value"
    :options="formattedStatusOptions"
    :show-clear="false"
    :disabled="!editable"
    @update:model-value="(k) => $emit('update:modelValue', emitVal(k))"
  />
</template>

<script lang="ts">
import kebabCase from 'lodash/kebabCase'
import { computed, defineComponent, PropType } from 'vue'
import { toIdMap } from '@/legacy/libs/store'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import { IntentMap, StatusMap } from '@/legacy/types/patients/patients'

export default defineComponent({
  components: {
    TDropdown,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    statusOptions: {
      type: Array as PropType<StatusMap[] | IntentMap[]>,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const statusesByKey = computed(() => {
      return toIdMap(props.statusOptions, 'key')
    })

    const formattedStatusOptions = computed(() =>
      props.statusOptions.map((statusObj) => ({
        value: statusObj.key,
        label: statusObj.display,
      }))
    )

    /**
     *
     * @param k
     */
    function emitVal(k: string) {
      if (k === 'None') {
        return props.statusOptions.find((d) => d.display === k)
      } else {
        return statusesByKey.value[k]
      }
    }

    return {
      kebabCase,
      emitVal,
      formattedStatusOptions,
    }
  },
})
</script>
