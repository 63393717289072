import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { TriggerInput } from '@/legacy/types/pathways/triggers/inputs'
import { Trigger } from '@/legacy/types/pathways/triggers/triggers'

export const useTriggersApi = apiStore<Trigger, IdMap<Trigger>>(
  'triggersApi',
  '/api/triggers',
  {
    transformData: (d: { data: Trigger[] }) =>
      idMapTransform({}, 'data', 'variantId', d.data),
  }
)

export const useTriggerInputsApi = apiStore<TriggerInput>(
  'triggerInputsApi',
  '/api/triggers/inputs',
  {
    transformData: (d: { data: TriggerInput[] }) => d,
  }
)
