<template>
  <!-- @vue-skip -->
  <o-dropdown
    :name="name"
    :model-value="modelValue"
    :disabled="disabled"
    :expanded="expanded"
    :multiple="multiple"
    :position="position"
    :scrollable="true"
    :data-cy="`${kebabCase(name)}-select`"
    class="relative"
    :class="[{ 'w-full': expanded }]"
    v-bind="$attrs"
    @change="onChange"
  >
    <template #trigger>
      <slot
        name="trigger"
        :icon="icon"
        :model-value="modelValue"
        :multiple="multiple"
        :option-text="selectedOptionText"
        :label="label"
        :num-selected="numSelected"
      >
        <div
          id="dropdown-text-container"
          class="flex items-center hover:text-nash-neutral900 hover:bg-nash-purple100 pl-1"
          :class="triggerClasses"
        >
          <TIcon
            v-if="icon"
            :icon="icon"
            :color="iconFillType"
            :size="iconSize"
            class="mr-1"
          />
          <div v-if="truncateLength">
            <TTruncate
              v-if="modelValue && !multiple"
              :label="selectedOptionText"
              :max-chars="truncateLength"
              :parent-selector="parentSelector"
            />
            <TTruncate
              v-else
              :label="label"
              :max-chars="truncateLength"
              :parent-selector="parentSelector"
            />
            <TTruncate
              v-if="multiple && numSelected"
              :max-chars="truncateLength"
              :label="numSelected"
              :parent-selector="parentSelector"
            />
          </div>
          <div v-else>
            <span v-if="modelValue && !multiple">{{ selectedOptionText }}</span>
            <span v-else>{{ label }}</span>
            <span v-if="multiple && numSelected"> ({{ numSelected }})</span>
          </div>
        </div>
      </slot>
    </template>
    <!-- this dropdown item will use `itemName` from `sanitizedOptions`
    NOT from the props -->
    <template v-if="groupedOptions">
      <div v-for="(gOptions, key) in groupedOptions" :key="key">
        <o-dropdown-item :clickable="false" :disabled="true">{{
          key
        }}</o-dropdown-item>
        <o-dropdown-item
          v-for="[id, itemName] in gOptions"
          :key="id"
          :data-cy="`${kebabCase(getItemName(itemName))}-option`"
          :value="id"
          class="w-full px-0"
        >
          <span class="flex gap-1">
            &nbsp;&nbsp;
            <TIcon
              v-if="itemName?.icon"
              :icon="itemName.icon"
              :color="itemName.fillType"
              size="sm"
            />
            <slot name="item" :item="itemName" :text="getItemName(itemName)">
              {{ getItemName(itemName) }}
            </slot>
          </span>
        </o-dropdown-item>
      </div>
    </template>
    <template v-else-if="options">
      <o-dropdown-item
        v-for="[id, itemName] in sanitizedOptions"
        :key="id"
        :data-cy="`${kebabCase(getItemName(itemName))}-option`"
        :value="id"
        class="w-full px-2"
      >
        <span class="flex gap-1">
          <TIcon
            v-if="itemName?.icon"
            :icon="itemName.icon"
            :color="itemName.fillType"
            size="sm"
          />
          <slot name="item" :item="itemName" :text="getItemName(itemName)">
            {{ getItemName(itemName) }}
          </slot>
        </span>
      </o-dropdown-item>
    </template>
    <!--- Any provided HTML goes in slot; i.e. more o-dropdown-item elements -->
    <slot />
  </o-dropdown>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import {
  DropdownSize,
  LegacyDropdownK,
} from '@thyme/nashville/src/types/dropdowns'
import { SelectOptions } from '@thyme/nashville/src/types/select'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import reduce from 'lodash/reduce'
import { computed, defineComponent, PropType } from 'vue'
import { toLegacyDropdownOptions } from '@/legacy/libs/dropdowns'
import { truncate } from '@/legacy/libs/format'
import TTruncate from './text/TTruncate.vue'

// CONST
const MAX_STRING_LENGTH = 7

export default defineComponent({
  components: { TIcon, TTruncate },
  props: {
    truncateLength: {
      type: Number,
      default: null,
    },
    parentSelector: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    iconFillType: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    iconSize: {
      type: String,
      default: DropdownSize.sm,
    },
    label: { type: String, default: '' },
    modelValue: {
      type: [Array, String] as PropType<string[] | string>,
      default: () => [],
    },
    multiple: { type: Boolean, default: false },
    position: { type: String, default: 'bottom' },
    options: {
      type: Array as PropType<SelectOptions>,
      default: null,
    },
    groupedOptions: {
      type: Object as PropType<{
        [key: string]: (string | string[][] | { [key: string]: string })[]
      }>,
      default: null,
    },
    expanded: { type: Boolean, default: true },
    showElementsSelected: { type: Boolean, default: false },
    name: { type: String, default: '' },
    size: { type: String, default: DropdownSize.sm },
    rounded: { type: Boolean, default: true },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const sanitizedOptions = computed(() =>
      toLegacyDropdownOptions(props.options)
    )

    const selectedOptionText = computed<string>(() => {
      let filterFn
      if (props.multiple) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filterFn = ([id, display]: string[]) =>
          props.modelValue.indexOf(id) >= 0
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filterFn = ([id, display]: string[]) => id === props.modelValue
      }

      const foundOptions = filter(
        props.groupedOptions
          ? reduce(
              props.groupedOptions,
              (res, opts) => [...res, ...opts],
              [] as (string | object)[]
            )
          : sanitizedOptions.value,
        filterFn
      )

      if (foundOptions.length) {
        const itemName = foundOptions
          // @ts-ignore  typescript really hates the type coersion here
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(([id, display]: [string, string | { [key: string]: string }]) =>
            getItemName(display as string | { [key: string]: string })
          )
          .join(', ')

        return itemName
      }

      return truncate(props.modelValue as string, MAX_STRING_LENGTH)
    })

    const numSelected = computed(() => {
      return props.modelValue.length
    })

    const onChange = (e: string[]) => {
      context.emit('update:modelValue', e)
    }

    const getItemName = (
      item: string | { [key: string]: string } | LegacyDropdownK
    ) => {
      return typeof item === 'string' ? item : item?.text
    }

    const triggerClasses = computed(() => [
      ...(props.modelValue
        ? ['text-nash-neutral700']
        : ['text-nash-neutral600']),
      ...(props.size === DropdownSize.md ? ['py-2 px-4'] : []),
      ...(props.rounded ? ['rounded-lg'] : []),
    ])

    return {
      numSelected,
      sanitizedOptions,
      selectedOptionText,
      triggerClasses,
      getItemName,
      onChange,
      kebabCase,
    }
  },
})
</script>
