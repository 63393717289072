import { IdMap } from '@/legacy/types/api/store'
import { Timestamps } from '@/legacy/types/global/dates'
import { UUID } from '../global/base'

// TYPES --------------------
export type UpsertDiagnosisRequest = Pick<
  Diagnosis,
  (typeof updateDiagnosisFields)[number]
>
export type UpsertDiagnosisResponse = Diagnosis

export type DiagnosisCatalog = IdMap<DiagnosisReference>

export type Diagnosis = {
  diagnosisId: string
  patientId: string
  diagnosisRefId: string
  diagnosisDate: string
  stage: string
  isPrimary: boolean
  source: string
  isInaccurate: boolean
} & Timestamps

export type DiagnosisUI = {
  codeType: DxCodeType
  description: string
} & Diagnosis

export type DiagnosisReference = {
  diagnosisRefId: string
  codeType: DxCodeType
  code: string
  description: string
} & Timestamps

export type DiagnosisFormData = {
  diagnosisRefId: string
  stage: string | undefined
  diagnosisDate: Date | undefined
  isPrimary: string | undefined
}

export type GetDiagnosisRefsParams = {
  filter_diagnosis_ref_ids?: UUID[] | null
  filter_code_types?: string[] | null
  page_length?: number | null
  page_number?: number | null
  sort_by?: string | string[] | null
}

export interface DiagnosisRefsState {
  isLoading: boolean
  error: Error | null
  data: IdMap<DiagnosisReference> | null
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}
// ENUMS --------------------
export enum DxCodeType {
  THYMECARE_CATEGORY = 'thymecare_category',
  ICD_10 = 'icd10',
}

export enum Stage {
  stage_0 = '0',
  stage_i = 'I',
  stage_ii = 'II',
  stage_iii = 'III',
  stage_iv = 'IV',
}

export enum DxType {
  PRIMARY = 'true',
  SECONDARY = 'false',
}

// CONSTANTS --------------------
export const updateDiagnosisFields = [
  'diagnosisRefId',
  'isPrimary',
  'stage',
  'source',
  'diagnosisDate',
  'diagnosisId',
] as const
