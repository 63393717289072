<template>
  <div v-if="!radio" :class="expanded ? 'w-full' : 'w-56'">
    <label :for="name">
      <div v-if="label" class="font-bold mt-2">{{ label }}</div>
      <div v-if="label && help" class="">{{ help }}</div>
    </label>

    <div
      :class="[
        'relative',
        'bg-nash-neutral000',
        'border-transparent',
        'rounded-xl',
        { 'max-w-max': !expanded },
      ]"
    >
      <select
        :data-cy="`${kebabCase(name)}-select`"
        :name="name"
        :value="inputValue"
        :class="[
          'rounded-xl',
          'relative',
          'z-10',
          'bg-transparent',
          'appearance-none',
          'border',
          color === SelectStyle.Gray
            ? 'border-gray-200'
            : 'border-nash-neutral900',
          { rounded: color === SelectStyle.Gray },
          'p-2',
          'pr-5',
          {
            'w-full': expanded,
            'border-red-500': meta.touched && !meta.valid,
            'cursor-not-allowed': disabled,
          },
        ]"
        :disabled="disabled"
        @change="onChange"
        @blur="
          (e) => {
            handleBlur(e)
            $emit('blur', e)
          }
        "
      >
        <option v-for="[k, v] in sanitizedOptions" :key="k" :value="k">
          {{ v }}
        </option>
      </select>
      <!--
        To override the browser default styling, we set the select appearance
        to none and apply our own styles/icon.
      -->
      <TIcon
        icon="chevronDown"
        class="absolute inset-y-0 right-0 !h-full mr-2 cursor-pointer"
      />
    </div>
    <ErrorMessage :name="name" data-cy="warning" class="text-red-500 text-sm" />
  </div>

  <div v-else>
    <div v-if="label" class="font-bold">{{ label }}</div>
    <div v-if="label && help">{{ help }}</div>

    <div
      :class="[
        expanded ? 'flex flex-col space-y-2' : 'flex space-x-2 justify-center',
      ]"
    >
      <label
        v-for="[k, v] in sanitizedOptions"
        :key="k"
        :class="[
          'rounded-xl',
          'inline-flex items-center space-x-2 w-full',
          'bg-nash-neutral000',
          tileClasses(v === inputValue, meta.touched && !meta.valid),
        ]"
      >
        <input
          :name="name"
          :value="v"
          :checked="v === inputValue"
          type="radio"
          :disabled="disabled"
          :data-cy="`${kebabCase(name)}-${kebabCase(k)}-radio`"
          @input="onChange"
          @blur="
            (e) => {
              handleBlur(e)
              $emit('blur', e)
            }
          "
        />

        <span>{{ k }}</span>
      </label>
      <slot />
    </div>
    <ErrorMessage :name="name" data-cy="warning" class="text-red-500 text-sm" />
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { SelectOptions, SelectStyle } from '@thyme/nashville/src/types/select'
import kebabCase from 'lodash/kebabCase'
import { ErrorMessage, useField } from 'vee-validate'
import { computed, defineComponent, PropType, watch } from 'vue'
import { toLegacyDropdownOptions } from '@/legacy/libs/dropdowns'

export default defineComponent({
  components: {
    TIcon,
    ErrorMessage,
  },
  props: {
    name: { type: String, required: true },
    standalone: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: '' },
    help: { type: String, default: '' },
    expanded: { type: Boolean, default: true },
    options: { type: Array as PropType<SelectOptions>, required: true },
    radio: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    rules: { type: String, default: '' },
    color: {
      type: String,
      default: SelectStyle.Gray,
    },
    unmask: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'blur'],
  setup(props, context) {
    const sanitizedOptions = computed(() =>
      toLegacyDropdownOptions(props.options)
    )

    const {
      value: inputValue,
      handleChange,
      handleBlur,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.modelValue,
      standalone: props.standalone,
    })

    const onChange = (e: any) => {
      handleChange(e)
      context.emit('update:modelValue', e.target.value)
    }

    // additional label classes to add when displaying as a tile
    const tileClasses = (isSelected: boolean, hasErrors: boolean) => {
      return {
        // always apply these styles
        'p-2 rounded border': true,
        // border color styles conflict, so choose only one
        'border-nash-neutral500': !hasErrors,
        'border-red-500': hasErrors,
        // apply these styles only when checked
        'bg-nash-midnightBlue700 border-nash-midnightBlue700': isSelected,
      }
    }
    /*
    This watch function ensures updates happen outside component.
    Without this, if updating a TSelect that is sharing values with
    another TSelect in a different parent component, it'll only
    update for the current TSelect in current parent component
    */
    watch(
      () => props.modelValue,
      (modelValue) => handleChange(modelValue)
    )

    return {
      sanitizedOptions,
      onChange,
      inputValue,
      handleBlur,
      meta,
      kebabCase,
      SelectStyle,
      tileClasses,
    }
  },
})
</script>
