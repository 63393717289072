<template>
  <div class="flex gap-2 items-center">
    <InputSwitch
      v-model="_checked"
      :data-cy="`${name ?? 'generic-input'}-toggle`"
      :aria-label="label ?? 'toggle'"
      :disabled="disabled"
      v-bind="$attrs"
      @update:model-value="$emit('update:checked', $event)"
    />
    <label
      v-if="label"
      :class="`p-inputswitch-label ${
        disabled ? 'p-inputswitch-label-disabled' : ''
      }`"
      >{{ label }}</label
    >
  </div>
</template>

<script lang="ts">
import InputSwitch from 'primevue/inputswitch'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: { InputSwitch },
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:checked'],
  setup(props) {
    const _checked = ref(props.checked)
    watch(
      () => props.checked,
      () => (_checked.value = props.checked)
    )
    return {
      _checked,
    }
  },
})
</script>
