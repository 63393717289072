import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------
export type Person = {
  entityId: string
  firstName: string
  lastName: string
  middleInitial?: string
  preferredName?: string
  suffix?: string
  gender?: Gender
  dateOfBirth?: string
  preferredLanguage?: string
} & Timestamps

// ENUMS --------------------
export enum Language {
  english = 'English',
  spanish = 'Spanish',
  // Above order is important!
  // Make sure 'English' option #1 and 'Spanish' is option #2
  arabic = 'Arabic',
  bengali = 'Bengali',
  creole_french = 'Creole (French)',
  creole_haitian = 'Creole (Haitian)',
  french = 'French',
  italian = 'Italian',
  korean = 'Korean',
  mandarin = 'Mandarin',
  polish = 'Polish',
  portuguese = 'Portuguese',
  russian = 'Russian',
  serbian = 'Serbian',
  tagalog = 'Tagalog',
  vietnamese = 'Vietnamese',
  other = 'Other',
}

export enum Gender {
  Male = 'M',
  Female = 'F',
  NonBinary = 'NB',
  PreferNotToAnswer = 'NA',
  Unknown = 'U',
  Other = 'O',
}
