<template>
  <div>
    <div v-if="!dtmfMode" class="mb-4">
      <div
        v-if="number"
        class="px-2 font-semibold flex justify-between items-center"
      >
        {{ number }}
        <TIcon
          icon="backspace"
          class="cursor-pointer select-none"
          @click="number = number.slice(0, -1)"
        />
      </div>
      <div v-else class="text-gray-500 text-center select-none">
        Click or type number
      </div>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <div
        v-for="{ digit: i, ...d } in digits"
        :key="`button-${i}`"
        class="digit bg-nash-neutral300 cursor-pointer"
        :style="{
          'background-color': selectedDigit === i ? '#c7c9c9' : undefined,
        }"
        @click="addDigit(i)"
      >
        <div :class="d.secondary ? 'text-gray-500' : 'font-semibold'">
          {{ i }}
        </div>
        <div v-if="d.help" class="text-gray-500 text-xs">{{ d.help }}</div>
      </div>

      <slot name="left"></slot>

      <div
        v-if="!dtmfMode"
        class="digit bg-nash-purple500 hover:bg-nash-purple500 text-nash-neutral000 col-start-2"
        :class="isValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'"
        @click="dial()"
      >
        <TIcon icon="phone" />
      </div>

      <slot name="right"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import {
  computed,
  defineComponent,
  ref,
  watch,
  watchEffect,
  onBeforeUnmount,
} from 'vue'

import { debounce } from '@/legacy/libs/debounce'
import { thymeListen, thymeTuneOut } from '@/legacy/libs/eventBus'
import { formatPhone } from '@/legacy/libs/format'

import { digits } from '@/legacy/types/entities/phoneNumbers'

// CONST
const PHONE_NUMBER_LENGTH = 10
const DEBOUNCE_INTERVAL_MS = 150

/*
 * Dialpad component.
 *
 * Works in one of two modes:
 *   - dial mode: allows entry of a full 10 digit phone number
 *     in this mode, the component emits the 'dial' event
 *
 *   - DTMF mode: allows entry of individual digits to emit DTMF tones
 *     in this mode, the component emits the 'digit' event
 *
 * Props:
 *   - dtmfMode: whether or not to enable DTMF mode
 *
 *   - keyboardListener: toggles the ability of this dialpad to
 *       respond to keyboard events via the `handleKey` method
 */
export default defineComponent({
  components: {
    TIcon,
  },
  props: {
    dtmfMode: { type: Boolean, default: false },
    keyboardListener: { type: Boolean, default: true },
  },
  emits: ['digit', 'dial'],
  setup(props, { emit }) {
    // the dirty number
    const number = ref('')
    // highlights the value being clicked/typed
    const selectedDigit = ref<string | null>(null)

    const isValid = computed(() => {
      return number.value.replace(/\D/g, '').length === PHONE_NUMBER_LENGTH
    })

    const debouncedResetSelected = debounce(() => {
      selectedDigit.value = null
    }, DEBOUNCE_INTERVAL_MS)

    const debouncedFormatPhone = debounce(() => {
      number.value = formatPhone(number.value)
    })
    watch(number, debouncedFormatPhone)

    /**
     *
     */
    function dial() {
      if (!isValid.value) {
        return
      }

      emit('dial', number.value)
      number.value = ''
    }

    /**
     *
     * @param d
     */
    function addDigit(d: string) {
      if (props.dtmfMode) {
        // in DTMF mode, don't add digits, just emit them
        emit('digit', d)
      } else if (isValid.value) {
        // if the number is already complete, don't add more digits
        return
      } else {
        number.value += d
      }

      selectedDigit.value = d
      debouncedResetSelected()
    }

    /**
     *
     * @param event
     */
    function handleKey(event: KeyboardEvent) {
      if (event.key.match(/\d/)) {
        addDigit(event.key)
        return
      }

      switch (event.key) {
        case 'Backspace':
          number.value = number.value.slice(0, -1)
          break
        case 'Enter':
          dial()
          break
      }
    }

    /**
     *
     * @param enable
     */
    function toggleKbdListener(enable = true) {
      if (enable) {
        console.debug(
          `Registering ${
            props.dtmfMode ? 'DTMF' : 'dial'
          } mode keyboard listener`
        )
        thymeListen('keydown', handleKey)
      } else {
        console.debug(
          `Deregistering ${
            props.dtmfMode ? 'DTMF' : 'dial'
          } mode keyboard listener`
        )
        thymeTuneOut('keydown', handleKey)
      }
    }

    // runs immediately with the initial status of the keyboard listener, then
    // again whenever the prop changes, and a final time on unmount to clean up
    watchEffect(() => toggleKbdListener(props.keyboardListener))
    onBeforeUnmount(() => toggleKbdListener(false))

    return {
      number,
      digits,
      selectedDigit,
      isValid,
      addDigit,
      dial,
    }
  },
})
</script>

<style scoped>
.digit {
  @apply p-1 rounded-full flex flex-col justify-center items-center h-12 w-12;
}
</style>
