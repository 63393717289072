export default {
  options: {
    type: Array,
    default: null,
    tooltip: 'Items to populate dropdown, valid JSON Array Required',
  },
  optionLabel: {
    type: String,
    default: null,
    tooltip: 'REQUIRED FOR CASCADE, what to label each option',
  },
  optionGroupLabel: {
    type: String,
    default: null,
    tooltip: 'REQUIRED FOR CASCADE, what to label each option group',
  },
  optionGroupChildren: {
    type: Array,
    default: null,
    tooltip: 'REQUIRED FOR CASCADE, what key(s) to cascade on',
  },
}
