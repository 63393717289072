<template>
  <div
    class="relative conversation-container space-y-1 cursor-pointer"
    :class="active ? 'added' : ''"
    @click="click"
  >
    <div class="flex flex-row">
      <div v-if="hasOpenPointerSubtask" class="unread-indicator"></div>
      <div>
        <div v-if="isUnknownTextsPage">
          {{ formatPhone(textableIndividual.phoneNumber.phoneNumber) }}
        </div>
        <div
          v-else
          v-tooltip.right="
            formatPhone(textableIndividual.phoneNumber.phoneNumber)
          "
          class="font-bold name-container"
        >
          {{ formatNameFromPerson(textableIndividual.person) }}
          {{
            textableIndividual.relationshipToPatient
              ? `(${displayRelationship(
                  textableIndividual.relationshipToPatient
                )})`
              : ''
          }}
        </div>
        <div
          v-if="textableIndividual.twilioConversationIds"
          class="last-message-container"
        >
          {{ truncate(textableIndividual.lastTextMessage, { length: 120 }) }}
        </div>
        <div
          v-if="!textableIndividual.twilioConversationIds"
          class="text-nash-neutral700"
        >
          Start a Conversation
        </div>
        <div
          v-if="textableIndividual.updatedAt"
          class="last-update-date-container text-sm text-nash-neutral700"
        >
          {{ formatDatetime(textableIndividual.lastUpdateDate ?? '', '') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { MapEntityPhoneNumberPersonSms } from '@/legacy/types/entities/phoneNumbers'
import setup from './controller'

export default defineComponent({
  components: {},
  props: {
    textableIndividual: {
      type: Object as PropType<MapEntityPhoneNumberPersonSms>,
      required: true,
    },
  },
  emits: ['select'],
  setup,
})
</script>
<style lang="scss">
.conversation-container {
  @apply py-2 px-6 rounded-lg;
  &.added {
    @apply bg-nash-neutral100 border border-nash-neutral400;
  }
}

.unread-indicator {
  @apply rounded-full absolute bg-nash-fern600;
  left: 8px;
  margin-top: 7px;
  width: 6px;
  height: 6px;
}
</style>
