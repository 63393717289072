import { enumValueToKey } from '@/legacy/libs/enum'
import { ApiStatePartial } from '@/legacy/types/api/apiBuilder'

import { IdMap } from '@/legacy/types/api/store'
import {
  CallDirection,
  CallDisposition,
  callDispositionDisplayLookup,
} from '@/legacy/types/communications/callDispositions'
import { PlannedCall } from '@/legacy/types/communications/plannedCalls'
import { PlannedText } from '@/legacy/types/communications/plannedTexts'
import { SMSThread, Turn } from '@/legacy/types/communications/texting'
import { Person } from '@/legacy/types/entities/people'
import { Timestamps } from '@/legacy/types/global/dates'
import { Patient } from '@/legacy/types/patients/patients'
import { EntityRole } from '../entities/entities'
import { PartiallyRequired } from '../global/base'

// TYPES --------------------
export type CommunicationSubtaskMap = {
  communicationId: string
  subtaskId: string
}

export type CommunicationRelationships = {
  callDisposition: CallDisposition | null
  patients?: Patient[]
  plannedCall: PlannedCall | null
  plannedText: PlannedText | null
  smsThread: SMSThread | null
  subtaskMaps: [{ subtaskId: string } & Timestamps]
}

export type CommunicationFields = {
  communicationId: string
  completedDatetime: string | null
  notes: string
  patientIds: string[]
  patient?: Patient | null
  responsibleRole: EntityRole | null
  responsibleStaffId: string | null
  type: CommunicationType
}

export type Communication = CommunicationFields &
  CommunicationRelationships &
  Timestamps

export type TextCommunication = Communication & {
  campaignId?: string
  smsThread: SMSThread
  turn: Turn
}

export type BaseCommunicationPayload = PartiallyRequired<
  CommunicationFields,
  'type' | 'patientIds' | 'notes' | 'completedDatetime'
>

export type CalleeOptions = any[]

export type CommunicationsState = {
  communications: Communication[] | null
  communicationPersons: IdMap<Person> | null
  isCreating: boolean
  communicationType: CommunicationDisplayType | null
} & ApiStatePartial

export type CommunicationsApiOptions = {
  filter_communication_ids?: string[]
  filter_free_text?: string
  filter_staff_ids?: string[]
  filter_due_before?: string
  filter_due_after?: string
  filter_phone_number_ids?: string[]
  filter_types?: string[]
}

export type CreateSmsThreadExtraArgs = {
  phoneNumberId: string
  turn?: Turn
}

export type CreateSmsCommunicationPayload = BaseCommunicationPayload & {
  smsArgs: CreateSmsThreadExtraArgs
}

// INTERFACES --------------------

// ENUMS --------------------
export enum CommSidebarActionEnums {
  plannedCallee = 'planned call with dropdown',
  scheduledCallee = 'scheduled call with dropdown',
  callee = 'callee dropdown',
  direction = 'call direction dropdown',
  completedDate = 'completed on date dropdown',
  callResult = 'call result dropdown',
  completedCallWith = 'speaking with dropdown',
  smsStatus = 'sms status',
  notes = 'notes',
  smsTurn = 'sms turn',
}

export enum CommunicationParts {
  planned = 'planned_call',
  planned_text = 'planned_text',
  patients = 'patients',
  disposition = 'call_disposition',
  smsThread = 'sms_thread',
  subtaskMaps = 'subtask_maps',
}

export enum StoreTypeForReminders {
  staff = 'staff',
  member = 'member',
}

export enum ModalType {
  REATTEMPT = 'reattempt',
  RESCHEDULE = 'reschedule',
}

// CONSTANTS --------------------
export const CALL_PARTS = [
  CommunicationParts.planned,
  CommunicationParts.disposition,
]
export const ALL_COMMUNICATION_PARTS = Object.values(CommunicationParts)
export const DEFAULT_NOTES =
  'Summary:\n\n' + 'Discussion:\n\n' + 'Next steps:\n'

// COMMUNICATION CLEANUP --------------

export enum CommunicationType {
  Call = 'CALL',
  Text = 'SMS',
  Email = 'EMAIL',
  Other = 'OTHER',
  Fax = 'FAX',
}

export enum CommunicationSubtype {
  Disposition = 'DISPOSITION',
  Planned = 'PLANNED',
}

export type CommunicationDisplayType = {
  label: string
  type: CommunicationType
  subtype?: CommunicationSubtype
  disabled?: boolean
  payload?: { [key: string]: any }
}

export const SCHEDULED_CALL_TYPE: CommunicationDisplayType = {
  label: 'Scheduled Call',
  type: CommunicationType.Call,
  subtype: CommunicationSubtype.Planned,
}

export const OUTBOUND_CALL_TYPE_PRE: CommunicationDisplayType = {
  label: 'Outbound Call',
  type: CommunicationType.Call,
  subtype: undefined,
}

// This outbound communication type should not be available in
// as that list is used mainly for communications menu.
export const OUTBOUND_CALL_TYPE_POST: CommunicationDisplayType = {
  label: 'Outbound Call',
  type: CommunicationType.Call,
  subtype: CommunicationSubtype.Disposition,
  payload: {
    direction: CallDirection.outbound,
    disposition: enumValueToKey(
      callDispositionDisplayLookup,
      callDispositionDisplayLookup.ANSWERED
    ),
  },
}

export const communicationTypeList: CommunicationDisplayType[] = [
  {
    label: 'Inbound Call',
    type: CommunicationType.Call,
    subtype: CommunicationSubtype.Disposition,
    payload: {
      direction: CallDirection.inbound,
      disposition: enumValueToKey(
        callDispositionDisplayLookup,
        callDispositionDisplayLookup.ANSWERED
      ),
    },
  },
  OUTBOUND_CALL_TYPE_PRE,
  SCHEDULED_CALL_TYPE,
  {
    label: 'Email',
    type: CommunicationType.Email,
  },
  {
    label: 'Fax',
    type: CommunicationType.Fax,
  },
  {
    label: 'Other',
    type: CommunicationType.Other,
  },
  {
    label: 'Mailer',
    type: CommunicationType.Other,
    disabled: true,
  },
]

// Empty strings seem to throw off expected behavior in Dropdowns,
// so we use a space character as a work around
export const STRING_WITH_SPACE = ' '
