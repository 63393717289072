<template>
  <div
    v-if="subtask.priority && subtask.status"
    data-cy="subtask-row"
    class="flex justify-between gap-2 text-sm"
  >
    <div class="flex gap-1">
      <TMActionDropdown
        v-if="showStatusAsIs"
        :model-value="''"
        class="shrink-1 h-fit remove-border"
        :icon="currentIcon"
        icon-size="md"
        :icon-fill-type="currentIconFill"
        name="subtask-status"
        option-label="label"
        option-value="val"
        :options="
          subtaskStatusOptions(
            subtask.status as SubtaskStatus,
            canOverridePathwaysBlockedStatus
          )"
        :disabled="
          isClosedInError ||
          (disableStatusDropdown && !canOverridePathwaysBlockedStatus)
        "
        :show-clear="false"
        :expanded="false"
        @change="setStatus($event.value)"
      />
      <div
        v-else-if="showBlockedStatus"
        v-tooltip.right="
          renderBlockedMessage(subtask.statusReason, subtask.minStartDatetime)
        "
      >
        <TMActionDropdown
          :model-value="''"
          class="shrink-1 h-fit remove-border"
          :icon="currentIcon"
          icon-size="md"
          :icon-fill-type="currentIconFill"
          name="subtask-status"
          option-label="label"
          option-value="val"
          :options="
            subtaskStatusOptions(
              subtask.status as SubtaskStatus,
              canOverridePathwaysBlockedStatus
            )"
          :disabled="isClosedInError"
          :show-clear="false"
          :expanded="false"
          @change="setStatus($event.value)"
        />
      </div>
      <div class="flex flex-col gap-x-3">
        <div
          v-if="!showTrimmedVersion"
          class="cursor-pointer"
          @click="goToSubtask(subtask.subtaskId)"
        >
          <b class="text-base">{{
            subtask.customSubject ? `${subtask.title}: ` : subtask.title
          }}</b>
          <p v-if="subtask.customSubject" class="inline">
            {{ subtask.customSubject }}
          </p>
        </div>

        <div class="flex flex-wrap items-center">
          <span
            v-if="subtask.queueStyle !== QueueStyle.ENROLLMENT"
            class="flex items-center shrink-1"
          >
            <span
              :class="{
                overdue: isOverdue,
                'text-nash-neutral700': !isOverdue,
              }"
            >
              {{ renderDatePretext(isOverdue, subtask.status) }}:
              {{ renderDateString(subtask) }}
            </span>
            <span
              v-if="subtask.hideDatetime"
              v-tooltip.bottom="
                renderReattemptTooltip(
                  subtask.subtaskId,
                  subtask.hideDatetime,
                  reattemptData
                )
              "
            >
              <TIcon
                icon="reattempt"
                :color="
                  SubtaskStatuses[SubtaskStatus.REATTEMPT].fillType ?? undefined
                "
                class="ml-1"
              />
            </span>
          </span>
          <div
            v-if="
              subtask.queueStyle !== QueueStyle.ENROLLMENT &&
              !hideSubtaskEstimatedCompletionTime
            "
            class="bullet mx-2"
          ></div>
          <div
            v-if="!hideSubtaskEstimatedCompletionTime"
            class="flex shrink-1 text-nash-neutral700 items-center"
          >
            {{
              subtask.estimatedMinutesToComplete
                ? `Est: ${subtask.estimatedMinutesToComplete} min`
                : 'Est: N/A'
            }}
          </div>
          <div
            v-if="
              displayType !== SubtaskRowDisplayType.MY_QUEUE &&
              !hideSubtaskEstimatedCompletionTime
            "
            class="bullet ml-2"
          ></div>
          <TOStaffAssignDropdown
            v-if="showResponsibleStaffSelect"
            name="subtask-responsible-staff"
            class="thymeline-status-dropdown remove-border h-6"
            :model-value="currentStaff"
            :disabled="notAssignable"
            :show-specialization-roles="true"
            :can-unassign="true"
            :pt="{
              trigger: { class: 'hidden' },
            }"
            @change="updateRoleOrStaff($event.value)"
          />
          <div
            v-if="
              displayType !== SubtaskRowDisplayType.MY_QUEUE &&
              displayType !== SubtaskRowDisplayType.THYMELINE &&
              displayType !== SubtaskRowDisplayType.TASK_ACCORDION &&
              displayType !== SubtaskRowDisplayType.STATUS_UPDATE_MODAL &&
              subtask.queueStyle !== QueueStyle.ENROLLMENT &&
              !hideSubtaskEstimatedCompletionTime
            "
            class="bullet"
          ></div>
          <TMActionDropdown
            v-if="showTrimmedVersion"
            :initial-value="getCurrentSubtaskStatus(currentStatus as SubtaskStatus)"
            class="shrink-1 h-fit remove-border"
            :icon="currentIcon"
            icon-size="md"
            :icon-fill-type="currentIconFill"
            name="subtask-status"
            option-label="label"
            option-value="val"
            :options="
            subtaskStatusOptions(
              subtask.status as SubtaskStatus,
              canOverridePathwaysBlockedStatus
            )"
            :show-clear="false"
            :expanded="false"
            @change="setStatus($event.value)"
          />
          <div
            v-if="
              displayType !== SubtaskRowDisplayType.MY_QUEUE &&
              displayType !== SubtaskRowDisplayType.THYMELINE &&
              displayType !== SubtaskRowDisplayType.TASK_ACCORDION &&
              displayType !== SubtaskRowDisplayType.STATUS_UPDATE_MODAL &&
              subtask.queueStyle !== QueueStyle.ENROLLMENT &&
              !hideSubtaskEstimatedCompletionTime
            "
            class="bullet"
          ></div>
          <div
            v-if="
              showTrimmedVersion && subtask.queueStyle !== QueueStyle.ENROLLMENT
            "
            class="flex shrink-1 text-nash-neutral700 items-center"
          >
            <div
              v-if="
                subtaskTaskMapTemplateId &&
                pathwayTaskMapTemplateId &&
                !canEditPathwaysPriority
              "
              class="flex items-center"
            >
              <TIcon
                :icon="getPriorityIcon(subtask.priority)"
                color="neutral500"
                class="mr-1"
              />
              {{ capitalize((subtask.priority ?? '').toLowerCase()) }}
            </div>
            <TMActionDropdown
              v-else
              class="bg-nash-neutral000 remove-border"
              :icon="currentPriorityIcon"
              name="subtask-priority"
              :initial-value="selectedPriorityObj"
              :options="subtaskPriorityOptionsV2"
              option-label="label"
              option-value="val"
              :show-clear="false"
              @change="setPriority($event.value)"
            />
          </div>
        </div>
        <div v-if="displayType !== SubtaskRowDisplayType.SUBTASK_DETAIL">
          <div v-if="subtask.notes && showCoolDownFeature" class="t-ellipsis">
            {{ subtask.notes }}
          </div>
          <TTruncate
            v-if="subtask.notes && !showCoolDownFeature"
            :label="subtask.notes"
            :max-chars="200"
          />
        </div>
      </div>
    </div>
    <div class="flex">
      <!-- IMPROVEME(MT-970): refactor below into new PV Chip when ready -->
      <div
        v-if="
          displayType === SubtaskRowDisplayType.THYMELINE ||
          displayType === SubtaskRowDisplayType.MY_QUEUE ||
          displayType === SubtaskRowDisplayType.TASK_ACCORDION ||
          displayType === SubtaskRowDisplayType.STATUS_UPDATE_MODAL
        "
        v-tooltip.bottom="capitalize((subtask.priority ?? '').toLowerCase())"
      >
        <span>
          <TIcon :icon="getPriorityIcon(subtask.priority)" color="neutral500" />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import capitalize from 'lodash/capitalize'
import { storeToRefs } from 'pinia'
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  watch,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { thymeListen } from '@/legacy/libs/eventBus'
import TMActionDropdown from '@/legacy/nashville/dropdown/TMActionDropdown.vue'
import TOStaffAssignDropdown from '@/legacy/nashville/dropdown/TOStaffAssignDropdown.vue'
import TTruncate from '@/legacy/nashville/text/TTruncate.vue'

import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePathwayApi } from '@/legacy/store/modules/pathways'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  renderReattemptTooltip,
  useReattemptStore,
} from '@/legacy/store/modules/reattempts'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { EntityRole, SYSTEM_USER_UUID } from '@/legacy/types/entities/entities'
import { UUID } from '@/legacy/types/global/base'
import { StatusReason, SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import { Reattempt } from '@/legacy/types/pathways/reattempts'
import {
  SubtaskStatus,
  SubtaskPayload,
  Subtask,
  SubtaskRowDisplayType,
  SubtaskPriority,
  QueueStyle,
  statusesWithSubStatusReason,
} from '@/legacy/types/pathways/subtasks'
import { Task } from '@/legacy/types/pathways/tasks'
import { setRoleOrStaff } from './lib/sharedSubtaskParts'
import {
  getAvailableStatusOptions,
  renderDatePretext,
  renderDateString,
  getPriorityIcon,
  subtaskPriorityOptions,
  getSubtaskIconFill,
  getSubtaskIconName,
  subtaskPriorityOptionsV2,
} from './lib/subtask'
import { renderBlockedMessage } from './lib/task'

export default defineComponent({
  components: {
    TTruncate,
    TIcon,
    TMActionDropdown,
    TOStaffAssignDropdown,
  },
  props: {
    disableStatusDropdown: {
      type: Boolean,
      default: false,
    },
    subtaskTask: {
      type: Object as PropType<Task>,
      default: null,
    },
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
    isOverdue: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String,
      required: true,
      validator: (v: string) =>
        Object.values<string>(SubtaskRowDisplayType).includes(v),
      // @ts-ignore options custom attr
      options: SubtaskRowDisplayType,
    },
  },
  emits: [
    'openReattemptModal',
    'setStatus',
    'callbackFn',
    'openSubStatusModal',
  ],
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()

    const reattemptData = ref<Reattempt[] | null>(null)

    const {
      canOverridePathwaysBlockedStatus,
      canEditPathwaysPriority,
      selfEntity,
    } = storeToRefs(useProfileStore())
    const { data: pathways } = storeToRefs(usePathwayApi())
    const {
      showCoolDownFeature,
      showSurfacedStatusReason,
      hideSubtaskEstimatedCompletionTime,
    } = storeToRefs(useFlagStore())

    const showResponsibleStaffSelect = computed(
      () => props.displayType !== SubtaskRowDisplayType.MY_QUEUE
    )
    const showStatusSelect = computed(
      () => props.displayType !== SubtaskRowDisplayType.STATUS_UPDATE_MODAL
    )
    const isTaskAccordionView = computed(
      () => props.displayType === SubtaskRowDisplayType.TASK_ACCORDION
    )
    const isSubtaskDetailView = computed(
      () =>
        props.displayType === SubtaskRowDisplayType.SUBTASK_DETAIL ||
        props.displayType === SubtaskRowDisplayType.SIDEBAR_QUEUE_STACK
    )
    const showTrimmedVersion = computed(
      () =>
        props.displayType === SubtaskRowDisplayType.SUBTASK_DETAIL ||
        props.displayType === SubtaskRowDisplayType.SIDEBAR_QUEUE_STACK
    )

    const subtaskTaskMapTemplateId = computed(() => {
      if (props.subtaskTask && props.subtaskTask.subtaskMaps) {
        const foundMapping = props.subtaskTask.subtaskMaps.find(
          (subtaskTaskMaps) =>
            subtaskTaskMaps.subtaskId === props.subtask.subtaskId
        )
        return foundMapping?.mappingTemplateId
      }
      return null
    })

    const pathwayTaskMapTemplateId = computed(() => {
      const allPathways = Object.values(pathways.value ?? {})
      if (props.subtaskTask && allPathways.length) {
        const parentPathway = allPathways.find((pathway) =>
          pathway.taskIds?.includes(props.subtaskTask.taskId)
        )
        if (parentPathway) {
          const foundMapping = parentPathway.taskMaps?.find(
            (pathwayTaskMap) =>
              pathwayTaskMap.taskId === props.subtaskTask.taskId
          )
          return foundMapping?.mappingTemplateId
        }
      }
      return null
    })

    const currentStaff = computed(() => {
      if (props.subtask.responsibleStaffId) {
        return props.subtask.responsibleStaffId
      }
      if (props.subtask.responsibleRole) {
        return props.subtask.responsibleRole === EntityRole.SYSTEM
          ? SYSTEM_USER_UUID
          : props.subtask.responsibleRole
      }
      return 'NULL'
    })
    const currentStatus = computed(() => props.subtask.status)
    const currentPriority = computed(() => props.subtask.priority)
    const currentPriorityIcon = computed(() =>
      getPriorityIcon(currentPriority.value)
    )

    const selectedPriorityObj = computed(() => ({
      val: currentPriority.value,
      label:
        SubtaskPriority[
          currentPriority.value.toUpperCase() as keyof typeof SubtaskPriority
        ],
      icon: getPriorityIcon(currentPriority.value),
    }))

    const isClosedInError = computed(
      () => currentStatus.value === SubtaskStatus.CLOSED_IN_ERROR
    )

    /**
     *
     * @param subtaskStatus
     * @param canOverridePathwaysBlockedStatus
     */
    function subtaskStatusOptions(
      subtaskStatus: SubtaskStatus,
      canOverridePathwaysBlockedStatus: boolean
    ) {
      const options = getAvailableStatusOptions(
        subtaskStatus,
        canOverridePathwaysBlockedStatus
      ) as any[]

      if (subtaskStatus && options) {
        return options.map(([value, obj]) => ({
          label: obj.text,
          val: value,
          icon: obj.icon,
          fillType: getSubtaskIconFill(value),
        }))
      }
    }

    const notAssignable = computed(
      () =>
        currentStatus.value === SubtaskStatus.CLOSED_IN_ERROR ||
        currentStatus.value === SubtaskStatus.COMPLETED
    )

    const queryPathwayId = computed(() => route.query?.pathwayId)
    const queryTaskId = computed(() => route.query?.taskId)
    const isCreatingComm = computed(() => useCommunicationsStore().isCreating)
    const commSidebarOpen = computed(
      () => !!route.query?.commId || isCreatingComm.value
    )

    const querySubtaskId = computed(() => route.query?.subtaskId)
    const sidebarIsOpen = computed(
      () =>
        !!queryPathwayId.value ||
        !!querySubtaskId.value ||
        !!queryTaskId.value ||
        !!commSidebarOpen.value
    )

    const showBlockedStatus = computed(
      () =>
        showStatusSelect.value &&
        !showTrimmedVersion.value &&
        props.subtask.status === SubtaskStatus.BLOCKED
    )

    const showStatusAsIs = computed(
      () =>
        showStatusSelect.value &&
        !showTrimmedVersion.value &&
        props.subtask.status !== SubtaskStatus.BLOCKED
    )

    // screen width listeners and variables
    const windowWidth = ref(window.innerWidth)
    const onWidthChange = () => (windowWidth.value = window.innerWidth)

    onMounted(async () => {
      await getReattemptData()
      return thymeListen('resize', onWidthChange)
    })
    onUnmounted(() => window.removeEventListener('resize', onWidthChange))

    const currentIcon = computed(
      () =>
        Object.values(SubtaskStatuses).filter(
          (taskStatus) => taskStatus.value === currentStatus.value
        )[0]?.icon ?? 'openStatus'
    )

    const currentIconFill = computed(
      () =>
        Object.values(SubtaskStatuses).filter(
          (taskStatus) => taskStatus.value === currentStatus.value
        )[0]?.fillType ?? ''
    )

    const goToSubtask = (subtaskId: string) => {
      props.subtask &&
        void router.push({
          path: `/patient/${props.subtask.memberId}/`,
          query: {
            ...(props.subtask.pointerToCommId
              ? { commId: props.subtask.pointerToCommId, type: 'sms' }
              : { subtaskId: subtaskId }),
          },
        })
    }

    /**
     * retrieve current subtask status
     * @param subtaskStatus
     */
    function getCurrentSubtaskStatus(subtaskStatus: SubtaskStatus) {
      return {
        icon: getSubtaskIconName(subtaskStatus),
        label: SubtaskStatuses[subtaskStatus].text,
        val: subtaskStatus,
      }
    }

    /**
     *
     * @param val
     */
    async function updateRoleOrStaff(val: EntityRole | UUID | null) {
      const selfEntityId = selfEntity.value ? selfEntity.value?.entityId : ''
      const updateSubtaskPayload = setRoleOrStaff(
        val,
        dirtySubtask,
        selfEntityId
      )

      await updateDirtySubtask(updateSubtaskPayload)
    }

    /**
     *
     * @param val
     */
    async function setPriority(val: SubtaskPriority) {
      await updateDirtySubtask({
        priority: val,
      })
    }

    /**
     *
     */
    function getDirtySubtaskDefaults(): SubtaskPayload {
      return {
        responsibleStaffId: props.subtask.responsibleStaffId ?? null,
        status: props.subtask.status,
        dueDatetime: props.subtask.dueDatetime,
        notes: props.subtask.notes,
        showTime: false,
        responsibleRole: props.subtask.responsibleRole,
        priority: props.subtask.priority,
        etag: showCoolDownFeature.value ? props.subtask.updatedAt : null,
      }
    }
    watch(props, async () => {
      await getReattemptData()
      return Object.assign(dirtySubtask, getDirtySubtaskDefaults())
    })

    const dirtySubtask: SubtaskPayload = reactive(getDirtySubtaskDefaults())

    /**
     *
     * @param status
     */
    async function setStatus(status: SubtaskStatus) {
      if (status === SubtaskStatus.REATTEMPT) {
        context.emit('openReattemptModal', props.subtask)
        return
      }
      if (
        showSurfacedStatusReason.value &&
        statusesWithSubStatusReason.includes(status)
      ) {
        context.emit('openSubStatusModal', props.subtask, status)
        return
      }
      if (status === SubtaskStatus.OPEN_UNASSIGNED) {
        await updateDirtySubtask({
          responsibleRole: dirtySubtask.responsibleRole,
          responsibleStaffId: null,
          status,
        })
      } else if (
        status === SubtaskStatus.OPEN_ASSIGNED ||
        (status === SubtaskStatus.IN_PROGRESS &&
          !dirtySubtask.responsibleStaffId)
      ) {
        await updateDirtySubtask({
          responsibleStaffId: selfEntity.value?.entityId,
          status,
        })
      } else {
        await updateDirtySubtask({ status })
      }

      if (
        props.displayType === SubtaskRowDisplayType.MY_QUEUE ||
        props.displayType === SubtaskRowDisplayType.ENROLLMENT
      ) {
        context.emit('callbackFn')
      }
    }

    /**
     *
     * @param data
     */
    async function updateDirtySubtask(data: Partial<SubtaskPayload>) {
      //https://stackoverflow.com/questions/70500357/using-ref-vs-reactive-why-this-example-works-as-it-does-in-vue-3-compositio
      Object.assign(dirtySubtask, data)
      await updateSubtask()
    }

    /**
     *
     */
    async function updateSubtask() {
      await useSubtasksStore().updateSubtask(
        props.subtask.subtaskId,
        dirtySubtask
      )

      context.emit('callbackFn', props.subtask.subtaskId)

      if (dirtySubtask.status === SubtaskStatus.COMPLETED) {
        context.emit('setStatus', {
          status: dirtySubtask.status,
          openModal: true,
        })
      }
    }

    /**
     * retrieve reattempted subtask information if subtask has hideDatetime field
     */
    async function getReattemptData() {
      if (props.subtask.hideDatetime) {
        // IMPROVEME(MT-1842): try to use store as source of truth to fetch/refetch subtasks
        const results = await useReattemptStore().getReattempts({
          filter_subtask_ids: [props.subtask.subtaskId],
        })
        reattemptData.value = results?.data
      }
    }

    return {
      canOverridePathwaysBlockedStatus,
      canEditPathwaysPriority,
      reattemptData,
      currentIcon,
      currentIconFill,
      currentPriority,
      currentPriorityIcon,
      currentStaff,
      currentStatus,
      dirtySubtask,
      getPriorityIcon,
      goToSubtask,
      isClosedInError,
      isSubtaskDetailView,
      isTaskAccordionView,
      notAssignable,
      pathwayTaskMapTemplateId,
      renderBlockedMessage,
      renderDatePretext,
      renderDateString,
      renderReattemptTooltip,
      setPriority,
      updateRoleOrStaff,
      setStatus,
      showBlockedStatus,
      showCoolDownFeature,
      showResponsibleStaffSelect,
      showStatusAsIs,
      showTrimmedVersion,
      sidebarIsOpen,
      capitalize,
      StatusReason,
      SubtaskPriority,
      subtaskPriorityOptions,
      SubtaskRowDisplayType,
      SubtaskStatus,
      SubtaskStatuses,
      subtaskStatusOptions,
      subtaskTaskMapTemplateId,
      QueueStyle,
      getCurrentSubtaskStatus,
      subtaskPriorityOptionsV2,
      selectedPriorityObj,
      EntityRole,
      hideSubtaskEstimatedCompletionTime,
    }
  },
})
</script>

<style lang="scss" scoped>
.overdue {
  @apply text-nash-brick600 font-bold;
}

button.action-button {
  @apply rounded-full border w-max;
}

.chip {
  @apply flex gap-1 px-2 py-1.5 mx-2 rounded-full border border-solid items-center;
}

.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}

.thymeline-status-dropdown.dropdown.is-disabled {
  opacity: 1;
}

.remove-border {
  border-width: 0px !important;
  @apply hover:bg-nash-purple100;
}
</style>
