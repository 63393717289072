import { PropType } from 'vue'

export const contractingChipProps = {
  contractId: {
    type: String as PropType<string | null>,
    default: null,
  },
  contractingEntityName: {
    type: String as PropType<string>,
    default: '',
  },
} as const
