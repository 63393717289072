<template>
  <div v-if="showPhoneNumberVerification">
    <PhoneNumberVerification
      :data="nonPatientPhoneNumberMaps"
      :saved-form-data="savedFormData"
      :was-unknown-number="!!wasUnknownNumber"
      @exit-create-or-edit="exitCreateOrEdit"
      @submit="upsertEntityPhoneNumber"
    />
  </div>

  <div v-else>
    <!-- @vue-ignore Submit generics not compatible with specifics -->
    <VForm
      v-slot="{ values, setFieldValue }"
      :initial-values="initialValues"
      @submit="submit"
    >
      <button
        v-if="data"
        class="flex w-full flex-row-reverse font-bold text-nash-purple500"
        name="edit-patient-phonenumber"
      >
        Save
      </button>
      <TInputText
        :model-value="initialValues.phoneNumber"
        label="Phone number"
        name="phoneNumber"
        rules="required|phone"
      />
      <div
        v-if="
          data &&
          (!data.phoneNumber ||
            data.phoneNumber.type !== PhoneNumberType.LANDLINE)
        "
        class="mt-4"
      >
        <label class="font-bold">Texting Consent Status</label>
        <TDropdown
          class="bg-nash-neutral000 w-full mt-1.5 mb-3"
          name="texting-consent"
          :options="consentStatusOptions"
          :initial-value="
            values.messagingOptInStatus === null
              ? NULL_STRING
              : values.messagingOptInStatus
          "
          option-label="label"
          option-value="value"
          @update:model-value="
            (e) => {
              setFieldValue(
                'messagingOptInStatus',
                e === NULL_STRING ? null : e
              )
            }
          "
        />
      </div>
      <div class="flex column">
        <div>
          <LegacyTCheckbox
            :checked="values.isPrimary"
            type="checkbox"
            label="Best number for the member"
            @update:checked="setFieldValue('isPrimary', $event)"
          />
        </div>
      </div>
      <div v-if="!data" class="mt-2 flex flex-row-reverse">
        <button
          class="p-button p-component mt-2"
          name="save-patient-phonenumber"
        >
          Save
        </button>
      </div>
    </VForm>
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { FillColor } from '@thyme/nashville/src/types/icons'
import { storeToRefs } from 'pinia'
import { Form as VForm } from 'vee-validate'
import { computed, defineComponent, PropType, ref } from 'vue'
import { SMS_COMM_BY_PHONE_NUMBER_ID_SORT_BY } from '@/legacy/components/texting/lib/sharedTextingParts'
import { formatPhone, onlyDigits } from '@/legacy/libs/format'

import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTCheckbox from '@/legacy/nashville/LegacyTCheckbox.vue'

import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useNotificationStore } from '@/legacy/store/modules/notification'

import {
  isEvergreenPathway,
  usePathwayApi,
} from '@/legacy/store/modules/pathways'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import {
  SMS_PARTS,
  useSmsCommByPhoneNumberIdApi,
} from '@/legacy/store/modules/textingV2'
import {
  Communication,
  CommunicationType,
} from '@/legacy/types/communications/communications'
import {
  PatientPhoneNumberForm,
  PhoneNumberType,
  MapEntityPhoneNumber,
  CreatePhoneNumberRequest,
} from '@/legacy/types/entities/phoneNumbers'
import { NULL_STRING } from '@/legacy/types/global/strings'
import { NotificationType } from '@/legacy/types/notifications'
import {
  closeOldSubtaskAndCreateNewOne,
  getPhoneNumberEntityMaps,
} from '../lib/sharedPhoneNumberVerificationParts'
import PhoneNumberVerification from '../parts/view/PhoneNumberVerification/PhoneNumberVerification.vue'

export default defineComponent({
  components: {
    PhoneNumberVerification,
    TInputText,
    LegacyTCheckbox,
    VForm,
    TDropdown,
  },
  props: {
    data: {
      type: Object as PropType<MapEntityPhoneNumber>,
      default: null,
    },
    editingId: {
      type: String as PropType<string | null>,
      required: true,
    },
    isContactOther: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['exitCreateOrEdit'],
  setup(props, context) {
    const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
      useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId

    const { patient, phoneNumbers } = storeToRefs(usePatientStore())
    const { data: pathways } = storeToRefs(usePathwayApi())

    const showPhoneNumberVerification = ref(false)

    const nonPatientPhoneNumberMaps = ref<MapEntityPhoneNumber[]>([])
    const savedFormData = ref<PatientPhoneNumberForm>(emptyForm())

    const consentStatusOptions = [
      { label: 'Waiting for Response/Unknown', value: NULL_STRING },
      { label: 'Consented to Texting', value: true },
      { label: 'Opted Out of Texting', value: false },
    ]

    /**
     *
     */
    function exitCreateOrEdit() {
      context.emit('exitCreateOrEdit')
    }

    /**
     * Function to empty intial form values
     */
    function emptyForm(): PatientPhoneNumberForm {
      return {
        phoneNumber: '',
        isPrimary: false,
        messagingOptInStatus: null,
        countryCode: '1',
        note: '',
      }
    }

    const evergreenPathway = computed(
      () =>
        Object.values(pathways.value ?? {}).filter((pathway) =>
          isEvergreenPathway(pathway.title)
        )[0]
    )

    const wasUnknownNumber = computed(
      () =>
        nonPatientPhoneNumberMaps.value.filter(
          (mapEntityPhoneNumber: MapEntityPhoneNumber) =>
            mapEntityPhoneNumber.entityId === TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
        )[0]
    )

    const initialValues = computed((): PatientPhoneNumberForm => {
      let initialForm: PatientPhoneNumberForm = emptyForm()
      if (!props.data) {
        return initialForm
      }
      const phone = props.data.phoneNumber
      initialForm = {
        note: props.data.note ?? '',
        isPrimary: props.data.isPrimary,
        messagingOptInStatus: phone.messagingOptInStatus,
        countryCode: phone.countryCode,
        phoneNumber: formatPhone(phone.phoneNumber),
      }
      return initialForm
    })

    /**
     *
     * @param createdMap
     * @param previousMap
     */
    async function deleteOldPhoneEntityMapAndUpdatePatientCommMap(
      createdMap: MapEntityPhoneNumber,
      previousMap: MapEntityPhoneNumber
    ) {
      // Remove the map between number and unknown texts patient
      try {
        await useMapEntityPhoneNumberApi().delete({
          ids: [previousMap.mapEntityPhoneNumberId],
        })
      } catch (err) {
        console.error(err)
        useNotificationStore().setNotification({
          message: 'Failed to delete old phone number map.',
          type: NotificationType.DANGER,
        })
        return
      }
      // Update comms map from unknown texts patient to new patient
      try {
        await updateMapPatientCommForAddedNumber(createdMap.phoneNumberId)
      } catch (err) {
        console.error(err)
        useNotificationStore().setNotification({
          message: 'Failed to update patient communication maps.',
          type: NotificationType.DANGER,
        })
        return
      }
      // refetch entity phone maps to reflect most current maps
      await getPhoneNumberEntityMaps(createdMap.phoneNumber.phoneNumber)
    }

    /**
     *
     * @param data
     */
    async function upsertEntityPhoneNumber(data: PatientPhoneNumberForm) {
      if (!phoneNumbers.value || !patient.value) {
        return
      }

      const updatedPhoneNumbers = {
        ...phoneNumbers.value,
      }

      try {
        const body = {
          entityId: patient.value.entityId,
          phoneNumberId: props.data?.phoneNumberId,
          note: data.note,
          isPrimary: data.isPrimary,
          phoneNumber: {
            phoneNumber: onlyDigits(data.phoneNumber),
            countryCode: data.countryCode,
            messagingOptInStatus: data.messagingOptInStatus,
          } as CreatePhoneNumberRequest,
        }

        const created = await useMapEntityPhoneNumberApi().upsert({ body })

        updatedPhoneNumbers[created.mapEntityPhoneNumberId] = created

        usePatientStore().updatePhoneNumbers(Object.values(updatedPhoneNumbers))

        if (wasUnknownNumber.value) {
          await deleteOldPhoneEntityMapAndUpdatePatientCommMap(
            created,
            wasUnknownNumber.value
          )
        }
      } catch (err) {
        console.error(err)
        useNotificationStore().setNotification({
          message: 'Failed to save phone number.',
          type: NotificationType.DANGER,
        })
        return
      }

      useNotificationStore().setNotification({
        message: 'Successfully saved phone number.',
        type: NotificationType.SUCCESS,
      })
      exitCreateOrEdit()
    }

    /**
     *
     *What: If current patient is zzUnknown zzTexts account
     *find all communications for the phone number
     *and map them to current zzUnknown zzTexts account
     *Why: this is a stopgap solution to ensure that all historical
     *text messages now show up in the conversation thread for users
     *to interact with unknown number properly
     * @param phoneNumberId
     */
    async function updateMapPatientCommForAddedNumber(phoneNumberId: string) {
      const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
        useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId
      if (
        patient.value &&
        (patient.value.entityId === TEXTING_UNKNOWN_NUMBERS_PATIENT_ID ||
          !!wasUnknownNumber.value)
      ) {
        const mostRecentComms = await useSmsCommByPhoneNumberIdApi().list({
          params: {
            filter_phone_number_ids: [phoneNumberId],
            filter_types: CommunicationType.Text,
            sort_by: SMS_COMM_BY_PHONE_NUMBER_ID_SORT_BY,
            page_length: 10,
            page_number: 1,
            parts: SMS_PARTS,
            filter_patient_ids: [
              wasUnknownNumber.value
                ? TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
                : patient.value.entityId,
            ],
          },
        })
        if (mostRecentComms.data.length) {
          // close pointer subtask for unknown text patient if it is open
          //  and create new one for current patient
          await closeOldSubtaskAndCreateNewOne(
            mostRecentComms.data[0],
            evergreenPathway.value
          )
          try {
            const promises: Promise<void>[] = []
            mostRecentComms.data.forEach((comm: Communication) => {
              promises.push(
                useCommunicationsStore().updateCommunication(
                  comm.communicationId,
                  { patientIds: [patient.value?.entityId ?? ''] }
                )
              )
            })
            await Promise.all(promises)
          } catch (err) {
            console.error(err)
            useNotificationStore().setNotification({
              message: 'Failed to map patient to communication.',
              type: NotificationType.DANGER,
            })
            return
          }
        } else {
          useNotificationStore().setNotification({
            message: 'Cannot map patient to null communication.',
            type: NotificationType.DANGER,
          })
        }
      }
    }

    /**
     *
     * @param phoneNumberMappings
     */
    function hasPhoneNumberEntityMaps(
      phoneNumberMappings: MapEntityPhoneNumber[]
    ) {
      if (phoneNumberMappings.length > 0) {
        const patientMapEntityPhoneNumberIds = Object.keys(
          phoneNumbers.value ?? {}
        )
        const nonPatientMaps = phoneNumberMappings.filter(
          (mapEntityPhone) =>
            !patientMapEntityPhoneNumberIds.includes(
              mapEntityPhone.mapEntityPhoneNumberId
            )
        )
        nonPatientPhoneNumberMaps.value = nonPatientMaps
        return !!nonPatientMaps.length
      }
      return false
    }

    /**
     * Function to submit update/create phone number
     * @param data
     */
    async function submit(data: PatientPhoneNumberForm) {
      if (!props.editingId) {
        const phoneNumberMappings = await getPhoneNumberEntityMaps(
          data.phoneNumber
        )
        const phoneIsMappedToOtherEntities =
          hasPhoneNumberEntityMaps(phoneNumberMappings)
        if (phoneIsMappedToOtherEntities) {
          savedFormData.value = data
          showPhoneNumberVerification.value = true
          return
        }
      }
      await upsertEntityPhoneNumber(data)
    }

    return {
      savedFormData,
      upsertEntityPhoneNumber,
      exitCreateOrEdit,
      nonPatientPhoneNumberMaps,
      showPhoneNumberVerification,
      phoneNumbers,
      submit,
      formatPhone,
      PhoneNumberType,
      FillColor,
      initialValues,
      consentStatusOptions,
      NULL_STRING,
      wasUnknownNumber,
    }
  },
})
</script>
