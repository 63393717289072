import apiStore from '@/legacy/store/modules/apiBuilder'
import { PatientContract } from '@/legacy/types/patients/patientContracts'

export const usePatientContractsApi = apiStore<PatientContract>(
  'patientContractsApi',
  '/api/patients/patient_contracts',
  {
    transformData: (d: { data: PatientContract[] }) => d,
  }
)
