// ENUMS --------------------
export enum LabelSize {
  Small = 'sm',
  Medium = 'md',
}

// CONSTANTS --------------------
export const labelClasses = [
  'cursor-default',
  'rounded-full',
  'items-center',
  'bg-nash-purple500',
]
