<template>
  <div>
    <TModal
      v-if="!showAddOrEditModal"
      :is-visible="true"
      :size="ModalSize.LG"
      title="Manage Treatment"
      @close="close"
    >
      <div>
        <div class="flex justify-between pb-2 items-center">
          <TTab
            :tabs="statuses"
            :active="selectedStatus"
            @select="selectStatus"
          />
          <div v-tooltip.left="addButtonDisabledMessage">
            <TMSecondaryButton
              label="+ Add Treatment"
              :disabled="addButtonDisabled"
              @click="toggleAddOrEditModal(null)"
            />
          </div>
        </div>
        <div class="space-y-4">
          <div
            v-for="treatment in treatmentsByStatus"
            :key="treatment.patientTreatmentId"
          >
            <div class="treatment-container">
              <div>
                <h5>{{ treatment.treatmentAgentDisplay }}</h5>
                <div class="text-nash-neutral800 text-base font-normal">
                  Brands: {{ treatment.treatmentBrandsDisplay }} •
                  {{ treatment.treatmentDuration }}
                </div>
              </div>
              <TOIconDropdown
                v-if="showEditOptionDropdown"
                name="treatment-edit-menu"
                icon="meatball"
                :options="editOptionsArray"
                @change="
                  (v) => openModal(v.value, treatment.patientTreatmentId)
                "
              />
            </div>
          </div>
        </div>
        <div v-if="showMoreCurrentTreatmentDatesButton">
          <TMTertiaryButton
            label="Show More"
            class="pt-2 mb-3"
            icon-pos="right"
            :icon="'chevronDown'"
            @click="loadMore"
          />
        </div>
      </div>
      <template #actions>
        <TMQuaternaryButton
          label="Cancel"
          name="manage-treatment-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="manage-treatment-save"
          label="Save"
          @click="onSave"
        />
      </template>
    </TModal>
    <DeleteTreatment
      v-if="showDeleteModal && deleteTreatmentId"
      :treatment-id="deleteTreatmentId"
      @close="toggleDeleteModal"
      @refetch="refetchCurrentTreatment"
    />
    <AddOrEditTreatment
      v-if="showAddOrEditModal"
      :cancer-diagnosis-id="cancerDxId"
      :show-add="!editTreatmentId"
      :treatment-id="editTreatmentId ?? ''"
      :initial-data="editModalInitialData"
      @close="toggleAddOrEditModal"
      @refetch="refetchCurrentTreatment"
    />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TTab from '@nashville/tab/TTab.vue'
import { defineComponent } from 'vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import AddOrEditTreatment from './AddOrEditTreatment/AddOrEditTreatment.vue'
import { setup } from './controller'
import DeleteTreatment from './DeleteTreatment/DeleteTreatment.vue'
import { manageTreatmentProps } from './types'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    TMTertiaryButton,
    TMPrimaryButton,
    TModal,
    TTab,
    TMSecondaryButton,
    DeleteTreatment,
    TOIconDropdown,
    AddOrEditTreatment,
  },
  props: manageTreatmentProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.treatment-container {
  @apply flex justify-between border border-nash-neutral400 p-4 rounded-lg;
}
</style>
