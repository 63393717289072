<template>
  <div>
    <CreateInsurance
      v-if="creating || editModalInitialData"
      :initial-data="editModalInitialData"
      data-testid="create-insurance-modal"
      @close="reset"
    />
    <DeleteInsurance
      v-else-if="deletingInsurance"
      :payer-name="deletingInsurance.payerName"
      :network-tier="deletingInsurance.networkTier"
      :insurance-id="deletingInsurance.insuranceId"
      data-testid="delete-insurance-modal"
      @close="reset"
    />
    <TModal
      v-else
      :is-visible="true"
      title="Insurances"
      :size="ModalSize.SM"
      :flex="true"
      :allow-overflow="true"
      data-testid="edit-insurances-modal"
      @close="close"
    >
      <!-- div wrapper to keep smaller sized button and not span full width -->
      <div class="mb-4">
        <TMSecondaryButton
          label="+ Add"
          name="add-insurance"
          @click="reset(true)"
        />
      </div>

      <div class="min-h-300 overflow-y-auto h-full flex flex-col gap-4">
        <div
          v-for="insurance in insurances"
          :key="insurance.insuranceId"
          class="insurance-wrapper"
        >
          <h5>{{ insurance.payerName }}</h5>
          <!-- IMPROVE-ME(MT-3323) Replace with PV Equiv -->
          <LegacyTDropdown
            icon="meatball"
            model-value=""
            size="sm"
            position="bottom-left"
            :expanded="false"
            :unmask="true"
            :show-elements-selected="true"
            :options="insuranceActions(insurance)"
            @update:model-value="triggerOptions($event, insurance)"
          />
        </div>
      </div>
      <template #actions>
        <TMPrimaryButton
          name="confirm-insurance"
          class="mt-3"
          label="Confirm"
          @click="close"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import CreateInsurance from './CreateInsurance/CreateInsurance.vue'
import DeleteInsurance from './DeleteInsurance/DeleteInsurance.vue'
import { EDIT_INSURANCES_PROPS } from './types'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { EditInsuranceModel } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMSecondaryButton,
    TModal,
    LegacyTDropdown,
    DeleteInsurance,
    CreateInsurance,
  },
  props: EDIT_INSURANCES_PROPS,
  emits: ['close'],
  setup,
})
</script>
<style lang="scss">
.insurance-wrapper {
  @apply flex justify-between items-center;
  @apply py-6 px-4;
  @apply border border-nash-neutral400 rounded-lg;
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
