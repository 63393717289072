import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { uniq } from 'lodash'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import { DateTime } from 'luxon'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime, formatName } from '@/legacy/libs/format'
import { useReattemptStore } from '@/legacy/store/modules/reattempts'
import {
  usePreferredContactTimesApi,
  useRemindersPatientsApi,
} from '@/legacy/store/modules/reminders'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import { Subtask, SubtaskPriority } from '@/legacy/types/pathways/subtasks'
import { PreferredContactTime } from '@/legacy/types/patients/contactTimes'
import { Patient } from '@/legacy/types/patients/patients'
import {
  subtaskStatusFilter,
  programStatusFilter,
  dueDateFilters,
  onCompletedDateFilters,
  languagesFilter,
  setupPayerFilter,
  lobFilter,
  subtaskTypeFilter,
  prioritiesFilter,
  tierFilters,
} from '../../sharedTable/sharedTableFilters'

// CONST
const RESTRICTED_HOUR = 20
const RESTRICTED_MINUTES = 59

/**
 *
 * @param val
 */
export function formatStringToArray(val: string) {
  return compact(val.split(/\s|,/g))
}

/**
 *
 * @param datetime
 * @param row
 */
export function formatReminderDatetime(datetime: string | null, row: Subtask) {
  let showTime
  let hasTime

  if (datetime) {
    const d = new Date(datetime)
    const hrs = d.getHours()
    const mins = d.getMinutes()

    hasTime = hrs !== RESTRICTED_HOUR && mins !== RESTRICTED_MINUTES
  }

  if (row) {
    showTime = row.showTime
  }
  return datetime
    ? formatDateTime(
        stringToDateTime(datetime),
        showTime && hasTime ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT
      )
    : ''
}

/**
 *
 * @param patientId
 */
function displayPreferredContactTimes(patientId: string | undefined) {
  const dataStream = usePreferredContactTimesApi().data
  if (dataStream && patientId && dataStream[patientId]) {
    return dataStream[patientId]
      .map(({ dayPart }: PreferredContactTime) => capitalize(dayPart))
      .join(', ')
  }
  return ''
}

/**
 *
 * @param subtask
 */
export function renderSubtaskStatus(subtask: Subtask) {
  return (
    (SubtaskStatuses[subtask.status as keyof typeof SubtaskStatuses] ?? {})
      .text ?? '--'
  )
}

/**
 *
 * @param subtasks
 */
export async function getPreferredContactTimes(subtasks: Subtask[]) {
  const patientIds = subtasks.map((subtask: Subtask) => subtask.memberId)
  await usePreferredContactTimesApi().list({
    params: {
      filter_patient_ids: patientIds,
    },
  })
}

/**
 *
 * @param subtasks
 */
export async function getReattemptsForListedReminders(subtasks: Subtask[]) {
  const reattemptedSubtaskIds = subtasks
    .filter((subtask: Subtask) => !!subtask.hideDatetime)
    .map((subtask: Subtask) => subtask.subtaskId)

  if (reattemptedSubtaskIds.length) {
    await useReattemptStore().getReattempts({
      filter_subtask_ids: reattemptedSubtaskIds,
    })
  }
}

/**
 *
 * @param subtasks
 */
export async function getFollowUpData(subtasks: Subtask[]) {
  await Promise.all([
    getReattemptsForListedReminders(subtasks),
    getPreferredContactTimes(subtasks),
    getTaskPatients(subtasks),
  ])
}

/**
 *
 * @param fields
 * @param columns
 */
export function hideColumns(fields: string[], columns: ColumnOptions[]) {
  columns
    .filter((column) => fields.includes(column.field as string))
    .forEach((column) => (column.hidden = true))
  return columns
}

/**
 *
 * @param subtask
 */
export function getQuery(subtask: Subtask) {
  return {
    ...(subtask.pointerToCommId
      ? {
          commId: subtask.pointerToCommId,
          type: 'sms',
        }
      : { subtaskId: subtask.subtaskId }),
  }
}

/**
 *
 * Function that fetches stacks of subtasks per given patient IDs
 * @param subtasks
 */
export async function getTaskPatients(subtasks: Subtask[]) {
  const patientIds = uniq(subtasks.map((subtask: Subtask) => subtask.memberId))
  await useRemindersPatientsApi().list({
    params: {
      filter_patient_ids: patientIds,
      parts: [
        'care_pod',
        'insurances',
        'latest_acuity_score',
        'pinned_acuity_score',
      ],
    },
  })
}

export const sharedColumns = (otherCols: ColumnOptions[]): ColumnOptions[] => {
  return [
    {
      field: 'dueDatetime',
      header: 'Due Date',
      sortable: true,
      style: { width: '9rem' },
      display: formatReminderDatetime,
    },
    {
      field: 'priority',
      header: 'Priority',
      sortable: true,
      style: { width: '8rem' },
      display: (_, row: Subtask) =>
        SubtaskPriority[
          row.priority.toUpperCase() as keyof typeof SubtaskPriority
        ] ?? '--',
    },
    {
      field: 'preferredContactTime',
      header: 'Preferred Time(s)',
      sortable: false,
      display: (_: any, row: Subtask) =>
        row.memberId ? displayPreferredContactTimes(row.memberId) : '--',
    },
    {
      field: 'reminderType',
      header: 'Type',
      sortable: true,
      display: (_, row: Subtask) =>
        row.title ?? 'Subtask: <Unknown Subtask Type>',
      truncateLength: 1000,
      linkFn: (_, row: Subtask) => ({
        path: `/patient/${row.memberId}/`,
        query: getQuery(row),
      }),
      style: { 'flex-wrap': 'wrap', width: '15%' },
    },
    {
      field: 'status',
      header: 'Status',
      display: (_, row: Subtask) => renderSubtaskStatus(row),
    },
    {
      field: 'patient',
      header: 'Member Name',
      display: (p: Patient) =>
        p
          ? formatName(p.person.firstName, p.person.lastName)
          : 'Unknown Member',
      linkFn: (_, row: Subtask) => ({
        path: `/patient/${row.memberId}/`,
      }),
      style: { 'flex-wrap': 'wrap', minWidth: '10rem' },
    },
    ...otherCols,
    {
      field: 'note',
      header: 'Notes',
      display: (_, row: Subtask) => row.notes ?? '-',
      sortable: true,
      style: { width: '15%' },
      truncateLength: 800,
    },
  ]
}

// used for TeamReminders
export const sharedFilters = () => {
  const nonAcuityFilters = {
    priorities: prioritiesFilter,
    languages: languagesFilter,
    lobs: lobFilter,
    payerIds: setupPayerFilter(),
  }
  return {
    ...dueDateFilters,
    ...onCompletedDateFilters,
    ...tierFilters,
    ...nonAcuityFilters,
    subtaskStatus: subtaskStatusFilter,
    programStatus: programStatusFilter,
    subtaskKeys: subtaskTypeFilter,
  }
}

// used for MyReminders and AllReminders
export const fullFilters = () => ({
  ...sharedFilters(),
})
