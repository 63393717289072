<template>
  <div>
    <div>
      <div>
        {{ address.streetAddressLine1 }}
      </div>
      <div v-if="address.streetAddressLine2">
        {{ address.streetAddressLine2 }}
      </div>
      <div>
        {{ address.city }},
        <span v-if="address.county">{{ address.county }} Co., </span>
        {{ address.state }}
        {{ address.zip }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AddressPartial } from '@/legacy/types/entities/addresses.js'

export default defineComponent({
  props: {
    address: { type: Object as PropType<AddressPartial>, required: true },
  },
})
</script>
