import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { Surgery } from '@/pages/PatientProfile/CarePlans/shared/types'
import { usePreviousCancerSurgeriesApi } from './shared/store'

/**
 * Retrieve all surgeries for previous cancer dx
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param patientId
 */
export async function getPreviousCancerSurgeries(
  cancerDxId: string,
  patientId: string
): Promise<Surgery | null> {
  const surgeryApi = usePreviousCancerSurgeriesApi()
  const response = await surgeryApi.list({
    params: {
      exclude_cancer_diagnosis_ids: [cancerDxId],
      filter_patient_ids: [patientId],
      parts: ['cancer_diagnosis'],
      sort_by: 'surgeryDate,desc',
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (surgeryApi.error) {
    const error = surgeryApi.error
    useNotificationStore().setNotification({
      message: `Error fetching current patient surgeries`,
      type: NotificationType.WARNING,
      error: `Error fetching current patient surgeries: ${error}`,
    })
  }
  return response.data
}
