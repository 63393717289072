import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import { stringToDateTime, utcDatetimeToDate } from '@/legacy/libs/date'
import { toDateDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { getPatientSurgeryWithParts } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { useSurgeryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { Surgery } from '@/pages/PatientProfile/CarePlans/shared/types'
import { getDisabledButtonStr } from '../shared/format'
import {
  EditOptions,
  editOptionsArray,
  ManageTabLabel,
  PAGE_LENGTH,
  tabLabels,
  TreatmentField,
} from '../shared/types'
import { getPreviousCancerSurgeries } from './queries'
import { usePreviousCancerSurgeriesApi } from './shared/store'
import { manageSurgeriesProps } from './types'

type PropsType = ExtractPropTypes<typeof manageSurgeriesProps>

/**
 * Set up the ManageSurgeries component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: currentSurgeries, queryMetadata: currentSurgeriesMetadata } =
    storeToRefs(useSurgeryApi())
  const { data: previousSurgeries } = storeToRefs(
    usePreviousCancerSurgeriesApi()
  )

  const totalCurrentSurgeriesCount = computed(
    () => currentSurgeriesMetadata.value?.total ?? 0
  )
  const showMoreCurrentSurgeriesButton = computed(
    () =>
      Object.values(currentSurgeries.value ?? {}).length <
      totalCurrentSurgeriesCount.value
  )
  const currentSurgeriesArray = computed(() =>
    Object.values(currentSurgeries.value ?? {})
  )

  const pageNumber = ref(1)

  const showDeleteModal = ref(false)
  const showAddOrEditModal = ref(false)

  const deleteSurgeryId = ref<string | null>(null)
  const editSurgeryId = ref<string | null>(null)

  const statuses = computed(() => {
    if (!previousSurgeries.value?.length) {
      return tabLabels.filter(
        (tabLabel) => tabLabel.value === ManageTabLabel.CURRENT
      )
    }
    return tabLabels
  })

  const selectedStatus = ref(statuses.value[0].value)
  const selectedStatusLabel = ref(statuses.value[0].label)

  const selectStatus = (v: Tab) => (
    (selectedStatus.value = v.value as ManageTabLabel),
    (selectedStatusLabel.value = v.label)
  )

  const addButtonDisabled = computed(
    () => selectedStatus.value === ManageTabLabel.PREVIOUS
  )
  const addButtonDisabledMessage = computed(() =>
    addButtonDisabled.value ? getDisabledButtonStr(TreatmentField.SURGERY) : ''
  )

  const showEditOptionDropdown = computed(
    () => selectedStatus.value === ManageTabLabel.CURRENT
  )

  /**
   *
   * @param str
   * @param surgeryId
   */
  function openModal(str: EditOptions, surgeryId: string) {
    if (str === EditOptions.EDIT) {
      toggleAddOrEditModal(surgeryId)
    } else if (str === EditOptions.DELETE) {
      toggleDeleteModal(surgeryId)
    }
  }

  /**
   *
   * @param surgery
   */
  function surgicalProcedureDisplay(surgery: Surgery) {
    const surgicalProcRef = surgery.surgicalProcedureReference
    const otherSurgicalProc = surgery.otherSurgeryType
    if (surgicalProcRef) {
      return surgicalProcRef.description
    }
    if (otherSurgicalProc) {
      return otherSurgicalProc
    }
  }

  const formattedCurrentSurgery = computed(() =>
    currentSurgeriesArray.value.map((surgery) => ({
      patientSurgeryId: surgery.patientSurgeryId,
      surgeryDisplay: surgery.otherSurgeryType
        ? `Other: ${surgery.otherSurgeryType}`
        : surgery.surgicalProcedureReference.description,
      surgeryDate: surgery.surgeryDate
        ? toDateDisplay(surgery.surgeryDate)
        : '',
    }))
  )

  const formattedPreviousSurgery = computed(() => {
    const surgerys = previousSurgeries.value ?? []
    return surgerys.map((surgery) => ({
      patientSurgeryId: surgery.patientSurgeryId,
      surgeryDisplay: `${
        surgery.otherSurgeryType
          ? `Other: ${surgery.otherSurgeryType}`
          : surgery.surgicalProcedureReference.description
      } (${
        surgery.cancerDiagnosis.otherOncologyDxType ??
        surgery.cancerDiagnosis.oncologyDiagnosisReference?.description
      })`,
      surgeryDate: surgery.surgeryDate
        ? toDateDisplay(surgery.surgeryDate)
        : '',
    }))
  })

  const surgeriesByStatus = computed(() => {
    if (selectedStatus.value === ManageTabLabel.CURRENT) {
      return formattedCurrentSurgery.value
    }
    if (selectedStatus.value === ManageTabLabel.PREVIOUS) {
      return formattedPreviousSurgery.value
    }
    return []
  })

  /**
   *
   * @param surgeryId
   */
  function toggleAddOrEditModal(surgeryId: string | null = null) {
    showAddOrEditModal.value = !showAddOrEditModal.value
    editSurgeryId.value = surgeryId
  }

  /**
   *
   * @param surgeryId
   */
  function toggleDeleteModal(surgeryId: string | null = null) {
    showDeleteModal.value = !showDeleteModal.value
    deleteSurgeryId.value = showDeleteModal.value ? surgeryId : null
  }

  const editModalInitialData = computed(() => {
    if (editSurgeryId.value && currentSurgeries.value) {
      const surgeryId = editSurgeryId.value
      const surgeryToEdit = currentSurgeries.value[surgeryId]
      return {
        surgeryDate:
          utcDatetimeToDate(stringToDateTime(surgeryToEdit.surgeryDate)) ??
          undefined,
        surgicalProcedureRefId: surgeryToEdit.surgicalProcedureRefId,
        otherSurgeryType: surgeryToEdit.otherSurgeryType,
      }
    }
    return {}
  })

  // fetch 3 more surgery dates
  const loadMore = async () => {
    if (!props.cancerDxId) {
      return
    }
    pageNumber.value = pageNumber.value + 1
    await getPatientSurgeryWithParts(
      props.cancerDxId,
      PAGE_LENGTH,
      pageNumber.value
    )
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  /**
   * reset variables that determine
   * edit/add/delete modal rendering
   */
  function resetModalVars() {
    showAddOrEditModal.value = false
    editSurgeryId.value = null
    showDeleteModal.value = false
    deleteSurgeryId.value = null
  }

  /**
   *
   */
  async function refetchCurrentSurgeries() {
    pageNumber.value = 1
    await getPatientSurgeryWithParts(
      props.cancerDxId,
      PAGE_LENGTH,
      pageNumber.value
    )
    resetModalVars()
  }

  /**
   *
   */
  function onSave() {
    useSurgeryApi().data = {}
    context.emit('refetch')
  }

  onBeforeMount(async () => {
    await getPreviousCancerSurgeries(props.cancerDxId, props.patientId)
  })

  return {
    ModalSize,
    // tabs
    statuses,
    selectedStatus,
    selectStatus,
    showEditOptionDropdown,
    addButtonDisabledMessage,
    addButtonDisabled,
    // display value
    surgeriesByStatus,
    toDateDisplay,
    surgicalProcedureDisplay,
    editOptionsArray,
    // button actions
    close,
    onSave,
    openModal,
    // delete modal
    toggleDeleteModal,
    deleteSurgeryId,
    showDeleteModal,
    // add/edit modal
    toggleAddOrEditModal,
    showAddOrEditModal,
    editSurgeryId,
    editModalInitialData,
    refetchCurrentSurgeries,
    // show more button
    showMoreCurrentSurgeriesButton,
    loadMore,
  }
}
