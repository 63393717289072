<template>
  <div class="columns h-full is-marginless" data-cy="debug-page">
    <div class="column overflow-y-auto bg-nash-neutral000">
      <div>
        <h1>Kitchen Sink</h1>
        <DebugNashvilleVue />
      </div>
      <div>
        <h1 class="border-b border-nash-neutral300 pb-4">Sandbox!</h1>
        <div v-for="(groupedComponents, key) in components" :key="key">
          <h2>{{ key }}</h2>
          <TCollapse
            v-for="component in groupedComponents"
            :key="component.name"
          >
            <template #trigger>{{ component.name }}</template>
            <template #content>
              <SandboxComponent
                :component-name="component.name"
                :extra-props-from="component.extraProps"
                :extra-attrs="component.extraAttrs"
                :slots="component.slots"
              />
            </template>
          </TCollapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import dropdownAttrs from '@/legacy/nashville/dropdown/dropdownDebugAttrs'
import calendarAttrs from '@/legacy/nashville/popups/calendarDebugAttrs'
import TCollapse from '@/legacy/nashville/TCollapse.vue'
import DebugNashvilleVue from '@/legacy/pages/debug/DebugNashville.vue'
import SandboxComponent from './SandboxComponent.vue'

type SandboxComponentOptions = {
  name: string
  extraProps?: string
  extraAttrs?: any
  slots?: string[]
}

export default defineComponent({
  components: {
    DebugNashvilleVue,
    SandboxComponent,
    TCollapse,
  },
  setup() {
    const components: { [key: string]: SandboxComponentOptions[] } = {
      'Admin Table': [{ name: 'TAdminAddField' }, { name: 'TAdminAddRow' }],
      PrimeVue: [
        { name: 'icon/TIcon' },
        { name: 'button/TButton' },
        { name: 'button/TMPrimaryButton', extraProps: 'button/TButton' },
        { name: 'button/TMSecondaryButton', extraProps: 'button/TButton' },
        { name: 'button/TMTertiaryButton', extraProps: 'button/TButton' },
        { name: 'button/TMQuaternaryButton', extraProps: 'button/TButton' },
        { name: 'button/TOButtonGroup' },
        { name: 'checkbox/TCheckbox' },
        { name: 'checkbox/TToggle' },
        { name: 'dropdown/TDropdown', extraAttrs: dropdownAttrs },
        {
          name: 'dropdown/TMActionDropdown',
          extraProps: 'dropdown/TDropdown',
          extraAttrs: dropdownAttrs,
        },
        {
          name: 'dropdown/TMFilterDropdown',
          extraProps: 'dropdown/TDropdown',
          extraAttrs: dropdownAttrs,
        },
        { name: 'popups/TCalendar', extraAttrs: calendarAttrs },
        { name: 'text/TTruncate' },
        { name: 'text/TMarkdown' },
      ],
      Inputs: [
        { name: 'TMAutosaveInput' },
        { name: 'TButton' },
        { name: 'TCheckbox' },
        { name: 'TDatePicker' },
        { name: 'TDialpad' },
        { name: 'LegacyTDropdown' },
        { name: 'TInput' },
        { name: 'TMultiselect' },
        { name: 'TSearch' },
        { name: 'TSelect' },
        { name: 'TToggle' },
        { name: 'TTimePicker' },
        { name: 'TMGroupedInputButtons' },
      ],
      Layout: [
        { name: 'badge/TBadge' },
        { name: 'TChip' },
        { name: 'TCollapse', slots: ['trigger', 'content'] },
        { name: 'TDuration' },
        { name: 'THeading' },
        { name: 'TModal' },
        { name: 'TNotification' },
        { name: 'molecules/TMAddableItem' },
      ],
      Utility: [{ name: 'TIcon' }, { name: 'TLazy' }],
    }
    return {
      components,
    }
  },
})
</script>
