import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientContractsApi } from '@/legacy/store/modules/patientContracts'
import { NotificationType } from '@/legacy/types/notifications'
import { PatientContract } from '@/legacy/types/patients/patientContracts'

/**
 * Retrieve the patient contract for a single patient.
 * Returns null if no contract is found for the patient.
 * Also returns null if more than one contract is found for the patient.
 * @param entityId the patient's entity id
 */
export async function getPatientContract(
  entityId: string
): Promise<PatientContract | null> {
  const patientContractsResponse = await usePatientContractsApi().list({
    params: {
      filter_patient_ids: [entityId],
    },
  })
  if (!patientContractsResponse?.data?.length) {
    return null
  }

  if (patientContractsResponse.data.length > 1) {
    useNotificationStore().setNotification({
      message: 'More than one patient contract found for this patient',
      type: NotificationType.WARNING,
    })
    console.error('More than one patient contract found for patient')
  }

  return patientContractsResponse.data[0]
}
