import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ref } from 'vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { useSocialSummariesApi } from '@/legacy/store/modules/socialSummaries'
import {
  DirtyOffboardingSummary,
  reasonOffboardingOptions,
} from '@/legacy/types/patients/socialSummaries'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const dirtyModel = ref<DirtyOffboardingSummary>({
    reengagementAttemptsDescription:
      props.patientSocialSummary?.reengagementAttemptsDescription ?? '',
    reasonOffboarding: props.patientSocialSummary?.reasonOffboarding ?? '',
    offboardingAdditionalDetails:
      props.patientSocialSummary?.offboardingAdditionalDetails ?? '',
  })

  /**
   * reset dirty values and close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   */
  function createSocialSummaryPayload() {
    return {
      reengagementAttemptsDescription: dirtyModel.value
        .reengagementAttemptsDescription?.length
        ? dirtyModel.value.reengagementAttemptsDescription
        : null,
      reasonOffboarding: dirtyModel.value.reasonOffboarding?.length
        ? dirtyModel.value.reasonOffboarding
        : null,
      offboardingAdditionalDetails: dirtyModel.value
        .offboardingAdditionalDetails?.length
        ? dirtyModel.value.offboardingAdditionalDetails
        : null,
      patientId: props.patientId,
    }
  }

  /**
   *
   */
  async function save() {
    const payload = createSocialSummaryPayload()
    if (!props.patientSocialSummary) {
      await useSocialSummariesApi().create({ body: payload })
    } else {
      await useSocialSummariesApi().partialUpdate({
        body: payload,
        ids: [props.patientSocialSummary.socialSummaryId],
      })
    }
    context.emit('refetchSocialSummary')
    thymeDispatch('care-planV2-update')
    close()
  }

  return {
    close,
    save,
    ModalSize,
    dirtyModel,
    reasonOffboardingOptions,
  }
}
