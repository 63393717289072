<template>
  <TTable
    v-if="patient"
    ref="table"
    name="TransitionsOfCare"
    class="pb-12 px-0"
    sort-field="transitionDate"
    :sort-order="SORT_DESCENDING"
    :rows="10"
    :columns="columns"
    :api-store="useTransitionOfCareApi"
    :params="{
      filter_patient_ids: [patient.entityId],
    }"
  >
    <template #startColumns>
      <Column expander class="w-20" />
    </template>
    <template #column-diagnoses="slotProps">
      <div>
        <span class="italic">Primary: </span>
        {{ slotProps.row.primaryDescription ?? '' }}
        <span v-if="slotProps.row.primaryDiagnosisCode"
          >({{ slotProps.row.primaryDiagnosisCode }})</span
        >
      </div>
      <div v-if="slotProps.row.subsequentDiagnosisCodes">
        <span class="italic">Secondary: </span>
        <span
          v-for="(code, idx) in slotProps.row.subsequentDiagnosisCodes"
          :key="code"
        >
          {{
            idx === slotProps.row.subsequentDiagnosisCodes.length - 1
              ? code
              : code + ', '
          }}
        </span>
      </div>
    </template>
    <template #expansion="slotProps">
      <TTable
        class="childTable"
        name="childTransitionsOfCareTable"
        :columns="childColumns"
        :api-store="
          createTransitionOfCareApi(slotProps.data.transitionOfCareId)
        "
        :params="{
          filter_transition_of_care_ids: slotProps.data.transitionOfCareId,
        }"
      >
        <template #column-facilityName="rowInfo">
          <!-- Facility info -->
          <div class="text-nash-neutral700 text-sm mb-1">Facility</div>
          <div>
            {{ rowInfo.row.facilityName }}
          </div>
          <div v-if="rowInfo.row.facilityCity || rowInfo.row.facilityState">
            {{ rowInfo.row.facilityCity ? rowInfo.row.facilityCity + ',' : '' }}
            {{ rowInfo.row.facilityState ?? '' }}
          </div>
          <div v-if="rowInfo.row.facilityNpi">
            <span class="italic">NPI: </span>
            {{ rowInfo.row.facilityNpi }}
          </div>
          <!-- Attending Info -->
          <br />
          <div class="text-nash-neutral700 text-sm mb-1">Attending</div>
          <div v-if="rowInfo.row.attendingProviderName">
            {{ rowInfo.row.attendingProviderName }}
          </div>
          <div v-if="rowInfo.row.attendingProviderNpi">
            <span class="italic">NPI: </span>
            {{ rowInfo.row.attendingProviderNpi }}
          </div>
        </template>
        <template #column-dischargeDisposition="rowInfo">
          <div
            v-if="
              rowInfo.row.dischargedDisposition || rowInfo.row.dischargeLocation
            "
            class="text-nash-neutral700 text-sm mb-1"
          >
            Discharge Details
          </div>
          <div v-if="rowInfo.row.dischargedDisposition">
            {{ startCase(rowInfo.row.dischargedDisposition) }}
          </div>
          <div v-if="rowInfo.row.dischargeLocation">
            {{ rowInfo.row.dischargeLocation }}
          </div>
          <div v-if="rowInfo.row.lengthOfStayDays">
            <span class="italic">Length of stay: </span>
            {{ rowInfo.row.lengthOfStayDays }}
          </div>
        </template>
      </TTable>
    </template>
  </TTable>
</template>

<script lang="ts">
import Column from 'primevue/column'
import { defineComponent } from 'vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TTable,
    Column,
  },

  setup,
})
</script>
