<template>
  <div v-if="label" class="text-nash-neutral700 font-bold mt-2">
    {{ label }}
  </div>
  <Calendar
    placeholder="mm/dd/yyyy"
    show-icon
    :disabled-dates="disableHolidays ? holidays : undefined"
    :min-date="minDateToday"
    :select-other-months="true"
    :disabled-days="unselectableDaysOfWeek"
    :model-value="modelValue ?? undefined"
    v-bind="$attrs"
    @update:model-value="setDateValue"
  />
  <ErrorMessage :name="name" data-cy="warning" class="text-red-500 text-sm" />
</template>

<script lang="ts">
import Calendar from 'primevue/calendar'
import { ErrorMessage } from 'vee-validate'
import { defineComponent } from 'vue'

import setup from './controller'

export default defineComponent({
  components: { Calendar, ErrorMessage },
  props: {
    modelValue: {
      type: Date,
      default: undefined,
    },
    minDateToday: {
      type: Date,
      default: () => new Date(),
    },
    unselectableDaysOfWeek: {
      type: Array as () => number[],
      default: () => [],
    },
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    rules: { type: String, default: '' },
    disableHolidays: { type: Boolean, default: true },
  },
  emits: ['update:modelValue'],
  setup,
})
</script>
