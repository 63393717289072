<template>
  <TCollapse
    v-if="Object.keys(linkedCommunicationIds).length"
    :is-visible="false"
    :is-left="false"
  >
    <template #trigger>
      <h3 class="mt-4">
        <span class="mr-3">Communication History</span>
        <LegacyTChip :label="`${linkedCommunicationIds.length}`" />
      </h3>
    </template>
    <template #content>
      <div>
        <TMThymeline
          v-slot="{ item }"
          class="comms-history-thymeline"
          :name="`${patientId}-IncompleteCommsHistory`"
          title="Incomplete Linked Communications"
          :rows="perPage"
          :columns="columns"
          :params="
            incompleteHistoryCommsParams(patientId, linkedCommunicationIds)
          "
          :api-store="useIncompleteCommsHistoryThymelineApi"
          :show-rows-per-page="false"
          :show-header-container="false"
          :free-text-filter="false"
        >
          <CommunicationsRow
            class="w-full"
            :communication="item"
            name="communications"
            :patient-id="patientId"
          />
        </TMThymeline>
        <TMThymeline
          v-slot="{ item }"
          class="comms-history-thymeline"
          :name="`${patientId}-CompletedCommsHistory`"
          title="Completed Linked Communications"
          :rows="perPage"
          :columns="columns"
          :params="
            completedCommsHistoryParams(patientId, linkedCommunicationIds)
          "
          :api-store="useCompletedCommsHistoryThymelineApi"
          :show-rows-per-page="false"
          :show-header-container="false"
          :free-text-filter="false"
        >
          <CommunicationsRow
            class="w-full"
            :communication="item"
            name="communications"
            :patient-id="patientId"
          />
        </TMThymeline>
      </div>
    </template>
  </TCollapse>

  <div v-else>
    <h3 class="mt-4 mb-2 border-b border-nash-neutral300">
      Communication History
    </h3>
    <div class="my-2">None documented</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'
import CommunicationsRow from '@/legacy/components/patient/thymeline/CommunicationsRow/CommunicationsRow.vue'
import LegacyTChip from '@/legacy/nashville/LegacyTChip.vue'
import TMThymeline from '@/legacy/nashville/table/TMThymeline.vue'

import TCollapse from '@/legacy/nashville/TCollapse.vue'

import {
  useCompletedCommsHistoryThymelineApi,
  useIncompleteCommsHistoryThymelineApi,
} from '@/legacy/store/modules/thymelines'
import {
  perPage,
  columns,
  completedCommsHistoryParams,
  incompleteHistoryCommsParams,
} from '../thymeline/lib/sharedThymelineParts'

export default defineComponent({
  components: {
    TMThymeline,
    TCollapse,
    LegacyTChip,
    CommunicationsRow,
  },
  props: {
    linkedCommunicationIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup() {
    const route = useRoute()
    const patientId = computed(() => `${route.params.patientId ?? ''}`)

    return {
      completedCommsHistoryParams,
      incompleteHistoryCommsParams,
      patientId,
      useCompletedCommsHistoryThymelineApi,
      useIncompleteCommsHistoryThymelineApi,
      perPage,
      columns,
    }
  },
})
</script>
