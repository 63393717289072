<template>
  <TModal
    is-visible
    :title="currentTitle"
    :size="ModalSize.MD"
    allow-overflow
    @close="closeModal"
  >
    <template #preTitle>
      <LegacyTButton
        v-if="currentFilter"
        name="back-filter-modal"
        icon="chevronLeft"
        type="blackAndWhite"
        :size="ButtonSize.MD"
        @click="setCurrentFilterKey(undefined)"
    /></template>
    <div v-if="currentFilterKey && currentFilter">
      <TOFilterInnerModal
        :filter="currentFilter"
        :data="filterVal(currentFilterKey)"
        @update="updateDirty(currentFilterKey ?? '', $event, currentFilter)"
      />
    </div>
    <div v-else class="gap-3 flex flex-col">
      <TMFilterListButton
        v-for="[filterKey, filter] in Object.entries(filterFields)"
        :key="filterKey"
        :filter="filter"
        :data="filterVal(filterKey)"
        @click="(v) => setCurrentFilterKey(filterKey)"
      />
    </div>
    <template #actions>
      <LegacyTButton value="Save" :name="`${name}-save`" @click="onSave" />
    </template>
  </TModal>
</template>

<script lang="ts">
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Filter, FilterMetaType } from '@thyme/nashville/src/types/tables'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import pickBy from 'lodash/pickBy'
import { computed, defineComponent, PropType, ref } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { updateFilterSet } from '../filterableTableSetup'
import TMFilterListButton from './TMFilterListButton.vue'
import TOFilterInnerModal from './TOFilterInnerModal.vue'

export default defineComponent({
  components: {
    LegacyTButton,
    TModal,
    TOFilterInnerModal,
    TMFilterListButton,
  },
  props: {
    filters: {
      type: Object as PropType<FilterMetaType>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['save', 'close'],
  setup(props, context) {
    const currentFilterKey = ref<string | undefined>()
    const dirtyModel = ref<{ [key: string]: any }>({})
    const currentTitle = computed(
      () => currentFilter.value?.modalOptions?.label ?? 'Filters'
    )
    const currentFilter = computed(() => {
      if (currentFilterKey.value) {
        return filterFields.value[currentFilterKey.value]
      }
      return null
    })

    const filterFields = computed(() =>
      pickBy(props.filters, (f: Filter) => f.modalOptions?.type)
    )

    const updateDirty = (field: string, data: any, filter: Filter) => {
      if (
        filter.modalOptions?.preEnabledOptions?.length &&
        isArray(data) &&
        !data.length
      ) {
        data = filter.modalOptions.preEnabledOptions
      }
      updateFilterSet(field, data, filter, dirtyModel.value)
    }

    const closeModal = () => {
      context.emit('close')
      dirtyModel.value = {}
      setCurrentFilterKey(undefined)
    }
    const onSave = () => {
      context.emit('save', cloneDeep(dirtyModel.value))
      closeModal()
    }

    const setCurrentFilterKey = (filterKey: string | undefined) => {
      currentFilterKey.value = filterKey
    }
    const filterVal = (filterKey: string) => {
      return dirtyModel.value[filterKey] !== undefined
        ? dirtyModel.value[filterKey]
        : props.filters[filterKey].value
    }

    return {
      dirtyModel,
      closeModal,
      filterFields,
      currentTitle,
      currentFilter,
      onSave,
      updateDirty,
      setCurrentFilterKey,
      filterVal,
      currentFilterKey,
      ModalSize,
      ButtonSize,
    }
  },
})
</script>
