import { useInsurancesApi } from '@/legacy/store/modules/insurances'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { NotificationType } from '@/legacy/types/notifications'

/**
 * DELETE insurance entry
 * @param insuranceId
 */
export async function deleteInsurance(insuranceId: string) {
  try {
    await useInsurancesApi().delete({
      ids: [insuranceId],
      metaOptions: { bubbleErrorThrow: true },
    })
    usePatientStore().updateInsurancesInPlace(insuranceId)
    useNotificationStore().setNotification({
      message: 'Successfully deleted insurance.',
      type: NotificationType.SUCCESS,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to delete insurance.',
      type: NotificationType.DANGER,
    })
  }
}
