import type { Nullable, UUID } from '@/legacy/types/global/base'
import { DateTimeUTC, Timestamps } from '@/legacy/types/global/dates'

export enum ConsentType {
  ONBOARDING = 'Onboarding',
  HIPAA_RELEASE = 'HIPAA RELEASE',
  RIGHT_OF_ACCESS = 'Right of Access',
  PROVIDER_SERVICES = 'Provider Services',
  THYME_CARE_SERVICES = 'Thyme Care Services',
}

export enum ConsentStatus {
  FAILED = 'Denied',
  SENT = 'Sent',
  COMPLETED = 'Signed',
}

export enum ConsentSource {
  docusign = 'DocuSign',
  formsort = 'FormSort',
  manual = 'Manually Added',
}

export type FormsortConsent = {
  formResponseId: UUID
}

export type Consent = {
  patientConsentId: UUID
  patientId: UUID
  consentType: ConsentType
  status: ConsentStatus
  consentingRelationship: Nullable<string>
  consentingName: Nullable<string>
  source: ConsentSource
  signedAt: Nullable<DateTimeUTC>
  formsortConsent: Nullable<FormsortConsent>
} & Timestamps

export type UpsertInsuranceRequest = Pick<
  Consent,
  (typeof updateConsentFields)[number]
>

export type UpsertInsuranceResponse = Consent

export interface UpdateConsentForm {
  patientConsentId: UUID
  status: ConsentStatus
  consentingRelationship: Nullable<string>
  consentingName: Nullable<string>
  signedAt: DateTimeUTC
}

export interface AddConsentForm {
  patientConsentId: string
  status: ConsentStatus
  consentingRelationship: Nullable<string>
  consentingName: Nullable<string>
  signedAt: DateTimeUTC | null
}

export const updateConsentFields = [
  'status',
  'consentingRelationship',
  'consentingName',
  'signedAt',
] as const
