<template>
  <div class="mb-5">
    <div v-if="incompleteSubtaskDates?.length">
      Subtasks scheduled for {{ memberName }} on these dates:
      {{ incompleteSubtaskDates.join(', ') }}.
    </div>
    <div v-else>No subtasks scheduled for {{ memberName }}.</div>
  </div>
</template>

<script lang="ts">
import uniq from 'lodash/uniq'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, defineComponent } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'

import { formatDateTime, formatName } from '@/legacy/libs/format'

import { usePatientStore } from '@/legacy/store/modules/patient'
import { useSubtaskApi } from '@/legacy/store/modules/subtasks'
import {
  openSubtaskStatuses,
  Subtask,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'

export default defineComponent({
  setup() {
    const { person } = storeToRefs(usePatientStore())
    const subtaskApi = useSubtaskApi()
    const subtasks = computed(() => Object.values(subtaskApi.data ?? {}))

    const memberName = computed(() =>
      person.value
        ? formatName(person.value.firstName, person.value.lastName)
        : 'Member'
    )

    const incompleteSubtaskDates = computed(() => {
      if (subtasks.value) {
        return uniq(
          subtasks.value
            .filter(
              (subtask: Subtask) =>
                openSubtaskStatuses.includes(subtask.status as SubtaskStatus) &&
                subtask.dueDatetime
            )
            .map((subtask: Subtask) =>
              formatDateTime(
                stringToDateTime(subtask.dueDatetime),
                DateTime.DATE_SHORT
              )
            )
        )
      }
      return []
    })

    return {
      incompleteSubtaskDates,
      memberName,
    }
  },
})
</script>
