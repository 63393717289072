<template>
  <div class="flex flex-col p-3">
    <div
      class="flex justify-between items-center w-full border-b border-nash-neutral500 my-2"
    >
      <h4>Medical Details</h4>
      <TMTertiaryButton icon="pencil" @click="toggleEditMedicalDetailsModal" />
    </div>
    <div class="flex flex-col space-y-0.5">
      <p>
        <span class="italic">Practice:</span>
        {{ patient.alignedPractice ? patient.alignedPractice : 'Unaligned' }}
      </p>
      <p>
        <span class="italic">Provider:</span>
        {{ formatProviderName(patient.alignedProvider) }}
      </p>
    </div>
    <EditMedicalDetails
      :is-visible="showEditMedicalDetails"
      @close="toggleEditMedicalDetailsModal"
    />
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import kebabCase from 'lodash/kebabCase'
import { defineComponent, PropType, ref } from 'vue'
import { AonPracticesToProviders } from '@/legacy/types/aonProviders'
import { NormalizedPatient } from '@/legacy/types/patients/patients'
import { ProvidersToPractice } from '@/legacy/types/providers'
import EditMedicalDetails from './modals/EditMedicalDetails/EditMedicalDetails.vue'

export default defineComponent({
  components: {
    EditMedicalDetails,
    TMTertiaryButton,
  },
  props: {
    patient: {
      type: Object as PropType<NormalizedPatient>,
      required: true,
    },
  },
  setup() {
    const showEditMedicalDetails = ref(false)

    /**
     * Function to show/hide EditMedicalDetails modal
     */
    function toggleEditMedicalDetailsModal() {
      showEditMedicalDetails.value = !showEditMedicalDetails.value
    }

    /**
     *
     */
    function getAONProviders() {
      const aonProviders = AonPracticesToProviders.map(
        (set: ProvidersToPractice) => set.providers
      ).flat()

      return new Set(aonProviders)
    }

    /**
     * Given the provider name, return the
     * formatted provider name
     *
     * Ex: Provider is not an AON Provider
     *      "First Last" -> "First Last, MD"
     *      null -> "N/A"
     *
     * Ex: Provider is an AON Provider
     *      "First Last, MD" -> "First Last, MD"
     *      "First Last, RN, NP" -> "First Last, RN, NP"
     *      null -> "N/A"
     * @param provider
     */
    function formatProviderName(provider: string | null) {
      const mdTitle = ', MD'
      const notAvailable = 'N/A'
      const aonProviders = getAONProviders()

      if (!provider) {
        return notAvailable
      }

      if (aonProviders.has(provider)) {
        return provider
      } else {
        return provider + mdTitle
      }
    }

    return {
      toggleEditMedicalDetailsModal,
      showEditMedicalDetails,
      kebabCase,
      formatProviderName,
    }
  },
})
</script>
