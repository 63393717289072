<template>
  <div>
    <h4
      v-if="subtask.autogeneratedNotes?.length"
      class="my-4 pt-4 border-t border-nash-neutral300"
    >
      Automated Notes
    </h4>
    <div
      v-for="autogenNote in subtask.autogeneratedNotes"
      :key="autogenNote.noteId"
      class="my-2 pre-formatted"
    >
      <TMarkdown :source="autogenNote.description" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import { Subtask } from '@/legacy/types/pathways/subtasks'

export default defineComponent({
  components: {
    TMarkdown,
  },
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
  },
})
</script>
<style>
.pre-formatted {
  white-space: pre-wrap;
}
</style>
