<template>
  <Message
    :closable="closable"
    :class="messageClasses"
    :severity="severity"
    :data-cy="name && `${kebabCase(name)}-message`"
    v-bind="$attrs"
  >
    <template #messageicon>
      <TIcon
        v-if="customIcon"
        v-bind="$attrs"
        :icon="customIcon"
        :icon-pos="iconPos"
        :color="customIconFill"
        :icon-classes="iconClasses"
      />
    </template>
    <span v-if="label" class="message-label">
      {{ label }}<slot name="extracontent" />
    </span>
  </Message>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import {
  iconPositionProp,
  AllowedIcons,
} from '@thyme/nashville/src/types/icons'
import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import {
  paddingProp,
  actionProp,
  ColorPalettes,
} from '@thyme/nashville/src/types/shared'
import kebabCase from 'lodash/kebabCase'
import Message from 'primevue/message'
import { computed, defineComponent, PropType } from 'vue'
import { createProp, tokenizedComponentClasses } from '../designTokens'

const sharedProps = {
  ...paddingProp,
  ...actionProp,
}

export default defineComponent({
  components: {
    Message,
    TIcon,
  },
  props: {
    ...sharedProps,
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    severity: {
      type: String as PropType<MessageSeverity>,
      default: MessageSeverity.INFO,
    },
    customIcon: createProp(AllowedIcons),
    customIconFill: createProp(ColorPalettes),
    ...iconPositionProp,
  },
  emits: ['click'],
  setup(props) {
    const tokenClasses = tokenizedComponentClasses(
      'message',
      props,
      Object.keys(sharedProps)
    )
    const messageClasses = computed(() => [
      ...tokenClasses.value,
      props.expanded ? 'w-full space-x-2' : '',
      !props.label ? 'p-message-icon-only' : '',
    ])

    // Ported from PV to customize icon v3.21.0
    const iconClasses = computed(() => ['p-message-icon'])

    return {
      messageClasses,
      iconClasses,
      kebabCase,
    }
  },
})
</script>
