import { IdMap } from '@/legacy/types/api/store'
import { Address } from '@/legacy/types/entities/addresses'
import { Person } from '@/legacy/types/entities/people'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { Timestamps } from '@/legacy/types/global/dates'
import { Nullable } from '../global/base'

// CONSTANTS ----------------

export const SYSTEM_USER_UUID = '00000000-2499-410d-85de-8baf9b92c245'

// TYPES --------------------
export type StaffMap = IdMap<Entity>

export type Label = {
  entityId: string
  labelId: string
  labelRefId: string
} & Timestamps

export type LabelReference = {
  labelRefId: string
  description: string
} & Timestamps

export type ApiEntityRole = {
  entityId: string
  role: string
} & Timestamps

export type Entity = {
  entityId: string
  email: string

  person?: Person
  phoneNumbers?: MapEntityPhoneNumber[]
  addresses?: Address[]
  roles?: ApiEntityRole[]
  userRoles?: string[]
} & Timestamps

export type ContactEntityWithRelationship = {
  relationshipToPatient: Nullable<string>
} & Entity

export type Email = {
  email: string
}

export type CreateEntityResponse = Entity

export type UserForm = {
  firstName: string
  lastName: string
  email: string
  role: EntityRole
}
// ENUMS --------------------
export enum EntityRoleSelectType {
  Dropdown = 'dropdown',
  PVDropdown = 'PVDropdown',
  Radio = 'radio',
}

export enum EntityRole {
  TECH_TEAM = 'tech_team',
  ADMIN = 'admin',
  CLINICAL = 'clinical',
  CLINICAL__CARE_PARTNER = 'clinical__care_partner',
  CLINICAL__NURSE = 'clinical__nurse',
  CLINICAL__ENROLLMENT_SPECIALIST = 'clinical__enrollment',
  CLINICAL__ENROLLMENT_SPECIALIST_LEAD = 'clinical__enrollment_lead',
  CLINICAL__CARE_POD_LEAD = 'clinical__care_pod_lead',
  CLINICAL__ADMIN_ASSISTANT = 'clinical__admin_assistant',
  CLINICAL__MEDICAL_DIRECTOR = 'clinical__medical_director',
  CLINICAL__TIERING_NP = 'clinical__tiering_np',
  CLINICAL__LIAISON = 'clinical__liaison',
  STAFF = 'staff',
  SYSTEM = 'system',
  PATIENT = 'patient',
  CONTACT = 'contact',
  CARE_TEAM_LEADERSHIP = 'care_team_leadership',
  SPECIALIZATION__ONBOARDING_RN = 'specialization__onboarding_rn',
  SPECIALIZATION__ONBOARDING_CP = 'specialization__onboarding_cp',
  SPECIALIZATION_MEDICAL_RECORDS_AA = 'specialization__medical_records_aa',
  SPECIALIZATION__TRIAGE_RN = 'specialization__triage_rn',
  SPECIALIZATION__SERIOUS_ILLNESS_CONVERSATION_RN = 'specialization__serious_illness_conversation_rn',
  SPECIALIZATION__SERIOUS_ILLNESS_CONVERSATION_CP = 'specialization__serious_illness_conversation_cp',
  SPECIALIZATION__BEHAVIORAL_HEALTH_LEAD = 'specialization__behavioral_health_lead',
  SPECIALIZATION__BEHAVIORAL_HEALTH_CP = 'specialization__behavioral_health_cp',
  SPECIALIZATION__ADVANCED_SOCIAL_NEEDS_CP = 'specialization__advanced_social_needs_cp',
  SPECIALIZATION__ADVANCED_SOCIAL_NEEDS_SW = 'specialization__advanced_social_needs_sw',
  SPECIALIZATION__SITE_OF_SERVICE_SHIFT_CP = 'specialization__site_of_service_shift_cp',
  SPECIALIZATION__SITE_OF_SERVICE_SHIFT_RN = 'specialization__site_of_service_shift_rn',
  SPECIALIZATION__TRANSITIONS_OF_CARE_CP = 'specialization__transitions_of_care_cp',
  SPECIALIZATION__TRANSITIONS_OF_CARE_RN = 'specialization__transitions_of_care_rn',
  // Care Team support roles
  QUALITY_TRAINING_ENABLEMENT = 'quality_training_enablement',
  CONTENT_MANAGER = 'content_manager',
  TCOP_PARTNERSHIP_OPS = 'tcop_partnership_ops',
}

export enum USState {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export const createEntityFields = [
  'person',
  'phoneNumbers',
  'addresses',
] as const
export type CreateEntityRequest = Pick<
  Entity,
  (typeof createEntityFields)[number]
>
