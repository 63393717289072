<template>
  <div class="card-style w-full">
    <ClinicalSummary
      v-if="!showNewClinicalSummary"
      :mode="mode"
      :care-plan-summary="carePlanSummary"
      @on-save="submitUpdate"
    />
    <SocialSummary
      v-if="!showSocialSummary"
      :mode="mode"
      :care-plan-summary="carePlanSummary"
      @on-save="submitUpdate"
    />
    <OffboardingSummary
      v-if="!showSocialSummary"
      :mode="mode"
      :care-plan-summary="carePlanSummary"
      @on-save="submitUpdate"
    />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { useCarePlanSummaryApi } from '@/legacy/store/modules/carePlanSummaries'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { SaveState } from '@/legacy/types/api/api'
import { NotificationType } from '@/legacy/types/notifications'
import {
  CarePlanSummary,
  CaseConferenceMode,
} from '@/legacy/types/patients/carePlanSummaries'
import ClinicalSummary from './ClinicalSummary.vue'
import OffboardingSummary from './OffboardingSummary.vue'
import SocialSummary from './SocialSummary.vue'

export default defineComponent({
  components: {
    ClinicalSummary,
    OffboardingSummary,
    SocialSummary,
  },
  props: {
    mode: {
      type: String as PropType<CaseConferenceMode>,
      required: true,
    },
    carePlanSummary: {
      type: Object as PropType<CarePlanSummary | null>,
      default: null,
    },
  },
  setup(props) {
    const { showSocialSummary, showClinicalSummary: showNewClinicalSummary } =
      storeToRefs(useFlagStore())
    const summary = computed(() => props.carePlanSummary)
    const route = useRoute()

    const submitUpdate = async ({
      keyName,
      updateString,
      fieldName,
      saveState,
    }: {
      keyName: string
      updateString: string
      fieldName: string
      saveState?: SaveState
    }) => {
      if (!summary.value) {
        useNotificationStore().setNotification({
          message: 'Unknown summary',
          type: NotificationType.DANGER,
        })
        return
      }

      const body = { [keyName]: updateString }

      try {
        await useCarePlanSummaryApi().partialUpdate({
          body,
          ids: [summary.value.carePlanSummaryId],
          metaOptions: { saveState: saveState },
        })
        if (useCarePlanSummaryApi().error) {
          throw useCarePlanSummaryApi().error
        }
      } catch (err) {
        if (!saveState) {
          useNotificationStore().setNotification({
            message: `Failed to update ${fieldName}.`,
            type: NotificationType.DANGER,
          })
        }
        console.error(err)
        return
      }
      if (!saveState) {
        useNotificationStore().setNotification({
          message: `Successfully updated ${fieldName}.`,
          type: NotificationType.SUCCESS,
        })
      }
      // refetch values for last updated section under care plans
      // when a summary is updated
      thymeDispatch('care-plan-update')
    }

    return {
      showSocialSummary,
      showNewClinicalSummary,
      summary,
      route,
      submitUpdate,
    }
  },
})
</script>
