<template>
  <TMAddableItem :active="active" @select="selectMedicalCondition">
    <div class="flex flex-row items-center">
      <h5>
        {{ medicalConditionReference.description }}
      </h5>
      <TIcon
        v-if="medicalConditionReference.isSensitive"
        icon="sensitive"
        size="sm"
        class="ml-3"
        color="nash-tigerlily600"
      />
    </div>
    <template #after>
      <div v-if="showActiveFields" class="space-y-4 mt-4 max-w-sm">
        <TMDropdownField
          v-if="medicalConditionReference.attributeOptions"
          name="conditionSpecificAttributes"
          class="w-1/2 my-2 bg-nash-neutral000"
          option-label="label"
          option-value="value"
          :multiple="true"
          :options="medicalConditionReference.attributeOptions"
          :model-value="selectedAttributes"
          @update:model-value="setSelectedAttributes"
        />
        <div
          v-if="medicalConditionReference.description === 'Other'"
          class="text-nash-neutral600"
        >
          <TInputText
            placeholder="Other medical condition"
            class="max-w-sm"
            name="other-medical-condition"
            :model-value="otherMedicalCondition ?? ''"
            @update:model-value="setOtherMedicalCondition"
          />
        </div>
      </div>
    </template>
  </TMAddableItem>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TMAddableItem from '@/legacy/nashville/molecules/TMAddableItem.vue'
import { setup } from './controller'
import { addMedicalConditionInnerProps } from './types'

export default defineComponent({
  components: { TIcon, TMDropdownField, TMAddableItem, TInputText },
  props: addMedicalConditionInnerProps,
  emits: ['add', 'remove', 'setMedicalConditionPayload'],
  setup,
})
</script>
