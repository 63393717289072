<template>
  <div class="mb-10 relative">
    <TSpinner
      v-if="isLoadingComm"
      name="unplanned-call-sidebar"
      :partial-page="true"
    />
    <div class="mb-5 mt-5 w-full">
      <h2
        data-cy="unplanned-call-details-header"
        class="border-nash-neutral300 flex items-center content-center"
      >
        Outbound call with
      </h2>
      <div
        class="inline-flex items-center content-center w-full justify-between"
      >
        <CommunicationCall :communication="dirtyCommunication" />
        <div
          v-if="dirtyCommunication.communicationId"
          class="inline-flex is-pulled-right gap-2"
        >
          <TMSecondaryButton
            name="delete-unplanned-call"
            icon="trash"
            @click="deleteComm"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="divide-y divide-nash-neutral400 pb-6">
        <div class="flex items-center gap-6 py-4">
          <span class="w-60 font-bold text-nash-neutral700">Assigned to</span>
          <TOStaffAssignDropdown
            :model-value="responsible"
            class="grow"
            name="unplanned-call-assigned-to"
            :show-clear="false"
            :disabled="true"
          />
        </div>
      </div>

      <StaffReminders />

      <NotesSection
        :dirty-communication="dirtyCommunication"
        :save-state="commSaveState"
        @create-or-update="updateComm"
        @update-notes-for-dirty-comm="updateNotesForDirtyComm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent, PropType } from 'vue'
import NotesSection from '@/legacy/components/NotesSection.vue'
import CommunicationCall from '@/legacy/components/patient/communicationV2/sidebar/calls/CommunicationCall/CommunicationCall.vue'
import StaffReminders from '@/legacy/components/patient/StaffReminders.vue'
import TOStaffAssignDropdown from '@/legacy/nashville/dropdown/TOStaffAssignDropdown.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { SaveState } from '@/legacy/types/api/api'
import setup from './controller'

export default defineComponent({
  components: {
    TSpinner,
    NotesSection,
    TMSecondaryButton,
    CommunicationCall,
    TOStaffAssignDropdown,
    StaffReminders,
  },
  props: {
    commSaveState: {
      type: Object as PropType<SaveState>,
      required: true,
    },
  },
  setup,
})
</script>
