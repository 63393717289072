<template>
  <TModal
    :flex="true"
    :is-visible="isVisible"
    :allow-overflow="true"
    :size="ModalSize.LG"
    @close="close"
  >
    <div class="flex justify-between items-center -m-2 mb-4">
      <div class="flex items-center">
        <LegacyTButton
          v-if="currentTask"
          name="back-add-task-subtasks"
          icon="chevronLeft"
          type="blackAndWhite"
          :size="ButtonSize.MD"
          @click="currentTask = undefined"
        />
        <h2 class="mt-1 ml-2">{{ currentTitle }}</h2>
      </div>
      <LegacyTButton
        name="close-add-task"
        icon="close"
        inline
        type="blackAndWhite"
        :size="ButtonSize.MD"
        @click="close"
      />
    </div>
    <TSearchAndFilter
      v-if="!currentTask"
      :search-placeholder="`Search ${capitalize(type)}...`"
      :filter-options="[]"
      class="text-nash-neutral900 focus:nash-purple500"
      @update-search="updateSearch"
    />
    <div v-if="type === TaskType.Task" class="overflow-auto space-y-4 h-full">
      <div v-if="!dataLoaded">Loading...</div>
      <div v-else-if="!currentTask" class="overflow-auto space-y-4 h-full">
        <AddTaskInner
          v-for="taskTemplate in filteredTasks"
          :key="taskTemplate.taskTemplateId"
          :task-template="taskTemplate"
          :added-task-obj="foundAddedTaskObj(taskTemplate.variantId)"
          @add="addTask"
          @remove="removeTask"
          @set-task-payload="setTaskPayload"
          @set-current-task="setCurrentTask"
        />
        <TMTertiaryButton
          v-if="
            filteredTasks &&
            totalTaskTemplates &&
            totalTaskTemplates > filteredTasks?.length
          "
          label="Load more"
          @click="loadMore"
        />
      </div>
      <div v-else class="space-y-4">
        <div v-if="areChildSubtasksLoading">Loading...</div>
        <div v-else-if="!childSubtaskTemplates?.length">No Subtasks</div>
        <div
          v-for="subtaskTemplate in childSubtaskTemplates"
          :key="subtaskTemplate?.variantId"
          class="subtask-template-item-wrapper"
        >
          <h4>
            {{ subtaskTemplate.title }}
            {{
              subtaskTemplate.variantDisplay
                ? `${subtaskTemplate.variantDisplay}`
                : ''
            }}
          </h4>
          <div class="text-sm py-2">
            <TMarkdown :source="subtaskTemplate.description" />
          </div>
          <div class="flex gap-3 mt-1 text-nash-neutral700">
            <div
              v-if="!hideSubtaskEstimatedCompletionTime"
              class="flex items-center space-x-1 gap-1"
            >
              {{ subtaskTemplate.estimatedMinutesToComplete ?? 0 }} min
            </div>
            <div
              v-if="!hideSubtaskEstimatedCompletionTime"
              class="bullet"
            ></div>
            <div
              v-if="subtaskTemplate.responsibleRole"
              class="flex items-center space-x-1 gap-1"
            >
              <div>{{ humanizeRole(subtaskTemplate.responsibleRole) }}</div>
            </div>
            <div
              v-if="!hideSubtaskEstimatedCompletionTime"
              class="bullet"
            ></div>
            <div class="flex items-center space-x-1 gap-1">
              <TIcon
                :icon="getPriorityIcon(subtaskTemplate.priority)"
                color="neutral500"
              />
              <div>
                {{ capitalize(subtaskTemplate.priority.toLowerCase()) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="type === TaskType.Subtask"
      class="overflow-auto space-y-4 h-full"
    >
      <div v-if="!dataLoaded">Loading...</div>
      <AddSubtaskInner
        v-for="subtaskTemplate in filteredSubtasks"
        :key="subtaskTemplate.subtaskTemplateId"
        :subtask-template="subtaskTemplate"
        @add="addTask"
        @remove="removeTask"
        @set-subtask-payload="setTaskPayload"
      />
      <TMTertiaryButton
        v-if="
          filteredSubtasks &&
          totalSubtaskTemplates &&
          totalSubtaskTemplates > filteredSubtasks?.length
        "
        label="Load more"
        @click="loadMore"
      />
    </div>
    <div v-else class="text-nash-brick600">
      Please configure with a proper type. Accepted types: {{ TaskType }}
    </div>
    <template #actions>
      <LegacyTButton type="button" value="Cancel" @click="close" />
      <LegacyTButton
        value="Save"
        :disabled="
          !addedTaskTemplateIds.length ||
          addedTaskTemplateIds.length !== addedTaskInfo.length ||
          dataIsLoading
        "
        @click="onSave"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import remove from 'lodash/remove'
import { storeToRefs } from 'pinia'
import { defineComponent, computed, ref, watch, PropType } from 'vue'
import { useRoute } from 'vue-router'
import { humanizeRole } from '@/legacy/libs/entity'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useSubtasksStore,
  useSubtaskTemplateApi,
  useSubtaskTemplatesByIdApi,
} from '@/legacy/store/modules/subtasks'
import { useTasksStore, useTaskTemplateApi } from '@/legacy/store/modules/tasks'

import {
  CreateTaskInfo,
  TaskTemplate,
  TaskType,
} from '@/legacy/types/pathways/tasks'
import AddSubtaskInner from './AddSubtaskInner.vue'
import AddTaskInner from './AddTaskInner.vue'
import { getPriorityIcon } from './lib/subtask'

// CONST
const DEBOUNCE_INTERVAL_MS = 200

export default defineComponent({
  components: {
    TMTertiaryButton,
    TIcon,
    TSearchAndFilter,
    TModal,
    AddTaskInner,
    AddSubtaskInner,
    LegacyTButton,
    TMarkdown,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    taskId: {
      type: String,
      default: null,
    },
    pathwayId: {
      type: String,
      default: null,
    },
    goalId: {
      type: String,
      default: null,
    },
    communicationIdToLink: {
      type: String as PropType<string | null>,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'saveAdd', 'saveAddWithCommLink', 'mapTasksToGoal'],
  setup(props, context) {
    const subtaskTemplateApi = useSubtaskTemplateApi()
    const subtaskTemplateByIdApi = useSubtaskTemplatesByIdApi()
    const taskTemplateApi = useTaskTemplateApi()
    const subtasksStore = useSubtasksStore()
    const tasksStore = useTasksStore()
    const { data: taskTemplatesData, isLoading: areTasksLoading } =
      storeToRefs(taskTemplateApi)
    const { data: subtaskTemplatesData, isLoading: areSubtasksLoading } =
      storeToRefs(subtaskTemplateApi)
    const {
      data: subtaskTemplatesByIdData,
      isLoading: areChildSubtasksLoading,
    } = storeToRefs(subtaskTemplateByIdApi)

    const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())

    const taskTemplates = computed(() =>
      Object.values(taskTemplatesData.value ?? {})
    )

    const subtaskTemplates = computed(() =>
      Object.values(subtaskTemplatesData.value ?? {})
    )

    const childSubtaskTemplates = computed(() =>
      Object.values(subtaskTemplatesByIdData.value ?? {})
    )

    const route = useRoute()

    const totalSubtaskTemplates = computed(
      () => subtaskTemplateApi.queryMetadata?.total
    )

    const dataIsLoading = computed(() =>
      props.type === 'task' ? areTasksLoading.value : areSubtasksLoading.value
    )

    const totalTaskTemplates = computed(
      () => taskTemplateApi.queryMetadata?.total
    )

    const currentTitle = computed(() =>
      props.title
        ? props.title
        : currentTask.value?.title ?? `Add ${capitalize(props.type)}`
    )

    const parentPathwayId = computed(
      () => props.pathwayId ?? route.query.pathwayId
    )

    const dataLoaded = computed(() => {
      if (props.type === 'tasks') {
        return taskTemplateApi.isLoaded
      }
      return subtaskTemplateApi.isLoaded
    })

    const currentSearch = ref('')

    //Encompasses both tasks and subtasks
    const addedTaskTemplateIds = ref<string[]>([])
    const addedTaskInfo = ref<CreateTaskInfo[]>([])
    const perPage = 10
    const pageNumber = ref(1)

    const currentTask = ref<TaskTemplate | undefined>()

    const addTask = (taskTemplateIds: string) => {
      if (!addedTaskTemplateIds.value.includes(taskTemplateIds)) {
        addedTaskTemplateIds.value.push(taskTemplateIds)
      }
    }

    const foundAddedTaskObj = (variantId: string) => {
      if (addedTaskTemplateIds.value.length && addedTaskInfo.value.length) {
        return addedTaskInfo.value.find(
          (taskInfo: CreateTaskInfo) => taskInfo.variantId === variantId
        )
      }
    }

    const removeTask = (taskTemplateId: string) => {
      remove(addedTaskTemplateIds.value, (v) => v === taskTemplateId)
      remove(addedTaskInfo.value, (v) => v.variantId === taskTemplateId)
    }

    const setTaskPayload = (taskTemplateIdAndDueDatetime: CreateTaskInfo) => {
      const match = addedTaskInfo.value.find(
        ({ variantId }: CreateTaskInfo) =>
          variantId === taskTemplateIdAndDueDatetime.variantId
      )
      if (match) {
        const index = addedTaskInfo.value.indexOf(match)
        if (index !== -1) {
          addedTaskInfo.value[index] = taskTemplateIdAndDueDatetime
        }
      } else {
        addedTaskInfo.value.push(taskTemplateIdAndDueDatetime)
      }
    }

    const setCurrentTask = (task?: TaskTemplate) => {
      currentTask.value = task
      if (addedTaskInfo.value.length !== addedTaskTemplateIds.value.length) {
        const unclearedIds = addedTaskTemplateIds.value.filter((id: string) =>
          addedTaskInfo.value.every((obj) => obj.variantId !== id)
        )
        if (unclearedIds.length) {
          unclearedIds.forEach((unclearedId: string) => {
            remove(addedTaskTemplateIds.value, (id) => id === unclearedId)
          })
        }
      }
    }

    const debouncedGetSubtaskTemplates = debounce(
      subtasksStore.getSubtaskTemplates,
      DEBOUNCE_INTERVAL_MS
    )
    const debouncedGetTaskTemplates = debounce(
      tasksStore.getTaskTemplates,
      DEBOUNCE_INTERVAL_MS
    )

    watch(currentSearch, async (text: string) => {
      pageNumber.value = 1
      if (props.type === 'tasks') {
        useTaskTemplateApi().reset()
        await debouncedGetTaskTemplates({
          page_length: perPage,
          filter_free_text: text,
          filter_manually_addable: true,
          page_number: pageNumber.value,
        })
      } else {
        useSubtaskTemplateApi().reset()
        await debouncedGetSubtaskTemplates({
          page_length: perPage,
          filter_free_text: text,
          filter_manually_addable: true,
          page_number: pageNumber.value,
        })
      }
    })

    watch(props, async () => {
      if (props.isVisible) {
        if (props.type === 'tasks') {
          taskTemplateApi.reset()
          await tasksStore.getTaskTemplates({
            page_length: perPage,
            filter_free_text: '',
            filter_manually_addable: true,
            page_number: pageNumber.value,
          })
        } else if (props.type === 'subtasks') {
          await subtasksStore.getSubtaskTemplates({
            page_length: perPage,
            filter_free_text: '',
            filter_manually_addable: true,
            page_number: pageNumber.value,
          })
        }
      }
    })

    watch(currentTask, async () => {
      if (currentTask.value) {
        const subtaskVariantIds = currentTask.value?.subtaskTemplateMaps?.map(
          (subtaskTemplateMap) => subtaskTemplateMap.subtaskVariantId
        )
        if (subtaskVariantIds && subtaskVariantIds.length) {
          await subtasksStore.getSubtaskTemplatesById({
            filter_variant_ids: subtaskVariantIds,
          })
        }
      }
    })

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      if (props.type === 'tasks') {
        await tasksStore.getTaskTemplates({
          page_length: perPage,
          filter_free_text: currentSearch.value,
          filter_manually_addable: true,
          page_number: pageNumber.value,
        })
      } else if (props.type === 'subtasks') {
        await subtasksStore.getSubtaskTemplates({
          page_length: perPage,
          filter_free_text: currentSearch.value,
          filter_manually_addable: true,
          page_number: pageNumber.value,
        })
      }
    }

    const close = () => {
      context.emit('close')
      addedTaskTemplateIds.value = []
      addedTaskInfo.value = []
      pageNumber.value = 1
      currentSearch.value = ''
      currentTask.value = undefined
    }

    const onSave = async () => {
      if (props.communicationIdToLink) {
        await saveAddWithCommLink()
      } else {
        await saveAdd()
      }
    }
    const saveAdd = async () => {
      if (props.type === TaskType.Task) {
        const createdTasks = await tasksStore.createTasks(
          `${parentPathwayId.value}`,
          addedTaskInfo.value
        )
        if (props.goalId) {
          const createdTaskIds = createdTasks?.map((task) => task.taskId)
          // pass taskIds to map the evergreen tasks to the goal it was created from
          context.emit('mapTasksToGoal', createdTaskIds)
        }
      }
      if (props.type === TaskType.Subtask) {
        await subtasksStore.createSubtasks(props.taskId, addedTaskInfo.value)
      }

      thymeDispatch('thymeline-update')

      close()
    }

    const saveAddWithCommLink = async () => {
      const tasksToCreate = addedTaskInfo.value.map((taskInfo) => {
        return {
          pathwayId: parentPathwayId.value,
          taskVariantId: taskInfo.variantId,
          dueDatetime: taskInfo.dueDatetime,
          priority: taskInfo.priority,
          ...(taskInfo.customSubject
            ? { customSubject: taskInfo.customSubject }
            : {}),
        }
      })
      context.emit('saveAddWithCommLink', tasksToCreate)
      close()
    }

    const updateSearch = (newSearch: string) =>
      (currentSearch.value = newSearch ?? '')

    const filteredSubtasks = computed(() => {
      if (props.type === TaskType.Subtask) {
        return Object.values(subtaskTemplates.value ?? {}).sort((a, b) =>
          a.title.localeCompare(b.title)
        )
      }
      return undefined
    })

    const filteredTasks = computed(() => {
      if (props.type === TaskType.Task) {
        return Object.values(taskTemplates.value ?? {}).sort((a, b) =>
          a.title.localeCompare(b.title)
        )
      }
      return undefined
    })

    return {
      ModalSize,
      ButtonSize,
      foundAddedTaskObj,
      getPriorityIcon,
      totalTaskTemplates,
      dataIsLoading,
      totalSubtaskTemplates,
      addedTaskInfo,
      setTaskPayload,
      onSave,
      saveAdd,
      saveAddWithCommLink,
      currentSearch,
      filteredSubtasks,
      filteredTasks,
      subtaskTemplates,
      taskTemplates,
      TaskType,
      addedTaskTemplateIds,
      addTask,
      removeTask,
      capitalize,
      close,
      remove,
      updateSearch,
      dataLoaded,
      loadMore,
      currentTask,
      currentTitle,
      setCurrentTask,
      humanizeRole,
      areChildSubtasksLoading,
      childSubtaskTemplates,
      parentPathwayId,
      hideSubtaskEstimatedCompletionTime,
    }
  },
})
</script>
<style lang="scss" scoped>
.subtask-template-item-wrapper {
  @apply border border-nash-neutral400  p-4 rounded-lg;
}
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
