import { DateTime, Interval } from 'luxon'
import { unselectableDaysOfWeek } from '@/legacy/types/global/dates'

const MILLISECONDS_PER_SECOND = 1000

export function stringToDateTime(s: string): DateTime
export function stringToDateTime(s: undefined): undefined
export function stringToDateTime(s: string | undefined): DateTime | undefined
/**
 *
 * @param s
 */
export function stringToDateTime(s: any): any {
  if (s) {
    return DateTime.fromISO(s, { zone: 'utc' })
  }
}

export function dateTimeToString(d: DateTime): string
export function dateTimeToString(d: undefined): undefined
export function dateTimeToString(d: DateTime | undefined): string | undefined
/**
 *
 * @param d
 */
export function dateTimeToString(d: any): any {
  if (d) {
    return d.toISO()
  }
}

/**
 *
 * @param d
 */
export function jsDateTimeToString(d: Date): string {
  return dateTimeToString(DateTime.fromJSDate(d))
}

/**
 *
 * @param d
 */
export function jsDateToString(d: Date): string {
  return DateTime.fromJSDate(d).toSQLDate() as string
}

/**
 *
 * @param s
 */
export function stringTojsDateTime(s: string): Date {
  return stringToDateTime(s).toJSDate()
}

export function sqlDateToLuxonDateTime(d: string): DateTime
export function sqlDateToLuxonDateTime(d: undefined): undefined
export function sqlDateToLuxonDateTime(
  d: string | undefined
): DateTime | undefined
/**
 *
 * @param d
 */
export function sqlDateToLuxonDateTime(d: any): any {
  if (d) {
    return DateTime.fromSQL(d)
  }
}
/**
 *
 * @param d
 */
export function sqlDateToFormattedDate(d: string): string {
  return sqlDateToLuxonDateTime(d).toLocaleString(DateTime.DATE_SHORT)
}

/*
 * Pull off the year, month, and day from a luxon DateTime
 * to create a standard JS date with the same date. Since
 * JS dates use a timezone offset (e.g. -0700),
 * there can otherwise be an off-by-one error when
 * only looking at date and considering a UTC date object
 * when in another tz.
 *
 * e.g. 'Thu Apr 08 1992 17:00:00 GMT-0700' is the JS
 * representation of 4/9/92 (UTC) in Pacific Time. In a datepicker,
 * this should be 4/9/92, not 4/8/92
 */
/**
 *
 * @param d DateTime or null
 */
export function utcDatetimeToDate(d: DateTime | null) {
  return d ? new Date(d.year, d.month - 1, d.day) : null
}

/**
 * convert ISO string with timezone into
 * ISO string in UTC
 * @param iso
 * @returns string UTC ISO
 */
export function ISOToUTC(iso: string | null | undefined) {
  if (iso) {
    return DateTime.fromISO(maybeAddTimezone(iso), { setZone: true })
      .toUTC()
      .toISO()
  }
  console.error('expected ISO string but received null or undefined')
  return
}

// luxon Datetime
/**
 *
 * @param a
 * @param b
 */
export function compareNullableDatetimes(
  a: DateTime | undefined,
  b: DateTime | undefined
) {
  if (!b) {
    return -1
  }
  if (!a) {
    return 1
  }
  return a.toMillis() - b.toMillis()
}

/**
 *
 * @param d
 */
export function toSQLDate(d: Date | undefined) {
  return d ? `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}` : ''
}

export const getBetweenWeekendDayCount = (startDate: Date, endDate: Date) => {
  // This function counts and returns how many weekend
  // days are between given start and end date
  let count = 0
  const curDate = new Date(startDate)
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay()
    if (unselectableDaysOfWeek.includes(dayOfWeek)) {
      count++
    }
    curDate.setDate(curDate.getDate() + 1)
  }
  return count
}

export const daysBetween = (startDate: DateTime, endDate: DateTime) => {
  const diff = Interval.fromDateTimes(endDate, startDate)
  return diff.length('days')
}

export const plusWeekdays = (startDate: DateTime, numDays: number) => {
  /*
  Adds a number of weekdays to a start date, always ending on a weekday
  (even if numDays = 0)
  */
  const NUM_WEEKDAYS = 5
  const LUXON_MONDAY = 1
  const LUXON_SATURDAY = 6
  const LUXON_SUNDAY = 7

  // weekday 1-7
  let dayOfWeek = startDate.weekday

  // If the current day is a weekend, offset it to the following Monday instead
  if (dayOfWeek === LUXON_SATURDAY || dayOfWeek === LUXON_SUNDAY) {
    if (dayOfWeek === LUXON_SATURDAY) {
      startDate = startDate.plus({ days: 2 })
    } else {
      startDate = startDate.plus({ days: 1 })
    }
    dayOfWeek = LUXON_MONDAY
    if (numDays > 0) {
      numDays -= 1
    }
  }

  let numWeeks = Math.floor(numDays / NUM_WEEKDAYS)
  let remainingDays = numDays % NUM_WEEKDAYS
  if (remainingDays + dayOfWeek > NUM_WEEKDAYS) {
    numWeeks += 1
    remainingDays = (remainingDays + dayOfWeek) % NUM_WEEKDAYS
  }

  const newDate = startDate.plus({ weeks: numWeeks, days: remainingDays })

  return newDate
}

export const maxDateWithWeekends = (
  minDate: Date,
  numDays: number,
  includeWeekends: boolean
) => {
  if (includeWeekends) {
    const lastDay = DateTime.fromJSDate(minDate).plus({ days: numDays })
    // Use this function to move the last day off of a weekend, if
    // it's on one
    return plusWeekdays(lastDay, 0).toJSDate()
  }
  return plusWeekdays(DateTime.fromJSDate(minDate), numDays).toJSDate()
}

/**
 * Backend does not include timezone and stores in UTC
 * This function adds timezone if it is not already present (UTC)
 * @param ISODate
 */
export function maybeAddTimezone(ISODate: string) {
  const splitDate = ISODate.split(/T\d+:\d+:\d+(\.\d+)*/g)
  if (splitDate.length > 1 && splitDate[1]) {
    return ISODate
  }
  return `${ISODate}Z`
}

/**
 *
 * @param ISODate
 */
export function fromISOWithZone(ISODate: string) {
  return DateTime.fromISO(maybeAddTimezone(ISODate), { setZone: true })
}

/**
 *
 * @param seconds
 * @returns number of seconds in milliseconds
 */
export function secondsToMilliseconds(seconds: number) {
  return seconds * MILLISECONDS_PER_SECOND
}

/**
 *
 * @param dateString
 */
export function fromISOStringWithoutTimeZone(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number)
  return new Date(year, month - 1, day)
}
