import { requiredInject } from '@thyme/libs/src/vue/inject'
import startCase from 'lodash/startCase'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { stringToDateTime, utcDatetimeToDate } from '@/legacy/libs/date'
import { getPatientClinicalSummary } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { MapCancerDiagnosisOncSpreadRef } from '@/pages/PatientProfile/CarePlans/shared/types'
import { arrayToStringDisplay } from '@/pages/PatientProfile/CarePlans/shared/utils'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { toDateDisplay } from '../shared/utils'
import { EditCancerDetailsForm } from './EditPatientCancerDetails/types'
import { getPatientCancerDetailWithRefs } from './queries'
import { useCancerDetailApi } from './shared/store'

// Defined only for convenience and use in testing.
export type CancerViewModel = {
  patientId: Ref<string>
  cancerDetailsId: Ref<string | undefined>
  cancerDisplay: Ref<string>
  spreadDisplay: Ref<string>
  diagnosisDateDisplay: Ref<string>
  additionalInformationDisplay: Ref<string | null>
  medicationsDisplay: Ref<string | null>
  lastUpdatedDisplay: Ref<string>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditCancerDetailsForm>>
  toggleCancerDetailsEdit: () => void
  refetchCancerDetails: () => Promise<void>
}

/**
 *
 * setup patient cancer details
 */
export function setup(): CancerViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { data: cancerDetailsData } = storeToRefs(useCancerDetailApi())

  const cancerDetails = computed(() => cancerDetailsData.value?.[0] ?? null)
  const cancerDetailsId = computed(() => cancerDetails.value?.cancerDiagnosisId)

  const cancerDisplay = computed(() => {
    const otherOncDx = cancerDetails.value?.otherOncologyDxType
    if (otherOncDx) {
      return `Other • ${startCase(otherOncDx)}`
    }
    const cancer = startCase(
      cancerDetails.value?.oncologyDiagnosisReference
        .oncologyDiagnosisCategoryReference.description
    )
    const cancerType = startCase(
      cancerDetails.value?.oncologyDiagnosisReference.description
    )

    if (cancer && cancerType) {
      return cancer.toLowerCase() === cancerType.toLowerCase()
        ? cancerType
        : `${cancer} • ${cancerType}`
    }
    return '-'
  })

  const spreadDisplay = computed(() => {
    const oncSpreadRef = cancerDetails.value?.oncologySpreadRefMaps
    if (oncSpreadRef?.length) {
      // grab descriptions from each onc spread ref
      const spreadDescriptions: string[] = oncSpreadRef.map(
        (spread: MapCancerDiagnosisOncSpreadRef) =>
          spread.oncologySpreadReference.description
      )
      return arrayToStringDisplay(spreadDescriptions)
    }
    return '-'
  })

  const diagnosisDateDisplay = computed(() => {
    const dxDate = cancerDetails.value?.diagnosisDate
    if (dxDate) {
      return toDateDisplay(dxDate)
    }
    return '-'
  })

  const lastUpdatedDisplay = computed(() => {
    const lastUpdatedAtStr = formatLastUpdatedStr(cancerDetails.value)
    return lastUpdatedAtStr ?? '-'
  })

  const additionalInformationDisplay = computed(() => {
    return cancerDetails.value?.additionalContext ?? null
  })

  const medicationsDisplay = computed(() => {
    return cancerDetails.value?.medications ?? null
  })

  const editModalInitialData = computed(() => ({
    diagnosisDate: utcDatetimeToDate(
      stringToDateTime(cancerDetails.value?.diagnosisDate ?? '')
    ),
    medications: cancerDetails.value?.medications,
    additionalContext: cancerDetails.value?.additionalContext,
    otherOncologyDxType: cancerDetails.value?.otherOncologyDxType,
    oncologyDiagnosisRefId: cancerDetails.value?.oncologyDiagnosisRefId,
    oncologyDiagnosisCategoryRefId: cancerDetails.value
      ?.oncologyDiagnosisReference
      ? cancerDetails.value.oncologyDiagnosisReference
          .oncologyDiagnosisCategoryRefId
      : cancerDetails.value?.otherOncologyDxType
      ? null
      : undefined,
    oncologySpreadRefIds: cancerDetails.value?.oncologySpreadRefMaps.map(
      (spreadMap) => spreadMap.oncologySpreadRefId
    ),
  }))

  /**
   * function to refetch cancer details and clinical summary
   */
  async function refetchCancerDetails() {
    await getPatientCancerDetailWithRefs(patientId.value)
    await getPatientClinicalSummary(patientId.value)
  }

  /**
   * open/close edit modals
   */
  function toggleCancerDetailsEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(async () => {
    await refetchCancerDetails()
  })

  return {
    patientId,
    cancerDetailsId,
    // display values
    lastUpdatedDisplay,
    cancerDisplay,
    spreadDisplay,
    diagnosisDateDisplay,
    additionalInformationDisplay,
    medicationsDisplay,
    // cancer diagnosis edit modal
    editModalInitialData,
    showEditModal,
    toggleCancerDetailsEdit,
    refetchCancerDetails,
  }
}
