<template>
  <div class="flex gap-6">
    <div
      v-for="tab in tabs"
      :key="tab.value"
      class="tab cursor-pointer"
      :class="tab.value === active ? 'active' : ''"
      @click="selectTab(tab)"
    >
      <span>
        {{ tabDisplay(tab) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Tab } from '@thyme/nashville/src/types/tabs'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    itemCounts: {
      type: Object,
      default: null,
    },
    active: {
      type: String,
      default: null,
    },
  },
  emits: ['select'],
  setup(props, context) {
    const selectTab = (v: Tab) => context.emit('select', v)
    /**
     *
     * @param t
     */
    function tabDisplay(t: Tab) {
      if (props.itemCounts && props.itemCounts[t.value]) {
        return `${t.label} (${props.itemCounts[t.value]})`
      }
      return t.label
    }

    return {
      selectTab,
      tabDisplay,
    }
  },
})
</script>

<style>
.tab {
  @apply h-6 text-center;
}
.active span {
  @apply underline underline-offset-8;
  @apply text-nash-purple500 decoration-nash-purple500;
  @apply border-b-2 font-bold decoration-2;
}
</style>
