import { DateTime } from 'luxon'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useRadiationApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { AddOrEditRadiationForm } from './types'

/**
 *
 * @param payloads
 */
export async function createRadiations(payloads: AddOrEditRadiationForm[]) {
  const radiationPromises: Promise<void>[] = []
  payloads.forEach((payload) =>
    radiationPromises.push(
      useRadiationApi().create({
        body: {
          ...payload,
          radiationDate: payload.radiationDate
            ? DateTime.fromJSDate(payload.radiationDate).toISODate()
            : null,
        },
        metaOptions: { bubbleErrorThrow: true },
      })
    )
  )
  try {
    await Promise.all(radiationPromises)
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to add radiation(s).',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully added radiation(s).',
    type: NotificationType.SUCCESS,
  })
}

/**
 *
 * @param radiationId
 * @param dirtyData
 */
export async function updateRadiation(
  radiationId: string,
  dirtyData: AddOrEditRadiationForm
) {
  const radiationApi = useRadiationApi()
  let upserted
  const body = {
    ...dirtyData,
    ...{
      ...(dirtyData.radiationDate
        ? {
            radiationDate: DateTime.fromJSDate(
              dirtyData.radiationDate
            ).toISODate(),
          }
        : {}),
    },
  }
  try {
    upserted = await radiationApi.partialUpdate({
      ids: [radiationId],
      body: body,
      metaOptions: { bubbleErrorThrow: true },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update radiation.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated radiation.',
    type: NotificationType.SUCCESS,
  })

  const radiationData = radiationApi.data
  if (
    radiationId?.length &&
    radiationData?.[radiationId as keyof typeof radiationData]
  ) {
    radiationData[radiationId] = upserted
  }

  return upserted
}
