import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { computed, ref } from 'vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { NotificationType } from '@/legacy/types/notifications'
import {
  subStatusLabels,
  SubStatusReason,
} from '@/legacy/types/pathways/pathways'
import {
  SubtaskRowDisplayType,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const selectedSubStatus = ref<SubStatusReason | null>(null)

  const modalTitle = computed(() => {
    return props.status === SubtaskStatus.CANCELLED
      ? 'Cancel Subtask'
      : 'Put Subtask On Hold'
  })

  const actionText = computed(() => {
    return props.status === SubtaskStatus.CANCELLED
      ? 'Choose a reason for cancelling the subtask above'
      : 'Choose a reason for placing the subtask above on hold'
  })

  const filteredSubStatusLabels = computed(() =>
    subStatusLabels.filter((label) => label.status === props.status)
  )

  /**
   *
   */
  function close() {
    selectedSubStatus.value = null
    context.emit('close')
  }

  /**
   * Enable confirm when user has selected a subStatus
   */
  const confirmEnabled = computed(() => {
    return selectedSubStatus.value !== null
  })

  /**
   * Update the subtask with Cancelled and the corresponding subStatus value
   */
  async function confirm() {
    if (selectedSubStatus.value) {
      await useSubtasksStore().updateSubtask(props.subtask.subtaskId, {
        status: props.status,
        subStatusReason: selectedSubStatus.value,
      })
      thymeDispatch('thymeline-update')
      context.emit('callbackFn', [props.subtask.subtaskId])
      close()
    } else if (!selectedSubStatus.value) {
      _showErrorToast(ERR_MSG_NO_SUBTASK_SUB_STATUS)
    } else {
      _showErrorToast(ERR_MSG_UNKNOWN_ERROR)
    }
  }

  const _showErrorToast = (msg: string) => {
    useNotificationStore().setNotification({
      message: msg,
      type: NotificationType.DANGER,
    })
  }

  const ERR_MSG_NO_SUBTASK_SUB_STATUS =
    'Could not update, no reason selected for this subtask status change'

  const ERR_MSG_UNKNOWN_ERROR = 'Could not update, an unknown error occurred.'

  return {
    subStatusLabels: filteredSubStatusLabels,
    SubtaskRowDisplayType,
    confirmEnabled,
    confirm,
    close,
    ModalSize,
    ButtonSize,
    selectedSubStatus,
    modalTitle,
    actionText,
  }
}
