<template>
  <div>
    <TModal
      :is-visible="showManageGoals"
      :size="ModalSize.LG"
      title="Manage Goals"
      @close="close"
    >
      <div
        class="flex justify-between border-b border-nash-neutral300 pb-2 items-center"
      >
        <TTab
          :tabs="statuses"
          :active="selectedStatus"
          :item-counts="goalCounts"
          @select="selectStatus"
        />
        <div
          v-tooltip.left="
            goalsLimitReached
              ? 'You have reached the maximum number of active Goals for this member.'
              : ''
          "
        >
          <TMPrimaryButton
            label="+ Add"
            :disabled="goalsLimitReached"
            @click="addGoal"
          />
        </div>
      </div>
      <GoalRow
        v-for="goal in goalsByStatus"
        :key="goal.goalId"
        :goal="goal"
        :goals-num="activeGoalsNum"
        :goal-pathways="getGoalPathways(goal)"
      />
      <div v-if="!goalsByStatus.length" class="text-neutral-600">
        There are no {{ selectedStatusLabel.toLowerCase() }} Goals
      </div>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TTab from '@nashville/tab/TTab.vue'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import filter from 'lodash/filter'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useRoute } from 'vue-router'
import GoalRow from '@/legacy/components/patient/carePlans/modals/ManageGoalsModal/GoalRow/GoalRow.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { useGoalStore } from '@/legacy/store/modules/goals'
import {
  Goal,
  GoalStatus,
  goalStatuses,
  MAX_GOALS,
} from '@/legacy/types/pathways/goals'
import { Pathway } from '@/legacy/types/pathways/pathways'
import { CaseConferenceMode } from '@/legacy/types/patients/carePlanSummaries'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TModal,
    TTab,
    GoalRow,
  },
  props: {
    goals: {
      type: Array as PropType<Goal[]>,
      required: true,
    },
    showManageGoals: {
      type: Boolean,
      default: false,
    },
    allPatientPathways: {
      type: Array as PropType<Pathway[]>,
      default: () => [],
    },
    goalsNum: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close'],
  setup(props, context) {
    const route = useRoute()
    const statuses = ref(goalStatuses)
    const selectedStatus = ref(statuses.value[0].value)
    const selectedStatusLabel = ref(statuses.value[0].label)
    const goalCounts = computed(() => {
      return statuses.value.reduce((counter, status) => {
        counter[status.value] = filter(props.goals, [
          'status',
          status.value,
        ]).length
        return counter
      }, {} as { [key: string]: number })
    })

    const goalsByStatus = computed(() =>
      filter(props.goals, ['status', selectedStatus.value]).sort(
        (a, b) => a.sortNumber - b.sortNumber
      )
    )

    const goalsLimitReached = computed(
      () =>
        filter(props.goals, ['status', GoalStatus.ACTIVE]).length >= MAX_GOALS
    )

    const activeGoalsNum = computed(
      () => filter(props.goals, ['status', GoalStatus.ACTIVE]).length
    )

    const selectStatus = (v: Tab) => (
      (selectedStatus.value = v.value as GoalStatus),
      (selectedStatusLabel.value = v.label)
    )

    const close = () => {
      context.emit('close')
    }

    /**
     * call create endpoint to create new empty goal
     * refetch goals after
     */
    async function addGoal() {
      const memberId = route.params.patientId as string
      await useGoalStore().createGoal({ memberId })
    }

    /**
     * get pathways associated with a goal
     * @param goal
     */
    function getGoalPathways(goal: Goal) {
      const filtered_pathways = props.allPatientPathways.filter(
        (pathway: Pathway) =>
          pathway.goalIds.length && pathway.goalIds.includes(goal.goalId)
      )
      return filtered_pathways ?? []
    }

    return {
      ModalSize,
      goalCounts,
      selectedStatusLabel,
      goalsByStatus,
      goalsLimitReached,
      selectedStatus,
      statuses,
      activeGoalsNum,
      getGoalPathways,
      selectStatus,
      close,
      addGoal,
      CaseConferenceMode,
    }
  },
})
</script>
