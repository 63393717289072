import { DateTime } from 'luxon'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useSurgeryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { SurgicalProcedureReference } from '@/pages/PatientProfile/CarePlans/shared/types'
import { useMapOncDxRefSurgicalProcedureRefApi } from './store'
import { AddOrEditSurgeryForm } from './types'

/**
 *
 * @param payloads
 */
export async function createSurgeries(payloads: AddOrEditSurgeryForm[]) {
  const surgeryPromises: Promise<void>[] = []
  payloads.forEach((payload) =>
    surgeryPromises.push(
      useSurgeryApi().create({
        body: {
          ...payload,
          ...{
            surgeryDate: payload.surgeryDate
              ? DateTime.fromJSDate(payload.surgeryDate).toISODate()
              : null,
          },
        },
        metaOptions: { bubbleErrorThrow: true },
      })
    )
  )
  try {
    await Promise.all(surgeryPromises)
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to add surgery/surgeries.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully added surgery/surgeries.',
    type: NotificationType.SUCCESS,
  })
}

/**
 *
 * @param surgeryId
 * @param dirtyData
 */
export async function updateSurgery(
  surgeryId: string,
  dirtyData: AddOrEditSurgeryForm
) {
  const surgeryApi = useSurgeryApi()
  let upserted
  const body = {
    ...dirtyData,
    ...{
      ...(dirtyData.surgeryDate
        ? {
            surgeryDate: DateTime.fromJSDate(dirtyData.surgeryDate).toISODate(),
          }
        : {}),
    },
  }
  try {
    upserted = await surgeryApi.partialUpdate({
      ids: [surgeryId],
      body: body,
      metaOptions: { bubbleErrorThrow: true },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update surgery.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated surgery.',
    type: NotificationType.SUCCESS,
  })

  const surgeryData = surgeryApi.data
  if (
    surgeryId?.length &&
    surgeryData?.[surgeryId as keyof typeof surgeryData]
  ) {
    surgeryData[surgeryId] = upserted
  }

  return upserted
}

/**
 * Retrieve surgical procedure options mapped to current cancer
 * Returns null if no data is returned.
 * @param oncDxRefId
 */
export async function getMapSurgicalProcedureReferences(
  oncDxRefId: string
): Promise<SurgicalProcedureReference[] | null> {
  const mapSurgicalRefApi = useMapOncDxRefSurgicalProcedureRefApi()
  const response = await mapSurgicalRefApi.list({
    params: {
      page_length: 50,
      parts: ['surgical_procedures_reference'],
      filter_oncology_diagnosis_ref_ids: [oncDxRefId],
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (mapSurgicalRefApi.error) {
    const error = mapSurgicalRefApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching surgical references',
      type: NotificationType.WARNING,
      error: `Error fetching surgical references: ${error}`,
    })
  }
  return response.data
}
