// ENUMS --------------------
export enum DropdownSize {
  md = 'md',
  sm = 'sm',
}

export enum SidebarDropdownTypeActions {
  status = 'status dropdown',
  staff = 'staff dropdown',
  date = 'date dropdown',
  identity = 'is identity verified dropdown',
  time = 'time dropdown',
  responsible = 'responsible dropdown',
  used_translator = 'used translator dropdown',
}

export enum DropdownTriggerOptions {
  edit = 'edit',
  add = 'add',
  delete = 'delete',
}

// TYPES --------------------

export type DropdownKV = {
  label: string
  value: string
}

export type DropdownKVWithDetail = DropdownKV & {
  detail?: string | null
}

export type LegacyDropdownK<T = string> = {
  icon?: string
  text?: string
  value?: T
  fillType?: string | null
}

export type LegacyDropdownKV<T = string> = [string, LegacyDropdownK<T> | string]
