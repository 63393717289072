<template>
  <div
    class="modal-content-container relative"
    :class="active ? 'bg-nash-purple100 border-nash-purple400' : ''"
  >
    <div class="relative m-3">
      <TIcon class="icon-placement" :icon="currentStatusIcon" />
    </div>
    <div class="flex flex-col mr-2 w-full">
      <div class="font-bold text-lg">{{ subtask.title }}</div>
      <div class="flex gap-3 mt-1 text-nash-neutral700">
        <span
          class="shrink-1"
          :class="{ overdue: isOverdue, 'text-nash-neutral700': !isOverdue }"
          >{{ renderDatePretext(isOverdue, subtask.status) }}:
          {{ renderDateString(subtask) }}</span
        >
        <div v-if="!hideSubtaskEstimatedCompletionTime" class="bullet"></div>
        <div
          v-if="!hideSubtaskEstimatedCompletionTime"
          class="flex items-center space-x-1 gap-1"
        >
          {{
            subtask.estimatedMinutesToComplete
              ? `Est: ${subtask.estimatedMinutesToComplete} min`
              : 'N/A'
          }}
        </div>
        <div v-if="!hideSubtaskEstimatedCompletionTime" class="bullet"></div>
        <div class="flex items-center space-x-1 gap-1">
          <div>
            {{
              subtask.responsibleStaffId
                ? getStaffNameFromId(subtask.responsibleStaffId)
                : humanizeRole(subtask.responsibleRole)
            }}
          </div>
        </div>
        <div v-if="!hideSubtaskEstimatedCompletionTime" class="bullet"></div>
        <div class="flex shrink-1 text-nash-neutral700 items-center">
          <TIcon :icon="getPriorityIcon(subtask.priority)" />
          <span>
            {{ capitalize(subtask.priority.toLowerCase()) }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex space-x-2">
      <LegacyTButton
        v-tooltip.left="addBtnTooltipText"
        :disabled="isAlreadyLinked"
        class="border shrink-0 h-10 w-10"
        :class="active ? 'svg-white-override ' : ''"
        :icon="active ? 'cancel' : 'add'"
        :icon-fill="active ? 'neutral600' : ''"
        :type="active ? 'primary' : 'blackAndWhite'"
        @click="select"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import capitalize from 'lodash/capitalize'

import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType, ref } from 'vue'
import {
  isSubtaskOverdue,
  renderDatePretext,
  renderDateString,
  getPriorityIcon,
} from '@/legacy/components/patient/pathways/lib/subtask'
import { humanizeRole } from '@/legacy/libs/entity'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import { Subtask } from '@/legacy/types/pathways/subtasks'

export default defineComponent({
  components: { LegacyTButton, TIcon },
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
    linkedSubtaskIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['add', 'remove'],
  setup(props, context) {
    const active = ref(false)
    const { hideSubtaskEstimatedCompletionTime } = storeToRefs(useFlagStore())

    const isAlreadyLinked = computed(
      () =>
        !!props.linkedSubtaskIds.find((id) => id === props.subtask.subtaskId)
    )

    const addBtnTooltipText = computed(() => {
      if (active.value) {
        return 'Remove Subtask'
      }
      return 'Add Subtask'
    })

    const select = () => {
      if (!active.value) {
        context.emit('add', props.subtask.subtaskId)
      } else if (active.value) {
        context.emit('remove', props.subtask.subtaskId)
      }
      active.value = !active.value
    }

    const isOverdue = computed(() => {
      if (props.subtask) {
        return isSubtaskOverdue(props.subtask)
      }
      return false
    })

    const currentStatusIcon = computed(
      () =>
        Object.values(SubtaskStatuses).filter(
          (taskStatus) => taskStatus.value === props.subtask.status
        )[0]?.icon ?? 'openStatus'
    )

    return {
      isAlreadyLinked,
      currentStatusIcon,
      isOverdue,
      renderDatePretext,
      renderDateString,
      getStaffNameFromId,
      active,
      addBtnTooltipText,
      humanizeRole,
      select,
      capitalize,
      getPriorityIcon,
      hideSubtaskEstimatedCompletionTime,
    }
  },
})
</script>
<style lang="scss" scoped>
.modal-content-container {
  @apply flex justify-between items-center border border-nash-neutral300 py-3 px-4 rounded;
}

.icon-placement {
  top: -28px;
  left: -21px;
  @apply absolute;
}
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
