<template>
  <Checkbox
    :class="chkbxClasses"
    :data-cy="name && `${kebabCase(name)}-checkbox`"
    v-bind="$attrs"
    @click="$emit('click', $event)"
    @change="$emit('change', $event)"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script lang="ts">
import { paddingProp } from '@thyme/nashville/src/types/shared'
import kebabCase from 'lodash/kebabCase'
import Checkbox from 'primevue/checkbox'
import { computed, defineComponent } from 'vue'
import { tokenizedComponentClasses } from '../designTokens'

const sharedProps = {
  ...paddingProp,
}

export default defineComponent({
  components: {
    Checkbox,
  },
  props: {
    ...sharedProps,
    name: {
      type: String,
      default: null,
    },
  },
  emits: ['click', 'change', 'update:modelValue'],
  setup(props) {
    const tokenClasses = tokenizedComponentClasses(
      'button',
      props,
      Object.keys(sharedProps)
    )
    const chkbxClasses = computed(() => [...tokenClasses.value])

    return {
      chkbxClasses,
      kebabCase,
    }
  },
})
</script>
