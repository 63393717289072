import apiStore from '@/legacy/store/modules/apiBuilder'
import { CarePlanTransitionsOfCare } from '@/pages/PatientProfile/CarePlans/shared/types'

export const useLastUpdatedCarePlanTransitionsOfCareApi =
  apiStore<CarePlanTransitionsOfCare>(
    'lastUpdatedCarePlanTransitionsOfCareApi',
    '/api/care_plan/care_plan_transitions_of_care',
    {
      transformData: (d: { data: CarePlanTransitionsOfCare[] }) => d,
    }
  )
