import { ref } from 'vue'

/**
 *
 */
export default function () {
  const showAppointmentsTable = ref(false)
  const showPatientAppointments = ref(false)

  return {
    showAppointmentsTable,
    showPatientAppointments,
  }
}
