<template>
  <section class="bg-nash-neutral000">
    <div class="h-full overflow-y-auto flex">
      <div class="is-fullwidth mx-auto my-0">
        <div class="my-5">
          <h2>Humana Eligibility Check</h2>
          <p>
            Please complete the fields below to check a member's eligibility
          </p>
        </div>
        <div class="my-5 space-y-4">
          <div>
            <TInputText
              v-model="humanaMemberId"
              label="Member ID"
              name="humanaMemberId"
              data-testid="humana-member-id"
            />
          </div>
          <div>
            <TCalendar
              v-model="humanaMemberDob"
              :min-date="null"
              :disable-holidays="false"
              name="humanaMemberDob"
              label="DOB (MM/DD)"
              class="w-full"
              date-format="mm/dd"
            />
          </div>
          <div class="flex justify-between">
            <TMPrimaryButton
              :disabled="disableSubmit"
              name="humana-eligibility-check-submit"
              label="Check Eligibility"
              @click="onSubmit"
            />
            <TMTertiaryButton
              v-if="showClearButton"
              label="Clear"
              icon-position="left"
              :icon="AllowedIcons.close"
              @click="clearInputs"
            />
          </div>
          <div>
            <TMessage
              v-if="eligibilityResponse && eligibilityResponseDetails"
              :expanded="true"
              :severity="eligibilityResponseDetails.severity"
              name="humana-eligibility-response"
              :label="eligibilityResponseDetails.label"
              :custom-icon="eligibilityResponseDetails.icon"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent } from 'vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'

import { setup } from './controller'

export default defineComponent({
  components: {
    TMessage,
    TInputText,
    TCalendar,
    TMPrimaryButton,
    TMTertiaryButton,
  },
  setup,
})
</script>
