import { computed, ExtractPropTypes, Ref } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { contractingChipProps } from './types'

type PropsType = ExtractPropTypes<typeof contractingChipProps>

/**
 *
 * @param props
 */
export function setup(props: PropsType): {
  copyContractIdToClipboard: () => Promise<void>
  hoverText: Ref<string>
  iconProps: Ref<{
    icon?: string
    iconPos?: string
    cursor?: string
  }>
} {
  /**
   * Text shown when hovering over the chip
   */
  const hoverText = computed(() =>
    props.contractId
      ? `Contract ID: ${props.contractId}`
      : 'Contract ID unknown'
  )

  /**
   * Copies the contract ID to the clipboard if it exists
   * and shows a helpful toast message
   */
  async function copyContractIdToClipboard() {
    if (props.contractId) {
      await navigator.clipboard.writeText(props.contractId)
      useNotificationStore().setNotification({
        message: 'Contract ID copied to clipboard',
        type: NotificationType.SUCCESS,
      })
    }
  }

  /**
   * Icon style bindings for the chip.
   * Only shows the copy icon if a contract ID exists
   */
  const iconProps = computed(() => {
    return props.contractId
      ? {
          icon: 'copy',
          iconPos: 'right',
          cursor: 'pointer',
        }
      : {}
  })

  return {
    copyContractIdToClipboard,
    hoverText,
    iconProps,
  }
}
