<template>
  <button
    :disabled="disabled"
    :class="buttonClasses"
    :data-cy="name && `${kebabCase(name)}-button`"
    @click="(e) => $emit('click', e)"
  >
    <TIcon
      v-if="icon && iconPosition === 'left'"
      class="m-0 pr-1"
      :icon="icon"
      :color="iconFill"
      :size="iconSize"
    />
    <slot>
      <span v-if="value" class="m-0">{{ value }}</span>
    </slot>
    <TIcon
      v-if="icon && iconPosition === 'right'"
      class="m-0 pl-1"
      :icon="icon"
      :color="iconFill"
      :size="iconSize"
    />
    <TIcon
      v-else-if="icon && !iconPosition && !value"
      class="m-0"
      :icon="icon"
      :color="iconFill"
      :size="iconSize"
    />
  </button>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { ButtonSize, TButtonType } from '@thyme/nashville/src/types/buttons'
import {
  FillColor,
  IconPosition,
  IconSize,
} from '@thyme/nashville/src/types/icons'
import kebabCase from 'lodash/kebabCase'
import { computed, defineComponent, PropType } from 'vue'
import { enumValueToKey, lookupEnum } from '@/legacy/libs/enum'

export default defineComponent({
  components: {
    TIcon,
  },
  props: {
    iconPosition: {
      type: String,
      default: '',
      options: IconPosition,
    },
    icon: {
      type: String,
      default: '',
    },
    iconFill: {
      type: String,
      default: '',
      options: FillColor,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: TButtonType.Primary,
      validator: (v: string) => Object.values<string>(TButtonType).includes(v),
      // @ts-ignore options custom attr
      options: TButtonType,
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: ButtonSize.MD,
      validator: (v: string) => Object.values<string>(ButtonSize).includes(v),
      // @ts-ignore options custom attr
      options: ButtonSize,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    dontCenter: {
      type: Boolean,
      default: false,
    },
  },
  /*
    Html button element has native click event that propagates which results in click
    event function being called twice in some components and TButton component
    to act buggy, Solution is to delete explicit @click statement and allow native
    click event to occur or to use this emit syntax to prevent native click event from setting off
  */
  emits: ['click'],
  setup(props) {
    const buttonClasses = computed(() => {
      const classes = [
        { 'text-sm': !props.multiselect },
        'rounded',
        props.inline ? 'inline-flex' : 'flex',
        /*
          IMPROVEME [ch1917]:
          Try to come up with a solution to handling
          style overrides more elegantly + avoid bloating
          the component with if/else logic
        */
        { 'justify-center': !props.dontCenter },
        'items-center',
        'space-x-3',
        'max-h-10',
        'h-full',
        { 'w-full': props.expanded },
      ]

      switch (props.type) {
        case TButtonType.Primary:
          classes.push(
            'bg-nash-purple500',
            'hover:bg-nash-purple500',
            'text-nash-neutral000'
          )
          break
        case TButtonType.PrimaryOutline:
          classes.push(
            'border-2',
            'hover:border-nash-purple500',
            'hover:bg-nash-purple500',
            'hover:text-nash-neutral000',
            'border-nash-purple500',
            'text-nash-purple500'
          )
          break
        case TButtonType.Secondary:
          classes.push('bg-nash-neutral300', 'hover:bg-gray-300')
          break
        case TButtonType.Tertiary:
          classes.push(
            'bg-transparent',
            'text-nash-purple500',
            'hover:text-nash-purple400'
          )
          break
        case TButtonType.BlackAndWhite:
          classes.push(
            'bg-transparent',
            'text-nash-neutral900',
            'hover:bg-gray-100'
          )
          break
        case TButtonType.Danger:
          classes.push(
            'bg-nash-brick600',
            'text-nash-neutral000',
            'hover:bg-red-700'
          )
          break
        case TButtonType.Success:
          classes.push(
            'bg-nash-fern600',
            'hover:bg-green-700',
            'text-nash-neutral000'
          )
          break
        case TButtonType.WhiteAndGray:
          classes.push(
            'bg-transparent',
            'text-nash-neutral700',
            'hover:bg-gray-100',
            'font-normal',
            'px-1.5'
          )
          break
        case TButtonType.WhiteAndPurple:
          classes.push(
            'bg-transparent',
            'text-nash-purple500',
            'hover:bg-nash-purple100',
            'hover:text-nash-purple500',
            'font-normal',
            'px-1.5'
          )
          break
        case TButtonType.WhiteAndPurpleSecondary:
          classes.push(
            'text-nash-purple500',
            'bg-transparent',
            'hover:bg-nash-purple500',
            'hover:text-nash-neutral000',
            'font-normal',
            'px-1.5'
          )
          break
        case TButtonType.WhiteAndPurpleTertiary:
          classes.push(
            'text-nash-purple500',
            'bg-nash-purple100',
            'hover:bg-nash-purple500',
            'hover:text-nash-neutral000',
            'font-normal',
            'px-1.5'
          )
          break
        case TButtonType.Transparent:
          classes.push('text-nash-neutral000')
          break
      }

      if (!props.multiselect && props.type !== TButtonType.WhiteAndGray) {
        classes.push('font-bold')
      }

      if (props.type !== TButtonType.Tertiary) {
        classes.push('px-2')
        switch (props.size) {
          case ButtonSize.XS:
            classes.push('py-1')
            break
          case ButtonSize.SM:
            classes.push('py-2')
            break
          case ButtonSize.MD:
            classes.push('py-3')
            break
          case ButtonSize.LG:
            classes.push('py-5')
            break
          case ButtonSize.XL:
            classes.push('py-7')
            break
        }
      }

      return classes
    })

    const iconSize = computed(() => {
      const iconKey: string | undefined = enumValueToKey(IconSize, props.size)
      return iconKey ? (lookupEnum(IconSize, iconKey) as IconSize) : IconSize.MD
    })

    return {
      buttonClasses,
      kebabCase,
      iconSize,
    }
  },
})
</script>
