import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { computed, ref } from 'vue'
import { formatMemberDob } from './format'
import { getRandomResponse } from './temp'
import {
  EligibilityCheckForm,
  HUMANA_ELIGBILITY_RES_MAP,
  HUMANA_ELIGIBILITY_REFERRAL_PROGRAM,
  schema,
} from './types'

/**
 *
 */
export function setup() {
  const humanaMemberId = ref('')
  const humanaMemberDob = ref<Date | undefined>(undefined)

  const disableSubmit = computed(() => {
    return !humanaMemberId.value || !humanaMemberDob.value
  })

  const showClearButton = computed(() => {
    return !!humanaMemberId.value || !!humanaMemberDob.value
  })

  const eligibilityResponse = ref<null | any>(null)

  /**
   * function to clear all inputs
   */
  function clearInputs() {
    humanaMemberId.value = ''
    humanaMemberDob.value = undefined
    eligibilityResponse.value = null
  }

  const { handleSubmit } = useForm({
    initialValues: {
      humanaMemberId: humanaMemberId.value,
      humanaMemberDob: humanaMemberDob.value,
    },
    validationSchema: toTypedSchema(schema),
  })

  /**
   * function to submit form for eligibility check
   * @param values
   */
  async function submit(values: EligibilityCheckForm) {
    const payload = {
      referralProgram: HUMANA_ELIGIBILITY_REFERRAL_PROGRAM,
      umid: values.humanaMemberId,
      dateOfBirth: formatMemberDob(values.humanaMemberDob),
    }

    eligibilityResponse.value = {
      eligibilityFlag: getRandomResponse(),
    }

    console.log('payload---', payload)
    console.log('eligibilityResponse---', eligibilityResponse.value)

    return payload
  }

  const onSubmit = handleSubmit(submit)

  const eligibilityResponseDetails = computed(() => {
    if (eligibilityResponse.value.eligibilityFlag === 'Y') {
      return HUMANA_ELIGBILITY_RES_MAP.ELIGIBILE
    }
    if (eligibilityResponse.value.eligibilityFlag === 'N') {
      return HUMANA_ELIGBILITY_RES_MAP.NOT_ELIGIBILE
    }

    if (eligibilityResponse.value.eligibilityFlag === null) {
      return HUMANA_ELIGBILITY_RES_MAP.NOT_FOUND
    }
    return null
  })

  return {
    AllowedIcons,
    // computed
    eligibilityResponse,
    eligibilityResponseDetails,
    showClearButton,
    disableSubmit,
    // refs
    humanaMemberId,
    humanaMemberDob,
    // functions
    submit,
    onSubmit,
    clearInputs,
  }
}
