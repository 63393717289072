import { PropType } from 'vue'

export const T_INPUT_TEXT_PROPS = {
  name: { type: String, required: true },
  label: { type: String, default: '' },
  help: { type: String, default: '' },
  tooltip: { type: String, default: '' },
  /*
   * Rules can be a string or an object. If an object, it can be a
   * single object or an array of objects. Each object can be a
   * Yup schema or a custom function. If a custom function, it
   * should return a string if invalid or undefined if valid.
   *
   * using Object as a default because vue props don't allow multi-typing
   */
  rules: { type: Object as PropType<any>, default: undefined },
  icon: {
    type: String,
    default: '',
  },
  modelValue: { type: String, default: '' },
  maxLength: {
    type: Number,
    default: 524288, // https://www.w3schools.com/tags/att_input_maxlength.asp
  },
}
