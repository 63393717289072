import { storeToRefs } from 'pinia'
import { onBeforeMount, Ref } from 'vue'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { CarePod } from '@/legacy/types/carePods'

export const useCarePodsApi = apiStore<CarePod>(
  'carePodsApi',
  '/api/care_pods',
  {
    transformData: (d: { data: CarePod[] }) => d,
  }
)

/**
 * A Vue composable that provides the catalog of contracting entities for use
 * in component setup functions. It makes the listAll call and returns a ref
 * which will be updated with the contracting entities when the call completes.
 */
export function useCarePods(): Ref<CarePod[] | null> {
  const { data: carePods } = storeToRefs(useCarePodsApi())

  onBeforeMount(async () => {
    // Populate store with available contracting entities
    await useCarePodsApi().listAll({
      params: {},
    })
  })

  return carePods
}
