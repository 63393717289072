<template>
  <div v-if="subtaskTemplate.outreachMessage" class="space-y-4 mb-4">
    <PhoneDropdown
      v-model="selectedTextee"
      name="outreach-textee"
      :entities="texteeOptionEntities"
      :is-disabled="disabled"
      :must-be-textable-including-unconsented="true"
    />
    <div class="space-x-2">
      <TMPrimaryButton
        v-tooltip.right="tooltip"
        label="Copy Member Outreach"
        icon="mingcute:copy-line"
        :disabled="disabled || !selectedTextee.phoneNumberId"
        @click="
          () => generateAndCopyOutreachToClipboard(SubtaskOutreachType.TEXT)
        "
      />
      <TMSecondaryButton
        v-tooltip.right="tooltip"
        label="Generate Form Link"
        :is-disabled="disabled"
        icon="heroicons:link-20-solid"
        @click="
          () => generateAndCopyOutreachToClipboard(SubtaskOutreachType.EMAIL)
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'

import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  useSubtaskOutreachApi,
  useSubtasksStore,
} from '@/legacy/store/modules/subtasks'
import { useTextingStore } from '@/legacy/store/modules/textingV2'

import {
  Communication,
  TextCommunication,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'
import {
  Subtask,
  SubtaskOutreachType,
  SubtaskStatus,
  SubtaskTemplate,
} from '@/legacy/types/pathways/subtasks'
import {
  PhoneDropdownValue,
  patientAndContactEntities,
  preferredPhoneId,
} from '../communicationV2/dropdowns/lib/phoneDropdown'
import PhoneDropdown from '../communicationV2/dropdowns/PhoneDropdown/PhoneDropdown.vue'

export default defineComponent({
  components: { TMSecondaryButton, TMPrimaryButton, PhoneDropdown },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    subtask: { type: Object as PropType<Subtask>, required: true },
    subtaskTemplate: {
      type: Object as PropType<SubtaskTemplate>,
      required: true,
    },
    tooltip: { type: String, default: '' },
  },
  setup(props) {
    const router = useRouter()

    const subtaskId = computed(() => props.subtask.subtaskId)
    const communicationsStore = useCommunicationsStore()
    const outreachApi = useSubtaskOutreachApi()
    const { selfEntity } = storeToRefs(useProfileStore())
    const { patient, person, entity, phoneNumbers, contacts } = storeToRefs(
      usePatientStore()
    )

    const texteeOptionEntities = computed(() => {
      if (
        contacts.value &&
        entity.value &&
        phoneNumbers.value &&
        person.value
      ) {
        return patientAndContactEntities(
          contacts.value,
          entity.value,
          phoneNumbers.value,
          person.value
        )
      }
      return []
    })

    const initialSelectedTextee = computed(() => {
      const textablePatientPhoneId = preferredPhoneId(
        Object.values(phoneNumbers.value ?? {}),
        true
      )
      if (textablePatientPhoneId) {
        return {
          entityId: patient.value?.entityId,
          phoneNumberId: textablePatientPhoneId,
        }
      }
      if (contacts.value) {
        Object.values(contacts.value).forEach((contact) => {
          const contactPhones = contact.contactEntity.phoneNumbers ?? []
          const phoneId = preferredPhoneId(contactPhones, true)
          if (phoneId) {
            return {
              entityId: contact.contactEntityId,
              phoneNumberId: preferredPhoneId,
            }
          }
        })
      }
      return {}
    })

    const selectedTextee = ref({
      ...initialSelectedTextee.value,
    } as PhoneDropdownValue)

    /**
     *
     * Helper function to create outreach
     * and return generated outreach text
     */
    async function createOutreachAndReturnMessage() {
      try {
        const res = await outreachApi.create({
          body: {
            subtaskId: subtaskId.value,
          },
          metaOptions: { bubbleErrorThrow: true },
        })
        return res.generated.outreachText ?? ''
      } catch (err) {
        let msg = ''
        if (
          err instanceof ApiError &&
          err.status === HTTPStatusCode.ERROR_422_UNPROCESSABLE_ENTITY
        ) {
          msg = `- ${err.message}`
        }
        useNotificationStore().setNotification({
          error: `Error generating outreach text: ${err}`,
          message: `Failed to generate outreach text ${msg}`,
          type: NotificationType.DANGER,
        })
        return
      }
    }

    /**
     *
     * Helper function to check and link subtask to communication
     * @param comm
     */
    async function checkAndLinkSubtask(comm: Communication) {
      const isAlreadyLinked = (props.subtask.communicationMaps ?? []).some(
        (m) => m.communicationId === comm.communicationId
      )
      if (!isAlreadyLinked) {
        await communicationsStore.linkSubtask(
          comm.communicationId,
          subtaskId.value
        )
      }
    }

    /**
     *
     * @param message
     */
    async function copyMessage(message: string) {
      try {
        void navigator.clipboard.writeText(message)
        useNotificationStore().setNotification({
          message: 'Message copied to clipboard',
          type: NotificationType.SUCCESS,
        })
      } catch (err) {
        useNotificationStore().setNotification({
          message: `Failed to copy: ${err}`,
          type: NotificationType.DANGER,
          error: err as string,
        })
        return
      }
    }

    /**
     *
     */
    async function retrieveRecentSMSCommunication() {
      if (selectedTextee.value.phoneNumberId) {
        const phoneNumberId = selectedTextee.value.phoneNumberId
        // check if there is currently an open sms communication for passed
        // phone number id; if none, create one
        const comm: TextCommunication =
          await useTextingStore().getSmsCommByPhoneNumberIds({
            phoneNumberIds: [phoneNumberId],
            ...(patient.value ? { entityIds: [patient.value.entityId] } : {}),
          })

        // reroute thymeline view to texting inbox with comm id in query
        // so communication's sms thread is active in texting inbox UI
        if (comm) {
          // check and link communication to current subtask if not linked already
          void checkAndLinkSubtask(comm)
          await router.push({
            query: {
              commId: comm.communicationId,
              type: 'sms',
              subtaskId: subtaskId.value,
            },
          })
        } else {
          await router.push({
            query: {
              phoneNumberId: phoneNumberId,
              type: 'sms',
              subtaskId: subtaskId.value,
            },
          })
        }
      }
    }

    /**
     *
     * @param type
     */
    async function generateAndCopyOutreachToClipboard(
      type: SubtaskOutreachType
    ) {
      let message = ''

      if (props.subtask.status !== SubtaskStatus.IN_PROGRESS) {
        void useSubtasksStore().updateSubtask(props.subtask.subtaskId, {
          status: SubtaskStatus.IN_PROGRESS,
          responsibleStaffId: selfEntity.value?.entityId,
        })
      }

      // create outreach and return generated outreach text
      message = await createOutreachAndReturnMessage()

      if (message.length) {
        if (type === 'email') {
          const urlRegex = /(https?:\/\/[^\s]+)/g
          const urls = message.match(urlRegex)
          if (urls?.length) {
            message = urls.join(', ')
          }
        } else {
          await retrieveRecentSMSCommunication()
        }
        void copyMessage(message)
      } else {
        useNotificationStore().setNotification({
          message: 'There is no message generated to copy to clipboard.',
          type: NotificationType.DANGER,
        })
        return
      }
    }

    /**
     *
     * Function to return either 'contact' or 'patient' string
     * based on textee id passed in params.
     * @param texteeEntityId
     */
    function getTexteeType(texteeEntityId: string | null): string {
      if (texteeEntityId !== patient.value?.entityId) {
        return 'contact'
      }
      return 'patient'
    }

    return {
      SubtaskOutreachType,
      generateAndCopyOutreachToClipboard,
      texteeOptionEntities,
      selectedTextee,
      getTexteeType,
    }
  },
})
</script>
