import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import { stringToDateTime, utcDatetimeToDate } from '@/legacy/libs/date'
import { toDateDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { getPatientRadiationWithParts } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { useRadiationApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { getDisabledButtonStr } from '../shared/format'
import {
  EditOptions,
  editOptionsArray,
  ManageTabLabel,
  PAGE_LENGTH,
  tabLabels,
  TreatmentField,
} from '../shared/types'
import { getPreviousCancerRadiation } from './queries'
import { usePreviousCancerRadiationApi } from './shared/store'
import { manageRadiationProps } from './types'

type PropsType = ExtractPropTypes<typeof manageRadiationProps>

/**
 * Set up the ManageRadiations component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: currentRadiations, queryMetadata: currentRadiationMetadata } =
    storeToRefs(useRadiationApi())
  const { data: previousRadiations } = storeToRefs(
    usePreviousCancerRadiationApi()
  )

  const pageNumber = ref(1)

  const totalCurrentRadiationCount = computed(
    () => currentRadiationMetadata.value?.total ?? 0
  )
  const showMoreCurrentRadiationDatesButton = computed(
    () =>
      Object.values(currentRadiations.value ?? {}).length <
      totalCurrentRadiationCount.value
  )
  const currentRadiationsArray = computed(() =>
    Object.values(currentRadiations.value ?? {})
  )
  const formattedCurrentRadiation = computed(() =>
    currentRadiationsArray.value.map((radiation) => ({
      patientRadiationId: radiation.patientRadiationId,
      radiationDateDisplay: toDateDisplay(radiation.radiationDate),
      additionalInfo: radiation.additionalInformation,
    }))
  )

  const formattedPreviousRadiation = computed(() => {
    const radiations = previousRadiations.value ?? []
    return radiations.map((radiation) => ({
      patientRadiationId: radiation.patientRadiationId,
      radiationDateDisplay: `${toDateDisplay(radiation.radiationDate)} (${
        radiation.cancerDiagnosis.otherOncologyDxType ??
        radiation.cancerDiagnosis.oncologyDiagnosisReference.description
      })`,
      additionalInfo: radiation.additionalInformation,
    }))
  })

  const showDeleteModal = ref(false)
  const showAddOrEditModal = ref(false)

  const deleteRadiationId = ref<string | null>(null)
  const editRadiationId = ref<string | null>(null)

  const statuses = computed(() => {
    if (!previousRadiations.value?.length) {
      return tabLabels.filter(
        (tabLabel) => tabLabel.value === ManageTabLabel.CURRENT
      )
    }
    return tabLabels
  })

  const selectedStatus = ref(statuses.value[0].value)
  const selectedStatusLabel = ref(statuses.value[0].label)

  const selectStatus = (v: Tab) => (
    (selectedStatus.value = v.value as ManageTabLabel),
    (selectedStatusLabel.value = v.label)
  )

  const radiationsByStatus = computed(() => {
    // IMPROVEME(MT-3680) include toDateDisplay(radiation.radiationDate) to avoid doing so in template
    if (selectedStatus.value === ManageTabLabel.CURRENT) {
      return formattedCurrentRadiation.value
    }
    if (selectedStatus.value === ManageTabLabel.PREVIOUS) {
      return formattedPreviousRadiation.value
    }
    return []
  })

  const addButtonDisabled = computed(
    () => selectedStatus.value === ManageTabLabel.PREVIOUS
  )
  const addButtonDisabledMessage = computed(() =>
    addButtonDisabled.value
      ? getDisabledButtonStr(TreatmentField.RADIATION)
      : ''
  )

  const showEditOptionDropdown = computed(
    () => selectedStatus.value === ManageTabLabel.CURRENT
  )

  /**
   *
   * @param str
   * @param radiationId
   */
  function openModal(str: EditOptions, radiationId: string) {
    if (str === EditOptions.EDIT) {
      toggleAddOrEditModal(radiationId)
    } else if (str === EditOptions.DELETE) {
      toggleDeleteModal(radiationId)
    }
  }

  /**
   *
   * @param radiationId
   */
  function toggleDeleteModal(radiationId: string | null = null) {
    showDeleteModal.value = !showDeleteModal.value
    deleteRadiationId.value = showDeleteModal.value ? radiationId : null
  }

  /**
   *
   * @param radiationId
   */
  function toggleAddOrEditModal(radiationId: string | null = null) {
    showAddOrEditModal.value = !showAddOrEditModal.value
    editRadiationId.value = radiationId
  }

  const editModalInitialData = computed(() => {
    if (editRadiationId.value && currentRadiations.value) {
      const radiationId = editRadiationId.value
      return {
        radiationDate:
          utcDatetimeToDate(
            stringToDateTime(currentRadiations.value[radiationId].radiationDate)
          ) ?? undefined,
        additionalInformation:
          currentRadiations.value[radiationId].additionalInformation,
      }
    }
    return {}
  })

  // fetch 3 more radiation dates
  const loadMore = async () => {
    if (!props.cancerDxId) {
      return
    }
    pageNumber.value = pageNumber.value + 1
    await getPatientRadiationWithParts(
      props.cancerDxId,
      PAGE_LENGTH,
      pageNumber.value
    )
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  /**
   * reset variables that determine
   * edit/add/delete modal rendering
   */
  function resetModalVars() {
    showDeleteModal.value = false
    showAddOrEditModal.value = false
    deleteRadiationId.value = null
    editRadiationId.value = null
  }

  /**
   *
   */
  async function refetchCurrentRadiation() {
    pageNumber.value = 1
    await getPatientRadiationWithParts(
      props.cancerDxId,
      PAGE_LENGTH,
      pageNumber.value
    )
    resetModalVars()
  }

  /**
   *
   */
  function onSave() {
    useRadiationApi().data = {}
    context.emit('refetch')
  }

  onBeforeMount(async () => {
    await getPreviousCancerRadiation(props.cancerDxId, props.patientId)
  })

  return {
    ModalSize,
    // tabs
    statuses,
    selectedStatus,
    selectStatus,
    showEditOptionDropdown,
    addButtonDisabled,
    addButtonDisabledMessage,
    // display value
    radiationsByStatus,
    toDateDisplay,
    editOptionsArray,
    // actions
    close,
    onSave,
    openModal,
    // delete modal
    toggleDeleteModal,
    deleteRadiationId,
    showDeleteModal,
    refetchCurrentRadiation,
    // add/edit modal
    toggleAddOrEditModal,
    showAddOrEditModal,
    editRadiationId,
    editModalInitialData,
    // show more button
    showMoreCurrentRadiationDatesButton,
    loadMore,
  }
}
