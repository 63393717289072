import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  MapCancerDiagnosisOncSpreadRef,
  OncologyDiagnosisCategoryReference,
  OncologyDiagnosisReference,
  OncologySpreadReference,
} from '@/pages/PatientProfile/CarePlans/shared/types'

export const useOncologyDiagnosisReferenceApi =
  apiStore<OncologyDiagnosisReference>(
    'oncologyDiagnosisReferenceApi',
    '/api/oncology_diagnoses/reference',
    {
      transformData: (d: { data: OncologyDiagnosisReference[] }) => d,
    }
  )

export const useOncologySpreadReferenceApi = apiStore<OncologySpreadReference>(
  'oncologySpreadReferenceApi',
  '/api/oncology_spread/reference',
  {
    transformData: (d: { data: OncologySpreadReference[] }) => d,
  }
)

export const useMapCancerDiagnosisOncSpreadRefApi =
  apiStore<MapCancerDiagnosisOncSpreadRef>(
    'mapCancerDiagnosisOncSpreadRefApi',
    '/api/map_cancer_diagnoses_onc_spreads_ref',
    {
      transformData: (d: { data: MapCancerDiagnosisOncSpreadRef[] }) => d,
    }
  )

export const useOncologyDiagnosisCategoryReferenceApi =
  apiStore<OncologyDiagnosisCategoryReference>(
    'oncologyDiagnosisCategoryReferenceApi',
    '/api/oncology_diagnosis_categories/reference',
    {
      transformData: (d: { data: OncologyDiagnosisCategoryReference[] }) => d,
    }
  )
