<template>
  <TCalendar
    :model-value="
      dirtyPlannedCall.dueDatetime
        ? new Date(maybeAddTimezone(dirtyPlannedCall.dueDatetime))
        : undefined
    "
    placeholder="Date"
    class="w-full"
    @update:model-value="setDueDatetime($event, DueDateTimePart.DATE)"
  />
  <TCalendar
    v-if="
      dirtyPlannedCall.timeBlock === ScheduledTimeBlock.CUSTOM &&
      shouldSetCallTime
    "
    :model-value="
      dirtyPlannedCall.dueDatetime
        ? new Date(maybeAddTimezone(dirtyPlannedCall.dueDatetime))
        : undefined
    "
    time-only
    inline
    :show-icon="false"
    :step-minute="15"
    hour-format="12"
    class="w-full"
    @update:model-value="setDueDatetime($event, DueDateTimePart.TIME)"
  />
  <label id="timezone-display" class="italic font-normal">
    <div id="member-timezone-display" class="mt-2">
      {{ displayMemberTimezoneString }}
    </div>
    <div id="user-timezone-display">{{ displayUserTimezoneString }}</div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import setup from './controller'

export default defineComponent({
  components: { TCalendar },
  props: {
    dueDatetime: {
      type: String,
      default: null,
    },
    shouldSetTime: {
      type: Boolean,
      default: null,
    },
  },
  emits: ['update'],
  setup,
})
</script>
