import { requiredInject } from '@thyme/libs/src/vue/inject'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { getPatientMedicalConditions } from '@/pages/PatientProfile/CarePlans/shared/queries'
import {
  useMedicalConditionAttrsRefApi,
  useMedicalConditionsWithAttrsApi,
  useMedicalConditionsWithoutAttrsApi,
} from '@/pages/PatientProfile/CarePlans/shared/store'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import {
  getLastUpdatedMedicalCondition,
  getMedicalConditionAttrRefs,
} from './queries'
import { formatAttributesDisplay } from './shared/format'
import { useLastUpdatedMedicalConditionApi } from './shared/store'
import { MedicalConditionDisplay } from './shared/types'

export type MedicalConditionsViewModel = {
  patientId: Ref<string>
  medicalConditionsWithAttrsDisplay: Ref<MedicalConditionDisplay[]>
  medicalConditionsWithoutAttrsDisplay: Ref<MedicalConditionDisplay[]>
  otherMedicalConditionsLabelDisplay: Ref<string>
  lastUpdatedDisplay: Ref<string>
  showManageModal: Ref<boolean>
  showOtherMedicalConditions: Ref<boolean>
  isLoadingMedicalConditions: Ref<boolean>
  isLoadingMedicalConditionsWithoutAttrs: Ref<boolean>
  toggleManageMedicalConditions: () => void
  fetchAndShowOtherMedicalConditions: () => Promise<void>
  fetchMedicalConditionsWithoutAttrs: () => Promise<void>
  fetchAll: () => Promise<void>
}

const GET_MEDICAL_CONDITIONS_WITH_ATTRS_BOOL = true

/**
 *
 *
 */
export function setup(): MedicalConditionsViewModel {
  const showManageModal = ref<boolean>(false)
  const showOtherMedicalConditions = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const {
    data: medicalConditionsWithAttrs,
    isLoading: isLoadingMedicalConditionsWithAttrs,
  } = storeToRefs(useMedicalConditionsWithAttrsApi())
  const { data: lastUpdatedMedicalCondition } = storeToRefs(
    useLastUpdatedMedicalConditionApi()
  )
  const { data: medicalConditionAttrRefs, isLoading: isLoadingAttrs } =
    storeToRefs(useMedicalConditionAttrsRefApi())
  const {
    data: medicalConditionsWithoutAttrs,
    isLoading: isLoadingMedicalConditionsWithoutAttrs,
  } = storeToRefs(useMedicalConditionsWithoutAttrsApi())

  const otherMedicalConditionsLabelDisplay = computed(() =>
    showOtherMedicalConditions.value
      ? 'Hide Other Medical Conditions'
      : 'Show Other Medical Conditions'
  )

  const isLoadingMedicalConditions = computed(() => {
    return isLoadingMedicalConditionsWithAttrs.value || isLoadingAttrs.value
  })

  /**
   * fetch both medical conditions with and without attributes
   */
  async function fetchAll() {
    await fetchMedicalConditionsWithAttrs()
    await fetchMedicalConditionsWithoutAttrs()
    await fetchLastUpdatedMedicalCondition()
  }

  /**
   * fetch medical conditions that  have attributes
   */
  async function fetchMedicalConditionsWithAttrs() {
    await getPatientMedicalConditions(
      patientId.value,
      GET_MEDICAL_CONDITIONS_WITH_ATTRS_BOOL
    )
  }

  /**
   *
   */
  async function fetchLastUpdatedMedicalCondition() {
    await getLastUpdatedMedicalCondition(patientId.value)
  }

  /**
   * fetch medical conditions that do not have attributes
   */
  async function fetchMedicalConditionsWithoutAttrs() {
    await getPatientMedicalConditions(
      patientId.value,
      !GET_MEDICAL_CONDITIONS_WITH_ATTRS_BOOL
    )
  }

  /**
   *  fetch other medical conditions and toggle display
   */
  async function fetchAndShowOtherMedicalConditions() {
    showOtherMedicalConditions.value = !showOtherMedicalConditions.value
    if (showOtherMedicalConditions.value) {
      await fetchMedicalConditionsWithoutAttrs()
    }
  }

  const medicalConditionsWithAttrsDisplay = computed(() => {
    if (
      !medicalConditionsWithAttrs.value ||
      !medicalConditionsWithAttrs.value.length
    ) {
      return []
    }
    return medicalConditionsWithAttrs.value
      .map((medicalCondition: MedicalCondition) => {
        return {
          attributesString: formatAttributesDisplay(
            medicalCondition.conditionSpecificAttributes,
            medicalConditionAttrRefs.value
          ),
          medicalConditionId: medicalCondition.medicalConditionId,
          isSensitive: medicalCondition.medicalConditionReference?.isSensitive,
          medicalConditionRefId: medicalCondition.medicalConditionRefId,
          description: medicalCondition.medicalConditionReference.description,
          conditionSpecificAttributes:
            medicalCondition.conditionSpecificAttributes,
          hasConditionSpecificAttributes: true,
          patientId: medicalCondition.patientId,
          isArchived: medicalCondition.isArchived,
        }
      })
      .sort((a, b) => {
        return a.description.localeCompare(b.description)
      })
  })

  const lastUpdatedDisplay = computed(() => {
    const lastUpdated = lastUpdatedMedicalCondition.value?.[0]
    const lastUpdatedAtStr = formatLastUpdatedStr(lastUpdated)
    return lastUpdatedAtStr ?? '-'
  })

  const medicalConditionsWithoutAttrsDisplay = computed(() => {
    if (
      !medicalConditionsWithoutAttrs.value ||
      !medicalConditionsWithoutAttrs.value.length
    ) {
      return []
    }
    return medicalConditionsWithoutAttrs.value
      .map((medicalCondition: MedicalCondition) => {
        return {
          attributesString: null,
          medicalConditionId: medicalCondition.medicalConditionId,
          isSensitive: medicalCondition.medicalConditionReference?.isSensitive,
          medicalConditionRefId: medicalCondition.medicalConditionRefId,
          description:
            medicalCondition.otherMedicalCondition ??
            medicalCondition.medicalConditionReference.description,
          conditionSpecificAttributes:
            medicalCondition.conditionSpecificAttributes,
          hasConditionSpecificAttributes: false,
          patientId: medicalCondition.patientId,
          isArchived: medicalCondition.isArchived,
        }
      })
      .sort((a, b) => {
        return a.description.localeCompare(b.description)
      })
  })

  /**
   * open/close edit modal
   */
  function toggleManageMedicalConditions() {
    showManageModal.value = !showManageModal.value
  }

  onBeforeMount(async () => {
    await fetchMedicalConditionsWithAttrs()
    await fetchLastUpdatedMedicalCondition()
    if (!medicalConditionAttrRefs.value) {
      await getMedicalConditionAttrRefs()
    }
  })

  return {
    patientId,
    // loading states
    isLoadingMedicalConditions,
    isLoadingMedicalConditionsWithoutAttrs,
    // display values
    lastUpdatedDisplay,
    medicalConditionsWithAttrsDisplay,
    medicalConditionsWithoutAttrsDisplay,
    otherMedicalConditionsLabelDisplay,
    // fetch functions
    fetchAndShowOtherMedicalConditions,
    fetchMedicalConditionsWithoutAttrs,
    fetchAll,
    // show/hide states
    showOtherMedicalConditions,
    showManageModal,
    // toggle edit modal
    toggleManageMedicalConditions,
  }
}
