import apiStore from '@/legacy/store/modules/apiBuilder'
import { PreferredContactMethod } from '@/legacy/types/patients/contactMethods'

export const useContactMethodsApi = apiStore<PreferredContactMethod>(
  'contactMethodsApi',
  '/api/patients/contact_preferences/methods',
  {
    transformData: (d: { data: PreferredContactMethod[] }) => d,
  }
)
