<template>
  <div class="mb-10 relative">
    <TSpinner
      v-if="isLoadingComm || isLoadingPlannedCall"
      name="planned-call-sidebar"
      :partial-page="true"
    />
    <div class="mb-5 mt-5 w-full">
      <h2
        data-cy="planned-call-details-header"
        class="border-nash-neutral300 flex items-center content-center"
      >
        {{ componentTitle }} call with
      </h2>
      <div
        class="inline-flex items-center content-center w-full justify-between"
      >
        <CommunicationCall
          :planned-call="dirtyPlannedCall"
          :communication="dirtyCommunication"
          @update-planned-callee="updatePlannedCallee"
        />
        <div
          v-if="dirtyPlannedCall.communicationId"
          class="inline-flex is-pulled-right gap-2"
        >
          <TMSecondaryButton
            name="edit-planned-call"
            icon="pencil"
            @click="scheduledCallModalOpen = true"
          />
          <TMSecondaryButton
            name="delete-planned-call"
            icon="trash"
            @click="deleteCallAndComm"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="divide-y divide-nash-neutral400 pb-6">
        <div class="flex items-center gap-6 py-4">
          <span class="w-60 font-bold text-nash-neutral700">Assigned to</span>
          <TOStaffAssignDropdown
            :model-value="responsible"
            class="grow"
            name="planned-call-assigned-to"
            :show-clear="false"
            :disabled="true"
          />
        </div>
        <div class="flex items-center gap-6 py-4">
          <span class="w-60 font-bold text-nash-neutral700"
            >Scheduled Date</span
          >
          <TInputText
            :model-value="displayDate(dirtyPlannedCall.dueDatetime ?? '')"
            class="grow"
            name="planned-call-scheduled-date"
            :disabled="true"
          />
        </div>
        <div class="py-4">
          <div class="flex items-center gap-6">
            <span class="w-60 font-bold text-nash-neutral700"
              >Scheduled Time</span
            >
            <TInputText
              :model-value="
                displayTime(
                  dirtyPlannedCall.duration,
                  dirtyPlannedCall.dueDatetime ?? ''
                )
              "
              class="grow"
              name="planned-call-scheduled-time"
              :disabled="true"
            />
          </div>
          <label
            v-if="dirtyPlannedCall.timeBlockStart"
            class="float-right font-normal"
            >Autogenerated by time block</label
          >
        </div>
      </div>

      <StaffReminders />

      <NotesSection
        :dirty-communication="dirtyCommunication"
        :save-state="commSaveState"
        @create-or-update="updateCallAndComm"
        @update-notes-for-dirty-comm="updateNotesForDirtyComm"
      />
    </div>
  </div>
  <ScheduledCommunicationModal
    :is-open="scheduledCallModalOpen"
    :member-name="memberName"
    :existing-planned-call="dirtyPlannedCall"
    :existing-communication="dirtyCommunication"
    @close="scheduledCallModalOpen = false"
    @update-from-modal="updateFromModal"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { SidebarDropdownTypeActions } from '@thyme/nashville/src/types/dropdowns'
import { defineComponent, PropType } from 'vue'
import NotesSection from '@/legacy/components/NotesSection.vue'
import ScheduledCommunicationModal from '@/legacy/components/patient/communicationV2/modals/ScheduledCommunicationModal/ScheduledCommunicationModal.vue'
import CommunicationCall from '@/legacy/components/patient/communicationV2/sidebar/calls/CommunicationCall/CommunicationCall.vue'
import StaffReminders from '@/legacy/components/patient/StaffReminders.vue'

import { jsDateTimeToString } from '@/legacy/libs/date'

import { formatNameFromPerson } from '@/legacy/libs/format'

import TOStaffAssignDropdown from '@/legacy/nashville/dropdown/TOStaffAssignDropdown.vue'

import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'
import { SaveState } from '@/legacy/types/api/api'

import { getCommunicationVars, getDisplayVars } from './controller'

export default defineComponent({
  components: {
    TSpinner,
    TInputText,
    NotesSection,
    TMSecondaryButton,
    CommunicationCall,
    ScheduledCommunicationModal,
    TOStaffAssignDropdown,
    StaffReminders,
  },
  props: {
    commSaveState: {
      type: Object as PropType<SaveState>,
      required: true,
    },
  },
  setup() {
    const {
      scheduledCallModalOpen,
      responsible,
      memberName,
      dirtyCommunication,
      dirtyPlannedCall,
      updateCallAndComm,
      deleteCallAndComm,
      updatePlannedCallee,
      updateNotesForDirtyComm,
      updateFromModal,
      isLoadingComm,
      isLoadingPlannedCall,
    } = getCommunicationVars()

    const { componentTitle, displayDate, displayTime } = getDisplayVars()

    return {
      isLoadingComm,
      isLoadingPlannedCall,
      memberName,
      responsible,
      deleteCallAndComm,
      updateCallAndComm,
      dirtyCommunication,
      dirtyPlannedCall,
      jsDateTimeToString,
      updatePlannedCallee,
      SidebarDropdownTypeActions,
      updateNotesForDirtyComm,
      scheduledCallModalOpen,
      formatNameFromPerson,
      updateFromModal,
      getStaffNameFromId,
      displayDate,
      displayTime,
      componentTitle,
    }
  },
})
</script>
