<template>
  <component :is="size" class="flex space-x-4 mb-3">
    <div :class="[...headingStyles, 'font-bold text-gray-900']">
      <slot></slot>
    </div>
    <slot name="actions"></slot>
  </component>
</template>

<script lang="ts">
import { HeadingSize } from '@thyme/nashville/src/types/headings'
import { computed, defineComponent, PropType } from 'vue'

/**
 *
 * @param headingSize
 */
function sizeClass(headingSize: HeadingSize) {
  switch (headingSize) {
    case HeadingSize.H1:
      return 'text-3xl'
    case HeadingSize.H2:
      return 'text-2xl'
    case HeadingSize.H3:
      return ''
    case HeadingSize.H4:
      return 'text-lg'
    default:
      return ''
  }
}

export default defineComponent({
  props: {
    size: {
      required: true,
      type: String as PropType<HeadingSize>,
    },
  },
  setup(props) {
    return {
      headingStyles: computed(() => [sizeClass(props.size)]),
    }
  },
})
</script>
