export const manageRadiationProps = {
  cancerDxId: {
    type: String,
    required: true,
  },
  patientId: {
    type: String,
    required: true,
  },
} as const
