<template>
  <div>
    <p v-if="label">{{ label }}</p>
    <div class="flex">
      <!-- Icons set as SVG code in TIcon -->
      <div
        v-for="iconName in AllIcons"
        :key="iconName"
        v-tooltip.top="iconName"
      >
        <TIcon class="bg-nash-neutral300" :icon="iconName" v-bind="$attrs" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { AllIcons } from '@thyme/nashville/src/types/icons'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { TIcon },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      AllIcons,
    }
  },
})
</script>
