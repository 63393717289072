<template>
  <TModal
    :is-visible="isVisible"
    title="Upload File"
    :size="ModalSize.MD"
    @close="close"
  >
    <o-field>
      <o-upload v-model="dropFile" drag-drop>
        <section class="drop-zone" :class="dropFile ? 'hidden' : ''">
          <p>
            <o-icon icon="upload" size="is-large"> </o-icon>
          </p>
          <p>Drop your files here or click to upload</p>
        </section>
      </o-upload>
    </o-field>

    <div
      v-if="dropFile"
      class="flex justify-between items-center gap-2 py-2 border-y border-nash-neutral300"
    >
      <div class="w-96 flex flex-col gap-2">
        <DocumentCategorySearch
          name="upload-name-search"
          placeholder="Enter Document Name"
          class="z-20"
          :options="documentCategoryOptions"
          @update="updateName"
        />
        <span>{{ dropFile?.name }}</span>
      </div>
      <TMQuaternaryButton icon="close" @click="dropFile = null" />
    </div>

    <template #actions>
      <TMSecondaryButton label="Cancel" @click="close" />
      <TMPrimaryButton label="Save" class="relative" @click="save">
        <span v-if="saving"> <TSpinner name="upload-modal" /> Saving </span>
      </TMPrimaryButton>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import {
  useDocumentCategoryApi,
  useDocumentStore,
} from '@/legacy/store/modules/documents'
import { DocumentCategory } from '@/legacy/types/documents'
import DocumentCategorySearch from '../DocumentCategorySearch.vue'

export default defineComponent({
  components: {
    TSpinner,
    TModal,
    DocumentCategorySearch,
    TMQuaternaryButton,
    TMSecondaryButton,
    TMPrimaryButton,
  },
  props: {
    isVisible: Boolean,
  },
  emits: ['close', 'save'],
  setup(props, context) {
    const { data: documentCategories } = storeToRefs(useDocumentCategoryApi())
    const dropFile = ref<File | null>(null)
    const fileName = ref('')
    const category = ref<string | null>(null)
    const { isUploadingDocument: saving } = storeToRefs(useDocumentStore())

    const documentCategoryOptions = computed(() =>
      documentCategories.value?.length
        ? documentCategories.value.map(
            ({ documentCategoryId, displayName }: DocumentCategory) => [
              documentCategoryId,
              displayName,
            ]
          )
        : []
    )

    const updateName = (event: { [key: string]: string }) => {
      category.value = event.category
      fileName.value = event.name
    }

    const close = () => context.emit('close')
    const save = () =>
      context.emit('save', {
        documentCategoryId: category.value,
        name: fileName.value,
        file: dropFile.value,
      })
    return {
      ModalSize,
      dropFile,
      documentCategoryOptions,
      saving,
      close,
      save,
      updateName,
    }
  },
})
</script>

<style lang="scss">
.drop-zone {
  @apply border-2 border-dashed border-nash-neutral300 p-12 mb-4 flex flex-col items-center justify-center cursor-pointer;
}
.o-upl {
  @apply relative;

  input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
    // outline-0 not available in our version of tailwind
    outline: 0;
    z-index: -1;
  }
}
</style>
