<template>
  <div :class="wrapperClasses">
    <div
      v-if="partialPage"
      class="progress-spinner-wrapper-partial-overlay"
    ></div>
    <ProgressSpinner
      :class="spinnerClasses"
      :data-cy="name && `${kebabCase(name)}-spinner`"
      v-bind="$attrs"
    />
  </div>
</template>

<script lang="ts">
import { paddingProp } from '@thyme/nashville/src/types/shared'
import kebabCase from 'lodash/kebabCase'
import ProgressSpinner from 'primevue/progressspinner'

import { computed, defineComponent } from 'vue'
import { tokenizedComponentClasses } from '.././designTokens'

const sharedProps = {
  ...paddingProp,
}

export default defineComponent({
  components: {
    ProgressSpinner,
  },
  props: {
    fullPage: {
      type: Boolean,
      default: false,
    },
    partialPage: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    ...sharedProps,
  },
  emits: ['click'],
  setup(props) {
    const tokenClasses = tokenizedComponentClasses(
      'spinner',
      props,
      Object.keys(sharedProps)
    )
    const spinnerClasses = computed(() => [...tokenClasses.value])

    const wrapperClasses = computed(() => {
      if (props.fullPage) {
        return ['progress-spinner-wrapper']
      }
      if (props.partialPage) {
        return ['progress-spinner-wrapper-partial']
      }
      return []
    })

    return {
      wrapperClasses,
      spinnerClasses,
      kebabCase,
    }
  },
})
</script>
<style scoped lang="scss">
.progress-spinner-wrapper {
  @apply fixed top-1/2 left-1/2;
  z-index: 999;
  height: 2em;
  width: 2em;
}

/* Transparent Overlay */
.progress-spinner-wrapper:before {
  @apply fixed block opacity-50 top-0 left-0 w-full h-full bg-white;
  content: '';
}

.progress-spinner-wrapper-partial {
  @apply absolute inset-y-0 inset-x-0 flex items-center justify-center overflow-hidden;
  z-index: 29;
}

.progress-spinner-wrapper-partial-overlay {
  @apply absolute inset-y-0 inset-x-0;
  background: rgba(255, 255, 255, 0.5);
}
</style>
