import compact from 'lodash/compact'
import flatMap from 'lodash/flatMap'
import isArray from 'lodash/isArray'
import { LocationQuery } from 'vue-router'
import type { IdMap } from '@/legacy/types/api/store'
import { StaffMap, Entity } from '@/legacy/types/entities/entities'
import { OutcomeCatalog } from '@/legacy/types/outcomes'

/**
 *
 * @param object
 * @param value
 */
export function objValToKey(
  object: { [key: string]: string },
  value: string
): string {
  return (
    Object.keys(object).find((key) => object[key] === value) ??
    value.toLowerCase()
  )
}

/**
 *
 * @param id
 * @param m
 */
export function safeLookup<T>(id: string, m: IdMap<T> | null): T | null {
  if (!m) {
    return null
  }
  return m[id] ?? null
}

/**
 *
 * @param entityEmail
 * @param staff
 */
export function staffEntityFromEmail(
  entityEmail: string,
  staff: StaffMap | null
): Entity | null {
  if (!staff) {
    return null
  }

  const entityPair = Object.entries(staff).find(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([k, v]) => v.email === entityEmail
  )
  if (entityPair) {
    return entityPair[1]
  }
  return null
}

/**
  Used to generate the `options` for a Multiselect component.
  Option output is in the form of [id, displayName]
  flatOptions: array of objects to further flatten
  idLocation: location on each object to find the id
  nameLocation: location on each object to find the displayName
 * @param flatOptions
 * @param idLocation
 * @param nameLocation
 */
export function generateOptionsFromFlatMap(
  flatOptions: object[],
  idLocation: string,
  nameLocation: string
) {
  return flatOptions
    .map((m: { [key: string]: any }) => [m[idLocation], m[nameLocation]])
    .sort((a, b) => (a[1] < b[1] ? -1 : 1))
}

/**
 *
 * @param categories
 */
export function outcomeCategoryOptions(categories: OutcomeCatalog) {
  return generateOptionsFromFlatMap(
    flatMap(categories),
    'outcomeRefId',
    'title'
  )
}

/**
 *
 * @param queryData
 */
export function sanitizeQuery(queryData: LocationQuery) {
  const queryMap: Record<string, string[]> = {}

  Object.entries(queryData ?? {}).forEach(([k, v]) => {
    const queryVal = isArray(v) ? v.map((item) => item?.toString()) : [v]
    queryMap[k] = compact(queryVal)
  })
  return queryMap
}

/**
 *
 * @param queryData
 * @param key
 */
export function getValFromQuery(
  queryData: LocationQuery,
  key: string
): string[] | undefined {
  const queryMap = sanitizeQuery(queryData)
  const selectedQuery = queryMap[key]
  return selectedQuery?.length ? selectedQuery : undefined
}
