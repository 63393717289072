import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeWithTime } from '@/legacy/libs/format'
import {
  TocSettingDisplay,
  TocStatusDisplay,
  TransitionOfCare,
} from '@/legacy/types/patients/transitionsOfCare'

/**
 * return formatted string for type column
 * @param toc
 */
export function formatType(toc: TransitionOfCare) {
  const status = toc.status
  const setting = toc.setting
  return (
    `${TocSettingDisplay[setting as keyof typeof TocSettingDisplay]} ` +
    `${TocStatusDisplay[status as keyof typeof TocStatusDisplay]}`
  )
}

/**
 * return formatted string with date and time
 * clover may provide only date not time so
 * if(time = utc midnight){
 *  return date only
 * }
 * if(member has a timezone){
 *  format time to reflect timezone
 *  return formatted datetime
 * }
 * else return formatted local datetime
 * @param datetime
 * @param patientTimezone
 */
export function formatDatetime(datetime: string, patientTimezone: string) {
  const formattedDatetime = formatDateTimeWithTime(stringToDateTime(datetime))
  const day = formattedDatetime.split(',')[0]
  const date = new Date(formattedDatetime)
  if (
    date.getUTCHours() === 0 &&
    date.getUTCMinutes() === 0 &&
    date.getUTCSeconds() === 0 &&
    date.getUTCMilliseconds() === 0
  ) {
    return day
  }
  if (patientTimezone.length > 0) {
    const time = date.toLocaleTimeString('en-US', {
      timeZone: patientTimezone,
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit',
    })
    return `${day}, ${time}`
  }
  return formattedDatetime
}

/**
 * return formatted string with primary and secondary diagnoses
 * @param toc
 */
export function displayDiagnoses(toc: TransitionOfCare) {
  const primaryDescription = toc.primaryDiagnosisDescription
  const primaryCode = toc.primaryDiagnosisCode
  const secondaryCode = toc.subsequentDiagnosisCodes
  let details = `Primary: ${primaryDescription ?? ''} `
  if (primaryCode) {
    details += `(${primaryCode})`
  }
  if (secondaryCode) {
    details += `\nSecondary: ${secondaryCode}`
  }

  return details
}

/**
 * return formatted string with recent stay type if available
 * @param toc
 */
export function displayRecentStay(toc: TransitionOfCare) {
  if (toc.recentSnfStayFlag) {
    return 'Recent SNF Stay'
  }
  if (toc.recentInpatientStayFlag) {
    return 'Recent Inpatient Stay'
  }
  return ''
}
