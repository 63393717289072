<template>
  <TButton v-bind="$attrs" action="secondary" type="link" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TButton from './TButton.vue'

export default defineComponent({
  components: {
    TButton,
  },
  inheritAttrs: false,
  setup() {
    return {}
  },
})
</script>
