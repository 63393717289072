import { requiredInject } from '@thyme/libs/src/vue/inject'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { getPatientFallRisk } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { useFallRiskApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  Anticoagulation,
  SedatingMedications,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  arrayToStringDisplay,
  formatStringDisplay,
} from '@/pages/PatientProfile/CarePlans/shared/utils'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { EditFallRiskForm } from './EditFallRisk/types'

// Defined only for convenience and use in testing.
export type FallRiskViewModel = {
  patientId: Ref<string>
  fallRiskId: Ref<string | undefined>
  fallInTheLastTwelveMonthsDisplay: Ref<string>
  unsteadyWithStandingOrWalkingDisplay: Ref<string>
  lastUpdatedDisplay: Ref<string>
  fearOfFallingDisplay: Ref<string>
  usesDMEToAssistWithAmbulationDisplay: Ref<string>
  peripheralNeuropathyDisplay: Ref<string>
  anticoagulationDisplay: Ref<string>
  sedatingMedicationsDisplay: Ref<string>
  additionalInformationDisplay: Ref<string | null>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditFallRiskForm>>
  toggleFallRiskEdit: () => void
  arrayToStringDisplay: (arr: string[]) => string
  formatStringDisplay: (str: string) => string
  refetchFallRisk: () => Promise<void>
}

/**
 *
 *
 */
export function setup(): FallRiskViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { data: fallRiskData } = storeToRefs(useFallRiskApi())

  const fallRisk = computed(() => fallRiskData.value?.[0] ?? null)
  const fallRiskId = computed(() => fallRisk.value?.fallRiskId)

  const fallInTheLastTwelveMonthsDisplay = computed(() => {
    const fallInLastTwelveMonths = fallRisk.value?.fallInLastTwelveMonths
    if (
      fallInLastTwelveMonths === undefined ||
      fallInLastTwelveMonths === null
    ) {
      return '-'
    }
    return fallInLastTwelveMonths ? 'Yes' : 'No'
  })

  const unsteadyWithStandingOrWalkingDisplay = computed(() => {
    const unsteadyWalkingOrStanding = fallRisk.value?.unsteadyWalkingOrStanding
    if (
      unsteadyWalkingOrStanding === undefined ||
      unsteadyWalkingOrStanding === null
    ) {
      return '-'
    }
    return unsteadyWalkingOrStanding ? 'Yes' : 'No'
  })

  const fearOfFallingDisplay = computed(() => {
    const fearOfFalling = fallRisk.value?.fearOfFalling
    if (fearOfFalling === undefined || fearOfFalling === null) {
      return '-'
    }
    return fearOfFalling ? 'Yes' : 'No'
  })

  const usesDMEToAssistWithAmbulationDisplay = computed(() => {
    const usesDmeAssistForAmbulation =
      fallRisk.value?.usesDmeAssistForAmbulation
    if (
      usesDmeAssistForAmbulation === undefined ||
      usesDmeAssistForAmbulation === null
    ) {
      return '-'
    }
    return usesDmeAssistForAmbulation ? 'Yes' : 'No'
  })

  const peripheralNeuropathyDisplay = computed(() => {
    const hasPeripheralNeuropathy = fallRisk.value?.hasPeripheralNeuropathy
    if (
      hasPeripheralNeuropathy === undefined ||
      hasPeripheralNeuropathy === null
    ) {
      return '-'
    }
    return hasPeripheralNeuropathy ? 'Yes' : 'No'
  })

  const anticoagulationDisplay = computed(() => {
    const anticoagulation = fallRisk.value?.anticoagulation ?? null
    const otherAnticoagulation = fallRisk.value?.otherAnticoagulation ?? null
    if (anticoagulation && anticoagulation.length) {
      // remove 'Other' from array
      const filtered = anticoagulation
        .filter((val: Anticoagulation) => val !== Anticoagulation.OTHER)
        .sort()
      // format each med and separate with a bullet point
      const formatted = arrayToStringDisplay(filtered)
      if (otherAnticoagulation) {
        // format the other string to replace possible commas with a bullet point
        const formattedOther = formatStringDisplay(otherAnticoagulation)
        return formatted.length
          ? formatted + ' • ' + formattedOther
          : formattedOther
      }
      return formatted
    }
    return '-'
  })

  const sedatingMedicationsDisplay = computed(() => {
    const sedatingMedications = fallRisk.value?.sedatingMedications ?? null
    const otherSedatingMedications =
      fallRisk.value?.otherSedatingMedications ?? null
    if (sedatingMedications && sedatingMedications.length) {
      // remove 'Other' from array
      const filtered = sedatingMedications.filter(
        (val: SedatingMedications) => val !== SedatingMedications.OTHER
      )
      // format each med and separate with a bullet point
      const formatted = arrayToStringDisplay(filtered)
      if (otherSedatingMedications) {
        // format the other string to replace possible commas with a bullet point
        const formattedOther = formatStringDisplay(otherSedatingMedications)
        return formatted.length
          ? formatted + ' • ' + formattedOther
          : formattedOther
      }
      return formatted
    }
    return '-'
  })

  const additionalInformationDisplay = computed(() => {
    return fallRisk.value?.additionalContext ?? null
  })

  const lastUpdatedDisplay = computed(() => {
    const lastUpdatedAtStr = formatLastUpdatedStr(fallRisk.value)
    return lastUpdatedAtStr ?? '-'
  })

  const editModalInitialData = computed(() => ({
    fallInLastTwelveMonths: fallRisk.value?.fallInLastTwelveMonths,
    fearOfFalling: fallRisk.value?.fearOfFalling,
    hasPeripheralNeuropathy: fallRisk.value?.hasPeripheralNeuropathy,
    sedatingMedications: fallRisk.value?.sedatingMedications,
    otherSedatingMedications: fallRisk.value?.otherSedatingMedications,
    unsteadyWalkingOrStanding: fallRisk.value?.unsteadyWalkingOrStanding,
    usesDmeAssistForAmbulation: fallRisk.value?.usesDmeAssistForAmbulation,
    anticoagulation: fallRisk.value?.anticoagulation,
    otherAnticoagulation: fallRisk.value?.otherAnticoagulation,
    additionalContext: fallRisk.value?.additionalContext,
  }))

  const refetchFallRisk = async () => {
    await getPatientFallRisk(patientId.value)
  }

  /**
   * open/close edit modal
   */
  function toggleFallRiskEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(async () => {
    await getPatientFallRisk(patientId.value)
  })

  return {
    patientId,
    fallRiskId,
    // display values
    lastUpdatedDisplay,
    fallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay,
    anticoagulationDisplay,
    sedatingMedicationsDisplay,
    additionalInformationDisplay,
    // functions to format display values
    arrayToStringDisplay,
    formatStringDisplay,
    // fall risk edit modal
    toggleFallRiskEdit,
    showEditModal,
    editModalInitialData,
    refetchFallRisk,
  }
}
