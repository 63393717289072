<template>
  <div class="italic my-3">
    <span v-if="statusReason === StatusReason.MANUALLY_SET">
      Manually set to {{ readableStatus }} {{ readableSubStatusReason }}
    </span>

    <span v-else-if="statusReason === StatusReason.BLOCKED_BY_MIN_TIME">
      {{
        showSurfacedStatusReason
          ? readableSubStatusReason
          : 'Set to Blocked due to the minimum time delay set in the playbook.'
      }}
      It will be unblocked on {{ readableMinStartTime }}.
    </span>

    <span v-else-if="statusReason === StatusReason.BLOCKED_BY_UPSTREAM">
      {{
        showSurfacedStatusReason
          ? readableSubStatusReason
          : 'Set to Blocked due to a subtask dependency within the playbook.'
      }}
    </span>

    <span v-else-if="statusReason === StatusReason.PROPEGATED_FROM_PARENT">
      {{
        showSurfacedStatusReason
          ? readableSubStatusReason
          : 'Set to ' +
            readableStatus +
            'because that is the status of the task that contains it.'
      }}
    </span>

    <span v-else-if="statusReason === StatusReason.STALE_CLEANUP">
      {{
        showSurfacedStatusReason
          ? readableSubStatusReason
          : 'Set to ' +
            readableStatus +
            ' via “Stale Subtask Cleanup” automation on ' +
            readableStatusUpdatedAt +
            '.'
      }}
    </span>
    <span v-else-if="statusReason === StatusReason.AUTOTEXTING">
      {{
        showSurfacedStatusReason
          ? readableSubStatusReason
          : 'Set to ' +
            readableStatus +
            ' via texting automation system on ' +
            readableStatusUpdatedAt +
            '.'
      }}
    </span>

    <SubtaskAutotriggerReason
      v-else-if="statusReason === StatusReason.AUTOTRIGGER && updatingTriggerId"
      :subtask="subtask"
      :trigger="updatingTrigger"
      :trigger-id="updatingTriggerId"
      :is-create="false"
    />

    <!-- Unexpected case, but added for completeness -->
    <span v-else-if="statusReason === StatusReason.AUTOTRIGGER">
      {{
        showSurfacedStatusReason
          ? readableSubStatusReason
          : 'Set to ' +
            readableStatus +
            ' via autotrigger on ' +
            readableStatusUpdatedAt +
            '.'
      }}
    </span>

    <!--Creation reason-->
    <SubtaskAutotriggerReason
      v-if="generatingTriggerId"
      :trigger="generatingTrigger"
      :trigger-id="generatingTriggerId"
      :is-create="true"
      :subtask="subtask"
    />
    <!-- Use a v-if with staff name to make sure it has loaded -->
    <span v-else-if="creatingStaffName"
      >This subtask was created due to an action by {{ creatingStaffName }} on
      {{ readableCreatedAt }}.</span
    >
    <span v-else-if="subtask.createdBy === SYSTEM_USER_UUID"
      >This subtask was created automatically by the system on
      {{ readableCreatedAt }}.</span
    >
    <span v-else
      >This subtask was created due to an action by {{ subtask.createdBy }} on
      {{ readableCreatedAt }}.</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import SubtaskAutotriggerReason from '@/legacy/components/patient/pathways/subtaskReason/SubtaskAutotriggerReason.vue'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import setup from './controller'

export default defineComponent({
  components: { SubtaskAutotriggerReason },
  props: {
    subtask: { type: Object as PropType<Subtask>, required: true },
  },
  setup,
})
</script>
