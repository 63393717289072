import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useMedicalConditionAttrsRefApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  MedicalCondition,
  MedicalConditionAttributeReference,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import { useLastUpdatedMedicalConditionApi } from './shared/store'

import { MEDICAL_CONDITIONS_PAGE_LENGTH } from './shared/types'

/**
 * Retrieve the attribute refs for provided ref ids.
 * Returns null if no data is returned.
 * @param medicalConditionAttributeIds
 */
export async function getMedicalConditionAttrRefs(
  medicalConditionAttributeIds: string[] = []
): Promise<MedicalConditionAttributeReference[] | null> {
  const medicalConditionAttrsRefApi = useMedicalConditionAttrsRefApi()
  const attsRefRes = await medicalConditionAttrsRefApi.list({
    params: {
      filter_med_condition_attr_ref_ids: medicalConditionAttributeIds,
      filter_is_active: true,
      page_length: MEDICAL_CONDITIONS_PAGE_LENGTH,
    },
  })
  if (!attsRefRes?.data?.length) {
    return null
  }

  if (medicalConditionAttrsRefApi.error) {
    const error = medicalConditionAttrsRefApi.error
    useNotificationStore().setNotification({
      message: `Error fetching medical condition attributes refs`,
      type: NotificationType.WARNING,
      error: `Error fetching medical condition attributes refs: ${error}`,
    })
  }
  return attsRefRes.data
}

/**
 * Retrieve the patient medical conditions for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getLastUpdatedMedicalCondition(
  patientId: string
): Promise<MedicalCondition[] | null> {
  const medicalConditionsApi = useLastUpdatedMedicalConditionApi()
  const medicalConditionsRes = await medicalConditionsApi.list({
    params: {
      filter_patient_ids: [patientId],
      page_length: 1,
      page_number: 1,
      sort_by: ['updatedAt,desc'],
    },
  })
  if (!medicalConditionsRes?.data?.length) {
    return null
  }

  if (medicalConditionsApi.error) {
    const error = medicalConditionsApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching last updated medical condition information',
      type: NotificationType.WARNING,
      error: `Error fetching patient medical conditions: ${error}`,
    })
  }
  return medicalConditionsRes.data
}
