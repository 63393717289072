<template>
  <TDropdown
    v-if="type === 'multiselect' || type === 'select'"
    v-model="dirty"
    class="w-full"
    :multiple="type === 'multiselect' ? true : false"
    :input-id="`add-${parentName}-${field}-${type}`"
    :placeholder="label ?? startCase(field)"
    option-label="label"
    option-value="value"
    :options="options || loadedOptions"
    :max-selected-labels="0"
    v-bind="inputAttrs"
    @update:model-value="updateDirty"
  />
  <div
    v-else-if="['model-multiselect', 'model-select'].indexOf(type) >= 0"
    class="flex justify-between my-2 items-end"
  >
    <TDropdown
      v-if="type === 'model-multiselect' || type === 'model-select'"
      v-model="dirty"
      :multiple="type === 'model-multiselect' ? true : false"
      :input-id="`add-${parentName}-${field}-${type}`"
      :placeholder="label ?? startCase(field)"
      option-label="label"
      option-value="value"
      :options="loadedOptions"
      v-bind="inputAttrs"
      :max-selected-labels="0"
      @update:model-value="updateDirty"
    />
    <div class="model-options">
      <TMSecondaryButton icon="refresh" @click="refresh" />
      <router-link :to="{ name: modelLink }" target="_blank">
        <TMSecondaryButton label="+ Add" />
      </router-link>
    </div>
  </div>
  <TDatePicker
    v-else-if="type === 'date'"
    v-model="dirty"
    :name="`add-${parentName}-${field}-date`"
    :label="label ?? startCase(field)"
    :is-datetime="false"
    v-bind="inputAttrs"
    @update:model-value="updateDirty"
  />
  <TDatePicker
    v-else-if="type === 'daterange'"
    v-model="dirty"
    :name="`add-${parentName}-${field}-daterange`"
    :label="label ?? startCase(field)"
    :is-datetime="false"
    range
    v-bind="inputAttrs"
    @update:model-value="updateDirtyDateRange"
  />
  <TTextarea
    v-else-if="type === InputType.TEXTAREA"
    v-model="dirty"
    :name="`add-${parentName}-${field}-input`"
    :label="label ?? startCase(field)"
    v-bind="inputAttrs"
    @update:model-value="updateDirty"
  />
  <TInputText
    v-else
    v-model="dirty"
    :name="`add-${parentName}-${field}-input`"
    :label="label ?? startCase(field)"
    type="text"
    v-bind="inputAttrs"
    @update:model-value="updateDirty"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { InputType } from '@thyme/nashville/src/types/inputs'
import startCase from 'lodash/startCase'
import { DateTime } from 'luxon'
import { defineComponent, onMounted, PropType, ref } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TDatePicker from './TDatePicker.vue'

export default defineComponent({
  components: {
    TTextarea,
    TInputText,
    TDatePicker,
    TMSecondaryButton,
    TDropdown,
  },
  props: {
    data: {
      type: Object as PropType<any>,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    parentName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    // multiselect and select type props
    options: {
      type: Array as PropType<string[] | string[][]>,
      default: null,
    },
    // model-multiselect and model-select Type props
    optionsFn: {
      type: Function,
      default: null,
    },
    modelLink: {
      type: String,
      default: null,
    },
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['updateDirty'],
  setup(props, context) {
    const dirty = ref(props.data)
    const updateDirty = (newVal: any) => context.emit('updateDirty', newVal)
    const updateDirtyDateRange = (newVal: [Date, Date]) => {
      return updateDirty([
        newVal[0],
        DateTime.fromJSDate(newVal[1]).endOf('day').toJSDate(),
      ])
    }
    // Model Type Functionality
    const loadedOptions = ref<string[] | string[][]>([])
    const refresh = async () => {
      loadedOptions.value = await props.optionsFn()
    }
    onMounted(() => (props.optionsFn ? refresh() : null))

    return {
      InputType,
      dirty,
      loadedOptions,
      refresh,
      startCase,
      updateDirty,
      updateDirtyDateRange,
    }
  },
})
</script>

<style lang="scss">
.model-options {
  @apply flex gap-2 ml-2;
  flex-shrink: 0;
}
</style>
