import { ModalSize } from '@thyme/nashville/src/types/modals'
import { computed, ExtractPropTypes } from 'vue'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { AddInsuranceForm, Insurance } from '@/legacy/types/patients/insurances'
import { createInsurance, updateInsurance } from './queries'
import { CREATE_INSURANCE_PROPS } from './types'

type CreateInsuranceSetupProps = ExtractPropTypes<typeof CREATE_INSURANCE_PROPS>

/**
 *
 * @param props
 * @param context
 */
export function setup(props: CreateInsuranceSetupProps, context: any) {
  const modalTitle = computed(
    () => `${props.initialData ? 'Edit' : 'Add'} Insurance`
  )

  /**
   * close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   * Function to submit udpated/created insurance
   * @param insuranceChange
   */
  async function submit(insuranceChange: AddInsuranceForm) {
    let newInsurance: Insurance | undefined
    if (props.initialData) {
      newInsurance = await updateInsurance(props.initialData, insuranceChange)
    } else {
      newInsurance = await createInsurance(insuranceChange)
    }
    if (newInsurance) {
      usePatientStore().updateInsurancesInPlace(
        newInsurance.insuranceId,
        newInsurance
      )
    }
    close()
  }

  return {
    modalTitle,
    close,
    submit,
    ModalSize,
  }
}
