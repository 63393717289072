/**
 * Takes url string and injects id list into 0+ ":id" substring locations
 * If an id is passed but no ":id" substrings, it will append to url instead
 * If no ids are passed, it will return the url as is.
 * @param url
 * @param ids
 */
export function createUrlWithId(url: string, ids: string[] | string | null) {
  let wrappedIds: string[] = ids as []
  if (typeof ids === 'string' || ids instanceof String) {
    wrappedIds = [ids as string]
  }
  let idPlaceholderCount = (url.match(/:id/g) ?? []).length
  if (
    idPlaceholderCount &&
    !(
      idPlaceholderCount === wrappedIds.length ||
      idPlaceholderCount === wrappedIds.length - 1
    )
  ) {
    throw new Error(`${idPlaceholderCount} id(s) required for this url: ${url}`)
  }
  // Append ID to the end of the URL if there are more IDs than :id placeholders.
  if (wrappedIds && idPlaceholderCount < wrappedIds.length) {
    url += '/:id'
    idPlaceholderCount++
  }
  if (idPlaceholderCount) {
    let finalUrl = url
    wrappedIds.forEach((id) => (finalUrl = finalUrl.replace(':id', id)))
    return finalUrl
  }
  return url
}
