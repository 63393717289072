<template>
  <div v-if="showZoomCallData && zoomData && zoomData.length > 0" class="pt-2">
    <div class="flex items-center">
      <h2
        data-cy="zoom-data-header"
        class="border-nash-neutral300 flex items-center content-center mr-2"
      >
        Zoom Data
      </h2>
      <TLabel
        v-if="zoomData[0].zoomCallData?.duration"
        :label="formatCallDuration(+zoomData[0].zoomCallData.duration)"
        :size="LabelSize.Small"
        class="call-duration-chip"
      />
    </div>

    <label class="font-bold text-xl block mb-2">Recordings</label>
    <TMSecondaryButton
      v-if="!hasFetchedRecording"
      label="Get Recording"
      @click="getCallRecording"
    />
    <div v-if="hasFetchedRecording && zoomRecordingData">
      <audio
        ref="audio"
        controls
        preload="metadata"
        :src="zoomRecordingData.fileUrl"
      ></audio>
    </div>
    <div
      v-else-if="
        hasFetchedRecording && !zoomRecordingData && !isLoadingRecording
      "
    >
      No recordings available for this call.
    </div>
    <label class="font-bold text-xl block my-2"> Transcripts </label>
    <TMSecondaryButton
      v-if="!hasFetchedTranscript"
      label="Get Transcript"
      @click="getCallTranscript"
    />
    <div
      v-if="hasFetchedTranscript && zoomTranscriptData"
      id="zoom-transcript-wrapper"
    >
      <div v-for="(item, index) in zoomTranscriptData?.timeline" :key="index">
        <div class="flex mb-3">
          <div class="flex-col leading-2">
            <div
              v-for="(user, idx) in item.users"
              :key="idx"
              class="font-bold inline mr-1"
            >
              {{ user?.username }}
            </div>
            <div v-if="!item.users || item.users.length === 0">
              <b>Unknown Speaker</b>
            </div>
            <div class="text-sm">
              {{
                formatStartTalkingDateTime(
                  zoomTranscriptData?.recordingStart,
                  item?.startTs
                )
              }}
            </div>
            <div>
              {{ item?.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        hasFetchedTranscript && !zoomTranscriptData && !isLoadingTranscript
      "
    >
      No transcripts available for this call.
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { LabelSize } from '@thyme/nashville/src/types/labels'
import { defineComponent, onMounted } from 'vue'
import TLabel from '@/legacy/nashville/TLabel.vue'
import { getZoomStoreVars, getZoomVars, getUtilFuncs } from './controller'

export default defineComponent({
  components: {
    TLabel,
    TMSecondaryButton,
  },
  setup() {
    const {
      zoomRecordingData,
      isLoadingRecording,
      zoomTranscriptData,
      isLoadingTranscript,
    } = getZoomStoreVars()

    const {
      zoomData,
      showZoomCallData,
      hasFetchedRecording,
      hasFetchedTranscript,
      getCallRecording,
      getCallTranscript,
      zoomMount,
    } = getZoomVars()

    const { formatStartTalkingDateTime, formatCallDuration } = getUtilFuncs()

    onMounted(async () => {
      await zoomMount()
    })

    return {
      zoomRecordingData,
      isLoadingRecording,
      zoomTranscriptData,
      isLoadingTranscript,
      zoomData,
      showZoomCallData,
      hasFetchedRecording,
      hasFetchedTranscript,
      getCallRecording,
      getCallTranscript,
      zoomMount,
      formatStartTalkingDateTime,
      formatCallDuration,
      LabelSize,
    }
  },
})
</script>
