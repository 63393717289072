<template>
  <TTable
    ref="table"
    title="Locations"
    name="PracticeLocations"
    class="px-0"
    :rows="10"
    :columns="columns"
    :api-store="usePracticeLocationApi"
    :params="{
      filter_practice_ids: [practiceId],
      parts: ['entity.addresses'],
    }"
    @data-loaded="fetchPhones"
  />
</template>

<script lang="ts">
import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { formatPhone } from '@/legacy/libs/format'
import TTable from '@/legacy/nashville/table/TTable.vue'
import {
  usePracticeLocationApi,
  usePracticeStore,
} from '@/legacy/store/modules/practice'
import { PracticeLocation } from '@/legacy/types/practices'

export default defineComponent({
  components: { TTable },
  setup() {
    const route = useRoute()
    const practiceId = route.params.practiceId

    const addressFromLocation = (location: PracticeLocation) => {
      const addresses = location.entity?.addresses
      if (addresses && addresses.length) {
        return addresses[0]
      }
    }
    const table = ref<{ getData: () => object } | null>(null)
    const fetchPhones = () => {
      void usePracticeStore().fetchAndSetPracticeLocationPhones()
    }
    const columns: ColumnOptions[] = [
      {
        field: 'address',
        header: 'Addresses',
        display: (_: any, row: PracticeLocation) =>
          [
            addressFromLocation(row)?.streetAddressLine1,
            addressFromLocation(row)?.streetAddressLine2,
          ]
            .filter((n) => n)
            .join(', '),
      },
      {
        field: 'city',
        header: 'City',
        display: (_: any, row: PracticeLocation) =>
          addressFromLocation(row)?.city ?? '',
      },
      {
        field: 'state',
        header: 'State',
        display: (_: any, row: PracticeLocation) =>
          addressFromLocation(row)?.state ?? '',
      },
      {
        field: 'zip',
        header: 'Zip Code',
        display: (_: any, row: PracticeLocation) =>
          addressFromLocation(row)?.zip ?? '',
      },
      {
        field: 'phoneNumber',
        header: 'Phone Number',
        display: (_: any, row: PracticeLocation) => {
          const phones = row.entity?.phoneNumbers
          if (phones && phones.length > 0) {
            return formatPhone(phones[0].phoneNumber.phoneNumber)
          }
          return 'None'
        },
      },
    ]
    return {
      columns,
      table,
      practiceId,
      usePracticeLocationApi,
      fetchPhones,
    }
  },
})
</script>
