import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { computed } from 'vue'
import {
  PhoneNumberNameAndType,
  PhoneNumberNameMap,
} from '@/legacy/types/entities/phoneNumbers'
import {
  renderSharedNumberWarningMessageForContacts,
  renderSharedNumberWarningMessageForPatient,
} from '../../../lib/sharedPhoneNumberVerificationParts'

/**
 *
 * @param props
 */
export default function (props: any) {
  const totalWarnings = computed(
    () => Object.keys(filteredPhoneNumberMaps.value ?? {}).length
  )
  const triggerString = computed(() =>
    totalWarnings.value
      ? `${totalWarnings.value} ${
          totalWarnings.value > 1 ? 'warnings' : 'warning'
        }`
      : 'Unknown number of warnings'
  )

  const filteredPhoneNumberMaps = computed(() => {
    const filtered: PhoneNumberNameMap = {}
    const phoneNumberMapKeys = Object.keys(props.phoneNumberMaps)
    phoneNumberMapKeys.forEach((key: string) => {
      if (props.phoneNumberMaps[key].length > 1) {
        filtered[key] = props.phoneNumberMaps[key]
      }
    })

    return Object.keys(filtered).length ? filtered : null
  })

  /**
   *
   * @param vals
   * @param phoneNumStr
   */
  function renderLabel(
    vals: PhoneNumberNameAndType[],
    phoneNumStr: string | number
  ) {
    if (props.isContactModal) {
      return renderSharedNumberWarningMessageForContacts(vals)
    }
    return renderSharedNumberWarningMessageForPatient(vals, phoneNumStr)
  }

  return {
    filteredPhoneNumberMaps,
    triggerString,
    renderLabel,
    MessageSeverity,
    totalWarnings,
  }
}
