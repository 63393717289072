import {
  FilterMetaType,
  nonApplicableFilterStrings,
} from '@thyme/nashville/src/types/tables'
import eq from 'lodash/eq'
import reduce from 'lodash/reduce'

/**
 *
 * @param filterMeta
 */
export function setupAppliedFilters(filterMeta: FilterMetaType) {
  return reduce(
    filterMeta,
    (acc, { value, modalOptions }, key) => {
      // do not count filters not in the filter modal unless specific filter
      if (!modalOptions && nonApplicableFilterStrings.includes(key)) {
        return acc
      }

      // value is a proxy so i can't directly check if its an empty array
      if (value && !eq(value?.length, 0)) {
        acc.push(key)
      }

      return acc
    },
    [] as string[]
  )
}
