const MS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24

/**
 * Returns how many milliseconds are in this many seconds.
 * @param seconds
 */
export function secondsToMs(seconds: number): number {
  return seconds * MS_IN_SECOND
}

/**
 * Returns how many milliseconds are in this many minutes.
 * @param minutes
 */
export function minutesToMs(minutes: number): number {
  return secondsToMs(minutesToSeconds(minutes))
}

/**
 * Returns how many seconds are in this many minutes.
 * @param minutes
 */
export function minutesToSeconds(minutes: number): number {
  return minutes * SECONDS_IN_MINUTE
}

/**
 * Returns how many seconds are in this many hours.
 * @param hours
 */
export function hoursToSeconds(hours: number): number {
  return minutesToSeconds(hours * MINUTES_IN_HOUR)
}

/**
 * Returns how many milliseconds are in this many hours.
 * @param hours
 */
export function hoursToMs(hours: number): number {
  return secondsToMs(hoursToSeconds(hours))
}

/**
 * Returns how many seconds are in this many days.
 * @param days
 */
export function daysToSeconds(days: number): number {
  return hoursToSeconds(days * HOURS_IN_DAY)
}
