<template>
  <div>
    <TMEditableTable
      ref="table"
      name="Medical Providers"
      :rows="10"
      :columns="columns"
      :edit-columns="editColumns"
      :api-store="useProvidersPatientsMapApi"
      sort-field="lastSeen"
      :sort-order="-1"
      :params="params"
      :filters="filters"
      :free-text-filter="true"
      free-text-placeholder="Search by provider name"
      @data-loaded="getFollowUpData"
      @save="save"
    >
    </TMEditableTable>
  </div>
</template>

<script lang="ts">
import {
  ColumnOptions,
  Filter,
  FilterType,
  TablePadding,
} from '@thyme/nashville/src/types/tables'
import { defineComponent, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { stringToDateTime } from '@/legacy/libs/date'
import { arraysToDropdownOptions } from '@/legacy/libs/dropdowns'
import {
  formatDateTimeWithTime,
  formatNameFromProvider,
} from '@/legacy/libs/format'
import { _updateExistingMapProviderPatient } from '@/legacy/libs/mapProviderPatient'
import TMEditableTable from '@/legacy/nashville/table/TMEditableTable.vue'
import { useProvidersPatientsMapApi } from '@/legacy/store/modules/mapProvidersPatients'
import { useProvidersTableApi } from '@/legacy/store/modules/provider'
import { MapProviderPatient } from '@/legacy/types/patients/mapProvidersPatients'

export default defineComponent({
  components: {
    TMEditableTable,
  },
  setup() {
    const route = useRoute()

    const params = computed(() => ({
      filter_patient_ids: [route.params.patientId],
    }))

    const columns: ColumnOptions[] = [
      {
        field: 'providerName',
        header: 'Name',
        style: { 'flex-wrap': 'wrap' },
        truncateLength: 400,
        display: (_: any, row: MapProviderPatient) =>
          displayProviderName(row.providerId),
      },
      {
        field: 'providerSpecialty',
        header: 'Specialty',
        style: { 'flex-wrap': 'wrap' },
        truncateLength: 400,
        display: (_: any, row: MapProviderPatient) =>
          displayPrimarySpecialty(row.providerId) ?? '-',
      },
      {
        field: 'lastSeen',
        header: 'Last Seen',
        sortable: true,
        style: { 'flex-wrap': 'wrap' },
        display: (datetime): string =>
          formatDateTimeWithTime(stringToDateTime(datetime)),
        truncateLength: 400,
      },
      {
        field: 'phoneNumber',
        header: 'Phone Number',
      },
    ]

    const editColumns = [{ field: 'phoneNumber' }]

    const showOncologyOnlyFilter: Filter = {
      value: null,
      matchMode: undefined,
      modalOptions: {
        label: 'Show Oncology Only',
        type: FilterType.Select,
        options: arraysToDropdownOptions([
          ['true', 'Yes'],
          ['false', 'No'],
        ]),
      },
    }

    const filters = {
      oncologyOnly: showOncologyOnlyFilter,
    }

    /**
     *
     * @param patientProviderMaps
     */
    async function getFollowUpData(patientProviderMaps: MapProviderPatient[]) {
      const providerIds = patientProviderMaps.map(
        (mapping) => mapping.providerId
      )
      await useProvidersTableApi().list({
        params: {
          filter_provider_ids: providerIds,
        },
      })
    }

    /**
     *
     * Function to grab and display provider info
     * providerId
     * @param providerId
     */
    function displayPrimarySpecialty(providerId: string | undefined) {
      const dataStream = useProvidersTableApi().data
      if (dataStream && providerId && dataStream[providerId]) {
        return dataStream[providerId].primarySpecialty
      }
      return ''
    }

    /**
     *
     * Function to grab and display provider info
     * providerId
     * @param providerId
     */
    function displayProviderName(providerId: string | undefined) {
      const dataStream = useProvidersTableApi().data
      const dataIsReady =
        dataStream &&
        providerId &&
        dataStream[providerId] &&
        dataStream[providerId].person
      if (dataIsReady) {
        return formatNameFromProvider(dataStream[providerId])
      }
      return ''
    }

    const table = ref<{ getData: () => object } | null>(null)

    const save = async ({ form, existing }: { [key: string]: any }) => {
      const formattedForm = {
        phoneNumber: (form.phoneNumber ?? existing?.value?.phoneNumber ?? '')
          .trim()
          .replace(/\D/g, ''),
      }

      const updatedMapProviderPatient = {
        mapProviderPatientId: existing?.value?.mapProviderPatientId,
        isPcp: existing?.value?.isPcp,
        providerId: existing?.value?.providerId,
        patientId: existing?.value?.patientId,
        lastSeen: existing?.value?.lastSeen,
        phoneNumber: formattedForm.phoneNumber,
      }

      await _updateExistingMapProviderPatient(updatedMapProviderPatient)

      // Refresh table view
      table.value?.getData()
    }

    return {
      columns,
      editColumns,
      useProvidersPatientsMapApi,
      TablePadding,
      params,
      getFollowUpData,
      filters,
      save,
      table,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.p-datatable) {
  padding: 0;
}
</style>
