<template>
  <div>
    <TTextarea
      v-if="type === InputType.TEXTAREA"
      v-model="computedData"
      name="autosave"
      v-bind="$attrs"
    />
    <TInputText v-else v-model="computedData" name="autosave" v-bind="$attrs" />
    <TMSaveIndicator v-if="saveState" :state="saveState" />
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { InputType } from '@thyme/nashville/src/types/inputs'
import {
  defineComponent,
  PropType,
  computed,
  onBeforeUnmount,
  onBeforeMount,
} from 'vue'
import { autosave } from '@/legacy/libs/autosave'
import { thymeListen, thymeTuneOut } from '@/legacy/libs/eventBus'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TMSaveIndicator from '@/legacy/nashville/molecules/TMSaveIndicator.vue'
import { SaveState } from '@/legacy/types/api/api'

export default defineComponent({
  components: {
    TTextarea,
    TInputText,
    TMSaveIndicator,
  },
  props: {
    type: {
      type: String as PropType<InputType>,
      default: InputType.TEXT,
    },
    data: {
      type: String as PropType<string | null>,
      default: null,
    },
    apiCall: {
      type: Function,
      required: true,
    },
    warnUnsavedChanges: {
      type: Boolean,
      default: false,
    },
    saveState: {
      type: Object as PropType<SaveState>,
      default: null,
    },
  },
  setup(props) {
    /**
     *
     */
    function apiCallWithChange() {
      if (dirty.data !== props.data) {
        void props.apiCall(dirty.data)
      }
    }
    const dirty = autosave(apiCallWithChange, { data: props.data })
    const computedData = computed({
      get() {
        return dirty.data ?? props.data ?? ''
      },
      set(newVal: string) {
        dirty.data = newVal
      },
    })

    /**
     *
     * @param e
     */
    function warn(e: Event) {
      if (dirty.data !== props.data && props.warnUnsavedChanges) {
        e.preventDefault()
        // @ts-ignore: some browsers require assigning a string to returnValue
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        e.returnValue = ''
      }
    }

    onBeforeMount(() => {
      thymeListen('beforeunload', warn)
    })
    onBeforeUnmount(() => {
      thymeTuneOut('beforeunload', warn)
    })

    return {
      InputType,
      dirty,
      computedData,
    }
  },
})
</script>
