<template>
  <div
    v-if="componentActual"
    class="flex justify-around items-center border-b border-nash-neutral300 p-5 mb-1"
  >
    <div>
      <span class="flex justify-between items-center gap-4">
        <h2>{{ componentName }}</h2>
        <LegacyTButton
          value="Copy Code"
          :size="ButtonSize.XS"
          @click="copyToClipboard"
        />
      </span>
      <component
        :is="componentActual.default"
        v-bind="componentProps"
        ref="componentState"
        v-model="dirtyModel"
        :class="classText"
      >
        <!-- eslint-disable vue/no-unused-vars, vue/no-v-html, vue/no-v-for-template-key -->
        <!-- this v-html is SAFE. it is only used in admin/debug and not loaded or saved. no XSS vulnerabilities here. -->
        <template v-for="slot in slots" :key="slot" #[slot]="slotProps"
          ><span v-html="slotText[slot]"></span
        ></template>
        <!-- this v-html is SAFE. it is only used in admin/debug and not loaded or saved. no XSS vulnerabilities here. -->
        <span v-if="!slots.length" v-html="slotText['default']" />
        <!-- eslint-enable vue/no-unused-vars, vue/no-v-html, vue/no-v-for-template-key -->
      </component>
    </div>
    <div
      class="border-l border-nash-neutral300 pl-5 grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-4"
    >
      <div v-for="(propOptions, prop) in allProps" :key="prop" class="w-48">
        <span v-tooltip.top="propOptions.tooltip || prop">{{ prop }}</span>
        <div v-if="propOptions.options">
          <!-- @vue-ignore propOptions are SelectOptions -->
          <TSelect
            :name="`${componentName}-${prop}-select`"
            :options="Object.values(propOptions.options)"
            @update:model-value="componentProps[prop] = $event"
          />
        </div>
        <div v-else-if="propOptions.type === Boolean">
          <TToggle
            :checked="componentProps[prop]"
            @update:checked="componentProps[prop] = $event"
          />
        </div>
        <div v-else-if="propOptions.type === String">
          <TInputText
            v-model="componentProps[prop]"
            :name="`${componentName}-${prop}-input`"
          />
        </div>
        <div v-else-if="propOptions.type === Array">
          <TTextarea
            :name="`${componentName}-${prop}-input`"
            :model-value="JSON.stringify(componentProps[prop])"
            @update:model-value="updateArray(prop, $event)"
          />
        </div>
        <div v-else-if="propOptions.type.length">
          <TTextarea
            :name="`${componentName}-${prop}-input`"
            :model-value="JSON.stringify(componentProps[prop])"
            @update:model-value="updateArray(prop, $event)"
          />
        </div>
        <div v-else>{{ propOptions.type }} editing not supported</div>
      </div>
      <TInputText
        v-model="classText"
        :name="`${componentName}-class-text`"
        label="classes"
      />
      <TTextarea
        v-for="slot in slots"
        :key="slot"
        v-model="slotText[slot]"
        :name="`${componentName}-slot-text`"
        :label="`${slot} slot`"
      />
      <TTextarea
        v-if="!slots.length"
        v-model="slotText.default"
        :name="`${componentName}-slot-text`"
        label="default slot"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { SelectOptions } from '@thyme/nashville/src/types/select'
import reduce from 'lodash/reduce'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import TToggle from '@/legacy/nashville/checkbox/TToggle.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'

import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TSelect from '@/legacy/nashville/TSelect.vue'

type Component = {
  modelValue?: any
  ref?: string
  props?: {
    [key: string]: any
    options?: SelectOptions
  }
}

type ComponentWrapper = {
  default: Component
}

export default defineComponent({
  components: { TInputText, TTextarea, TToggle, TSelect, LegacyTButton },
  props: {
    componentName: {
      type: String,
      required: true,
    },
    slots: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    extraPropsFrom: {
      type: String,
      default: null,
    },
    extraAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const dirtyModel = ref<any>(null)
    const componentActual = ref<ComponentWrapper | null>(null)
    const componentSecondary = ref<ComponentWrapper | null>(null)
    const componentProps = ref<{ [key: string]: any }>({})
    const slotText = ref<{ [key: string]: any }>({})
    const classText = ref('')
    const allProps = computed(() => ({
      ...(componentActual.value?.default?.props ?? {}),
      ...(componentSecondary.value?.default?.props ?? {}),
      ...props.extraAttrs,
    }))

    const dynamicImport = async (componentPath: string) => {
      const splitPath = componentPath.split('/')
      if (splitPath.length === 1) {
        return await import(`../../nashville/${splitPath[0]}.vue`)
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (splitPath.length === 2) {
        return await import(
          `../../nashville/${splitPath[0]}/${splitPath[1]}.vue`
        )
      }
      throw new Error(`dynamic import path not supported: ${componentPath}`)
    }
    const updateComponent = async () => {
      componentActual.value = await dynamicImport(props.componentName)

      if (props.extraPropsFrom) {
        componentSecondary.value = await dynamicImport(props.extraPropsFrom)
      }

      componentProps.value = Object.keys(
        componentActual.value?.default.props ?? {}
      ).reduce(
        (accumulator, value) => ({
          ...accumulator,
          [value]: componentActual.value?.default.props
            ? componentActual.value?.default.props[value].default ?? undefined
            : undefined,
        }),
        {}
      )
      slotText.value = props.slots.reduce(
        (accumulator, value) => ({ ...accumulator, [value]: '' }),
        {}
      )
      slotText.value.default = ''
    }
    onMounted(updateComponent)
    watch(() => props.componentName, updateComponent)

    const updateArray = (prop: string | number, arrayString: string) => {
      arrayString = arrayString.replace(/\s/g, '')
      componentProps.value[prop] = JSON.parse(arrayString.replaceAll("'", '"'))
      console.log(componentProps.value[prop])
    }

    const copyToClipboard = () => {
      const splitName = props.componentName.split('/')
      const componentCode = `
<${splitName[splitName.length - 1]}${reduce(
        componentProps.value,
        (acc, val, key) => {
          const componentProps = allProps.value
          if (
            val === undefined ||
            val === (componentProps ?? {})[key]?.default
          ) {
            return acc
          }
          if ((componentProps ?? {})[key]?.type === String) {
            return `${acc}\n  ${key}="${val}"`
          }
          return `${acc}\n  :${key}="${val}"`
        },
        ''
      )}
>${reduce(
        slotText.value,
        (acc, val, key) => {
          if (key === 'default') {
            return `${acc}\n${val}`
          }
          return `${acc}\n<template #${key}="slotProps">${val}</template>`
        },
        ''
      )}</${splitName[splitName.length - 1]}>
      `
      void navigator.clipboard.writeText(componentCode)
      console.log(componentCode)
    }

    return {
      dirtyModel,
      classText,
      componentActual,
      componentProps,
      slotText,
      allProps,
      copyToClipboard,
      updateArray,
      ButtonSize,
    }
  },
})
</script>
