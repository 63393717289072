import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { Entity } from '@/legacy/types/entities/entities'

export const useEntitiesApi = apiStore<Entity, IdMap<Entity>>(
  'entitiesApi',
  '/api/entities',
  {
    transformData: (d: { data: Entity[] }) =>
      idMapTransform({}, 'data', 'entityId', d.data),
  }
)
