import { Timestamps } from '@/legacy/types/global/dates'

export enum ScheduledTimeBlock {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  CUSTOM = 'CUSTOM',
}

// TYPES --------------------
export type PlannedCall = {
  calleeEntityId: string
  communicationId: string
  dueDatetime: string
  timeBlock?: ScheduledTimeBlock | null
  duration?: number | null
  // IMPROVEME: remove after flag showTimeBlocks is removed (MT-2343)
  showTime?: boolean | null
  timeBlockStart: string
  timeBlockEnd: string
} & Timestamps

// INTERFACES --------------------
export type DirtyPlannedCall = Partial<PlannedCall>
