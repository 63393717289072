import { ModalSize } from '@thyme/nashville/src/types/modals'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { editOptionsArray } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientTreatment/shared/types'
import { useSurgeryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { deleteSurgery } from './queries'
import { deleteSurgeryProps } from './types'

type PropsType = ExtractPropTypes<typeof deleteSurgeryProps>

/**
 * Set up the DeleteSurgery component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: surgeriesData } = storeToRefs(useSurgeryApi())
  const surgery = computed(() =>
    surgeriesData.value ? surgeriesData.value[props.surgeryId] : null
  )

  const surgeryDate = computed(() => {
    if (surgery.value && surgery.value.surgeryDate) {
      return stringToDateTime(surgery.value.surgeryDate).toLocaleString(
        DateTime.DATE_SHORT
      )
    }
    return ''
  })

  const surgeryDisplay = computed(() => {
    if (surgery.value) {
      return surgery.value.otherSurgeryType
        ? `Other: ${surgery.value.otherSurgeryType}`
        : surgery.value.surgicalProcedureReference.description
    }
    return ''
  })

  /**
   * close delete modal
   */
  function close() {
    context.emit('close')
  }

  /**
   * delete surgery
   */
  async function onDelete() {
    if (surgery.value) {
      await deleteSurgery(props.surgeryId)
      context.emit('refetch')
    }
  }

  return {
    editOptionsArray,
    ModalSize,
    surgeryDate,
    surgeryDisplay,
    onDelete,
    close,
  }
}
