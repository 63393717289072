<template>
  <h4 class="w-full border-b border-nash-neutral500 my-2">
    External Profile(s)
  </h4>
  <div v-if="shouldShowLinks" class="pt-2 my-2 space-y-1.5">
    <div v-for="linkObj in patientLinks" :key="linkObj.url">
      <a :href="linkObj.url" target="_blank">
        <TMTertiaryButton
          :label="linkObj.label"
          icon-pos="right"
          icon="heroicons:arrow-top-right-on-square"
        />
      </a>
    </div>
  </div>
  <div v-else class="flex flex-col space-y-0.5">
    <p>
      <span class="italic">No external profiles available.</span>
    </p>
  </div>
</template>
<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import { patientExternalLinksProps } from './types'
export default defineComponent({
  components: {
    TMTertiaryButton,
  },
  props: patientExternalLinksProps,
  setup,
})
</script>
