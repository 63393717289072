<template>
  <Transition>
    <TNotification v-if="notification" />
  </Transition>
  <div class="h-full !overflow-scroll">
    <TTable
      ref="table"
      title="All Tasks"
      name="AllTasks"
      data-key="subtaskId"
      class="pb-12"
      :rows="10"
      :rows-per-page="[10, 25]"
      :columns="columns"
      :params="params"
      :api-store="useRemindersApi"
      :filters="filters"
      :free-text-filter="true"
      :selected-items="selectedSubtasks"
      :free-text-options="freeTextOptions"
      :free-text-filter-name="freeTextFilterName"
      free-text-placeholder="Search..."
      @data-loaded="getFollowUpData"
      @update-selected-items="updateSelectedItems"
    >
      <template #filterOptions>
        <TDropdown
          class="bg-nash-neutral000"
          :options="freeTextOptions"
          :initial-value="freeTextFilterName"
          :show-clear="false"
          option-label="label"
          option-value="value"
          @update:model-value="setFreeTextFilterName"
        />
      </template>
      <template #multiSelectActions>
        <TOTableButton
          v-if="selectedSubtasks.length && canEditBulkSubtasks"
          name="AllTasks-table-multi-select"
          icon="heroicons:square-2-stack"
          size="sm"
          label="Bulk Edit"
          @click="toggleBulkEditModal"
        />
      </template>
      <template #startColumns>
        <Column
          v-if="canEditBulkSubtasks"
          selection-mode="multiple"
          header-style="width: 3rem"
        ></Column>
      </template>
      <template #column-dueDatetime="slotProps">
        <div class="flex items-center flex-wrap space-x-2">
          <div v-if="slotProps.row.disablePriority">N/A</div>
          <div v-else>
            <span
              v-if="isSubtaskOverdue(slotProps.row)"
              class="text-nash-brick600 font-bold"
            >
              Overdue
              {{
                formatReminderDatetime(slotProps.row.dueDatetime, slotProps.row)
              }}
            </span>
            <span v-else>
              {{
                formatReminderDatetime(slotProps.row.dueDatetime, slotProps.row)
              }}
            </span>
            <span
              v-if="slotProps.row.hideDatetime"
              v-tooltip.right="
                renderReattemptTooltip(
                  slotProps.row,
                  slotProps.row.hideDatetime
                )
              "
            >
              <TIcon icon="reattempt" color="nash-teal700" />
            </span>
          </div>
        </div>
      </template>
      <template #column-priority="slotProps">
        <div class="flex items-center flex-wrap">
          <div v-if="slotProps.row.disablePriority">N/A</div>
          <div v-else>
            <TIcon :icon="getPriorityIcon(slotProps.row.priority)" />
            <span class="ml-1">
              {{
                SubtaskPriority[
                  slotProps.row.priority as keyof typeof SubtaskPriority
                ]
              }}
            </span>
          </div>
        </div>
      </template>
      <template #column-status="slotProps">
        <TMActionDropdown
          class="dropdown-styles"
          :icon="getSubtaskIconName(slotProps.row.status)"
          :icon-fill-type="
            getSubtaskIconFill(slotProps.row.status) ?? undefined
          "
          name="subtask-status"
          :initial-value="getCurrentSubtaskStatus(slotProps.row.status)"
          option-label="label"
          option-value="val"
          :options="subtaskStatusOptions(slotProps.row.status)"
          :disabled="slotProps.row.status === SubtaskStatus.CLOSED_IN_ERROR"
          :show-clear="false"
          @change="setStatus(slotProps.row, $event.value)"
        />
      </template>
      <template #column-patient="slotProps">
        <router-link
          v-if="patientsData"
          :to="slotProps.column.linkFn(slotProps.column, slotProps.row)"
          data-cy="patientsData-table-name"
        >
          {{
            slotProps.column.display(
              patientsData[slotProps.row.memberId],
              slotProps.row
            )
          }}
        </router-link>
      </template>
    </TTable>
    <SubtaskReattemptModal
      v-if="selectedReattemptSubtask"
      :subtask="selectedReattemptSubtask"
      :is-open="showReattemptModal"
      @close="closeReattemptModal"
      @callback-fn="getData"
    />
    <SubtaskSubstatusModal
      v-if="
        showSurfacedStatusReason && subtaskStatus && selectedSubStatusSubtask
      "
      :subtask="selectedSubStatusSubtask"
      :status="subtaskStatus"
      :is-open="showSubStatusModal"
      @close="showSubStatusModal = false"
      @callback-fn="getData"
    />
    <BulkEditModal
      v-if="selectedSubtasks.length"
      :is-open="showBulkEditModal"
      :selected-subtasks="selectedSubtasks"
      @close="toggleBulkEditModal"
      @callback-fn="getData"
      @clear-selected-subtasks="resetSelectedSubtasks"
    />
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import Column from 'primevue/column'

import { defineComponent } from 'vue'
import SubtaskReattemptModal from '@/legacy/components/patient/pathways/SubtaskReattemptModal.vue'
import SubtaskSubstatusModal from '@/legacy/components/patient/pathways/SubtaskSubStatusModal/SubtaskSubStatusModal.vue'
import BulkEditModal from '@/legacy/components/reminders/modals/BulkEditModal/BulkEditModal.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TMActionDropdown from '@/legacy/nashville/dropdown/TMActionDropdown.vue'
import TOTableButton from '@/legacy/nashville/table/TOTableButton.vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'

import setup from './controller'

export default defineComponent({
  components: {
    SubtaskSubstatusModal,
    TNotification,
    TDropdown,
    BulkEditModal,
    TOTableButton,
    Column,
    SubtaskReattemptModal,
    TTable,
    TMActionDropdown,
    TIcon,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.chip {
  @apply flex gap-1 px-2 mx-2 rounded-full border border-solid cursor-default;
}

.language-chip {
  @apply border-nash-purple200;
}
.dropdown-styles {
  @apply min-w-fit hover:bg-nash-purple100;
  border-width: 0px !important;
}
</style>
