<template>
  <div>
    <TModal
      :is-visible="true"
      :size="ModalSize.SM"
      title="Delete TOC"
      @close="close"
    >
      <div class="flex pb-2 items-center">
        <div>Are you sure you want to delete this TOC event?</div>
      </div>
      <div class="toc-wrapper">
        <div class="flex justify-between items-center w-full">
          <div class="flex flex-col overflow-hidden">
            <div class="space-y-2 flex flex-col">
              <div class="flex flex-row items-center">
                <h5>
                  {{ deletingToc.dateDisplay }}
                </h5>
              </div>
              <div v-if="deletingToc.metadataDisplay">
                {{ deletingToc.metadataDisplay }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="toc-delete-cancel"
          @click="close"
        />
        <TMDangerButton name="toc-delete" label="Delete" @click="onDelete" />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMDangerButton from '@nashville/button/TMDangerButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { deleteTocProps } from './types'

export default defineComponent({
  components: {
    TModal,
    TMSecondaryButton,
    TMDangerButton,
  },
  props: deleteTocProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.toc-wrapper {
  @apply flex justify-between items-center;
  @apply py-6 px-4;
  @apply border border-nash-neutral400 rounded-lg;
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
