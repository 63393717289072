<template>
  <TCollapse v-if="messages.length" :disable-justify-between="true">
    <template #trigger>
      <div
        v-if="totalMessages"
        class="flex-inline space-y-1 font-bold text-nash-neutral700 py-2"
      >
        {{ `${totalMessages} ${totalMessages > 1 ? 'messages' : 'message'}` }}
      </div>
      <div v-else class="flex-inline space-y-1 font-bold text-nash-neutral700">
        Unknown number of messages
      </div>
    </template>
    <template #content>
      <div class="border-l-2 border-nash-neutral400 block m-0 pl-2 space-y-2">
        <div v-for="message in messages" :key="message.sid">
          <div class="inline-flex flex-row space-x-2">
            <div
              class="inline-flex items-center font-bold text-nash-neutral700"
            >
              {{
                isPatient(message.author, currentPhoneNumberStr)
                  ? getTexteeNameOrFormatNumber(message.author, smsThreadPeople)
                  : getStaffNameFromId(message.author ?? '') ??
                    'Thyme Care Staff'
              }}
            </div>
            <div class="font-normal text-nash-neutral700">
              {{ message.dateUpdated?.toLocaleDateString('en-US') }}
              {{
                message.dateUpdated?.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                }) ?? ''
              }}
            </div>
          </div>

          <div class="m-0">
            <div v-if="message.attachedMedia?.length">
              <div
                v-for="media in message.attachedMedia"
                :key="media.sid"
                class="break-words text-nash-neutral900"
              >
                {{ media.contentType }}:
                {{ media.filename ?? 'Unknown filename' }}
              </div>
            </div>
            <div
              v-if="!message.attachedMedia || message.body"
              class="break-words text-nash-neutral900"
            >
              {{ message.body }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </TCollapse>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import TCollapse from '@/legacy/nashville/TCollapse.vue'
import { Person } from '@/legacy/types/entities/people'
import setup from './controller'

export default defineComponent({
  components: { TCollapse },
  props: {
    messages: {
      type: Object as PropType<any>,
      required: true,
    },
    currentPhoneNumberStr: {
      type: String,
      required: true,
    },
    smsThreadPeople: {
      type: Array as PropType<Person[]>,
      required: true,
    },
  },
  setup,
})
</script>
