import { createProp } from '@thyme/nashville/src/utils/designTokens'
import { nashColors } from '@thyme/nashville/tailwind.config'

// ENUMS --------------------
export enum Padding {
  SM = 'sm',
  MD = '', // Default
  LG = 'lg',
}

export enum Size {
  XS = 'xs',
  SM = 'sm',
  MD = '', // Default
  LG = 'lg',
  XL = 'xl',
}

export enum Actions {
  Primary = '', // Default
  Secondary = 'secondary',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Help = 'help',
  Danger = 'danger',
}

// CONSTANTS --------------------
export const paddingProp = { padding: createProp(Padding) }
export const sizeProp = { size: createProp(Size) }
export const actionProp = { action: createProp(Actions) }

// new colors
export const ColorPalettes = {
  ...Object.keys(nashColors).reduce((acc, key: string) => {
    ;(acc as { [key: string]: string })[`nash-${key}`] = `nash-${key}`
    return acc
  }, {}),
}
export const colorProp = { color: createProp(ColorPalettes) }
