<template>
  <div class="mb-10 relative">
    <TSpinner
      v-if="isLoading"
      :name="`${nonCallType}-comm-sidebar`"
      :partial-page="true"
    />
    <div class="inline-flex space-x-2 items-center mb-5 mt-5 w-full">
      <div class="inline-flex items-center content-center w-72">
        <h2
          :data-cy="`${nonCallType}-comm-sidebar-header`"
          class="border-nash-neutral300 flex items-center content-center"
        >
          {{ capitalize(nonCallType) }}
        </h2>
      </div>
      <div
        v-if="commId"
        class="inline-flex items-center content-center w-full justify-end"
      >
        <TMSecondaryButton
          :name="`delete-${nonCallType}`"
          icon="trash"
          @click="deleteComm"
        />
      </div>
    </div>
    <div>
      <table class="is-fullwidth table">
        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Completed By</label
            >
          </td>
          <td>
            <TSelect
              name="comm-staff"
              :options="staffIdOptions"
              :model-value="dirtyCommunication.responsibleStaffId ?? ''"
              expanded
              @update:model-value="
                (v) => {
                  dirtyCommunication.responsibleStaffId = v
                  updateComm(SidebarDropdownTypeActions.staff)
                }
              "
            />
          </td>
        </tr>

        <tr>
          <td class="is-vcentered">
            <label class="is-inline-block has-text-weight-bold"
              >Completed On</label
            >
          </td>
          <td>
            <TDatePicker
              name="comm-completed-date"
              is-datetime
              :model-value="
                dirtyCommunication.completedDatetime
                  ? stringTojsDateTime(dirtyCommunication.completedDatetime)
                  : new Date()
              "
              show-now-button
              @update:model-value="
                (v) => {
                  dirtyCommunication.completedDatetime = jsDateTimeToString(v)
                  updateComm(CommSidebarActionEnums.completedDate)
                }
              "
            />
          </td>
        </tr>
      </table>

      <NotesSection
        :dirty-communication="dirtyCommunication"
        :save-state="commSaveState"
        @create-or-update="updateComm"
        @update-notes-for-dirty-comm="updateNotesForDirtyComm"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent, PropType } from 'vue'
import NotesSection from '@/legacy/components/NotesSection.vue'

import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TSelect from '@/legacy/nashville/TSelect.vue'

import { SaveState } from '@/legacy/types/api/api'
import { CommunicationType } from '@/legacy/types/communications/communications'
import setup from './controller'

export default defineComponent({
  components: {
    TSpinner,
    TMSecondaryButton,
    NotesSection,
    TSelect,
    TDatePicker,
  },
  props: {
    commSaveState: {
      type: Object as PropType<SaveState>,
      required: true,
    },
    nonCallType: {
      type: String as PropType<CommunicationType>,
      required: true,
    },
  },
  setup,
})
</script>
