<template>
  <TModal
    :is-visible="true"
    :title="modalTitle"
    :size="ModalSize.SM"
    :flex="true"
    :allow-overflow="true"
    @close="close"
  >
    <template #preTitle>
      <TIcon class="mr-2" icon="chevronLeft" @click="close" />
    </template>

    <!-- IMPROVEME(MT-3417) Use newer form patterns. -->
    <InsuranceForm
      :creating="!initialData"
      :insurance="initialData"
      @submit="submit"
    />
  </TModal>
</template>
<script lang="ts">
import TIcon from '@thyme/nashville/src/components/icon/TIcon.vue'
import { defineComponent } from 'vue'
import InsuranceForm from '@/legacy/components/patient/sidebar/forms/InsuranceForm.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { CREATE_INSURANCE_PROPS } from './types'

export default defineComponent({
  name: 'CreateInsurance',
  components: {
    InsuranceForm,
    TIcon,
    TModal,
  },
  props: CREATE_INSURANCE_PROPS,
  emits: ['close'],
  setup,
})
</script>
