import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { retrieveCommunicationById } from '@/legacy/components/patient/communicationV2/lib/communications'
import { RescheduleReattemptSavePayload } from '@/legacy/components/patient/communicationV2/shared/types'
import { formatNameFromPerson } from '@/legacy/libs/format'
import { normalize } from '@/legacy/libs/store'
import router from '@/legacy/router'
import {
  useCallDispositionApi,
  useCommunicationApi,
  useCommunicationsStore,
  usePlannedCallApi,
} from '@/legacy/store/modules/communications'
import { usePathwaysJobsApi } from '@/legacy/store/modules/jobs'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'

import { useReattemptStore } from '@/legacy/store/modules/reattempts'
import {
  useBulkEditSubtasksApi,
  useSubtasksStore,
} from '@/legacy/store/modules/subtasks'
import { SaveState, newSaveState } from '@/legacy/types/api/api'
import {
  Communication,
  CommunicationDisplayType,
  CommunicationSubtype,
  CommunicationType,
  ModalType,
} from '@/legacy/types/communications/communications'
import { JobStatus } from '@/legacy/types/jobs/jobs'
import {
  NotificationActions,
  NotificationType,
} from '@/legacy/types/notifications'
import { BulkEditSubtaskData } from '@/legacy/types/pathways/subtasks'
import {
  createCommunicationAndPlannedCall,
  updateCommunicationAndPlannedCall,
} from '../../lib/reattempts'
import {
  getCurrentPatientId,
  retrieveCommunicationSubType,
} from '../../lib/sharedCommunicationParts'
import { linkSubtasks, delinkSubtask } from '../../lib/subtaskMaps'

export enum SidebarType {
  CommunicationsMenu = '',
  CallDisposition = 'CallDisposition',
  PlannedCall = 'PlannedCall',
  UnplannedCall = 'UnplannedCall',
  EmailAddress = 'EmailAddress',
  OtherCommunication = 'OtherCommunication',
  Fax = 'FaxCommunication',
}

/**
 * SIDEBAR -------------------------------
 */
function getSidebarVars() {
  const { communicationType } = storeToRefs(useCommunicationsStore())
  const { hasOpenFormsortForms } = getSubtaskVars()

  const sidebarType = computed(() =>
    communicationType.value
      ? getSidebarFromCommType(communicationType.value)
      : SidebarType.CommunicationsMenu
  )

  const isCallType = computed(
    () =>
      sidebarType.value === SidebarType.PlannedCall ||
      sidebarType.value === SidebarType.CallDisposition ||
      sidebarType.value === SidebarType.UnplannedCall
  )

  /**
   *
   * Function that takes in a communication type
   * and returns the matched sidebar component type to render.
   * @param commType
   */
  function getSidebarFromCommType(commType: CommunicationDisplayType) {
    if (commType.subtype === CommunicationSubtype.Planned) {
      return SidebarType.PlannedCall
    } else if (commType.subtype === CommunicationSubtype.Disposition) {
      return SidebarType.CallDisposition
    } else if (commType.type === CommunicationType.Call) {
      return SidebarType.UnplannedCall
    } else if (commType.type === CommunicationType.Email) {
      return SidebarType.EmailAddress
    } else if (commType.type === CommunicationType.Fax) {
      return SidebarType.Fax
    } else if (commType.type === CommunicationType.Other) {
      return SidebarType.OtherCommunication
    } else {
      useNotificationStore().setNotification({
        message: 'Unknown communication type.',
        type: NotificationType.DANGER,
      })
      return
    }
  }

  /**
   *
   * Function to trigger communications and calls subtype stores reset.
   */
  function resetData() {
    useCommunicationsStore().communicationType = null
    useCommunicationsStore().isCreating = false
    useCommunicationApi().reset()
    usePlannedCallApi().reset()
    useCallDispositionApi().reset()
  }

  /**
   *
   * Function that triggers closing of sidebar.
   * Also ensures resetData() is called.
   */
  function closeSidebar() {
    if (
      !hasOpenFormsortForms.value ||
      window.confirm(
        'There is a form in a linked subtask. Complete it before closing, or your changes will be lost!'
      )
    ) {
      void router.push({
        query: {
          commId: undefined,
        },
      })
      resetData()
    }
  }

  return {
    isCallType,
    sidebarType,
    closeSidebar,
    resetData,
  }
}

/**
 * SCHEDULED COMM MODAL -------------------------------
 */
function getScheduledCommModalVars() {
  const scheduledCallModalOpen = ref(false)
  const { person } = storeToRefs(usePatientStore())

  const memberName = computed(() =>
    person.value ? formatNameFromPerson(person.value) : 'Member'
  )

  return {
    scheduledCallModalOpen,
    memberName,
  }
}

/**
 * SUBTASK SECTION -------------------------------
 */
function getSubtaskVars() {
  const { datum: communication } = storeToRefs(useCommunicationApi())

  const linkedSubtaskIds = ref<string[]>([])
  watch(communication, () => {
    const subtaskMaps = communication.value?.subtaskMaps
    linkedSubtaskIds.value = subtaskMaps
      ? normalize(subtaskMaps, 'subtaskId')
      : []
  })
  const subtasksWithFormsortForms = ref(new Set<string>())

  const computedLinkedSubtaskIds = computed(() => linkedSubtaskIds.value)
  const hasOpenFormsortForms = computed(
    () => !!subtasksWithFormsortForms.value.size
  )

  /**
   *
   * Function to trigger refresh on linked subtask data upon
   * adding new task from communication.
   * @param newLinkedSubtaskIds
   */
  async function refreshLinkedSubtasks(newLinkedSubtaskIds: string[]) {
    await useSubtasksStore().getSubtasks({
      filter_patient_ids: [getCurrentPatientId() ?? ''],
      filter_subtask_ids: newLinkedSubtaskIds,
      page_length: newLinkedSubtaskIds.length,
    })
    linkedSubtaskIds.value = newLinkedSubtaskIds
  }

  /**
   *
   * Function to add subtasks with formsort forms to
   * `subtasksWithFormsortForms` ref.
   */
  async function addSubtasksWithFormsortForms({
    subtasksWithFormsortIds,
  }: {
    [key: string]: string[]
  }) {
    subtasksWithFormsortIds.forEach((subtaskWithFormsortId) =>
      subtasksWithFormsortForms.value.add(subtaskWithFormsortId)
    )
  }

  /**
   *
   * Passthrough function to hide complex linking logic
   * in linkSubtasks() and sets returned linkedSubtaskIds.
   * @param id
   */
  async function linkAndSetMappedSubtasks(
    { newIds }: { [key: string]: string[] },
    id?: string
  ) {
    linkedSubtaskIds.value = await linkSubtasks(
      communication.value,
      computedLinkedSubtaskIds.value,
      { newIds },
      id
    )
  }

  /**
   *
   * Passthrough function to hide complex linking logic
   * in delinkSubtask() and sets
   * returned linkedSubtaskIds and subtasksWithFormsortForms.
   */
  async function delinkAndSetMappedSubtasksWithFormsortSubtasks({
    removingId,
  }: {
    [key: string]: string
  }) {
    const delinked = await delinkSubtask(
      communication.value,
      computedLinkedSubtaskIds.value,
      subtasksWithFormsortForms.value,
      { removingId }
    )
    linkedSubtaskIds.value = delinked.updatedLinkedSubtaskIds
    subtasksWithFormsortForms.value = delinked.subtasksWithFormsortForms
  }

  return {
    linkAndSetMappedSubtasks,
    delinkAndSetMappedSubtasksWithFormsortSubtasks,
    computedLinkedSubtaskIds,
    communication,
    addSubtasksWithFormsortForms,
    linkedSubtaskIds,
    refreshLinkedSubtasks,
    subtasksWithFormsortForms,
    hasOpenFormsortForms,
  }
}

/**
 * Reattempt/Reschedule modal vars
 */
export function getReattemptVars() {
  const { datum: callDisposition } = storeToRefs(useCallDispositionApi())

  const isReschedule = ref(false)
  const isReattempt = ref(false)
  const reattemptRescheduleModalIsOpen = ref(false)
  const isLoading = ref(false)
  const runId = ref<string | null>(null)
  const jobStatus = ref<JobStatus | null>(null)

  const JOB_STATUS_CHECK_INTERVAL_MS = 3000

  const { communication, linkedSubtaskIds } = getSubtaskVars()

  const calleeEntityId = computed(
    () =>
      communication.value?.callDisposition?.speakingWithPersonId ??
      communication.value?.plannedCall?.calleeEntityId ??
      undefined
  )

  /**
   *
   * open reattempt or reschedule/reattempt and reschedule
   * modal depending on the icon that was clicked and whether there are
   * any subtasks to reattempt
   * @param type
   * @param linkedSubtaskIdsArr
   * @param communication
   */
  function openReattemptRescheduleModal(
    type: ModalType,
    linkedSubtaskIdsArr: string[],
    communication: Communication | null
  ) {
    if (!communication?.communicationId) {
      useNotificationStore().setNotification({
        message: 'Communication ID missing, cannot open modal',
        type: NotificationType.DANGER,
      })
      return
    }
    if (type === ModalType.REATTEMPT) {
      isReattempt.value = true
    } else if (type === ModalType.RESCHEDULE && linkedSubtaskIdsArr.length) {
      isReschedule.value = true
      isReattempt.value = true
    } else {
      isReschedule.value = true
    }
    reattemptRescheduleModalIsOpen.value = true
  }

  const isDispositionSubtype = computed(
    () => communication.value?.callDisposition ?? callDisposition.value
  )

  /**
   * close reattempt/reschedule modal
   * reset associated values
   */
  function closeReattemptRescheduleModal() {
    reattemptRescheduleModalIsOpen.value = false
    isReattempt.value = false
    isReschedule.value = false
  }

  /**
   * Set isLoading boolean if a bulk edit job has started or finished
   * @param loading
   */
  function setIsLoading(loading: boolean) {
    isLoading.value = loading
  }

  /**
   *
   * Function to set current runId and jobStatus
   * @param id
   * @param status
   */
  function setJobDetails(id: string, status: JobStatus) {
    runId.value = id
    jobStatus.value = status
  }

  const jobIsRunning = computed(
    () =>
      runId.value &&
      (jobStatus.value === JobStatus.QUEUED ||
        jobStatus.value === JobStatus.RUNNING)
  )
  const jobFailure = computed(
    () =>
      runId.value &&
      (jobStatus.value === JobStatus.REJECTED ||
        jobStatus.value === JobStatus.ERROR)
  )

  /**
   *
   * Helper function to trigger success or failure notification
   */
  function triggerSuccessOrFailure() {
    if (jobFailure.value) {
      useNotificationStore().setNotification({
        message: 'Failed to bulk edit selected subtasks',
        type: NotificationType.DANGER,
      })
    }

    if (jobStatus.value === JobStatus.COMPLETED) {
      useNotificationStore().setNotification({
        message: 'Successfully bulk edited selected subtasks',
        type: NotificationType.SUCCESS,
      })
    }
  }

  /**
   *
   * @param subtaskIds
   * @param reattemptDate
   * @param reattemptReason
   */
  async function bulkReattempt(
    subtaskIds: string[],
    reattemptDate: Date,
    reattemptReason: string
  ) {
    const data: BulkEditSubtaskData[] = []
    subtaskIds.forEach((subtaskId: string) => {
      data.push({
        subtaskId,
        hideDatetime: reattemptDate,
        reattemptReason: reattemptReason ?? 'N/A',
      })
    })
    const bulkEditJobRes = await useBulkEditSubtasksApi().create({
      body: { data },
    })
    // IMPROVEME(MT-3306): refactor shared functionality with BulkEditModal
    setIsLoading(true)
    if (bulkEditJobRes) {
      setJobDetails(bulkEditJobRes.runId, bulkEditJobRes.status)
      if (runId.value) {
        const idForJob = runId.value
        useNotificationStore().setNotification({
          message: 'Bulk update in progress',
          type: NotificationType.INFO,
        })
        const getJobStatus = setInterval(async () => {
          const asyncJobRes = await usePathwaysJobsApi().retrieve({
            ids: [idForJob],
          })

          if (asyncJobRes) {
            setJobDetails(asyncJobRes.runId, asyncJobRes.status)
          }

          if (!jobIsRunning.value) {
            clearInterval(getJobStatus)
            triggerSuccessOrFailure()
            setIsLoading(false)
            await useReattemptStore().setSuccess(NotificationActions.CREATE)
          }
        }, JOB_STATUS_CHECK_INTERVAL_MS)
      }
    }
  }

  /**
   *
   * Function called when clicking "Confirm" on
   * RescheduleAndReattempt modal.
   *
   * If reattempting, ensure passed subtask IDs are reattempted.
   * If also rescheduling, ensure the following:
   *  - If planned/scheduled call, only update that planned call and comm.
   *  - If call disposition, create a new scheduled call with rescheduled date.
   *    Ensure call disposition's linked subtasks are also linked to the
   *    newly created scheduled call.
   * @param payloads
   */
  async function saveReattempt(payloads: RescheduleReattemptSavePayload) {
    // reattempt subtasks
    if (isReattempt.value) {
      await bulkReattempt(
        payloads.linkedSubtaskIds,
        payloads.dirtyReattempt.reattemptDatetime,
        payloads.dirtyReattempt.reattemptReason
      )
      linkedSubtaskIds.value = payloads.linkedSubtaskIds
    }
    // reschedule comm
    if (isReschedule.value) {
      // if call disposiiton, create scheduled call and link
      if (isDispositionSubtype.value) {
        const { linkedSubtaskIds: linkedIds, communication: communicationId } =
          await createCommunicationAndPlannedCall(payloads)
        linkedSubtaskIds.value = linkedIds
        await router.push({
          query: {
            commId: communicationId,
          },
        })
      } else {
        await updateCommunicationAndPlannedCall(communication.value, payloads)
      }
    }

    closeReattemptRescheduleModal()
  }

  return {
    calleeEntityId,
    saveReattempt,
    isReattempt,
    isReschedule,
    isLoading,
    reattemptRescheduleModalIsOpen,
    closeReattemptRescheduleModal,
    openReattemptRescheduleModal,
    // returned for testing only
    bulkReattempt,
    triggerSuccessOrFailure,
    setJobDetails,
    jobFailure,
    jobStatus,
  }
}

/**
 * SETUP -------------------------------
 */
export default function () {
  const {
    linkAndSetMappedSubtasks,
    delinkAndSetMappedSubtasksWithFormsortSubtasks,
    addSubtasksWithFormsortForms,
    linkedSubtaskIds,
    computedLinkedSubtaskIds,
    subtasksWithFormsortForms,
    communication,
    refreshLinkedSubtasks,
  } = getSubtaskVars()

  const {
    calleeEntityId,
    saveReattempt,
    isReattempt,
    isReschedule,
    isLoading,
    reattemptRescheduleModalIsOpen,
    closeReattemptRescheduleModal,
    openReattemptRescheduleModal,
  } = getReattemptVars()

  const { sidebarType, closeSidebar, resetData, isCallType } = getSidebarVars()
  const { scheduledCallModalOpen, memberName } = getScheduledCommModalVars()

  const route = useRoute()
  const queryCommId = computed(() => route.query.commId ?? null)

  const commSaveState = ref<SaveState>(newSaveState())

  /**
   *
   * Function that first resets store data and fetches communication
   * and relative subtypes if there is a commId query in URL.
   */
  async function getCommunicationAndSubtype() {
    if (queryCommId.value) {
      resetData()
      const comm: Communication | undefined | null =
        await retrieveCommunicationById(`${queryCommId.value}`)

      if (!comm) {
        useNotificationStore().setNotification({
          message: 'Communication not found',
          type: NotificationType.DANGER,
        })
        return
      }
      const subtaskMaps = comm?.subtaskMaps

      linkedSubtaskIds.value = subtaskMaps
        ? normalize(subtaskMaps, 'subtaskId')
        : []
      await retrieveCommunicationSubType(comm)
    }
  }

  onBeforeMount(getCommunicationAndSubtype)
  watch(queryCommId, getCommunicationAndSubtype)

  return {
    calleeEntityId,
    isCallType,
    refreshLinkedSubtasks,
    linkedSubtaskIds,
    communication,
    linkAndSetMappedSubtasks,
    delinkAndSetMappedSubtasksWithFormsortSubtasks,
    saveReattempt,
    addSubtasksWithFormsortForms,
    isReattempt,
    isReschedule,
    isLoading,
    reattemptRescheduleModalIsOpen,
    closeReattemptRescheduleModal,
    openReattemptRescheduleModal,
    ModalType,
    computedLinkedSubtaskIds,
    subtasksWithFormsortForms,
    queryCommId,
    commSaveState,
    sidebarType,
    closeSidebar,
    scheduledCallModalOpen,
    memberName,
  }
}
