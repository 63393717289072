import {
  PhoneNumberType,
  PhoneNumber,
  MapEntityPhoneNumber,
} from '@/legacy/types/entities/phoneNumbers'

/**
 * Return True for non-landline, non-other, non-voip
 * Only allow mobile or null values
 * Null should be allowed for local testing
 * @param phone
 */
export function isMobilePhoneNumberType(phone: PhoneNumber): boolean {
  return !!(
    phone.type !== PhoneNumberType.LANDLINE &&
    phone.type !== PhoneNumberType.OTHER &&
    phone.type !== PhoneNumberType.VOIP
  )
}

/**
 * Return True for (messagingOptInStatus == true) and non-landline
 * @param phone
 */
export function isTextableAndOptedIn(phone: PhoneNumber): boolean {
  return !!(!!phone.messagingOptInStatus && isMobilePhoneNumberType(phone))
}

/**
 * Return True for (messagingOptInStatus == null) and non-landline
 * @param phone
 */
export function isTextableAndUnknownOptIn(phone: PhoneNumber): boolean {
  return !!(
    phone.messagingOptInStatus === null && isMobilePhoneNumberType(phone)
  )
}

/**
 * Return True for (messagingOptInStatus == true or null) and non-landline
 * @param phone
 */
export function isTextableAndNotOptedOut(phone: PhoneNumber): boolean {
  return !!(
    phone.messagingOptInStatus !== false && isMobilePhoneNumberType(phone)
  )
}

export const renderNonTextableMsg = (
  {
    messagingOptInStatus,
    type,
  }: { messagingOptInStatus: boolean | null; type?: PhoneNumberType | null },
  approved = true
): string => {
  if (!approved) {
    return "Not approved to speak on the member's behalf"
  }
  if (type === PhoneNumberType.LANDLINE) {
    return 'This number is a landline'
  }
  if (messagingOptInStatus === null) {
    return 'Waiting for response/unknown'
  }
  if (messagingOptInStatus === false) {
    return 'Declined texting'
  }
  return 'This person cannot be texted for unknown reasons'
}

/**
 * Comparator for two entity phone numbers by isPrimary
 * attribute, putting the primary phone first
 * @param a
 * @param b
 */
export const compareIsPrimary = (
  a: MapEntityPhoneNumber,
  b: MapEntityPhoneNumber
): number => {
  if (a.isPrimary) {
    return -1
  }
  if (b.isPrimary) {
    return 1
  }
  return 0
}
