import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { camelCase, startCase } from 'lodash'
import { useForm } from 'vee-validate'
import { computed, ExtractPropTypes } from 'vue'
import { booleanOptions } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'
import {
  DischargeLocation,
  EventType,
  ReadmissionRisk,
  ServicesOnDischarge,
} from '@/pages/PatientProfile/CarePlans/shared/types'

import { AddOrEditTransitionsOfCareForm, schema } from '../types'
import {
  createCarePlanTransitionsOfCare,
  updateCarePlanTransitionsOfCare,
} from './queries'
import { addOrEditTransitionsOfCareProps } from './types'

type PropsType = ExtractPropTypes<typeof addOrEditTransitionsOfCareProps>

/**
 *
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const modalTitle = computed(() => (props.creating ? 'Add TOC' : 'Edit TOC'))
  /**
   *
   */
  function close() {
    context.emit('close')
  }

  const { values, handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  const dischargeLocationOptions = Object.values(DischargeLocation)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const readmissionRiskOptions = Object.values(ReadmissionRisk)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const eventTypeOptions = Object.values(EventType)
    .map((val) => {
      if (val === EventType.ED_DISCHARGE) {
        return { value: val, label: 'ED Discharge' }
      }
      return { value: val, label: startCase(camelCase(val)) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const servicesOnDischargeOptions = Object.values(ServicesOnDischarge)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  /**
   *
   * @param values
   */
  async function saveTransitionsOfCare(values: AddOrEditTransitionsOfCareForm) {
    if (props.editingToc?.transitionsOfCareId) {
      await updateCarePlanTransitionsOfCare(
        props.editingToc.transitionsOfCareId,
        values
      )
    } else {
      await createCarePlanTransitionsOfCare(props.patientId, values)
    }

    context.emit('refetch')
    close()
  }

  const onSubmit = handleSubmit(saveTransitionsOfCare)

  return {
    modalTitle,
    values,
    close,
    ModalSize,
    dischargeLocationOptions,
    readmissionRiskOptions,
    eventTypeOptions,
    servicesOnDischargeOptions,
    booleanOptions,
    onSubmit,
  }
}
