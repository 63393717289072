import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { dedupeHelper } from '@/legacy/libs/collections'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { NotificationType } from '@/legacy/types/notifications'
import { Patient } from '@/legacy/types/patients/patients'
import {
  providerAndPracticeList,
  ProvidersToPractice,
} from '@/legacy/types/providers'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const route = useRoute()
  const { patient } = storeToRefs(usePatientStore())

  const dirtyProvider = ref<string | undefined>('')
  const dirtyPractice = ref<string | undefined>('')

  /**
   *
   */
  function setCurrentProviderPracticeValues() {
    dirtyProvider.value = patient.value?.alignedProvider
    dirtyPractice.value = patient.value?.alignedPractice
  }

  onBeforeMount(() => {
    setCurrentProviderPracticeValues()
  })

  /**
   * Emit back close modal function to parent component
   */
  function close() {
    setCurrentProviderPracticeValues()
    context.emit('close')
  }

  /**
   *
   */
  function getAllProviders() {
    return providerAndPracticeList
      .filter((set: ProvidersToPractice) => {
        // if a user selected a practice, return all providers that are
        // part of that particular practice
        // else, return all providers
        return dirtyPractice.value
          ? set.practice.toLowerCase() === dirtyPractice.value.toLowerCase()
          : true
      })
      .map((set: ProvidersToPractice) => set.providers)
      .flat()
      .filter(dedupeHelper)
      .map((provider) => {
        return { label: provider, value: provider }
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  }

  /**
   *
   */
  function getAllPractices() {
    return providerAndPracticeList
      .filter((set: ProvidersToPractice) => {
        return dirtyProvider.value
          ? set.providers.includes(dirtyProvider.value)
          : true
      })
      .map((set: ProvidersToPractice) => {
        return { label: set.practice, value: set.practice }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  /**
   *
   */
  function setDropdownValue() {
    if (!dirtyProvider.value) {
      dirtyProvider.value = getAllProviders()[0].value
    }
    if (!dirtyPractice.value) {
      dirtyPractice.value = getAllPractices()[0].value
    }
  }

  /**
   *
   * @param changes
   */
  async function updatePatient(changes: Partial<Patient> | null) {
    if (changes != null) {
      try {
        await usePatientsApi().partialUpdate({
          ids: [route.params.patientId as string],
          body: changes,
          metaOptions: { bubbleErrorThrow: true },
        })
        await usePatientStore().patientApiCall(route.params.patientId as string)
        context.emit('close')
      } catch (err) {
        useNotificationStore().setNotification({
          message: 'Failed to update providers.',
          type: NotificationType.DANGER,
        })
        return
      }
      useNotificationStore().setNotification({
        message: 'Successfully updated providers.',
        type: NotificationType.SUCCESS,
      })
    }
  }

  return {
    setCurrentProviderPracticeValues,
    close,
    getAllProviders,
    getAllPractices,
    setDropdownValue,
    updatePatient,
    dirtyProvider,
    dirtyPractice,
    ModalSize,
  }
}
