import uniqBy from 'lodash/uniqBy'
import { formatNameFromPerson, formatPhone } from '@/legacy/libs/format'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePeopleApi } from '@/legacy/store/modules/patient'
import {
  useMapEntityOtherContactPhoneNumberApi,
  useMapEntityPhoneNumberApi,
} from '@/legacy/store/modules/phoneNumbers'
import { useTaskApi } from '@/legacy/store/modules/tasks'
import { useTextingSubtaskByIdApi } from '@/legacy/store/modules/textingV2'
import { IdMap } from '@/legacy/types/api/store'
import { Communication } from '@/legacy/types/communications/communications'
import { Person } from '@/legacy/types/entities/people'
import {
  CURRENT_MEMBER_STR,
  MapEntityPhoneNumber,
  PhoneNumberNameAndType,
} from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import { Pathway } from '@/legacy/types/pathways/pathways'
import { SubtaskPayload, SubtaskStatus } from '@/legacy/types/pathways/subtasks'
import { Contact } from '@/legacy/types/patients/contacts'
import { NormalizedPatient } from '@/legacy/types/patients/patients'
import {
  contactPersonObj,
  displayRelationship,
  getContactFromStore,
} from './contacts'

/**
 *
 * @param phoneNumberString
 * @param otherContact
 * @param entityIds
 */
export async function getPhoneNumberEntityMaps(
  phoneNumberString: string | null,
  otherContact = false,
  entityIds?: string[]
) {
  if (otherContact) {
    const res = await useMapEntityOtherContactPhoneNumberApi().list({
      params: {
        ...(phoneNumberString?.length
          ? { filter_free_text_phone_number: phoneNumberString }
          : {}),
        ...(entityIds?.length ? { filter_entity_ids: entityIds } : {}),
      },
    })
    return res.data
  }
  const res = await useMapEntityPhoneNumberApi().list({
    params: {
      ...(phoneNumberString?.length
        ? { filter_free_text_phone_number: phoneNumberString }
        : {}),
      ...(entityIds?.length ? { filter_entity_ids: entityIds } : {}),
    },
  })
  return res.data
}

/**
 *
 * @param isCurrentMember
 * @param person
 * @param isContact
 * @param currentEntityId
 * @param contacts
 */
async function setUpNameAndTypeDetailsForMappings(
  isCurrentMember: boolean,
  person: Person | null,
  isContact: boolean,
  currentEntityId: string,
  contacts: IdMap<Contact> | null
) {
  let name
  let type

  if (isCurrentMember && person) {
    name = formatNameFromPerson(person)
    type = CURRENT_MEMBER_STR
  } else if (isContact) {
    const contact = getContactFromStore(currentEntityId, contacts ?? {})
    const contactPerson = contact ? contactPersonObj(contact) : undefined
    name = contactPerson
      ? formatNameFromPerson(contactPerson)
      : 'Unknown Contact'
    type = contact ? displayRelationship(contact.relationshipToPatient) : 'N/A'
  } else {
    const personDetails = await usePeopleApi().retrieve({
      ids: [currentEntityId],
    })
    name = personDetails
      ? formatNameFromPerson(personDetails)
      : 'Unknown Other Member or Contact'
    type = "Other TC member or other TC member's contact"
  }

  return { name, type }
}

/**
 * Function to get and set contact phone number maps
 * e.g. { 'phone_number_string': <entity_ids>[]  }
 * @param phoneNumberStrings
 * @param contactEntityIds
 * @param person
 * @param contacts
 * @param patient
 * @param otherContact
 */
export async function mapPhoneNumberToNames(
  phoneNumberStrings: string[],
  contactEntityIds: string[],
  person: Person | null,
  contacts: IdMap<Contact> | null,
  patient: NormalizedPatient | null,
  otherContact = false
) {
  const phoneNumberMaps: {
    [key: string]: { name: string; type: string }[]
  } = {}
  phoneNumberStrings.forEach(async (phoneNumber: string) => {
    const retrievedMaps = await getPhoneNumberEntityMaps(
      phoneNumber,
      otherContact
    )
    if (retrievedMaps.length) {
      retrievedMaps.forEach(async (phoneNumberMap: MapEntityPhoneNumber) => {
        const currentEntityId = phoneNumberMap.entityId
        const phoneNumberStr = phoneNumberMap.phoneNumber.phoneNumber
        const isCurrentMember = currentEntityId === patient?.entityId
        const isContact = contactEntityIds.includes(currentEntityId)

        const mappingDetails = await setUpNameAndTypeDetailsForMappings(
          isCurrentMember,
          person,
          isContact,
          currentEntityId,
          contacts
        )

        if (!phoneNumberMaps[phoneNumberStr]) {
          phoneNumberMaps[phoneNumberStr] = [mappingDetails]
          return
        }
        if (phoneNumberMaps[phoneNumberStr]) {
          phoneNumberMaps[phoneNumberStr].push(mappingDetails)
          phoneNumberMaps[phoneNumberStr] = uniqBy(
            phoneNumberMaps[phoneNumberStr],
            'name'
          )
        }
      })
    }
  })
  return phoneNumberMaps
}

/**
 *
 * @param namesAndTypes
 */
export function renderSharedNumberWarningMessageForContacts(
  namesAndTypes: { name: string; type: string }[]
) {
  let str = 'Shared phone number between '
  namesAndTypes.forEach(
    ({ name, type }: { name: string; type: string }, idx: number) => {
      if (idx !== 0) {
        str += ` and ${name} (${type})`
      } else {
        str += `${name} (${type})`
      }
    }
  )

  return str
}

/**
 *
 * @param namesAndTypes
 * @param phoneNumberString
 */
export function renderSharedNumberWarningMessageForPatient(
  namesAndTypes: PhoneNumberNameAndType[],
  phoneNumberString: string | number
) {
  let str = `Shared number between member (${formatPhone(phoneNumberString)}) `
  namesAndTypes.forEach(
    ({ name, type }: { name: string; type: string }, idx: number) => {
      if (type === CURRENT_MEMBER_STR) {
        return
      }
      if (idx !== 0) {
        str += ` and ${name} (${type})`
      } else {
        str += `${name} (${type})`
      }
    }
  )

  return str
}

/**
 *
 * @param subtaskId
 * @param payload
 */
export async function updatePointerSubtask(
  subtaskId: string,
  payload: Partial<SubtaskPayload>
) {
  try {
    await useTextingSubtaskByIdApi().partialUpdate({
      ids: [subtaskId],
      body: payload,
    })
  } catch (err) {
    console.error(err)
    useNotificationStore().setNotification({
      message: 'Failed to update texting subtask.',
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * @param taskId
 * @param pointerToCommId
 * @param pathwayId
 */
export async function createPointerSubtaskForComm(
  taskId: string,
  pointerToCommId: string,
  pathwayId: string
) {
  // grab taskVariantId to create new texting subtask for current patient
  const tasks = await useTaskApi().list({
    params: {
      filter_task_ids: [taskId],
    },
  })

  try {
    // create new texting task
    const createdTask = await useTaskApi().create({
      body: {
        taskVariantId: tasks.data[0].taskVariantId,
        pathwayId: pathwayId,
      },
    })

    // update the created subtask to have a commId
    await updatePointerSubtask(createdTask.subtasks[0].subtaskId, {
      pointerToCommId: pointerToCommId,
    })
  } catch (err) {
    console.error(err)
    useNotificationStore().setNotification({
      message: 'Failed to create texting subtask.',
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * @param comm
 * @param evergreenPathway
 */
export async function closeOldSubtaskAndCreateNewOne(
  comm: Communication,
  evergreenPathway: Pathway
) {
  const subtasks = await useTextingSubtaskByIdApi().list({
    params: {
      filter_pointer_comm_ids: [comm.communicationId],
    },
  })
  const pointerSubtask = subtasks.data
  if (pointerSubtask.length) {
    // close previous pointer subtask
    await updatePointerSubtask(pointerSubtask[0].subtaskId, {
      status: SubtaskStatus.COMPLETED,
    })
    // create new pointer subtask for current patient
    await createPointerSubtaskForComm(
      pointerSubtask[0].taskIds[0],
      pointerSubtask[0].pointerToCommId,
      evergreenPathway.pathwayId
    )
  }
  return
}
