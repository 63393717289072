<template>
  <div v-if="visitNoteId">
    <div
      class="bg-nash-neutral000 shadow rounded-lg p-5 h-full overflow-y-auto relative"
    >
      <LegacyTButton
        name="close-visit-note-sidebar"
        icon="close"
        inline
        type="blackAndWhite"
        class="absolute top-4 right-2"
        :size="ButtonSize.MD"
        @click="closeSidebar"
      />
      <TSpinner
        v-if="isLoading"
        name="visit-note-sidebar"
        :partial-page="true"
      />
      <VisitNote
        v-else-if="visitNote"
        :visit-note="visitNote"
        :person="person"
        :visit-type="visitType"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import VisitNote from '@/legacy/components/patient/visitNote/VisitNote.vue'

import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatDateTime,
  formatDateTimeWithTime,
  formatName,
} from '@/legacy/libs/format'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { useAppointmentsApi } from '@/legacy/store/modules/appointments'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useVisitNotesApi } from '@/legacy/store/modules/visitNotes'

export default defineComponent({
  components: { TSpinner, LegacyTButton, VisitNote },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { person } = storeToRefs(usePatientStore())

    const visitNoteId = computed(() => route.query.visitNoteId)
    const { data: appointments } = storeToRefs(useAppointmentsApi())
    const appointment = computed(() =>
      appointments.value?.find(
        (a) => a.visitNoteId === parseInt(visitNoteId.value as string)
      )
    )
    const visitType = appointment.value?.appointmentType ?? ''
    // IMPROVEME (MT-2675) VisitNotes list being used as retrieve
    const { data: visitNote, isLoading } = storeToRefs(useVisitNotesApi())

    /**
     *
     */
    function fetchVisitNote() {
      const id = visitNoteId.value
      if (id) {
        // IMPROVEME (MT-2675) VisitNotes list being used as retrieve
        void useVisitNotesApi().list({ ids: [id.toString()] })
      }
    }

    onMounted(() => {
      fetchVisitNote()
    })

    const closeSidebar = () => {
      void router.push({ query: { visitNoteId: undefined, reason: undefined } })
    }

    watch(visitNoteId, () => {
      fetchVisitNote()
    })

    return {
      isLoading,
      closeSidebar,
      visitNoteId,
      visitNote,
      person,
      formatDateTime,
      formatName,
      stringToDateTime,
      formatDateTimeWithTime,
      visitType,
      ButtonSize,
    }
  },
})
</script>
