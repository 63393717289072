import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'

import { useRoute } from 'vue-router'
import {
  formatContactNameAndRelationship,
  formatNameFromPerson,
  formatPhone,
} from '@/legacy/libs/format'
import { getValFromQuery } from '@/legacy/libs/lookup'
import { useConfigStore } from '@/legacy/store/modules/config'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useTextingStore } from '@/legacy/store/modules/textingV2'
import { TextCommunication } from '@/legacy/types/communications/communications'
import { Person } from '@/legacy/types/entities/people'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { Contact } from '@/legacy/types/patients/contacts'
import {
  UNKNOWN_TEXTS_PAGE_URL_NAME,
  hasSharedNumberWithAnotherContact,
  hasSharedNumberWithAnotherMember,
} from '../lib/sharedTextingParts'

/**
 * Function to get active conversation vars
 */
export default function () {
  const sharedPhoneNumberWithContactMsg =
    'This contact shares a number with another Thyme Care member. They have the same message thread but different profiles.'
  const sharedPhoneNumberWithAnotherMemberMsg =
    'The contacts in this message thread share a phone number.'
  const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
    useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId

  const route = useRoute()

  const { activeCommunication, smsCommsByPhoneNumberId, conversations } =
    storeToRefs(useTextingStore())

  const isUnknownTextsPage = computed(
    () => route.name === UNKNOWN_TEXTS_PAGE_URL_NAME
  )
  const patientId = computed(() =>
    isUnknownTextsPage.value && TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      ? TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      : `${route.params.patientId ?? ''}`
  )
  const { contacts, phoneNumbers, person } = storeToRefs(usePatientStore())

  const queryData = computed(() => route.query)
  const commId = computed(() => getValFromQuery(queryData.value, 'commId'))
  const phoneNumId = computed(() =>
    getValFromQuery(queryData.value, 'phoneNumberId')
  )

  const isSharedNumberWithAnotherMember = computed(() =>
    activeCommunication.value
      ? hasSharedNumberWithAnotherMember(
          activeCommunication.value,
          Object.values(contacts.value ?? {})
        )
      : false
  )
  const isSharedNumberWithContact = computed(() =>
    activeCommunication.value
      ? hasSharedNumberWithAnotherContact(
          activeCommunication.value,
          Object.values(contacts.value ?? {})
        )
      : false
  )

  const unknownNumber = computed(() => {
    if (activeCommunication.value) {
      // get phone number from existing comm
      return formatPhone(
        activeCommunication.value.smsThread.phoneNumber.phoneNumber ?? ''
      )
    }
    if (phoneNumId.value && phoneNumId.value.length && phoneNumbers.value) {
      //match phoneNumId with phone numbers associated to unknown text patient and return formatted phone num
      const patientPhoneNumbers: MapEntityPhoneNumber[] = Object.values(
        phoneNumbers.value
      )
      if (patientPhoneNumbers) {
        return formatPhone(
          patientPhoneNumbers.find((pn: MapEntityPhoneNumber) => {
            if (phoneNumId.value) {
              return pn.phoneNumberId === phoneNumId.value[0]
            }
          })?.phoneNumber.phoneNumber ?? ''
        )
      }
    }
    return ''
  })

  // ActiveConversation-------------------------------------------------------------------
  const currentTextableIndividual = computed(() => {
    // if the current page is the unknown texts page,
    // return the phone number to be displayed instead of the patient name
    if (unknownNumber.value.length && isUnknownTextsPage.value) {
      return unknownNumber.value
    }
    let personOrPeopleStr = ''
    // Check if there are communication IDs and SMS communications available
    if (commId.value?.length && smsCommsByPhoneNumberId.value) {
      // Iterate over people in the current SMS communication
      activeCommunication.value?.smsThread.people?.forEach(
        (person: Person, idx: number) => {
          // Add comma if not the first person
          if (idx !== 0) {
            personOrPeopleStr += ', '
          }
          // Check if the person is a patient, contact, or associated member from
          // different profile and add name
          if (person.entityId === patientId.value) {
            personOrPeopleStr += formatNameFromPerson(person)
          } else {
            const foundContact = Object.values(contacts.value ?? {}).find(
              (contact: Contact) => contact.contactEntityId === person.entityId
            )
            if (foundContact) {
              personOrPeopleStr +=
                formatContactNameAndRelationship(foundContact)
            } else {
              // If not contact, person is an associated member from different profile
              personOrPeopleStr += formatNameFromPerson(person)
            }
          }
        }
      )
    } else if (phoneNumbers.value && phoneNumId.value?.length) {
      // Check if phone numbers and phone number IDs are available
      const patientPhoneNumbers = Object.values(phoneNumbers.value)
      const isPatient = patientPhoneNumbers.find((pn) => {
        if (phoneNumId.value?.length) {
          return pn.phoneNumberId === phoneNumId.value[0]
        }
      })

      // Check if the phone number corresponds to a patient
      if (isPatient && person.value) {
        personOrPeopleStr = formatNameFromPerson(person.value)
      } else if (contacts.value) {
        // Find contact corresponding to the phone number ID
        const foundContact = Object.values(contacts.value ?? {}).find(
          (contact: Contact) =>
            phoneNumId.value &&
            contact.contactEntity.phoneNumbers?.length &&
            contact.contactEntity.phoneNumbers[0].phoneNumberId ===
              phoneNumId.value[0]
        )

        // Add contact information to the string
        if (foundContact) {
          personOrPeopleStr = formatContactNameAndRelationship(foundContact)
        }
      }
    }
    return personOrPeopleStr ?? 'Unknown Contact'
  })

  const showMessageBox = computed(() => {
    if (isUnknownTextsPage.value && !currentTextableIndividual.value.length) {
      return false
    }
    return true
  })

  /**
   *
   * Function that checks current query ID (phone number vs. comm)
   * and either clears data or fetches needed data to render
   * MessageBox.vue and AllSms.vue.
   */
  async function getAndSetAllTwilioData() {
    useTextingStore().clearErrorStates()
    useTextingStore().clearSetConvosAndMessages()
    if (commId.value?.length && smsCommsByPhoneNumberId.value?.data.length) {
      const associatedSmsComm = smsCommsByPhoneNumberId.value.data.find(
        (smsCommByPhoneId: TextCommunication) => {
          if (commId.value) {
            return smsCommByPhoneId.communicationId === commId.value[0]
          }
        }
      )
      if (associatedSmsComm?.smsThread.phoneNumberId) {
        await getActiveThreadCommunications(
          associatedSmsComm?.smsThread.phoneNumberId
        )
        await useTextingStore().getAndSetConversationsForIndividual()
        await useTextingStore().getAndSetMessagesForIndividual()
        await useTextingStore().mapMessagesToDeliveryStatus()
      }
    }
  }

  // This watches for changes to sms communications per phone ID:
  // Edge case 1: when sends a message, sms communications refetched
  watch(smsCommsByPhoneNumberId, async () => {
    await getAndSetAllTwilioData()
  })

  /**
   * Function that gets 10 sms communications per phone number id given
   * @param phoneNumberId
   */
  async function getActiveThreadCommunications(phoneNumberId: string) {
    await useTextingStore().getSmsCommsForActiveThread({
      entityIds: [patientId.value],
      phoneNumberIds: [phoneNumberId],
    })
  }

  return {
    sharedPhoneNumberWithAnotherMemberMsg,
    sharedPhoneNumberWithContactMsg,
    MessageSeverity,
    isSharedNumberWithContact,
    isSharedNumberWithAnotherMember,
    conversations,
    currentTextableIndividual,
    formatPhone,
    showMessageBox,
  }
}
