import { startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { usePatientStore } from '@/legacy/store/modules/patient'

/**
 *
 */
export default function () {
  const { isLoading, patient } = storeToRefs(usePatientStore())
  const activeTab = ref('appointments')

  const tabMap = computed((): { [key: string]: string } => ({
    appointments: 'PatientAppointments',
    claims: 'PatientClaimsHistory',
    oncologyTreatments: 'PatientTreatments',
    providers: 'PatientMedicalProviders',
    tocs: 'PatientTransitionsOfCareNotifications',
  }))

  const activeTabComponent = computed(() => tabMap.value[activeTab.value])

  /**
   *
   * @param newTab
   */
  function updateActiveTab(newTab: string) {
    return tabMap.value[newTab] && newTab !== activeTab.value
      ? (activeTab.value = newTab)
      : null
  }

  /**
   *
   * @param tab
   */
  function tabName(tab: string) {
    if (tab === 'tocs') {
      return 'TOCs'
    }

    return startCase(tab)
  }

  return {
    tabMap,
    activeTab,
    activeTabComponent,
    updateActiveTab,
    tabName,
    isLoading,
    patient,
  }
}
