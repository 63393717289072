<template>
  <TTable
    ref="table"
    name="ClaimsHistory"
    class="pb-12 px-0"
    :rows="10"
    :columns="columns"
    :api-store="usePatientClaimsApi"
    :params="{ filter_patient_ids: patientId }"
    sort-field="date"
    :sort-order="SORT_ASCENDING"
  >
    <template #startColumns>
      <Column expander class="w-20" />
    </template>
    <template #expansion="slotProps">
      <table class="bg-nash-purple50 table-auto w-full">
        <tr>
          <td class="w-20"></td>
          <td class="py-3">
            <ul v-if="slotProps.data.nonPharmacyDetails" class="list-none">
              <li>
                <strong>Modifier: </strong>
                {{ slotProps.data.nonPharmacyDetails.modifier1 }}
              </li>
            </ul>
            <ul v-else-if="slotProps.data.pharmacyDetails" class="list-none">
              <li>
                <strong>Quantity:</strong>
                {{ slotProps.data.pharmacyDetails.quantity }}
              </li>

              <li>
                <strong>Days Supply: </strong>
                {{ slotProps.data.pharmacyDetails.daysSupply }}
              </li>

              <li>
                <strong>Strength: </strong>
                {{ slotProps.data.pharmacyDetails.strength }}
              </li>

              <li>
                <strong>Dosage Form: </strong>
                {{ slotProps.data.pharmacyDetails.dosageForm }}
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </template>
  </TTable>
</template>

<script lang="ts">
import Column from 'primevue/column'
import { defineComponent } from 'vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import setup from './controller'

export default defineComponent({
  components: { TTable, Column },
  props: {
    patientId: { type: String, required: true },
  },
  setup,
})
</script>
