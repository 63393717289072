import { NO_CACHE } from '@thyme/libs/src/api/types/api'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'
import { jsDateTimeToString } from '@/legacy/libs/date'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { formatNameFromPerson } from '@/legacy/libs/format'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'

import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { SaveState } from '@/legacy/types/api/api'
import { DataType, initialApiState } from '@/legacy/types/api/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { CallDisposition } from '@/legacy/types/communications/callDispositions'
import {
  ALL_COMMUNICATION_PARTS,
  Communication,
  CommunicationType,
  BaseCommunicationPayload,
  CommunicationsApiOptions,
  CommunicationsState,
  StoreTypeForReminders,
  CreateSmsCommunicationPayload,
  CommunicationSubtaskMap,
  CommunicationSubtype,
} from '@/legacy/types/communications/communications'
import { PlannedCall } from '@/legacy/types/communications/plannedCalls'
import { SMSThread } from '@/legacy/types/communications/texting'
import { Entity } from '@/legacy/types/entities/entities'
import { NotificationType } from '@/legacy/types/notifications'
import { useEntitiesApi } from './entity'

/**
 * Helper fn to refetch comms thymeline on comm updates
 * @param updateDetail
 */
export const refetchCommsThymeline = (
  updateDetail: { id: string; item: Communication } | null = null
) => {
  // trigger new thymelines to update (they now watch this and call for getData)
  if (updateDetail) {
    thymeDispatch('thymeline-patch', updateDetail)
  } else {
    thymeDispatch('thymeline-update')
  }
}

export const isCallType = (
  commType: CommunicationType | CommunicationSubtype | null
): boolean =>
  commType === CommunicationSubtype.Planned ||
  commType === CommunicationSubtype.Disposition ||
  commType === CommunicationType.Call

// Comm Payload Setters -----------------------------------
export const getCurrentStaffId = () => {
  const profileStore = useProfileStore()
  if (profileStore.selfEntity) {
    return profileStore.selfEntity.entityId
  }
  return null
}

export const createDefaultDatetime = (nullTime = false) => {
  const defaultDueDatetime = new Date()
  const defaultHour = 20
  const defaultMinutes = 59
  const defaultSeconds = 0
  const defaultMilliseconds = 0
  if (nullTime) {
    defaultDueDatetime.setHours(
      defaultHour,
      defaultMinutes,
      defaultSeconds,
      defaultMilliseconds
    )
  }
  return jsDateTimeToString(defaultDueDatetime)
}

// Comm Store/States -----------------------------

const initialState = (): CommunicationsState => ({
  communications: null,
  communicationPersons: null,
  isCreating: false,
  communicationType: null,
  ...initialApiState(),
})

const transform = (data: Communication[]): Partial<CommunicationsState> =>
  idMapTransform({}, 'data', 'communicationId', data)

export const useCommunicationsStore = defineStore('communications', {
  state: () => cloneDeep(initialState()),
  getters: {},
  actions: {
    setIsCreating() {
      useCommunicationApi().updateData(null, DataType.SINGULAR)
      this.isCreating = true
    },
    reset() {
      useCommunicationApi().updateData(null, DataType.SINGULAR)
      this.isCreating = false
    },
    updateCommunicationPersons(entities: Entity[] | null) {
      let newPersons = {}
      if (entities && entities.length) {
        const persons = entities.map((entity: Entity) => entity.person)
        newPersons = idMapTransform(
          this.$state,
          'communicationPersons',
          'entityId',
          persons
        )
      }
      Object.assign(this.$state, newPersons)
    },

    async fetchCommunications(
      patientId: string | null,
      {
        filter_free_text,
        filter_staff_ids,
        filter_due_after,
        filter_due_before,
        filter_phone_number_ids,
        filter_communication_ids,
      }: CommunicationsApiOptions = {},
      useOtherStore: StoreTypeForReminders | null = null
    ) {
      let communicationApi = useCommunicationApi()
      if (useOtherStore) {
        communicationApi =
          useOtherStore === StoreTypeForReminders.staff
            ? useCommunicationForStaffInfoApi()
            : useCommunicationForMemberInfoApi()
      }

      const data = await communicationApi.list({
        params: {
          ...(patientId ? { filter_patient_ids: [patientId] } : {}),
          ...(filter_communication_ids ? { filter_communication_ids } : {}),
          ...(filter_free_text ? { filter_free_text } : {}),
          ...(filter_due_after ? { filter_due_after } : {}),
          ...(filter_due_before ? { filter_due_before } : {}),
          ...(filter_staff_ids?.length ? { filter_staff_ids } : {}),
          ...(filter_phone_number_ids?.length
            ? { filter_phone_number_ids }
            : {}),
          parts: ALL_COMMUNICATION_PARTS,
        },
      })

      if (data) {
        const speakingWithIds = data.data.reduce(
          (acc: string[], cur: Communication) => {
            cur.callDisposition?.speakingWithPersonId &&
            !acc.includes(cur.callDisposition?.speakingWithPersonId)
              ? acc.push(cur.callDisposition.speakingWithPersonId)
              : cur.plannedCall?.calleeEntityId &&
                !acc.includes(cur.plannedCall?.calleeEntityId)
              ? acc.push(cur.plannedCall.calleeEntityId)
              : null
            return acc
          },
          []
        )

        if (speakingWithIds.length) {
          const speakingWithEntities = await useEntitiesApi().list({
            params: {
              filter_entity_ids: speakingWithIds,
              parts: ['person'],
            },
          })

          this.updateCommunicationPersons(speakingWithEntities.data)
        }

        return data
      }
    },
    async fetchCommunicationById(
      communicationId: string,
      commType: null | CommunicationType | CommunicationSubtype = null
    ) {
      let data
      try {
        const isCall = isCallType(commType)
        data = await useCommunicationApi().list({
          params: {
            filter_communication_ids: [communicationId],
            ...(commType
              ? {
                  filter_types: isCall ? CommunicationType.Call : commType,
                }
              : {}),
            parts: ALL_COMMUNICATION_PARTS,
          },
          headers: {
            'Cache-Control': NO_CACHE,
          },
        })
      } catch (err) {
        useNotificationStore().setNotification({
          message: 'Failed to get communication by ID.',
          type: NotificationType.DANGER,
          error: err as string,
        })
        return
      }

      const commById = data.data[0]

      useCommunicationApi().updateData(commById, DataType.SINGULAR)
      return commById
    },
    async handleSharedNumbers(phoneNumberId: string, currentPatientId: string) {
      let res
      let updated
      if (!phoneNumberId) {
        throw Error('Cannot handle shared numbers without a phone number ID')
      }

      try {
        res = await this.fetchCommunications(null, {
          filter_phone_number_ids: [phoneNumberId],
          filter_types: [CommunicationType.Text],
        })
      } catch (err) {
        if (err instanceof Error) {
          console.error(err)
        }
      }

      if (res && res.data) {
        const foundComm = res.data[0]
        const memberName = formatNameFromPerson(foundComm.patients[0].person)
        if (
          window.confirm(
            `An open communication already exists with this phone number (About ${memberName}). Would you like to join it?`
          )
        ) {
          updated = await this.updateCommunication(foundComm.communicationId, {
            patientIds: [currentPatientId, ...foundComm.patientIds],
          })
        }
      }
      return updated
    },
    async createSmsCommunication(payload: CreateSmsCommunicationPayload) {
      try {
        const res = await useSmsCommunicationApi().create({
          body: payload,
        })
        if (res) {
          return this.handleUpdatedCommunicationPart(
            CommunicationType.Text,
            res.communicationId
          )
        }
      } catch (err: any) {
        if (err) {
          if (
            err.status === HTTPStatusCode.ERROR_409_CONFLICT &&
            payload.smsArgs &&
            payload.patientIds
          ) {
            const sharedComm = await this.handleSharedNumbers(
              payload.smsArgs.phoneNumberId,
              payload.patientIds[0]
            )
            return sharedComm
          }
          console.error(err)
        }
        useNotificationStore().setNotification({
          message: 'Failed to create text communication.',
          type: NotificationType.DANGER,
        })
      }
    },
    async handleUpdatedCommunicationPart(
      commType: CommunicationType | CommunicationSubtype,
      communicationId: string
    ) {
      const comm: Communication = await this.fetchCommunicationById(
        communicationId,
        commType
      )

      const isCompletedComm = comm.completedDatetime ?? comm.callDisposition
      const updateDetail = isCompletedComm
        ? null
        : { id: comm.communicationId, item: comm }

      refetchCommsThymeline(updateDetail)

      return comm
    },

    async updateCommunication(
      commId: string,
      payload: Partial<BaseCommunicationPayload>,
      saveState: SaveState | undefined = undefined
    ) {
      let res
      try {
        res = await useCommunicationApi().partialUpdate({
          ids: [commId],
          body: payload,
          metaOptions: { saveState: saveState },
        })
      } catch (err) {
        const errMsg = 'Failed to update communication.'
        if (!saveState) {
          useNotificationStore().setNotification({
            message: err instanceof Error ? err : Error(errMsg),
            error: errMsg,
            type: NotificationType.DANGER,
          })
        }
        throw err
      }
      if (res) {
        const comm = await this.fetchCommunicationById(res.communicationId)
        const isCompletedComm = comm.completedDatetime ?? comm.callDisposition
        const updateDetail = isCompletedComm
          ? null
          : { id: comm.communicationId, item: comm }
        refetchCommsThymeline(updateDetail)
      }
      return res
    },

    async delinkSubtask(communicationId: string, subtaskId: string) {
      try {
        await useCommunicationSubtaskApi().delete({
          ids: ['maps'],
          params: {
            communicationId: communicationId,
            subtaskId: subtaskId,
          },
        })
        return subtaskId
      } catch (err) {
        if (err instanceof Error) {
          console.error(err)
        }
        useNotificationStore().setNotification({
          message: 'Failed to delink activity from communication.',
          type: NotificationType.DANGER,
        })
      }
    },
    async linkSubtask(communicationId: string, subtaskId: string) {
      try {
        const map = await useCommunicationSubtaskApi().create({
          ids: ['maps'],
          body: {
            communicationId: communicationId,
            subtaskId: subtaskId,
          },
        })
        return map
      } catch (err) {
        if (err instanceof Error) {
          console.error(err)
        }
        useNotificationStore().setNotification({
          message: 'Failed to link activity from communication.',
          type: NotificationType.DANGER,
        })
      }
    },
  },
})

export const useCommunicationApi = apiStore<
  Communication,
  IdMap<Communication>
>('communicationApi', '/api/communications', {
  transformData: (d: { data: Communication[] }) => transform(d.data),
})

export const useCommunicationForStaffInfoApi = apiStore<
  Communication,
  IdMap<Communication>
>('communicationForStaffInfoApi', '/api/communications', {
  transformData: (d: { data: Communication[] }) => transform(d.data),
})

export const useCommunicationForMemberInfoApi = apiStore<
  any,
  IdMap<Communication>
>('communicationForMemberInfoApi', '/api/communications', {
  transformData: (d: { data: Communication[] }) => transform(d.data),
})

export const useAssignedQueueScheduledCallsApi = apiStore<
  any,
  IdMap<Communication>
>('assignedQueueScheduledCallsApi', '/api/communications', {
  transformData: (d: { data: Communication[] }) => transform(d.data),
})

export const useUnassignedQueueScheduledCallsApi = apiStore<
  any,
  IdMap<Communication>
>('unassignedQueueScheduledCallsApi', '/api/communications', {
  transformData: (d: { data: Communication[] }) => transform(d.data),
})

// ---CALLS---
// Planned Call/Scheduled Call --> POST + PATCH
export const usePlannedCallApi = apiStore<PlannedCall>(
  'plannedCallApi',
  '/api/communications/calls/planned',
  {}
)

// Call Disposition --> POST + PATCH
export const useCallDispositionApi = apiStore<CallDisposition>(
  'callDispositionApi',
  '/api/communications/calls/disposition',
  {}
)

// ---SMS---
// SMS thread --> PATCH
export const useSmsApi = apiStore<SMSThread>('smsApi', '/api/sms', {})

// SMS communication --> CREATE
export const useSmsCommunicationApi = apiStore<
  Communication,
  IdMap<Communication>
>('communicationApi', '/api/communications', {})

// ---SUBTASK COMM MAPS---
// Subtask communication maps --> DELETE + POST
export const useCommunicationSubtaskApi = apiStore<CommunicationSubtaskMap>(
  'communicationSubtaskMapApi',
  '/api/subtasks/communications',
  {}
)

export const useLastSuccessfulCallApi = apiStore<
  Communication,
  IdMap<Communication>
>('lastSuccessfulCallCommunicationApi', '/api/communications', {
  transformData: (d: { data: Communication[] }) => transform(d.data),
})
