import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes } from 'vue'
import { editOptionsArray } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientTreatment/shared/types'
import { useTreatmentApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { formatTreatmentDuration } from '../shared/utils'
import { deleteTreatment } from './queries'
import { deleteTreatmentProps } from './types'

type PropsType = ExtractPropTypes<typeof deleteTreatmentProps>

/**
 * Set up the DeleteTreatment component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: treatmentsData } = storeToRefs(useTreatmentApi())
  const treatment = computed(() =>
    treatmentsData.value ? treatmentsData.value[props.treatmentId] : null
  )

  const treatmentDisplay = computed(() => ({
    treatmentAgentDisplay: treatment.value?.otherTreatmentType
      ? `Other: ${treatment.value?.otherTreatmentType}`
      : treatment.value?.oncologySystematicTherapyReference?.agent,
    treatmentBrandsDisplay: treatment.value?.oncologySystematicTherapyReference
      ?.brandNames.length
      ? treatment.value?.oncologySystematicTherapyReference.brandNames.join(
          ', '
        )
      : 'N/A',
    treatmentDuration: treatment.value
      ? formatTreatmentDuration(treatment.value)
      : 'unknown',
  }))

  /**
   * close delete modal
   */
  function close() {
    context.emit('close')
  }

  /**
   * delete treatment
   */
  async function onDelete() {
    if (treatment.value) {
      await deleteTreatment(props.treatmentId)
      context.emit('refetch')
    }
  }

  return {
    editOptionsArray,
    ModalSize,
    treatmentDisplay,
    onDelete,
    close,
  }
}
