import { kebabCase } from 'lodash'
import { useField } from 'vee-validate'
import { computed, ExtractPropTypes, toRef, watch } from 'vue'
import { T_INPUT_TEXT_PROPS } from './types'

type TInputTextProps = ExtractPropTypes<typeof T_INPUT_TEXT_PROPS>

/**
 *
 * @param props
 * @param context
 */
export function setup(props: TInputTextProps, context: any) {
  if (!props.name) {
    // required due to useField & accessibility
    // component will not work properly if not provided
    throw new Error(
      'TInputText: unique name prop (used as HTML attr id) is required'
    )
  }

  const inputId = kebabCase(props.name)
  // wrap in a ref so it can be updated mid-form
  const rules = toRef(() => props.rules)

  const {
    value: inputValue,
    handleBlur,
    handleChange,
    meta,
    errors,
  } = useField(props.name, rules, {
    initialValue: props.modelValue,
  })

  // Used show red border on invalid fields
  const isInvalid = computed(() => !!errors.value.length && meta.touched)

  /**
   * Validate value on change and send appropriate event to parent
   */
  async function updateValue() {
    if (!errors.value.length) {
      context.emit('update:modelValue', inputValue.value)
    }
  }

  /**
   *
   */
  function emitBlur() {
    handleBlur()
    context.emit('blur')
  }

  const dynamicClasses = computed(() => ({
    'p-invalid': isInvalid.value,
    'icon-spacer': !!props.icon,
  }))

  /*
   * This watch function allows for updates to happen outside of the component.
   */
  watch(
    () => props.modelValue,
    (modelValue) => handleChange(modelValue)
  )

  return {
    inputId,
    inputValue,
    isInvalid,
    dynamicClasses,

    errors,
    meta,
    updateValue,
    emitBlur,
  }
}
