<template>
  <div class="flex flex-col p-3">
    <div
      class="flex justify-between items-center w-full border-b border-nash-neutral500 mb-2"
    >
      <h4>Member Contact</h4>
      <TMTertiaryButton
        icon="pencil"
        @click="toggleManagePatientContactModal"
      />
    </div>
    <div class="font-bold italic">
      {{ displayTimeZone }}
    </div>
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col space-y-0.5">
        <div
          v-for="phoneEntityMap in phoneEntityMaps"
          :key="phoneEntityMap.mapEntityPhoneNumberId"
          class="space-y-2"
        >
          <div class="flex space-x-2 mb-2 items-center justify-between">
            <div class="flex items-center space-x-1 py-1.5">
              <p>
                {{
                  formatPhone(phoneEntityMap.phoneNumber.phoneNumber) || 'N/A'
                }}
              </p>
              <div v-if="phoneEntityMap.phoneNumber.type" class="bullet"></div>
              <p v-if="phoneEntityMap.phoneNumber.type">
                {{ capitalize(phoneEntityMap.phoneNumber.type) || '' }}
              </p>
              <TIconV2
                v-if="phoneEntityMap.isPrimary"
                icon="heroicons:star-20-solid"
                color="nash-neutral800"
              />
            </div>
            <div class="space-x-2">
              <TOCallButton
                name="patient"
                :phone-number="phoneEntityMap.phoneNumber.phoneNumber"
                @call="createCallDisposition"
              />
              <TMChipButton
                v-if="isTextableAndOptedIn(phoneEntityMap.phoneNumber)"
                icon-fill="nash-purple500"
                name="patient-phonenumber"
                icon="heroicons:chat-bubble-oval-left"
                size="sm"
                @click="createOrOpenText(phoneEntityMap.phoneNumberId)"
              />
              <div
                v-else-if="isMobilePhoneNumberType(phoneEntityMap.phoneNumber)"
                v-tooltip.right="
                  renderNonTextableMsg(phoneEntityMap.phoneNumber)
                "
                class="relative inline-block"
              >
                <TMChipButton
                  v-if="
                    phoneEntityMap.phoneNumber.messagingOptInStatus === false
                  "
                  :disabled="true"
                  icon-fill="nash-purple500"
                  name="patient-phonenumber"
                  icon="declinedTexting"
                  size="sm"
                />
                <div v-else>
                  <TMChipButton
                    icon-fill="nash-purple500"
                    name="patient-phonenumber"
                    icon="notConsented"
                    size="sm"
                    @click="createOrOpenText(phoneEntityMap.phoneNumberId)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="email in patientEmails" :key="email?.email">
          <div
            v-if="email"
            class="flex space-x-2 mb-2 items-center justify-between"
          >
            <div class="flex space-x-1 items-center">
              <p>{{ email.email || 'N/A' }}</p>
            </div>
            <div class="space-x-2">
              <TMChipButton
                name="patient-email"
                icon="heroicons:envelope"
                icon-fill="nash-purple500"
                @click="() => copyContent(email.email)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-0.5">
        <div class="font-bold italic">Outreach Preferences</div>
        <div class="flex justify-between">
          <div>
            <div class="my-1">
              <div v-if="!preferredTimes?.length">Contact time(s) unknown</div>
              <div v-else>
                <i>Time(s): </i>
                <span v-for="(time, index) in preferredTimes" :key="index">
                  {{ lookupEnum(ContactTime, time)
                  }}{{ index === preferredTimes?.length - 1 ? '' : ', ' }}
                </span>
              </div>
            </div>
            <div class="my-1">
              <div v-if="!preferredMethods?.length">Method(s) unknown</div>
              <div v-else>
                <i>Method(s): </i>
                <span v-for="(method, index) in preferredMethods" :key="index">
                  {{ lookupEnum(ContactMethod, method)
                  }}{{ index === preferredMethods?.length - 1 ? '' : ', ' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-0.5">
        <div class="font-bold italic">Address</div>
        <div v-for="address in addresses" :key="address.addressId">
          <div>
            <div class="font-bold">
              {{
                splitTitleCaseWithSpaces(
                  enumValueToKey(AddressType, address.type)
                )
              }}
            </div>
            <div>
              {{ address.streetAddressLine1 }}
            </div>
            <div v-if="address.streetAddressLine2">
              {{ address.streetAddressLine2 }}
            </div>
            <div>
              {{ address.city }},
              <span v-if="address.county">{{ address.county }} Co., </span>
              {{ address.state }}
              {{ address.zip }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ManagePatientContacts
      :is-visible="showManagePatientContact"
      :patient-contact-info="patientContactInfo"
      @close="toggleManagePatientContactModal"
      @fetch-outreach-preferences="fetchOutreachPreferences"
    />
  </div>
</template>

<script lang="ts">
import { datadogRum } from '@datadog/browser-rum'
import TMChipButton from '@nashville/button/TMChipButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIconV2 from '@nashville/icon/TIcon.vue'
import { FillColor } from '@thyme/nashville/src/types/icons'
import capitalize from 'lodash/capitalize'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { createDisposition } from '@/legacy/components/patient/communicationV2/lib/callDispositions'
import { createCommunication } from '@/legacy/components/patient/communicationV2/lib/communications'
import { dial } from '@/legacy/libs/call'
import { secondsToMilliseconds } from '@/legacy/libs/date'
import { enumValueToKey, lookupEnum } from '@/legacy/libs/enum'
import { formatPhone } from '@/legacy/libs/format'
import { splitTitleCaseWithSpaces } from '@/legacy/libs/string'
import TOCallButton from '@/legacy/nashville/button/TOCallButton.vue'
import router from '@/legacy/router'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useContactMethodsApi } from '@/legacy/store/modules/contactMethods'
import { useContactTimesApi } from '@/legacy/store/modules/contactTimes'
import {
  renderNonTextableMsg,
  isTextableAndOptedIn,
  isMobilePhoneNumberType,
} from '@/legacy/store/modules/lib/phoneNumbers'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { SpeakingWithType } from '@/legacy/types/communications/callDispositions'
import { OUTBOUND_CALL_TYPE_POST } from '@/legacy/types/communications/communications'
import { AddressType } from '@/legacy/types/entities/addresses'
import { PhoneNumberType } from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import { ContactMethod } from '@/legacy/types/patients/contactMethods'
import { ContactTime } from '@/legacy/types/patients/contactTimes'
import { PatientContactInfo } from '@/legacy/types/patients/patientSidebar'
import { handleRerouteToTextingInbox } from './lib/texting'
import ManagePatientContacts from './modals/ManagePatientContacts.vue'

export default defineComponent({
  components: {
    ManagePatientContacts,
    TMChipButton,
    TIconV2,
    TMTertiaryButton,
    TOCallButton,
  },
  setup() {
    const now = ref(new Date())
    const route = useRoute()
    const {
      patient,
      phoneNumbers: phoneEntityMaps,
      entity,
      addresses,
    } = storeToRefs(usePatientStore())
    const { selfEntity } = storeToRefs(useProfileStore())
    const { data: contactTimeMaps } = storeToRefs(useContactTimesApi())
    const { data: contactMethodMaps } = storeToRefs(useContactMethodsApi())

    const preferredTimes = ref<ContactTime[]>([])
    const preferredMethods = ref<ContactMethod[]>([])
    const showManagePatientContact = ref(false)
    const contactTimes = computed(() => {
      return contactTimeMaps.value?.map((m) => m.dayPart)
    })

    const contactMethods = computed(() => {
      return contactMethodMaps.value?.map((m) => m.method)
    })

    const currentOpenSubtaskId = computed(() => {
      return (route.query.subtaskId as string) ?? null
    })

    const patientEmails = computed(() => {
      if (entity.value?.email) {
        return { 0: { email: entity.value.email } }
      }
      return {}
    })

    // IMPROVEME (MT-2676) PatientContactInfo type is incorrect
    // @ts-expect-error PatientContactInfo type is incorrect
    const patientContactInfo = computed<PatientContactInfo>(() => ({
      phoneNumber: phoneEntityMaps.value,
      email: patientEmails.value,
      outreachPreferences: {
        preferredTimes: preferredTimes.value,
        preferredMethods: preferredMethods.value,
      },
      address: addresses.value,
    }))

    const CURRENT_TIME_REFRESH_SECS = 10

    onMounted(() => {
      void fetchOutreachPreferences()
      // Update the 'Current time' counter every 10 seconds
      setInterval(() => {
        now.value = new Date()
      }, secondsToMilliseconds(CURRENT_TIME_REFRESH_SECS))
    })

    /**
     * Function to fetch patient's outreach preferences
     */
    async function fetchOutreachPreferences() {
      if (entity.value?.entityId) {
        await useContactTimesApi().list({
          params: { filter_patient_ids: [entity.value?.entityId] },
        })
        if (contactTimes.value) {
          preferredTimes.value = contactTimes.value
        }

        await useContactMethodsApi().list({
          params: { filter_patient_ids: [entity.value?.entityId] },
        })
        if (contactMethods.value) {
          preferredMethods.value = contactMethods.value
        }
      }
    }

    /**
     * Function to show or hide ManagePatientContact modal
     */
    function toggleManagePatientContactModal() {
      showManagePatientContact.value = !showManagePatientContact.value
    }

    /**
     * Function to dial out member's phone number on zoom
     * @param id
     */
    async function call(id: string) {
      if (!phoneEntityMaps.value) {
        return
      }
      const { phoneNumber } = phoneEntityMaps.value[id]
      // Attempt to wait for call disposition to be created before dialing
      // to prevent network interruption by zoom
      // see MT-3659 for detailed error
      await createCallDisposition()
      await dial({ phoneNumber: phoneNumber.phoneNumber })
    }

    /**
     * Function to create SMS communciation on message button click
     * @param phoneNumberId
     */
    async function createOrOpenText(phoneNumberId: string) {
      if (patient.value?.entityId) {
        await handleRerouteToTextingInbox(
          phoneNumberId,
          patient.value.entityId,
          currentOpenSubtaskId.value
        )
      }
    }

    /**
     * Function that links an open subtask to a created communication ID.
     * @param createdId
     */
    async function linkOpenSubtask(createdId: string) {
      if (currentOpenSubtaskId.value) {
        await linkOpenSubtasks(
          { newIds: [currentOpenSubtaskId.value] },
          createdId
        )
      }
    }
    /**
     *
     * Function that creates communication and outbound call disposition.
     * All communications and call dispositions created from
     * left patient sidebar call button will be considered an immedate
     * outbound call without the scheduled calls UI workflow.
     */
    async function createCallDisposition() {
      const commType = OUTBOUND_CALL_TYPE_POST
      const createdComm = await createCommunication(commType)
      if (!createdComm) {
        useNotificationStore().setNotification({
          message: `Communication for ${commType.type} not created`,
          type: NotificationType.DANGER,
        })
        return
      }

      const commId = createdComm.communicationId
      const dispositionPayload = {
        ...commType.payload,
        speakingWithPersonId: patient.value?.entityId,
        speakingWithType: SpeakingWithType.member,
        isIdentityVerified: false,
        completedByStaffId: selfEntity.value?.entityId,
      }

      datadogRum.addAction('createDispositionStart', {
        patientId: patient.value?.entityId,
        communicationId: createdComm.communicationId,
        staffId: selfEntity.value?.entityId,
        calledFrom: 'PatientContact.vue',
      })
      const createdCallDisposition = await createDisposition(
        commId,
        dispositionPayload
      )

      if (!createdCallDisposition) {
        useNotificationStore().setNotification({
          message: `Communication Subtype ${commType.subtype} not created`,
          type: NotificationType.DANGER,
        })
      }

      await linkOpenSubtask(commId)
      useCommunicationsStore().communicationType = commType
      await router.push({
        query: {
          commId: createdComm.communicationId,
        },
      })
      useNotificationStore().setNotification({
        message: `Communication for ${commType.label} created`,
        type: NotificationType.SUCCESS,
      })
    }

    /**
     * Function to trigger linking any open subtasks to created communication
     * from this sidebar.
     * @param id
     */
    async function linkOpenSubtasks(
      { newIds }: { [key: string]: string[] },
      id: string
    ) {
      const commsStore = useCommunicationsStore()
      for (const newId of newIds) {
        await commsStore.linkSubtask(id, newId)
      }
      if (patient.value) {
        await useSubtasksStore().getSubtasks({
          filter_patient_ids: [patient.value.entityId],
          filter_subtask_ids: newIds,
        })
      }
    }

    const copyContent = async (email: string) => {
      try {
        await navigator.clipboard.writeText(email)
      } catch (err) {
        useNotificationStore().setNotification({
          message: `Failed to copy: ${err}`,
          type: NotificationType.DANGER,
          error: err as string,
        })
        return
      }
      useNotificationStore().setNotification({
        message: 'Content copied to clipboard',
        type: NotificationType.SUCCESS,
      })
    }

    const displayTimeZone = computed(() => {
      if (patient.value?.timezone) {
        const time = now.value.toLocaleTimeString('en-US', {
          timeZone: patient.value?.timezone,
          timeZoneName: 'short',
          hour: '2-digit',
          minute: '2-digit',
        })
        return `Current time for member is ${time}`
      }
      return ''
    })

    return {
      isMobilePhoneNumberType,
      enumValueToKey,
      displayTimeZone,
      splitTitleCaseWithSpaces,
      AddressType,
      toggleManagePatientContactModal,
      fetchOutreachPreferences,
      patientContactInfo,
      addresses,
      preferredTimes,
      preferredMethods,
      lookupEnum,
      ContactTime,
      ContactMethod,
      copyContent,
      patientEmails,
      phoneEntityMaps,
      renderNonTextableMsg,
      formatPhone,
      call,
      createCallDisposition,
      PhoneNumberType,
      createOrOpenText,
      FillColor,
      isTextableAndOptedIn,
      showManagePatientContact,
      capitalize,
    }
  },
})
</script>
<style lang="scss" scoped>
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
../communicationV2/lib/sharedCommunicationParts
