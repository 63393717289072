<template>
  <TModal
    is-visible
    title="Member Outcomes"
    :size="ModalSize.MD"
    :allow-overflow="true"
    @close="() => $emit('hideModal')"
  >
    <div
      v-if="possibleOutcomes"
      id="container"
      class="flex flex-col justify-evenly"
    >
      <div id="checkboxes">
        <div
          v-for="possibleOutcome in possibleOutcomes"
          :key="possibleOutcome.outcomeRefId"
          class="flex flex-row"
        >
          <LegacyTCheckbox
            :checked="isOutcomeChecked(possibleOutcome.outcomeRefId)"
            type="checkbox"
            :label="readableOutcomeRef(possibleOutcome.outcomeRefId)"
            class="pb-3 pr-1"
            @update:checked="() => updateOutcomesForSubtask(possibleOutcome)"
          />
          <div
            v-if="possibleOutcome.description"
            v-tooltip.right="possibleOutcome.description"
          >
            <TIcon icon="informationCircle" />
          </div>
        </div>
        <div v-if="lastUpdatedAt" class="text-xs py-2">
          Outcomes last updated at:
          {{ formattedDate(lastUpdatedAt) }}
        </div>
      </div>
      <div data-cy="outcomes-textareas-section">
        <label class="is-inline-block has-text-weight-bold">Notes:</label>
        <textarea
          v-model="dirtyOutcomeNote"
          class="p-2 mb-8 h-40 w-full border border-solid border-nash-neutral900"
        />
        <label class="is-inline-block has-text-weight-bold">Quotes</label>
        <textarea
          v-model="dirtyOutcomeQuotes"
          class="p-2 mb-8 h-40 w-full border border-solid border-nash-neutral900"
        />
      </div>
    </div>

    <TCollapse v-if="canValidateOutcomes" class="my-3">
      <template #trigger>
        <span class="text-nash-purple500 font-bold"
          >Outcome Validation (health plan team only)</span
        >
      </template>
      <template #content>
        <div
          v-if="outcomesToValidate && outcomesToValidate.length"
          class="flex flex-col justify-evenly"
        >
          <div class="w-6/12">
            <div
              v-for="outcome in outcomesToValidate"
              :key="outcome.outcomeRefId"
              class="max-w-xs"
            >
              <TSelect
                v-if="outcome.outcomeValidation"
                v-model="outcome.outcomeValidation.status"
                :label="readableOutcomeRef(outcome.outcomeRefId ?? '')"
                name="outcomeValidation"
                :options="OutcomeValidationStatuses"
                @update:model-value="outcome.outcomeValidation.status = $event"
              />
              <div
                v-if="
                  outcome.outcomeValidation &&
                  outcome.outcomeValidation.updatedAt &&
                  outcome.outcomeValidation.updatedBy
                "
                class="text-xs px-2"
              >
                Last Updated:
                {{ formattedDate(outcome.outcomeValidation.updatedAt) }} by
                {{
                  getStaffNameFromId(
                    outcome.outcomeValidation.updatedBy,
                    'System'
                  )
                }}
              </div>
            </div>
          </div>
          <div class="w-6/12">
            <label class="is-inline-block has-text-weight-bold"
              >Outcome Story:</label
            >
            <textarea
              v-model="dirtyOutcomeStory"
              class="p-2 mb-8 h-40 w-full border border-solid border-nash-neutral900"
            />
          </div>
        </div>
        <div v-else>No validation required</div>
      </template>
    </TCollapse>

    <template #actions>
      <div class="flex justify-between w-full space-x-2">
        <TMPrimaryButton
          name="clear-outcomes"
          label="Clear All Outcomes"
          action="danger"
          @click="clearOutcomes"
        />
        <div class="flex space-x-2">
          <TMSecondaryButton
            name="close"
            label="Close"
            @click="
              () => {
                $emit('hideModal')
              }
            "
          />
          <TMPrimaryButton
            :disabled="isDisabled"
            name="save-outcome"
            label="Save"
            @click="saveOutcomes"
          />
        </div>
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import find from 'lodash/find'
import { storeToRefs } from 'pinia'

import { PropType, computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { arraysHaveSameElements } from '@/legacy/libs/collections'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime } from '@/legacy/libs/format'

import LegacyTCheckbox from '@/legacy/nashville/LegacyTCheckbox.vue'
import TCollapse from '@/legacy/nashville/TCollapse.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import TSelect from '@/legacy/nashville/TSelect.vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { getStaffNameFromId } from '@/legacy/store/modules/staff'
import { useTaskApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { useOutcomesThymelineApi } from '@/legacy/store/modules/thymelines'
import { NotificationType } from '@/legacy/types/notifications'
import {
  DirtyOutcome,
  Outcome,
  OutcomeReference,
  OutcomeValidationStatuses,
} from '@/legacy/types/outcomes'
import { outcomesParams } from './thymeline/lib/sharedThymelineParts'

export default defineComponent({
  components: {
    TModal,
    LegacyTCheckbox,
    TCollapse,
    TIcon,
    TSelect,
    TMPrimaryButton,
    TMSecondaryButton,
  },
  props: {
    possibleOutcomes: {
      type: Array as PropType<OutcomeReference[]>,
      required: true,
    },
    currentTaskId: {
      type: String,
      default: '',
    },
    currentOutcomes: {
      type: Array as PropType<Outcome[]>,
      required: true,
    },
    currentOutcomeNote: {
      type: String,
      default: '',
    },
    currentOutcomeQuotes: {
      type: String,
      default: '',
    },
    currentOutcomeStory: {
      type: String,
      default: '',
    },
  },
  emits: ['hideModal'],
  setup(props, context) {
    const route = useRoute()

    const { canValidateOutcomes } = storeToRefs(useFlagStore())

    const dirtyOutcomes = ref<DirtyOutcome[]>([])
    const dirtyOutcomeNote = ref('')
    const dirtyOutcomeQuotes = ref('')
    const dirtyOutcomeStory = ref('')

    onMounted(async () => {
      dirtyOutcomes.value = [
        ...props.currentOutcomes.map((outcome: any) => ({
          ...outcome,
          outcomeValidation: outcome.outcomeValidation ?? {
            status: OutcomeValidationStatuses[0][0],
          },
        })),
      ]
      dirtyOutcomeNote.value = props.currentOutcomeNote
      dirtyOutcomeQuotes.value = props.currentOutcomeQuotes
      dirtyOutcomeStory.value =
        find(dirtyOutcomes.value, 'outcomeValidation.story')?.outcomeValidation
          ?.story ?? ''
    })

    const lastUpdatedAt = computed(() => {
      if (dirtyOutcomes.value.length) {
        return dirtyOutcomes.value.reduce(
          (a: DirtyOutcome, b: DirtyOutcome) => {
            if (a.updatedAt && b.updatedAt && a.updatedAt > b.updatedAt) {
              return a
            }

            if (!a.updatedAt) {
              return b
            }

            if (!b.updatedAt) {
              return a
            }
            return a
          }
        ).updatedAt
      }
      return null
    })

    const isDisabled = computed(() => {
      return (
        dirtyOutcomes.value?.length <= 0 &&
        !dirtyOutcomeNote.value &&
        !dirtyOutcomeQuotes.value
      )
    })

    const outcomesToValidate = computed(() => {
      return (
        dirtyOutcomes.value &&
        dirtyOutcomes.value.filter(
          ({ outcomeRefId }) =>
            props.possibleOutcomes.find(
              (possibleOutcome) => possibleOutcome.outcomeRefId === outcomeRefId
            )?.requiresReview
        )
      )
    })

    /**
     *
     * @param stringDate
     */
    function formattedDate(stringDate: string) {
      return formatDateTime(stringToDateTime(stringDate))
    }

    /**
     *
     * @param outcomeRefId
     */
    function readableOutcomeRef(outcomeRefId: string) {
      const ref = props.possibleOutcomes.find(
        (possibleOutcome) => possibleOutcome.outcomeRefId === outcomeRefId
      )
      return ref ? ref.title : ''
    }

    /**
     *
     * @param outcomeRefId
     */
    function isOutcomeChecked(outcomeRefId: string) {
      const currentSelectedOutcomes = dirtyOutcomes.value
      return currentSelectedOutcomes.some(
        (outcome) => outcome.outcomeRefId === outcomeRefId
      )
    }

    /**
     *
     * @param outcome
     */
    function updateOutcomesForSubtask(outcome: OutcomeReference) {
      const outcomeAlreadyChecked = isOutcomeChecked(outcome.outcomeRefId)
      if (outcomeAlreadyChecked) {
        dirtyOutcomes.value = dirtyOutcomes.value.filter(
          (selectedOutcome) =>
            selectedOutcome.outcomeRefId !== outcome.outcomeRefId
        )
        return
      }

      //IMPROVEME (laura):
      /*
      if an outcome is unchecked and then checked, we'd wind up deleting
      previously existing Outcome with that outcomeRefId since this
      won't have an outcomeId and it would make a new one
      */

      dirtyOutcomes.value.push({
        outcomeRefId: outcome.outcomeRefId,
        outcomeValidation: {
          status: OutcomeValidationStatuses[0][0],
        },
        ...(props.currentTaskId ? { taskId: props.currentTaskId } : {}),
      })
    }

    /**
     *
     */
    async function clearOutcomes() {
      dirtyOutcomes.value = []
      dirtyOutcomeNote.value = ''
      dirtyOutcomeQuotes.value = ''
    }

    /**
     *
     */
    async function saveOutcomes() {
      dirtyOutcomes.value.forEach((outcome) => {
        if (
          props.possibleOutcomes.find(
            (possibleOutcome) =>
              possibleOutcome.outcomeRefId === outcome.outcomeRefId
          )?.requiresReview
        ) {
          outcome.outcomeValidation = {
            ...outcome.outcomeValidation,
            story: dirtyOutcomeStory.value,
          }
        } else {
          delete outcome.outcomeValidation
        }
      })

      if (props.currentTaskId) {
        try {
          await useTaskApi().partialUpdate({
            ids: props.currentTaskId,
            body: {
              outcomeNote: dirtyOutcomeNote.value?.length
                ? dirtyOutcomeNote.value
                : null,
              outcomeQuotes: dirtyOutcomeQuotes.value?.length
                ? dirtyOutcomeQuotes.value
                : null,
            },
          })
          // only update outcomes for a task if there are any changes to the outcomes array
          if (
            !arraysHaveSameElements(dirtyOutcomes.value, props.currentOutcomes)
          ) {
            await useTasksStore().updateTaskOutcomes({
              taskId: props.currentTaskId,
              outcomes: dirtyOutcomes.value as Outcome[],
            })
          }
          await useTasksStore().setSuccess('updating outcomes for')
          void useOutcomesThymelineApi().list({
            params: outcomesParams(route.params.patientId as string),
          })
        } catch (e) {
          useNotificationStore().setNotification({
            message: 'Failed to update outcomes',
            description: e as string,
            type: NotificationType.DANGER,
          })
        }
      }
      context.emit('hideModal')
    }

    return {
      lastUpdatedAt,
      dirtyOutcomes,
      isOutcomeChecked,
      updateOutcomesForSubtask,
      readableOutcomeRef,
      dirtyOutcomeNote,
      dirtyOutcomeQuotes,
      canValidateOutcomes,
      outcomesToValidate,
      OutcomeValidationStatuses,
      formattedDate,
      getStaffNameFromId,
      dirtyOutcomeStory,
      clearOutcomes,
      isDisabled,
      saveOutcomes,
      ModalSize,
    }
  },
})
</script>
