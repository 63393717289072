<template>
  <div class="relative">
    <TSpinner
      v-if="!textingInboxDataLoaded"
      name="texting-inbox"
      :partial-page="true"
    />
    <h4>{{ textingInboxTitle }}</h4>
    <div class="mt-3 space-y-3">
      <div
        v-for="t in inboxDataWithLastMessage"
        :key="`${t.phoneNumberId}-${t.entityId}`"
      >
        <TextingConversation :textable-individual="t" @select="select" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TextingConversation from '@/legacy/components/texting/TextingConversation/TextingConversation.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import setup from './controller'

export default defineComponent({
  components: { TSpinner, TextingConversation },
  setup,
})
</script>
