import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'
import {
  NotificationType,
  Notification,
  NotificationState,
} from '@/legacy/types/notifications'

// CONST
const TIMEOUT_MS = 5000

const initialState = (): NotificationState => ({
  notification: null,
})

export const useNotificationStore = defineStore('notification', {
  state: () => cloneDeep(initialState()),
  actions: {
    closeNotification() {
      this.notification = null
    },
    setNotification(notification: Notification) {
      if (notification.error && notification.type === NotificationType.DANGER) {
        console.error(notification.error)
      }
      this.notification = notification
      setTimeout(() => {
        this.closeNotification()
      }, TIMEOUT_MS)
    },
  },
})

/**
 * Take a caught error, log it, and show a warning notification to the user
 * @param err
 * @param message
 */
export function logAndShowErrorMsg(err: any, message: string) {
  if (err instanceof Error) {
    console.error(err)
  }
  useNotificationStore().setNotification({
    message: message,
    type: NotificationType.DANGER,
  })
}
