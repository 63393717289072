// ENUMS --------------------

export enum ClinicalSummaryTabMap {
  SUMMARY = 'PatientClinicalSummary',
  CANCER = 'PatientCancerDetails',
  TREATMENT = 'PatientTreatment',
  MEDICAL_CONDITIONS = 'MedicalConditions',
  FALL_RISK = 'FallRisk',
  PHYSICAL_FUNCTION_ASSESSMENT = 'PhysicalFunctionAssessment',
  TRANSITIONS_OF_CARE = 'TransitionsOfCare',
  MEDICAL_TEAM = 'MedicalTeam',
}

export const clinicalSummaryTabOptions = [
  { label: 'Summary', value: ClinicalSummaryTabMap.SUMMARY },
  { label: 'Cancer', value: ClinicalSummaryTabMap.CANCER },
  { label: 'Treatment', value: ClinicalSummaryTabMap.TREATMENT },
  {
    label: 'Medical Conditions',
    value: ClinicalSummaryTabMap.MEDICAL_CONDITIONS,
  },
  {
    label: 'Medical Team',
    value: ClinicalSummaryTabMap.MEDICAL_TEAM,
  },
  { label: 'Fall Risk', value: ClinicalSummaryTabMap.FALL_RISK },
  {
    label: 'Physical Function',
    value: ClinicalSummaryTabMap.PHYSICAL_FUNCTION_ASSESSMENT,
  },
  { label: 'TOC', value: ClinicalSummaryTabMap.TRANSITIONS_OF_CARE },
]
