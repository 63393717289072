import apiStore from '@/legacy/store/modules/apiBuilder'
import { CarePlanSummary } from '@/legacy/types/patients/carePlanSummaries'

export const useCarePlanSummaryApi = apiStore<CarePlanSummary>(
  'carePlanSummaryApi',
  '/api/care_plan/summary',
  {
    transformData: (d: { data: CarePlanSummary[] }) => d,
  }
)
