<template>
  <div>
    <TModal
      :is-visible="true"
      :size="ModalSize.SM"
      title="Delete Treatment"
      @close="close"
    >
      <div class="flex pb-2 items-center">
        <div>Are you sure you want to delete this treatment?</div>
      </div>
      <div class="treatment-container">
        <div>
          <h5>{{ treatmentDisplay.treatmentAgentDisplay }}</h5>
          <div class="text-nash-neutral800 text-base font-normal">
            Brands: {{ treatmentDisplay.treatmentBrandsDisplay }} •
            {{ treatmentDisplay.treatmentDuration }}
          </div>
        </div>
      </div>
      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="treatment-delete-cancel"
          @click="close"
        />
        <TMDangerButton
          name="treatment-delete"
          label="Delete"
          @click="onDelete"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMDangerButton from '@nashville/button/TMDangerButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { deleteTreatmentProps } from './types'

export default defineComponent({
  components: {
    TModal,
    TMSecondaryButton,
    TMDangerButton,
  },
  props: deleteTreatmentProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.treatment-container {
  @apply border border-nash-neutral400 p-4 rounded-lg font-medium text-sm;
}
</style>
