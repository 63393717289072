// TYPES --------------------
export type PreferredContactMethod = {
  preferredContactMethodId: string
  patientId: string
  method: ContactMethod
}

// ENUMS --------------------
export enum ContactMethod {
  CALL = 'Phone call',
  SMS = 'Text',
  EMAIL = 'Email',
  CAREGIVER = 'Contact my caregiver',
  CASE_WORKER = 'Contact my social worker / case worker',
}
