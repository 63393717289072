<template>
  <div class="overflow-visible px-5 card-style w-full mb-5">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row space-x-4 items-center">
            <h2 class="py-2 print-h2">Care Plan</h2>
            <div>
              <TChip
                v-if="hasSensitiveDx"
                label="Sensitive Diagnosis"
                icon="sensitive"
                icon-fill="nash-tigerlily600"
                icon-pos="left"
              />
            </div>
          </div>
          <div class="flex flex-row">
            <div v-tooltip.left="'Print care plan'">
              <TMSecondaryButton
                icon="print"
                class="w-10 h-10 ml-3 p-4"
                @click="showPdf"
              />
            </div>
            <div v-tooltip.left="'Edit care plan'">
              <TMSecondaryButton
                icon="pencil"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleCarePlanEdit"
              />
            </div>
          </div>
        </div>
        <div>
          <h4>
            {{ patientSummary }}
          </h4>
        </div>
        <div class="grid-container">
          <label class="t-grid-item">Tier</label>
          <div class="t-grid-item">
            {{ tierLevel }}
          </div>
          <label class="t-grid-item">Domain(s)</label>
          <div class="t-grid-item">
            {{ domainsDisplay }}
          </div>
          <label class="t-grid-item">Treatment Status</label>
          <div class="t-grid-item">
            {{ treatmentStatusDisplay }}
          </div>
          <label class="t-grid-item">Treatment Intent</label>
          <div class="t-grid-item">
            {{ treatmentIntentDisplay }}
          </div>
          <label class="t-grid-item">Program Status</label>
          <div class="t-grid-item">
            {{ programStatusDisplay }}
          </div>
          <label class="t-grid-item">Last Update</label>
          <div class="t-grid-item">
            {{ formattedCarePlanUpdateString }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditCarePlan
    v-if="showEditModal"
    :is-open="showEditModal"
    @close="toggleCarePlanEdit"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TChip from '@/legacy/nashville/chip/TChip.vue'
import setup from './controller'
import EditCarePlan from './EditCarePlan/EditCarePlan.vue'

export default defineComponent({
  components: {
    TChip,
    TMSecondaryButton,
    EditCarePlan,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  @apply w-11/12;
}
</style>
