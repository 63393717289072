<template>
  <TTable
    ref="table"
    title="Providers"
    name="PracticeProviders"
    class="px-0"
    :rows="10"
    :columns="columns"
    :api-store="useProvidersApi"
    :params="{
      filter_practice_ids: [practiceId],
    }"
  />
</template>

<script lang="ts">
import { ColumnOptions } from '@thyme/nashville/src/types/tables'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { formatName } from '@/legacy/libs/format'
import TTable from '@/legacy/nashville/table/TTable.vue'
import { useProvidersApi } from '@/legacy/store/modules/provider'
import { Provider } from '@/legacy/types/providers'

export default defineComponent({
  components: { TTable },

  setup() {
    const route = useRoute()
    const practiceId = route.params.practiceId
    const table = ref<{ getData: () => object } | null>(null)
    const columns: ColumnOptions[] = [
      {
        field: 'providerName',
        header: 'Provider Name',
        display: (_: any, row: Provider) =>
          formatName(row.person?.firstName, row.person?.lastName) ?? '',
      },
      {
        field: 'primarySpecialty',
        header: 'Primary Specialty',
      },
      {
        field: 'npi',
        header: 'NPI',
      },
    ]
    return {
      table,
      columns,
      practiceId,
      useProvidersApi,
    }
  },
})
</script>
