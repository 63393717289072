<template>
  <div class="addable-item-wrapper" :class="active ? 'added' : ''">
    <div class="flex justify-between items-center">
      <div class="w-full mr-6">
        <slot>
          <span class="font-bold">{{ label }}</span>
        </slot>
        <slot name="after" />
      </div>
      <div class="flex space-x-2">
        <slot name="actions" />
        <div v-tooltip.left="addTooltip">
          <component
            :is="'TMSecondaryButton'"
            class="shrink-0"
            :icon="active ? 'close' : 'add'"
            :size="active ? 'xs' : ''"
            :disabled="active ? false : disabled"
            @click="click"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TMSecondaryButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    addTooltip: {
      type: String,
      default: null,
    },
  },
  emits: ['select'],
  setup(props, context) {
    const click = () => context.emit('select', !props.active)
    return {
      click,
    }
  },
})
</script>

<style lang="scss">
.addable-item-wrapper {
  @apply border border-nash-neutral400  p-4 rounded-lg;

  &.added {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}
</style>
