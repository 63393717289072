import { requiredInject } from '@thyme/libs/src/vue/inject'
import { FilterType } from '@thyme/nashville/src/types/tables'
import debounce from 'lodash/debounce'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'

import { useRoute } from 'vue-router'
import { thymeDispatch } from '@/legacy/libs/eventBus'

import {
  useCommunicationsStore,
  useLastSuccessfulCallApi,
} from '@/legacy/store/modules/communications'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useCancelledThymelineApi,
  useCompletedThymelineApi,
  useCompletedThymelineV2Api,
  useCancelledThymelineV2Api,
} from '@/legacy/store/modules/thymelines'

import {
  CALL_PARTS,
  Communication,
  CommunicationType,
} from '@/legacy/types/communications/communications'
import {
  Subtask,
  SubtaskRowDisplayType,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'
import {
  ThymelineItemType,
  ThymelineStatus,
} from '@/legacy/types/patients/thymelines'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import {
  columns,
  perPage,
  overloadSearchServiceList,
  getCommunicationEntities,
} from '../lib/sharedThymelineParts'

// CONST
const DEBOUNCE_INTERVAL_MS = 300

/**
 *
 */
export function setup() {
  const { showLastSuccessfulCall, useThymelineV2Api } = storeToRefs(
    useFlagStore()
  )

  const route = useRoute()
  const patientId = requiredInject(PATIENT_ID_KEY)

  const showCancelled = ref(false)

  const showReattemptModal = ref(false)
  const selectedReattemptSubtask = ref<Subtask | null>(null)
  const lastSuccessfulCall = ref<Communication | null>(null)
  const showSubStatusModal = ref(false)
  const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
  const selectedSubStatusSubtask = ref<Subtask | null>(null)
  const subtaskStatus = ref<SubtaskStatus | null>(null)

  // IMPROVEME(MT-3404): remove all overload-related code
  if (!useThymelineV2Api.value) {
    overloadSearchServiceList(useCompletedThymelineApi())
    overloadSearchServiceList(useCancelledThymelineApi())
  }

  onMounted(async () => {
    if (showLastSuccessfulCall.value) {
      lastSuccessfulCall.value = await fetchLastSuccessfulCall()
    }
  })

  const completedParams: { [key: string]: any } = computed(() => {
    return {
      patient_id: patientId.value,
      filter_status: ThymelineStatus.COMPLETED,
      filter_free_text_title: '',
      filter_free_text_notes: '',
    }
  })

  const cancelledParams: { [key: string]: any } = computed(() => {
    return {
      patient_id: patientId.value,
      filter_status: ThymelineStatus.CANCELLED,
      filter_free_text_title: '',
      filter_free_text_notes: '',
    }
  })

  /**
   * Trigger close reattempt modal
   */
  function closeReattemptModal() {
    showReattemptModal.value = false
  }
  /**
   * Trigger open reattempt modal
   * @param subtask
   */
  function openReattemptModal(subtask: Subtask) {
    showReattemptModal.value = true
    selectedReattemptSubtask.value = subtask
  }

  /**
   * Function to show/open SubStatusModal modal
   * @param subtask
   * @param status
   */
  function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
    showSubStatusModal.value = true
    subtaskStatus.value = status
    selectedSubStatusSubtask.value = subtask
  }

  /**
   * Take completed datetime from comm and
   * return time difference from current time to display
   * @param completedDatetime
   */
  function getReadableTimeDiff(completedDatetime: string) {
    const diff = DateTime.now().diff(DateTime.fromISO(completedDatetime), [
      'months',
      'days',
      'hours',
    ])
    if (diff.months > 0) {
      return `${Math.floor(diff.months)} month${diff.months > 1 ? 's' : ''} ago`
    } else if (diff.weeks > 0) {
      return `${Math.floor(diff.weeks)} week${diff.weeks > 1 ? 's' : ''} ago`
    } else if (diff.days > 0) {
      return `${Math.floor(diff.days)} day${diff.days > 1 ? 's' : ''} ago`
    } else if (diff.hours > 0) {
      return `${Math.floor(diff.hours)} hour${diff.hours > 1 ? 's' : ''} ago`
    } else {
      return ` less than an hour ago `
    }
  }

  const filters = {
    itemTypes: {
      value: null,
      modalOptions: {
        label: 'Item Type',
        type: FilterType.Multiselect,
        options: [
          {
            value: ThymelineItemType.SUBTASK,
            label: 'Subtask',
          },
          {
            value: ThymelineItemType.COMMUNICATION,
            label: 'Communication',
          },
          {
            value: ThymelineItemType.TRANSITION_OF_CARE,
            label: 'Transition Of Care',
          },
        ],
        preEnabledOptionsFn: () => [
          ThymelineItemType.SUBTASK,
          ThymelineItemType.COMMUNICATION,
          ThymelineItemType.TRANSITION_OF_CARE,
        ],
      },
    },
  }

  /** Add Communication Button Code */

  const communicationsStore = useCommunicationsStore()

  const isCreating = computed(() => communicationsStore.isCreating)
  const queryCommId = computed(() => `${route.query.commId ?? ''}`)

  /**
   *
   */
  function createCommunication() {
    void communicationsStore.setIsCreating()
  }

  /** End Add Communication Button Code */

  /** Search Filter Code */

  const currentSearch = ref('')
  const freeTextFilterName = ref('filter_free_text_title')
  const freeTextOptions = [
    { label: 'Subtask Title', value: 'filter_free_text_title' },
    { label: 'Notes', value: 'filter_free_text_notes' },
  ]

  const updateSearch = (newSearch: string) =>
    (currentSearch.value = newSearch ?? '')

  const debouncedSearch = debounce(() => {
    thymeDispatch('thymeline-update')
  }, DEBOUNCE_INTERVAL_MS)

  /**
   * set values for free text filters
   */
  function setFilterValues() {
    completedParams.value[freeTextFilterName.value] = currentSearch.value
    cancelledParams.value[freeTextFilterName.value] = currentSearch.value
  }

  const setFreeTextFilterName = (val: string) => {
    currentSearch.value = ''
    setFilterValues()
    freeTextFilterName.value = val
  }

  watch(currentSearch, async () => {
    setFilterValues()
    debouncedSearch()
  })

  /** End Search Filter Code */

  /**
   * Fetch most recent answered call disposition
   */
  async function fetchLastSuccessfulCall() {
    const comms = await useLastSuccessfulCallApi().list({
      params: {
        filter_patient_ids: [patientId.value],
        filter_types: [CommunicationType.Call],
        filter_is_completed: true,
        filter_call_dispositions: ['ANSWERED'],
        sort_by: 'completedDatetime,desc',
        parts: CALL_PARTS,
      },
    })

    if (comms && comms.data.length > 0) {
      await getCommunicationEntities(comms.data)
      return comms.data[0]
    }
    return null
  }

  return {
    selectedReattemptSubtask,
    showReattemptModal,
    openReattemptModal,
    closeReattemptModal,
    showSurfacedStatusReason,
    subtaskStatus,
    showSubStatusModal,
    selectedSubStatusSubtask,
    openSubStatusModal,
    patientId: patientId.value,
    perPage,
    columns,
    showCancelled,

    ThymelineItemType,
    SubtaskRowDisplayType,

    useCompletedThymelineApi,
    useCancelledThymelineApi,

    completedParams,
    cancelledParams,

    filters,

    createCommunication,
    isCreating,
    queryCommId,

    updateSearch,
    setFreeTextFilterName,
    freeTextOptions,
    currentSearch,
    freeTextFilterName,

    lastSuccessfulCall,
    getReadableTimeDiff,
    showLastSuccessfulCall,

    // V2 API
    useThymelineV2Api,
    useCompletedThymelineV2Api,
    useCancelledThymelineV2Api,
  }
}
