<template>
  <div class="relative p-3">
    <h1 data-cy="practice-name">
      {{ practice.name }}
    </h1>

    <div class="container">
      <div>
        {{ practice.practiceSpecialty }}
      </div>
      <div v-if="practice.isHvan">HVAN</div>
      <a v-if="practice.practiceWebsite" :href="practice.practiceWebsite"
        >Practice Website</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    practice: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
</script>
