<template>
  <div>
    <div
      v-if="disableAndShowWarningForComm"
      data-cy="warning"
      class="text-sm text-nash-brick600"
    >
      {{ notesWarning }}
    </div>
    <label class="has-text-weight-bold is-size-5 block mb-1">Notes</label>
    <TTextarea
      name="comms-note"
      :model-value="notes"
      :disabled="disableAndShowWarningForComm"
      @blur="autoSave"
      @update:model-value="(v) => updateNotesForDirtyComm(v)"
    />
    <TMSaveIndicator :state="saveState" />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType } from 'vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TMSaveIndicator from '@/legacy/nashville/molecules/TMSaveIndicator.vue'
import { SaveState } from '@/legacy/types/api/api'
import { DirtyCallDisposition } from '@/legacy/types/communications/callDispositions'
import {
  CommSidebarActionEnums,
  BaseCommunicationPayload,
} from '@/legacy/types/communications/communications'
import { useFlagStore } from '../store/modules/flags/flags'

export default defineComponent({
  components: { TTextarea, TMSaveIndicator },
  props: {
    dirtyCommunication: {
      type: Object as PropType<BaseCommunicationPayload | null>,
      default: null,
    },
    dirtyCallDisposition: {
      type: Object as PropType<DirtyCallDisposition | null>,
      default: null,
    },
    saveState: {
      type: Object as PropType<SaveState>,
      required: true,
    },
  },
  emits: ['updateDirtyActivity', 'createOrUpdate', 'updateNotesForDirtyComm'],
  setup(props, context) {
    const { showScheduledCallNoTime } = storeToRefs(useFlagStore())

    // DirtyCallDisposition ----------
    const completedByStaffId = computed(
      () => props.dirtyCallDisposition?.completedByStaffId
    )
    const disposition = computed(() => props.dirtyCallDisposition?.disposition)

    // DirtyCommunication ----------
    const notes = computed(() => props.dirtyCommunication?.notes ?? '')
    const responsibleStaffId = computed(
      () => props.dirtyCommunication?.responsibleStaffId
    )

    // General ----------
    const isDisposition = computed(() => !!props.dirtyCallDisposition)

    const responsibleStaffEditable = computed(() => {
      return showScheduledCallNoTime.value
        ? !!props.dirtyCommunication?.responsibleRole ||
            !!props.dirtyCommunication?.responsibleStaffId
        : !!props.dirtyCommunication?.responsibleStaffId
    })
    const disableAndShowWarningForComm = computed(() =>
      isDisposition.value
        ? !props.dirtyCallDisposition?.completedByStaffId ||
          !props.dirtyCallDisposition?.disposition
        : !responsibleStaffEditable.value
    )
    const notesWarning = computed(() => {
      const pleaseSelectResponsibleStr = "Please select who's responsible"
      return isDisposition.value
        ? `${pleaseSelectResponsibleStr} and the call result for this communication to enable notes.`
        : `${pleaseSelectResponsibleStr} for this communication to enable notes.`
    })

    const updateDirtyActivity = (val: { activityNote: string }) => {
      context.emit('updateDirtyActivity', val)
    }

    const autoSave = () => {
      context.emit('createOrUpdate', CommSidebarActionEnums.notes)
    }

    const updateNotesForDirtyComm = (notes: string) => {
      context.emit('updateNotesForDirtyComm', notes)
    }

    return {
      autoSave,
      notesWarning,
      completedByStaffId,
      disableAndShowWarningForComm,
      disposition,
      isDisposition,
      notes,
      responsibleStaffId,
      updateDirtyActivity,
      updateNotesForDirtyComm,
    }
  },
})
</script>
