<template>
  <div>
    <h2>TCollapse Debug</h2>
    <TCollapse :is-visible="base">
      <template #trigger>Trigger Slot</template>
      <template #content>Content Slot</template>
    </TCollapse>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import TCollapse from '@/legacy/nashville/TCollapse.vue'

export default defineComponent({
  components: { TCollapse },
  setup() {
    const cStates = {
      base: false, // Default
    }
    return {
      ...toRefs(cStates),
    }
  },
})
</script>
