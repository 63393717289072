import apiStore from '@/legacy/store/modules/apiBuilder'
import { Patient } from '@/legacy/types/patients/patients'

export const usePatientsApi = apiStore<Patient>(
  'patientsApi',
  '/api/patients',
  {
    transformData: (d: { data: Patient[] }) => d,
  }
)
