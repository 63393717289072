<template>
  <div v-show="flowLabel" class="block">
    <h4 class="my-4">Form</h4>
    <div v-if="isReadonly" class="mb-4">
      <span v-if="inClosedStatus && !hasExistingResponse">
        <b>This form was not filled out before this subtask was closed.</b> Due
        to this subtask's status, this form can only be viewed not edited.
      </span>
      <span v-else-if="wasCompletedByOtherSubtask">
        This form was completed as part of another subtask so cannot be edited.
      </span>
      <span v-else-if="!inEditableStatus">
        Due to this subtask's status, this form can only be viewed not edited.
      </span>
      <span v-else-if="!isStaffCompletable">
        This form is not completable by staff so cannot be edited.
      </span>
    </div>
    <div :class="isReadonly ? 'bg-gray-100' : ''">
      <div
        v-if="!isDevEnv"
        :id="subtaskId"
        :class="`space-y-2 is-vcentered ${
          isReadonly ? 'pointer-events-none opacity-50' : ''
        }`"
      />
      <div v-else class="h-20 text-center">Cannot load forms locally.</div>
    </div>
  </div>
</template>

<script lang="ts">
import { IFlowAnswers } from '@formsort/constants/lib/flow'
import FormsortWebEmbed, {
  IFormsortWebEmbed,
  SupportedAnalyticsEvent,
} from '@formsort/web-embed-api'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { getQueryParams } from '@/legacy/libs/formsort'
import { getFormResponse } from '@/legacy/service/forms/forms'
import { useConfigStore } from '@/legacy/store/modules/config'
import {
  FormSortAnswerType,
  FormsortEventResponse,
  SUBTASK_ID_KEY,
} from '@/legacy/types/forms/formSort'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import {
  Subtask,
  SubtaskStatus,
  SubtaskTemplate,
  closedSubtaskStatuses,
} from '@/legacy/types/pathways/subtasks'

export default defineComponent({
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
    subtaskTemplate: {
      type: Object as PropType<SubtaskTemplate>,
      required: true,
    },
  },
  setup(props) {
    const formEmbed = ref<IFormsortWebEmbed | null>(null)
    const { configVals } = storeToRefs(useConfigStore())

    const subtaskId = computed(() => props.subtask?.subtaskId)

    const wasCompletedByOtherSubtask = computed(() => {
      const matchingSubtaskId =
        !subtaskIdFromForm.value || subtaskIdFromForm.value === subtaskId.value
      return !matchingSubtaskId
    })

    const isStaffCompletable = computed(() => {
      const staffCompletableForms = props.subtaskTemplate.staffCompletableForms
      let staffCompletable = false
      if (staffCompletableForms) {
        staffCompletableForms.forEach((form) => {
          if (
            form.fsFlowLabel === flowLabel.value &&
            form.fsVariantLabel === variantLabel.value
          ) {
            staffCompletable = true
          }
        })
      }
      return staffCompletable
    })

    const inEditableStatus = computed(() => {
      const enabledStatuses = [SubtaskStatuses.IN_PROGRESS.value]
      return enabledStatuses.includes(props.subtask?.status as SubtaskStatus)
    })
    const inClosedStatus = computed(() => {
      return closedSubtaskStatuses.includes(
        props.subtask?.status as SubtaskStatus
      )
    })

    const isReadonly = computed(() => {
      return (
        !inEditableStatus.value ||
        wasCompletedByOtherSubtask.value ||
        !isStaffCompletable.value
      )
    })

    const hasError = ref(false)
    const hasExistingResponse = ref(false)

    const flowLabel = ref(
      props.subtaskTemplate.staffCompletableForms?.length
        ? props.subtaskTemplate.staffCompletableForms[0].fsFlowLabel
        : null
    )
    const variantLabel = ref(
      props.subtaskTemplate.staffCompletableForms?.length
        ? props.subtaskTemplate.staffCompletableForms[0].fsVariantLabel
        : null
    )
    const variantRevisionUuid = ref(
      props.subtaskTemplate.staffCompletableForms?.length
        ? props.subtaskTemplate.staffCompletableForms[0].latestRevisionId
        : null
    )

    const prefillData = ref<Array<[string, string]> | null>(null)

    const subtaskIdFromForm = computed(() => {
      if (prefillData.value) {
        const subtaskIdPair = prefillData.value.find(
          (pair) => pair[0] === SUBTASK_ID_KEY
        )
        if (subtaskIdPair) {
          return subtaskIdPair[1]
        }
      }
      return null
    })

    /**
     * Check if the activity has existing form responses and if yes, set form to display and
     * answers based on data
     */
    const getExistingResponse = async () => {
      const formResponseData = await getFormResponse(subtaskId.value)
      if (formResponseData?.formResponse) {
        flowLabel.value = formResponseData.formResponse.flowLabel
        variantLabel.value = formResponseData.formResponse.variantLabel
        variantRevisionUuid.value =
          formResponseData.formResponse.variantRevisionId
        prefillData.value = formResponseData.formResponse.prefillData
        hasExistingResponse.value = true
      }
    }

    /**
     * Convert answers from dictionary format to Array<[string, string]> expected by Formsort
     * https://docs.formsort.com/handling-data/passing-data-in/url-parameters
     * @param answers
     */
    const convertToUrlParams = (answers: IFlowAnswers) => {
      const formattedAnswers: Array<[string, string]> = []
      for (const [k, v] of Object.entries(answers)) {
        if (v === undefined) {
          continue
        }
        if (v.constructor === Array) {
          const answerLabel = `${k}[]`
          v.forEach((answer: FormSortAnswerType) =>
            formattedAnswers.push([answerLabel, answer.toString()])
          )
        } else if (k.includes('.') && v) {
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          const answerLabelParts = k.split('.', 2)
          const answerLabel = `${answerLabelParts[0]}[${answerLabelParts[1]}]`
          formattedAnswers.push([answerLabel, v.toString()])
        } else {
          formattedAnswers.push([k, v.toString()])
        }
      }

      return formattedAnswers
    }

    /**
     * After form submission, immediately capture submitted answers and format them for
     * use as queryParams to redisplay form as we can't guarantee webhook delivery
     * will happen quickly enough to instead call getExistingResponse
     * @param data Dictionary answer payload
     */
    const reloadForm = async (data: FormsortEventResponse) => {
      formEmbed.value?.unloadFlow()

      if (data.answers) {
        prefillData.value = convertToUrlParams(data.answers)
      }
      await embedForm()
    }

    const isDevEnv = computed(() => configVals.value?.env === 'dev')

    /**
     * Load iFrame containing form
     */
    const embedForm = async () => {
      const rootEl = document.getElementById(subtaskId.value)
      if (!rootEl) {
        console.debug('Form root element not defined when attempting to embed.')
        return
      }

      formEmbed.value = FormsortWebEmbed(rootEl, {
        autoHeight: true,
        style: { width: '100%' },
      })

      formEmbed.value.addEventListener(
        SupportedAnalyticsEvent.FlowFinalized,
        reloadForm
      )

      if (flowLabel.value && variantLabel.value && configVals.value?.env) {
        formEmbed.value?.loadFlow(
          'GVGy7e-KFn',
          flowLabel.value,
          variantLabel.value,
          getQueryParams(
            subtaskId.value,
            configVals.value?.env,
            variantRevisionUuid.value,
            prefillData.value
          )
        )
      } else {
        console.log(
          `Either no flow associated with subtask or data is missing to load a flow with
          flow label: ${flowLabel.value} and variant label: ${variantLabel.value}`
        )
      }
    }

    watch(() => subtaskId.value, embedForm)

    onMounted(async () => {
      if (subtaskId.value) {
        await getExistingResponse()
        await embedForm()
      }
    })

    return {
      hasError,
      subtaskId,
      flowLabel,
      isReadonly,
      wasCompletedByOtherSubtask,
      isStaffCompletable,
      inEditableStatus,
      inClosedStatus,
      hasExistingResponse,
      isDevEnv,
    }
  },
})
</script>
