<template>
  <TDropdown v-bind="$attrs" filter>
    <template v-for="(_, s) in $slots" #[s]="slotProps">
      <slot :name="s" v-bind="slotProps"></slot>
    </template>
    <template #filtericon="slotProps">
      <TIcon icon="search" :class="slotProps.class" />
    </template>
  </TDropdown>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TDropdown from './TDropdown.vue'
export default defineComponent({
  components: { TDropdown, TIcon },
  setup() {
    return {}
  },
})
</script>
