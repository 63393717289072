import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ExtractPropTypes } from 'vue'
import { updateMedicalCondition } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/ManageMedicalConditions/queries'
import { archiveMedicalConditionProps } from './types'

type PropsType = ExtractPropTypes<typeof archiveMedicalConditionProps>

/**
 * Set up the ArchiveMedicalConditions component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * Emit back close modal function to parent component
   */
  async function archive() {
    await updateMedicalCondition(
      props.confirmArchiveMedicalCondition.medicalConditionId,
      {
        isArchived: true,
      }
    )
    context.emit('refetch')
    context.emit('close')
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  return {
    ModalSize,
    close,
    archive,
  }
}
