<template>
  <TTable
    ref="table"
    title="Members"
    name="Patients"
    :rows="10"
    :rows-per-page="[10, 25]"
    :columns="columns"
    :params="params"
    :api-store="usePatientsApi"
    :filters="filters"
    :free-text-filter="true"
    :resizable-columns="true"
    :free-text-options="freeTextOptions"
    :free-text-filter-name="freeTextFilterName"
    free-text-placeholder="Search..."
    @on-row-click="rowClick"
  >
    <template #filterOptions>
      <TDropdown
        :options="freeTextOptions"
        :initial-value="freeTextFilterName"
        :show-clear="false"
        class="bg-nash-neutral000"
        option-label="label"
        option-value="value"
        @update:model-value="setFreeTextFilterName"
      />
    </template>
    <template #tableTopActions>
      <router-link :to="{ path: '/patients/create' }">
        <TMPrimaryButton label="+ Add Member" />
      </router-link>
    </template>

    <template #column-name="slotProps">
      <router-link
        :to="slotProps.column.linkFn(slotProps.column, slotProps.row)"
        data-cy="patients-table-name"
      >
        {{ formatNameFromEntity(slotProps.row) }}
      </router-link>
    </template>
  </TTable>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import { defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TTable,
    TMPrimaryButton,
    TDropdown,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.language-chip {
  @apply flex gap-1 px-2 mx-2 rounded-full border border-solid border-nash-purple200;
}
</style>
