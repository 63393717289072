<template>
  <TTable
    ref="table"
    title="Practices"
    name="Practices"
    :rows="10"
    :columns="columns"
    :params="params"
    :api-store="usePracticeListApi"
    :filters="filters"
    :free-text-filter="true"
    :resizable-columns="true"
    free-text-placeholder="Practice name"
  >
  </TTable>
</template>

<script lang="ts">
import {
  ColumnOptions,
  Filter,
  FilterType,
} from '@thyme/nashville/src/types/tables'
import { defineComponent, ref } from 'vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import { usePracticeListApi } from '@/legacy/store/modules/practice'
import { Practice } from '@/legacy/types/practices'

export default defineComponent({
  components: {
    TTable,
  },
  setup() {
    const table = ref<{
      getData: () => object
    } | null>(null)
    const getData = () => table.value?.getData()

    const columns: ColumnOptions[] = [
      {
        field: 'name',
        header: 'Practice Name',
        sortable: true,
        display: (_: any, row: Practice) => row.name,
        linkFn: (_: any, row: Practice) => ({
          path: `/practice/${row.entityId}`,
        }),
      },
      {
        field: 'practiceSpecialty',
        header: 'Specialty',
        sortable: true,
        display: (_: any, row: Practice) => row.practiceSpecialty,
      },
      {
        field: 'isHvan',
        header: 'HVAN?',
        sortable: true,
        display: (_: any, row: Practice) => (row.isHvan ? 'Yes' : 'No'),
      },
      {
        field: 'numProviders',
        header: 'Providers',
        sortable: true,
        display: (_: any, row: Practice) =>
          row.statistics?.numProviders.toString() ?? 'N/A',
      },
    ]

    const filters: { [key: string]: Filter } = {
      practiceSpecialty: {
        value: null,
        matchMode: undefined,
        modalOptions: {
          label: 'Practice Specialty',
          type: FilterType.Multiselect,
          options: [
            { label: 'Oncology', value: 'oncology' },
            { label: 'PCP', value: 'pcp' },
            { label: 'Multi-Specialty', value: 'multi_specialty' },
          ],
        },
      },
      isHvan: {
        value: null,
        matchMode: undefined,
        modalOptions: {
          label: 'Is TCOP?',
          type: FilterType.Select,
          options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
        },
      },
    }

    const params = {
      include_provider_counts: true,
    }

    return {
      table,
      columns,
      filters,
      getData,
      usePracticeListApi,
      params,
    }
  },
})
</script>
