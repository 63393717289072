import {
  ColumnOptions,
  SORT_DESCENDING,
} from '@thyme/nashville/src/types/tables'
import startCase from 'lodash/startCase'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import {
  formatType,
  formatDatetime,
  displayDiagnoses,
  displayRecentStay,
} from '@/legacy/components/patient/transitionOfCare/lib/transitionOfCare'
import {
  useTransitionOfCareApi,
  createTransitionOfCareApi,
} from '@/legacy/store/modules/admin'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { TransitionOfCare } from '@/legacy/types/patients/transitionsOfCare'

/**
 *
 */
export default function () {
  const { patient } = storeToRefs(usePatientStore())
  const patientTimezone = ref<string>(patient.value?.timezone ?? '')
  const columns: ColumnOptions[] = [
    {
      field: 'transitionDate',
      header: 'Date and Time',
      display: (_: any, row: TransitionOfCare) =>
        formatDatetime(row.transitionDate, patientTimezone.value),
      style: { width: '12rem' },
      truncateLength: 1000,
    },
    {
      field: 'type',
      header: 'Type',
      display: (_: any, row: TransitionOfCare) => formatType(row),
      style: { width: '12rem' },
      truncateLength: 1000,
    },
    {
      field: 'diagnoses',
      header: 'Diagnoses',
      display: (_: any, row: TransitionOfCare) => displayDiagnoses(row),
      style: { 'white-space': 'pre-line', width: '36rem' },
      truncateLength: 1000,
    },
  ]

  const childColumns: ColumnOptions[] = [
    {
      field: '',
      header: '',
      style: { width: '5em' },
      display: () => '',
    },
    {
      field: 'facilityName',
      header: 'Facility',
      style: { 'white-space': 'pre-line', width: '12rem' },
      truncateLength: 1000,
    },
    {
      field: 'dischargeDisposition',
      header: 'Discharge',
      style: { 'white-space': 'pre-line', width: '12rem' },
      truncateLength: 1000,
    },
    {
      field: 'recentSnfStayFlag',
      header: 'Recent Stay',
      display: (_: any, row: TransitionOfCare) => displayRecentStay(row),
      style: {
        'white-space': 'pre-line',
        width: '36rem',
        color: 'rgb(185 28 28)',
      },
      truncateLength: 1000,
    },
  ]

  const table = ref<{ getData: () => object } | null>(null)
  return {
    columns,
    childColumns,
    table,
    useTransitionOfCareApi,
    SORT_DESCENDING,
    patient,
    displayDiagnoses,
    formatType,
    formatDatetime,
    createTransitionOfCareApi,
    startCase,
  }
}
