<template>
  <div class="flex flex-col p-3">
    <div
      class="flex justify-between items-center w-full border-b border-nash-neutral500 mb-2"
    >
      <h4>Insurance</h4>
      <TMTertiaryButton
        icon="pencil"
        name="openEditInsurancesModal"
        @click="toggleEditInsurancesModal"
      />
    </div>
    <div class="flex flex-col space-y-4">
      <InsuranceDisplay
        v-for="insurance in insuranceDisplays"
        :key="insurance.insuranceId"
        :insurance="insurance"
      />
    </div>
    <EditInsurances
      v-if="showEditInsurances"
      :insurances="editInsuranceModels"
      @close="toggleEditInsurancesModal"
    />
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import EditInsurances from './EditInsurances/EditInsurances.vue'
import InsuranceDisplay from './InsuranceDisplay/InsuranceDisplay.vue'

export default defineComponent({
  components: {
    EditInsurances,
    InsuranceDisplay,
    TMTertiaryButton,
  },
  setup,
})
</script>
