import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeWithTime } from '@/legacy/libs/format'
import { StatusReason } from '@/legacy/types/pathways/pathways'

/**
 *
 * @param statusReason
 * @param minStartDatetime
 * @param isTask
 */
export function renderBlockedMessage(
  statusReason: string | null | undefined,
  minStartDatetime: string | null,
  isTask = false
) {
  const child = isTask ? 'task' : 'subtask'
  const parent = isTask ? 'playbook' : 'task'

  if (statusReason === StatusReason.BLOCKED_BY_MIN_TIME) {
    if (minStartDatetime) {
      return `This ${child} is blocked by a time delay. It can be started on ${formatDateTimeWithTime(
        stringToDateTime(minStartDatetime)
      )}`
    }
    return `This ${child} is blocked by a time delay`
  }
  if (statusReason === StatusReason.PROPEGATED_FROM_PARENT) {
    return `This ${child} is blocked because the ${parent} it is contained by is blocked`
  }
  if (statusReason === StatusReason.BLOCKED_BY_UPSTREAM) {
    return `This ${child} is blocked by an incomplete ${child} within this ${parent}`
  }
  return `This ${child} is blocked`
}
