<template>
  <div class="section-bottom-border whitespace-pre-wrap break-inside-avoid">
    <h4 class="py-2 print-h2">Clinical Summary</h4>
    <div class="case-conf-grid-3">
      <div>
        <label>Primary Oncology Diagnoses</label>
        <div
          v-for="[cancerCategory, patientDx] of Object.entries(
            mapByCancerCategory
          )"
          :key="cancerCategory"
        >
          <b>{{ cancerCategory }}</b>
          <p>{{ dxListDetails(patientDx) }}</p>
        </div>
        <div class="print:hidden">
          <a href="#oncology-dx">See all...</a>
        </div>
      </div>
      <div>
        <label>Comorbidities</label>
        <div v-if="comorbiditiesToShow?.length">
          <div
            v-for="comorbidity in comorbiditiesToShow"
            :key="comorbidity.comorbidityId"
            class="flex gap-2 py-1"
          >
            <b>{{ getComorbidityName(comorbidity.comorbidityRefId) }}</b>
            <div
              v-tooltip.top="
                'Sensitive diagnosis &mdash; do not disclose without explicit consent'
              "
            >
              <TIcon
                v-if="isSensitiveComorbidity(comorbidity.comorbidityRefId)"
                icon="sensitive"
                size="sm"
              />
            </div>
          </div>
          <div class="print:hidden">
            <a href="#comorbids">See all...</a>
          </div>
        </div>
        <div v-else>N/A</div>
      </div>
      <div>
        <label>Oncology Treatments</label>
        <div v-if="displayedTreatments?.length">
          <div
            v-for="treatment in displayedTreatments"
            :key="treatment.oncologyTreatmentId"
          >
            <b>{{ getTreatmentName(treatment.oncologyTreatmentRefId) }}</b>
          </div>
          <div v-if="displayedTreatments" class="print:hidden">
            <a href="#oncology-tx">See all...</a>
          </div>
        </div>
        <div v-else>N/A</div>
      </div>
    </div>
    <div v-if="isEditMode" class="case-conf-grid-3">
      <TMAutosaveInput
        label="High Risk Medications"
        :data="summary?.highRiskMedications"
        :disabled="!isEditMode"
        :api-call="
          (highRiskMed: string) =>
            save(
              CaseConferenceUpdates.highRiskMedications,
              highRiskMed,
              CaseConferenceStrings.highRiskMedications,
              highRiskMedSaveState
            )
        "
        :save-state="highRiskMedSaveState"
        :type="InputType.TEXTAREA"
      />
      <TMAutosaveInput
        label="Functional Status"
        :data="summary?.functionalStatus"
        :disabled="!isEditMode"
        :api-call="
          (functionalStatus: string) =>
            save(
              CaseConferenceUpdates.functionalStatus,
              functionalStatus,
              CaseConferenceStrings.functionalStatus,
              functionalStatusSaveState
            )
        "
        :save-state="functionalStatusSaveState"
        :type="InputType.TEXTAREA"
      />
      <TMAutosaveInput
        label="Fall Risk"
        :data="summary?.fallRisk"
        :disabled="!isEditMode"
        :api-call="
          (fallRisk: string) =>
            save(
              CaseConferenceUpdates.fallRisk,
              fallRisk,
              CaseConferenceStrings.fallRisk,
              fallRiskSaveState
            )
        "
        :save-state="fallRiskSaveState"
        :type="InputType.TEXTAREA"
      />
    </div>
    <div v-else class="case-conf-grid-3">
      <div>
        <div>
          <label>High Risk Medications</label>
        </div>
        <div>
          {{ summary?.highRiskMedications || 'N/A' }}
        </div>
      </div>
      <div>
        <div>
          <label>Functional Status</label>
        </div>
        <div>
          {{ summary?.functionalStatus || 'N/A' }}
        </div>
      </div>
      <div>
        <div>
          <label>Fall Risk</label>
        </div>
        <div>
          {{ summary?.fallRisk || 'N/A' }}
        </div>
      </div>
    </div>
    <div v-if="isEditMode" class="case-conf-grid-3">
      <TMAutosaveInput
        label="Recent Transitions of Care"
        :data="summary?.transitionsOfCare"
        :disabled="!isEditMode"
        :api-call="
          (transitionsOfCare: string) =>
            save(
              CaseConferenceUpdates.transitionsOfCare,
              transitionsOfCare,
              CaseConferenceStrings.transitionsOfCare,
              tocSaveState
            )
        "
        :save-state="tocSaveState"
        :type="InputType.TEXTAREA"
      />
      <TMAutosaveInput
        label="Prognosis"
        :data="summary?.prognosis"
        :disabled="!isEditMode"
        :api-call="
          (prognosis: string) =>
            save(
              CaseConferenceUpdates.prognosis,
              prognosis,
              CaseConferenceStrings.prognosis,
              prognosisSaveState
            )
        "
        :save-state="prognosisSaveState"
        :type="InputType.TEXTAREA"
      />
      <TMAutosaveInput
        label="Additional Clinical Context"
        :data="summary?.additionalClinicalContext"
        :disabled="!isEditMode"
        :api-call="
          (additionalClinicalContext: string) =>
            save(
              CaseConferenceUpdates.additionalClinicalContext,
              additionalClinicalContext,
              CaseConferenceStrings.additionalClinicalContext,
              clinicalContextSaveState
            )
        "
        :save-state="clinicalContextSaveState"
        :type="InputType.TEXTAREA"
      />
    </div>
    <div v-else class="case-conf-grid-3">
      <div>
        <div>
          <label>Recent Transitions of Care</label>
        </div>
        <div>
          {{ summary?.transitionsOfCare || 'N/A' }}
        </div>
      </div>
      <div>
        <div>
          <label>Prognosis</label>
        </div>
        <div>
          {{ summary?.prognosis || 'N/A' }}
        </div>
      </div>
      <div>
        <div>
          <label>Additional Clinical Context</label>
        </div>
        <div>
          {{ summary?.additionalClinicalContext || 'N/A' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { InputType } from '@thyme/nashville/src/types/inputs'
import sortBy from 'lodash/sortBy'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { fetchOncReferences } from '@/legacy/components/patient/carePlans/lib/sharedOncologyTreatmentParts'
import { stringToDateTime } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import { formatDateTimeUTC } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { compareNullableStrings } from '@/legacy/libs/string'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import { useComorbidityRefApi } from '@/legacy/store/modules/comorbiditiesReferences'
import { useDiagnosisRefApi } from '@/legacy/store/modules/diagnosesReferences'
import { useOncologyTreatmentRefApi } from '@/legacy/store/modules/oncologyTreatmentsReferences'
import {
  useComorbiditiesApi,
  useDiagnosesApi,
  useOncologyTreatmentApi,
} from '@/legacy/store/modules/patient'
import { SaveState, newSaveState } from '@/legacy/types/api/api'
import { IdMap } from '@/legacy/types/api/store'
import {
  CaseConferenceUpdates,
  CaseConferenceStrings,
  CarePlanSummary,
  CaseConferenceMode,
} from '@/legacy/types/patients/carePlanSummaries'
import {
  Diagnosis,
  DiagnosisReference,
  Stage,
} from '@/legacy/types/patients/diagnoses'

export default defineComponent({
  components: {
    TMAutosaveInput,
    TIcon,
  },
  props: {
    mode: {
      type: String as PropType<CaseConferenceMode>,
      required: true,
    },
    carePlanSummary: {
      type: Object as PropType<CarePlanSummary | null>,
      default: null,
    },
  },
  emits: ['onSave'],
  setup(props, context) {
    const summary = computed(() => props.carePlanSummary)
    const route = useRoute()
    const { data: oncologyTreatments } = storeToRefs(useOncologyTreatmentApi())
    const { data: comorbidityCatalog } = storeToRefs(useComorbidityRefApi())
    const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
    const { data: diagnosisCatalog } = storeToRefs(useDiagnosisRefApi())
    const { data: diagnoses } = storeToRefs(useDiagnosesApi())
    const { data: oncologyTreatmentCatalog } = storeToRefs(
      useOncologyTreatmentRefApi()
    )

    const highRiskMedSaveState = ref<SaveState>(newSaveState())
    const functionalStatusSaveState = ref<SaveState>(newSaveState())
    const fallRiskSaveState = ref<SaveState>(newSaveState())
    const tocSaveState = ref<SaveState>(newSaveState())
    const prognosisSaveState = ref<SaveState>(newSaveState())
    const clinicalContextSaveState = ref<SaveState>(newSaveState())

    const comorbiditiesToShow = computed(() => {
      const activeComorbidities =
        (comorbidities.value &&
          Object.values(comorbidities.value).filter(
            (cx) => !cx.isInaccurate
          )) ??
        []
      if (
        props.mode !== CaseConferenceMode.INTERNAL_VIEW &&
        props.mode !== CaseConferenceMode.EDIT
      ) {
        return activeComorbidities?.filter(
          (cx) => !isSensitiveComorbidity(cx.comorbidityRefId)
        )
      }
      return activeComorbidities
    })
    const isEditMode = computed(() => props.mode === CaseConferenceMode.EDIT)

    const getComorbidityName = (comorbidityId: string) =>
      safeLookup(comorbidityId, comorbidityCatalog.value)?.description ?? ''

    // to be safe, fail-closed by assuming it's sensitive if we can't look it up
    // for some reason
    const isSensitiveComorbidity = (comorbidityId: string) =>
      safeLookup(comorbidityId, comorbidityCatalog.value)?.isSensitive ?? true

    /**
     * get diagnosis name
     * @param diagnosis
     */
    function getDiagnosisName(diagnosis: Diagnosis) {
      const dx = safeLookup(diagnosis.diagnosisRefId, diagnosisCatalog.value)
      if (dx) {
        return dx.description
      } else {
        return 'No Diagnosis Found'
      }
    }

    /**
     *
     * get diagnosis list details
     * @param dx
     */
    function dxListDetails(dx: Diagnosis) {
      const details = []
      if (dx.isPrimary !== null) {
        details.push(dx.isPrimary ? 'Primary' : 'Secondary')
      }
      if (dx.stage) {
        details.push(`Stage ${lookupEnum(Stage, dx.stage)}`)
      }
      if (dx.diagnosisDate) {
        details.push(
          `Diagnosed on ${formatDateTimeUTC(
            stringToDateTime(dx.diagnosisDate)
          )}`
        )
      }
      return details.join(' · ')
    }

    const getTreatmentName = (oncologyTreatmentId: string) =>
      safeLookup(oncologyTreatmentId, oncologyTreatmentCatalog.value)
        ?.description ?? ''

    const displayedTreatments = computed(
      () =>
        oncologyTreatments.value &&
        sortBy(
          oncologyTreatments.value,
          (a) => a.lastClaimDate || a.firstClaimDate
        ).reverse()
    )

    // Return dx description => most recent Diagnosis
    const mapByCancerCategory = computed(() => {
      if (diagnosisCatalog.value) {
        const mapLatestDx: IdMap<Diagnosis> = {}
        sortedPatientDxObjs.value.forEach((dx) => {
          const dxCatalogItem = (
            diagnosisCatalog.value as IdMap<DiagnosisReference>
          )[dx.diagnosisRefId]
          if (dxCatalogItem) {
            const dxDescription = dxCatalogItem.description
            if (!(dxDescription in mapLatestDx)) {
              mapLatestDx[dxDescription] = dx
            }
          }
        })
        return mapLatestDx
      }
      return []
    })

    const sortedPatientDxObjs = computed(() => {
      // TODO keep only the newest one
      return Object.values(diagnoses.value ?? {})
        .filter(
          (dx) =>
            (dx.isPrimary && !dx.isInaccurate) ||
            (dx.isPrimary === null && !dx.isInaccurate)
        )
        .sort(
          (a, b) =>
            // Sort dates in descending order (most recent first), with dateless
            // items last.
            -compareNullableStrings(a.diagnosisDate, b.diagnosisDate, false)
        )
    })

    const save = (
      keyName: string,
      updateString: string,
      fieldName: string,
      saveState: SaveState
    ) =>
      context.emit('onSave', {
        keyName,
        updateString,
        fieldName,
        saveState,
      })

    onMounted(async () => {
      const oncologyTreatments = await useOncologyTreatmentApi().list({
        params: {
          filter_patient_ids: [route.params.patientId as string],
        },
      })

      fetchOncReferences(oncologyTreatments.data)
    })

    return {
      InputType,
      summary,
      comorbiditiesToShow,
      mapByCancerCategory,
      displayedTreatments,
      CaseConferenceUpdates,
      CaseConferenceStrings,
      route,
      getDiagnosisName,
      dxListDetails,
      getComorbidityName,
      getTreatmentName,
      save,
      highRiskMedSaveState,
      functionalStatusSaveState,
      fallRiskSaveState,
      tocSaveState,
      prognosisSaveState,
      clinicalContextSaveState,
      isEditMode,
      isSensitiveComorbidity,
    }
  },
})
</script>
<style lang="scss">
.case-conf-grid-3 {
  @apply grid grid-cols-3 gap-4 text-nash-neutral800 print:grid-cols-1;
}
</style>
