<template>
  <div>
    <TModal
      :is-visible="isVisible"
      :title="modalTitle"
      :size="ModalSize.SM"
      :flex="true"
      :allow-overflow="true"
      @close="close"
    >
      <template #preTitle>
        <TIcon
          v-if="viewingType || creating"
          class="mr-2 cursor-pointer"
          icon="chevronLeft"
          @click="reset"
        />
      </template>
      <div v-if="!viewingType && !creating" class="space-y-4">
        <!-- IMPROVEME (MT-2676) PatientContactInfo type is incorrect -->
        <!-- @vue-skip -->
        <div
          v-for="(patientContact, id) in Object.entries(patientContactInfo)"
          :key="id"
          class="patient-contact-wrapper cursor-pointer"
          @click="() => setEditingType(patientContact)"
        >
          <div class="flex flex-row justify-between w-full items-center">
            <div class="flex flex-row items-center">
              <p class="mr-2">
                {{ splitTitleCaseWithSpaces(patientContact[0]) }}
              </p>
              <TBadge
                v-if="
                  Object.values(patientContact[1]).length &&
                  !isOutreachPreferenceType(patientContact[0])
                "
                :value="Object.values(patientContact[1]).length"
              />
            </div>
            <TIcon class="cursor-pointer" icon="chevronRight" />
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!isOutreachPreferenceType(viewingType as string)">
          <div
            v-if="
              !isEmailType(viewingType as string) ||
              (isEmailType(viewingType as string) && !typeDataArray?.length)
            "
            class="mb-4"
          >
            <TMSecondaryButton
              v-if="!creating"
              label="+ Add"
              name="add-editing-type"
              @click="setIsCreating"
            />
          </div>
          <div v-if="!creating">
            <div
              v-if="patientPhoneNumberMaps && viewingPhoneNumber"
              class="space-y-4 pb-2"
            >
              <SharedPhoneNumberWarnings
                :phone-number-maps="patientPhoneNumberMaps"
                :is-contact-modal="false"
              />
            </div>
            <div v-if="!typeDataArray?.length">None documented.</div>
            <div v-else class="space-y-4">
              <div
                v-for="(patientContactData, id) in typeDataArray"
                :key="id"
                :class="`patient-contact-wrapper ${
                  isEditingDataMatch(patientContactData) ||
                  (editingId && isEmailType(viewingType as string))
                    ? 'editing'
                    : ''
                }`"
              >
                <div
                  v-if="
                    !editingId ||
                    (!isEditingDataMatch(patientContactData) &&
                      !isEmailType(viewingType as string))
                  "
                  class="flex flex-row justify-between w-full items-center"
                >
                  <div class="flex flex-row items-center">
                    <div lass="flex flex-col mr-2">
                      <p class="font-bold">
                        {{ renderDataTypeValue(patientContactData) }}
                      </p>
                      <p v-if="viewingType === 'address'">
                        {{
                          (patientContactData as AddressType)
                            .streetAddressLine1
                        }}, {{ (patientContactData as AddressType).city }},
                        {{ (patientContactData as AddressType).state }},
                        {{ (patientContactData as AddressType).zip }}
                      </p>
                    </div>
                  </div>
                  <LegacyTDropdown
                    icon="meatball"
                    model-value=""
                    size="sm"
                    position="bottom-left"
                    :expanded="false"
                    :unmask="true"
                    :show-elements-selected="true"
                    :options="patientContactActions"
                    @update:model-value="
                      triggerOptions($event, patientContactData)
                    "
                  />
                </div>
                <div v-else>
                  <component
                    :is="formComponent"
                    :data="patientContactData"
                    :editing-id="editingId"
                    @exit-create-or-edit="exitCreateOrEdit"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <component
              :is="formComponent"
              :deleting-id="deletingId"
              @exit-create-or-edit="exitCreateOrEdit"
            />
          </div>
        </div>
        <div v-else>
          <EditOutreachPreferences
            :outreach-preferences="typeDataArray"
            @fetch-outreach-preferences="fetchOutreachPreferences"
          />
        </div>
      </div>
      <template #actions>
        <div
          v-if="!creating && !isOutreachPreferenceType(viewingType as string)"
          class="mt-3"
        >
          <TMQuaternaryButton name="cancel" label="Cancel" @click="close" />
          <TMPrimaryButton
            :disabled="
              editingId ||
              creating ||
              isOutreachPreferenceType(viewingType as string)
            "
            class="ml-4"
            name="confirm-patient-contact"
            label="Confirm"
            @click="close"
          />
        </div>
      </template>
    </TModal>

    <!-- DELETE MODAL -->
    <TModal :is-visible="!!deletingId" @close="cancelDelete">
      <div v-if="viewingType === 'phoneNumber'">
        Delete
        <span class="font-bold">{{
          formatDeletingNum(deletingDataObj as MapEntityPhoneNumber)
        }}</span>
        from phone numbers? This action cannot be undone.
      </div>
      <div v-if="viewingType === 'address'">
        Delete the following from addresses? This action cannot be undone.
        <div class="mt-2">
          <AddressBase :address="deletingDataObj as AddressType" />
        </div>
      </div>
      <div v-if="viewingType === 'email'">
        Delete the following email? This action cannot be undone.
        <div class="mt-2">
          {{ (deletingDataObj as PatientEmail).email }}
        </div>
      </div>
      <template #actions>
        <TMQuaternaryButton
          name="cancel"
          label="Cancel"
          @click="cancelDelete"
        />
        <TMPrimaryButton
          class="ml-4"
          name="delete-patient-contact"
          label="Delete"
          @click="deletePatientContact"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TBadge from '@nashville/badge/TBadge.vue'
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { DropdownTriggerOptions } from '@thyme/nashville/src/types/dropdowns'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType, ref, computed, watch, Ref } from 'vue'

import AddressBase from '@/legacy/components/AddressBase.vue'
import { enumValueToKey } from '@/legacy/libs/enum'
import { formatPhone } from '@/legacy/libs/format'
import { splitTitleCaseWithSpaces } from '@/legacy/libs/string'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import { useAddressesApi } from '@/legacy/store/modules/addresses'
import { useEntitiesApi } from '@/legacy/store/modules/entity'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import { IdMap } from '@/legacy/types/api/store'
import {
  Address as AddressType,
  AddressType as AddressTypeEnum,
} from '@/legacy/types/entities/addresses'
import {
  MapEntityPhoneNumber,
  PhoneNumberNameMap,
} from '@/legacy/types/entities/phoneNumbers'

import { NotificationType } from '@/legacy/types/notifications'
import { Contact } from '@/legacy/types/patients/contacts'
import {
  PatientContactInfo,
  PatientEmail,
} from '@/legacy/types/patients/patientSidebar'
import AddressForm from '../forms/AddressForm.vue'
import EmailForm from '../forms/EmailForm.vue'
import PhoneNumberForm from '../forms/PhoneNumberForm.vue'

import { mapPhoneNumberToNames } from '../lib/sharedPhoneNumberVerificationParts'
import EditOutreachPreferences from '../parts/edit/EditOutreachPreferences.vue'
import SharedPhoneNumberWarnings from '../parts/view/SharedPhoneNumberWarnings/SharedPhoneNumberWarnings.vue'

export default defineComponent({
  components: {
    SharedPhoneNumberWarnings,
    AddressBase,
    AddressForm,
    EmailForm,
    PhoneNumberForm,
    TMSecondaryButton,
    EditOutreachPreferences,
    LegacyTDropdown,
    TMPrimaryButton,
    TMQuaternaryButton,
    TIcon,
    TModal,
    TBadge,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    patientContactInfo: {
      type: Object as PropType<PatientContactInfo>,
      default: null,
    },
  },
  emits: ['close', 'fetchOutreachPreferences'],
  setup(props, context) {
    const { patient, person, contacts, phoneNumbers } = storeToRefs(
      usePatientStore()
    )

    const close = () => {
      exitCreateOrEdit()
      reset()
      context.emit('close')
    }

    const viewingPhoneNumber = computed(
      () => viewingType.value === 'phoneNumber'
    )
    const viewingAddress = computed(() => viewingType.value === 'address')
    const viewingEmail = computed(() => viewingType.value === 'email')

    watch(props, (changes) => {
      if (viewingPhoneNumber.value) {
        typeDataArray.value = Object.values(
          changes.patientContactInfo.phoneNumber as IdMap<MapEntityPhoneNumber>
        )
      }
      if (viewingAddress.value) {
        typeDataArray.value = Object.values(
          changes.patientContactInfo.address as IdMap<AddressType>
        )
      }
      if (viewingEmail.value) {
        typeDataArray.value = Object.values(changes.patientContactInfo.email)
      }
    })

    const viewingType: Ref<string | null> = ref(null)
    const typeDataArray: Ref<Array<
      AddressType | MapEntityPhoneNumber | PatientEmail
    > | null> = ref(null)
    const creating = ref(false)

    const editingId = ref<string | null>(null)
    const deletingId = ref<string | null>(null)

    const patientPhoneNumberMaps = ref<PhoneNumberNameMap | null>(null)
    const patientPhoneNumberStrings = computed(() =>
      Object.values(phoneNumbers.value ?? {}).map(
        (mapEntityPhoneNumber: MapEntityPhoneNumber) =>
          mapEntityPhoneNumber.phoneNumber.phoneNumber
      )
    )
    const contactEntityIds = computed(() =>
      Object.values(contacts.value ?? {}).map(
        (contact: Contact) => contact.contactEntityId
      )
    )
    /**
     * Function to set up phone number mappings for
     * patient.
     */
    async function setPatientPhoneNumberMaps() {
      patientPhoneNumberMaps.value = await mapPhoneNumberToNames(
        patientPhoneNumberStrings.value,
        contactEntityIds.value,
        person.value,
        contacts.value,
        patient.value
      )
    }
    watch(patientPhoneNumberStrings, setPatientPhoneNumberMaps)

    const patientContactActions = computed(() => [
      [
        DropdownTriggerOptions.edit,
        `Edit ${splitTitleCaseWithSpaces(viewingType.value)}`,
      ],
      [
        DropdownTriggerOptions.delete,
        `Delete ${splitTitleCaseWithSpaces(viewingType.value)}`,
      ],
    ])

    const modalTitle = computed(() => {
      if (!viewingType.value) {
        return 'Member Contact'
      } else {
        return `${creating.value ? 'Add' : 'Edit'} ${splitTitleCaseWithSpaces(
          viewingType.value
        )}`
      }
    })

    const deletingDataObj = computed(() => {
      if (viewingPhoneNumber.value && deletingId.value && typeDataArray.value) {
        return (typeDataArray.value as Array<MapEntityPhoneNumber>).find(
          (p: MapEntityPhoneNumber) =>
            p.mapEntityPhoneNumberId === deletingId.value
        )
      }
      if (viewingAddress.value && deletingId.value && typeDataArray.value) {
        return (typeDataArray.value as Array<AddressType>).find(
          (d: AddressType) => d.addressId === deletingId.value
        )
      }
      if (viewingEmail.value && deletingId.value && typeDataArray.value) {
        return (typeDataArray.value as Array<PatientEmail>).find(
          (e: { email: string }) => e.email === deletingId.value
        )
      }
      return null
    })

    /**
     * Function to check if data in rendering list display matches current editing id
     * @param data
     */
    function isEditingDataMatch(data: any) {
      if (viewingPhoneNumber.value) {
        return editingId.value === data.mapEntityPhoneNumberId
      } else if (viewingAddress.value) {
        return editingId.value === data.addressId
      }
    }

    /**
     * Function to set create contact mode
     */
    function setIsCreating() {
      creating.value = true
      editingId.value = null
      deletingId.value = null
    }

    /**
     *
     */
    function fetchOutreachPreferences() {
      context.emit('fetchOutreachPreferences')
    }

    /**
     * Function to check for email type
     * @param type
     */
    function isEmailType(type: string) {
      return type === 'email'
    }

    /**
     * Function to check for outreach preference type
     * @param type
     */
    function isOutreachPreferenceType(type: string) {
      return type === 'outreachPreferences'
    }

    /**
     * Function to set editing type (phone number vs. email vs. address etc)
     * and each editing type's data
     * @param patientContactType
     */
    function setEditingType(patientContactType: any) {
      viewingType.value = patientContactType[0]
      typeDataArray.value = Object.values(patientContactType[1])
    }

    /**
     * Function to delete patient contact type (phone number vs. email vs. address)
     */
    async function deletePatientContact() {
      if (patient.value && deletingDataObj.value) {
        if (viewingPhoneNumber.value && deletingId.value) {
          try {
            await useMapEntityPhoneNumberApi().delete({
              ids: [deletingId.value],
            })
          } catch (err) {
            useNotificationStore().setNotification({
              message: 'Failed to delete phone number.',
              type: NotificationType.DANGER,
            })
            return
          }
          const persistingNumbers: MapEntityPhoneNumber[] = Object.values(
            props.patientContactInfo.phoneNumber as IdMap<MapEntityPhoneNumber>
          ).filter(
            ({ mapEntityPhoneNumberId }: MapEntityPhoneNumber) =>
              mapEntityPhoneNumberId !==
              (deletingDataObj.value as MapEntityPhoneNumber)
                .mapEntityPhoneNumberId
          )
          usePatientStore().updatePhoneNumbers(persistingNumbers)
          useNotificationStore().setNotification({
            message: 'Successfully deleted phone number.',
            type: NotificationType.SUCCESS,
          })
        }
        if (viewingAddress.value && deletingId.value) {
          const persistingAddresses: AddressType[] = Object.values(
            props.patientContactInfo.address as IdMap<AddressType>
          ).filter(
            ({ addressId }: AddressType) =>
              addressId !== (deletingDataObj.value as AddressType).addressId
          )

          try {
            await useAddressesApi().delete({
              ids: (deletingDataObj.value as AddressType).addressId,
            })
          } catch (err) {
            useNotificationStore().setNotification({
              message: 'Failed to delete address.',
              type: NotificationType.DANGER,
            })
            return
          }

          usePatientStore().updateAddresses(persistingAddresses)
          useNotificationStore().setNotification({
            message: 'Successfully deleted address.',
            type: NotificationType.SUCCESS,
          })
        }
        if (viewingEmail.value && deletingId.value) {
          try {
            usePatientStore().updateEntity(
              await useEntitiesApi().partialUpdate({
                ids: [patient.value.entityId],
                body: { email: '' },
              })
            )
          } catch (err) {
            useNotificationStore().setNotification({
              message: 'Failed to delete email address.',
              type: NotificationType.DANGER,
            })
            return
          }
          useNotificationStore().setNotification({
            message: `Successfully deleted  email address.`,
            type: NotificationType.SUCCESS,
          })
        }
        cancelDelete()
      }
    }

    /**
     * Function to reset ref values
     */
    function reset() {
      if (creating.value) {
        creating.value = false
      } else if (editingId.value && viewingType.value) {
        editingId.value = null
      } else if (viewingType.value && !editingId.value) {
        editingId.value = null
        typeDataArray.value = null
        viewingType.value = null
        creating.value = false
      } else if (deletingId.value) {
        deletingId.value = null
      }
    }

    /**
     * Function to go back to data type list view
     */
    function exitCreateOrEdit() {
      creating.value = false
      editingId.value = null
    }

    /**
     * Function to cancel out of deleting
     */
    function cancelDelete() {
      deletingId.value = null
    }

    /**
     * Function to help render proper data type (e.g. phone number --> 123-123-1234)
     * @param data
     */
    function renderDataTypeValue(data: any) {
      if (viewingPhoneNumber.value) {
        return formatPhone(data.phoneNumber.phoneNumber)
      } else if (viewingAddress.value) {
        return splitTitleCaseWithSpaces(
          enumValueToKey(AddressTypeEnum, data.type)
        )
      } else if (viewingEmail.value) {
        return data.email
      }
    }

    /**
     * Function to set refs based on delete or edit contact by checking editing type
     * @param value
     * @param data
     */
    function triggerOptions(value: DropdownTriggerOptions, data: any) {
      let targetId
      if (viewingPhoneNumber.value) {
        targetId = data.mapEntityPhoneNumberId
      } else if (viewingEmail.value) {
        targetId = data.email
      } else if (viewingAddress.value) {
        targetId = data.addressId
      }

      if (value === DropdownTriggerOptions.delete) {
        deletingId.value = targetId
      } else if (value === DropdownTriggerOptions.edit) {
        editingId.value = targetId
      }
    }

    /**
     * Function to find and format phone number from data parm
     * @param phoneEntityMap
     */
    function formatDeletingNum(phoneEntityMap: MapEntityPhoneNumber) {
      if (phoneEntityMap) {
        return formatPhone(phoneEntityMap.phoneNumber.phoneNumber)
      }
      return 'N/A'
    }

    const formComponent = computed(() => {
      if (viewingPhoneNumber.value) {
        return 'PhoneNumberForm'
      } else if (viewingAddress.value) {
        return 'AddressForm'
      } else if (viewingEmail.value) {
        return 'EmailForm'
      }
      return null
    })

    return {
      viewingPhoneNumber,
      patientPhoneNumberMaps,
      fetchOutreachPreferences,
      deletePatientContact,
      deletingDataObj,
      formatDeletingNum,
      exitCreateOrEdit,
      deletingId,
      cancelDelete,
      formComponent,
      isEditingDataMatch,
      editingId,
      creating,
      setIsCreating,
      isEmailType,
      triggerOptions,
      isOutreachPreferenceType,
      patientContactActions,
      renderDataTypeValue,
      reset,
      modalTitle,
      viewingType,
      typeDataArray,
      setEditingType,
      splitTitleCaseWithSpaces,
      close,
      ModalSize,
    }
  },
})
</script>
<style lang="scss">
.patient-contact-wrapper {
  @apply border border-nash-neutral400 rounded-lg py-6 px-4 w-full;
  &.editing {
    @apply bg-nash-purple100 border-nash-purple400;
  }
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
