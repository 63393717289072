import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { CarePlanTransitionsOfCare } from '@/pages/PatientProfile/CarePlans/shared/types'
import { useManageTransitionsOfCareApi } from './store'

/**
 * Retrieve tocs for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 * @param pageLength
 * @param pageNumber
 */
export async function getPaginatedTransitionsOfCare(
  patientId: string,
  pageLength: number,
  pageNumber: number
): Promise<CarePlanTransitionsOfCare | null> {
  const manageTocApi = useManageTransitionsOfCareApi()
  const response = await manageTocApi.list({
    params: {
      filter_patient_ids: [patientId],
      sort_by: 'admissionDate,desc',
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (manageTocApi.error) {
    const error = manageTocApi.error
    useNotificationStore().setNotification({
      message: `Error fetching transitions of care`,
      type: NotificationType.WARNING,
      error: `Error fetching transitions of care: ${error}`,
    })
  }
  return response.data
}
