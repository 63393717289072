import apiStore from '@/legacy/store/modules/apiBuilder'
import { VisitResponse, Visit } from '@/legacy/types/visits'

// Visit does not have a single PK, so rather than use an IdMap<Visit> as the
// plural state type we store the default Visit[]
export const useVisitsApi = apiStore<Visit>(
  'visitsApi',
  '/api/patients/visits',
  {
    transformData: (d: VisitResponse) => ({ data: d.data }),
  }
)
