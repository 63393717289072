import { uniq } from 'lodash'
import { toIdMap } from '@/legacy/libs/store'
import { ApiState } from '@/legacy/types/api/apiBuilder'

/**
 * Transform list of items into IdMap + List
 * BUT ALSO appends to existing data rather than overwriting
 * used for virtual scroll as an example
 * @param idLocation
 */
export function transformListAppend<
  T extends { [key: string | number | symbol]: any }
>(idLocation: keyof T) {
  return (d: { data: T[] }, state: ApiState<T, string[]>) => ({
    ...d,
    data: uniq([
      ...(state.data ?? []),
      ...d.data.map((item: T) => item[idLocation] as string),
    ]),
    idMap: { ...(state.idMap ?? {}), ...toIdMap<T>(d.data, idLocation) },
  })
}

/**
 * Inserts GET/PATCH call into existing idMap list
 * used for virtual scroll as an example
 * @param idLocation
 */
export function transformGetAppend<
  T extends { [key: string | number | symbol]: any }
>(idLocation: keyof T) {
  return (d: T, state: ApiState<T, string[]>) => ({
    datum: d,
    idMap: { ...(state.idMap ?? {}), [d[idLocation]]: d },
  })
}
