import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { CarePlanTransitionsOfCare } from '@/pages/PatientProfile/CarePlans/shared/types'
import { useLastUpdatedCarePlanTransitionsOfCareApi } from './shared/store'

/**
 * Retrieve the last updated care plan transitions of care for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getLastUpdatedTransitionOfCare(
  patientId: string
): Promise<CarePlanTransitionsOfCare | null> {
  const carePlanTransitionsOfCareApi =
    useLastUpdatedCarePlanTransitionsOfCareApi()
  const carePlanTransitionsOfCareResponse =
    await carePlanTransitionsOfCareApi.list({
      params: {
        filter_patient_ids: [patientId],
        sort_by: 'updatedAt,desc',
        page_length: 1,
        page_number: 1,
      },
    })
  if (!carePlanTransitionsOfCareResponse?.data?.length) {
    return null
  }

  if (carePlanTransitionsOfCareApi.error) {
    const error = carePlanTransitionsOfCareApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching last updated care plan transitions of care',
      type: NotificationType.DANGER,
      error: `Error fetching last updated care plan transitions of care: ${error}`,
    })
  }
  return carePlanTransitionsOfCareResponse.data[0]
}
