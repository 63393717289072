<template>
  <div>
    <h2>TButton Debug</h2>
    <p>Button Type</p>
    <div class="flex">
      <LegacyTButton>Default button</LegacyTButton>
      <LegacyTButton type="primary">type: primary</LegacyTButton>
      <LegacyTButton type="secondary">type: secondary</LegacyTButton>
      <LegacyTButton type="tertiary">type: tertiary</LegacyTButton>
      <LegacyTButton type="blackAndWhite">type: blackAndWhite</LegacyTButton>
      <LegacyTButton type="transparent">type: transparent</LegacyTButton>
      <LegacyTButton type="danger">type: danger</LegacyTButton>
      <LegacyTButton type="success">type: success</LegacyTButton>
    </div>
    <p>Button Size</p>
    <div class="flex">
      <LegacyTButton>Default button</LegacyTButton>
      <LegacyTButton :size="ButtonSize.XS">size: xs</LegacyTButton>
      <LegacyTButton :size="ButtonSize.SM">size: sm</LegacyTButton>
      <LegacyTButton :size="ButtonSize.MD">size: md</LegacyTButton>
      <LegacyTButton :size="ButtonSize.LG">size: lg</LegacyTButton>
      <LegacyTButton :size="ButtonSize.XL">size: xl</LegacyTButton>
    </div>
    <p>Button Other</p>
    <div class="flex">
      <LegacyTButton>Default button</LegacyTButton>
      <LegacyTButton :inline="true">:inline: true</LegacyTButton>
      <LegacyTButton :expanded="true">:expanded: true</LegacyTButton>
    </div>
    <div class="flex">
      <LegacyTButton :disabled="true">:disabled: true</LegacyTButton>
      <LegacyTButton icon="phone">icon: phone</LegacyTButton>
      <LegacyTButton :multiselect="true">:multiselect: true</LegacyTButton>
      <LegacyTButton :dont-center="true">:dontCenter: true</LegacyTButton>
    </div>
  </div>
</template>

<script lang="ts">
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { defineComponent } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

export default defineComponent({
  components: { LegacyTButton },
  setup() {
    return { ButtonSize }
  },
})
</script>
