import {
  ColumnOptions,
  SORT_ASCENDING,
} from '@thyme/nashville/src/types/tables'
import { ref } from 'vue'
import { sqlDateToFormattedDate } from '@/legacy/libs/date'
import { usePatientClaimsApi } from '@/legacy/store/modules/claims'

/**
 *
 */
export default function () {
  const table = ref<{ getData: () => object } | null>(null)
  const columns: ColumnOptions[] = [
    {
      field: 'date',
      header: 'Date',
      sortable: true,
      display: (date: string) => (date ? sqlDateToFormattedDate(date) : '-'),
    },
    { field: 'diagnosis', header: 'Diagnosis', sortable: true },
    {
      field: 'procedureMedication',
      header: 'Procedure or Medication',
      sortable: true,
    },
    { field: 'providerName', header: 'Provider Name', sortable: true },
    { field: 'placeOfService', header: 'Place of Service', sortable: true },
  ]

  return {
    columns,
    usePatientClaimsApi,
    table,
    SORT_ASCENDING,
  }
}
