import { defineStore, storeToRefs } from 'pinia'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  logAndShowErrorMsg,
  useNotificationStore,
} from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useTasksStore } from '@/legacy/store/modules/tasks'
import { IdMap } from '@/legacy/types/api/store'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import {
  ApiPathway,
  MapPathwayTask,
  Pathway,
  PathwayDataState,
  PathwayPayload,
  PathwayTemplate,
  PathwayTemplateDataState,
  TemplateStatus,
} from '@/legacy/types/pathways/pathways'

// CONST
const PATHWAY_TEMPLATES_PAGE_LENGTH = 10

export const isEvergreenPathway = (pathwayTitle: string) =>
  pathwayTitle.toLowerCase() === 'evergreen' ||
  pathwayTitle.toLowerCase() === 'sprinkles'

/**
 *
 * @param data
 */
function transformPathways(data: ApiPathway[]): Partial<PathwayDataState> {
  let convertedData = data
  if (data?.length) {
    convertedData = data.map((pathway: Pathway) => ({
      ...pathway,
      taskIds: pathway.taskMaps?.map(
        (taskMap: MapPathwayTask) => taskMap.taskId
      ),
    }))
  }
  const pathwayApi = usePathwayApi()
  const currentPathwaysMapping = pathwayApi.data ?? {}
  const newDataMap = toIdMap(convertedData, 'pathwayId')
  const newData = Object.assign({}, currentPathwaysMapping, newDataMap)

  return {
    ...idMapTransform({}, 'data', 'pathwayId', Object.values(newData)),
  }
}

/**
 *
 * @param data
 * @param total
 */
function transformPathwayTemplates(
  data: PathwayTemplate[],
  total: number
): Partial<PathwayTemplateDataState> {
  const pathwayTemplateApi = usePathwayTemplateApi()
  const currentTemplatesMapping = pathwayTemplateApi.data ?? {}
  const newDataMap = toIdMap(data, 'variantId')
  const newData = Object.assign({}, currentTemplatesMapping, newDataMap)

  return {
    queryMetadata: { total },
    ...idMapTransform({}, 'data', 'variantId', Object.values(newData)),
  }
}

export const usePathwaysStore = defineStore('pathways', {
  // cloneDeep due to object ref issue: https://github.com/vuejs/pinia/issues/484
  state: () => ({}),
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} playbook(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} playbook: ${error}`,
      })
    },

    async setSuccess(
      action: string,
      pathwayIds: null | string[] = null,
      refetchAll = false
    ) {
      const { patientId } = storeToRefs(usePatientStore())
      if (patientId.value) {
        const refetchedPathways = await this.getAllPathways(
          patientId.value,
          pathwayIds
        )
        if (refetchAll) {
          const taskIds = refetchedPathways.data
            .map((pathway: Pathway) =>
              pathway.taskMaps?.map(
                (taskMaps: MapPathwayTask) => taskMaps.taskId
              )
            )
            .flat()
          await useTasksStore().getTasks({
            filter_patient_ids: [patientId.value],
            ...(taskIds.length ? { filter_task_ids: taskIds } : {}),
          })
        }
        useNotificationStore().setNotification({
          message: `Success ${action} playbook(s)`,
          type: NotificationType.SUCCESS,
        })
        thymeDispatch('care-plan-update')
      }
    },

    async getAllPathways(memberId: string, pathwayIds: string[] | null = null) {
      const pathwayApi = usePathwayApi()
      const results = await pathwayApi.listAll({
        params: {
          ...(pathwayIds ? { filter_pathway_ids: pathwayIds } : {}),
          filter_patient_ids: [memberId],
          parts: ['task_maps'],
          sortBy: ['title,asc'],
        },
      })
      if (pathwayApi.error) {
        this.setError(pathwayApi.error, 'fetching')
      }

      return results
    },

    async updatePathway(
      pathwayId: string,
      payload: Partial<PathwayPayload>,
      refetchAll = false
    ) {
      const data = await usePathwayApi().partialUpdate({
        body: payload,
        ids: pathwayId,
      })

      const error = usePathwayApi().error
      if (error) {
        this.setError(error, NotificationActions.UPDATE)
        return
      }

      if (data) {
        await this.setSuccess(
          NotificationActions.UPDATE,
          [pathwayId],
          refetchAll
        )
      }
    },

    async createPathways(pathwayIds: string[]) {
      const data: Pathway[] = []
      const { patientId } = storeToRefs(usePatientStore())

      for (let i = 0; i < pathwayIds.length; i++) {
        const res = await usePathwayApi().create({
          body: {
            memberId: patientId.value,
            pathwayVariantId: pathwayIds[i],
          },
        })

        if (res) {
          data.push(res)
        }

        const error = usePathwayApi().error
        if (error) {
          this.setError(error, NotificationActions.CREATE)
          return
        }
      }

      if (data.length) {
        const pathwayIds = data.map((pathway) => pathway.pathwayId)
        await this.setSuccess(NotificationActions.CREATE, pathwayIds, true)
      }
    },
  },
})

/**
 *
 * @param root0
 * @param root0.page_length
 * @param root0.page_number
 * @param root0.filter_free_text
 */
export async function getAllPathwayTemplates({
  page_length = PATHWAY_TEMPLATES_PAGE_LENGTH,
  page_number = 1,
  filter_free_text = null,
}: {
  page_length: number
  page_number: number
  filter_free_text: string | null
}) {
  const pathwayTemplateApi = usePathwayTemplateApi()
  await pathwayTemplateApi.list({
    params: {
      ...(filter_free_text ? { filter_free_text } : {}),
      filter_template_statuses: TemplateStatus.PUBLISHED,
      sort_by: ['title,asc'],
      page_length,
      page_number,
    },
  })
  if (pathwayTemplateApi.error) {
    logAndShowErrorMsg(pathwayTemplateApi.error, 'fetching pathway template')
  }
}

export const usePathwayApi = apiStore<ApiPathway, IdMap<Pathway>>(
  'pathwayApi',
  '/api/pathways',
  {
    transformData: (d: { data: ApiPathway[] }) => transformPathways(d.data),
  }
)

export const usePathwayTemplateApi = apiStore<
  PathwayTemplate,
  IdMap<PathwayTemplate>
>('pathwayTemplateApi', '/api/pathway_templates', {
  transformData: (d: {
    data: PathwayTemplate[]
    queryMetadata: { total: number }
  }) => transformPathwayTemplates(d.data, d.queryMetadata.total),
})
