import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import AddPatient from '@/legacy/pages/AddPatient/AddPatient.vue'
import AllReminders from '@/legacy/pages/AllReminders.vue'
import AdminDebug from '@/legacy/pages/debug/AdminDebug.vue'
import EnrollmentLeadQueue from '@/legacy/pages/EnrollmentLeadQueue.vue'
import EnrollmentQueue from '@/legacy/pages/EnrollmentQueue.vue'
import HomeComponent from '@/legacy/pages/HomePage.vue'
import MyQueue from '@/legacy/pages/MyQueue.vue'

import PatientProfile from '@/legacy/pages/PatientProfile.vue'
import PatientProfilePrint from '@/legacy/pages/PatientProfilePrint.vue'
import PatientsPage from '@/legacy/pages/PatientsPage.vue'
import PodLeadQueue from '@/legacy/pages/PodLeadQueue.vue'
import PracticeProfile from '@/legacy/pages/PracticeProfile.vue'
import PracticesPage from '@/legacy/pages/PracticesPage.vue'
import PatientCarePlanReport from '@/legacy/pages/reporting/PatientCarePlanReport.vue'
import PatientNavigationReport from '@/legacy/pages/reporting/PatientNavigationReport.vue'
import UnknownTextsPage from '@/legacy/pages/UnknownTextsPage.vue'
import { useProfileStore } from '@/legacy/store/modules/profile'
import PatientCarePlanReportV2 from '@/pages/PatientProfile/CarePlans/PatientCarePlanReport/PatientCarePlanReportV2.vue'
import HumanaEligibilityCheck from '@/pages/Tools/HumanaEligibilityCheck/HumanaEligibilityCheck.vue'
import { createAuthGuard } from './guard'

// set to false (LOCAL DEV ONLY) if Auth0 is down
const requiresAuth = true

export type Route = RouteRecordRaw & {
  name: string
  meta: {
    requiresAuth?: boolean
    title: string
  }
}

const routes: Route[] = [
  // Routes without auth
  {
    path: '/',
    name: '#home',
    redirect: () => useProfileStore().entryPoint,
    meta: {
      title: '',
    },
  },
  {
    path: '/error',
    name: '#error',
    component: () => import('@/legacy/pages/ErrorPage.vue'),
    meta: {
      title: 'Error',
    },
  },

  // All other routes require auth and will redirect to auth0 if not authenticated
  {
    path: '/sandbox',
    name: '#sandbox',
    component: AdminDebug,
    meta: {
      requiresAuth,
      title: 'Sandbox',
    },
  },
  {
    path: '/admin/home',
    name: '#adminHome',
    component: HomeComponent,
    meta: {
      requiresAuth,
      title: 'Admin Home',
    },
  },
  {
    path: '/patients',
    name: '#patients',
    component: PatientsPage,
    meta: {
      requiresAuth,
      title: 'Members',
    },
  },
  {
    path: '/patients/create',
    name: '#addPatient',
    component: AddPatient,
    meta: {
      requiresAuth,
      title: 'Create Member',
    },
  },
  {
    path: '/patient/:patientId/',
    name: '#patientProfile',
    component: PatientProfile,
    meta: {
      requiresAuth,
      title: 'Patient Profile',
    },
  },
  {
    path: '/patient/:patientId/print',
    name: '#patientPrint',
    component: PatientProfilePrint,
    meta: {
      title: 'Print Patient Profile',
      requiresAuth,
    },
  },
  {
    path: '/patient/:patientId/report/:reportType',
    name: '#patientNavigationReport',
    component: PatientNavigationReport,
    props: true,
    meta: {
      requiresAuth,
      title: 'Patient Navigation Report',
    },
  },
  {
    path: '/patient/:patientId/report',
    name: '#patientCarePlanReport',
    component: PatientCarePlanReport,
    props: true,
    meta: {
      requiresAuth,
      title: 'Patient Care Plan Report',
    },
  },
  {
    path: '/patient/:patientId/report/v2',
    name: '#patientCarePlanReportV2',
    component: PatientCarePlanReportV2,
    props: true,
    meta: {
      requiresAuth,
      title: 'Patient Care Plan Report',
    },
  },
  {
    path: '/my-queue',
    name: '#myQueue',
    component: MyQueue,
    meta: {
      requiresAuth,
      title: 'My Queue',
    },
  },
  {
    path: '/tools/unknown-texts',
    name: '#unknownTexts',
    component: UnknownTextsPage,
    meta: {
      requiresAuth,
      title: 'Unknown Texts',
    },
  },
  {
    path: '/tools/bulk_edit',
    name: '#bulkEdit',
    component: () => import('@/pages/Tools/BulkEdit/BulkEdit.vue'),
    meta: {
      requiresAuth,
      title: 'Bulk Edit',
    },
  },
  {
    path: '/tools/humana-eligibility-check',
    name: '#HumanaEligibilityCheck',
    component: HumanaEligibilityCheck,
    meta: {
      requiresAuth,
      title: 'Humana Eligibility Check',
    },
  },
  {
    path: '/enrollment-queue',
    name: '#enrollmentQueue',
    component: EnrollmentQueue,
    meta: {
      requiresAuth,
      title: 'Enrollment Queue',
    },
  },
  {
    path: '/pod-lead-queue',
    name: '#podLeadQueue',
    component: PodLeadQueue,
    meta: {
      requiresAuth,
      title: 'Pod Lead Queue',
    },
  },
  {
    path: '/enrollment-lead-queue',
    name: '#enrollmentLeadQueue',
    component: EnrollmentLeadQueue,
    meta: {
      requiresAuth,
      title: 'Enrollment Lead Queue',
    },
  },
  {
    path: '/reminders/all-tasks',
    name: '#allTasks',
    alias: '/reminders/all-reminders',
    component: AllReminders,
    meta: {
      requiresAuth,
      title: 'All Tasks',
    },
  },
  {
    path: '/practices',
    name: '#practices',
    component: PracticesPage,
    meta: {
      requiresAuth,
      title: 'Practices',
    },
  },
  {
    path: '/practice/:practiceId',
    name: '#practice',
    component: PracticeProfile,
    meta: {
      requiresAuth,
      title: 'Practice Page',
    },
  },
  {
    path: '/admin/users',
    name: '#adminUsers',
    component: () => import('@/legacy/components/admin/AdminUser.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Users',
    },
  },
  {
    path: '/admin/csv',
    name: '#adminCsvUploads',
    component: () => import('@/legacy/components/admin/AdminCSVUploads.vue'),
    meta: {
      requiresAuth,
      title: 'Admin CSV Uploads',
    },
  },
  {
    path: '/admin/care_pods',
    name: '#adminCarePods',
    component: () => import('@/legacy/components/admin/AdminCarePod.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Care Pods',
    },
  },

  {
    path: '/admin/playbooks/subtasks',
    name: '#adminSubtasks',
    component: () =>
      import('@/legacy/components/admin/playbooks/AdminSubtasksTable.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Subtasks',
    },
  },
  {
    path: '/admin/playbooks/subtasks/:baseTemplateId',
    name: '#adminEditSubtasks',
    component: () =>
      import(
        '@/legacy/pages/pathwaysAdmin/AdminEditSubtasksTemplate/AdminEditSubtasksTemplate.vue'
      ),
    meta: {
      requiresAuth,
      title: 'Admin Edit Subtask Template',
    },
  },

  {
    path: '/admin/playbooks/tasks',
    name: '#adminTasks',
    component: () =>
      import('@/legacy/components/admin/playbooks/AdminTasksTable.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Tasks',
    },
  },
  {
    path: '/admin/playbooks/tasks/:baseTemplateId',
    name: '#adminEditTasks',
    component: () =>
      import(
        '@/legacy/pages/pathwaysAdmin/AdminEditTasksTemplate/AdminEditTasksTemplate.vue'
      ),
    meta: {
      requiresAuth,
      title: 'Admin Edit Task Template',
    },
  },

  {
    path: '/admin/playbooks/playbooks',
    name: '#adminPlaybooks',
    component: () =>
      import('@/legacy/components/admin/playbooks/AdminPathwaysTable.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Playbooks',
    },
  },
  {
    path: '/admin/playbooks/playbooks/:baseTemplateId',
    name: '#adminEditPlaybooks',
    component: () =>
      import(
        '@/legacy/pages/pathwaysAdmin/AdminEditPathwaysTemplate/AdminEditPathwaysTemplate.vue'
      ),
    meta: {
      requiresAuth,
      title: 'Admin Edit Pathway Template',
    },
  },

  {
    path: '/tools/campaigns',
    name: '#bulkCampaigns',
    component: () =>
      import('@/legacy/components/admin/campaigns/CampaignsTable.vue'),
    meta: {
      title: 'Bulk Campaigns',
      requiresAuth,
    },
  },

  {
    path: '/tools/cohorts',
    name: '#cohorts',
    component: () => import('@/pages/Admin/CohortBuilder/CohortBuilder.vue'),
    meta: {
      title: 'Create Cohort',
      requiresAuth,
    },
  },

  {
    path: '/admin/outcomes/edit',
    name: '#adminEditOutcomes',
    component: () => import('@/legacy/pages/AdminOutcomes.vue'),
    meta: {
      title: 'Admin Edit Outcomes',
      requiresAuth,
    },
  },

  {
    path: '/admin/care_plan_goals',
    name: '#adminEditCarePlanGoals',
    component: () =>
      import(
        '@/legacy/components/admin/AdminCarePlanGoals/AdminCarePlanGoals.vue'
      ),
    meta: {
      requiresAuth,
      title: 'Admin Edit Care Plan Goals',
    },
  },

  {
    path: '/admin/care_pod_assignment',
    name: '#adminCarePodAssignment',
    component: () =>
      import('@/legacy/components/admin/AdminCarePodAssignment.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Care Pod Assignments',
    },
  },
  {
    path: '/admin/comorbidity_reference',
    name: '#adminComorbidityReference',
    component: () =>
      import('@/legacy/components/admin/AdminComorbidityReference.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Comorbidity Refererences',
    },
  },
  {
    path: '/admin/document_category',
    name: '#adminDocumentCategories',
    component: () =>
      import('@/legacy/components/admin/AdminDocumentCategories.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Document Categories',
    },
  },
  {
    path: '/admin/outcomes/edit',
    name: '#adminOutcomes',
    component: () => import('@/legacy/pages/AdminOutcomes.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Edit Outcomes',
    },
  },
  {
    path: '/admin/triggers',
    name: '#adminTriggers',
    component: () =>
      import('@/legacy/components/admin/triggers/AdminTriggers.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Autotriggers',
    },
  },
  {
    path: '/admin/meos_charges',
    name: '#adminMeosCharges',
    component: () =>
      import('@/legacy/components/admin/meosCharges/AdminMeosChargesTable.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Meos Charges',
    },
  },
  {
    path: '/admin/thyme_phone_numbers',
    name: '#adminThymePhoneNumbers',
    component: () => import('@/legacy/pages/AdminThymePhoneNumbers.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Thyme Phone Numbers',
    },
  },
  {
    path: '/admin/thyme_phone_number_configurations',
    name: '#adminThymePhoneNumberConfigurations',
    component: () =>
      import('@/legacy/pages/AdminThymePhoneNumberConfigurations.vue'),
    meta: {
      requiresAuth,
      title: 'Admin Thyme Phone Number Configurations',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '#notFound',
    component: () => import('@/legacy/pages/404Page.vue'),
    meta: {
      requiresAuth,
      title: 'Page Not Found',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  routes,
})

router.beforeEach(createAuthGuard())
router.beforeEach((to, from, next) => {
  const prefix = 'Thyme Box'
  document.title = to.meta.title
    ? `${prefix} - ${String(to.meta.title)}`
    : prefix
  next()
})

export default router
