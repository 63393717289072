import { PropType } from 'vue'

export type MedicalConditionPayload = {
  patientId: string
  medicalConditionRefId: string | null
  otherMedicalCondition?: string | null
  isArchived: boolean
  conditionSpecificAttributes: string[] | null
}

export const OTHER_MEDICAL_CONDITION_REF = {
  description: 'Other',
  isActive: true,
  isSensitive: false,
  activeFrom: new Date().toISOString(),
  activeTo: null,
  medicalConditionRefId: null,
}

export const addMedicalConditionsProps = {
  patientId: {
    type: String,
    required: true,
  },
  excludeIds: {
    type: Array as PropType<string[]>,
    requred: true,
  },
} as const
