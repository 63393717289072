import { map } from 'lodash'
import { storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { usePayerApi } from '@/legacy/store/modules/payers'
import { Insurance } from '@/legacy/types/patients/insurances'
import { EditInsuranceModel } from './EditInsurances/types'
import { InsuranceDisplayModel } from './InsuranceDisplay/types'

/**
 * Controls for EditInsurances modal
 */
export function modalControls() {
  const showEditInsurances = ref(false)

  /**
   * Toggles the visibility of the Edit Insurances modal
   */
  function toggleEditInsurancesModal() {
    showEditInsurances.value = !showEditInsurances.value
  }

  return {
    showEditInsurances,
    toggleEditInsurancesModal,
  }
}

/**
 * Setup the PatientInsurances component
 */
export function setup() {
  const { enablePatientContracts } = storeToRefs(useFlagStore())
  const { insurances } = storeToRefs(usePatientStore())
  const { data: payers } = storeToRefs(usePayerApi())

  /**
   * Returns display name of the payer for the given insurance, if set.
   * If display name is not set, return the payer name.
   * If payer name is not set, return 'Unknown Payer'.
   * If no payers in the store, also return 'Unknown Payer'.
   * @param insurance
   * @returns string
   */
  function getPayerName(insurance: Insurance) {
    const UNKNOWN_PAYER = 'Unknown Payer'

    if (!payers.value || Object.values(payers.value).length === 0) {
      return UNKNOWN_PAYER
    }

    const payer = payers.value[insurance.payerId]
    if (enablePatientContracts.value && payer?.displayName) {
      return payer.displayName
    }
    return payer.payerName
  }

  /**
   * Convert the Insurance apiModel to an InsuranceDisplayModel
   * @param insurance
   */
  function insuranceApiModelToDisplayModel(
    insurance: Insurance
  ): InsuranceDisplayModel {
    return {
      isActive: insurance.isActive,
      isPrimary: insurance.isPrimary,
      memberId: insurance.memberId,
      payerId: insurance.payerId,
      groupId: insurance.groupId,
      planType: insurance.planType,
      planName: insurance.planName,
      otherInsuranceName: insurance.otherInsuranceName,
      marketSegment: insurance.marketSegment,
      networkTier: insurance.networkTier,
      insuranceId: insurance.insuranceId,
      payerName: getPayerName(insurance),
    }
  }

  /**
   * The insurances belonging to the patient to show in the component, structured as an array of InsuranceDisplayModels.
   */
  const insuranceDisplays = computed<InsuranceDisplayModel[]>(() =>
    map(insurances.value, insuranceApiModelToDisplayModel)
  )

  /**
   *
   * @param insurance
   */
  function insuranceApiModeltoEditModel(
    insurance: Insurance
  ): EditInsuranceModel {
    return {
      ...insurance,
      payerName: getPayerName(insurance),
    }
  }

  const editInsuranceModels = computed<EditInsuranceModel[]>(() =>
    map(insurances.value, insuranceApiModeltoEditModel)
  )

  return {
    // InsuranceDisplay props
    insuranceDisplays,
    enablePatientContracts,

    // EditInsurances props
    editInsuranceModels,
    ...modalControls(),
  }
}
