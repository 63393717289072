import apiStore from '@/legacy/store/modules/apiBuilder'
import { CancerDiagnosis } from '@/pages/PatientProfile/CarePlans/shared/types'

export const useCancerDetailApi = apiStore<CancerDiagnosis>(
  'cancerDetailApi',
  '/api/care_plan/cancer_diagnoses',
  {
    transformData: (d: { data: CancerDiagnosis[] }) => d,
  }
)
