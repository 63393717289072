<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Cancer Diagnosis"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <TMessage
        v-if="noCancerSetErrMsg"
        :expanded="false"
        :severity="MessageSeverity.ERROR"
        name="message-box-not-owner"
        :label="noCancerSetErrMsg"
      />
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div>
            <label class="font-bold">Cancer</label>
            <TMFilterDropdown
              name="cancer"
              label="Cancer"
              class="w-full my-2"
              :initial-value="cancerInitialValue"
              :options="cancerOptions"
              option-label="label"
              option-value="value"
              @update:model-value="setCancerField"
            />
          </div>
          <TMDropdownField
            name="oncologySpreadRefIds"
            label="Spread"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="spreadOptions"
            :disabled="showOtherCancerType"
            :model-value="values.oncologySpreadRefIds ?? []"
          />
        </div>
        <div>
          <TMDropdownField
            v-if="!showOtherCancerType"
            name="oncologyDiagnosisRefId"
            class="w-full my-2"
            label="Cancer Type"
            option-label="label"
            option-value="value"
            :options="cancerTypeOptions"
            :model-value="values.oncologyDiagnosisRefId ?? undefined"
            @update:model-value="setCancerType"
          />
          <TInputText
            v-if="showOtherCancerType"
            id="other-onc-input"
            class="mt-2 mb-3"
            label="Cancer Type"
            name="otherOncologyDxType"
            :model-value="values.otherOncologyDxType"
          />
          <TCalendar
            :min-date="null"
            :disable-holidays="false"
            name="diagnosisDate"
            label="Diagnosis Date"
            class="w-full"
            :model-value="values.diagnosisDate ?? undefined"
          />
        </div>
      </div>
      <TTextarea
        label="Medications"
        name="medications"
        :model-value="values.medications ?? ''"
      />
      <TTextarea
        label="Additional Context"
        name="additionalContext"
        :model-value="values.additionalContext ?? ''"
      />
      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="cancer-details-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="cancer-details-save"
          label="Save"
          @click="onSubmit"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'

import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TMFilterDropdown from '@/legacy/nashville/dropdown/TMFilterDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TMessage from '@/legacy/nashville/message/TMessage.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'

import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editCancerDetailsProps } from './types'

export default defineComponent({
  components: {
    TMessage,
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TTextarea,
    TInputText,
    TMSecondaryButton,
    TCalendar,
    TMFilterDropdown,
  },
  props: editCancerDetailsProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
