import { Communication } from '../communications/communications'
import { UUID } from '../global/base'
import { Subtask } from '../pathways/subtasks'
import { TransitionOfCare } from './transitionsOfCare'

export enum ThymelineStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum ThymelineItemType {
  SUBTASK = 'SUBTASK',
  COMMUNICATION = 'COMMUNICATION',
  TRANSITION_OF_CARE = 'TRANSITION_OF_CARE',
}

// IMPROVEME(MT-3404): remove unused type
export type ThymelineItemObjectTypes =
  | Communication
  | Subtask
  | TransitionOfCare

// IMPROVEME(MT-3404): remove unused type
export type ThymelineItem = {
  patientId: UUID
  itemId: UUID
  itemType: ThymelineItemType
  status: ThymelineStatus
  effectiveDatetime: string
  item?: ThymelineItemObjectTypes
}

export type ThymelineV2Item = {
  patientId: UUID
  itemId: UUID
  itemType: ThymelineItemType
  status: ThymelineStatus
  effectiveDatetime: string
  subtask?: Subtask
  communication?: Communication
  transitionOfCare?: TransitionOfCare
}
