import { ExtractPropTypes } from 'vue'
import { deleteInsurance } from './queries'
import { DELETE_INSURANCE_WARNING_PROPS } from './types'

type DeleteInsuranceWarningSetupProps = ExtractPropTypes<
  typeof DELETE_INSURANCE_WARNING_PROPS
>

/**
 *
 * @param props
 * @param context
 */
export function setup(props: DeleteInsuranceWarningSetupProps, context: any) {
  /**
   * Close the delete insurance warning
   */
  function close() {
    context.emit('close')
  }

  /**
   * Submit the delete insurance action
   */
  async function submit() {
    if (props.insuranceId) {
      await deleteInsurance(props.insuranceId)
    }
    close()
  }

  return {
    close,
    submit,
  }
}
