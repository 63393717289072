import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { IconSize } from '@thyme/nashville/src/types/icons'
import { FilterMetaType, FilterType } from '@thyme/nashville/src/types/tables'
import { startCase } from 'lodash'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { ref, computed, onBeforeMount } from 'vue'
import { formatTierScore } from '@/legacy/components/patient/acuity/lib/acuityFns'
import {
  getSubtaskIconName,
  getAvailableStatusOptions,
  getSubtaskIconFill,
  isSubtaskOverdue,
  getPriorityIcon,
} from '@/legacy/components/patient/pathways/lib/subtask'
import { setupContractingEntityIdsFilter } from '@/legacy/components/sharedTable/sharedTableFilters'
import { sortByLabel, arraysToDropdownOptions } from '@/legacy/libs/dropdowns'
import { humanizeRole, staffRoles } from '@/legacy/libs/entity'
import { formatNameFromEntity } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useAdminCarePodApi } from '@/legacy/store/modules/admin'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { renderReattemptTooltip } from '@/legacy/store/modules/reattempts'
import {
  useRemindersPatientsApi,
  useRemindersApi,
} from '@/legacy/store/modules/reminders'
import { useStaffApi } from '@/legacy/store/modules/staff'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { EntityRole } from '@/legacy/types/entities/entities'
import { minDateTomorrow } from '@/legacy/types/global/dates'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import {
  Subtask,
  SubtaskStatus,
  SubtaskVisibility,
  SubtaskPayload,
  SubtaskPriority,
  statusesWithSubStatusReason,
} from '@/legacy/types/pathways/subtasks'
import { CarePodStatus } from '@/legacy/types/patients/carePods'
import {
  hideColumns,
  sharedColumns,
  fullFilters,
  formatStringToArray,
  getFollowUpData,
  formatReminderDatetime,
} from '../lib/sharedRemindersParts'

/**
 *
 */
export default function () {
  const { notification } = storeToRefs(useNotificationStore())

  const { enablePatientContracts } = storeToRefs(useFlagStore())

  const { canEditBulkSubtasks } = storeToRefs(useProfileStore())

  const table = ref<{
    getData: () => object
  } | null>(null)
  const SELECTED_SUBTASK_LIMIT = 100

  const getData = () => table.value?.getData()
  const { data: carePods } = storeToRefs(useAdminCarePodApi())
  const { selfEntity: activeUser } = storeToRefs(useProfileStore())
  const { data: patients } = storeToRefs(useRemindersPatientsApi())
  useContractingEntities()

  const patientsData = computed(() => patients.value)

  const showReattemptModal = ref(false)
  const showSubStatusModal = ref(false)
  const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
  const selectedSubStatusSubtask = ref<Subtask | null>(null)
  const subtaskStatus = ref<SubtaskStatus | null>(null)
  const showBulkEditModal = ref(false)
  const selectedSubtasks = ref<Array<Subtask>>([])
  const selectedReattemptSubtask = ref<Subtask | null>(null)

  const freeTextFilterName = ref<string>('freeText')
  const freeTextOptions = [
    { label: 'Name, Type or Notes', value: 'freeText' },
    { label: 'Subtask IDs', value: 'subtaskIds' },
  ]

  const setFreeTextFilterName = (val: string) => {
    freeTextFilterName.value = val
  }

  /**
   * Set selected subtasks
   * @param updated
   */
  function updateSelectedItems(updated: Subtask[]) {
    selectedSubtasks.value = updated
  }

  /**
   * Show/hide bulk edit modal
   */
  function toggleBulkEditModal() {
    if (
      !showBulkEditModal.value &&
      selectedSubtasks.value.length > SELECTED_SUBTASK_LIMIT
    ) {
      window.confirm(
        `There are ${selectedSubtasks.value.length} subtasks selected. Please select no more than ${SELECTED_SUBTASK_LIMIT} subtasks.`
      )
    } else {
      showBulkEditModal.value = !showBulkEditModal.value
    }
  }

  /**
   * Clear/reset selected subtasks
   */
  function resetSelectedSubtasks() {
    selectedSubtasks.value = []
  }

  /**
   * Close reattempt modal
   */
  function closeReattemptModal() {
    showReattemptModal.value = false
  }
  /**
   * Open reattempt modal and set subtask to reattempt
   * @param subtask
   */
  function openReattemptModal(subtask: Subtask) {
    showReattemptModal.value = true
    selectedReattemptSubtask.value = subtask
  }

  /**
   *
   * @param subtask
   * @param status
   */
  function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
    showSubStatusModal.value = true
    subtaskStatus.value = status
    selectedSubStatusSubtask.value = subtask
  }

  onBeforeMount(async () => {
    if (!useAdminCarePodApi().isQueued()) {
      await useAdminCarePodApi().listAll({
        params: {
          parts: 'staff',
          filter_status: CarePodStatus.ACTIVE,
        },
      })
    }
  })

  const { data: staff } = storeToRefs(useStaffApi())

  /**
   *
   * @param staffId
   */
  function staffName(staffId: string): string {
    return formatNameFromEntity(safeLookup(staffId, staff.value))
  }
  const columns = computed(() => {
    const cols = hideColumns(
      ['preferredContactTime'],
      sharedColumns([
        {
          field: 'ownerId',
          header: 'Assigned To',
          style: { width: '16rem' },
          display: (_: any, row: Subtask) =>
            row.responsibleStaffId
              ? staffName(row.responsibleStaffId)
              : row.responsibleRole
              ? humanizeRole(row.responsibleRole as EntityRole)
              : '-',
        },
      ])
    )
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    cols.splice(6, 0, {
      field: 'preferredLanguage',
      header: 'Language',
      display: (_: any, row: Subtask) =>
        patientsData.value && row.memberId && patientsData.value[row.memberId]
          ? startCase(patientsData.value[row.memberId].person.preferredLanguage)
          : '-',
    })
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    cols.splice(7, 0, {
      field: 'acuityScore',
      header: 'Tier',
      sortable: true,
      style: { width: '10rem' },
      display: (_: any, row: Subtask) =>
        patientsData.value && row.memberId && patientsData.value[row.memberId]
          ? formatTierScore(
              patientsData.value[row.memberId].pinnedAcuityScore?.overallScore
            )
          : '-',
    })

    return cols
  })

  /**
   * retrieve current subtask status
   * @param subtaskStatus
   */
  function getCurrentSubtaskStatus(subtaskStatus: SubtaskStatus) {
    return {
      icon: getSubtaskIconName(subtaskStatus),
      label: SubtaskStatuses[subtaskStatus].text,
      val: subtaskStatus,
    }
  }

  /**
   * retrieve available subtask status options based on current status
   * @param subtaskStatus
   */
  function subtaskStatusOptions(subtaskStatus: SubtaskStatus) {
    const options = getAvailableStatusOptions(subtaskStatus) as any[]
    if (subtaskStatus && options) {
      return options.map(([value, obj]) => ({
        label: obj.text,
        val: value,
        icon: obj.icon,
        fillType: getSubtaskIconFill(value),
      }))
    }
    return []
  }

  const params = computed(() => ({
    ...{
      filter_subtask_visibility: SubtaskVisibility.INCLUDE_HIDDEN,
    },
  }))

  const allCarePods = computed(() =>
    carePods.value
      ? carePods.value
          .map(({ carePodId, name }) => ({
            value: carePodId,
            label: name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : []
  )
  const allRoles = computed(() =>
    staffRoles
      .map(
        (entityRole) =>
          ({
            value: entityRole,
            label: humanizeRole(entityRole),
          } as DropdownKV)
      )
      .sort(sortByLabel)
  )

  const allStaff = computed(() =>
    staff.value
      ? Object.values(staff.value)
          .map((staffMember) => {
            return {
              value: staffMember.entityId,
              label: formatNameFromEntity(staffMember),
            } as DropdownKV
          })
          .sort(sortByLabel)
      : []
  )

  const getFilters = (): FilterMetaType => ({
    ...fullFilters(),
    staffIds: {
      value: null,
      matchMode: undefined,
      modalOptions: {
        label: 'Assignee',
        type: FilterType.Multiselect,
        optionsFn: () => allStaff.value,
        search: true,
      },
    },
    carePodIds: {
      value: null,
      matchMode: undefined,
      modalOptions: {
        label: 'Pods',
        type: FilterType.Multiselect,
        search: true,
        optionsFn: () => allCarePods.value,
      },
    },
    roles: {
      value: null,
      matchMode: undefined,
      modalOptions: {
        label: 'Roles',
        type: FilterType.Multiselect,
        optionsFn: () => allRoles.value,
      },
    },
    showOverdueOnly: {
      value: null,
      matchMode: undefined,
      hidden: true,
      modalOptions: {
        label: 'Show Overdue Only',
        type: FilterType.Select,
        dataLocations: ['dueBefore'],
        optionsFn: () =>
          arraysToDropdownOptions([
            [DateTime.fromJSDate(minDateTomorrow).toISO() as string, 'Yes'],
            ['', 'No'],
          ]),
      },
    },
    dueBefore: { value: null, matchMode: undefined },
    subtaskIds: {
      value: null,
      matchMode: undefined,
      modalOptions: {
        label: 'Subtask IDs',
        type: FilterType.Multiselect,
      },
      valToParamFn: (val: any) =>
        // Ensure that the string is formatted properly before  final split
        // on ',' as value can come as space separated list of IDs.
        // This is because we do not know what the delimiter, or separating character,
        // will be based on what format  the user use as input value. See below example:
        // e.g. "blah,blah blah blah, hello, bye" => ['blah', 'blah', 'blah', 'blah', 'hello', 'bye']
        val?.length ? formatStringToArray(val as string) : null,
    },
    ...(enablePatientContracts.value
      ? { contractingEntityIds: setupContractingEntityIdsFilter() }
      : {}),
  })

  const filters = computed(() => getFilters())

  /**
   * Update subtask status
   * @param subtaskId
   * @param payload
   */
  async function updateSubtask(
    subtaskId: string,
    payload: Partial<SubtaskPayload>
  ) {
    await useSubtasksStore().updateSubtask(subtaskId, payload)
    void table.value?.getData()
  }

  /**
   * Helper function to set and call updateSubtask() if not reattempt
   * @param subtask
   * @param status
   */
  async function setStatus(subtask: Subtask, status: SubtaskStatus) {
    const subtaskId = subtask.subtaskId
    if (status === SubtaskStatus.REATTEMPT) {
      openReattemptModal(subtask)
      return
    }
    if (
      showSurfacedStatusReason.value &&
      statusesWithSubStatusReason.includes(status)
    ) {
      openSubStatusModal(subtask, status)
      return
    }
    if (status === SubtaskStatus.OPEN_UNASSIGNED) {
      await updateSubtask(subtaskId, {
        responsibleRole: subtask.responsibleRole,
        responsibleStaffId: null,
        status,
      })
    } else if (
      status === SubtaskStatus.OPEN_ASSIGNED ||
      (status === SubtaskStatus.IN_PROGRESS && !subtask.responsibleStaffId)
    ) {
      await updateSubtask(subtaskId, {
        responsibleStaffId: activeUser.value?.entityId,
        status,
      })
    } else {
      await updateSubtask(subtaskId, { status })
    }
  }

  return {
    notification,
    resetSelectedSubtasks,
    setFreeTextFilterName,
    freeTextFilterName,
    freeTextOptions,
    patientsData,
    showBulkEditModal,
    toggleBulkEditModal,
    updateSelectedItems,
    selectedSubtasks,
    isSubtaskOverdue,
    getFollowUpData,
    formatReminderDatetime,
    renderReattemptTooltip,
    selectedReattemptSubtask,
    closeReattemptModal,
    showReattemptModal,
    showSurfacedStatusReason,
    subtaskStatus,
    showSubStatusModal,
    selectedSubStatusSubtask,
    table,
    columns,
    filters,
    params,
    useRemindersApi,
    subtaskStatusOptions,
    getSubtaskIconName,
    SubtaskStatus,
    setStatus,
    getData,
    carePods,
    startCase,
    getPriorityIcon,
    SubtaskPriority,
    canEditBulkSubtasks,
    getSubtaskIconFill,
    IconSize,
    getCurrentSubtaskStatus,
  }
}
