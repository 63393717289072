import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useCarePlanUpdateApi, useDomainApi } from './stores'

const DOMAINS_PAGE_LENGTH = 10

/**
 *
 * @param patientId
 */
export async function getDomains(patientId: string) {
  try {
    const domainApi = useDomainApi()
    const res = await domainApi.list({
      params: {
        filter_patient_ids: [patientId],
        page_length: DOMAINS_PAGE_LENGTH,
      },
    })
    return res.data
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error fetching domains for Care Plan view',
      type: NotificationType.WARNING,
      error: `Error fetching domains for Care Plan view: ${err}`,
    })
    return null
  }
}

/**
 *
 * @param patientId
 */
export async function getCarePlanUpdate(patientId: string) {
  const carePlanUpdate = useCarePlanUpdateApi()
  try {
    const res = await carePlanUpdate.list({
      params: {
        filter_patient_ids: [patientId],
      },
    })
    return res.datum
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error fetching care plan updates',
      type: NotificationType.WARNING,
      error: `Error fetching care plan updates: ${err}`,
    })
    return null
  }
}
