<template>
  <TModal
    :is-visible="isOpen"
    :title="`Confirm ${action}`"
    :size="ModalSize.SM"
    @close="closeModal"
  >
    <div>Are you sure you would like to {{ action }} {{ actionTarget }}</div>
    <template #actions>
      <LegacyTButton
        value="Cancel"
        :name="`${action}-cancel`"
        @click="closeModal"
      />
      <LegacyTButton value="Save" :name="`${action}-save`" @click="onSave" />
    </template>
  </TModal>
</template>

<script lang="ts">
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { defineComponent } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'

export default defineComponent({
  components: {
    LegacyTButton,
    TModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    actionTarget: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'save'],
  setup(props, context) {
    const closeModal = () => {
      context.emit('close')
    }
    const onSave = () => {
      context.emit('save')
      closeModal()
    }

    return {
      closeModal,
      onSave,
      ModalSize,
    }
  },
})
</script>
