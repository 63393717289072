import { defineStore } from 'pinia'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { Payer } from '@/legacy/types/patients/insurances'

export const usePayerStore = defineStore('payer', {
  state: () => ({}),
  actions: {
    async fetchPayers() {
      await usePayerApi().listAll({})
    },
  },
})

export const usePayerApi = apiStore<Payer, IdMap<Payer>>(
  'payerApi',
  '/api/payers',
  {
    transformData: (d: { data: Payer[] }): IdMap<Payer> =>
      idMapTransform({}, 'data', 'payerId', d.data),
  }
)
