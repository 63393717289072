<template>
  <div :id="uniqueId">
    <div class="flex mb-4 items-center gap-4" :class="titleClass(printTitle)">
      <h1 v-if="primary">{{ title }}</h1>
      <h2 v-else>{{ title }}</h2>
      <TOPrintButton :print="print" :primary="primary" />
    </div>
    <div class="header hidden">
      <img :src="logo" alt="Thyme Care" />
      <div class="text">
        <div>
          With any questions or concerns, contact Thyme Care:
          <ul>
            <li>Call toll-free <b>1-833-849-6300</b></li>
            <li>Call or text <b>1-201-526-8484</b></li>
          </ul>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Printd from 'printd'
import { defineComponent } from 'vue'
import TOPrintButton from '@/legacy/nashville/button/TOPrintButton.vue'

export default defineComponent({
  components: { TOPrintButton },
  props: {
    title: { type: String, default: '' },
    uniqueId: { type: String, required: true },
    primary: { type: Boolean, default: true },
    printTitle: { type: Boolean, default: true },
  },
  setup(props) {
    const logo = '/assets/images/ThymeCare-Logo-Wordmark-Plum.jpg'

    const cssText = `
      body {
        font-family: sans-serif;
        font-size: 14pt;
        color: #534E5E;
        white-space: pre-wrap;
      }

      .print-hidden {
        display: none;
      }

      .header {
        display: flex;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      
      .header .text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-left: 1px solid #E6D7E0;
        margin-left: 2rem;
        padding-left: 2rem;
        color: #471132;
      }

      .header img {
        width: 12rem;
      }
    `
    /**
     *
     * @param printTitle weather or not to render the title in the print view
     */
    function titleClass(printTitle: boolean) {
      return { 'print-hidden': !printTitle }
    }
    /**
     *  Wrapper function to find and print component
     */
    function print() {
      const d = new Printd()
      const el = document.getElementById(props.uniqueId)
      if (el) {
        d.print(el, [cssText])
      }
    }

    return {
      titleClass,
      logo,
      print,
    }
  },
})
</script>

<style lang="scss">
@media print {
  body {
    @apply bg-nash-neutral000;
  }
}
</style>
