<template>
  <!-- print-hidden css class required here to ensure button is hidden when used in
  PrintableComponent -->
  <div class="print:!hidden print-hidden">
    <TMPrimaryButton v-if="primary" icon="print" @click="print" />
    <TMSecondaryButton v-else icon="print" @click="print" />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  components: { TMPrimaryButton, TMSecondaryButton },
  props: {
    print: {
      type: Function as PropType<() => void>,
      default: window.print,
    },
    primary: { type: Boolean, default: true },
  },
  setup() {
    return {}
  },
})
</script>
