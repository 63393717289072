<template>
  <label
    :class="[
      'inline-flex items-center space-x-2',
      tileClasses,
      disabled ? 'opacity-50 bg-gray-100' : 'is-clickable',
    ]"
  >
    <div class="bg-nash-neutral000 rounded">
      <!-- @vue-expect-error checked does exist on HTMLInputElement -->
      <input
        ref="checkbox"
        type="checkbox"
        :checked="checked"
        :disabled="!!disabled"
        hidden
        @input="(e) => $emit('update:checked', e.target?.checked)"
      />

      <!--
      Most styles can't be applied directly to a checkbox element. Common
      practice is to hide the input and style a sibling element.
    -->
      <!-- @vue-expect-error checkbox ref (above) exists -->
      <TIcon
        icon="check"
        class="focus:ring-2 border text-nash-neutral000 rounded"
        tabindex="0"
        @keydown.space="$refs.checkbox.click()"
      />
    </div>

    <span v-if="label">{{ label }}</span>
  </label>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  components: {
    TIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:checked'],
  setup(props) {
    const tileClasses = computed(() => {
      return props.tile
        ? {
            // always apply these styles
            'p-2 rounded border border-nash-neutral500': true,

            // apply these styles only when checked
            'bg-nash-midnightBlue700 border-nash-midnightBlue700':
              props.checked,
          }
        : {}
    })

    return {
      tileClasses,
    }
  },
})
</script>

<style scoped>
svg {
  stroke-opacity: 0;
}

input:checked + svg {
  @apply border-transparent bg-nash-midnightBlue700;
  stroke-opacity: 1;
}
</style>
