import { DropdownTriggerOptions } from '@thyme/nashville/src/types/dropdowns'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ExtractPropTypes, ref } from 'vue'
import { formatName } from '@/legacy/libs/format'
import { USState } from '@/legacy/types/entities/entities'
import { Insurance } from '@/legacy/types/patients/insurances'
import { EDIT_INSURANCES_PROPS, EditInsuranceModel } from './types'

type EditInsurancesSetupProps = ExtractPropTypes<typeof EDIT_INSURANCES_PROPS>

/**
 *
 * @param _
 * @param context
 */
export function setup(_: EditInsurancesSetupProps, context: any) {
  const editModalInitialData = ref<EditInsuranceModel | null>(null)
  const deletingInsurance = ref<EditInsuranceModel | null>(null)

  const creating = ref(false)

  /**
   * Function to reset ref values
   * @param creatingValue sets creating value to true or false
   */
  function reset(creatingValue = false) {
    creating.value = creatingValue
    editModalInitialData.value = null
    deletingInsurance.value = null
  }

  const close = () => {
    reset()
    context.emit('close')
  }

  /**
   * Function to set refs based on delete or edit insurance
   * @param value
   * @param insurance
   */
  function triggerOptions(
    value: DropdownTriggerOptions,
    insurance: EditInsuranceModel
  ) {
    reset()
    if (value === DropdownTriggerOptions.delete) {
      deletingInsurance.value = insurance
    } else if (value === DropdownTriggerOptions.edit) {
      editModalInitialData.value = insurance
    }
  }

  const insuranceActions = (insurance: Insurance) => [
    [DropdownTriggerOptions.edit, 'Edit Insurance'],
    ...(insurance.isManuallyAdded
      ? [[DropdownTriggerOptions.delete, 'Delete Insurance']]
      : []),
  ]

  return {
    // refs
    deletingInsurance,
    editModalInitialData,
    creating,

    // functions
    triggerOptions,
    reset,
    close,
    insuranceActions,

    // passthrough
    ModalSize,
    USState,
    formatName,
  }
}
