<template>
  <span v-if="!isLoading">
    <template v-if="isCreate"> Created </template>
    <template v-else> Set to {{ readableStatus }} </template>
    via "{{ trigger?.triggerBase?.title ?? triggerId }}" autotrigger

    <template v-if="triggerInput">
      <template
        v-if="triggerInput.inputType === TriggerInputType.SUBTASK_COMPLETION"
      >
        from {{ triggerInput.inputSubtask?.subtaskBase.title }} subtask
        completion
      </template>
      <template
        v-else-if="triggerInput.inputType === TriggerInputType.TASK_UNBLOCKED"
      >
        from {{ triggerInput.inputTask?.taskBase.title }} task unblocking
      </template>
      <template
        v-else-if="triggerInput.inputType === TriggerInputType.TASK_COMPLETION"
      >
        from {{ triggerInput.inputTask?.taskBase.title }} task completion
      </template>
      <template
        v-else-if="
          triggerInput.inputType === TriggerInputType.FORM_COMPLETION ||
          triggerInput.inputType === TriggerInputType.SUBTASK_COMPLETION_FORM
        "
      >
        from a form ({{ triggerInput.inputForm?.form.fsFlowLabel }} -
        {{ triggerInput.inputForm?.form.fsVariantLabel }}) completion
      </template>
      <template
        v-else-if="triggerInput.inputType === TriggerInputType.WAREHOUSE"
      >
        from the data warehouse
      </template>
    </template>

    on {{ isCreate ? readableCreatedAt : readableStatusUpdatedAt }}.
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTimeWithTime } from '@/legacy/libs/format'
import { useTriggerInputsApi } from '@/legacy/store/modules/triggers'
import { StatusReason, SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import {
  TriggerInput,
  TriggerInputType,
} from '@/legacy/types/pathways/triggers/inputs'
import { Trigger } from '@/legacy/types/pathways/triggers/triggers'
export default defineComponent({
  components: {},
  props: {
    subtask: { type: Object as PropType<Subtask>, required: true },
    trigger: {
      type: Object as PropType<Trigger | undefined>,
      default: undefined,
    },
    triggerId: { type: String, required: true },
    isCreate: { type: Boolean, required: true },
  },
  setup(props) {
    const statusReason = computed(() => props.subtask.statusReason)
    const triggerInputsApi = useTriggerInputsApi()
    const triggerInput = ref<TriggerInput | null>(null)
    const isLoading = ref<boolean>(true)

    const readableStatusUpdatedAt = computed(() =>
      formatDateTimeWithTime(stringToDateTime(props.subtask.statusUpdatedAt))
    )

    const readableCreatedAt = computed(() =>
      formatDateTimeWithTime(stringToDateTime(props.subtask.createdAt))
    )

    const readableStatus = computed(
      () => SubtaskStatuses[props.subtask.status].text
    )
    onMounted(async () => {
      const { data } = await triggerInputsApi.list({
        params: {
          filter_trigger_variant_ids: [props.triggerId],
          parts: [
            'input_subtask',
            'input_subtask.subtask_base',
            'input_task',
            'input_task.task_base',
            'input_form',
            'input_form.form',
          ],
        },
      })
      if (data.length > 1) {
        console.warn(
          `Multiple trigger inputs found for ${props.triggerId}. Displaying the first.`
        )
      }
      if (data.length >= 1) {
        triggerInput.value = data[0]
      }
      isLoading.value = false
    })

    return {
      triggerInput,
      statusReason,
      StatusReason,
      readableStatusUpdatedAt,
      readableCreatedAt,
      readableStatus,
      TriggerInputType,
      isLoading,
    }
  },
})
</script>
