<template>
  <span v-for="(text, index) in splitText" :key="index">
    <TMarkdown :source="text" />
    <a
      v-if="(splitLinks?.length ?? 0) > index"
      :href="removeEscapeCharacters(splitLinks[index])"
      >{{ removeEscapeCharacters(splitLinks[index]) }}</a
    >
  </span>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'

/** looking for the string ##(LINK/GOES/HERE) to parse out LINK/GOES/HERE */
export const linkEscapeRegex = /##\(.*\)/g

/**
 * strip ##() off link
 * @param escapedLink
 */
export function removeEscapeCharacters(escapedLink: string | null) {
  const substringStart = 3
  return escapedLink
    ? escapedLink.substring(substringStart, escapedLink.length - 1)
    : ''
}

export default defineComponent({
  components: {
    TMarkdown,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    // return all non-link text
    const splitText = computed(() =>
      props.description ? props.description.split(linkEscapeRegex) : []
    )

    // return all links (wrapped in escape characters)
    const splitLinks = computed(() =>
      props.description ? props.description.match(linkEscapeRegex) ?? [] : []
    )

    return {
      splitText,
      splitLinks,
      removeEscapeCharacters,
    }
  },
})
</script>
