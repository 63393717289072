import { Gender } from '@/legacy/types/entities/people'
import { splitTitleCaseWithSpaces } from './string'

/**
 * Given short form gender as string, return the long form enum name
 * with space separation
 *
 * Ex:  "NB" -> "Non Binary"
 *      "F"  -> "Female"
 * @param gender
 */
export function mapToLongFormGender(gender: string) {
  // Fallback to empty string if gender not in enum
  if (!Object.values(Gender).map(String).includes(gender)) {
    return ''
  }

  return splitTitleCaseWithSpaces(
    Object.keys(Gender).find(
      (key) => Gender[key as keyof typeof Gender] === gender
    )
  )
}
