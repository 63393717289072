import { IdMap } from '@/legacy/types/api/store'
import { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

export type FetchPatientDataFunction<T> = (
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
) => Promise<T | null>

// CANCER DIAGNOSIS ----------------

export type CancerDiagnosis = {
  diagnosisDate: Nullable<string>
  additionalContext: Nullable<string>
  medications: Nullable<string>
  otherOncologyDxType: Nullable<string>
  oncologyDiagnosisRefId: Nullable<UUID>
  cancerDiagnosisId: UUID
  patientId: UUID
  oncologyDiagnosisReference: OncologyDiagnosisReference
  oncologySpreadRefMaps: MapCancerDiagnosisOncSpreadRef[]
  isPrimaryDiagnosis: Nullable<boolean>
} & Timestamps

export type OncologyDiagnosisReference = {
  oncologyDiagnosisCategoryRefId: UUID
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  oncologyDiagnosisRefId: UUID
  oncologyDiagnosisCategoryReference: OncologyDiagnosisCategoryReference
}

export type OncologyDiagnosisCategoryReference = {
  oncologyDiagnosisCategoryRefId: UUID
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
}

export type MapCancerDiagnosisOncSpreadRef = {
  cancerDiagnosisId: UUID
  oncologySpreadRefId: UUID
  mapCancerDiagnosesOncSpreadRefId: UUID
  oncologySpreadReference: OncologySpreadReference
}

export type OncologySpreadReference = {
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  oncologySpreadRefId: UUID
}

// PATIENT CLINICAL SUMMARY ----------------

export type PatientClinicalSummary = {
  cancerDiagnosis: CancerDiagnosis
  currentTreatments: string | null
  ecogStatus: ECOGStatus | null
  tocLastDischargeDate: string | null
  medicalConditions: MedicalCondition[]
}

// MEDICAL CONDITIONS ----------------

export type MedicalCondition = {
  isArchived: boolean
  conditionSpecificAttributes: UUID[]
  medicalConditionId: UUID
  patientId: UUID
  medicalConditionRefId: UUID
  medicalConditionReference: MedicalConditionReference
  otherMedicalCondition: string | null
}

export type MedicalConditionReference = {
  description: string
  isActive: boolean
  isSensitive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  medicalConditionRefId: UUID | null
}

export type MapMedicalAttrsMedicalConditions = {
  medicalConditionAttributeId: UUID
  medicalConditionRefId: UUID
  mapMedConditionAttrRefMedConditionRefId: UUID
}

export type MedicalConditionAttributeReference = {
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  medicalConditionAttributeId: UUID
}

// PHYSICAL FUNCTION ASSESSMENT ----------------

export enum ECOGStatus {
  FULLY_ACTIVE = 'FULLY_ACTIVE',
  LIGHT_WORK_ONLY = 'LIGHT_WORK_ONLY',
  UNABLE_TO_WORK_UP_OVER_50_PERCENT = 'UNABLE_TO_WORK_UP_OVER_50_PERCENT',
  IN_BED_OR_CHAIR_OVER_50_PERCENT = 'IN_BED_OR_CHAIR_OVER_50_PERCENT',
  FULLY_DEPENDENT = 'FULLY_DEPENDENT',
}

export type PhysicalFunctionAssessment = {
  physicalFunctionAssessmentId: UUID
  patientId: UUID
  ecogStatus: ECOGStatus
  iadlsInability: IADLsInability[]
  adlsInability: ADLsInability[]
  unableToLeaveHomeOtherThanMedicalAppts: Nullable<boolean>
  dmeAtHome: DME[]
  proceduralSupport: ProceduralSupport[]
  unableToWalkTwoBlocksOrAFlightOfStairs: Nullable<boolean>
  additionalContext: Nullable<string>
} & Timestamps

export enum IADLsInability {
  TELEPHONE = 'TELEPHONE',
  SHOPPING = 'SHOPPING',
  FOOD_PREPARATION = 'FOOD_PREPARATION',
  HOUSEKEEPING = 'HOUSEKEEPING',
  LAUNDRY = 'LAUNDRY',
  FINANCES = 'FINANCES',
  TRANSPORTATION = 'TRANSPORTATION',
  MEDICATION_PREPARATION = 'MEDICATION_PREPARATION',
  UNKNOWN_HAS_NOT_BEEN_ASSESSED = 'UNKNOWN_HAS_NOT_BEEN_ASSESSED',
  NOT_APPLICABLE_INDEPENDENT = 'NOT_APPLICABLE_INDEPENDENT',
}

export enum ADLsInability {
  TOILETING = 'TOILETING',
  BATHING = 'BATHING',
  EATING_FEEDING = 'EATING_FEEDING',
  DRESSING = 'DRESSING',
  GROOMING = 'GROOMING',
  TRANSFERRING = 'TRANSFERRING',
  WALKING = 'WALKING',
  UNKNOWN_HAS_NOT_BEEN_ASSESSED = 'UNKNOWN_HAS_NOT_BEEN_ASSESSED',
  NOT_APPLICABLE_INDEPENDENT = 'NOT_APPLICABLE_INDEPENDENT',
}

export enum DME {
  CANE = 'CANE',
  WALKER = 'WALKER',
  ROLLATOR = 'ROLLATOR',
  WHEELCHAIR = 'WHEELCHAIR',
  SCOOTER = 'SCOOTER',
  LIFT = 'LIFT',
  COMMODE = 'COMMODE',
  OXYGEN = 'OXYGEN',
  SHOWER_RAILS = 'SHOWER_RAILS',
  SHOWER_BENCH = 'SHOWER_BENCH',
  CPAP = 'CPAP',
  HOSPITAL_BED = 'HOSPITAL_BED',
}

export enum ProceduralSupport {
  TRACHEOSTOMY = 'TRACHEOSTOMY',
  FEEDING_TUBE = 'FEEDING_TUBE',
  ILEOSTOMY = 'ILEOSTOMY',
  COLOSTOMY = 'COLOSTOMY',
  CYSTOSTOMY = 'CYSTOSTOMY',
  UROSTOMY = 'UROSTOMY',
  NEPHROSTOMY = 'NEPHROSTOMY',
  INDWELLING_URINARY_CATHETER = 'INDWELLING_URINARY_CATHETER',
  SURGICAL_TUBE_DRAIN = 'SURGICAL_TUBE_DRAIN',
}

// FALL RISK ----------------
export enum Anticoagulation {
  WARFARIN = 'WARFARIN',
  APIXABAN = 'APIXABAN',
  DABIGATRAN = 'DABIGATRAN',
  RIVAROXABAN = 'RIVAROXABAN',
  EDOXABAN = 'EDOXABAN',
  CLOPIDOGREL = 'CLOPIDOGREL',
  PRASUGREL = 'PRASUGREL',
  TICAGRELOR = 'TICAGRELOR',
  ASPIRIN = 'ASPIRIN',
  NSAIDS = 'NSAIDS',
  NONE = 'NONE',
  OTHER = 'OTHER',
}

export enum SedatingMedications {
  BENZODIAZEPINES = 'BENZODIAZEPINES',
  OPIOIDS = 'OPIOIDS',
  ANTICHOLINERGICS = 'ANTICHOLINERGICS',
  ANTIPSYCHOTICS = 'ANTIPSYCHOTICS',
  NONE = 'NONE',
  OTHER = 'OTHER',
}

export type FallRisk = {
  fallRiskId: UUID
  patientId: UUID
  fallInLastTwelveMonths: Nullable<boolean>
  unsteadyWalkingOrStanding: Nullable<boolean>
  fearOfFalling: Nullable<boolean>
  usesDmeAssistForAmbulation: Nullable<boolean>
  hasPeripheralNeuropathy: Nullable<boolean>
  anticoagulation: Anticoagulation[]
  otherAnticoagulation: Nullable<string>
  sedatingMedications: SedatingMedications[]
  otherSedatingMedications: Nullable<string>
  additionalContext: Nullable<string>
} & Timestamps

// PATIENT TREATMENTS ----------------

export type OncologySystematicTherapyReference = {
  oncologySystematicTherapyRefId: UUID
  agent: string
  brandNames: string[]
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
} & Timestamps

export type MapOncDxRefOncTherapyRef = {
  oncologyDiagnosisRefId: UUID
  oncologySystematicTherapyRefId: UUID
  mapOncDxRefOncTherapyRefId: UUID
  oncologyDiagnosesReference: OncologyDiagnosisReference
  oncologySystematicTherapiesReference: OncologySystematicTherapyReference
}

export type Treatment = {
  patientTreatmentId: UUID
  otherTreatmentType: string | null
  oncologySystematicTherapyRefId: UUID
  cancerDiagnosisId: UUID
  treatmentEndDate: string | null
  treatmentStartDate: string | null
  cancerDiagnosis: CancerDiagnosis
  oncologySystematicTherapyReference: OncologySystematicTherapyReference
} & Timestamps

export type Radiation = {
  patientRadiationId: UUID
  cancerDiagnosisId: UUID
  radiationDate: string
  cancerDiagnosis: CancerDiagnosis
  additionalInformation: Nullable<string>
} & Timestamps

export type SurgicalProcedureReference = {
  surgicalProcedureRefId: UUID
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
} & Timestamps

export type MapOncDxRefSurgicalProcedureRef = {
  oncologyDiagnosisRefId: UUID
  surgicalProcedureRefId: UUID
  mapOncDxRefSurgicalProcedureRefId: UUID
  oncologyDiagnosesReference: OncologyDiagnosisReference
  surgicalProceduresReference: SurgicalProcedureReference
}

export type Surgery = {
  surgicalProcedureRefId: UUID
  otherSurgeryType: string
  patientSurgeryId: UUID
  cancerDiagnosisId: UUID
  surgeryDate: string
  cancerDiagnosis: CancerDiagnosis
  surgicalProcedureReference: SurgicalProcedureReference
} & Timestamps

export interface TreatmentDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<Treatment>
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

export interface SurgeryDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<Surgery>
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

export interface RadiationDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<Radiation>
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

// PATIENT TOCS ----------------

export enum DischargeLocation {
  HOME = 'HOME',
  LTACH = 'LTACH',
  ACUTE_REHAB = 'ACUTE_REHAB',
  SUBACUTE_REHAB = 'SUBACUTE_REHAB',
  HALFWAY_HOUSE = 'HALFWAY_HOUSE',
  ASSISTED_LIVING = 'ASSISTED_LIVING',
  LONG_TERM_CARE = 'LONG_TERM_CARE',
  SKILLED_NURSING = 'SKILLED_NURSING',
  HOSPICE = 'HOSPICE',
}

export enum ReadmissionRisk {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum EventType {
  ED_DISCHARGE = 'ED_DISCHARGE',
  INPATIENT_DISCHARGE = 'INPATIENT_DISCHARGE',
  POST_ACUTE_CARE_DISCHARGE = 'POST_ACUTE_CARE_DISCHARGE',
}

export enum ServicesOnDischarge {
  SKILLED_NURSING = 'SKILLED_NURSING',
  WOUND_CARE = 'WOUND_CARE',
  PT_OT = 'PT_OT',
  SPEECH_SWALLOW_THERAPY = 'SPEECH_SWALLOW_THERAPY',
  HOME_HEALTH_AIDE = 'HOME_HEALTH_AIDE',
  DIALYSIS = 'DIALYSIS',
}

export type CarePlanTransitionsOfCare = {
  transitionsOfCareId: UUID
  patientId: UUID
  admissionDate: Date
  dischargeDate: Nullable<Date>
  eventType: EventType
  dischargeDiagnosis: Nullable<string>
  dischargeLocation: Nullable<DischargeLocation>
  isReadmissionRisk: Nullable<ReadmissionRisk>
  hasNewDmeOnDischarge: Nullable<boolean>
  servicesOnDischarge: ServicesOnDischarge[]
  moreThanThreeAdmissionsInLastSixMonths: Nullable<boolean>
  summaryOfEvents: Nullable<string>
  hasElectiveAdmission: Nullable<boolean>
} & Timestamps

// MEDICAL TEAM ----------------
export type MedicalTeam = {
  patientMedTeamId: UUID
  patientId: UUID
  lastOncologyAppt: string | null
  nextOncologyAppt: string | null
  oncologistName: string
  surgeonName: string
  radiationOncologistName: string
  palliativeMedicineName: string
  primaryCareName: string
  additionalContext: Nullable<string>
} & Timestamps
