import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'

import {
  useCarePlanTransitionsOfCareApi,
  usePhysicalFunctionAssessmentApi,
  useMedicalTeamApi,
  useMapMedicalAttrsMedicalConditionsApi,
  useMedicalConditionsWithAttrsApi,
  useMedicalConditionsWithoutAttrsApi,
  useFallRiskApi,
  useSurgeryApi,
  useRadiationApi,
  useTreatmentApi,
} from '@/pages/PatientProfile/CarePlans/shared/store'

import {
  Radiation,
  Surgery,
  Treatment,
  MedicalCondition,
  FallRisk,
  CarePlanTransitionsOfCare,
  PatientClinicalSummary,
  PhysicalFunctionAssessment,
  MedicalTeam,
  MapMedicalAttrsMedicalConditions,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import { MEDICAL_CONDITIONS_PAGE_LENGTH } from '../ClinicalSummary/MedicalConditions/shared/types'
import {
  useMedicalConditionsCarePlanApi,
  usePatientClinicalSummaryApi,
} from './store'

/**
 * Retrieve the attribute mappings for provided medical condition ref ids.
 * Returns null if no data is returned.
 * @param medicalConditionRefIds
 * @param medicalConditionAttributeIds
 * @param parts
 */
export async function getMapMedicalAttrsMedicalConditions(
  medicalConditionRefIds: string[] = [],
  medicalConditionAttributeIds: string[] = [],
  parts: string[] = []
): Promise<MapMedicalAttrsMedicalConditions[] | null> {
  const mapMedicalAttrsMedicalConditionsApi =
    useMapMedicalAttrsMedicalConditionsApi()
  const mappingsRes = await mapMedicalAttrsMedicalConditionsApi.list({
    params: {
      filter_med_condition_ref_ids: medicalConditionRefIds,
      filter_med_condition_attr_ref_ids: medicalConditionAttributeIds,
      page_length: MEDICAL_CONDITIONS_PAGE_LENGTH,
      parts,
    },
  })
  if (!mappingsRes?.data?.length) {
    return null
  }

  if (mapMedicalAttrsMedicalConditionsApi.error) {
    const error = mapMedicalAttrsMedicalConditionsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching medical conditions <> medical attributes mappings`,
      type: NotificationType.WARNING,
      error: `Error fetching medical conditions <> medical attributes mappings: ${error}`,
    })
  }
  return mappingsRes.data
}

/**
 * Retrieve the patient medical conditions for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 * @param getConditionsWithAttrs
 */
export async function getPatientMedicalConditions(
  patientId: string,
  getConditionsWithAttrs: boolean | null = false
): Promise<MedicalCondition[] | null> {
  const medicalConditionsApi = getConditionsWithAttrs
    ? useMedicalConditionsWithAttrsApi()
    : useMedicalConditionsWithoutAttrsApi()
  const medicalConditionsRes = await medicalConditionsApi.list({
    params: {
      filter_patient_ids: [patientId],
      ...(getConditionsWithAttrs
        ? { filter_has_condition_specific_attributes: true }
        : getConditionsWithAttrs === false
        ? { filter_has_condition_specific_attributes: false }
        : {}),
      parts: ['medical_condition_reference'],
      filter_is_archived: false,
    },
  })
  if (!medicalConditionsRes?.data?.length) {
    return null
  }

  if (medicalConditionsApi.error) {
    const error = medicalConditionsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient medical conditions`,
      type: NotificationType.WARNING,
      error: `Error fetching patient medical conditions: ${error}`,
    })
  }
  return medicalConditionsRes.data
}

/**
 * Retrieve the patient medical team for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientMedicalTeam(
  patientId: string
): Promise<MedicalTeam | null> {
  const medicalTeamApi = useMedicalTeamApi()
  const medicalTeamRes = await medicalTeamApi.list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
  if (!medicalTeamRes?.data?.length) {
    return null
  }

  if (medicalTeamApi.error) {
    const error = medicalTeamApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient medical team`,
      type: NotificationType.WARNING,
      error: `Error fetching patient medical team: ${error}`,
    })
  }
  return medicalTeamRes.data[0]
}

/**
 * Retrieve patient physical function assessment for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPhysicalFunctionAssessment(
  patientId: string
): Promise<PhysicalFunctionAssessment | null> {
  const physicalFunctionAssessmentApi = usePhysicalFunctionAssessmentApi()
  const pfaResponse = await physicalFunctionAssessmentApi.list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
  if (!pfaResponse?.data?.length) {
    return null
  }

  if (physicalFunctionAssessmentApi.error) {
    const error = physicalFunctionAssessmentApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient physical function assessment`,
      type: NotificationType.WARNING,
      error: `Error fetching patient physical function assessment: ${error}`,
    })
  }
  return pfaResponse.data[0]
}

/**
 * Retrieve patient physical function assessment for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientClinicalSummary(
  patientId: string
): Promise<PatientClinicalSummary | null> {
  const patientClinicalSummaryApi = usePatientClinicalSummaryApi()
  const clinicalSummary = await patientClinicalSummaryApi.retrieve({
    ids: [patientId],
    metaOptions: { bubbleErrorThrow: true },
  })
  if (!clinicalSummary) {
    return null
  }

  if (patientClinicalSummaryApi.error) {
    const error = patientClinicalSummaryApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient clinical summary`,
      type: NotificationType.WARNING,
      error: `Error fetching patient clinical summary: ${error}`,
    })
  }
  return clinicalSummary
}

/**
 * Retrieve the care plan transitions of care for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getMostRecentTransitionOfCare(
  patientId: string
): Promise<CarePlanTransitionsOfCare | null> {
  const carePlanTransitionsOfCareApi = useCarePlanTransitionsOfCareApi()
  const carePlanTransitionsOfCareResponse =
    await carePlanTransitionsOfCareApi.list({
      params: {
        filter_patient_ids: [patientId],
        sort_by: 'admissionDate,desc',
        page_length: 1,
      },
    })
  if (!carePlanTransitionsOfCareResponse?.data?.length) {
    return null
  }

  if (carePlanTransitionsOfCareApi.error) {
    const error = carePlanTransitionsOfCareApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching care plan transitions of care',
      type: NotificationType.DANGER,
      error: `Error fetching care plan transitions of care: ${error}`,
    })
  }
  return carePlanTransitionsOfCareResponse.data[0]
}

/**
 * Retrieve the patient fall risk for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientFallRisk(
  patientId: string
): Promise<FallRisk | null> {
  const fallRiskApi = useFallRiskApi()
  const fallRiskResponse = await fallRiskApi.list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
  if (!fallRiskResponse?.data?.length) {
    return null
  }

  if (fallRiskApi.error) {
    const error = fallRiskApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient fall risk`,
      type: NotificationType.WARNING,
      error: `Error fetching patient fall risk: ${error}`,
    })
  }
  return fallRiskResponse.data[0]
}

/**
 * Retrieve cancer treatments for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param pageLength
 * @param pageNumber
 */
export async function getPatientTreatmentWithParts(
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
): Promise<Treatment | null> {
  const treatmentApi = useTreatmentApi()
  const response = await treatmentApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      parts: ['cancer_diagnosis', 'oncology_systematic_therapy_reference'],
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (treatmentApi.error) {
    const error = treatmentApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient treatment`,
      type: NotificationType.WARNING,
      error: `Error fetching patient treatment: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve surgery for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param pageLength
 * @param pageNumber
 */
export async function getPatientSurgeryWithParts(
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
): Promise<Surgery | null> {
  const surgeryApi = useSurgeryApi()
  const response = await surgeryApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      parts: ['cancer_diagnosis', 'surgical_procedure_reference'],
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (surgeryApi.error) {
    const error = surgeryApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient surgery`,
      type: NotificationType.WARNING,
      error: `Error fetching patient surgery: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve radiation for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param pageLength
 * @param pageNumber
 */
export async function getPatientRadiationWithParts(
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
): Promise<Radiation | null> {
  const radiationApi = useRadiationApi()
  const response = await radiationApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      parts: ['cancer_diagnosis'],
      sort_by: 'radiationDate,desc',
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (radiationApi.error) {
    const error = radiationApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient radiation`,
      type: NotificationType.WARNING,
      error: `Error fetching patient radiation: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve sensitive medical conditions for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getSensitiveMedicalConditions(
  patientId: string
): Promise<MedicalCondition[] | null> {
  let medicalConditionsRes
  try {
    const medicalConditionsApi = useMedicalConditionsCarePlanApi()
    medicalConditionsRes = await medicalConditionsApi.list({
      params: {
        filter_patient_ids: [patientId],
        parts: ['medical_condition_reference'],
        filter_is_archived: false,
        filter_is_sensitive: true,
        page_length: 1, //we need to only check if patient has atleast 1 sensitive medical condition
      },
    })
    if (!medicalConditionsRes?.data?.length) {
      return null
    } else {
      return medicalConditionsRes.data
    }
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error fetching medical conditions for Care Plan view',
      type: NotificationType.WARNING,
      error: `Error fetching medical conditions for Care Plan view: ${err}`,
    })
    return null
  }
}
