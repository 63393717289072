<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    title="Edit Social Summary"
    :size="ModalSize.LG"
    class="text-nash-neutral900"
    @close="close"
  >
    <div v-if="isOpen" class="mb-5 overflow-scroll">
      <div class="flex flex-col space-y-3">
        <label>Transportation Barrier</label>
        <TDropdown
          v-model="dirtyModel['transportationBarrier']"
          class="bg-nash-neutral000"
          name="social-summary-transportation-barrier"
          :multiple="true"
          :options="transportationBarrierOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.transportationBarrier"
        />

        <label>Food Barrier</label>
        <TDropdown
          v-model="dirtyModel['foodBarrier']"
          class="bg-nash-neutral000"
          name="social-summary-food-barrier"
          :multiple="true"
          :options="foodBarrierOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.foodBarrier"
        />

        <label>Housing Barrier</label>
        <TDropdown
          v-model="dirtyModel['housingBarrier']"
          class="bg-nash-neutral000"
          name="social-summary-housing-barrier"
          :multiple="true"
          :options="housingBarrierOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.housingBarrier"
        />

        <label>Financial Barrier</label>
        <TDropdown
          v-model="dirtyModel['financialBarrier']"
          class="bg-nash-neutral000"
          name="social-summary-financial-barrier"
          :multiple="true"
          :options="financialBarrierOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.financialBarrier"
        />

        <label>Social Support</label>
        <TDropdown
          v-model="dirtyModel['socialSupport']"
          class="bg-nash-neutral000"
          name="social-summary-social-support"
          :multiple="true"
          :options="socialSupportOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.socialSupport"
        />

        <label>In-Home Support</label>
        <TDropdown
          v-model="dirtyModel['inHomeSupport']"
          class="bg-nash-neutral000"
          name="social-summary-in-home-support"
          :multiple="true"
          :options="inHomeSupportOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.inHomeSupport"
        />

        <label>Caregiver Mapping</label>
        <TDropdown
          v-model="dirtyModel['caregiverMapping']"
          class="bg-nash-neutral000"
          name="social-summary-caregiver-mapping"
          :multiple="true"
          :options="caregiverMappingOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.caregiverMapping"
        />

        <label>Benefits, Programs, and Services</label>
        <TDropdown
          v-model="dirtyModel['benefitsProgramsServices']"
          class="bg-nash-neutral000"
          name="social-summary-benefits-programs-services"
          :multiple="true"
          :options="benefitsProgramsServicesOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.benefitsProgramsServices"
        />

        <label>Healthcare Proxy / Healthcare POA</label>
        <TDropdown
          v-model="dirtyModel['healthcareProxy']"
          class="bg-nash-neutral000"
          name="social-summary-healthcare-proxy-poa"
          :options="healthcareProxyOptions"
          option-label="label"
          option-value="value"
          @update:model-value="dirtyModel.healthcareProxy"
        />

        <label>Additional Context</label>
        <TTextarea
          v-model="dirtyModel.additionalContext"
          name="social-summary-additional-context"
          @update:model-value="dirtyModel.additionalContext"
        />
      </div>
    </div>

    <template #actions>
      <div class="w-full flex flex-row justify-end space-x-4">
        <TMSecondaryButton label="Cancel" name="cancel" @click="close" />
        <TMPrimaryButton label="Save" name="save" @click="save" />
      </div>
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { PropType, defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'

import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { SocialSummary } from '@/legacy/types/patients/socialSummaries'
import setup from './controller'

export default defineComponent({
  components: {
    TModal,
    TMPrimaryButton,
    TMSecondaryButton,
    TDropdown,
    TTextarea,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    patientSocialSummary: {
      type: Object as PropType<SocialSummary | null>,
      required: true,
    },
    patientId: {
      type: String as PropType<string | null>,
      required: true,
    },
  },
  emits: ['close', 'refetchSocialSummary'],
  setup,
})
</script>
