import { requiredInject } from '@thyme/libs/src/vue/inject'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { addECOGStatusLevel } from '@/pages/PatientProfile/CarePlans/shared/format'
import { getPhysicalFunctionAssessment } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { usePhysicalFunctionAssessmentApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  ADLsInability,
  IADLsInability,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import { arrayToStringDisplay } from '@/pages/PatientProfile/CarePlans/shared/utils'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { EditPhysicalFunctionAssessmentForm } from './EditPhysicalFunctionAssessment/types'

// Defined only for convenience and use in testing.
export type PhysicalFunctionAssessmentViewModel = {
  patientId: Ref<string>
  physicalFunctionAssessmentId: Ref<string | undefined>
  ecogStatusDisplay: Ref<string>
  unableToPerformTheFollowingIADLsDisplay: Ref<string>
  unableToPerformTheFollowingADLsDisplay: Ref<string>
  lastUpdatedDisplay: Ref<string>
  homeboundDisplay: Ref<string>
  dmeDisplay: Ref<string>
  proceduralSupportDisplay: Ref<string>
  walksTwoBlocksOrOneFlightOfStairsDisplay: Ref<string>
  additionalInformationDisplay: Ref<string | null>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditPhysicalFunctionAssessmentForm>>
  arrayToStringDisplay: (arr: string[]) => string
  togglePhysicalFunctionAssessmentEdit: () => void
  refetchPhysicalFunctionAssessment: () => Promise<void>
}

/**
 *
 *
 */
export function setup(): PhysicalFunctionAssessmentViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { data: physicalFunctionAssesmentData } = storeToRefs(
    usePhysicalFunctionAssessmentApi()
  )

  const physicalFunctionAssessment = computed(
    () => physicalFunctionAssesmentData.value?.[0] ?? null
  )
  const physicalFunctionAssessmentId = computed(
    () => physicalFunctionAssessment.value?.physicalFunctionAssessmentId
  )

  const ecogStatusDisplay = computed(() => {
    const ecogStatus = physicalFunctionAssessment.value?.ecogStatus ?? null
    return ecogStatus ? addECOGStatusLevel(ecogStatus) : '-'
  })

  const unableToPerformTheFollowingIADLsDisplay = computed(() => {
    const followingIADLs: IADLsInability[] | string[] =
      physicalFunctionAssessment.value?.iadlsInability ?? []
    if (followingIADLs && followingIADLs.length) {
      const formatted = followingIADLs.map((val) => {
        if (val === IADLsInability.UNKNOWN_HAS_NOT_BEEN_ASSESSED) {
          return 'Unknown, has not been assessed'
        }
        if (val === IADLsInability.NOT_APPLICABLE_INDEPENDENT) {
          return 'Not Applicable, Independent'
        }
        return startCase(camelCase(val))
      })

      return arrayToStringDisplay(formatted, true)
    }
    return '-'
  })

  const unableToPerformTheFollowingADLsDisplay = computed(() => {
    const followingADLs: ADLsInability[] | string[] =
      physicalFunctionAssessment.value?.adlsInability ?? []
    if (followingADLs && followingADLs.length) {
      const formatted = followingADLs.map((val) => {
        if (val === IADLsInability.UNKNOWN_HAS_NOT_BEEN_ASSESSED) {
          return 'Unknown, has not been assessed'
        }
        if (val === IADLsInability.NOT_APPLICABLE_INDEPENDENT) {
          return 'Not Applicable, Independent'
        }
        return startCase(camelCase(val))
      })

      return arrayToStringDisplay(formatted, true)
    }
    return '-'
  })

  const homeboundDisplay = computed(() => {
    const homebound =
      physicalFunctionAssessment.value?.unableToLeaveHomeOtherThanMedicalAppts
    if (homebound === undefined || homebound === null) {
      return '-'
    }
    return homebound ? 'Yes' : 'No'
  })

  const dmeDisplay = computed(() => {
    const dme = physicalFunctionAssessment.value?.dmeAtHome ?? null
    if (dme && dme.length) {
      return arrayToStringDisplay(dme)
    }
    return '-'
  })

  const proceduralSupportDisplay = computed(() => {
    const proceduralSupport =
      physicalFunctionAssessment.value?.proceduralSupport ?? null
    if (proceduralSupport && proceduralSupport.length) {
      return arrayToStringDisplay(proceduralSupport)
    }
    return '-'
  })

  const walksTwoBlocksOrOneFlightOfStairsDisplay = computed(() => {
    const walksTwoBlocksOrOneFlightOfStairs =
      physicalFunctionAssessment.value?.unableToWalkTwoBlocksOrAFlightOfStairs
    if (
      walksTwoBlocksOrOneFlightOfStairs === undefined ||
      walksTwoBlocksOrOneFlightOfStairs === null
    ) {
      return '-'
    }
    return walksTwoBlocksOrOneFlightOfStairs ? 'Yes' : 'No'
  })

  const additionalInformationDisplay = computed(() => {
    return physicalFunctionAssessment.value?.additionalContext ?? null
  })

  const lastUpdatedDisplay = computed(() => {
    const lastUpdatedAtStr = formatLastUpdatedStr(
      physicalFunctionAssessment.value
    )
    return lastUpdatedAtStr ?? '-'
  })

  const editModalInitialData = computed(() => ({
    ecogStatus: physicalFunctionAssessment.value?.ecogStatus,
    iadlsInability: physicalFunctionAssessment.value?.iadlsInability,
    adlsInability: physicalFunctionAssessment.value?.adlsInability,
    unableToLeaveHomeOtherThanMedicalAppts:
      physicalFunctionAssessment.value?.unableToLeaveHomeOtherThanMedicalAppts,
    dmeAtHome: physicalFunctionAssessment.value?.dmeAtHome,
    proceduralSupport: physicalFunctionAssessment.value?.proceduralSupport,
    unableToWalkTwoBlocksOrAFlightOfStairs:
      physicalFunctionAssessment.value?.unableToWalkTwoBlocksOrAFlightOfStairs,
    additionalContext: physicalFunctionAssessment.value?.additionalContext,
  }))

  const refetchPhysicalFunctionAssessment = async () => {
    await getPhysicalFunctionAssessment(patientId.value)
  }

  /**
   * open/close edit modal
   */
  function togglePhysicalFunctionAssessmentEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(async () => {
    await getPhysicalFunctionAssessment(patientId.value)
  })

  return {
    patientId,
    physicalFunctionAssessmentId,
    // display values
    lastUpdatedDisplay,
    ecogStatusDisplay,
    unableToPerformTheFollowingIADLsDisplay,
    unableToPerformTheFollowingADLsDisplay,
    homeboundDisplay,
    dmeDisplay,
    proceduralSupportDisplay,
    walksTwoBlocksOrOneFlightOfStairsDisplay,
    additionalInformationDisplay,
    // physical function assessment edit modal
    togglePhysicalFunctionAssessmentEdit,
    refetchPhysicalFunctionAssessment,
    showEditModal,
    editModalInitialData,
    // format display
    arrayToStringDisplay,
  }
}
