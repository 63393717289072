<template>
  <TModal :is-visible="true" @close="close">
    Delete the following insurance from this patient? This action cannot be
    undone.
    <div class="mt-2 font-bold" data-testid="insurance-info-wrapper">
      Payer Name: {{ payerName }}
      <br />
      Network Tier: {{ networkTier }}
    </div>

    <template #actions>
      <TMQuaternaryButton name="cancel" label="Cancel" @click="close" />
      <TMPrimaryButton
        class="ml-4"
        name="delete-insurance"
        label="Delete"
        @click="submit"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { DELETE_INSURANCE_WARNING_PROPS } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMQuaternaryButton,
    TModal,
  },
  props: DELETE_INSURANCE_WARNING_PROPS,
  emits: ['close'],
  setup,
})
</script>
