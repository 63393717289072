<template>
  <div class="section-bottom-border whitespace-pre-wrap break-inside-avoid">
    <h4 class="py-2 print-h2">Social Summary</h4>
    <div v-if="isEditMode">
      <div class="case-conf-grid-3">
        <TMAutosaveInput
          label="Barriers/Needs"
          :data="summary?.barriersDescription"
          :disabled="!isEditMode"
          :api-call="
            (barriersDescription: string) =>
              save(
                CaseConferenceUpdates.barriersDescription,
                barriersDescription,
                CaseConferenceStrings.barriersDescription,
                barriersSaveState
              )
          "
          :save-state="barriersSaveState"
          :type="InputType.TEXTAREA"
        />
        <TMAutosaveInput
          label="Living Environment"
          :data="summary?.livingEnvDescription"
          :disabled="!isEditMode"
          :api-call="
            (livingEnvDescription: string) =>
              save(
                CaseConferenceUpdates.livingEnvDescription,
                livingEnvDescription,
                CaseConferenceStrings.livingEnvDescription,
                livingEnvSaveState
              )
          "
          :save-state="livingEnvSaveState"
          :type="InputType.TEXTAREA"
        />
        <TMAutosaveInput
          label="Support System"
          :data="summary?.supportDescription"
          :disabled="!isEditMode"
          :api-call="
            (supportDescription: string) =>
              save(
                CaseConferenceUpdates.supportDescription,
                supportDescription,
                CaseConferenceStrings.supportDescription,
                supportDescriptionSaveState
              )
          "
          :save-state="supportDescriptionSaveState"
          :type="InputType.TEXTAREA"
        />
      </div>
    </div>
    <div v-else class="case-conf-grid-3">
      <div>
        <div>
          <label>Barriers/Needs</label>
        </div>
        <div>
          {{ summary?.barriersDescription || 'N/A' }}
        </div>
      </div>
      <div>
        <div>
          <label>Living Environment</label>
        </div>
        <div>
          {{ summary?.livingEnvDescription || 'N/A' }}
        </div>
      </div>
      <div>
        <div>
          <label>Support System</label>
        </div>
        <div>
          {{ summary?.supportDescription || 'N/A' }}
        </div>
      </div>
    </div>
    <div v-if="isEditMode">
      <div class="case-conf-grid-1">
        <TMAutosaveInput
          label="Additional Social Context"
          :data="summary?.additionalSocialContext"
          :disabled="!isEditMode"
          :api-call="
            (additionalSocialContext: string) =>
              save(
                CaseConferenceUpdates.additionalSocialContext,
                additionalSocialContext,
                CaseConferenceStrings.additionalSocialContext,
                socialContextSaveState
              )
          "
          :save-state="socialContextSaveState"
          :type="InputType.TEXTAREA"
        />
      </div>
    </div>
    <div v-else class="case-conf-grid-1">
      <div>
        <div>
          <label>Additional Social Context</label>
        </div>
        <div>
          {{ summary?.additionalSocialContext || 'N/A' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { InputType } from '@thyme/nashville/src/types/inputs'
import { PropType, computed, defineComponent, ref } from 'vue'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import { SaveState, newSaveState } from '@/legacy/types/api/api'
import {
  CaseConferenceUpdates,
  CaseConferenceStrings,
  CarePlanSummary,
  CaseConferenceMode,
} from '@/legacy/types/patients/carePlanSummaries'

export default defineComponent({
  components: {
    TMAutosaveInput,
  },
  props: {
    mode: {
      type: String as PropType<CaseConferenceMode>,
      required: true,
    },
    carePlanSummary: {
      type: Object as PropType<CarePlanSummary | null>,
      default: null,
    },
  },
  emits: ['onSave'],
  setup(props, context) {
    const summary = computed(() => props.carePlanSummary)

    const barriersSaveState = ref<SaveState>(newSaveState())
    const livingEnvSaveState = ref<SaveState>(newSaveState())
    const supportDescriptionSaveState = ref<SaveState>(newSaveState())
    const socialContextSaveState = ref<SaveState>(newSaveState())
    const isEditMode = computed(() => props.mode === CaseConferenceMode.EDIT)

    const save = (
      keyName: string,
      updateString: string,
      fieldName: string,
      saveState: SaveState
    ) =>
      context.emit('onSave', {
        keyName,
        updateString,
        fieldName,
        saveState,
      })

    return {
      InputType,
      summary,
      CaseConferenceStrings,
      CaseConferenceUpdates,
      save,
      isEditMode,

      barriersSaveState,
      livingEnvSaveState,
      supportDescriptionSaveState,
      socialContextSaveState,
    }
  },
})
</script>
<style lang="scss">
.case-conf-grid-1 {
  @apply grid grid-cols-1 gap-4 text-nash-neutral800;
}
.case-conf-grid-3 {
  @apply grid grid-cols-3 gap-4 text-nash-neutral800 print:grid-cols-1;
}
</style>
