import { DateTime, Duration } from 'luxon'
import { storeToRefs } from 'pinia'
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import {
  useZoomDataApi,
  useZoomRecordingDataApi,
  useZoomTranscriptDataApi,
} from '@/legacy/store/modules/zoomData'
import { CallParts } from '@/legacy/types/communications/zoomData'

/**
 * get store vars for directly populating template
 */
export function getZoomStoreVars() {
  const { data: zoomRecordingData, isLoading: isLoadingRecording } =
    storeToRefs(useZoomRecordingDataApi())
  const { data: zoomTranscriptData, isLoading: isLoadingTranscript } =
    storeToRefs(useZoomTranscriptDataApi())

  return {
    zoomRecordingData,
    isLoadingRecording,
    zoomTranscriptData,
    isLoadingTranscript,
  }
}

/**
 * get zoom related functions and computed vars
 */
export function getZoomVars() {
  const { showZoomCallData } = storeToRefs(useFlagStore())

  const route = useRoute()
  const zoomApi = useZoomDataApi()
  const zoomRecordingDataApi = useZoomRecordingDataApi()
  const zoomTranscriptDataApi = useZoomTranscriptDataApi()

  const { data: zoomData } = storeToRefs(zoomApi)

  const hasFetchedRecording = ref(false)
  const hasFetchedTranscript = ref(false)

  const queryCommId = computed(() => `${route.query.commId ?? ''}`)

  /**
   * reload zoom data and hide recording + transcript
   */
  async function resetZoomData() {
    if (!queryCommId.value || !showZoomCallData.value) {
      // silently exit, should not call
      return
    }

    hasFetchedRecording.value = false
    hasFetchedTranscript.value = false
    await zoomApi.list({
      params: {
        filter_communication_ids: [queryCommId.value],
        parts: [CallParts.ZOOM_CALL_DATA],
      },
    })
  }

  /**
   * fetch Zoom call recording
   */
  async function getCallRecording() {
    if (zoomData.value && zoomData.value?.length > 0) {
      hasFetchedRecording.value = true
      const callId = zoomData.value[0].callId
      await zoomRecordingDataApi.list({ params: { call_id: callId } })
    }
  }

  /**
   * fetch Zoom call transcript
   */
  async function getCallTranscript() {
    if (zoomData.value && zoomData.value?.length > 0) {
      hasFetchedTranscript.value = true
      const callId = zoomData.value[0].callId
      await zoomTranscriptDataApi.list({
        params: { call_id: callId, parts: [CallParts.ZOOM_CALL_DATA] },
      })
    }
  }

  watch(queryCommId, resetZoomData)

  /**
   * onMount function
   */
  async function zoomMount() {
    await resetZoomData()
  }

  return {
    zoomData,
    showZoomCallData,
    hasFetchedRecording,
    hasFetchedTranscript,
    getCallRecording,
    getCallTranscript,
    zoomMount,
  }
}

/**
 *
 */
export function getUtilFuncs() {
  const formatStartTalkingDateTime = (
    recordingStartDatetimeStr?: string,
    timeSinceStartStr?: string
  ) => {
    if (!timeSinceStartStr || !recordingStartDatetimeStr) {
      return timeSinceStartStr ?? 'Time Unknown'
    }
    const recordingStartDatetime = DateTime.fromISO(recordingStartDatetimeStr)
    const timeSinceStartDuration = Duration.fromISOTime(
      timeSinceStartStr.split('.')[0]
    )

    return recordingStartDatetime
      .plus(timeSinceStartDuration)
      .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
  }

  const formatCallDuration = (callDurationSec?: number) => {
    if (!callDurationSec) {
      return 'Unknown'
    }
    const ms = 1000
    const callDuration1 = Duration.fromMillis(callDurationSec * ms).shiftTo(
      'hours',
      'minutes',
      'seconds'
    )
    return callDuration1.toFormat('hh:mm:ss')
  }

  return {
    formatStartTalkingDateTime,
    formatCallDuration,
  }
}
