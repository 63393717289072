import { createProp } from '@thyme/nashville/src/utils/designTokens'

// ENUMS --------------------
export enum TooltipDirection {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
}

export const directionProp = { direction: createProp(TooltipDirection) }
