<template>
  <div class="bg-nash-neutral000 p-5" data-cy="medical-container">
    <div class="mb-10 grid lg:grid-cols-2 gap-6 lg:gap-5">
      <PatientDiagnoses />
      <PatientComorbidities
        class="lg:border-l lg:pl-5 border-nash-neutral300"
      />
    </div>
    <h3>Medical Context</h3>
    <div
      v-if="Object.keys(tabMap).length > 1"
      class="flex flex-row gap-x-4 mb-3"
    >
      <div v-for="tab in Object.keys(tabMap)" :key="tab">
        <div
          class="tab cursor-pointer flex"
          :class="{ 'tab--active': tab === activeTab }"
          data-cy="medical-category"
          @click="updateActiveTab(tab)"
        >
          {{ tabName(tab) }}
        </div>
      </div>
    </div>
    <component :is="activeTabComponent" :patient-id="patient?.entityId" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PatientDiagnoses from '@/legacy/components/patient/medical/diagnoses/PatientDiagnoses.vue'
import PatientClaimsHistory from '@/legacy/components/patient/medical/PatientClaimsHistory/PatientClaimsHistory.vue'
import PatientComorbidities from '@/legacy/components/patient/medical/PatientComorbidities.vue'
import PatientMedicalProviders from '@/legacy/components/patient/medical/PatientMedicalProviders.vue'
import PatientTreatments from '@/legacy/components/patient/medical/PatientTreatments/PatientTreatments.vue'
import PatientAppointments from '@/legacy/components/patient/thymeline/appointments/PatientAppointments/PatientAppointments.vue'
import PatientTransitionsOfCareNotifications from '@/legacy/components/patient/transitionOfCare/PatientTransitionsOfCareNotifications/PatientTransitionsOfCareNotifications.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TSpinner,
    PatientAppointments,
    PatientMedicalProviders,
    PatientDiagnoses,
    PatientTreatments,
    PatientComorbidities,
    PatientClaimsHistory,
    PatientTransitionsOfCareNotifications,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.tab {
  @apply h-6 text-center;
  border-bottom: 2px solid transparent;
  &--active {
    @apply border-b-2 font-bold;
    border-bottom-color: #4a4a4a;
  }
  &:hover {
    background-color: rgba(#ccc, 0.2);
  }
}
</style>
