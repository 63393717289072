import { inject } from 'vue'
import type { InjectionKey } from 'vue'

/**
 *
 * @param key
 */
export function requiredInject<T>(key: InjectionKey<T> | string): T {
  const val = inject(key)
  if (!val) {
    const keyName = String(key.toString())
      .replace('Symbol', '')
      .replace(/[()]/g, '')
    throw new Error(`No ${keyName} found in app context`)
  }
  return val
}
