<template>
  <div>
    <TModal
      v-if="!showAddOrEditModal"
      :is-visible="true"
      :size="ModalSize.LG"
      title="Manage Surgery"
      @close="close"
    >
      <div>
        <div class="flex justify-between pb-2 items-center">
          <TTab
            :tabs="statuses"
            :active="selectedStatus"
            @select="selectStatus"
          />
          <div v-tooltip.left="addButtonDisabledMessage">
            <TMSecondaryButton
              label="+ Add Surgery"
              :disabled="addButtonDisabled"
              @click="toggleAddOrEditModal(null)"
            />
          </div>
        </div>
        <div class="space-y-4">
          <div
            v-for="surgery in surgeriesByStatus"
            :key="surgery.patientSurgeryId"
          >
            <div class="surg-container">
              <div>
                <h5>{{ surgery.surgeryDisplay }}</h5>
                <div class="text-nash-neutral800 text-base font-normal">
                  {{ surgery.surgeryDate }}
                </div>
              </div>
              <TOIconDropdown
                v-if="showEditOptionDropdown"
                name="surgery-edit-menu"
                icon="meatball"
                :options="editOptionsArray"
                @change="(v) => openModal(v.value, surgery.patientSurgeryId)"
              />
            </div>
          </div>
          <TMTertiaryButton
            v-if="showMoreCurrentSurgeriesButton"
            label="Show More"
            class="pt-2 mb-3"
            icon-pos="right"
            :icon="'chevronDown'"
            @click="loadMore"
          />
        </div>
      </div>
      <template #actions>
        <TMQuaternaryButton
          label="Cancel"
          name="manage-surgery-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="manage-surgery-save"
          label="Save"
          @click="onSave"
        />
      </template>
    </TModal>
    <AddOrEditSurgery
      v-if="showAddOrEditModal"
      :cancer-diagnosis-id="cancerDxId"
      :show-add="!editSurgeryId"
      :surgery-id="editSurgeryId ?? ''"
      :initial-data="editModalInitialData"
      @close="toggleAddOrEditModal"
      @refetch="refetchCurrentSurgeries"
    />
    <DeleteSurgery
      v-if="showDeleteModal && deleteSurgeryId"
      :surgery-id="deleteSurgeryId"
      @close="toggleDeleteModal"
      @refetch="refetchCurrentSurgeries"
    />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TTab from '@nashville/tab/TTab.vue'
import { defineComponent } from 'vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import AddOrEditSurgery from './AddOrEditSurgery/AddOrEditSurgery.vue'
import { setup } from './controller'
import DeleteSurgery from './DeleteSurgery/DeleteSurgery.vue'
import { manageSurgeriesProps } from './types'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    TMTertiaryButton,
    TMPrimaryButton,
    TModal,
    TTab,
    TMSecondaryButton,
    TOIconDropdown,
    AddOrEditSurgery,
    DeleteSurgery,
  },
  props: manageSurgeriesProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.surg-container {
  @apply flex justify-between border border-nash-neutral400 p-4 rounded-lg;
}
</style>
