import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  Radiation,
  Surgery,
  Treatment,
} from '@/pages/PatientProfile/CarePlans/shared/types'

export const useLastUpdatedRadiationApi = apiStore<Radiation>(
  'lastUpdatedRadiationApi',
  '/api/care_plan/patient_radiations',
  {
    transformData: (d: { data: Radiation[] }) => d,
  }
)

export const useLastUpdatedSurgeryApi = apiStore<Surgery>(
  'lastUpdatedSurgeryApi',
  '/api/care_plan/patient_surgeries',
  {
    transformData: (d: { data: Surgery[] }) => d,
  }
)

export const useLastUpdatedTreatmentApi = apiStore<Treatment>(
  'lastUpdatedTreatmentApi',
  '/api/care_plan/patient_treatments',
  {
    transformData: (d: { data: Treatment[] }) => d,
  }
)
