<template>
  <!-- @vue-ignore Submit generics not compatible with specifics -->
  <VForm :initial-values="initialValues" @submit="submit">
    <button
      v-if="data"
      class="flex w-full flex-row-reverse font-bold text-nash-purple500"
      name="edit-patient-email"
    >
      Save
    </button>
    <TInputText
      :model-value="initialValues.email"
      label="Email address"
      name="email"
      rules="required|email"
    />
    <div v-if="!data" class="mt-2 flex flex-row-reverse">
      <button class="p-button p-component mt-2" name="save-patient-email">
        Save
      </button>
    </div>
  </VForm>
</template>
<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { ApiError } from '@thyme/libs/src/api/apiRequest'
import { HTTPStatusCode } from '@thyme/libs/src/api/types/statusCodes'
import { storeToRefs } from 'pinia'
import { Form as VForm } from 'vee-validate'
import { computed, defineComponent, PropType } from 'vue'

import { useEntitiesApi } from '@/legacy/store/modules/entity'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { ChangeVerb } from '@/legacy/types/global/strings'
import { NotificationType } from '@/legacy/types/notifications'
import { PatientEmailForm } from '@/legacy/types/patients/patients'

export default defineComponent({
  components: {
    TInputText,
    VForm,
  },
  props: {
    data: {
      type: Object as PropType<any>,
      default: null,
    },
  },
  emits: ['exitCreateOrEdit'],
  setup(props, context) {
    const { entity } = storeToRefs(usePatientStore())

    const initialValues = computed(() => ({
      email: props.data ? props.data.email : '',
    }))

    /**
     * Function to submit create or edit email
     * @param email_change
     */
    async function submit(email_change: PatientEmailForm) {
      if (!entity.value) {
        return
      }
      const verb: ChangeVerb = { present: '', past: '' }
      if (!props.data) {
        verb.present = 'add'
        verb.past = 'added'
      } else if (!email_change['email']) {
        verb.present = 'remove'
        verb.past = 'removed'
      } else {
        verb.present = 'update'
        verb.past = 'updated'
      }

      try {
        const entityUpdateData = await useEntitiesApi().partialUpdate({
          ids: [entity.value.entityId],
          body: { ...email_change },
        })

        const email = entityUpdateData.email
        usePatientStore().updateEmailAddress(email)
      } catch (err) {
        const msg =
          err instanceof ApiError &&
          err.status === HTTPStatusCode.ERROR_409_CONFLICT
            ? `Failed to ${verb.present}, email address already in use.`
            : `Failed to ${verb.present} email address.`
        useNotificationStore().setNotification({
          message: msg,
          type: NotificationType.DANGER,
        })
        return
      }
      useNotificationStore().setNotification({
        message: `Successfully ${verb.past} email address.`,
        type: NotificationType.SUCCESS,
      })
      context.emit('exitCreateOrEdit')
    }

    return {
      initialValues,
      submit,
    }
  },
})
</script>
