import { provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { PATIENT_ID_KEY } from './shared/types'

/**
 * Provides the patient ID to the component tree via injection.
 * Watches the route for updates.
 *
 * Not intended for use outside the PatientProfile. This should
 * be called in the setup function of the PatientProfile component.
 */
export function usePatientIdProvide() {
  const route = useRoute()
  const patientId = ref(route.params.patientId as string)
  provide(PATIENT_ID_KEY, patientId)

  watch(
    () => route.params.patientId,
    () => {
      patientId.value = route.params.patientId as string
    }
  )

  return { patientId }
}
