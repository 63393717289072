import { DateTime } from 'luxon'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useTreatmentApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { SurgicalProcedureReference } from '@/pages/PatientProfile/CarePlans/shared/types'
import { useMapOncDxRefOncTherapyRefApi } from './store'
import { AddOrEditTreatmentForm } from './types'

/**
 *
 * @param payloads
 */
export async function createTreatments(payloads: AddOrEditTreatmentForm[]) {
  const treatmentPromises: Promise<void>[] = []
  payloads.forEach((payload) =>
    treatmentPromises.push(
      useTreatmentApi().create({
        body: {
          ...payload,
          treatmentStartDate: payload.treatmentStartDate
            ? DateTime.fromJSDate(payload.treatmentStartDate).toISODate()
            : null,
          treatmentEndDate: payload.treatmentEndDate
            ? DateTime.fromJSDate(payload.treatmentEndDate).toISODate()
            : null,
        },
        metaOptions: { bubbleErrorThrow: true },
      })
    )
  )
  try {
    await Promise.all(treatmentPromises)
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to add treatment(s).',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully added treatment(s).',
    type: NotificationType.SUCCESS,
  })
}

/**
 *
 * @param treatmentId
 * @param dirtyData
 */
export async function updateTreatment(
  treatmentId: string,
  dirtyData: AddOrEditTreatmentForm
) {
  const treatmentApi = useTreatmentApi()
  let upserted
  const body = {
    ...dirtyData,
    ...{
      ...(dirtyData.treatmentStartDate
        ? {
            treatmentStartDate: DateTime.fromJSDate(
              dirtyData.treatmentStartDate
            ).toISODate(),
          }
        : {}),
      ...(dirtyData.treatmentEndDate
        ? {
            treatmentEndDate: DateTime.fromJSDate(
              dirtyData.treatmentEndDate
            ).toISODate(),
          }
        : {}),
    },
  }
  try {
    upserted = await treatmentApi.partialUpdate({
      ids: [treatmentId],
      body: body,
      metaOptions: { bubbleErrorThrow: true },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update treatment.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated treatment.',
    type: NotificationType.SUCCESS,
  })

  const treatmentData = treatmentApi.data
  if (
    treatmentId?.length &&
    treatmentData?.[treatmentId as keyof typeof treatmentData]
  ) {
    treatmentData[treatmentId] = upserted
  }

  return upserted
}

/**
 * Retrieve oncology systematic therapy options mapped to current cancer
 * Returns null if no data is returned.
 * @param oncDxRefId
 */
export async function getMapOncologySystematicTherapyReferences(
  oncDxRefId: string
): Promise<SurgicalProcedureReference[] | null> {
  const mapOncTherapyApi = useMapOncDxRefOncTherapyRefApi()
  const response = await mapOncTherapyApi.list({
    params: {
      page_length: 50,
      parts: ['oncology_systematic_therapies_reference'],
      filter_oncology_diagnosis_ref_ids: [oncDxRefId],
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (mapOncTherapyApi.error) {
    const error = mapOncTherapyApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching oncology systematic therapy references',
      type: NotificationType.WARNING,
      error: `Error fetching oncology systematic therapy references: ${error}`,
    })
  }
  return response.data
}
