<template>
  <!--
    For security reason, markdown-it html rendering should be **false**.
    See https://github.com/markdown-it/markdown-it/blob/master/docs/security.md
  -->
  <VueMarkdown v-if="source" :html="false" :source="source" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VueMarkdown from 'vue-markdown-render'

export default defineComponent({
  components: {
    VueMarkdown,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
</script>
