import { RescheduleReattemptSavePayload } from '@/legacy/components/patient/communicationV2/shared/types'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  Communication,
  BaseCommunicationPayload,
  SCHEDULED_CALL_TYPE,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'
import { createCommunication, updateCommunication } from './communications'
import { createPlannedCall, updatePlannedCall } from './plannedCalls'
import { linkSubtasks } from './subtaskMaps'

/**
 *
 * Function to update communication and planned/scheduled call
 * when reattempt+rescheduling from modal from planned/scheduled call.
 * @param communication
 * @param payloads
 */
export async function updateCommunicationAndPlannedCall(
  communication: Communication | null,
  payloads: RescheduleReattemptSavePayload
) {
  if (!communication) {
    useNotificationStore().setNotification({
      message: "Missing communication. Can't process update for rescheduling.",
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    await Promise.all([
      updateCommunication(
        communication.communicationId,
        payloads.dirtyCommunication as BaseCommunicationPayload
      ),
      updatePlannedCall(
        communication.communicationId,
        payloads.dirtyPlannedCall
      ),
    ])
  } catch (error) {
    useNotificationStore().setNotification({
      message: `Error updating communication and planned call for rescheduling: ${error}, ${communication.communicationId}`,
      type: NotificationType.DANGER,
    })
  }
}

/**
 *
 * Function to create communication and planned/scheduled call
 * when reattempt+rescheduling from modal from a call disposition.
 * Also ensures any linked subtasks from previous call disposition comm
 * is also linked to the newly created scheduled call comm.
 *
 * Returns created communications and linked subtask ids in an object.
 * @param payloads
 */
export async function createCommunicationAndPlannedCall(
  payloads: RescheduleReattemptSavePayload
) {
  const createdComm = await createCommunication(
    SCHEDULED_CALL_TYPE,
    payloads.dirtyCommunication as BaseCommunicationPayload
  )

  if (!createdComm) {
    useNotificationStore().setNotification({
      message: `Communication for ${SCHEDULED_CALL_TYPE.type} not created`,
      type: NotificationType.DANGER,
    })
    return { communication: null, linkedSubtaskIds: payloads.linkedSubtaskIds }
  }

  const subtaskIdsLinked = await linkSubtasks(createdComm, [], {
    newIds: payloads.linkedSubtaskIds,
  })
  const linkedSubtaskIds: string[] = subtaskIdsLinked.length
    ? subtaskIdsLinked
    : payloads.linkedSubtaskIds
  const createdCommId = createdComm.communicationId

  let createdCommSubtype = null
  createdCommSubtype = await createPlannedCall(
    createdCommId,
    payloads.dirtyPlannedCall
  )
  if (!createdCommSubtype) {
    useNotificationStore().setNotification({
      message: `Communication Subtype ${SCHEDULED_CALL_TYPE.subtype} not created`,
      type: NotificationType.DANGER,
    })
  } else {
    useCommunicationsStore().communicationType = SCHEDULED_CALL_TYPE
  }

  return { communication: createdCommId, linkedSubtaskIds }
}
