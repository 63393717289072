import { TreatmentField } from './types'

/**
 *
 * @param treatmentType
 */
export function getDisabledButtonStr(treatmentType: TreatmentField) {
  const pluralTreatmentStr =
    treatmentType === TreatmentField.SURGERY
      ? 'surgeries'
      : `${treatmentType.toLowerCase()}s`
  return `Unable to add ${pluralTreatmentStr} to a previous cancer diagnosis`
}
