import { ModalSize } from '@thyme/nashville/src/types/modals'
import debounce from 'lodash/debounce'
import remove from 'lodash/remove'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes, onBeforeMount, ref, watch } from 'vue'
import {
  useMapMedicalAttrsMedicalConditionsApi,
  useMedicalConditionAttrsRefApi,
} from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  MapMedicalAttrsMedicalConditions,
  MedicalConditionAttributeReference,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import { addMedicalConditions, getMedicalConditionRefs } from './queries'
import { useMedicalConditionsRefApi } from './store'
import { addMedicalConditionsProps, MedicalConditionPayload } from './types'

type PropsType = ExtractPropTypes<typeof addMedicalConditionsProps>
const DEBOUNCE_INTERVAL_MS = 200

/**
 * Set up the AddMedicalConditions component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: medicalConditionRefs, isLoading: isLoadingRefs } = storeToRefs(
    useMedicalConditionsRefApi()
  )
  const { data: mappings } = storeToRefs(
    useMapMedicalAttrsMedicalConditionsApi()
  )
  const { data: medicalConditionAttrRefs } = storeToRefs(
    useMedicalConditionAttrsRefApi()
  )

  const medicalConditionsToAdd = ref<MedicalConditionPayload[]>([])
  const currentSearch = ref('')

  const medicalConditionRefsDisplay = computed(() => {
    return medicalConditionRefs.value
      ?.map((medicalCondition) => ({
        ...medicalCondition,
        attributeOptions: getAttributeOptions(
          medicalCondition.medicalConditionRefId
        ),
        patientId: props.patientId,
      }))
      .sort((a, b) => {
        return a.description.localeCompare(b.description)
      })
  })

  const incompleteOtherMedicalConditionToAdd = computed(() => {
    return medicalConditionsToAdd.value.find(
      (medicalCondition) =>
        medicalCondition.medicalConditionRefId === null &&
        medicalCondition.otherMedicalCondition === null
    )
  })

  const disableSave = computed(() => {
    return isLoadingRefs.value || !!incompleteOtherMedicalConditionToAdd.value
  })

  /**
   *
   * @param medicalConditionRefId
   */
  function getAttributeOptions(medicalConditionRefId: string | null) {
    if (!medicalConditionRefId) {
      return null
    }
    const mappingsForCondition = mappings.value?.filter(
      (maps: MapMedicalAttrsMedicalConditions) =>
        maps.medicalConditionRefId === medicalConditionRefId
    )
    const mappedAttrIds = mappingsForCondition?.map(
      (map: MapMedicalAttrsMedicalConditions) => map.medicalConditionAttributeId
    )
    const attrRefsForSelectedCondition = medicalConditionAttrRefs.value?.filter(
      (attr: MedicalConditionAttributeReference) =>
        mappedAttrIds?.includes(attr.medicalConditionAttributeId)
    )

    if (!attrRefsForSelectedCondition?.length) {
      return null
    }
    return attrRefsForSelectedCondition
      ?.map((attr: MedicalConditionAttributeReference) => {
        return {
          value: attr.medicalConditionAttributeId,
          label: attr.description,
        }
      })
      .sort((a: any) => a.label.localeCompare)
  }

  /**
   *
   * @param medicalConditionToAdd
   */
  function addMedicalCondition(medicalConditionToAdd: MedicalConditionPayload) {
    medicalConditionsToAdd.value.push(medicalConditionToAdd)
  }

  /**
   *
   * @param medicalConditionRefId
   */
  function removeMedicalCondition(medicalConditionRefId: string) {
    remove(
      medicalConditionsToAdd.value,
      (v) => v.medicalConditionRefId === medicalConditionRefId
    )
  }

  /**
   *
   * @param medicalConditionToAdd
   */
  function setMedicalConditionPayload(
    medicalConditionToAdd: MedicalConditionPayload
  ) {
    const match = medicalConditionsToAdd.value.find(
      ({ medicalConditionRefId }: MedicalConditionPayload) =>
        medicalConditionRefId === medicalConditionToAdd.medicalConditionRefId
    )
    if (match) {
      const index = medicalConditionsToAdd.value.indexOf(match)
      if (index !== -1) {
        medicalConditionsToAdd.value[index] = medicalConditionToAdd
      }
    } else {
      medicalConditionsToAdd.value.push(medicalConditionToAdd)
    }
  }

  /**
   *
   */
  function close() {
    reset()
    context.emit('close')
  }

  /**
   *
   */
  function reset() {
    updateSearch()
    medicalConditionsToAdd.value = []
  }

  /**
   *
   */
  async function onSubmit() {
    if (medicalConditionsToAdd.value.length) {
      await addMedicalConditions(medicalConditionsToAdd.value)
      context.emit('refetch')
    }
    close()
  }

  /**
   *
   * @param freeTextSearch
   */
  async function getMedicalConditionRefsWithExcludeIds(
    freeTextSearch?: string
  ) {
    await getMedicalConditionRefs(props.excludeIds, freeTextSearch)
  }

  /**
   *
   * @param newSearch
   */
  function updateSearch(newSearch?: string) {
    currentSearch.value = newSearch ?? ''
  }

  const debouncedGetAllMedicalConditions = debounce(
    getMedicalConditionRefsWithExcludeIds,
    DEBOUNCE_INTERVAL_MS
  )

  watch(currentSearch, async (freeTextSearch: string) => {
    useMedicalConditionsRefApi().reset()
    await debouncedGetAllMedicalConditions(freeTextSearch)
  })

  onBeforeMount(getMedicalConditionRefsWithExcludeIds)

  return {
    // display
    disableSave,
    medicalConditionsToAdd,
    currentSearch,
    // loading
    isLoadingRefs,
    // data
    medicalConditionRefsDisplay,
    // actions
    setMedicalConditionPayload,
    addMedicalCondition,
    removeMedicalCondition,
    onSubmit,
    updateSearch,
    // modal
    close,
    ModalSize,
  }
}
