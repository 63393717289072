<template>
  <div class="items-center flex space-x-1 text-nash-neutral700 text-xs min-h-6">
    <template v-if="state.isSaving">
      <TSpinner name="save-indicator" />
      <span>Saving...</span>
    </template>
    <template v-else-if="state.error">
      <TIcon icon="heroicons:x-circle-20-solid" color="nash-brick600" />
      <span>Unable to save changes</span>
    </template>
    <template v-else-if="state.lastCallAt">
      <span>
        Last saved
        {{ DateTime.fromJSDate(state.lastCallAt).toRelativeCalendar() }} at
        {{ DateTime.fromJSDate(state.lastCallAt).toFormat('h:mm a') }}
      </span>
    </template>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { DateTime } from 'luxon'
import { PropType, defineComponent } from 'vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'

import { SaveState } from '@/legacy/types/api/api'

export default defineComponent({
  components: {
    TIcon,
    TSpinner,
  },
  props: {
    state: {
      type: Object as PropType<SaveState>,
      required: true,
    },
  },
  setup() {
    return { DateTime }
  },
})
</script>
