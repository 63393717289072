import { Timestamps } from '@/legacy/types/global/dates'

// Enums
export enum DocumentStatus {
  ARCHIVED = 'ARCHIVED',
  UPLOADED = 'UPLOADED',
  UPLOAD_REQUESTED = 'UPLOAD_REQUESTED',
}
// TYPES --------------------
export type DocumentHistoryType = {
  activityType: string
  oldValue: string
  newValue: string
  documentId: string
  documentHistoryId: string
} & Timestamps

export type DocumentCategory = {
  documentCategoryId: string
  name: string
  displayName: string
} & Timestamps

export type Document = {
  status: string
  archivedAt: string
  archivedReasonNote: string | undefined
  documentCategoryId: string
  documentCategory?: DocumentCategory
  documentId: string
  name: string
  patientId: string
  s3ObjectKey: string
} & Timestamps

export type DocumentDownload = {
  action: string
  documentId: string
  url: string
}
