<template>
  <div v-if="section && section[0].parent_text">
    <b>{{ title }}</b>
    <div v-if="isSingular" class="whitespace-pre-wrap">
      {{ section[0].parent_text }}
    </div>
    <ul v-else class="pl-5">
      <li v-for="parent in section" :key="parent.parent_text">
        {{ parent.parent_text }}
        <ul v-if="parent.child_text && parent.child_text.length > 0">
          <li v-for="child in parent.child_text" :key="child">{{ child }}</li>
        </ul>
      </li>
    </ul>
    <br />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { VisitNoteParent } from '@/legacy/types/patients/visitNotes'

export default defineComponent({
  props: {
    section: { type: Array as PropType<VisitNoteParent[]>, default: () => [] },
    title: { type: String, required: true },
    isSingular: { type: Boolean, default: false },
  },
})
</script>

<style lang="scss">
ul {
  list-style: initial;
}
</style>
