<template>
  <div>
    <div v-tooltip.bottom="'Test VPN'" @click="callTestVpn()">
      <div class="cursor-pointer flex text-nash-neutral000">
        <TIcon name="wrench" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'
import { apiUrl, Timeout } from '@/legacy/libs/api'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'

// CONST
const TIMEOUT_SECS = 5

export default defineComponent({
  components: {
    TIcon,
  },
  setup() {
    const { notification } = storeToRefs(useNotificationStore())
    const callTestVpn = async () => {
      try {
        await fetch(apiUrl('/healthz'), {
          signal: Timeout(TIMEOUT_SECS).signal,
        })
        useNotificationStore().setNotification({
          message: 'VPN test succeeded!',
          type: NotificationType.SUCCESS,
        })
      } catch (e) {
        useNotificationStore().setNotification({
          message: 'VPN or ThymeBox failed to connect.',
          type: NotificationType.DANGER,
        })
      }
    }

    return {
      notification,
      callTestVpn,
    }
  },
})
</script>
