<template>
  <div id="home">
    <Transition>
      <TNotification v-if="notification" />
    </Transition>
    <div class="card">
      <div class="card-content">
        <div class="content columns">
          <AdminTools v-if="hasAdminPrivileges" class="column is-one-fifth" />
          <UserClaims class="column" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'
import AdminTools from '@/legacy/components/AdminTools.vue'
import UserClaims from '@/legacy/components/UserClaims.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useProfileStore } from '@/legacy/store/modules/profile'

export default defineComponent({
  components: {
    AdminTools,
    UserClaims,
    TNotification,
  },
  setup() {
    const { hasAdminPrivileges } = storeToRefs(useProfileStore())
    const { notification } = storeToRefs(useNotificationStore())

    return {
      hasAdminPrivileges,
      notification,
    }
  },
})
</script>
