<template>
  <TModal
    :is-visible="true"
    :size="ModalSize.LG"
    :title="modalTitle"
    @close="close"
  >
    <template #preTitle>
      <TIcon class="mr-2 cursor-pointer" icon="chevronLeft" @click="close" />
    </template>
    <div v-for="(field, index) in fields" :key="field.key">
      <div
        v-if="!field.value.removed"
        class="flex w-full justify-between space-x-2"
      >
        <div class="w-1/2 mt-2">
          <div v-if="showSurgeryDropdown">
            <TMDropdownField
              :name="`surgeryPayload[${index}].surgicalProcedureRefId`"
              class="w-full mb-2"
              option-label="label"
              option-value="value"
              label="Surgery Type"
              :options="surgeryOptions"
              :model-value="field.value.surgicalProcedureRefId ?? undefined"
              @update:model-value="setSurgeryField($event, index)"
            />
          </div>
          <TInputText
            v-if="!showSurgeryDropdown || field.value.otherSurgeryType != null"
            id="other-surgery-type-input"
            class="mb-3"
            label="Other Surgery"
            :name="`surgeryPayload[${index}].otherSurgeryType`"
            :model-value="field.value.otherSurgeryType"
          />
        </div>
        <div class="w-1/2">
          <TCalendar
            :name="`surgeryPayload[${index}].surgeryDate`"
            :disable-holidays="false"
            label="Surgery Date"
            class="w-full"
            :min-date="null"
            :model-value="field.value.surgeryDate ?? undefined"
          />
        </div>
        <div>
          <TMSecondaryButton
            v-if="showAdd"
            class="mt-5 w-1/5"
            icon="close"
            @click="removeSurgeryForm(index)"
          />
        </div>
      </div>
    </div>

    <TMTertiaryButton
      v-if="showAdd"
      label="Add Surgery"
      class="pt-2 mb-3"
      icon="add"
      @click="addSurgeryForm"
    />
    <template #actions>
      <TMSecondaryButton
        label="Cancel"
        name="surgery-add-or-edit-cancel"
        @click="close"
      />
      <TMPrimaryButton
        v-if="showAdd"
        name="surgery-add"
        label="Save"
        @click="onAddSubmit"
      />
      <TMPrimaryButton
        v-if="!showAdd"
        name="surgery-edit"
        label="Save"
        @click="onEditSubmit"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { addOrEditSurgeryProps } from './types'

export default defineComponent({
  components: {
    TIcon,
    TCalendar,
    TMTertiaryButton,
    TModal,
    TMSecondaryButton,
    TMPrimaryButton,
    TInputText,
    TMDropdownField,
  },
  props: addOrEditSurgeryProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
