import { EntityRole } from '../entities/entities'
import { QueueStyle, SubtaskPriority } from '../pathways/subtasks'

export type VariantKey = {
  variantDisplay: string
  variantKey: string
}

export type TemplateBaseForm = {
  title: string
  variantSet: string | null
} & BaseKeys

export type TemplateBasePayload = {
  title: string
  variantSet: VariantKey[]
} & BaseKeys

export enum PathwayAdminType {
  PATHWAY = 'playbook',
  TASK = 'task',
  SUBTASK = 'subtask',
}

export enum PathwayTemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum MemberFacingTheme {
  THYME = 'THYME',
  AON = 'AON',
}

export type BasicTemplateBase = {
  baseTemplateId: string
  title: string
  variants: VariantKey[]
} & BaseKeys

export type BaseKeys = {
  subtaskKey?: string
  pathwayKey?: string
  taskKey?: string
}

export type SubtaskTemplateVariantForm = {
  description: string
  responsibleRole: EntityRole | string | null
  estimatedMinutesToComplete: number | null
  hyperlinks: string
  priority: SubtaskPriority | string | null
  outreachMessage?: string | null
  memberFacingTheme: MemberFacingTheme | null
  variantKey: string
  variantDisplay: string
  queueStyle: QueueStyle
  isMemberFacing?: boolean
  isManuallyAddable: boolean
} & SubtaskTemplateVariantFormLabels

export type SubtaskTemplateVariantFormLabels = {
  formFlowLabel: string
  staffFormVariantLabel: string
  memberFormVariantLabel: string
}

export type SubtaskTemplateVariantPayload = {
  description?: string
  responsibleRole: EntityRole | string
  estimatedMinutesToComplete?: number
  hyperlinks: string | string[]
  priority: SubtaskPriority | string
  outreachMessage?: string | null
  memberFacingTheme?: MemberFacingTheme | null
  queueStyle: QueueStyle
  isMemberFacing?: boolean
  isManuallyAddable: boolean
} & SubtaskTemplateVariantFormLabels

export type MapTasksSubtasks = {
  mappingTemplateId: string
  taskVariantId: string
  subtaskVariantId: string
}

export type TaskSubtaskDependency = {
  dependencyTemplateId: string
  taskVariantId: string
  mappingTemplateId: string
  dependsOnMappingTemplateId?: string | null
  minDaysToStart?: number | null
  maxDaysToComplete?: number | null
}

export type MapPathwaysTasks = {
  mappingTemplateId: string
  taskVariantId: string
  pathwayVariantId: string
}

export type PathwayTaskDependency = {
  dependencyTemplateId: string
  pathwayVariantId: string
  mappingTemplateId: string
  dependsOnMappingTemplateId?: string | null
  minDaysToStart?: number | null
  maxDaysToComplete?: number | null
}
