<template>
  <TTable
    id="oncology-tx"
    ref="table"
    name="OncologyTreatments"
    class="pb-12 px-0"
    sort-field="lastClaimDate"
    :sort-order="SORT_DESCENDING"
    :rows="5"
    :columns="columns"
    :api-store="useOncologyTreatmentApi"
    :params="{ filter_patient_ids: patientId }"
    :resizable-columns="true"
    @data-loaded="fetchOncReferences"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import setup from './controller'

export default defineComponent({
  components: { TTable },
  setup,
})
</script>
