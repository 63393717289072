import { debounce } from 'lodash'
import { StoreGeneric, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const DEFAULT_SCROLL_PAGE_LENGTH = 10
const DEBOUNCE_INTERVAL_MS = 600

/**
 * Check if data should be grabbed
 * NOTE: This function is called VERY frequently
 * due to the way PrimeVue handles event emitting
 * @param isLoading
 * @param amountFetched
 * @param totalItems
 * @param lastVisible
 * @returns boolean
 */
export function shouldGetData(
  isLoading: boolean,
  amountFetched: number,
  totalItems: number,
  lastVisible: number
) {
  if (isLoading) {
    return false
  }
  if (amountFetched >= totalItems) {
    return false
  }
  if (lastVisible < amountFetched) {
    return false
  }
  return true
}

/**
 * Generate PrimeVue options object for lazy loaded dropdowns
 * @param apiBuilderStore ApiBuilder store using TransformListAppend transform
 * @param itemHeight px height of dropdown items
 * @param paginationLength size of page (default 10)
 * @param freeTextFilterKey api key to send freeText filter through
 * @returns Options object
 */
export function getVirtualScrollOptions(
  apiBuilderStore: () => StoreGeneric,
  itemHeight: number,
  paginationLength: number = DEFAULT_SCROLL_PAGE_LENGTH,
  freeTextFilterKey = 'filter_free_text'
) {
  const { computedData, isLoading, queryMetadata } = storeToRefs(
    apiBuilderStore()
  )

  const filterText = ref<string | null>(null)

  /**
   * Fetch data from apiBuilder store
   * STORE MUST USE TransformListAppend TRANSFORM!!!
   * Called by PrimeVue on lazyLoad event emit
   * NOTE: This function is called VERY frequently
   * make sure to block dupe calls whenever possible
   * @param param0 Indexes of first and last item loaded into DOM
   * (straight from PrimeVue)
   * @returns void
   */
  async function getData({ last }: { [key: string]: number }) {
    const amountFetched = computedData.value?.length ?? 0
    const totalItems = queryMetadata.value?.total ?? paginationLength

    if (!shouldGetData(isLoading.value, amountFetched, totalItems, last)) {
      return
    }

    let itemDifference = last - amountFetched
    const lastPage = Math.ceil(totalItems / paginationLength)
    let nextPage = Math.ceil(amountFetched / paginationLength) + 1

    while (itemDifference > 0 && nextPage <= lastPage) {
      await apiBuilderStore().list({
        params: {
          page_length: paginationLength,
          page_number: nextPage,
          ...(filterText.value && freeTextFilterKey
            ? { [freeTextFilterKey]: filterText.value }
            : {}),
        },
      })
      nextPage += 1
      itemDifference -= paginationLength
    }
  }

  const debouncedFilterData = debounce(() => {
    apiBuilderStore().$patch({
      // @ts-ignore StoreGeneric used for $patch, can't combine with ApiStore type
      data: null,
      isLoaded: false,
      queryMetadata: null,
    })
    void getData({ last: paginationLength })
  }, DEBOUNCE_INTERVAL_MS)

  /**
   * attach to filter event emission from TMFilterDropdown
   * re-populates dropdown with filtered items
   * @param root0
   * @param root0.originalEvent original DOM event
   * @param root0.value filter value specified
   */
  function onFilter({ value }: { originalEvent: Event; value: string }) {
    filterText.value = value
    debouncedFilterData()
  }

  /**
   * Retrieve object/label for modelValue on dropdown
   * @param modelValue
   * @param force
   */
  function getModelLabel(modelValue: string, force = false) {
    if (
      !force &&
      apiBuilderStore().idMap &&
      apiBuilderStore().idMap[modelValue]
    ) {
      return
    }
    void apiBuilderStore().retrieve({
      ids: [modelValue],
    })
  }

  return computed(() => ({
    lazy: true,
    onLazyLoad: getData,
    itemSize: itemHeight,
    loading: isLoading.value,
    delay: 250,
    filter: onFilter,
    filterValue: filterText.value,
    getModelLabel,
  }))
}
