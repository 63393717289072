<template>
  <div>
    <div
      class="section-header flex is-justify-content-space-between items-center"
    >
      <h3 id="oncology-dx" data-cy="diagnoses-header" class="my-2 pb-1">
        Oncology Diagnoses
      </h3>
      <div v-tooltip.left="'Edit Diagnoses'">
        <TMSecondaryButton
          v-if="canEditPatientDiagnoses"
          name="edit-diagnoses"
          size="xs"
          icon="pencil"
          @click="isModalOpen = true"
        />
      </div>
    </div>
    <div
      v-if="sortedPatientDxObjs && Object.keys(sortedPatientDxObjs).length"
      class="w-full"
    >
      <div>
        <div
          v-for="[cancerCategory, patientDxList] of Object.entries(
            mapByCancerCategory
          )"
          :key="cancerCategory"
        >
          <div v-for="dx in patientDxList" :key="dx.diagnosisId">
            <div class="items-start columns">
              <div class="space-x-2 column">
                <slot :item="dx"></slot>
                <div class="flex w-full cursor-default font-medium">
                  {{ cancerCategory }}
                </div>
                <div class="flex w-full ml-0 text-sm">
                  {{ dxListDetails(dx) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>None documented</div>

    <ManageDiagnoses
      :is-visible="isModalOpen"
      @close="isModalOpen = false"
      @refresh="getData"
      @mark-accuracy="markAccuracy"
      @save="saveDiagnoses"
    />
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import values from 'lodash/values'

import { storeToRefs } from 'pinia'
import { computed, defineComponent, onBeforeMount, ref, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import { sqlDateToFormattedDate } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import { safeLookup } from '@/legacy/libs/lookup'
import { compareNullableStrings } from '@/legacy/libs/string'

import {
  useDiagnosesRefStore,
  useDiagnosisRefApi,
} from '@/legacy/store/modules/diagnosesReferences'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  useDiagnosesApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import type { IdMap } from '@/legacy/types/api/store'
import { NotificationType } from '@/legacy/types/notifications'

import {
  Stage,
  Diagnosis,
  DxCodeType,
  DiagnosisUI,
} from '@/legacy/types/patients/diagnoses'
import ManageDiagnoses from './ManageDiagnoses.vue'

export default defineComponent({
  components: {
    TMSecondaryButton,
    ManageDiagnoses,
  },
  setup() {
    const isModalOpen = ref(false)
    const editingId = ref<string | null>(null)
    const deletingId = ref<string | keyof Diagnosis | null>(null)
    const creating = ref<boolean>(false)

    const { canEditPatientDiagnoses } = storeToRefs(useProfileStore())
    const { data: diagnosisCatalog } = storeToRefs(useDiagnosisRefApi())
    const { data: diagnoses } = storeToRefs(useDiagnosesApi())
    const route = useRoute()

    const stageChoices = Object.entries(Stage).map((x) => [x[1], x[0]])

    onBeforeMount(async () => {
      await usePatientStore().fetchDiagnoses(route.params.patientId as string)
      if (diagnoses.value) {
        const dxRefIds = Object.values(diagnoses.value).map(
          (dx: Diagnosis) => dx.diagnosisRefId
        )
        if (dxRefIds.length) {
          void useDiagnosesRefStore().fetchAllDiagnosisRefs(dxRefIds)
        }
      }
    })

    /**
     *
     * @param stageEnum
     */
    function readableStage(stageEnum: string) {
      return lookupEnum(Stage, stageEnum)
    }

    /**
     *
     * @param diagnosisRefId
     */
    function readableDiagnosisRef(diagnosisRefId: string) {
      const dxCatalog = values(diagnosisCatalog.value).length
        ? diagnosisCatalog.value
        : {}
      const ref = safeLookup(diagnosisRefId, dxCatalog)
      if (ref) {
        if (ref.code) {
          /* eslint-disable @typescript-eslint/restrict-plus-operands */
          return ref.code + ': ' + ref.description
          /* eslint-enable @typescript-eslint/restrict-plus-operands */
        }
        return ref.description
      }
      return 'Diagnosis'
    }

    const fetchAllDx = async () => {
      void useDiagnosisRefApi().listAll({})
    }

    const diagnosisOptions = computed(() => {
      if (diagnosisCatalog.value) {
        return Object.entries(diagnosisCatalog.value)
          .filter((v) => v[1].codeType === DxCodeType.THYMECARE_CATEGORY)
          .map((v) => v[0]) // ids
          .map((id) => [id, readableDiagnosisRef(id)])
          .sort((a, b) => a[1].localeCompare(b[1]))
      }
      return []
    })

    const splitCategoryFromDescription = (description: string) => ({
      cancerCategory: description.split(':')[0],
      description: description.split(':')[1] ?? description.split(':')[0],
    })

    const getFirstDxDateString = (dxList: Diagnosis[]) => {
      const oldest = Array.from(dxList).sort((a, b) =>
        compareNullableStrings(a.diagnosisDate, b.diagnosisDate, false)
      )[0]
      return `First diagnosed on ${sqlDateToFormattedDate(
        oldest.diagnosisDate
      )}`
    }

    const sortedPatientDxObjs = computed(() => {
      return Object.values(diagnoses.value ?? {})
        .filter((diagnosis) => !diagnosis.isInaccurate)
        .sort(
          (a, b) =>
            (a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1) ||
            // Sort dates in descending order (most recent first), with dateless
            // items last.
            -compareNullableStrings(a.diagnosisDate, b.diagnosisDate, false)
        )
    })

    const isCodeTypeIcd10 = (dxList: DiagnosisUI[]) =>
      dxList[0].codeType === DxCodeType.ICD_10

    const mapByCancerCategory = computed(() => {
      if (diagnosisCatalog.value) {
        return sortedPatientDxObjs.value.reduce(
          (obj: IdMap<Diagnosis[]>, dx: Diagnosis) => {
            const dxCatalogItem = (diagnosisCatalog.value ?? {})[
              dx.diagnosisRefId
            ]
            if (dxCatalogItem) {
              const { description, codeType } = dxCatalogItem
              Object.assign(obj, {
                [`${description}`]: [toRaw({ ...dx, codeType, description })],
              })
            }
            return obj
          },
          {}
        )
      }
      return []
    })

    const getData = async () => {
      void usePatientStore().fetchPatient(route.params.patientId as string)
      void usePatientStore().fetchDiagnoses(route.params.patientId as string)
    }
    const markAccuracy = async (diagnosis: Diagnosis) => {
      try {
        await useDiagnosesApi().partialUpdate({
          body: diagnosis,
          ids: [diagnosis.diagnosisId],
        })
        useNotificationStore().setNotification({
          message: 'Diagnosis updated successfully',
          type: NotificationType.SUCCESS,
        })
      } catch (e) {
        useNotificationStore().setNotification({
          message: 'Error marking accuracy on diagnosis',
          type: NotificationType.DANGER,
        })
      }
      void getData()
    }

    /**
     *
     * @param dx
     */
    function dxListDetails(dx: Diagnosis) {
      const details = []
      if (dx.isPrimary !== null) {
        details.push(dx.isPrimary ? 'Primary' : 'Secondary')
      }
      if (dx.stage) {
        details.push(`Stage ${readableStage(dx.stage)}`)
      }
      if (dx.diagnosisDate) {
        details.push(`Diagnosed on ${sqlDateToFormattedDate(dx.diagnosisDate)}`)
      }
      return details.join(' · ')
    }

    const saveDiagnoses = async (addedDiagnosisInfo: string[]) => {
      const promises: Promise<Diagnosis>[] = []
      try {
        addedDiagnosisInfo.forEach((dx: any) => {
          promises.push(
            usePatientStore().createDiagnosis({
              patientId: route.params.patientId as string,
              ...dx,
            })
          )
        })
        await Promise.all(promises)
        void getData()
        useNotificationStore().setNotification({
          message: 'Diagnoses added successfully',
          type: NotificationType.SUCCESS,
        })
      } catch (e) {
        useNotificationStore().setNotification({
          message: 'Error adding diagnoses',
          type: NotificationType.DANGER,
        })
      }
    }

    return {
      creating,
      deletingId,
      diagnoses,
      diagnosisCatalog,
      diagnosisOptions,
      dxListDetails,
      editingId,
      getFirstDxDateString,
      isCodeTypeIcd10,
      mapByCancerCategory,
      readableDiagnosisRef,
      splitCategoryFromDescription,
      stageChoices,
      fetchAllDx,
      canEditPatientDiagnoses,
      isModalOpen,
      sortedPatientDxObjs,
      getData,
      markAccuracy,
      saveDiagnoses,
    }
  },
})
</script>
