import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'

import { Communication } from '@/legacy/types/communications/communications'
import { Goal } from '@/legacy/types/pathways/goals'
import { MapPathwayTask, Pathway } from '@/legacy/types/pathways/pathways'
import { Task } from '@/legacy/types/pathways/tasks'

/**
 * THESE API STORES ARE MADE FOR THE NAVIGATION REPORT ONLY
 * Don't reuse them please
 */

export const useCommunicationApi = apiStore<Communication>(
  'NRCommunications',
  '/api/communications',
  {
    transformData: (d: { data: Communication[] }) => d,
  }
)

export const useTaskApi = apiStore<Task>('NRTasks', '/api/tasks', {
  transformData: (d: { data: Task[] }) => d,
})

export const useGoalApi = apiStore<Goal>('NRGoals', '/api/goals', {
  transformData: (d: { data: any[] }) => d,
})

export const usePathwayApi = apiStore<Pathway, IdMap<Pathway>>(
  'NRPathways',
  '/api/pathways',
  {
    transformData: (d: { data: Pathway[] }) => {
      const converted = d.data.map((pathway) => ({
        ...pathway,
        taskIds: pathway.taskMaps?.map(
          (taskMap: MapPathwayTask) => taskMap.taskId
        ),
      }))
      return idMapTransform({}, 'data', 'pathwayId', converted)
    },
  }
)
