import { ModalSize } from '@thyme/nashville/src/types/modals'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes, onBeforeMount, Ref, ref } from 'vue'
import { formatAttributesDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/shared/format'
import { MedicalConditionDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/shared/types'
import {
  getPatientClinicalSummary,
  getSensitiveMedicalConditions,
} from '@/pages/PatientProfile/CarePlans/shared/queries'
import {
  useMedicalConditionAttrsRefApi,
  useMedicalConditionsWithoutAttrsApi,
} from '@/pages/PatientProfile/CarePlans/shared/store'
import { getArchivedPatientMedicalConditions } from './queries'
import {
  manageMedicalConditionsProps,
  ManageMedicalConditionDisplay,
} from './shared/types'
import { useArchivedMedicalConditionsApi } from './store'

type PropsType = ExtractPropTypes<typeof manageMedicalConditionsProps>

export type ManageMedicalConditionsViewModel = {
  archivedMedicalConditionsDisplay: Ref<ManageMedicalConditionDisplay[]>
  orderedMedicalConditionsDisplay: Ref<ManageMedicalConditionDisplay[]>
  archivedMedicalConditionsLabelDisplay: Ref<string>
  updateAttrsMedicalCondition: Ref<ManageMedicalConditionDisplay | null>
  updateOtherMedicalCondition: Ref<ManageMedicalConditionDisplay | null>
  confirmArchiveMedicalCondition: Ref<ManageMedicalConditionDisplay | null>
  excludeIds: Ref<string[]>
  ModalSize: any
  showArchivedMedicalConditions: Ref<boolean>
  creating: Ref<boolean>
  close: () => void
  fetchArchivedMedicalConditions: () => Promise<void>
  refetch: () => Promise<void>
}

/**
 * Set up the ManageMedicalConditions component
 * @param props
 * @param context
 */
export function setup(
  props: PropsType,
  context: any
): ManageMedicalConditionsViewModel {
  const { data: medicalConditionAttrRefs } = storeToRefs(
    useMedicalConditionAttrsRefApi()
  )
  const { data: medicalConditionsWithoutAttrs } = storeToRefs(
    useMedicalConditionsWithoutAttrsApi()
  )
  const { data: archivedMedicalConditions } = storeToRefs(
    useArchivedMedicalConditionsApi()
  )

  const creating = ref(false)
  const confirmArchiveMedicalCondition =
    ref<ManageMedicalConditionDisplay | null>(null)
  const updateAttrsMedicalCondition = ref<ManageMedicalConditionDisplay | null>(
    null
  )
  const updateOtherMedicalCondition = ref<ManageMedicalConditionDisplay | null>(
    null
  )
  const showArchivedMedicalConditions = ref<boolean>(false)

  const archivedMedicalConditionsLabelDisplay = computed(() =>
    showArchivedMedicalConditions.value
      ? 'Hide Archived Medical Conditions'
      : 'Show Archived Medical Conditions'
  )

  const medicalConditionsWithAttrsDisplay = computed(() =>
    props.medicalConditionsWithAttrsDisplay.map(
      (medicalConditionDisplay: MedicalConditionDisplay) => ({
        ...medicalConditionDisplay,
        isEditing:
          updateAttrsMedicalCondition.value?.medicalConditionId ===
          medicalConditionDisplay.medicalConditionId,
      })
    )
  )

  const medicalConditionsWithoutAttrsDisplay = computed(() =>
    props.medicalConditionsWithoutAttrsDisplay.map(
      (medicalConditionDisplay: MedicalConditionDisplay) => {
        if (
          updateOtherMedicalCondition.value?.medicalConditionId ===
          medicalConditionDisplay.medicalConditionId
        ) {
          return {
            ...medicalConditionDisplay,
            isEditing: true,
          }
        }
        return medicalConditionDisplay
      }
    )
  )

  const orderedMedicalConditionsDisplay = computed(() =>
    [
      ...(medicalConditionsWithAttrsDisplay.value ?? []),
      ...(medicalConditionsWithoutAttrsDisplay.value ?? []),
    ].sort((a, b) => {
      return a.description.localeCompare(b.description)
    })
  )

  const archivedMedicalConditionsDisplay = computed(() => {
    if (!archivedMedicalConditions.value) {
      return []
    }

    return archivedMedicalConditions.value
      .map((medicalCondition) => ({
        attributesString: formatAttributesDisplay(
          medicalCondition.conditionSpecificAttributes,
          medicalConditionAttrRefs.value
        ),
        medicalConditionId: medicalCondition.medicalConditionId,
        isSensitive: medicalCondition.medicalConditionReference?.isSensitive,
        medicalConditionRefId: medicalCondition.medicalConditionRefId,
        description:
          medicalCondition.otherMedicalCondition ??
          medicalCondition.medicalConditionReference.description,
        conditionSpecificAttributes:
          medicalCondition.conditionSpecificAttributes,
        patientId: medicalCondition.patientId,
        isArchived: medicalCondition.isArchived,
      }))
      .sort((a, b) => {
        return a.description.localeCompare(b.description)
      })
  })

  const excludeIds = computed(() => {
    const nonArchivedIds = orderedMedicalConditionsDisplay.value.map(
      (m) => m.medicalConditionRefId
    )
    const archivedIds = archivedMedicalConditionsDisplay.value.map(
      (m) => m.medicalConditionRefId
    )
    return [...nonArchivedIds, ...archivedIds].filter((m) => !!m) as string[]
  })

  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   */
  async function refetch() {
    context.emit('refetch')
    context.emit('fetchMedicalConditionsWithoutAttrs')

    await getPatientClinicalSummary(props.patientId)
    await getArchivedPatientMedicalConditions(props.patientId)

    // this is to ensure isSensitive flag is updated in Care Plan section
    await getSensitiveMedicalConditions(props.patientId)
  }

  /**
   * fetch archived medical conditions
   */
  async function fetchArchivedMedicalConditions() {
    showArchivedMedicalConditions.value = !showArchivedMedicalConditions.value
    if (!archivedMedicalConditions.value) {
      await getArchivedPatientMedicalConditions(props.patientId)
    }
  }

  onBeforeMount(async () => {
    if (!medicalConditionsWithoutAttrs.value) {
      context.emit('fetchMedicalConditionsWithoutAttrs')
    }
  })

  return {
    // status
    creating,
    // display
    orderedMedicalConditionsDisplay,
    archivedMedicalConditionsLabelDisplay,
    archivedMedicalConditionsDisplay,
    showArchivedMedicalConditions,
    updateAttrsMedicalCondition,
    updateOtherMedicalCondition,
    excludeIds,
    // modal
    ModalSize,
    confirmArchiveMedicalCondition,
    // actions
    fetchArchivedMedicalConditions,
    close,
    refetch,
  }
}
