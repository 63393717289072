import { PropType } from 'vue'
import { Insurance } from '@/legacy/types/patients/insurances'

export type EditInsuranceModel = {
  payerName: string
} & Insurance

export const EDIT_INSURANCES_PROPS = {
  insurances: {
    type: Array as PropType<EditInsuranceModel[]>,
    default: undefined,
  },
} as const
