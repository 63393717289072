import { PropType } from 'vue'
import { MedicalConditionDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/shared/types'

export const manageMedicalConditionsProps = {
  patientId: {
    type: String,
    required: true,
  },
  medicalConditionsWithAttrsDisplay: {
    type: Array as PropType<MedicalConditionDisplay[]>,
    required: true,
  },
  medicalConditionsWithoutAttrsDisplay: {
    type: Array as PropType<MedicalConditionDisplay[]>,
    required: true,
  },
} as const

export type ManageMedicalConditionDisplay = {
  isEditing?: boolean
} & MedicalConditionDisplay
