<template>
  <div
    v-if="referralIds"
    class="bg-nash-neutral000 p-5 h-full overflow-y-auto relative"
  >
    <h1>Referral(s)</h1>
    <LegacyTButton
      name="close-visit-note-sidebar"
      icon="close"
      inline
      type="blackAndWhite"
      class="text-thyme-darkGray absolute top-5 right-4"
      @click="closeSidebar"
    />
    <TSpinner v-if="isLoading" name="referrals-sidebar" :partial-page="true" />
    <div v-else>
      <br />
      <div class="grid gap-y-6">
        <PatientReferral
          v-for="referral in referrals"
          :key="referral.id"
          :referral="referral"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PatientReferral from '@/legacy/components/patient/referral/PatientReferral.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatDateTime,
  formatDateTimeWithTime,
  formatName,
} from '@/legacy/libs/format'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'

import { useReferralsApi } from '@/legacy/store/modules/referrals'

export default defineComponent({
  components: { TSpinner, LegacyTButton, PatientReferral },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const referralIds = computed(() => route.query.referralIds)
    const { data: referrals, isLoading } = storeToRefs(useReferralsApi())
    /**
     * fetch referral data or clear them when the sidebar is closed
     */
    function fetchReferrals() {
      const ids = referralIds.value
      if (ids?.length) {
        void useReferralsApi().list({
          params: { referral_ids: ids },
        })
      }
    }

    onMounted(() => {
      fetchReferrals()
    })

    const closeSidebar = () => {
      void router.push({ query: { referralIds: undefined, reason: undefined } })
    }

    watch(referralIds, () => {
      fetchReferrals()
    })

    return {
      referralIds,
      isLoading,
      referrals,
      closeSidebar,
      formatDateTime,
      formatName,
      stringToDateTime,
      formatDateTimeWithTime,
      PatientReferral,
    }
  },
})
</script>
