import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatDateTimeWithTime,
  formatNameFromEntity,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useStaffApi } from '@/legacy/store/modules/staff'
import { useTriggersApi } from '@/legacy/store/modules/triggers'
import { StaffMap, SYSTEM_USER_UUID } from '@/legacy/types/entities/entities'
import {
  StatusReason,
  SubStatusReason,
  SubtaskStatuses,
  subtaskStatusMapping,
} from '@/legacy/types/pathways/pathways'
import { Subtask, SubtaskStatus } from '@/legacy/types/pathways/subtasks'

/**
 *
 * @param props
 * @param props.subtask
 */
export default function (props: { subtask: Subtask }) {
  const { data: staff } = storeToRefs(useStaffApi())
  const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
  const triggerApi = useTriggersApi()

  const statusReason = computed(() => props.subtask.statusReason)

  const creatingStaffName = computed(() =>
    formatNameFromEntity(
      safeLookup(props.subtask.createdBy, staff.value as StaffMap)
    )
  )

  const generatingTriggerId = computed(() => props.subtask.generatingTriggerId)

  const readableStatus = computed(
    () => SubtaskStatuses[props.subtask.status].text
  )

  /**
   *
   * @param status
   * @param reason
   * @param subStatus
   */
  function getStatusLabel(
    status: SubtaskStatus,
    reason: StatusReason,
    subStatus?: SubStatusReason
  ): string | undefined {
    const reasonMap = subtaskStatusMapping[status]
    const reasonValue = reasonMap[reason]
    if (typeof reasonValue === 'object' && subStatus) {
      return reasonValue[subStatus]
    } else if (typeof reasonValue === 'string') {
      return reasonValue
    }

    return undefined
  }

  const readableSubStatusReason = computed(() => {
    const status = props.subtask.status as SubtaskStatus
    const reason = statusReason.value as StatusReason
    const subReason = props.subtask.statusReasonJson
      ?.sub_status_reason as SubStatusReason
    const isBulkEdited = props.subtask.statusReasonJson?.is_bulk_edited
    const statusLabel = getStatusLabel(status, reason, subReason)
    let message
    if (
      reason !== StatusReason.MANUALLY_SET &&
      showSurfacedStatusReason.value &&
      statusLabel
    ) {
      message = `${statusLabel}`
    } else if (reason !== StatusReason.MANUALLY_SET) {
      message = `Set to ${readableStatus.value}`
    } else {
      message = ''
    }
    if (
      reason === StatusReason.MANUALLY_SET &&
      showSurfacedStatusReason.value
    ) {
      message += ` with reason ${statusLabel}`
    }

    if (isBulkEdited) {
      message += ' via a bulk edit'
    }

    if (reason === StatusReason.BLOCKED_BY_MIN_TIME) {
      message += `. It will be unblocked on ${readableMinStartTime.value}`
    } else {
      message += ` on ${readableStatusUpdatedAt.value}`
    }

    return message + '.'
  })

  const generatingTrigger = computed(() =>
    triggerApi.data && generatingTriggerId.value
      ? triggerApi.data[generatingTriggerId.value]
      : undefined
  )

  const updatingTriggerId = computed(
    () =>
      props.subtask.statusReasonJson &&
      props.subtask.statusReasonJson.trigger_id
  )

  const updatingTrigger = computed(() =>
    triggerApi.data && updatingTriggerId.value
      ? triggerApi.data[updatingTriggerId.value]
      : undefined
  )

  const readableStatusUpdatedAt = computed(() =>
    formatDateTimeWithTime(stringToDateTime(props.subtask.statusUpdatedAt))
  )

  const readableMinStartTime = computed(() =>
    formatDateTimeWithTime(stringToDateTime(props.subtask.minStartDatetime))
  )

  const readableCreatedAt = computed(() =>
    formatDateTimeWithTime(stringToDateTime(props.subtask.createdAt))
  )

  onMounted(async () => {
    const triggerIds = [
      generatingTriggerId.value,
      updatingTriggerId.value,
    ].filter((x) => !!x)
    if (triggerIds.length) {
      await triggerApi.list({
        params: { filter_trigger_ids: triggerIds, parts: ['trigger_base'] },
      })
    }
  })

  return {
    statusReason,
    StatusReason,
    readableStatusUpdatedAt,
    readableMinStartTime,
    readableCreatedAt,
    updatingTrigger,
    generatingTrigger,
    SYSTEM_USER_UUID,
    creatingStaffName,
    generatingTriggerId,
    updatingTriggerId,
    readableStatus,
    readableSubStatusReason,
    showSurfacedStatusReason,
  }
}
