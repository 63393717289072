import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'
import { EditMedicalConditionsForm } from './EditMedicalConditions/types'
import {
  useArchivedMedicalConditionsApi,
  useMedicalConditionsApi,
} from './store'

/**
 *
 * @param medicalConditionId
 * @param dirtyData
 */
export async function updateMedicalCondition(
  medicalConditionId: string,
  dirtyData: EditMedicalConditionsForm
) {
  let upserted
  try {
    upserted = await useMedicalConditionsApi().partialUpdate({
      ids: [medicalConditionId],
      body: dirtyData,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: `Failed to update medical condition ${medicalConditionId}.`,
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: `Successfully updated medical condition ${medicalConditionId}.`,
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 * Retrieve the patient medical conditions for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getArchivedPatientMedicalConditions(
  patientId: string
): Promise<MedicalCondition | null> {
  const medicalConditionsApi = useArchivedMedicalConditionsApi()
  const medicalConditionsRes = await medicalConditionsApi.list({
    params: {
      filter_patient_ids: [patientId],
      parts: ['medical_condition_reference'],
      filter_is_archived: true,
    },
  })

  if (!medicalConditionsRes?.data?.length) {
    return null
  }

  if (medicalConditionsApi.error) {
    const error = medicalConditionsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient medical conditions`,
      type: NotificationType.WARNING,
      error: `Error fetching patient archived medical conditions: ${error}`,
    })
  }
  return medicalConditionsRes.data
}
