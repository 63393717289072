import { PADSTART_DATE_FORMAT_INT } from './types'

/**
 * function to convert date to MM/DD
 * @param date
 */
export function formatMemberDob(date: Date | undefined): string {
  if (!date) return ''

  const month = String(date.getUTCMonth() + 1).padStart(
    PADSTART_DATE_FORMAT_INT,
    '0'
  ) // getUTCMonth() is 0-based
  const day = String(date.getUTCDate()).padStart(PADSTART_DATE_FORMAT_INT, '0') // getUTCDate() is 1-based

  return `${month}/${day}`
}
