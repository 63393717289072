import { Tab } from '@thyme/nashville/src/types/tabs'
import { Ref, ref } from 'vue'
import { clinicalSummaryTabOptions, ClinicalSummaryTabMap } from './types'

export type ClinicalSummaryViewModel = {
  clinicalSummaryTabOptions: Tab[]
  selectedTab: Ref<ClinicalSummaryTabMap>
  selectTab: (v: Tab) => void
}

/**
 *
 *
 */
export function setup(): ClinicalSummaryViewModel {
  const selectedTab = ref<ClinicalSummaryTabMap>(
    clinicalSummaryTabOptions[0].value
  )

  /**
   *
   * @param v
   */
  function selectTab(v: Tab) {
    selectedTab.value = v.value as ClinicalSummaryTabMap
  }

  return {
    clinicalSummaryTabOptions,
    selectedTab,
    selectTab,
  }
}
