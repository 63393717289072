export const DELETE_INSURANCE_WARNING_PROPS = {
  payerName: {
    type: String,
    required: true,
  },
  networkTier: {
    type: String,
    required: true,
  },
  insuranceId: {
    type: String,
    required: true,
  },
}
