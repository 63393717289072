<template>
  <section class="bg-nash-neutral000">
    <Transition>
      <TNotification v-if="notification" />
    </Transition>
    <div class="container overflow-y-auto h-full">
      <div class="w-1/3 p-5">
        <router-link v-if="!cachedBasicForm" :to="{ path: '/patients' }">
          <span class="flex">
            <TIcon icon="chevronLeft" />
            Back
          </span>
        </router-link>
        <span v-else class="is-clickable flex" @click="cachedBasicForm = null">
          <TIcon icon="chevronLeft" />
          Back
        </span>

        <div>
          <h2 class="mt-2">
            <span v-if="!cachedBasicForm"> Add member </span>
            <span v-else> Add details </span>
          </h2>
        </div>

        <div class="container my-5">
          <!-- v-show is required (vs. v-if) to support multiple validation providers -->
          <!-- @vue-ignore Submit generics not compatible with specifics -->
          <VForm
            v-show="!cachedBasicForm"
            v-slot="{ meta }"
            class="flex flex-col space-y-2"
            @submit="submitBasicForm"
          >
            <div class="flex space-x-2">
              <TInputText
                label="First name"
                name="firstName"
                rules="required"
                class="w-1/2"
              />

              <TInputText
                label="Last name"
                name="lastName"
                rules="required"
                class="w-1/2"
              />
            </div>

            <TInputText
              label="Preferred name (optional)"
              name="preferredName"
            />

            <TDatePicker
              label="Date of Birth"
              name="dateOfBirth"
              :is-datetime="false"
              rules="required"
              color="black"
            />

            <div>
              <LegacyTButton
                expanded
                name="member-add-next"
                type="primary"
                class="mb-1"
                value="Next: Add Details"
                :disabled="!meta.valid"
                @click="shouldAddDetails = true"
              />
              <LegacyTButton
                expanded
                name="member-add-save-without-details"
                type="primaryOutline"
                value="Create Member Profile"
                :disabled="!meta.valid"
                @click="shouldAddDetails = false"
              />
            </div>
          </VForm>

          <!-- @vue-ignore Submit generics not compatible with specifics -->
          <VForm
            v-show="cachedBasicForm"
            v-slot="{ values, setFieldValue }"
            :initial-values="extendedFormInitialValues"
            class="flex flex-col space-y-2"
            @submit="submitExtendedForm"
          >
            <h3 class="mb-3">Statuses</h3>

            <label class="font-bold"> Program Status </label>
            <TDropdown
              v-model="values.programStatus"
              input-id="programStatus"
              option-label="label"
              option-value="value"
              rules="required"
              :options="programStatusChoices"
              :show-clear="false"
              @update:model-value="
                (v: any) => setFieldValue('programStatus', v)
              "
            />
            <label class="font-bold"> Treatment Status </label>
            <TDropdown
              v-model="values.treatmentStatus"
              input-id="programStatus"
              option-label="label"
              option-value="value"
              :options="treatmentStatusChoices"
              @update:model-value="
                (v: any) => setFieldValue('treatmentStatus', v)
              "
            />
            <h3 class="mb-3 mt-5">Contact Information</h3>

            <TInputText
              label="Phone Number"
              name="phoneNumber"
              rules="required|phone"
            />

            <TInputText
              label="Street Address"
              name="streetAddressLine1"
              rules="required"
            />

            <TInputText
              label="Apartment, Suite, etc. (optional)"
              name="streetAddressLine2"
            />

            <TInputText label="City" name="city" rules="required" />

            <div class="flex space-x-2">
              <div>
                <label class="font-bold"> State </label>
                <TDropdown
                  v-model="values.state"
                  class="w-full"
                  input-id="state"
                  option-label="label"
                  option-value="value"
                  :options="stateOptions"
                  rules="required"
                  :show-clear="false"
                  @update:model-value="
                    (v: any) => setFieldValue('state', v)
                  "
                />
              </div>
              <div class="w-1/3">
                <TInputText
                  label="Zip"
                  name="zip"
                  rules="required|digits:5"
                  class="w-1/3"
                />
              </div>
            </div>

            <h3 class="mb-3 mt-5">Insurance Information</h3>

            <!-- IMPROVEME(MT-3196): better validation for insurances/contracts -->
            <label class="font-bold"> Insurance name </label>
            <TDropdown
              v-model="values.insurancePayerId"
              class="w-full"
              input-id="insurancePayerId"
              option-label="label"
              option-value="value"
              :options="payerChoices"
              :show-clear="false"
              @update:model-value="
                (v: any) => setFieldValue('insurancePayerId', v)
              "
            />
            <TInputText
              v-if="isOtherInsurance(values.insurancePayerId)"
              name="insuranceOtherName"
              placeholder="Type in name..."
            />
            <TInputText label="Member ID" name="insuranceMemberId" />
            <TInputText label="Group ID" name="insuranceGroupId" />

            <label class="font-bold">Type</label>
            <TDropdown
              v-model="values.insuranceType"
              class="w-full bg-white"
              input-id="insuranceType"
              option-label="label"
              option-value="value"
              :show-clear="false"
              :options="[
                { label: 'Primary', value: 'primary' },
                { label: 'Secondary', value: 'secondary' },
              ]"
              @update:model-value="
                (v: any) => setFieldValue('insuranceType', v)
              "
            />

            <div v-if="enablePatientContracts">
              <h3 class="mb-3 mt-5">Contracting Information</h3>

              <label class="font-bold"> Contracting entity </label>
              <TDropdown
                v-model="values.contractingEntityId"
                class="w-full"
                input-id="contractingEntityId"
                option-label="label"
                option-value="value"
                :options="contractingEntityChoices"
                :show-clear="false"
                @update:model-value="
                  (v: any) => setFieldValue('contractingEntityId', v)
                "
              />

              <TInputText label="Contracting ID" name="contractingId" />
            </div>

            <LegacyTButton
              name="member-add-save"
              type="primary"
              value="Create Member Profile"
            />
          </VForm>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { Form as VForm } from 'vee-validate'
import { defineComponent } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TNotification from '@/legacy/nashville/TNotification.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TInputText,
    TNotification,
    TDatePicker,
    LegacyTButton,
    TIcon,
    VForm,
    TDropdown,
  },
  setup,
})
</script>
