<template>
  <span>
    <span v-for="(unmatched, index) in unmatchedText" :key="index">
      <span>{{ unmatched }}</span>
      <span v-if="index < unmatchedText.length - 1" class="underline">{{
        searchText
      }}</span>
    </span>
  </span>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    // String being used in search/filter which will be highlighted in option
    searchText: {
      type: String as PropType<string | null | undefined>,
      default: null,
    },
    // Full text label of the option
    optionText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Text which will not be highlighted
    const unmatchedText = computed(() =>
      props.searchText
        ? props.optionText.split(new RegExp(props.searchText, 'gi'))
        : [props.optionText]
    )

    return {
      unmatchedText,
    }
  },
})
</script>
