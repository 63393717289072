<template>
  <div>
    <TModal
      :is-visible="true"
      :size="ModalSize.SM"
      title="Delete Radiation"
      @close="close"
    >
      <div class="flex pb-2 items-center">
        <div>Are you sure you want to delete this radiation?</div>
      </div>
      <div class="date-container">
        <div>
          <div class="font-medium text-sm">{{ radiationDate }}</div>
          <div v-if="radiation" class="font-normal text-sm">
            {{ radiation.additionalInformation }}
          </div>
        </div>
      </div>
      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="radiation-delete-cancel"
          @click="close"
        />
        <TMDangerButton
          name="radiation-delete"
          label="Delete"
          @click="onDelete"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMDangerButton from '@nashville/button/TMDangerButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { deleteRadiationProps } from './types'

export default defineComponent({
  components: {
    TModal,
    TMSecondaryButton,
    TMDangerButton,
  },
  props: deleteRadiationProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.date-container {
  @apply border border-nash-neutral400 p-4 rounded-lg;
}
</style>
