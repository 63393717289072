<template>
  <TMActionDropdown
    v-if="loadedOptions"
    v-bind="$attrs"
    icon="close"
    :show-clear="false"
    :icon-pos="IconPosition.RIGHT"
    :options="loadedOptions"
    icon-clickable
    :initial-value="dirty"
    :option-disabled="isOptionDisabled"
    @change="updateDirty($event.value)"
    @hide="onHide"
    @icon-click="$emit('iconClick', $event)"
  />
  <TOTableButton
    v-else
    v-bind="$attrs"
    icon="close"
    :icon-pos="IconPosition.RIGHT"
    icon-clickable
    @icon-click="(v) => $emit('iconClick', v)"
  />
</template>

<script lang="ts">
import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { IconPosition } from '@thyme/nashville/src/types/icons'
import { Filter } from '@thyme/nashville/src/types/tables'
import { PropType, defineComponent, onMounted, ref, watch } from 'vue'
import { loadOptions } from '@/legacy/components/sharedTable/sharedTableFilters'
import TMActionDropdown from '../dropdown/TMActionDropdown.vue'
import TOTableButton from './TOTableButton.vue'

export default defineComponent({
  components: {
    TMActionDropdown,
    TOTableButton,
  },
  inheritAttrs: false,
  props: {
    filter: {
      type: Object as PropType<Filter>,
      required: true,
    },
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  emits: ['update', 'hide', 'iconClick'],
  setup(props, context) {
    const loadedOptions = ref<DropdownKV[]>([])
    const loadedDisabledOptions = ref<string[]>([])
    const loadedPreEnabledOptions = ref<string[]>([])

    const dirty = ref(props.data)

    const refreshOptions = async () => {
      if (props.filter.modalOptions) {
        const optionsLoaded = await loadOptions(props.filter.modalOptions)

        loadedOptions.value = optionsLoaded.options
        if (optionsLoaded.disabledOptions) {
          loadedDisabledOptions.value = optionsLoaded.disabledOptions
        }
        if (optionsLoaded.preEnabledOptions) {
          loadedPreEnabledOptions.value = optionsLoaded.preEnabledOptions
        }

        if (!props.data && loadedPreEnabledOptions.value.length) {
          updateDirty(loadedPreEnabledOptions)
        }
      }
    }
    const updateDirty = (newVal: any) => {
      const valToUpdate =
        loadedPreEnabledOptions.value.length && !newVal?.length
          ? loadedPreEnabledOptions.value
          : newVal
      context.emit('update', valToUpdate)
      dirty.value = valToUpdate
    }
    const onHide = () => {
      context.emit('hide', dirty.value)
    }

    const isOptionDisabled = (option: DropdownKV) =>
      loadedDisabledOptions.value.find(
        (disabledVal) => disabledVal === option.value
      )

    onMounted(() => refreshOptions())
    watch(
      props,
      () => {
        dirty.value = props.data
      },
      { immediate: true }
    )

    return {
      loadedPreEnabledOptions,
      isOptionDisabled,
      IconPosition,
      loadedOptions,
      onHide,
      updateDirty,
      dirty,
    }
  },
})
</script>
