import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useTreatmentApi } from '@/pages/PatientProfile/CarePlans/shared/store'

/**
 *
 * @param treatmentId
 */
export async function deleteTreatment(treatmentId: string) {
  const treatmentApi = useTreatmentApi()
  let deleted
  try {
    deleted = await treatmentApi.delete({
      ids: [treatmentId],
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to delete treatment.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully deleted treatment.',
    type: NotificationType.SUCCESS,
  })

  const treatmentData = treatmentApi.data
  if (treatmentData && treatmentData[treatmentId]) {
    delete treatmentData[treatmentId]
  }
  return deleted
}
