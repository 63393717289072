<template>
  <div v-if="visitSummary">
    <PrintableComponent
      title="Patient Summary"
      unique-id="visitSummaryPrintElement"
    >
      <div>
        <b>Patient Name: </b>
        {{ formatName(person?.firstName, person?.lastName) }}
      </div>
      <div>
        <b>DOB: </b>
        {{ formatCleanDateTime(stringToDateTime(person?.dateOfBirth)) }}
      </div>
      <div>
        <b>Visit Date: </b>
        {{ formatDateTimeWithTime(stringToDateTime(visitSummary.date)) }}
      </div>
      <div v-if="visitSummary.signature">
        <b>Provider: </b>
        {{ visitSummary.signature.signed_by }}
      </div>
      <div v-if="visitSummary.exam_reason">
        <b>Exam Reason: </b>
        {{ visitSummary.exam_reason[0].parent_text }}
      </div>

      <br />

      <VisitNoteList
        :section="visitSummary.medications"
        title="Current Medications"
      />

      <VisitNoteList
        :section="visitSummary.follow_up"
        is-singular
        title="Follow Up"
      />
    </PrintableComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import VisitNoteList from '@/legacy/components/patient/visitNote/VisitNoteList.vue'
import PrintableComponent from '@/legacy/components/PrintableComponent.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  formatCleanDateTime,
  formatName,
  formatDateTimeWithTime,
} from '@/legacy/libs/format'
import { Person } from '@/legacy/types/entities/people'
import { VisitSummary } from '@/legacy/types/patients/visitNotes'

export default defineComponent({
  components: { VisitNoteList, PrintableComponent },
  props: {
    visitSummary: { type: Object as PropType<VisitSummary>, required: true },
    person: { type: Object as PropType<Person | null>, default: null },
  },
  setup() {
    return {
      formatCleanDateTime,
      formatName,
      stringToDateTime,
      formatDateTimeWithTime,
    }
  },
})
</script>
