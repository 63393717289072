import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useManageTransitionsOfCareApi } from '../store'

/**
 *
 * @param tocId
 */
export async function deleteToc(tocId: string) {
  const tocApi = useManageTransitionsOfCareApi()
  let deleted
  try {
    deleted = await tocApi.delete({
      ids: [tocId],
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to delete transition of care.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully deleted transition of care.',
    type: NotificationType.SUCCESS,
  })

  const tocData = tocApi.data
  if (tocData && tocData[tocId]) {
    delete tocData[tocId]
  }
  return deleted
}
