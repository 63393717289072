import { Job, S3FileDownload } from '@/legacy/types/jobs/jobs'
import apiStore from './apiBuilder'

export const usePathwaysJobsApi = apiStore<Job>(
  'pathwaysJobsApi',
  '/api/pathways/async_jobs',
  {
    transformData: (d: { data: Job[] }) => d,
  }
)

export const usePathwaysJobsFileDownloadApi = apiStore<S3FileDownload>(
  'pathwaysJobsDownloadFileApi',
  '/api/pathways/async_jobs'
)
