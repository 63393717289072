import { DropdownTriggerOptions } from '@thyme/nashville/src/types/dropdowns'
import partition from 'lodash/partition'
import { storeToRefs } from 'pinia'
import { computed, ref, watch, onMounted } from 'vue'

import { formatNameFromPerson } from '@/legacy/libs/format'
import { usePatientStore, usePeopleApi } from '@/legacy/store/modules/patient'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { Contact, ContactsFormData } from '@/legacy/types/patients/contacts'
import { getContactFromStore, upsertContactData } from '../../../lib/contacts'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const { patient, contacts } = storeToRefs(usePatientStore())

  const nameEntityIdMaps = ref<{ [key: string]: string }>({})
  const editingId = ref<string | null>(null)

  const sharedNumberWithTCMember =
    'This number is associated with another member/contact. Please review the existing profile before continuing.'
  const sharedNumberWithContact =
    "This number is associated with another contact on the member's profile. Please review before continuing."

  const memberContactActions = [[DropdownTriggerOptions.edit, 'Edit contact']]

  const contactEntityIds = computed(() =>
    Object.values(contacts.value ?? {}).map(
      (contact: Contact) => contact.contactEntityId
    )
  )
  const partitionedMapEntityPhoneIds = computed(() =>
    partition(
      props.data,
      (mapEntityPhoneNumber) =>
        !contactEntityIds.value.includes(mapEntityPhoneNumber.entityId)
    )
  )

  const otherTCMembers = computed(() => partitionedMapEntityPhoneIds.value[0])
  const memberContacts = computed(() => partitionedMapEntityPhoneIds.value[1])

  /**
   *
   * Function to emit passed close function to exit current view.
   */
  function exitCreateOrEdit() {
    context.emit('exitCreateOrEdit')
  }

  /**
   *
   * Function to emit passed sumbit function with saved form data.
   */
  function submit() {
    context.emit('submit', props.savedFormData)
  }

  /**
   *
   * Function to set editing id based on option selected
   * @param value
   * @param contactEntityMap
   */
  function triggerOptions(
    value: DropdownTriggerOptions,
    contactEntityMap: MapEntityPhoneNumber
  ) {
    const { entityId } = contactEntityMap
    if (value === DropdownTriggerOptions.edit) {
      editingId.value = entityId
    }
  }

  /**
   *
   * Helper function to set up patient name maps
   * e.g. { <map_entity_id>: <person_name>  }
   */
  async function setUpTCMemberNameMaps() {
    if (otherTCMembers.value.length) {
      otherTCMembers.value.forEach(async (tcMember: MapEntityPhoneNumber) => {
        const tcMemberName = await getTCMemberName(tcMember.entityId)
        nameEntityIdMaps.value[tcMember.entityId] =
          tcMemberName ?? 'Unknown Member Name'
      })
    }
  }

  /**
   *
   * Helper function to set up contact name maps
   * e.g. { <map_entity_id>: <person_name>  }
   */
  function setUpContactNameMaps() {
    if (memberContacts.value.length) {
      memberContacts.value.forEach((mapEntityContact: MapEntityPhoneNumber) => {
        const contactMatch = getContactFromStore(
          mapEntityContact.entityId,
          contacts.value ?? {}
        )
        if (contactMatch?.contactEntity.person) {
          nameEntityIdMaps.value[mapEntityContact.entityId] =
            formatNameFromPerson(contactMatch?.contactEntity.person)
        } else {
          nameEntityIdMaps.value[mapEntityContact.entityId] =
            'Unknown Contact Name'
        }
      })
    }
  }

  watch(otherTCMembers, setUpTCMemberNameMaps)
  watch(memberContacts, setUpContactNameMaps)

  onMounted(async () => {
    await setUpTCMemberNameMaps()
    setUpContactNameMaps()
  })

  /**
   *
   * Helper function to retrieve patient name through
   * people api
   * @param entityId
   */
  async function getTCMemberName(entityId: string) {
    const matchedMemberPerson = await usePeopleApi().retrieve({
      ids: [entityId],
    })

    if (matchedMemberPerson) {
      return formatNameFromPerson(matchedMemberPerson)
    }
    return 'Unknown Member'
  }

  /**
   * Build URL to specific subtask
   * @param entityId
   */
  function buildUrlToOtherTCMember(entityId: string) {
    return `/patient/${entityId}`
  }

  /**
   * Function to submit udpated/created contact
   * @param data
   */
  async function submitContact(data: ContactsFormData) {
    const contactId =
      getContactFromStore(editingId.value, contacts.value ?? {})?.contactId ??
      null
    await upsertContactData(data, patient.value, contacts.value, contactId)
    editingId.value = null
  }

  return {
    contacts,
    submitContact,
    getContactFromStore,
    editingId,
    triggerOptions,
    memberContactActions,
    submit,
    exitCreateOrEdit,
    nameEntityIdMaps,
    sharedNumberWithTCMember,
    sharedNumberWithContact,
    buildUrlToOtherTCMember,
    getTCMemberName,
    otherTCMembers,
    memberContacts,
  }
}
