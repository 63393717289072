import { minutesToMs } from '@thyme/libs/src/time/convert'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { loadAppVersion } from './version'

const DEFAULT_CHECK_INTERVAL_MINUTES = 5
const DEFAULT_CHECK_INTERVAL_MS = minutesToMs(DEFAULT_CHECK_INTERVAL_MINUTES)

/**
 * A Pinia store for checking if a new version of the app is available. It does this
 * by making a call on a configurable interval to check the latest deployed version
 * of the app.
 * This is intended for use with SPA deployments (not chrome extension).
 *
 * Usage:
 * ```ts
 * import { createAppVersionCheckStore } from '@thyme/libs/src/version/checkNewVersion'
 *
 * // Do this once in your app to define the store. APP_VERSION is whatever variable holds
 * // the value of this code version.
 * export const useAppVersionChecker = createAppVersionCheckStore(APP_VERSION)
 *
 * // In your component
 * const { isNewVersionAvailable } = storeToRefs(useAppVersionChecker())
 * ```
 * @param thisDeploymentVersion
 * @param checkIntervalMs
 */
export function createAppVersionCheckStore(
  thisDeploymentVersion: string,
  checkIntervalMs = DEFAULT_CHECK_INTERVAL_MS
) {
  return defineStore('appVersionState', () => {
    const isNewVersionAvailable = ref(false)

    /**
     *
     */
    async function checkForNewVersion() {
      const latestVersion = await loadAppVersion()
      if (latestVersion && latestVersion !== thisDeploymentVersion) {
        console.debug(
          'New version available:',
          latestVersion,
          '!=',
          thisDeploymentVersion
        )
        isNewVersionAvailable.value = true
      } else {
        isNewVersionAvailable.value = false
      }
    }

    setInterval(() => {
      void checkForNewVersion()
    }, checkIntervalMs)
    void checkForNewVersion()

    return {
      isNewVersionAvailable,
    }
  })
}
