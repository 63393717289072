import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useFieldArray, useForm } from 'vee-validate'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import {
  OTHER_OPTION,
  OTHER_STRING,
} from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'
import { usePatientClinicalSummaryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  createTreatments,
  getMapOncologySystematicTherapyReferences,
  updateTreatment,
} from './queries'
import { useMapOncDxRefOncTherapyRefApi } from './store'
import {
  AddOrEditTreatmentsForm,
  AddOrEditTreatmentForm,
  addOrEditTreatmentProps,
  listTreatmentSchemas,
} from './types'

type PropsType = ExtractPropTypes<typeof addOrEditTreatmentProps>

/**
 * Set up the AddOrEditTreatment component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: treatmentRefsData } = storeToRefs(
    useMapOncDxRefOncTherapyRefApi()
  )
  const { datum: clinicalSummary } = storeToRefs(usePatientClinicalSummaryApi())

  const oncDxRefId = computed(() => {
    return clinicalSummary.value?.cancerDiagnosis.oncologyDiagnosisRefId
  })

  const showTreatmentDropdown = ref(
    clinicalSummary.value?.cancerDiagnosis.oncologyDiagnosisRefId ??
      !clinicalSummary.value?.cancerDiagnosis.otherOncologyDxType
  )

  const treatmentOptions = computed(() => {
    if (treatmentRefsData.value) {
      const txArr = treatmentRefsData.value
        .map((treatmentRef) => ({
          value: treatmentRef.oncologySystematicTherapyRefId,
          label: `${
            treatmentRef.oncologySystematicTherapiesReference.agent
          } • Brands: ${
            treatmentRef.oncologySystematicTherapiesReference.brandNames.length
              ? treatmentRef.oncologySystematicTherapiesReference.brandNames.join(
                  ', '
                )
              : 'N/A'
          }`,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label))
      return [...txArr, ...OTHER_OPTION]
    }
    return []
  })

  const modalTitle = computed(
    () => `${props.showAdd ? 'Add' : 'Edit'} Treatment`
  )
  const defaultForm = () => ({
    otherTreatmentType: null,
    oncologySystematicTherapyRefId: null,
    treatmentStartDate: null,
    treatmentEndDate: null,
    cancerDiagnosisId: props.cancerDiagnosisId,
    removed: false,
  })

  const treatmentPayloads = ref<AddOrEditTreatmentForm[]>([
    // @ts-ignore
    props.showAdd ? defaultForm() : props.initialData,
  ])

  const { handleSubmit, setFieldValue } = useForm({
    initialValues: { treatmentPayload: treatmentPayloads.value },
    validationSchema: toTypedSchema(listTreatmentSchemas),
  })

  const { fields, push, update } =
    useFieldArray<AddOrEditTreatmentForm>('treatmentPayload')

  /**
   *
   * set other treatment field to empty string to render
   * free text input for other type.
   *
   * also sets appropriate val for oncologySystematicTherapyRefId
   * for proper dropdown value to render.
   * @param val
   * @param index
   * if other option is selected, set otherTreatmentType to empty string
   * to trigger rendering of TextInput
   * else otherTreatmentType should be null so that otherSurgeryType does not show
   */
  function setTreatmentField(val: string, index: number) {
    if (val === OTHER_STRING) {
      setFieldValue(`treatmentPayload.${index}.otherTreatmentType`, '')
    } else {
      setFieldValue(`treatmentPayload.${index}.otherTreatmentType`, null)
    }
    setFieldValue(
      `treatmentPayload.${index}.oncologySystematicTherapyRefId`,
      val
    )
  }

  /**
   * add another treatment payload
   */
  function addTreatmentForm() {
    // @ts-ignore
    push(defaultForm())
  }

  /**
   *
   * @param index
   * using remove will mess with the link between each element
   * and it's associated index
   * band-aid solution is to mark payload
   * to be removed and delete it before making api call
   */
  function removeTreatmentForm(index: number) {
    update(index, { removed: true })
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param treatmentPayloads
   * @param isEdit
   */
  function cleanupTreatmentPayloads(
    treatmentPayloads: AddOrEditTreatmentsForm,
    isEdit = false
  ) {
    const treatmentPayloadsCopy = { ...treatmentPayloads }

    // remove payloads marked to be removed
    treatmentPayloadsCopy.treatmentPayload =
      treatmentPayloadsCopy.treatmentPayload.filter(
        (treatment) => !treatment.removed
      )

    treatmentPayloadsCopy.treatmentPayload.forEach((treatment) => {
      delete treatment.removed

      if (isEdit) {
        delete treatment.cancerDiagnosisId
      }
      if (treatment.otherTreatmentType) {
        treatment.oncologySystematicTherapyRefId = null
      }
      if (treatment.oncologySystematicTherapyRefId) {
        treatment.otherTreatmentType = null
      }
    })

    return treatmentPayloadsCopy
  }

  /**
   *
   * @param values
   */
  async function editTreatment(values: AddOrEditTreatmentsForm) {
    // cleanup payload
    const cleanedTreatmentPayloads = cleanupTreatmentPayloads(values, true)

    try {
      // make update call
      await updateTreatment(
        props.treatmentId,
        cleanedTreatmentPayloads.treatmentPayload[0]
      )
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to update treatment.',
        type: NotificationType.DANGER,
      })
      return
    }
    context.emit('refetch')
  }

  /**
   *
   * @param values
   */
  async function addTreatment(values: AddOrEditTreatmentsForm) {
    const cleanedTreatmentPayloads = cleanupTreatmentPayloads(values)

    try {
      // make create call
      await createTreatments(cleanedTreatmentPayloads.treatmentPayload)
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to add treatment.',
        type: NotificationType.DANGER,
      })
      return
    }
    context.emit('refetch')
  }

  const onAddSubmit = handleSubmit(addTreatment)
  const onEditSubmit = handleSubmit(editTreatment)

  onBeforeMount(async () => {
    if (oncDxRefId.value) {
      await getMapOncologySystematicTherapyReferences(oncDxRefId.value)
    }
  })

  return {
    ModalSize,
    modalTitle,
    treatmentPayloads,
    removeTreatmentForm,
    addTreatmentForm,
    close,
    setTreatmentField,
    fields,
    treatmentOptions,
    showTreatmentDropdown,
    // onSubmit,
    onAddSubmit,
    onEditSubmit,
  }
}
