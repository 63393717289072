<template>
  <div>
    <TMThymeline
      v-slot="{ item }"
      class="outcomes-thymeline"
      :name="`${patientId}-Outcomes`"
      title="Outcomes"
      :rows="perPage"
      :filters="filters"
      :columns="columns"
      :params="outcomesParams(patientId)"
      :api-store="useOutcomesThymelineApi"
      :show-rows-per-page="false"
      :show-header-container="true"
      :free-text-filter="true"
    >
      <OutcomesThymelineRow :task-outcome="item" name="outcomes" />
    </TMThymeline>
  </div>
</template>
<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import OutcomesThymelineRow from '@/legacy/components/patient/thymeline/OutcomesThymelineRow.vue'
import { outcomeCategoryOptions } from '@/legacy/libs/lookup'
import TMThymeline from '@/legacy/nashville/table/TMThymeline.vue'

import { useOutcomeRefApi } from '@/legacy/store/modules/outcomesReferences'
import { useOutcomesThymelineApi } from '@/legacy/store/modules/thymelines'
import { outcomesParams, columns, perPage } from './lib/sharedThymelineParts'

export default defineComponent({
  components: {
    TMThymeline,
    OutcomesThymelineRow,
  },
  setup() {
    const route = useRoute()
    const patientId = computed(() => `${route.params.patientId ?? ''}`)
    const { data: outcomeCatalog } = storeToRefs(useOutcomeRefApi())

    onBeforeMount(() => {
      void useOutcomeRefApi().listAll({})
    })

    const outcomeFilterOptions = computed(() => [
      ...(outcomeCatalog.value
        ? outcomeCategoryOptions(outcomeCatalog.value)
        : []),
    ])

    const filters = {
      outcomeRefIds: {
        value: null,
        modalOptions: {
          label: 'Outcome Categories',
          type: 'multiselect',
          optionsFn: () => outcomeFilterOptions.value,
        },
      },
    }

    return {
      patientId,
      useOutcomesThymelineApi,
      columns,
      perPage,
      outcomesParams,
      filters,
    }
  },
})
</script>
