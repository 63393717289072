<template>
  <NonCallCommunication
    :non-call-type="CommunicationType.Other"
    :comm-save-state="commSaveState"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SaveState } from '@/legacy/types/api/api'
import { CommunicationType } from '@/legacy/types/communications/communications'
import NonCallCommunication from '../NonCallCommunication/NonCallCommunication.vue'

export default defineComponent({
  components: {
    NonCallCommunication,
  },
  props: {
    commSaveState: {
      type: Object as PropType<SaveState>,
      required: true,
    },
  },
  setup() {
    return {
      CommunicationType,
    }
  },
})
</script>
