export default {
  dateFormat: {
    type: String,
    default: 'mm/dd/yy',
  },
  selectionMode: {
    type: String,
    default: null,
    options: ['', 'multiple', 'range'],
  },
  showButtonBar: {
    type: Boolean,
    default: false,
  },
  showTime: {
    type: Boolean,
    default: false,
  },
  hourFormat: {
    type: String,
    default: '12',
  },
  timeOnly: {
    type: Boolean,
    default: false,
  },
  view: {
    type: String,
    default: null,
    options: ['', 'month', 'year'],
  },
  numberOfMonths: {
    type: Number,
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
}
