<template>
  <PVButton
    :class="buttonClasses"
    :data-cy="name && `${kebabCase(name)}-button`"
    :data-testid="name && `${kebabCase(name)}-button`"
    v-bind="$attrs"
    @click="onClick"
  >
    <TIcon
      v-if="loading || icon"
      :icon="loading ? 'prime:spinner' : icon"
      :icon-pos="iconPos"
      :color="iconFill"
      :icon-classes="iconClasses"
      @click="onIconClick"
    />
    <div class="flex items-center">
      <span v-if="label" class="p-button-label">{{ label }}</span>
      <TBadge v-if="badge" class="p-button-badge" :value="badge" />
    </div>
  </PVButton>
</template>

<script lang="ts">
import { buttonTypeProp } from '@thyme/nashville/src/types/buttons'
import { iconProp, iconPositionProp } from '@thyme/nashville/src/types/icons'
import {
  paddingProp,
  actionProp,
  ColorPalettes,
} from '@thyme/nashville/src/types/shared'
import {
  createProp,
  tokenizedComponentClasses,
} from '@thyme/nashville/src/utils/designTokens'
import kebabCase from 'lodash/kebabCase'
import Button from 'primevue/button'
import { computed, defineComponent } from 'vue'
import TBadge from '../badge/TBadge.vue'
import TIcon from '../icon/TIcon.vue'

const sharedProps = {
  ...paddingProp,
  ...actionProp,
  ...buttonTypeProp,
}

export default defineComponent({
  components: {
    PVButton: Button,
    TIcon,
    TBadge,
  },
  props: {
    ...sharedProps,
    /** used for test attrs and unique-ness id */
    name: {
      type: String,
      default: null,
    },
    /** show loading indicator */
    loading: {
      type: Boolean,
      default: false,
    },
    /** text that shows inside the button */
    label: {
      type: String,
      default: '',
    },
    /** expand horizontally to fit full width */
    expanded: {
      type: Boolean,
      default: false,
    },
    /** set justify: space-between css */
    justifyBetween: {
      type: Boolean,
      default: false,
    },
    /** separate icon click from text click */
    iconClickable: {
      type: Boolean,
      default: false,
    },
    ...iconProp,
    ...iconPositionProp,
    /** icon fill color */
    iconFill: createProp(ColorPalettes),
    /** badge text at end of button (used for aggregating) */
    badge: {
      type: String,
      default: '',
    },
  },
  emits: ['click', 'iconClick'],
  setup(props, context) {
    const tokenClasses = tokenizedComponentClasses(
      'button',
      props,
      Object.keys(sharedProps)
    )
    const buttonClasses = computed(() => [
      ...tokenClasses.value,
      props.expanded ? 'p-button-expanded' : '',
      props.justifyBetween ? 'justify-between' : '',
      !props.label && props.icon ? 'p-button-icon-only' : '',
    ])

    // Ported from PV to customize icon v3.21.0
    const iconClasses = computed(() => [
      'p-button-icon',
      props.loading ? 'p-button-loading-icon pi-spin' : props.icon ?? '',
    ])

    const onClick = (e: any) => {
      context.emit('click', e)
    }

    const onIconClick = (e: any) => {
      if (props.iconClickable) {
        context.emit('iconClick', e)
        e.stopPropagation()
      }
    }

    return {
      onClick,
      onIconClick,
      buttonClasses,
      iconClasses,
      kebabCase,
    }
  },
})
</script>
