import { ScheduledTimeBlock } from '@/legacy/types/communications/plannedCalls'

export const timeBlockDropdownOptions = [
  {
    label: 'Morning',
    description: '8am-12pm',
    value: ScheduledTimeBlock.MORNING,
  },
  {
    label: 'Afternoon',
    description: '12pm-4pm',
    value: ScheduledTimeBlock.AFTERNOON,
  },
  {
    label: 'Evening',
    description: '4pm-8pm',
    value: ScheduledTimeBlock.EVENING,
  },
  {
    label: 'Custom',
    description: 'Input specific time directly',
    value: ScheduledTimeBlock.CUSTOM,
  },
]
