import { EntityRole } from '@/legacy/types/entities/entities'
import { UUID } from '@/legacy/types/global/base'
import { SubtaskPayload, SubtaskStatus } from '@/legacy/types/pathways/subtasks'

/**
 *
 * @param val
 * @param oldSubtask
 * @param entityId
 */
export function setRoleOrStaff(
  val: EntityRole | UUID | null,
  oldSubtask: SubtaskPayload,
  entityId: string
) {
  const isRoleVal = (val: EntityRole | string) =>
    Object.values(EntityRole).includes(val as EntityRole)
  const isNull = val === 'NULL' || !val
  const isUnassignedOrRoleVal = isNull || isRoleVal(val)

  const setStatus = () => {
    const isOpenUnassigned = oldSubtask.status === SubtaskStatus.OPEN_UNASSIGNED
    // If current status = OPEN_UNASSIGNED, and new val is a staff member, set status=OPEN_ASSIGNED
    if (isOpenUnassigned && !isUnassignedOrRoleVal) {
      return SubtaskStatus.OPEN_ASSIGNED
    }
    // If current status != BLOCKED and != OPEN_UNASSIGNED, and new val is a role or null, set status=OPEN_UNASSIGNED
    if (isUnassignedOrRoleVal && oldSubtask.status !== SubtaskStatus.BLOCKED) {
      return SubtaskStatus.OPEN_UNASSIGNED
    }
    // Otherwise, keep status the same (e.g. if we change roles on an unassigned subtask, or change staff on an assigned subtask)
    return oldSubtask.status
  }

  if (isUnassignedOrRoleVal) {
    return {
      responsibleRole: isNull
        ? oldSubtask.responsibleRole
        : (val as EntityRole),
      responsibleStaffId: null,
      status: setStatus(),
    }
  } else {
    return {
      responsibleStaffId: val === 'ME' ? entityId : val,
      status: setStatus(),
    }
  }
}
