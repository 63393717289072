<template>
  <TModal
    :is-visible="isVisible"
    :flex="true"
    :size="ModalSize.LG"
    :allow-overflow="true"
    @close="close"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <LegacyTButton
          name="back-add-pathway"
          icon="chevronLeft"
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="goBack"
        />
        <span class="font-bold text-2xl">Add Playbooks</span>
      </div>
      <div>
        <LegacyTButton
          name="close-add-pathway"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="close"
        />
      </div>
    </div>
    <TSearchAndFilter
      search-placeholder="Search Playbooks..."
      @update-search="updateSearch"
    />
    <div v-if="isLoading">Loading...</div>
    <div class="overflow-auto space-y-4 h-full">
      <AddPathwayInner
        v-for="pathwayTemplate in shownPathwayTemplates"
        :key="pathwayTemplate.pathwayTemplateId"
        :pathway-template="pathwayTemplate"
        :can-add="!hitPathwayLimit"
        @add="addPathway(pathwayTemplate.variantId)"
        @remove="removePathway(pathwayTemplate.variantId)"
      />
      <span v-if="!shownPathwayTemplates.length"> None found </span>
      <TMTertiaryButton
        v-if="
          totalPathwayTemplates &&
          totalPathwayTemplates - currentPathwayVariantIds.length >
            shownPathwayTemplates.length
        "
        label="Load more"
        @click="loadMore"
      />
    </div>
    <template #actions>
      <LegacyTButton type="tertiary" value="Cancel" @click="cancelAdd" />
      <LegacyTButton value="Save" :disabled="dataIsLoading" @click="saveAdd" />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import debounce from 'lodash/debounce'
import remove from 'lodash/remove'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, watch, PropType, ref } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

import TModal from '@/legacy/nashville/TModal.vue'
import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'
import {
  usePathwayTemplateApi,
  usePathwayApi,
  getAllPathwayTemplates,
} from '@/legacy/store/modules/pathways'
import { UUID } from '@/legacy/types/global/base'
import { PathwayTemplate } from '@/legacy/types/pathways/pathways'
import AddPathwayInner from './AddPathwayInner.vue'

// CONST
const DEBOUNCE_INTERVAL_MS = 200

export default defineComponent({
  components: {
    TMTertiaryButton,
    LegacyTButton,
    TSearchAndFilter,
    TModal,
    AddPathwayInner,
  },
  props: {
    excludeVariantIds: {
      type: Array as PropType<UUID[]>,
      default: () => [],
    },
    numAllowedPathways: {
      type: Number,
      default: undefined,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'back', 'save', 'cancel'],
  setup(props, context) {
    const pathwayTemplateApi = usePathwayTemplateApi()
    const { data: pathwayTemplates } = storeToRefs(pathwayTemplateApi)

    const addedPathways = ref<string[]>([])
    const currentSearch = ref('')
    const perPage = 10
    const pageNumber = ref(1)

    const totalPathwayTemplates = computed(
      () => pathwayTemplateApi.queryMetadata?.total
    )
    const dataIsLoading = computed(() => usePathwayApi().isLoading)

    const allPathwayTemplates = computed(() =>
      Object.values(pathwayTemplates.value ?? {})
    )
    const isLoading = computed(() => pathwayTemplateApi.isLoading)

    //baseTemplateIds of pathway template variants that match
    //variant ids of real pathways minus cancelled/completed
    const currentPathwayVariantIds = computed(() => {
      const baseTemplateIds = Object.values(props.excludeVariantIds ?? []).map(
        (excludedVariantId) =>
          allPathwayTemplates.value.find(
            (pathwayTemplate) => pathwayTemplate.variantId === excludedVariantId
          )?.baseTemplateId
      )

      return allPathwayTemplates.value
        .filter((pathwayTemplate) =>
          baseTemplateIds.includes(pathwayTemplate.baseTemplateId)
        )
        .map((pathwayTemplate) => pathwayTemplate.baseTemplateId)
    })

    const debouncedGetAllPathwayTemplates = debounce(
      getAllPathwayTemplates,
      DEBOUNCE_INTERVAL_MS
    )

    //all templates minus evergreen and already added
    //currently showing in addPathwaysModal
    const shownPathwayTemplates = computed(() =>
      allPathwayTemplates.value
        .filter(({ baseTemplateId }: PathwayTemplate) => {
          const pathwayTypeNotAdded =
            !currentPathwayVariantIds.value.includes(baseTemplateId)
          return pathwayTypeNotAdded
        })
        .sort((a, b) => a.title.localeCompare(b.title))
    )

    watch(currentSearch, async (text: string) => {
      pageNumber.value = 1
      usePathwayTemplateApi().reset()
      await debouncedGetAllPathwayTemplates({
        page_length: perPage,
        filter_free_text: text,
        page_number: pageNumber.value,
      })
    })

    watch(props, async () => {
      if (!pathwayTemplates.value && props.isVisible) {
        await debouncedGetAllPathwayTemplates({
          page_length: perPage,
          filter_free_text: '',
          page_number: pageNumber.value,
        })
      }
    })

    const hitPathwayLimit = computed(
      () =>
        props.numAllowedPathways !== undefined &&
        addedPathways.value.length >= props.numAllowedPathways
    )

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      await getAllPathwayTemplates({
        page_length: perPage,
        filter_free_text: currentSearch.value,
        page_number: pageNumber.value,
      })
    }

    const resetAll = () => {
      addedPathways.value = []
      currentSearch.value = ''
      pageNumber.value = 1
    }

    const close = () => {
      context.emit('close')
      resetAll()
    }
    const goBack = () => {
      context.emit('back')
      resetAll()
    }
    const saveAdd = () => {
      context.emit('save', addedPathways.value)
      resetAll()
    }
    const cancelAdd = () => {
      context.emit('cancel')
      resetAll()
    }

    const addPathway = (pathwayVariantId: string) =>
      addedPathways.value.push(pathwayVariantId)
    const removePathway = (pathwayVariantId: string) =>
      remove(addedPathways.value, (v) => v === pathwayVariantId)
    const updateSearch = (newSearch: string) =>
      (currentSearch.value = newSearch ?? '')

    return {
      ModalSize,
      ButtonSize,
      currentPathwayVariantIds,
      dataIsLoading,
      totalPathwayTemplates,
      allPathwayTemplates,
      hitPathwayLimit,
      addedPathways,
      shownPathwayTemplates,
      currentSearch,
      addPathway,
      cancelAdd,
      close,
      goBack,
      removePathway,
      saveAdd,
      updateSearch,
      isLoading,
      loadMore,
    }
  },
})
</script>
