import { isLeft } from 'fp-ts/Either'
import * as t from 'io-ts'
import { PathReporter } from 'io-ts/PathReporter'

const VERSION_FILE_NAME = 'version.json'

const VersionDecoder = t.type({
  version: t.string,
})

/**
 * Validator for version JSON.
 * @param arg
 */
function validateVersion(arg: any): string {
  const decoded = VersionDecoder.decode(arg)
  if (isLeft(decoded)) {
    throw new Error(
      `Application version is not valid: ${PathReporter.report(decoded).join(
        '\n'
      )}`
    )
  }
  return decoded.right.version
}

/**
 * @param filename
 */
export async function loadAppVersion(
  filename = VERSION_FILE_NAME
): Promise<string | null> {
  let versionObj: any
  try {
    const response = await fetch(`/${filename}`)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    versionObj = await response.json()
    return validateVersion(versionObj)
  } catch (e: any) {
    // At the moment, this feature is best-effort: we do not want to crash on a missing version file.
    console.error('Failed to fetch application version', e)
    return null
  }
}
