z
<template>
  <div class="texting-thymeline-container">
    <TextingInbox class="texting-inbox" />
    <ActiveConversation class="active-conversation" />
  </div>
</template>
<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ActiveConversation from '@/legacy/components/texting/ActiveConversation/ActiveConversation.vue'
import { getOpenPointerSubtasks } from '@/legacy/components/texting/lib/sharedTextingParts'
import TextingInbox from '@/legacy/components/texting/TextingInbox/TextingInbox.vue'
import router from '@/legacy/router'
import { useConfigStore } from '@/legacy/store/modules/config'
import { usePatientStore } from '@/legacy/store/modules/patient'

export default defineComponent({
  components: { ActiveConversation, TextingInbox },
  setup() {
    const { phoneNumbers } = storeToRefs(usePatientStore())
    const route = useRoute()
    const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
      useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId

    onMounted(async () => {
      if (TEXTING_UNKNOWN_NUMBERS_PATIENT_ID) {
        await usePatientStore().fetchPatient(TEXTING_UNKNOWN_NUMBERS_PATIENT_ID)
        await getOpenPointerSubtasks(route.params.patientId as string)
      }

      // make sure url updates if there are no unknown numbers to prevent constant loading state
      if (!phoneNumbers.value) {
        void router.push({
          query: {},
        })
      }
    })

    return {}
  },
})
</script>
<style scoped>
.texting-thymeline-container {
  @apply relative border-y border-nash-neutral300 flex flex-row h-1/3;
  height: 650px;
}

.texting-inbox {
  @apply h-full p-3 relative w-1/3 border-r border-nash-neutral300 flex flex-col;
  overflow: scroll;
}

.active-conversation {
  @apply h-full relative w-2/3 flex flex-col;
}
</style>
