import { USState } from '@/legacy/types/entities/entities'
import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------

export type AddressPartial = {
  streetAddressLine1: string
  streetAddressLine2: string
  city: string
  state: USState
  county: string
  zip: string
}

export type Address = {
  addressId: string
  entityId: string
  streetAddressLine1: string
  streetAddressLine2: string
  city: string
  state: USState
  county: string
  zip: string
  note: string
  type: string
  source: string
} & Timestamps

export type PatientAddressFormData = {
  streetAddressLine1: string
  streetAddressLine2: string
  city: string
  county: string
  state: string
  zip: string
  type?: string
  note?: string
}

// ENUMS --------------------
export enum AddressType {
  Home = 'home',
  Mailing = 'mailing',
  HomeAndMailing = 'home_and_mailing',
  Other = 'other',
}
