<template>
  <div :class="containerClasses">
    <div :class="notificationClasses">
      <div class="flex gap-1 items-center">
        <TIcon v-if="icon" class="m-0" :icon="icon" />
        {{ notification?.message }}
      </div>
      <TIcon class="m-0" icon="close" @click="closeIcon" />
    </div>
    <div v-if="notification?.description" class="pl-5 ml-2">
      {{ notification.description }}
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import { storeToRefs } from 'pinia'
import { computed, defineComponent } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  notificationClasses,
  notificationTypeToIcon,
  notificationTypeToColor,
} from '@/legacy/types/notifications'

export default defineComponent({
  components: {
    TIcon,
  },
  setup() {
    const { notification } = storeToRefs(useNotificationStore())

    const icon = computed(
      () => notificationTypeToIcon[notification.value?.type ?? '']
    )
    const bgColor = computed(
      () => notificationTypeToColor[notification.value?.type ?? '']
    )

    const containerClasses = computed(() => [
      'rounded',
      'max-h-20',
      'flex-col',
      'p-3',
      'text-nash-neutral000',
      'flex',
      'absolute',
      'z-50',
      'w-700',
      'inset-x-0',
      'bottom-0',
      'mb-2.5',
      'mx-2.5',
      'mt-2',
      'nash-drop-shadow',
      bgColor.value,
    ])

    /**
     *
     */
    function closeIcon() {
      useNotificationStore().closeNotification()
    }

    return {
      icon,
      containerClasses,
      notificationClasses,
      closeIcon,
      notification,
    }
  },
})
</script>
