import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import {
  MapEntityPhoneNumber,
  PhoneNumber,
} from '@/legacy/types/entities/phoneNumbers'

export const useMapEntityPhoneNumberApi = apiStore<
  MapEntityPhoneNumber,
  IdMap<MapEntityPhoneNumber>
>('mapEntityPhoneNumberApi', '/api/phone_numbers/entity_maps', {
  transformData: (d: { data: MapEntityPhoneNumber[] }) =>
    idMapTransform({}, 'data', 'mapEntityPhoneNumberId', d.data),
})
export const useMapEntityOtherContactPhoneNumberApi = apiStore<
  MapEntityPhoneNumber,
  IdMap<MapEntityPhoneNumber>
>('mapEntityOtherContactPhoneNumberApi', '/api/phone_numbers/entity_maps', {
  transformData: (d: { data: MapEntityPhoneNumber[] }) =>
    idMapTransform({}, 'data', 'mapEntityPhoneNumberId', d.data),
})
export const usePhoneNumberApi = apiStore<PhoneNumber, IdMap<PhoneNumber>>(
  'phoneNumberApi',
  '/api/phone_numbers',
  {
    transformData: (d: PhoneNumber[]) =>
      idMapTransform({}, 'data', 'phoneNumberId', d),
  }
)
