<template>
  <TSearchAndFilter
    v-if="filter.modalOptions?.search"
    @update-search="updateSearch"
  />
  <TMGroupedInputButtons
    v-if="
      filter.modalOptions?.type === FilterType.Select ||
      filter.modalOptions?.type === FilterType.Multiselect
    "
    :data="dirty"
    :options="filteredOptions"
    name="filter-modal-inner-selection"
    :multiple="filter.modalOptions?.type === FilterType.Multiselect"
    select-all-option
    :disabled="loadedDisabledOptions"
    @update:model-value="updateDirty"
  />
  <div
    v-else-if="filter.modalOptions?.type === FilterType.DateRange"
    class="flex min-h-450"
  >
    <TDatePicker
      v-model="dirty"
      :name="`filter-modal-daterange-${filter.modalOptions?.label}`"
      :is-datetime="false"
      range
      @update:model-value="updateDirtyDateRange"
    />
  </div>
</template>

<script lang="ts">
import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { Filter, FilterType } from '@thyme/nashville/src/types/tables'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { DateTime } from 'luxon'
import { defineComponent, onMounted, PropType, ref } from 'vue'
import { loadOptions } from '@/legacy/components/sharedTable/sharedTableFilters'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TMGroupedInputButtons from '@/legacy/nashville/TMGroupedInputButtons.vue'
import TSearchAndFilter from '@/legacy/nashville/TSearchAndFilter.vue'

export default defineComponent({
  components: {
    TDatePicker,
    TMGroupedInputButtons,
    TSearchAndFilter,
  },
  props: {
    filter: {
      type: Object as PropType<Filter>,
      default: null,
    },
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, context) {
    const dirty = ref(props.data)
    const updateDirty = (newVal: any) => {
      context.emit('update', newVal)
      dirty.value = newVal
    }
    const updateDirtyDateRange = (newVal: [Date, Date]) => {
      if (newVal) {
        return updateDirty([
          newVal[0],
          DateTime.fromJSDate(newVal[1]).endOf('day').toJSDate(),
        ])
      }
      return updateDirty([])
    }
    // Model Type Functionality
    const loadedOptions = ref<DropdownKV[]>([])
    const filteredOptions = ref<DropdownKV[]>(loadedOptions.value)
    const loadedDisabledOptions = ref<string[]>([])

    const refreshOptions = async () => {
      if (props.filter.modalOptions) {
        const optionsLoaded = await loadOptions(props.filter.modalOptions)

        loadedOptions.value = optionsLoaded.options
        filteredOptions.value = optionsLoaded.options
        if (optionsLoaded.disabledOptions) {
          loadedDisabledOptions.value = optionsLoaded.disabledOptions
        }
      }
    }

    /**
     *
     * @param newSearch
     * filters through filter options and returns a list of related matches
     */
    const updateSearch = async (newSearch: string) =>
      (filteredOptions.value = sortBy(
        filter(loadedOptions.value, (option) =>
          option.label.toLowerCase().includes(newSearch.toLowerCase())
        ),
        (option) => option.label
      ))

    onMounted(() => {
      if (
        (!dirty.value || !dirty.value.length) &&
        props.filter.modalOptions?.type === FilterType.Multiselect
      ) {
        const preEnabledValsFn = props.filter.modalOptions.preEnabledOptionsFn
        preEnabledValsFn
          ? updateDirty([...preEnabledValsFn()])
          : updateDirty([])
      }
      void refreshOptions()
    })

    return {
      loadedDisabledOptions,
      dirty,
      updateDirty,
      updateDirtyDateRange,
      updateSearch,
      filteredOptions,
      FilterType,
      loadedOptions,
    }
  },
})
</script>
