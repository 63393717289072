<template>
  <div
    class="py-2 px-6 font-bold"
    :style="{ backgroundColor: color, color: textColor(color) }"
  >
    {{ name }}
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    color: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    /**
     * use W3 standard for color contrast to determine optimal text color
     * @param bgColor
     * @param lightColor
     * @param darkColor
     */
    function pickTextColorBasedOnBgColor(
      bgColor: string,
      lightColor = 'white',
      darkColor = 'black'
    ) {
      const color = bgColor.substring(1, 7)
      const r = parseInt(color.substring(0, 2), 16) // hexToR
      const g = parseInt(color.substring(2, 4), 16) // hexToG
      const b = parseInt(color.substring(4, 6), 16) // hexToB
      const uicolors = [r / 255, g / 255, b / 255]
      const c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92
        }
        return Math.pow((col + 0.055) / 1.055, 2.4)
      })
      const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
      return L > 0.179 ? darkColor : lightColor
    }
    return {
      textColor: pickTextColorBasedOnBgColor,
    }
  },
})
</script>
