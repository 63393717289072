<template>
  <div tabindex="-1" class="relative z-10" @focusout="handleBlur">
    <input
      v-model="searchText"
      autocomplete="off"
      class="border rounded p-2 w-full"
      :name="name"
      :placeholder="placeholder"
      :data-cy="kebabCase(name)"
      @focus="showOptions = true"
      @input="updateSearch"
    />
    <div v-if="showOptions" class="options-wrapper">
      <div
        v-for="option in filteredOptions"
        :key="option[0]"
        class="option"
        @click="select(option)"
      >
        <span
          v-for="(unmatched, index) in getUnmatchedText(option)"
          :key="index"
        >
          <span>{{ unmatched }}</span>
          <span
            v-if="index < getUnmatchedText(option).length - 1"
            class="underline"
            >{{ searchText }}</span
          >
        </span>
        <span v-if="excessMatchedText(option[1])">{{
          excessMatchedText(option[1])
        }}</span>
      </div>
      <div class="option" @click="select(['other', ''])">
        <span :class="selectedCategory === 'other' ? 'font-bold' : ''"
          >Other:
        </span>
        {{ searchText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import find from 'lodash/find'
import kebabCase from 'lodash/kebabCase'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<string[][]>,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    selectedCategory: {
      type: String,
      default: '',
    },
    selectedName: {
      type: String,
      default: '',
    },
  },
  emits: ['update'],
  setup(props, context) {
    const findOptionDisplay = (value: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const option = find(props.options, ([v, d]: string[]) => v === value)
      return option ? option[1] : ''
    }

    const showOptions = ref(false)
    const searchText = ref(
      props.selectedCategory || props.selectedName
        ? `${findOptionDisplay(props.selectedCategory)} ${props.selectedName}`
        : ''
    )
    const dirtyCategory = ref(props.selectedCategory || '')
    const dirtyName = computed(() =>
      excessMatchedText(findOptionDisplay(dirtyCategory.value))
    )

    const matchSearch = (optionValue: string, text: string) =>
      !!optionValue.toLowerCase().includes(text.toLowerCase()) ||
      !!text.toLowerCase().includes(optionValue.toLowerCase())

    const getUnmatchedText = (option: string[]) =>
      searchText.value
        ? option[1].split(new RegExp(searchText.value, 'gi'))
        : [option[1]]

    const excessMatchedText = (optionText: string) => {
      const text = optionText.trim()
      const excess =
        searchText.value &&
        text &&
        searchText.value.match(new RegExp(text, 'gi'))
          ? searchText.value.split(new RegExp(text, 'gi'))
          : optionText
          ? ['', '']
          : ['', searchText.value]
      return excess[1]
    }

    const filteredOptions = computed(() =>
      props.options.filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([v, d]: string[]) => matchSearch(d, searchText.value) && d !== 'Other'
      )
    )
    const select = (option: string[]) => {
      dirtyCategory.value = option[0]
      searchText.value = `${option[1]} ${excessMatchedText(option[1]).trim()}`
      context.emit('update', {
        category: dirtyCategory.value,
        name: dirtyName.value,
      })
    }
    const updateSearch = () =>
      context.emit('update', {
        category: dirtyCategory.value,
        name: dirtyName.value,
      })

    // focusout grabs all child blur events, but will flicker the options container
    // if we can't filter out blur events in the same parent chain causing undefined clicks
    const handleBlur = (event: FocusEvent) => {
      // @ts-ignore Current Target is node and contains related Target guaranteed
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        showOptions.value = false
      }
    }

    return {
      searchText,
      dirtyCategory,
      dirtyName,
      filteredOptions,
      showOptions,
      excessMatchedText,
      getUnmatchedText,
      matchSearch,
      select,
      kebabCase,
      updateSearch,
      handleBlur,
    }
  },
})
</script>

<style lang="scss">
.options-wrapper {
  @apply absolute w-full border-1 border-gray-300;
}

.option {
  @apply border p-2 bg-nash-neutral100 w-full border-1 border-gray-300 cursor-pointer;
}
</style>
