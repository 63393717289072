import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { CarePlanTransitionsOfCare } from '@/pages/PatientProfile/CarePlans/shared/types'
import { ManageCarePlanTransitionsOfCareDataState } from './types'
/**
 *
 * @param data
 * @param total
 */
function transformManageCarePlanTransitionsOfCare(
  data: CarePlanTransitionsOfCare[],
  total: number
): Partial<ManageCarePlanTransitionsOfCareDataState> {
  const currentData = useManageTransitionsOfCareApi().data
  const currentDataMap = toIdMap(data, 'transitionsOfCareId')
  const newData = Object.assign({}, currentDataMap, currentData)

  // sort direction should be in desc order based on admissionDate
  const sortedData = Object.values(newData).sort(
    (a: CarePlanTransitionsOfCare, b: CarePlanTransitionsOfCare) => {
      const aDate = new Date(a.admissionDate).valueOf()
      const bDate = new Date(b.admissionDate).valueOf()
      return bDate - aDate
    }
  )
  return {
    ...idMapTransform({}, 'data', 'transitionsOfCareId', sortedData),
    queryMetadata: { total },
  }
}

export const useManageTransitionsOfCareApi = apiStore<
  CarePlanTransitionsOfCare,
  IdMap<CarePlanTransitionsOfCare>
>(
  'manageTransitionsOfCareApi',
  '/api/care_plan/care_plan_transitions_of_care',
  {
    transformData: (d: {
      data: CarePlanTransitionsOfCare[]
      queryMetadata: { total: number }
    }) =>
      transformManageCarePlanTransitionsOfCare(d.data, d.queryMetadata.total),
  }
)
