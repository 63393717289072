import { ColumnLayout } from '@/legacy/types/nashville/layout'

/**
 * dispatch layout event
 * used by TLColumnView
 * @param eventName
 * @param detail
 */
export function layoutUpdate<K extends keyof LayoutEventMap>(
  eventName: K,
  detail?: Partial<ColumnLayout>
) {
  const event = new CustomEvent(eventName, { detail })
  document.dispatchEvent(event)
}

/**
 * dispatch global event across all components
 * used to trigger updates in components in separate DOM trees
 * @param eventName
 * @param detail
 */
export function thymeDispatch<K extends keyof ThymeEventMap>(
  eventName: K,
  detail?: any
) {
  let evt
  if (eventName === 'keydown') {
    evt = new KeyboardEvent(eventName)
  } else if (eventName === 'beforeunload') {
    evt = new BeforeUnloadEvent()
  } else if (detail) {
    evt = new CustomEvent(eventName, { detail })
  } else {
    evt = new Event(eventName)
  }
  document.dispatchEvent(evt)
}

/**
 * listen for an event
 * @param eventName
 * @param listener
 * @param options
 */
export function thymeListen<K extends keyof ThymeEventMap>(
  eventName: K,
  listener: (this: Document, ev: ThymeEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
) {
  document.addEventListener(eventName, listener, options)
}

/**
 * remove an active event listener
 * @param eventName
 * @param listener
 * @param options
 */
export function thymeTuneOut<K extends keyof ThymeEventMap>(
  eventName: K,
  listener: (this: Document, ev: ThymeEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
) {
  document.removeEventListener(eventName, listener, options)
}
