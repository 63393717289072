import { defineStore } from 'pinia'

import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { NotificationType } from '@/legacy/types/notifications'
import {
  DiagnosisReference,
  DiagnosisRefsState,
  GetDiagnosisRefsParams,
} from '@/legacy/types/patients/diagnoses'
import { useNotificationStore } from './notification'

// CONST
const DIAGNOSES_REFS_PAGE_LENGTH = 10

/**
 *
 * @param data
 * @param total
 */
function transformDiagnosisRefs(
  data: DiagnosisReference[],
  total: number
): Partial<DiagnosisRefsState> {
  const diagnosisRefApi = useDiagnosisRefApi()
  const currentTemplatesMapping = diagnosisRefApi.data ?? {}
  const newDataMap = toIdMap(data, 'diagnosisRefId')
  const newData = Object.assign({}, currentTemplatesMapping, newDataMap)

  return {
    queryMetadata: { total },
    ...idMapTransform({}, 'data', 'diagnosisRefId', Object.values(newData)),
  }
}

export const useDiagnosesRefStore = defineStore('diagnosesReferences', {
  state: () => ({}),
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} task(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} comorbidity reference: ${error}`,
      })
    },

    async fetchDiagnosisRefs({
      filter_diagnosis_ref_ids = null,
      filter_code_types = null,
      page_length = DIAGNOSES_REFS_PAGE_LENGTH,
      page_number = 1,
      sort_by = null,
    }: GetDiagnosisRefsParams) {
      const diagnosisRefApi = useDiagnosisRefApi()
      const results = await diagnosisRefApi.list({
        params: {
          ...(filter_diagnosis_ref_ids ? { filter_diagnosis_ref_ids } : {}),
          ...(filter_code_types ? { filter_code_types } : {}),
          ...(page_length ? { page_length: page_length } : {}),
          ...(page_number ? { page_number: page_number } : {}),
          ...(sort_by ? { sort_by: sort_by } : {}),
        },
      })

      if (diagnosisRefApi.error) {
        this.setError(diagnosisRefApi.error, 'fetching')
      }

      return results
    },
    async fetchAllDiagnosisRefs(filter_diagnosis_ref_ids: string[] = []) {
      await useDiagnosisRefApi().listAll({
        params: { filter_diagnosis_ref_ids: filter_diagnosis_ref_ids },
      })
    },
  },
})

export const useDiagnosisRefApi = apiStore<
  DiagnosisReference,
  IdMap<DiagnosisReference>
>('diagnosisReferenceApi', '/api/diagnoses/reference', {
  transformData: (d: {
    data: DiagnosisReference[]
    queryMetadata: { total: number }
  }) => transformDiagnosisRefs(d.data, d.queryMetadata.total),
})
