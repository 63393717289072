import { DateTime } from 'luxon'
import { UUID } from '../global/base'

export enum CallSource {
  WEBHOOK = 'WEBHOOK',
  ZOOM_API = 'ZOOM_API',
  MANUAL = 'MANUAL',
  UNKNOWN = 'UNKNOWN',
}

export enum CallParts {
  ZOOM_CALL_DATA = 'zoom_call_data',
}

export type ZoomCallData = {
  callId: string
  logId?: UUID

  direction?: string
  duration?: string

  dateRime?: DateTime
  callEndTime?: DateTime
  answerStartTime?: DateTime
}

export type ZoomCall = {
  callId: string
  calleePhoneNumberId?: UUID
  callerPhoneNumberId?: UUID
  source: CallSource
  communicationId: UUID
  zoomCallData?: ZoomCallData
}

export type ZoomCallRecording = {
  recordingId: UUID
  fileUrl: string
}

export type ZoomTranscriptUser = {
  username?: string
}

export type ZoomCallTimeline = {
  text?: string
  endTs?: string
  startTs?: string
  users?: ZoomTranscriptUser[]
}

export type ZoomCallTranscript = {
  recordingId: string
  timeline: ZoomCallTimeline[]
  recordingStart?: string
}
