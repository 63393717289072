import { requiredInject } from '@thyme/libs/src/vue/inject'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import {
  getPatientRadiationWithParts,
  getPatientSurgeryWithParts,
  getPatientTreatmentWithParts,
} from '@/pages/PatientProfile/CarePlans/shared/queries'
import {
  usePatientClinicalSummaryApi,
  useRadiationApi,
  useSurgeryApi,
  useTreatmentApi,
} from '@/pages/PatientProfile/CarePlans/shared/store'
import { formatLastUpdatedStr } from '@/pages/PatientProfile/shared/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { toDateDisplay } from '../shared/utils'
import { usePreviousCancerRadiationApi } from './ManageRadiations/shared/store'
import { useMapOncDxRefSurgicalProcedureRefApi } from './ManageSurgeries/AddOrEditSurgery/store'
import { usePreviousCancerSurgeriesApi } from './ManageSurgeries/shared/store'
import { useMapOncDxRefOncTherapyRefApi } from './ManageTreatments/AddOrEditTreatment/store'
import { usePreviousCancerTreatmentApi } from './ManageTreatments/shared/store'
import {
  getLastUpdatedPatientSurgery,
  getLastUpdatedPatientTreatment,
  getLastUpdatedPatientRadiation,
} from './queries'

import {
  useLastUpdatedTreatmentApi,
  useLastUpdatedSurgeryApi,
  useLastUpdatedRadiationApi,
} from './shared/store'

import { PAGE_LENGTH, TreatmentField } from './shared/types'

// Defined only for convenience and use in testing.
export type PatientTreatmentViewModel = {
  treatmentDisplay: Ref<{ agent: string; brands: string }[]>
  radiationDisplay: Ref<{ radDate: string; additionalInfo: string }[]>
  surgeryDisplay: Ref<string[]>
  showMoreTreatmentsButton: Ref<boolean>
  showMoreSurgeriesButton: Ref<boolean>
  showMoreRadiationDatesButton: Ref<boolean>
  isLoading: Ref<boolean>
  TreatmentField: typeof TreatmentField
  showManageTreatmentModal: Ref<boolean>
  showManageSurgeryModal: Ref<boolean>
  showManageRadiationsModal: Ref<boolean>
  cancerDetailsId: Ref<string | null>
  patientId: Ref<string>
  lastUpdatedDisplay: Ref<string>
  loadMore: (field: TreatmentField) => void
  toggleManageModal: (field: TreatmentField) => Promise<void>
  refetchRadiations: () => void
  refetchSurgeries: () => void
  refetchTreatments: () => void
}

/**
 *
 *
 */
export function setup(): PatientTreatmentViewModel {
  const patientId = requiredInject(PATIENT_ID_KEY)

  const { datum: clinicalSummary } = storeToRefs(usePatientClinicalSummaryApi())
  const {
    data: treatments,
    isLoading: isLoadingTreatments,
    queryMetadata: treatmentMetadata,
  } = storeToRefs(useTreatmentApi())
  const {
    data: surgeries,
    isLoading: isLoadingSurgeries,
    queryMetadata: surgeryMetadata,
  } = storeToRefs(useSurgeryApi())
  const {
    data: radiations,
    isLoading: isLoadingRadiations,
    queryMetadata: radiationMetadata,
  } = storeToRefs(useRadiationApi())

  const { data: lastUpdatedTreatment } = storeToRefs(
    useLastUpdatedTreatmentApi()
  )
  const { data: lastUpdatedRadiation } = storeToRefs(useLastUpdatedSurgeryApi())
  const { data: lastUpdatedSurgery } = storeToRefs(useLastUpdatedRadiationApi())

  const cancerDetailsId = computed(
    () => clinicalSummary.value?.cancerDiagnosis?.cancerDiagnosisId ?? null
  )
  const treatmentsArray = computed(() => Object.values(treatments.value ?? {}))
  const surgeriesArray = computed(() => Object.values(surgeries.value ?? {}))
  const radiationsArray = computed(() => Object.values(radiations.value ?? {}))

  const isLoading = computed(
    () =>
      isLoadingTreatments.value ||
      isLoadingSurgeries.value ||
      isLoadingRadiations.value
  )

  const pageNumber = ref(1)

  const showManageTreatmentModal = ref(false)
  const showManageSurgeryModal = ref(false)
  const showManageRadiationsModal = ref(false)

  const totalTreatmentCount = computed(
    () => treatmentMetadata.value?.total ?? 0
  )
  const totalSurgeryCount = computed(() => surgeryMetadata.value?.total ?? 0)
  const totalRadiationCount = computed(
    () => radiationMetadata.value?.total ?? 0
  )

  const lastUpdatedDisplay = computed(() => {
    const lastUpdateds = [
      ...(lastUpdatedTreatment.value ?? []),
      ...(lastUpdatedSurgery.value ?? []),
      ...(lastUpdatedRadiation.value ?? []),
    ]

    let latest
    if (lastUpdateds.length) {
      const lastUpdated = lastUpdateds.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )[0]
      latest = formatLastUpdatedStr(lastUpdated)
    }
    return latest ?? '-'
  })

  const treatmentDisplay = computed(() => {
    const patientTreatments = treatmentsArray.value
    if (patientTreatments.length) {
      // sort treatments alphabetically
      // format treatment agent and brand into string array for display
      const formatted = patientTreatments
        .map((treatment) => {
          if (treatment.otherTreatmentType) {
            return {
              agent: `Other: ${treatment.otherTreatmentType}`,
              brands: 'N/A', // Assuming no brand information for this case
            }
          }
          const oncSystematicTherapyRef =
            treatment.oncologySystematicTherapyReference

          const currentOrPrev = treatment.treatmentEndDate
            ? 'previous'
            : treatment.treatmentStartDate
            ? 'current'
            : 'unknown'
          if (oncSystematicTherapyRef) {
            return {
              agent: `${oncSystematicTherapyRef.agent} (${currentOrPrev})`,
              brands: `${
                oncSystematicTherapyRef.brandNames.length
                  ? oncSystematicTherapyRef.brandNames.join(', ')
                  : 'N/A'
              }`,
            }
          }
          return {
            agent: 'N/A',
            brands: 'N/A',
          }
        })
        .sort((a, b) => a.agent.localeCompare(b.agent))
      return formatted
    }
    return [
      {
        agent: '-',
        brands: '-',
      },
    ]
  })

  const surgeryDisplay = computed(() => {
    const patientSurgeries = surgeriesArray.value
    if (patientSurgeries.length) {
      return patientSurgeries.map((surgery) => {
        const surgicalProcedure = surgery.surgicalProcedureReference
          ? surgery.surgicalProcedureReference.description
          : surgery.otherSurgeryType
        const surgeryDate = surgery.surgeryDate
          ? `(${stringToDateTime(surgery.surgeryDate).toLocaleString(
              DateTime.DATE_SHORT
            )})`
          : ''
        return `${surgicalProcedure} ${surgeryDate} `
      })
    }
    return ['-']
  })

  const radiationDisplay = computed(() => {
    const patientRadiation = radiationsArray.value
    if (patientRadiation.length) {
      return patientRadiation.map((rad) => ({
        radDate: toDateDisplay(rad.radiationDate),
        additionalInfo: rad.additionalInformation ?? '-',
      }))
    }

    return [
      {
        radDate: '-',
        additionalInfo: '-',
      },
    ]
  })

  const showMoreTreatmentsButton = computed(
    () => treatmentDisplay.value.length < totalTreatmentCount.value
  )
  const showMoreSurgeriesButton = computed(
    () => surgeryDisplay.value.length < totalSurgeryCount.value
  )
  const showMoreRadiationDatesButton = computed(
    () => radiationDisplay.value.length < totalRadiationCount.value
  )

  // fetch 3 more treatments, surgeries, or radiation dates
  const loadMore = async (field: string) => {
    if (!cancerDetailsId.value) {
      return
    }
    pageNumber.value = pageNumber.value + 1
    if (field === TreatmentField.TREATMENT) {
      await getPatientTreatmentWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      return
    } else if (field === TreatmentField.SURGERY) {
      await getPatientSurgeryWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      return
    } else if (field === TreatmentField.RADIATION) {
      await getPatientRadiationWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
    }
  }

  /**
   *
   * refetch radiations
   */
  async function refetchRadiations() {
    pageNumber.value = 1
    if (cancerDetailsId.value) {
      await getPatientRadiationWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      await toggleManageModal(TreatmentField.RADIATION)
    }
  }

  /**
   *
   * refetch surgeries
   */
  async function refetchSurgeries() {
    pageNumber.value = 1
    if (cancerDetailsId.value) {
      await getPatientSurgeryWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      await toggleManageModal(TreatmentField.SURGERY)
    }
  }

  /**
   *
   * refetch treatments
   */
  async function refetchTreatments() {
    pageNumber.value = 1
    if (cancerDetailsId.value) {
      await getPatientTreatmentWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      await toggleManageModal(TreatmentField.TREATMENT)
    }
  }

  /**
   *
   * @param field
   */
  async function toggleManageModal(field: TreatmentField) {
    if (
      showManageTreatmentModal.value ||
      showManageSurgeryModal.value ||
      showManageRadiationsModal.value
    ) {
      await getAllLastUpdated()
    }

    if (field === TreatmentField.TREATMENT) {
      showManageTreatmentModal.value = !showManageTreatmentModal.value
    }
    if (field === TreatmentField.SURGERY) {
      showManageSurgeryModal.value = !showManageSurgeryModal.value
    }
    if (field === TreatmentField.RADIATION) {
      showManageRadiationsModal.value = !showManageRadiationsModal.value
    }
  }

  /**
   *
   */
  function resetRadiationStores() {
    useRadiationApi().data = {}
    useRadiationApi().datum = null
    useRadiationApi().queryMetadata = null
    usePreviousCancerRadiationApi().data = null
  }

  /**
   *
   */
  function resetSurgeryStores() {
    useSurgeryApi().data = {}
    useSurgeryApi().datum = null
    useSurgeryApi().queryMetadata = null
    usePreviousCancerSurgeriesApi().data = null
    useMapOncDxRefSurgicalProcedureRefApi().data = null
  }

  /**
   *
   */
  function resetTreatmentStores() {
    useTreatmentApi().data = {}
    useTreatmentApi().datum = null
    useTreatmentApi().queryMetadata = null
    usePreviousCancerTreatmentApi().data = null
    useMapOncDxRefOncTherapyRefApi().data = null
  }

  /**
   *
   */
  function resetAllStores() {
    resetRadiationStores()
    resetSurgeryStores()
    resetTreatmentStores()
  }

  /**
   *
   */
  async function getAllLastUpdated() {
    if (cancerDetailsId.value) {
      await getLastUpdatedPatientRadiation(cancerDetailsId.value)
      await getLastUpdatedPatientSurgery(cancerDetailsId.value)
      await getLastUpdatedPatientTreatment(cancerDetailsId.value)
    } else {
      useLastUpdatedRadiationApi().data = null
      useLastUpdatedSurgeryApi().data = null
      useLastUpdatedTreatmentApi().data = null
    }
  }

  onBeforeMount(async () => {
    // clear store to remove other patient data
    resetAllStores()
    await getAllLastUpdated()
    if (cancerDetailsId.value) {
      await getPatientTreatmentWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      await getPatientSurgeryWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
      await getPatientRadiationWithParts(
        cancerDetailsId.value,
        PAGE_LENGTH,
        pageNumber.value
      )
    }
  })

  return {
    loadMore,
    patientId,
    // enum
    TreatmentField,
    // display values
    treatmentDisplay,
    radiationDisplay,
    surgeryDisplay,
    lastUpdatedDisplay,
    isLoading,
    // show more buttons
    showMoreTreatmentsButton,
    showMoreSurgeriesButton,
    showMoreRadiationDatesButton,
    //  manage modal
    toggleManageModal,
    showManageTreatmentModal,
    showManageSurgeryModal,
    showManageRadiationsModal,
    cancerDetailsId,
    refetchRadiations,
    refetchSurgeries,
    refetchTreatments,
  }
}
