<template>
  <div>
    <h1>H1 Header The quick brown fox jumps over the lazy dog</h1>
    <p>use for Page Header and Top Left Name of Details Page(s)</p>
    <br />
    <h2>H2 Header The quick brown fox jumps over the lazy dog</h2>
    <p>use for Component Headers and section headers</p>
    <br />
    <h3>H3 Header The quick brown fox jumps over the lazy dog</h3>
    <p>use sparingly (deprecated?)</p>
    <br />
    <h4>H4 Header The quick brown fox jumps over the lazy dog</h4>
    <p>use for sub-section headers and card titles</p>
    <br />
    <h5>H5 Header we don't use h5 tags</h5>
    <br />
    <h6>H6 Header we don't use h6 tags</h6>
    <br />
    <p>Standard Text The quick brown fox jumps over the lazy dog</p>
    <p>Use for everything else</p>
  </div>
</template>
