import apiStore from '@/legacy/store/modules/apiBuilder'
import { Appointment } from '@/legacy/types/patients/appointments'

export const useAppointmentsApi = apiStore<Appointment>(
  'appointmentsApi',
  '/api/patients/appointments',
  {
    transformData: (d: { data: Appointment[] }) => d,
  }
)
