import type { UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

// TYPES --------------------
export type Insurance = {
  entityId: UUID
  insuranceId: UUID
  groupId: UUID
  memberId: UUID
  payerId: UUID
  planType: string
  isPrimary: boolean
  isActive: boolean
  planName: string
  isManuallyAdded: boolean
  otherInsuranceName: string
  marketSegment: string
  networkTier: string
} & Timestamps

export type UpsertInsuranceRequest = Pick<
  Insurance,
  (typeof updateInsuranceFields)[number]
>

export type UpsertInsuranceResponse = Insurance

export type Payer = {
  payerId: UUID
  payerName: string
  isThymeboxVisible: boolean
  displayName: string | null
} & Timestamps

// ENUMS --------------------
export enum InsuranceLabelName {
  clover_eligible = 'Clover - eligible',
}

// INTERFACES --------------------
export interface AddInsuranceForm {
  payerId: string
  memberId: string
  groupId: string
  type: string
  status: string
  otherInsuranceName: string
  marketSegment: string
  networkTier: string
}

// ENUMS --------------------
export enum MarketSegment {
  Consumer = 'CONSUMER',
  Group = 'GROUP',
}

// CONSTANTS --------------------
export const updateInsuranceFields = [
  'payerId',
  'memberId',
  'groupId',
  'otherInsuranceName',
  'marketSegment',
  'networkTier',
] as const
