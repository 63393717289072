import { DateTime } from 'luxon'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import {
  OncologyDiagnosisCategoryReference,
  OncologyDiagnosisReference,
  OncologySpreadReference,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import { useCancerDetailApi } from '../shared/store'
import { ONCOLOGY_REFERENCES_PAGE_LENGTH } from '../shared/types'
import {
  useOncologyDiagnosisCategoryReferenceApi,
  useOncologyDiagnosisReferenceApi,
  useOncologySpreadReferenceApi,
} from './store'
import { EditCancerDetailsForm } from './types'

/**
 *
 * @param cancerDetailId
 * @param dirtyData
 */
export async function updatePatientCancerDiagnosis(
  cancerDetailId: string,
  dirtyData: EditCancerDetailsForm
) {
  let upserted
  const body = {
    ...dirtyData,
    ...{
      ...(dirtyData.diagnosisDate
        ? {
            diagnosisDate: DateTime.fromJSDate(
              dirtyData.diagnosisDate
            ).toISODate(),
          }
        : {}),
    },
  }
  try {
    upserted = await useCancerDetailApi().partialUpdate({
      ids: [cancerDetailId],
      body: body,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update patient cancer diagnosis.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated patient cancer diagnosis.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 *
 * @param patientId
 * @param dirtyData
 */
export async function createPatientCancerDiagnosis(
  patientId: string,
  dirtyData: EditCancerDetailsForm
) {
  let upserted
  const body = {
    ...dirtyData,
    ...{
      ...(dirtyData.diagnosisDate
        ? {
            diagnosisDate: DateTime.fromJSDate(
              dirtyData.diagnosisDate
            ).toISODate(),
          }
        : {}),
    },
  }
  try {
    upserted = await useCancerDetailApi().create({
      body: { patientId: patientId, ...body },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update patient cancer diagnosis.',
      type: NotificationType.DANGER,
    })
    return null
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated patient cancer diagnosis.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 * Retrieve cancer spread options
 * Returns null if no data is returned.
 */
export async function getCancerSpread(): Promise<
  OncologySpreadReference[] | null
> {
  const oncologySpreadRefApi = useOncologySpreadReferenceApi()
  const response = await oncologySpreadRefApi.list({
    params: {
      page_length: ONCOLOGY_REFERENCES_PAGE_LENGTH,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (oncologySpreadRefApi.error) {
    const error = oncologySpreadRefApi.error
    useNotificationStore().setNotification({
      message: `Error fetching cancer spread references`,
      type: NotificationType.WARNING,
      error: `Error fetching cancer spread references: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve oncology diagnosis reference by oncologyDiagnosisCategoryRefId
 * Returns null if no data is returned.
 * @param oncologyDiagnosisCategoryRefId
 */
export async function getOncologyDiagnosisReferenceByCategoryRefId(
  oncologyDiagnosisCategoryRefId: string
): Promise<OncologyDiagnosisReference | null> {
  const oncologyDiagnosisRefApi = useOncologyDiagnosisReferenceApi()
  const response = await oncologyDiagnosisRefApi.list({
    params: {
      filter_oncology_diagnosis_categories_ref_ids: [
        oncologyDiagnosisCategoryRefId,
      ],
      page_length: ONCOLOGY_REFERENCES_PAGE_LENGTH,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (oncologyDiagnosisRefApi.error) {
    const error = oncologyDiagnosisRefApi.error
    useNotificationStore().setNotification({
      message: `Error fetching oncology diagnosis references `,
      type: NotificationType.WARNING,
      error: `Error fetching oncology diagnosis references: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve cancer type options
 * Returns null if no data is returned.
 */
export async function getOncologyDiagnosisCategoryReference(): Promise<OncologyDiagnosisCategoryReference | null> {
  const oncologyDiagnosisCateogryRefApi =
    useOncologyDiagnosisCategoryReferenceApi()
  const response = await oncologyDiagnosisCateogryRefApi.list({
    params: {
      page_length: ONCOLOGY_REFERENCES_PAGE_LENGTH,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (oncologyDiagnosisCateogryRefApi.error) {
    const error = oncologyDiagnosisCateogryRefApi.error
    useNotificationStore().setNotification({
      message: `Error fetching oncology diagnosis category references `,
      type: NotificationType.WARNING,
      error: `Error fetching oncology diagnosis category references: ${error}`,
    })
  }
  return response.data
}
