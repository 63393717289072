import { DialOptions } from '@/legacy/types/entities/phoneNumbers'

/**
 * Open zoom call for phone number
 * @param param0 Object with phone number inside
 * @param param0.phoneNumber
 * @param param0.outboundNumber
 * @returns void
 */
export async function dial({ phoneNumber, outboundNumber }: DialOptions) {
  console.debug('Opening call via zoom')
  // @ts-ignore
  if (window.Cypress) {
    return
  }
  const outboundNumberStr = outboundNumber
    ? `callerid=+${outboundNumber.countryCode ?? '1'}${
        outboundNumber.phoneNumber
      }`
    : ''

  window.open(`zoomphonecall://${phoneNumber}?${outboundNumberStr}`)
  return
}
