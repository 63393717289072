import { map, difference } from 'lodash'
import { useOncologyTreatmentRefStore } from '@/legacy/store/modules/oncologyTreatmentsReferences'
import {
  OncologyTreatment,
  OncologyTreatmentReference,
} from '@/legacy/types/patients/oncologyTreatments'

/**
 *
 * Function to get oncology treatment ref IDs that are not present in the store
 * @param data
 * @param oncologyTreatmentCatalog
 */
export function getOncRefIdsToFetchFromCatalog(
  data: OncologyTreatment[],
  oncologyTreatmentCatalog: OncologyTreatmentReference[]
) {
  const refIds = map(data, 'oncologyRefId')
  const storeRefIds = Object.keys(oncologyTreatmentCatalog ?? {})
  return difference(refIds, storeRefIds)
}

/**
 * Helper function to grab oncology treatment ref IDs
 * from a list of patient oncology treatments
 * @param data
 */
export function getOncRefIds(data: OncologyTreatment[]) {
  return Object.values(data).map(
    (treatment) => treatment.oncologyTreatmentRefId
  )
}

/**
 *
 * Function to fetch oncology treatment references for patient oncology treatments
 * passed as params
 * @param data
 */
export function fetchOncReferences(data: OncologyTreatment[]) {
  if (data.length) {
    const refIdsToBeFetched = getOncRefIds(data)
    if (refIdsToBeFetched) {
      void useOncologyTreatmentRefStore().fetchOncologyTreatmentRefs(
        refIdsToBeFetched
      )
    }
  }
}
