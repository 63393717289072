import {
  DIARRHEA_PATHWAY_NAME,
  Pathway,
  PathwayTemplate,
} from '@/legacy/types/pathways/pathways'

/**
 *
 * @param pathway
 * Pathway variants will no longer display in the ui except for Diarrhea pathway
 */
export function getVariantDisplay(pathway: Pathway | PathwayTemplate) {
  if (
    pathway.title &&
    pathway.title.toLowerCase() === DIARRHEA_PATHWAY_NAME.toLowerCase()
  ) {
    return `(${pathway.variantDisplay})`
  }
  return ''
}
