<template>
  <RadioButton
    :class="radioClasses"
    :data-cy="name && `${kebabCase(name)}-radiobutton`"
    v-bind="$attrs"
    @click="(e: any) => $emit('click', e)"
  />
</template>

<script lang="ts">
import { paddingProp } from '@thyme/nashville/src/types/shared'
import kebabCase from 'lodash/kebabCase'
import RadioButton from 'primevue/radiobutton'
import { computed, defineComponent } from 'vue'
import { tokenizedComponentClasses } from '../designTokens'

const sharedProps = {
  ...paddingProp,
}

export default defineComponent({
  components: {
    RadioButton,
  },
  props: {
    ...sharedProps,
    name: {
      type: String,
      default: null,
    },
  },
  emits: ['click'],
  setup(props) {
    const tokenClasses = tokenizedComponentClasses(
      'button',
      props,
      Object.keys(sharedProps)
    )
    const radioClasses = computed(() => [...tokenClasses.value])

    return {
      radioClasses,
      kebabCase,
    }
  },
})
</script>
