import { PropType } from 'vue'
import { UUID } from '@/legacy/types/global/base'

export type InsuranceDisplayModel = {
  // required
  isActive: boolean
  isPrimary: boolean
  memberId: UUID
  payerId: UUID

  // not required
  groupId?: UUID
  planType?: string
  planName?: string
  otherInsuranceName?: string
  marketSegment?: string
  networkTier?: string

  // for loop iterator key
  insuranceId?: string

  // from Payer model
  payerName: string
}

export const INSURANCE_DISPLAY_PROPS = {
  insurance: {
    type: Object as PropType<InsuranceDisplayModel>,
    required: true,
  },
} as const
