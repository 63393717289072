import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { Address } from '@/legacy/types/entities/addresses'

export const useAddressesApi = apiStore<Address, IdMap<Address>>(
  'addressesApi',
  '/api/entities/addresses',
  {
    transformData: (d: { data: Address[] }) =>
      idMapTransform({}, 'data', 'addressId', d.data),
  }
)
