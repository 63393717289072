import { defineStore } from 'pinia'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { IdMap } from '@/legacy/types/api/store'
import { NotificationType } from '@/legacy/types/notifications'
import { Provider } from '@/legacy/types/providers'

export const useProviderStore = defineStore('provider', {
  state: () => ({}),
  getters: {},
  actions: {
    async fetchProviders(practiceId: string | null = null) {
      try {
        await useProvidersApi().list({
          params: practiceId ? { filter_practice_ids: [practiceId] } : {},
        })
      } catch (err) {
        if (err instanceof Error) {
          console.error(err)
        }
        useNotificationStore().setNotification({
          message: 'Failed to fetch providers.',
          type: NotificationType.DANGER,
        })
        return
      }
    },
  },
})

export const useProvidersApi = apiStore<Provider[], IdMap<Provider>>(
  'providersApi',
  '/api/providers',
  {
    transformData: (d: { data: Provider[] }) =>
      idMapTransform({}, 'data', 'entityId', d.data),
    params: { parts: ['practice_maps'] },
  }
)

export const useProvidersTableApi = apiStore<Provider[], IdMap<Provider>>(
  'providersTableApi',
  '/api/providers',
  {
    transformData: (d: { data: Provider[] }) =>
      idMapTransform({}, 'data', 'entityId', d.data),
  }
)
