<template>
  <div>
    <TSpinner v-if="isLoading" name="patient-medical" :partial-page="true" />

    <div v-show="!isLoading && !hasError">
      <div class="space-y-4">
        <div>
          <h5 class="pb-1 mt-5">Active Documents</h5>
          <TTable
            id="active-documents"
            ref="activeTable"
            name="ActiveDocuments"
            class="px-0"
            sort-field="createdAt"
            :sort-order="SORT_DESCENDING"
            :rows="perPage"
            :columns="activeColumns"
            :api-store="useDocumentApi"
            :params="{
              filter_patient_ids: [patientId],
              filter_statuses: [
                DocumentStatus.UPLOADED,
                DocumentStatus.UPLOAD_REQUESTED,
              ],
              parts: ['document_category'],
            }"
          >
            <template #column-meatballMenu="slotProps">
              <TOIconDropdown
                icon="meatball"
                name="active-document-options"
                :options="activeOptions"
                @change="openAction($event.value, slotProps.row)"
              />
            </template>
            <template #headerEnd>
              <TMSecondaryButton
                label="+ Upload"
                name="add-document"
                @click="showUploadModal = true"
              />
            </template>
            <template #column-docName="slotProps">
              {{ formatDocName(slotProps.row) }}
            </template>
          </TTable>
        </div>
        <a class="flex gap-2 font-bold" @click="showArchive = !showArchive">
          Show Archived
          <TIcon :icon="showArchive ? 'chevronUp' : 'chevronDown'" />
        </a>
        <div v-if="showArchive">
          <h5 class="pb-1 mt-5">Archived Documents</h5>
          <TTable
            id="archived-documents"
            ref="archiveTable"
            name="ArchivedDocuments"
            class="px-0"
            sort-field="createdAt"
            :sort-order="SORT_DESCENDING"
            :rows="perPage"
            :columns="archiveColumns"
            :api-store="useArchivedDocumentApi"
            :params="{
              filter_patient_ids: [patientId],
              filter_statuses: [DocumentStatus.ARCHIVED],
              parts: ['document_category'],
            }"
          >
            <template #column-docName="slotProps">
              {{ formatDocName(slotProps.row) }}
            </template>
            <template #column-meatballMenu="slotProps">
              <TOIconDropdown
                icon="meatball"
                name="archive-document-options"
                :options="archiveOptions"
                @change="openAction($event.value, slotProps)"
              />
            </template>
          </TTable>
        </div>
      </div>
    </div>
    <div v-if="hasError">Error loading document Thymeline.</div>
    <TModal
      :is-visible="actionModalActive === 'rename'"
      :size="ModalSize.MD"
      title="Rename File"
      @close="closeAction"
    >
      <DocumentCategorySearch
        name="rename-search"
        :options="documentCategoryOptions"
        :selected-category="selectedCategory"
        :selected-name="selectedName"
        @update="updateRename"
      />
      <template #actions>
        <TMSecondaryButton label="Cancel" @click="closeAction" />
        <LegacyTButton value="Save" @click="renameDocument" />
      </template>
    </TModal>
    <TModal
      :is-visible="actionModalActive === 'unarchive'"
      :size="ModalSize.MD"
      title="Unarchive File"
      @close="closeAction"
    >
      <span>Are you sure you want to unarchive this file?</span>
      <template #actions>
        <TMSecondaryButton label="Cancel" @click="closeAction" />
        <LegacyTButton
          value="Unarchive"
          type="danger"
          @click="unarchiveDocument"
        />
      </template>
    </TModal>
    <TModal
      :is-visible="actionModalActive === 'archive'"
      :size="ModalSize.MD"
      title="Archive File"
      @close="closeAction"
    >
      <span
        >Are you sure you want to archive this file? Please describe why this
        file is no longer relevant.</span
      >
      <TTextarea
        v-model="dirtyArchiveReason"
        name="edit-document-archive-reason"
        label=" "
        placeholder="Enter comments"
      />
      <div v-if="archiveReasonRequiredError" class="text-nash-brick600">
        Archive reason is required
      </div>
      <template #actions>
        <TMSecondaryButton label="Cancel" @click="closeAction" />
        <LegacyTButton value="Archive" type="danger" @click="archiveDocument" />
      </template>
    </TModal>
    <!-- <TModal
      :is-visible="actionModalActive === 'viewActivity'"
      size="md"
      title="View File History"
      @close="closeAction"
    >
      <DocumentHistory
        v-for="(history, index) in activeDocumentHistory"
        :key="history.documentHistoryId"
        :type="history.activityType"
        :old-value="history.oldValue"
        :new-value="history.newValue"
        :created-by="history.createdBy"
        :created-at="history.createdAt"
        :last="index === activeDocumentHistory.length - 1"
      />
    </TModal> -->
    <UploadModal
      :is-visible="showUploadModal"
      @close="showUploadModal = false"
      @save="uploadFile($event)"
    />
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { InputType } from '@thyme/nashville/src/types/inputs'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import {
  ColumnOptions,
  SORT_DESCENDING,
} from '@thyme/nashville/src/types/tables'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref } from 'vue'

import { useRoute } from 'vue-router'
import DocumentCategorySearch from '@/legacy/components/DocumentCategorySearch.vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime, formatNameFromEntity } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TTable from '@/legacy/nashville/table/TTable.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import {
  useDocumentApi,
  useArchivedDocumentApi,
  useDocumentStore,
  useDocumentCategoryApi,
} from '@/legacy/store/modules/documents'
import { useStaffApi } from '@/legacy/store/modules/staff'
import {
  DocumentCategory,
  Document,
  DocumentStatus,
} from '@/legacy/types/documents'
import UploadModal from '../UploadModal.vue'
// import DocumentHistory from './DocumentHistory.vue'

export default defineComponent({
  components: {
    TSpinner,
    TTextarea,
    LegacyTButton,
    TOIconDropdown,
    TModal,
    TMSecondaryButton,
    // DocumentHistory,
    TIcon,
    UploadModal,
    DocumentCategorySearch,
    TTable,
  },
  setup() {
    const activeTable = ref<{ getData: () => object } | null>(null)
    const archiveTable = ref<{ getData: () => object } | null>(null)
    const perPage = 5
    const { data: documentCategories } = storeToRefs(useDocumentCategoryApi())
    const { data: staff } = storeToRefs(useStaffApi())
    const route = useRoute()
    const archiveReasonRequiredError = ref(false)

    const documentCategoryOptions = computed(() =>
      documentCategories.value?.length
        ? documentCategories.value.map(
            ({ documentCategoryId, displayName }: DocumentCategory) => [
              documentCategoryId,
              displayName,
            ]
          )
        : []
    )

    onMounted(() => {
      void useDocumentCategoryApi().list({})
    })

    const patientId = route.params.patientId

    const staffName = (staffId: string): string =>
      formatNameFromEntity(safeLookup(staffId, staff.value)) || 'Unknown Staff'

    const formatDocName = (doc: Document): string =>
      `${doc.documentCategory?.displayName ?? 'Other'}${
        doc.name ? `: ${doc.name}` : ''
      }`

    const nameColumn = {
      field: 'docName',
      header: 'Name',
    }
    const uploadedAtColumn = {
      field: 'createdAt',
      header: 'Uploaded At',
      display: (createdAt: string) =>
        formatDateTime(stringToDateTime(createdAt)),
      sortable: true,
    }
    const meatballMenuColumn = {
      field: 'meatballMenu',
      header: '',
      style: { width: '5rem' },
    }
    const uploadedByColumn = {
      field: 'createdBy',
      header: 'Uploaded By',
      display: (createdBy: string) => staffName(createdBy),
      sortable: true,
    }
    const activeColumns: ColumnOptions[] = [
      nameColumn,
      uploadedAtColumn,
      uploadedByColumn,
      meatballMenuColumn,
    ]
    const archiveColumns: ColumnOptions[] = [
      nameColumn,
      {
        header: 'Archived At',
        field: 'archivedAt',
        display: (archivedAt: string) =>
          formatDateTime(stringToDateTime(archivedAt)),
        sortable: true,
      },
      uploadedAtColumn,
      uploadedByColumn,
      meatballMenuColumn,
    ]

    const activeOptions = [
      { label: 'Rename', value: 'rename' },
      { label: 'Archive', value: 'archive' },
      { label: 'Download', value: 'download' },
    ]

    const archiveOptions = [
      { label: 'Rename', value: 'rename' },
      { label: 'Unarchive', value: 'unarchive' },
      { label: 'Download', value: 'download' },
    ]

    const actionModalActive = ref<string | null>(null)
    const activeDocumentId = ref<string | null>(null)

    // implement when document history api is ready
    // const activeDocumentHistory = ref<DocumentHistoryType[] | null>(
    //   documentHistory
    // )

    const openAction = (action: string, document: Document) => {
      if (action === 'download') {
        void downloadDocument(document)
      } else {
        actionModalActive.value = action
        activeDocumentId.value = document.documentId
        selectedName.value = document.name
        selectedCategory.value = document.documentCategoryId
        dirtyArchiveReason.value = document.archivedReasonNote ?? ''
      }
    }

    const refreshData = () => {
      void activeTable.value?.getData()
      void archiveTable.value?.getData()
    }

    const closeAction = () => {
      actionModalActive.value = null
      activeDocumentId.value = null
      showUploadModal.value = false
      selectedName.value = ''
      selectedCategory.value = ''
      refreshData()
    }

    const isLoading = false
    const hasError = false

    const showArchive = ref(false)
    const showUploadModal = ref(false)
    const selectedName = ref<string>('')
    const selectedCategory = ref<string>('')

    const dirtyArchiveReason = ref('')

    const updateRename = (event: { [key: string]: string }) => {
      selectedName.value = event.name
      selectedCategory.value = event.category
    }

    const renameDocument = async () => {
      await useDocumentApi().partialUpdate({
        ids: [activeDocumentId.value as string],
        body: {
          documentCategoryId: useDocumentStore().getDocumentCategoryId(
            selectedCategory.value
          ),
          name: selectedName.value,
        },
      })
      closeAction()
    }

    const archiveDocument = async () => {
      if (!dirtyArchiveReason.value) {
        archiveReasonRequiredError.value = true
      } else {
        await useDocumentApi().partialUpdate({
          ids: [activeDocumentId.value as string],
          body: {
            status: DocumentStatus.ARCHIVED,
            archivedReasonNote: dirtyArchiveReason.value,
          },
        })
        closeAction()
      }
    }
    const unarchiveDocument = async () => {
      await useDocumentApi().partialUpdate({
        ids: [activeDocumentId.value as string],
        body: {
          status: DocumentStatus.UPLOADED,
        },
      })
      closeAction()
    }

    const downloadDocument = async (document: Document) =>
      await useDocumentStore().downloadFile(document)

    const uploadFile = async ({
      name,
      documentCategoryId,
      file,
    }: {
      name: string
      documentCategoryId: string
      file: File
    }) => {
      await useDocumentStore().startUpload(
        patientId as string,
        documentCategoryId,
        name,
        file
      )
      refreshData()
      closeAction()
    }

    return {
      ModalSize,
      perPage,
      activeColumns,
      activeOptions,
      archiveColumns,
      archiveOptions,
      archiveReasonRequiredError,
      isLoading,
      hasError,
      showArchive,
      showUploadModal,
      actionModalActive,
      // activeDocumentHistory,
      selectedCategory,
      formatDocName,
      selectedName,
      dirtyArchiveReason,
      documentCategoryOptions,
      closeAction,
      openAction,
      renameDocument,
      archiveDocument,
      unarchiveDocument,
      uploadFile,
      updateRename,
      InputType,
      useDocumentApi,
      useArchivedDocumentApi,
      patientId,
      SORT_DESCENDING,
      activeTable,
      archiveTable,
      DocumentStatus,
    }
  },
})
</script>
