<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="isVisible"
      title="Edit Medical Details"
      :size="ModalSize.SM"
      @close="close"
    >
      <label class="font-bold"> Practice </label>
      <TDropdown
        v-model="dirtyPractice"
        class="w-full"
        input-id="practice"
        option-label="label"
        option-value="value"
        :options="getAllPractices()"
        @update:model-value="
          (v: any) => {
            dirtyPractice = v
            setDropdownValue()
          }
        "
      />
      <label class="font-bold"> Provider </label>
      <TDropdown
        v-model="dirtyProvider"
        class="w-full"
        input-id="provider"
        option-label="label"
        option-value="value"
        :options="getAllProviders()"
        @update:model-value="
          (v: any) => {
            dirtyProvider = v
            setDropdownValue()
          }
        "
      />
      <div>
        <TMTertiaryButton
          name="practiceProvider-reset"
          label="Reset Practice and Provider"
          @click="
            () => {
              dirtyPractice = ''
              dirtyProvider = ''
            }
          "
        />
      </div>
      <template #actions>
        <TMPrimaryButton
          name="practiceProvider-save"
          label="Confirm"
          @click="
            () => {
              updatePatient({
                alignedProvider: dirtyProvider,
                alignedPractice: dirtyPractice,
              })
            }
          "
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'

import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import setup from './controller'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TModal,
    TMTertiaryButton,
    TDropdown,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup,
})
</script>
