import values from 'lodash/values'
import { computed } from 'vue'

import { formatNameFromEntity } from '@/legacy/libs/format'

import { useStaffApi } from '@/legacy/store/modules/staff'

/**
 * staff Entity related variables
 * IMPROVEME (MT-2643) replace with TOStaffAssignDropdown
 */
export function useStaffVars() {
  const staffApi = useStaffApi()

  const staffIdOptions = computed(() =>
    values(staffApi.data ?? {})
      .map((staff) => {
        return { value: staff.entityId, label: formatNameFromEntity(staff) }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  )

  return {
    staffIdOptions,
  }
}
