import apiStore from '@/legacy/store/modules/apiBuilder'
import { VisitNote, VisitSummary } from '@/legacy/types/patients/visitNotes'

// IMPROVEME (MT-2675) VisitNotes list being used as retrieve
export const useVisitNotesApi = apiStore<VisitNote, VisitNote>(
  'visitNotesApi',
  '/api/patients/visit_notes'
)

// IMPROVEME (MT-2675) VisitSummary list being used as retrieve
export const useVisitSummariesApi = apiStore<VisitSummary, VisitSummary>(
  'visitSummariesApi',
  '/api/patients/visit_notes/summaries'
)
