<template>
  <div class="flex">
    <TIcon icon="documentPlus" color="nash-brick600" />
    <b>Transition Of Care: {{ formatType(toc) }}</b>
  </div>
  <div class="ml-5">
    <div class="text-sm text-nash-neutral700 mb-2">
      {{ formatDatetime(toc.transitionDate, patientTimezone) }}
    </div>
    <div>
      <div v-if="toc.primaryDiagnosisCode">
        <i>Primary Diagnosis</i>:
        {{
          toc.primaryDiagnosisDescription
            ? startCase(toc.primaryDiagnosisDescription)
            : ''
        }}
        ({{ toc.primaryDiagnosisCode }})
      </div>
      <div v-if="toc.subsequentDiagnosisCodes">
        <i>Subsequent Diagnoses</i>:
        <span v-for="(code, idx) in toc.subsequentDiagnosisCodes" :key="code">
          {{
            idx === toc.subsequentDiagnosisCodes.length - 1 ? code : code + ', '
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import startCase from 'lodash/startCase'
import { storeToRefs } from 'pinia'
import { PropType, defineComponent, ref } from 'vue'
import {
  formatType,
  formatDatetime,
} from '@/legacy/components/patient/transitionOfCare/lib/transitionOfCare'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { TransitionOfCare } from '@/legacy/types/patients/transitionsOfCare'

export default defineComponent({
  components: {
    TIcon,
  },
  props: {
    toc: {
      type: Object as PropType<TransitionOfCare>,
      required: true,
    },
  },
  setup() {
    const { patient } = storeToRefs(usePatientStore())
    const patientTimezone = ref<string>(patient.value?.timezone ?? '')

    return {
      formatType,
      formatDatetime,
      startCase,
      patientTimezone,
    }
  },
})
</script>
