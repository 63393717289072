<template>
  <div class="flex flex-col space-y-0.5">
    <p class="font-bold" data-testid="payer-name">
      {{ insurance.payerName }}
    </p>
    <div class="inline space-x-2">
      <span data-testid="insurance-primary">{{
        renderInsurancePrimaryOrSecondary
      }}</span>
      <div class="inline bullet" />
      <span :class="insuranceStatusStyle" data-testid="insurance-status">{{
        renderInsuranceStatus
      }}</span>
    </div>
    <p data-testid="insurance-name">Plan name: {{ renderInsuranceName }}</p>
    <p data-testid="lob-name">LOB: {{ renderLOB }}</p>
    <p data-testid="member-id">Member ID: {{ insurance.memberId }}</p>
    <p data-testid="market-segment">Plan Type: {{ renderMarketSegment }}</p>
    <p data-testid="network-tier">Network Tier: {{ renderNetworkTier }}</p>
    <p data-testid="group-name">Group: {{ renderGroup }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setup } from './controller'
import { INSURANCE_DISPLAY_PROPS } from './types'

export default defineComponent({
  props: INSURANCE_DISPLAY_PROPS,
  setup,
})
</script>

<style lang="scss" scoped>
.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
