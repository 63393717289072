<template>
  <div
    :class="`inline-flex space-x-2 items-center ${expanded ? 'w-1/2' : 'w-64'}`"
  >
    <PhoneDropdown
      name="comm-callee"
      :entities="callOptionEntities"
      :model-value="dirtySelectedCallee"
      :label="plannedCall ? '' : 'Speak to'"
      empty-option-label="N/A: Manual Call"
      @update:model-value="(v: any) => updateCallee(v)"
    />
    <TOCallButton
      v-if="selectedPhoneNumber && communication.communicationId"
      class="shrink-0 p-2"
      name="communication"
      :phone-number="selectedPhoneNumber.phoneNumber"
      @call="createCallDisposition()"
    />
    <div v-else class="block relative">
      <TMSecondaryButton
        :disabled="isDisabled"
        icon="dialpad"
        label="Manual Call"
        @click="togglePopover"
      />
      <OverlayPanel ref="showPopover">
        <TDialpad
          @dial="
            (phoneNumber) => {
              generateOutboundCall(phoneNumber)
              togglePopover(phoneNumber)
            }
          "
        />
      </OverlayPanel>
    </div>
  </div>
</template>
<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import OverlayPanel from 'primevue/overlaypanel'
import { defineComponent, PropType } from 'vue'

import PhoneDropdown from '@/legacy/components/patient/communicationV2/dropdowns/PhoneDropdown/PhoneDropdown.vue'
import TOCallButton from '@/legacy/nashville/button/TOCallButton.vue'
import TDialpad from '@/legacy/nashville/TDialpad.vue'
import { BaseCommunicationPayload } from '@/legacy/types/communications/communications'
import { DirtyPlannedCall } from '@/legacy/types/communications/plannedCalls'
import setup from './controller'

export default defineComponent({
  components: {
    OverlayPanel,
    TDialpad,
    TMSecondaryButton,
    PhoneDropdown,
    TOCallButton,
  },
  props: {
    plannedCall: {
      type: Object as PropType<DirtyPlannedCall>,
      default: null,
    },
    communication: {
      type: Object as PropType<BaseCommunicationPayload>,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updatePlannedCallee'],
  setup,
})
</script>
