<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    :size="ModalSize.MD"
    class="text-nash-neutral900"
    @close="close"
  >
    <TSpinner v-if="isLoading" name="bulk-edit-modal" :partial-page="true" />
    <div class="overflow-auto">
      <div class="flex justify-between">
        <div class="flex">
          <LegacyTButton
            v-if="showSelectedSubtasksView"
            class="mr-2"
            name="back-add-task-subtasks"
            icon="chevronLeft"
            type="blackAndWhite"
            :size="ButtonSize.MD"
            @click="toggleSelectedSubtasksView"
          />
          <h2>
            {{ showSelectedSubtasksView ? 'Selected Subtasks' : 'Bulk Edit' }}
          </h2>
        </div>
        <LegacyTButton
          name="close-bulk-edit-modal"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="close"
        />
      </div>
      <div>
        <TMTertiaryButton
          v-if="!showSelectedSubtasksView"
          class="pt-2"
          icon-pos="right"
          icon="chevronRight"
          :label="totalSelectedSubtasksText"
          @click="toggleSelectedSubtasksView"
        />
      </div>
      <div v-if="showSelectedSubtasksView" class="py-4">
        <div
          v-for="selectedSubtask in selectedSubtasks"
          :key="selectedSubtask.subtaskId"
          class="selected-subtask-item-wrapper"
        >
          <b>
            {{ selectedSubtask.title }}
            {{
              selectedSubtask.variantDisplay
                ? `${selectedSubtask.variantDisplay}`
                : ''
            }}
          </b>
          <div v-if="selectedSubtask.description" class="text-sm py-2">
            <SubtaskDescription :description="selectedSubtask.description" />
          </div>
          <div class="flex gap-3 mt-1 text-nash-neutral700">
            <div
              v-if="!hideSubtaskEstimatedCompletionTime"
              class="flex items-center space-x-1 gap-1"
            >
              Est: {{ selectedSubtask.estimatedMinutesToComplete ?? 0 }} min
            </div>
            <div class="bullet"></div>
            <div v-if="staff" class="flex items-center space-x-1 gap-1">
              <div>
                {{ renderRoleOrStaff(selectedSubtask, staff) }}
              </div>
            </div>
            <div class="bullet"></div>
            <div class="flex items-center space-x-1 gap-1">
              <TIcon
                :icon="getPriorityIcon(selectedSubtask.priority)"
                fill-type="neutral500"
              />
              <div>
                {{ capitalize(selectedSubtask.priority.toLowerCase()) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col w-full space-y-5 pt-5">
        <TMActionDropdown
          class="bg-nash-neutral000 min-w-fit min-w-155"
          name="bulk-edit-subtask-assignee"
          :disabled="
            isReattempt || selectedStatusObj.val || selectedRoleObj.val
          "
          :initial-value="selectedAssigneeObj"
          :options="assigneeOptions"
          option-label="label"
          option-value="val"
          :show-clear="false"
          @change="setAssigneeObj"
        />
        <TMActionDropdown
          class="bg-nash-neutral000 min-w-fit min-w-155"
          name="bulk-edit-subtask-responsible_role"
          :disabled="
            isReattempt || selectedStatusObj.val || selectedAssigneeObj.val
          "
          :initial-value="selectedRoleObj"
          :options="roleOptions"
          option-label="label"
          option-value="val"
          :show-clear="false"
          @change="setRoleObj"
        />
        <TMActionDropdown
          class="bg-nash-neutral000 min-w-fit min-w-155"
          name="bulk-edit-subtask-status"
          :disabled="selectedAssigneeObj.val || selectedRoleObj.val"
          :icon="currentStatusIcon"
          :icon-fill-type="currentStatusIconFill"
          :initial-value="selectedStatusObj"
          :options="statusOptions"
          option-label="label"
          option-value="val"
          :show-clear="false"
          @change="setStatusObj"
        />
        <TMActionDropdown
          v-if="showSubStatusDropdown && showSurfacedStatusReason"
          class="bg-nash-neutral000 min-w-fit min-w-155"
          name="bulk-edit-subtask-sub-status"
          :initial-value="selectedSubStatusObj"
          :options="cancelledSubStatusOptions"
          option-label="label"
          option-value="value"
          :show-clear="false"
          @change="setSubStatusObj"
        />
        <div v-if="isReattempt">
          <div class="flex flex-col ml-5">
            <p class="text-neutral-800 pb-2">
              {{ displayMsg }}
            </p>
            <div class="flex flex-col space-y-2">
              <TDatePicker
                v-model="reattemptDate"
                :min-date="minDateTomorrow"
                :max-date="maxReattemptDays"
                :unselectable-days-of-week="unselectableDaysOfWeek"
                :is-datetime="false"
                :placeholder="datepickerPlaceholder"
                @update:model-value="(v) => setReattemptDate(v)"
              />
              <TTextarea
                v-model="reattemptReason"
                :placeholder="reattemptReasonPlaceholder"
                name="reattempt-note"
              />
            </div>
          </div>
        </div>
        <TMActionDropdown
          v-if="!disablePriority"
          class="bg-nash-neutral000 min-w-fit min-w-155"
          :icon="currentPriorityIcon ?? undefined"
          :disabled="showSubStatusDropdown && showSurfacedStatusReason"
          name="bulk-edit-subtask-priority"
          :initial-value="selectedPriorityObj"
          :options="subtaskPriorityOptionsV2"
          option-label="label"
          option-value="val"
          :show-clear="false"
          @change="setPriorityObj"
        />
        <TTextarea
          v-model="bulkEditReason"
          :placeholder="reasonPlaceholder"
          name="bulk-edit-note"
        />
      </div>
    </div>
    <template #actions>
      <TMQuaternaryButton
        :disabled="!confirmEnabled || isLoading"
        name="reset"
        label="Reset"
        @click="clearAll"
      />
      <TMPrimaryButton
        :disabled="!confirmEnabled || isLoading"
        class="ml-4"
        name="confirm"
        label="Confirm"
        @click="confirm"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent, PropType } from 'vue'
import SubtaskDescription from '@/legacy/components/patient/pathways/SubtaskDescription.vue'

import TMActionDropdown from '@/legacy/nashville/dropdown/TMActionDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { Subtask } from '@/legacy/types/pathways/subtasks'
import setup from './controller'

export default defineComponent({
  components: {
    TSpinner,
    TMPrimaryButton,
    TMQuaternaryButton,
    TMTertiaryButton,
    TDatePicker,
    TTextarea,
    TIcon,
    TMActionDropdown,
    LegacyTButton,
    TModal,
    SubtaskDescription,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedSubtasks: {
      type: Array as PropType<Subtask[]>,
      required: true,
    },
    disablePriority: {
      type: Boolean,
      default: false,
    },
    disableReattempt: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'callbackFn', 'clearSelectedSubtasks'],
  setup,
})
</script>

<style lang="scss" scoped>
.selected-subtask-item-wrapper {
  @apply border border-nash-neutral300 py-2 px-3 w-full rounded-lg py-2 my-4;
}

.bullet:after {
  @apply text-nash-neutral700;
  content: '•';
}
</style>
