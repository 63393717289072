<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between pr-4">
      <div class="w-11/12">
        <div class="grid-container">
          <label class="t-grid-item">Admission Date</label>
          <div class="t-grid-item">
            {{ admissionDateDisplay }}
          </div>
          <label class="t-grid-item">Discharge Date</label>
          <div class="t-grid-item">
            {{ dischargeDateDisplay }}
          </div>
          <label class="t-grid-item">Event Type</label>
          <div class="t-grid-item">
            {{ eventTypeDisplay }}
          </div>
          <label class="t-grid-item">Elective Admission</label>
          <div class="t-grid-item">
            {{ hasElectiveAdmissionDisplay }}
          </div>
          <label class="t-grid-item">Discharge Diagnosis</label>
          <div class="t-grid-item">
            {{ dischargeDiagnosisDisplay }}
          </div>
          <label class="t-grid-item">Discharge Location</label>
          <div class="t-grid-item">
            {{ dischargeLocationDisplay }}
          </div>
          <label class="t-grid-item">New DME on Discharge</label>
          <div class="t-grid-item">
            {{ hasNewDmeOnDischargeDisplay }}
          </div>
          <label class="t-grid-item">Services on Discharge</label>
          <div class="t-grid-item">
            {{ servicesOnDischargeDisplay }}
          </div>
          <label class="t-grid-item">Readmission Risk</label>
          <div class="t-grid-item">
            {{ isReadmissionRiskDisplay }}
          </div>
          <label class="t-grid-item">>3 Admissions in 6 Months</label>
          <div class="t-grid-item">
            {{ moreThanThreeAdmissionsInLastSixMonthsDisplay }}
          </div>
          <label class="t-grid-item">Summary of Events</label>
          <TMarkdown
            v-if="summaryOfEventsDisplay"
            class="t-grid-item whitespace-pre-wrap"
            :source="summaryOfEventsDisplay"
          />
          <div v-else class="t-grid-item whitespace-pre-wrap">-</div>
          <label class="t-grid-item">Last update</label>
          <div class="t-grid-item">
            {{ lastUpdatedDisplay }}
          </div>
        </div>
        <TMTertiaryButton
          label="View All TOCs"
          class="pt-4 mb-3"
          icon-pos="right"
          icon="heroicons:arrow-top-right-on-square"
          @click="toggleTransitionsOfCareEdit"
        />
      </div>
      <div v-tooltip.left="'Edit transitions of care'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="toggleTransitionsOfCareEdit"
        />
      </div>
    </div>
  </div>
  <ManageTransitionsOfCare
    v-if="showEditModal"
    :patient-id="patientId"
    @close="toggleTransitionsOfCareEdit"
    @refetch="fetchTransitionsOfCare"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import { setup } from './controller'
import ManageTransitionsOfCare from './ManageTransitionsOfCare/ManageTransitionsOfCare.vue'

export default defineComponent({
  components: {
    TMTertiaryButton,
    TMarkdown,
    ManageTransitionsOfCare,
    TMSecondaryButton,
  },
  setup,
})
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
