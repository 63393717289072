import { Timestamps } from '@/legacy/types/global/dates'
import { PartiallyRequired } from '../global/base'

// TYPES --------------------
export type CallDisposition = {
  communicationId: string
  completedByStaffId: string
  direction: CallDirection
  disposition: string
  isIdentityVerified: boolean
  usedTranslator: boolean
  speakingWithPersonId: string | null
  speakingWithType: SpeakingWithType | null
} & Timestamps

export type DirtyCallDisposition = PartiallyRequired<
  CallDisposition,
  'communicationId' | 'completedByStaffId' | 'disposition' | 'direction'
>

export type CreateCallDisposition = Omit<
  DirtyCallDisposition,
  'communicationId'
>

// ENUMS --------------------
export enum CallDirection {
  outbound = 'OUTBOUND',
  inbound = 'INBOUND',
}

export enum SpeakingWithType {
  member = 'MEMBER',
  contact = 'CONTACT',
  provider = 'PROVIDER',
  insurance = 'INSURANCE',
  resource = 'RESOURCE',
  other = 'OTHER',
}

// CONSTANTS --------------------
export const callDispositionDisplayLookup = {
  ANSWERED: 'Answered',
  ANSWERED_REQUESTED_CALLBACK: 'Answered - Requested Callback',
  LEFT_VOICEMAIL: 'Left Voicemail',
  VOICEMAIL_FULL: 'Voicemail Full',
  NUMBER_INOPERABLE: 'Number Inoperable',
  WRONG_NUMBER: 'Wrong Number',
  NO_ANSWER: 'Did Not Answer - No Voicemail',
  CONNECTIVITY_ISSUES: 'Connectivity Issues',
  DO_NOT_CALL_LIST: 'Do Not Call List',
} as { [key: string]: string }

export const callTypeDisplayLookup = {
  inbound: 'Inbound Call from',
  outbound: 'Outbound Call from',
  plannedCall: 'Scheduled Call to',
  text: 'Text conversation',
} as { [key: string]: string }

export const callTypeDisplayLookupV2 = {
  inbound: 'Inbound Call from',
  outbound: 'Outbound Call to',
  plannedCall: 'Scheduled Call to',
  text: 'Text Conversation',
} as { [key: string]: string }
