import apiStore from '@/legacy/store/modules/apiBuilder'
import { Claim } from '@/legacy/types/patients/claims'

export const usePatientClaimsApi = apiStore<Claim>(
  'claimsApi',
  '/api/patients/claims',
  {
    transformData: (d: { data: Claim[] }) => d,
  }
)
