import { AllowedIcons } from '@thyme/nashville/src/types/icons'
import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { object, string, InferType, date } from 'yup'

export const HUMANA_ELIGIBILITY_REFERRAL_PROGRAM = 'Thyme'
export const PADSTART_DATE_FORMAT_INT = 2
const HUMANA_MEMBER_ID_CHAR_REQUIREMENT = 11

export const HUMANA_ELIGBILITY_RES_MAP = {
  ELIGIBILE: {
    label: 'Member is eligible',
    severity: MessageSeverity.SUCCESS,
    icon: AllowedIcons.checkShield,
  },
  NOT_ELIGIBILE: {
    label: 'Member is ineligible',
    severity: MessageSeverity.ERROR,
    icon: AllowedIcons.exclamationShield,
  },
  NOT_FOUND: {
    label: 'Member not found',
    severity: MessageSeverity.WARN,
    icon: AllowedIcons.notFoundSearch,
  },
}

export const schema = object({
  humanaMemberDob: date().required(),
  humanaMemberId: string()
    .test(
      'is-not-empty',
      'humanaMemberId is a required field',
      (value) => !value || value.trim() !== '' // Pass if no value or a non-empty value is provided
    )
    .test(
      'max-length',
      ({ value }) =>
        `Maximum length is ${HUMANA_MEMBER_ID_CHAR_REQUIREMENT} characters, you have entered ${
          value?.length || 0
        } characters`,
      (value) => !value || value.length <= HUMANA_MEMBER_ID_CHAR_REQUIREMENT
    )
    .test(
      'min-length',
      ({ value }) =>
        `Minimum length is ${HUMANA_MEMBER_ID_CHAR_REQUIREMENT} characters, you have entered ${
          value?.length || 0
        } characters`,
      (value) => !value || value.length >= HUMANA_MEMBER_ID_CHAR_REQUIREMENT
    ),
})

export type EligibilityCheckForm = InferType<typeof schema>
