<template>
  <div>
    <h2>Our Colors</h2>
    <div class="flex gap-4">
      <div
        v-for="(colorGroup, key) in colorGroups"
        :key="key"
        class="color-group"
      >
        <ColorPalette
          v-for="palette in colorGroup"
          :key="palette[0]"
          :color="palette[0]"
          :name="palette[1]"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { nashColors } from 'tailwind.config.ts'
import { defineComponent } from 'vue'
import ColorPalette from '@/legacy/nashville/debug/ColorPalette.vue'

export default defineComponent({
  components: {
    ColorPalette,
  },
  setup() {
    // new colors
    const colorPalettes: string[][] = [
      ...Object.keys(nashColors).map((key) => [
        (nashColors as { [key: string]: string })[key],
        `nash-${key}`,
      ]),
    ]

    const colorGroups = colorPalettes.reduce(
      (acc: { [key: string]: string[][] }, [hex, key]: string[]) => {
        const [lib, colorAndGrade] = key.split('-')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [color, grade] = colorAndGrade.split(/\d+/g)
        acc[lib + color]
          ? acc[lib + color].push([hex, key])
          : (acc[lib + color] = [[hex, key]])
        return acc
      },
      {}
    )

    return {
      colorGroups,
    }
  },
})
</script>

<style lang="scss">
.color-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  white-space: nowrap;
}
</style>
