import { camelCase, startCase } from 'lodash'
import get from 'lodash/get'
import map from 'lodash/map'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { getDataByTimeframe } from '@/legacy/components/reporting/navigationReportData'
import { jsDateTimeToString, stringToDateTime } from '@/legacy/libs/date'
import {
  formatCleanDateTime,
  formatDateTime,
  formatDateTimeWithTime,
  formatName,
} from '@/legacy/libs/format'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { Address, AddressType } from '@/legacy/types/entities/addresses'
import {
  CARE_TEAM_EMAIL,
  READABLE_CARE_TEAM_PHONE,
} from '@/legacy/types/global/thymeInfo'
import { GoalStatus } from '@/legacy/types/pathways/goals'
import {
  programStatusMap,
  treatmentIntentMap,
  treatmentStatusMap,
} from '@/legacy/types/patients/patients'

import {
  useFallRisk,
  useGoals,
  useMedicalConditions,
  useMedicalTeam,
  usePatientClinicalSummary,
  usePatientSocialSummary,
  usePatientTreatment,
  usePhysicalFunctionAssessment,
  useTransitionsOfCare,
} from '@/pages/PatientProfile/CarePlans/shared/variables'

const PERCENTAGE_MULTIPLIER = 100

/**
 *
 * @param props
 * @param props.patientId
 */
export default function setup(props: { patientId: string }) {
  const logo = '/assets/images/ThymeCare-Logo-Wordmark-Plum.jpg'
  const patientIdRef = ref(props.patientId)

  const { patient, person, pathways, goals, goalToPathwayMap, communications } =
    getDataByTimeframe(props.patientId)

  const activeGoals = computed(() =>
    goals.value.filter((goal) => goal.status === GoalStatus.ACTIVE)
  )

  const titleDisplayDate = computed(() => {
    const today = jsDateTimeToString(new Date())
    return formatDateTime(stringToDateTime(today))
  })

  const treatmentStatus = computed(() =>
    get(
      treatmentStatusMap,
      `${patient.value?.treatmentStatus}.${patient.value?.treatmentSubstatus}`
    )
  )

  const treatmentIntent = computed(() =>
    get(treatmentIntentMap, `${patient.value?.treatmentIntent}`)
  )

  const programStatus = computed(() =>
    get(
      programStatusMap,
      `${patient.value?.programStatus}.${patient.value?.programSubstatus}`
    )
  )

  const latestUpdate = computed(
    () =>
      map(goals.value ?? [], 'updatedAt').sort((a, b) => b.localeCompare(a))[0]
  )

  const {
    fallRiskId,
    fallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay,
    anticoagulationDisplay,
    sedatingMedicationsDisplay,
    additionalInformationDisplay,
  } = useFallRisk(patientIdRef)

  const {
    lastOncologyAppt,
    nextOncologyAppt,
    oncologistName,
    surgeonName,
    radiationOncologistName,
    palliativeMedicineName,
    primaryCareName,
    additionalContext: medicalTeamAdditionalContext,
  } = useMedicalTeam(patientIdRef)

  const {
    physicalFunctionAssessmentId,
    ecogStatusDisplay,
    unableToPerformTheFollowingIADLsDisplay,
    unableToPerformTheFollowingADLsDisplay,
    homeboundDisplay,
    dmeDisplay,
    proceduralSupportDisplay,
    walksTwoBlocksOrOneFlightOfStairsDisplay,
    additionalInformationDisplay: physicalFunctionAdditionalInformationDisplay,
  } = usePhysicalFunctionAssessment(patientIdRef)

  const {
    transitionsOfCareId,
    admissionDateDisplay,
    dischargeDateDisplay,
    eventTypeDisplay,
    dischargeDiagnosisDisplay,
    dischargeLocationDisplay,
    isReadmissionRiskDisplay,
    hasNewDmeOnDischargeDisplay,
    servicesOnDischargeDisplay,
    moreThanThreeAdmissionsInLastSixMonthsDisplay,
    summaryOfEventsDisplay,
    hasElectiveAdmissionDisplay,
  } = useTransitionsOfCare(patientIdRef)

  const { cancerDisplay, spreadDisplay, diagnosisDateDisplay } =
    usePatientClinicalSummary(patientIdRef)

  const { treatmentDisplay, surgeryDisplay, radiationDisplay } =
    usePatientTreatment()

  const { sortedGoals, getGoalPathways, getGoalTasks, getCompletedGoalTasks } =
    useGoals()

  const {
    socialSummaryId,
    benefitsProgramsServicesDisplay,
    caregiverMappingDisplay,
    financialBarrierDisplay,
    foodBarrierDisplay,
    isHealthcareProxyOrPOA,
    housingBarrierDisplay,
    inHomeSupportDisplay,
    socialSupportDisplay,
    transportationBarrierDisplay,
    additionalContextDisplay,
  } = usePatientSocialSummary(patientIdRef)

  const {
    medicalConditionsWithAttrsDisplay,
    medicalConditionsWithoutAttrsDisplay,
    isLoadingMedicalConditions,
  } = useMedicalConditions(patientIdRef)

  const { addresses } = storeToRefs(usePatientStore())

  const formatSingleAddress = (address: Address): string => {
    return [
      address.streetAddressLine1,
      address.streetAddressLine2,
      address.city,
      address.state,
      address.zip,
    ]
      .filter(Boolean)
      .join(', ')
  }

  const formattedAddress = computed((): string => {
    if (!addresses.value || Object.keys(addresses.value).length === 0) {
      return ''
    }

    const sortedAddresses = Object.values(addresses.value).sort((a, b) => {
      const order = [
        AddressType.Home,
        AddressType.HomeAndMailing,
        AddressType.Mailing,
        AddressType.Other,
      ]
      return (
        order.indexOf(a.type as AddressType) -
        order.indexOf(b.type as AddressType)
      )
    })

    return sortedAddresses.map(formatSingleAddress).join(' | ')
  })

  const formatDomain = (domain: string) => {
    return startCase(camelCase(domain))
  }

  const getPathwayPercentage = (pathway: any) => {
    return pathway.totalTaskCount
      ? (
          (pathway.completedTaskCount / pathway.totalTaskCount) *
          PERCENTAGE_MULTIPLIER
        ).toFixed(0)
      : 0
  }

  const hasClinicalData = computed(() =>
    Boolean(
      cancerDisplay.value ?? spreadDisplay.value ?? diagnosisDateDisplay.value
    )
  )

  const hasMedicalTeamData = computed(() =>
    Boolean(
      lastOncologyAppt.value ??
        nextOncologyAppt.value ??
        oncologistName.value ??
        surgeonName.value ??
        radiationOncologistName.value ??
        palliativeMedicineName.value ??
        primaryCareName.value
    )
  )

  const hasTreatmentData = computed(() =>
    Boolean(
      treatmentDisplay.value ?? surgeryDisplay.value ?? radiationDisplay.value
    )
  )

  const hasMedicalConditions = computed(
    () =>
      medicalConditionsWithAttrsDisplay.value.length > 0 ||
      medicalConditionsWithoutAttrsDisplay.value.length > 0
  )

  const hasGoals = computed(() => sortedGoals.value.length > 0)

  const hasCommunications = computed(() => communications.value.length > 0)

  const displayElectiveAdmission = computed(
    () => hasElectiveAdmissionDisplay.value != null
  )
  const displayFallInTheLastTwelveMonths = computed(
    () => fallInTheLastTwelveMonthsDisplay.value != null
  )

  const displayFearOfFalling = computed(
    () => fearOfFallingDisplay.value != null
  )

  const displayUnsteadyWithStandingOrWalking = computed(
    () => unsteadyWithStandingOrWalkingDisplay.value != null
  )

  const displayUsesDMEToAssistWithAmbulation = computed(
    () => usesDMEToAssistWithAmbulationDisplay.value != null
  )

  const displayPeripheralNeuropathy = computed(
    () => peripheralNeuropathyDisplay.value != null
  )

  const displayHomebound = computed(() => homeboundDisplay.value != null)

  const displayWalksTwoBlocksOrOneFlightOfStairs = computed(
    () => walksTwoBlocksOrOneFlightOfStairsDisplay.value != null
  )

  const displayIsReadmissionRisk = computed(
    () => isReadmissionRiskDisplay.value != null
  )

  const displayHasNewDmeOnDischarge = computed(
    () => hasNewDmeOnDischargeDisplay.value != null
  )

  const displayMoreThanThreeAdmissionsInLastSixMonths = computed(
    () => moreThanThreeAdmissionsInLastSixMonthsDisplay.value != null
  )

  const displayHealthcareProxyOrPOA = computed(
    () => isHealthcareProxyOrPOA.value != null
  )

  return {
    goals,
    goalToPathwayMap,
    latestUpdate,
    logo,
    pathways,
    patient,
    person,
    titleDisplayDate,
    treatmentStatus,
    treatmentIntent,
    programStatus,
    activeGoals,
    communications,
    jsDateTimeToString,
    formatName,
    formatDateTime,
    formatCleanDateTime,
    formatDateTimeWithTime,
    stringToDateTime,
    formatDomain,
    READABLE_CARE_TEAM_PHONE,
    CARE_TEAM_EMAIL,
    fallRiskId,
    fallInTheLastTwelveMonthsDisplay,
    unsteadyWithStandingOrWalkingDisplay,
    fearOfFallingDisplay,
    usesDMEToAssistWithAmbulationDisplay,
    peripheralNeuropathyDisplay,
    anticoagulationDisplay,
    sedatingMedicationsDisplay,
    additionalInformationDisplay,
    lastOncologyAppt,
    nextOncologyAppt,
    oncologistName,
    surgeonName,
    radiationOncologistName,
    palliativeMedicineName,
    primaryCareName,
    medicalTeamAdditionalContext,
    physicalFunctionAssessmentId,
    ecogStatusDisplay,
    unableToPerformTheFollowingIADLsDisplay,
    unableToPerformTheFollowingADLsDisplay,
    homeboundDisplay,
    dmeDisplay,
    proceduralSupportDisplay,
    walksTwoBlocksOrOneFlightOfStairsDisplay,
    physicalFunctionAdditionalInformationDisplay,
    transitionsOfCareId,
    admissionDateDisplay,
    dischargeDateDisplay,
    eventTypeDisplay,
    dischargeDiagnosisDisplay,
    dischargeLocationDisplay,
    isReadmissionRiskDisplay,
    hasNewDmeOnDischargeDisplay,
    servicesOnDischargeDisplay,
    moreThanThreeAdmissionsInLastSixMonthsDisplay,
    summaryOfEventsDisplay,
    hasElectiveAdmissionDisplay,
    cancerDisplay,
    spreadDisplay,
    diagnosisDateDisplay,
    treatmentDisplay,
    surgeryDisplay,
    radiationDisplay,
    sortedGoals,
    getGoalPathways,
    getGoalTasks,
    getCompletedGoalTasks,
    socialSummaryId,
    benefitsProgramsServicesDisplay,
    caregiverMappingDisplay,
    financialBarrierDisplay,
    foodBarrierDisplay,
    isHealthcareProxyOrPOA,
    housingBarrierDisplay,
    inHomeSupportDisplay,
    socialSupportDisplay,
    transportationBarrierDisplay,
    additionalContextDisplay,
    medicalConditionsWithAttrsDisplay,
    medicalConditionsWithoutAttrsDisplay,
    isLoadingMedicalConditions,
    formattedAddress,
    getPathwayPercentage,
    hasClinicalData,
    hasMedicalTeamData,
    hasTreatmentData,
    hasMedicalConditions,
    hasGoals,
    hasCommunications,
    displayElectiveAdmission,
    displayFallInTheLastTwelveMonths,
    displayFearOfFalling,
    displayUnsteadyWithStandingOrWalking,
    displayUsesDMEToAssistWithAmbulation,
    displayPeripheralNeuropathy,
    displayHomebound,
    displayWalksTwoBlocksOrOneFlightOfStairs,
    displayIsReadmissionRisk,
    displayHasNewDmeOnDischarge,
    displayMoreThanThreeAdmissionsInLastSixMonths,
    displayHealthcareProxyOrPOA,
  }
}
