import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { Treatment } from '@/pages/PatientProfile/CarePlans/shared/types'
import { usePreviousCancerTreatmentApi } from './shared/store'

/**
 * Retrieve all treatment for previous cancer dx
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param patientId
 */
export async function getPreviousCancerTreatment(
  cancerDxId: string,
  patientId: string
): Promise<Treatment | null> {
  const treatmentApi = usePreviousCancerTreatmentApi()
  const response = await treatmentApi.list({
    params: {
      exclude_cancer_diagnosis_ids: [cancerDxId],
      filter_patient_ids: [patientId],
      parts: ['cancer_diagnosis'],
      sort_by: 'treatmentStartDate,desc',
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (treatmentApi.error) {
    const error = treatmentApi.error
    useNotificationStore().setNotification({
      message: `Error fetching current patient treatments`,
      type: NotificationType.WARNING,
      error: `Error fetching current patient treatments: ${error}`,
    })
  }
  return response.data
}
