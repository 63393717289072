import qs from 'qs'
import { Method, TEN_MINUTE_CACHE, RequestOptions } from './types/api'
import { HTTPStatusCode } from './types/statusCodes'

/**
 * Generates a valid API URL given an endpoint and request parameters.
 * @param baseUrl
 * @param endpoint
 * @param params
 * @param paramsSeralizationOptions
 */
export function getApiUrl<P extends object>(
  baseUrl: string,
  endpoint: string,
  params?: P,
  paramsSeralizationOptions?: qs.IStringifyOptions
) {
  let url = `${baseUrl}${endpoint}`
  if (params && Object.keys(params).length) {
    url += `?${qs.stringify(params, paramsSeralizationOptions)}`
  }

  return url
}

export class ApiError extends Error {
  status: number

  constructor(message: string, status: number) {
    super(message)
    this.status = status
  }
}

/**
 * Performs a request to the API.
 * @param method
 * @param endpoint
 * @param options
 * @param baseUrl
 * @throws any errors during the request
 */
export async function apiRequest(
  method: Method,
  endpoint: string,
  options: RequestOptions,
  baseUrl: string
) {
  // NOTE: this does not pass an Authorization header,
  // Do not use from ThymeBox, use apps/thymebox/src/libs/api.ts
  const headers = new Headers()

  if (options.body && Object.keys(options.body).length) {
    headers.append('Content-Type', 'application/json')
  }

  if (options.headers) {
    Object.entries(options.headers).forEach(([k, v]: [string, any]) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      headers.append(k, v)
    )
  }

  if (method === 'GET' && !headers.has('Cache-Control') && !options.noCache) {
    headers.append('Cache-Control', TEN_MINUTE_CACHE)
  }

  let formData = undefined
  if (options.file) {
    formData = new FormData()
    formData.append('file', options.file)
  }

  const response = await fetch(
    getApiUrl(
      baseUrl,
      endpoint,
      options.params ?? {},
      options.paramSerializationOptions
    ),
    {
      method,
      headers,
      body: formData ? formData : JSON.stringify(options.body),
    }
  )

  if (response.status !== HTTPStatusCode.STATUS_204_NO_CONTENT) {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    const res = await response.json()

    if (response.status >= HTTPStatusCode.ERROR_400_BAD_REQUEST) {
      throw new ApiError(
        res.error ??
          res.message ??
          res.detail.message ??
          res.detail.detail ??
          res.detail,
        response.status
      )
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res
  }

  return null
}
