import apiStore from '@/legacy/store/modules/apiBuilder'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'

export const useArchivedMedicalConditionsApi = apiStore<MedicalCondition>(
  'archivedMedicalConditionsApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)

export const useMedicalConditionsApi = apiStore<MedicalCondition>(
  'medicalConditionsApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)
