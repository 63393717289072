<template>
  <PatientsTable class="pb-12" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PatientsTable from '@/legacy/components/patientList/PatientsTable.vue'

export default defineComponent({
  components: {
    PatientsTable,
  },
  setup() {
    return {}
  },
})
</script>
