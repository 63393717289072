import { datadogRum } from '@datadog/browser-rum'
import { DatadogRumConfig } from './config'
import type { RumInitConfiguration } from '@datadog/browser-rum'

/**
 * Initializes RUM and starts session replay recording.
 * @param config
 * @param options
 */
export function initDatadogRum(
  config: DatadogRumConfig,
  options?: Omit<RumInitConfiguration, keyof DatadogRumConfig>
) {
  datadogRum.init({
    ...config,
    ...(options ? options : {}),
  })
  datadogRum.startSessionReplayRecording()
}
