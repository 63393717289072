import {
  refetchCommsThymeline,
  useCommunicationApi,
  useCommunicationsStore,
} from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import {
  Communication,
  CommunicationSubtaskMap,
} from '@/legacy/types/communications/communications'
import { NotificationType } from '@/legacy/types/notifications'
import { MAX_NUM_SUGGESTIONS } from '@/legacy/types/pathways/subtasks'
import { retrieveCommunicationById } from './communications'
import {
  getCurrentPatientId,
  getCurrentStaffId,
} from './sharedCommunicationParts'

/**
 *
 * Function to handle de-linking of subtasks from
 * a communication and updates `linkedSubtaskIds` ref.
 *
 * Makes sure that the de-linked subtasks remove correlated
 * subtasksWithFormsortForms id.
 *
 * Refetches subtasks to ensure thymeline is updated.
 * Refetches the comms thymeline.
 * Refetches the subtask suggestions.
 *
 * Returns updated linkedSubtaskIds and subtasksWithFormsortForms
 * in an object format.
 * @param communication
 * @param linkedSubtaskIds
 * @param subtasksWithFormsortForms
 */
export async function delinkSubtask(
  communication: Communication | null,
  linkedSubtaskIds: string[],
  subtasksWithFormsortForms: any,
  { removingId }: { [key: string]: string }
) {
  if (!communication) {
    useNotificationStore().setNotification({
      message:
        "Missing communication. Can't delink subtasks from null communication.",
      type: NotificationType.DANGER,
    })
    return {
      updatedLinkedSubtaskIds: linkedSubtaskIds,
      subtasksWithFormsortForms,
    }
  }

  let updatedLinkedSubtaskIds: string[] = []

  if (communication) {
    await useCommunicationsStore().delinkSubtask(
      communication.communicationId,
      removingId
    )
    updatedLinkedSubtaskIds = linkedSubtaskIds.filter(
      (subtaskId) => subtaskId !== removingId
    )
  }
  subtasksWithFormsortForms.delete(removingId)
  // refetch delinked subtask to update communication maps
  await fetchSubtasksAndSuggestions(communication, [removingId])

  return { updatedLinkedSubtaskIds, subtasksWithFormsortForms }
}

/**
 *
 * Function to handle linking subtasks to a communication
 * and updates `linkedSubtaskIds` ref.
 *
 * Refetches subtasks to ensure thymeline is updated.
 * Refetches the comms thymeline.
 * Refetches the subtask suggestions.
 *
 * Returns updated linkedSubtaskIds.
 * @param communication
 * @param linkedSubtaskIds
 * @param createdCommId
 */
export async function linkSubtasks(
  communication: Communication | null,
  linkedSubtaskIds: string[],
  { newIds }: { [key: string]: string[] },
  createdCommId?: string
) {
  if (!communication) {
    useNotificationStore().setNotification({
      message:
        "Missing communication. Can't link subtasks to null communication.",
      type: NotificationType.DANGER,
    })
    return linkedSubtaskIds
  }

  let updatedLinkedSubtaskIds: string[] = [...linkedSubtaskIds]
  for (const newId of newIds) {
    const sentCommunicationId = createdCommId ?? communication?.communicationId
    if (!sentCommunicationId) {
      continue
    }
    const linkedIdMap: CommunicationSubtaskMap | null | undefined =
      await useCommunicationsStore().linkSubtask(sentCommunicationId, newId)
    if (linkedIdMap) {
      updatedLinkedSubtaskIds = [
        ...updatedLinkedSubtaskIds,
        linkedIdMap.subtaskId,
      ]
    }
  }

  // refetch linked subtask to update communication maps
  await fetchSubtasksAndSuggestions(communication, newIds)

  const updated = useCommunicationApi().datum
  // retrieve all communication parts for updated comm
  if (updated) {
    await retrieveCommunicationById(updated.communicationId)
  }

  return updatedLinkedSubtaskIds
}

/**
 *
 *Helper function to fetch subtasks and
 *subtask suggestions post linking/delinking.
 * @param communication
 * @param ids
 */
export async function fetchSubtasksAndSuggestions(
  communication: Communication,
  ids: string[]
) {
  const currentPatientId = getCurrentPatientId()

  if (currentPatientId) {
    await useSubtasksStore().getSubtasks({
      filter_patient_ids: [currentPatientId],
      filter_subtask_ids: ids,
    })

    if (useCommunicationsStore().communicationType) {
      refetchCommsThymeline()
    }
  } else {
    useNotificationStore().setNotification({
      message: "Can't get subtasks for member without member ID.",
      type: NotificationType.DANGER,
    })
  }

  const staffEntityId = getCurrentStaffId()
  if (staffEntityId && currentPatientId) {
    void useSubtasksStore().getSubtaskSuggestions({
      memberId: currentPatientId,
      pageLength: MAX_NUM_SUGGESTIONS,
      staffId: staffEntityId,
      communicationId: communication.communicationId,
    })
  } else {
    useNotificationStore().setNotification({
      message:
        "Failed to retrieve suggested subtasks as current user and/or member can't be found.",
      type: NotificationType.DANGER,
    })
  }
}
