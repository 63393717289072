import { MessageSeverity } from '@thyme/nashville/src/types/messages'
import { startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getValFromQuery } from '@/legacy/libs/lookup'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'

import {
  useSmsCommByPhoneNumberIdApi,
  useTextingStore,
  useTextingSubtaskByIdApi,
} from '@/legacy/store/modules/textingV2'
import {
  CommunicationType,
  CreateSmsCommunicationPayload,
  TextCommunication,
} from '@/legacy/types/communications/communications'
import { SYSTEM_USER_UUID } from '@/legacy/types/entities/entities'
import { MapEntityPhoneNumberPersonSms } from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import {
  UNKNOWN_TEXTS_PAGE_URL_NAME,
  getOpenPointerSubtasks,
  getPointerSubtaskByCommunicationId,
  hasSharedNumberWithAnotherMember,
} from '../lib/sharedTextingParts'
/**
 *
 * Setup function for MessageBox.vue
 */
export default function () {
  const TEXTING_UNKNOWN_NUMBERS_PATIENT_ID =
    useConfigStore().configVals?.textingSubtasksUnknownNumberPatientId

  const route = useRoute()
  const router = useRouter()

  const { patient, contacts } = storeToRefs(usePatientStore())
  const { selfEntity } = storeToRefs(useProfileStore())

  const isUnknownTextsPage = computed(
    () => route.name === UNKNOWN_TEXTS_PAGE_URL_NAME
  )
  const patientId = computed(() =>
    isUnknownTextsPage.value && TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      ? TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
      : `${route.params.patientId ?? ''}`
  )

  const isUnknownTextsProfile = computed(
    () => patientId.value === TEXTING_UNKNOWN_NUMBERS_PATIENT_ID
  )

  const queryData = computed(() => route.query)
  const phoneNumId = computed(() =>
    getValFromQuery(queryData.value, 'phoneNumberId')
  )
  const commId = computed(() => getValFromQuery(queryData.value, 'commId'))

  const {
    allTextablePhoneNumberIds,
    activeConversation,
    textableIndividuals,
    activeCommunication,
    conversationsError,
    messagesError,
    conversationsClientError,
  } = storeToRefs(useTextingStore())

  const { data: subtaskById } = storeToRefs(useTextingSubtaskByIdApi())

  const pointerSubtask = computed(() =>
    subtaskById.value && commId.value
      ? subtaskById.value[commId.value[0]]
      : null
  )

  const hasConsented = computed(() => {
    if (!activeCommunication.value && isUnknownTextsPage.value) {
      return true
    }
    if (phoneNumId.value?.length) {
      const currentTextableIndividual = textableIndividuals.value.find(
        (textableIndividual: MapEntityPhoneNumberPersonSms) =>
          phoneNumId.value
            ? textableIndividual.phoneNumberId === phoneNumId.value[0]
            : false
      )
      return currentTextableIndividual?.phoneNumber.messagingOptInStatus
    }
    return activeCommunication.value?.smsThread?.phoneNumber
      ?.messagingOptInStatus
  })

  const notOwnerMsg = 'Must be the owner of the conversation to edit.'
  const notConsentedMsg = computed(() =>
    hasConsented.value === null
      ? 'This person has not yet consented to texting.'
      : 'This person has opted out of texting.'
  )

  const responsibleStaffId = computed(() => {
    if (activeCommunication.value?.responsibleStaffId) {
      return activeCommunication.value.responsibleStaffId
    }
    return 'NULL'
  })

  const isOwner = computed(() => {
    if (!activeCommunication.value?.completedDatetime && commId.value?.length) {
      return (
        selfEntity.value?.entityId ===
        activeCommunication.value?.responsibleStaffId
      )
    }
    return true
  })

  const isSharedNumberWithAnotherMember = computed(() =>
    activeCommunication.value
      ? hasSharedNumberWithAnotherMember(
          activeCommunication.value,
          Object.values(contacts.value ?? {})
        )
      : false
  )

  const isCompleted = computed(
    () => !!activeCommunication.value?.completedDatetime
  )

  const completedMessage = computed(() => {
    if (isCompleted.value && !!pointerSubtask.value) {
      return `"${startCase(pointerSubtask.value.title)}" subtask ${
        pointerSubtask.value.updatedBy === SYSTEM_USER_UUID
          ? 'was auto-completed'
          : 'completed'
      }`
    }
    return null
  })

  const showWarnings = computed(
    () => hasConsented.value === null || !hasConsented.value || !isOwner.value
  )
  const hasErrors = computed(
    () =>
      !!conversationsError.value ||
      !!messagesError.value ||
      !!conversationsClientError.value
  )

  const communicationIsUpdating = computed(
    () => useSmsCommByPhoneNumberIdApi().isLoading
  )

  // ------------ Disable logic ------------

  const disabledMessaging = computed(
    () =>
      !!hasErrors.value ||
      !!sendingMessage.value ||
      !!isSharedNumberWithAnotherMember.value ||
      !isOwner.value ||
      hasConsented.value === false
  )

  const disabledOwnerDropdown = computed(
    () =>
      isCompleted.value ||
      sendingMessage.value ||
      communicationIsUpdating.value ||
      phoneNumId.value?.length
  )
  const disabledMarkAsCompletedBtn = computed(
    () =>
      !isOwner.value || communicationIsUpdating.value || sendingMessage.value
  )
  const disabledSendAndAttachBtns = computed(
    () => disabledMessaging.value || sendingMessage.value
  )
  // ------------ Actions ------------

  const { messageToSend } = storeToRefs(useTextingStore())
  const addedMedia = ref<any>([])
  const sendingMessage = ref(false)

  /**
   * Called when the user selects a responsible staff from the dropdown
   * @param newResponsibleStaffId
   */
  async function setResponsibleStaff(
    newResponsibleStaffId: string | null | undefined
  ) {
    if (activeCommunication.value?.communicationId) {
      try {
        const res = await useSmsCommByPhoneNumberIdApi().partialUpdate({
          ids: [activeCommunication.value?.communicationId],
          body: {
            responsibleStaffId: newResponsibleStaffId,
          },
        })

        if (res?.detail?.message) {
          console.error(res.detail.message)
          useNotificationStore().setNotification({
            message: `Failed to update owner dropdown ... ${res.detail.message}`,
            type: NotificationType.WARNING,
          })
          return
        }

        await useTextingStore().getSmsCommByPhoneNumberIds({
          phoneNumberIds: allTextablePhoneNumberIds.value,
          fetchAll: !!isUnknownTextsPage.value || !!isUnknownTextsProfile.value,
        })
        useNotificationStore().setNotification({
          message: 'Successfully updated owner dropdown.',
          type: NotificationType.SUCCESS,
        })
      } catch (err) {
        console.error(err)
        useNotificationStore().setNotification({
          message: 'Failed to update owner dropdown.',
          type: NotificationType.WARNING,
        })
      }
    }
  }

  /**
   * fetch subtask by its pointerToCommId
   */
  async function getPointerSubtaskByCommId() {
    if (commId.value) {
      await getPointerSubtaskByCommunicationId(commId.value[0], patientId.value)
    }
  }

  /**
   * refetch open/completed subtasks
   */
  async function fetchPointerSubtasks() {
    await getOpenPointerSubtasks(patientId.value)
    await getPointerSubtaskByCommId()
  }

  /**
   * it should only refetch comms and pointer subtasks
   */
  async function refetchCommAndPointerSubtask() {
    await useTextingStore().getSmsCommByPhoneNumberIds({
      phoneNumberIds: allTextablePhoneNumberIds.value,
      fetchAll: !!isUnknownTextsPage.value || !!isUnknownTextsProfile.value,
    })
    await fetchPointerSubtasks()
    return
  }

  /**
   *
   * Function triggered when performing file upload to send as text.
   * @param event
   */
  const uploadFile = (event: Event) => {
    event.preventDefault()
    if (!isOwner.value) {
      return
    }
    document.getElementById('file-upload')?.click()
  }

  /**
   *
   * Function triggered when performing drag/drop of media files to attach.
   * @param e
   */
  const onDrop = (e: DragEvent) => {
    addedMedia.value = [...addedMedia.value, ...(e.dataTransfer?.files ?? [])]
  }

  /**
   *
   * Function triggered when trying to remove an attached media file.
   * @param mediaName
   */
  const removeAddedMedia = (mediaName: string) => {
    addedMedia.value = addedMedia.value.filter(
      (file: File) => file.name !== mediaName
    )
  }

  /**
   *
   * Function triggered when browsing media files to attach.
   * @param e
   */
  const onBrowse = (e: Event) => {
    // @ts-ignore files does exist on target
    addedMedia.value = [...addedMedia.value, ...(e.target?.files ?? [])]
  }

  /**
   *
   * Helper function to set up sms communication payload.
   */
  function setUpSmsCommPayload(): CreateSmsCommunicationPayload {
    let phoneNumberId: string
    let patientIds: string[]

    if (phoneNumId.value?.length && patient.value) {
      phoneNumberId = phoneNumId.value[0]
      patientIds = [patient.value?.entityId]
    } else {
      phoneNumberId = activeCommunication.value?.smsThread.phoneNumberId ?? ''
      patientIds = activeCommunication.value?.patientIds ?? []
    }

    return {
      responsibleStaffId: selfEntity.value?.entityId,
      smsArgs: {
        phoneNumberId,
      },
      type: CommunicationType.Text,
      completedDatetime: null,
      notes: '',
      patientIds,
    }
  }

  /**
   *
   */
  function getMediaFilename() {
    return `ThymeCare_${new Date().toISOString()}`
  }

  /**
   *
   * Function that does a few things:
   * - calls to setup payload
   * - creates new sms communication
   * - calls sendMediaOrTextMessageFromNewConvo() that handles sending message
   */
  async function createNewSmsCommAndSendMessage() {
    // setup sms comm payload
    const smsPayload = setUpSmsCommPayload()

    // create sms communication and call sendMediaOrTextMessageFromNewConvo()
    const created: TextCommunication =
      await useCommunicationsStore().createSmsCommunication(smsPayload)
    // will refetch comms and pointer subtasks
    await refetchCommAndPointerSubtask()
    await sendMediaOrTextMessageFromNewConvo(created)
  }

  /**
   *
   * Helper function to fetch conversation of newly
   * created sms communication and send message from
   * fetched conversation for media and/or text message.
   * Also pushes new comm id to route query.
   * @param createdSmsCommunication
   */
  async function sendMediaOrTextMessageFromNewConvo(
    createdSmsCommunication: TextCommunication
  ) {
    // get conversation for new communication
    const createdConversation = await useTextingStore().getConversation(
      createdSmsCommunication.smsThread.twilioConversationIds[0]
    )

    if (createdConversation) {
      // send messages from new conversation
      if (addedMedia.value.length) {
        for (const media of addedMedia.value) {
          await createdConversation.sendMessage({
            contentType: media.type,
            filename: getMediaFilename(),
            media: media,
          })
        }
      }
      if (messageToSend.value) {
        await createdConversation.sendMessage(messageToSend.value)
      }
    }

    // Delete phone number ID in query as this will conflict with UI logic
    delete route.query['phoneNumberId']

    // push id to new communication to register selected state for inbox
    await router.push({
      query: {
        ...route.query,
        commId: createdSmsCommunication.communicationId,
        type: 'sms',
      },
    })
  }

  /* eslint-disable @typescript-eslint/no-magic-numbers */
  /**
   *
   * Helper function to provide readable file size if attached
   * as text message.
   * @param size
   */
  function readableSize(size: number) {
    if (size >= 1000000) {
      // mb
      return `${Math.round((size * 10) / 1000000) / 10}MB`
    } else if (size >= 1000) {
      // kb
      return `${Math.round((size * 10) / 1000) / 10}KB`
    }
    return `${Math.round(size * 10) / 10}B`
  }

  /**
   *
   * Helper function to decide if we'd need to create a new
   * sms communication before sending a message.
   * Otherwise, send message directly from current active
   * conversation.
   */
  async function maybeCreateSmsCommOrSendMessage() {
    if (isCompleted.value || phoneNumId.value?.length) {
      // if currentConvoObj is closed or first time texting a user, create a new one
      await createNewSmsCommAndSendMessage()
    } else {
      const promises: Promise<number>[] = []
      if (addedMedia.value.length && activeConversation.value) {
        for (const media of addedMedia.value) {
          promises.push(
            activeConversation.value.sendMessage({
              contentType: media.type,
              filename: getMediaFilename(),
              media: media,
            })
          )
        }
      }
      if (messageToSend.value && activeConversation.value) {
        promises.push(activeConversation.value.sendMessage(messageToSend.value))
      }

      await Promise.all(promises)

      // will refetch comms and pointer subtasks
      await refetchCommAndPointerSubtask()
    }
  }

  /**
   *
   * Function to send outbound text message.
   * If the are no open communications, create a new sms communication.
   * If it's the first time we are texting someone, create a new sms communication.
   * Otherwise, send text.
   *
   * If there is media attachment, that is sent as media text.
   * If there is a messageToSend value, will send that as regular text.
   * @param event
   */
  async function sendMessage(event: Event) {
    event.preventDefault()
    if (!isOwner.value) {
      return
    }
    sendingMessage.value = true

    try {
      await maybeCreateSmsCommOrSendMessage()
    } catch (err) {
      const errorMesage = `Error while trying to send message ... ${err}`
      console.error(errorMesage)
      useTextingStore().messagesError = errorMesage
    } finally {
      addedMedia.value = []
      sendingMessage.value = false
      useTextingStore().clearMessageToSend()
      // will refetch comms and pointer subtasks
      await refetchCommAndPointerSubtask()
    }
  }

  onMounted(async () => {
    await getPointerSubtaskByCommId()
  })

  watch(activeCommunication, async () => {
    await getPointerSubtaskByCommId()
  })

  return {
    pointerSubtask,
    completedMessage,
    disabledSendAndAttachBtns,
    disabledMarkAsCompletedBtn,
    disabledOwnerDropdown,
    communicationIsUpdating,
    notConsentedMsg,
    notOwnerMsg,
    MessageSeverity,
    disabledMessaging,
    sendingMessage,
    readableSize,
    addedMedia,
    activeCommunication,
    hasConsented,
    isOwner,
    isCompleted,
    showWarnings,
    responsibleStaffId,
    setResponsibleStaff,
    refetchCommAndPointerSubtask,
    // uploadAndSendActions---
    uploadFile,
    onDrop,
    onBrowse,
    sendMessage,
    messageToSend,
    removeAddedMedia,
  }
}
