import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import {
  Radiation,
  Surgery,
  Treatment,
} from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  useLastUpdatedRadiationApi,
  useLastUpdatedSurgeryApi,
  useLastUpdatedTreatmentApi,
} from './shared/store'

/**
 * Retrieve last updated radiation for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 */
export async function getLastUpdatedPatientRadiation(
  cancerDxId: string
): Promise<Radiation | null> {
  const radiationApi = useLastUpdatedRadiationApi()
  const response = await radiationApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      sort_by: 'updatedAt,desc',
      page_length: 1,
      page_number: 1,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (radiationApi.error) {
    const error = radiationApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching last updated radiation information',
      type: NotificationType.WARNING,
      error: `Error fetching last updated radiation information: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve last updated surgery for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 */
export async function getLastUpdatedPatientSurgery(
  cancerDxId: string
): Promise<Surgery | null> {
  const surgeryApi = useLastUpdatedSurgeryApi()
  const response = await surgeryApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      sort_by: 'updatedAt,desc',
      page_length: 1,
      page_number: 1,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (surgeryApi.error) {
    const error = surgeryApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching last updated surgery information',
      type: NotificationType.WARNING,
      error: `Error fetching last updated surgery information: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve last updated treatment for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 */
export async function getLastUpdatedPatientTreatment(
  cancerDxId: string
): Promise<Treatment | null> {
  const treatmentApi = useLastUpdatedTreatmentApi()
  const response = await treatmentApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      sort_by: 'updatedAt,desc',
      page_length: 1,
      page_number: 1,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (treatmentApi.error) {
    const error = treatmentApi.error
    useNotificationStore().setNotification({
      message: 'Error fetching last updated treatment information',
      type: NotificationType.WARNING,
      error: `Error fetching last updated treatment information: ${error}`,
    })
  }
  return response.data
}
