import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useAddressesApi } from '@/legacy/store/modules/addresses'
import { useEntitiesApi } from '@/legacy/store/modules/entity'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientsApi } from '@/legacy/store/modules/patients'
import { usePayerApi } from '@/legacy/store/modules/payers'
import { useMapEntityPhoneNumberApi } from '@/legacy/store/modules/phoneNumbers'
import { USState } from '@/legacy/types/entities/entities'
import { Gender } from '@/legacy/types/entities/people'
import { NotificationType } from '@/legacy/types/notifications'
import { PatientContractStatus } from '@/legacy/types/patients/contracts'
import {
  programStatusOptions,
  treatmentStatusOptions,
  PatientBasicForm,
  PatientExtendedForm,
} from '@/legacy/types/patients/patients'

/**
 *
 */
export default function () {
  const { data: payers } = storeToRefs(usePayerApi())
  const contractingEntities = useContractingEntities()
  const { notification } = storeToRefs(useNotificationStore())
  const { enablePatientContracts } = storeToRefs(useFlagStore())

  const router = useRouter()
  const cachedBasicForm = ref<PatientBasicForm | null>(null)
  const shouldAddDetails = ref(false)

  const treatmentStatusChoices = treatmentStatusOptions.map(
    ({ key, display }) => {
      return { value: key, label: display }
    }
  )
  const programStatusChoices = programStatusOptions.map(({ key, display }) => {
    return { value: key, label: display }
  })

  const stateOptions = Object.entries(USState).map(([key, display]) => {
    return {
      label: display,
      value: key,
    }
  })

  const payerChoices = computed(() => {
    return payers.value
      ? Object.values(payers.value).map(({ payerId, payerName }) => {
          return {
            value: payerId,
            label: payerName,
          }
        })
      : []
  })

  const contractingEntityChoices = computed(() => {
    return contractingEntities.value
      ? Object.values(contractingEntities.value).map(
          ({ contractingEntityId, displayName }) => {
            return {
              value: contractingEntityId,
              label: displayName,
            }
          }
        )
      : []
  })

  const extendedFormInitialValues = computed(() => ({
    programStatus: '',
    treatmentStatus: '',
    phoneNumber: '',
    streetAddressLine1: '',
    streetAddressLine2: '',
    city: '',
    state: '',
    zip: '',
    insurancePayerId: '',
    insuranceMemberId: '',
    insuranceGroupId: '',
    insuranceType: 'primary',
    insuranceOtherName: '',
    contractingEntityId: '',
    contractingId: '',
  }))

  /**
   *
   * @param payerId
   */
  function isOtherInsurance(payerId: string) {
    // IMPROVEME(): This check is brittle and will break if the payer name changes in the database
    return payers?.value?.[payerId]?.payerName === 'Other insurance'
  }

  /**
   *
   * @param formData
   */
  async function submitBasicForm(formData: PatientBasicForm) {
    // if we want to add details, cache the basic form and don't submit yet
    if (shouldAddDetails.value) {
      cachedBasicForm.value = formData
      return
    }

    await submit(formData, null)
  }

  /**
   *
   * @param formData
   */
  async function submitExtendedForm(formData: PatientExtendedForm) {
    if (!cachedBasicForm.value) {
      // shouldn't happen, but handle the error case
      console.error('Missing data from form page 1')
      return
    }

    await submit(cachedBasicForm.value, formData)
  }

  /**
   *
   * @param basicFormData
   * @param extendedFormData
   */
  async function submit(
    basicFormData: PatientBasicForm,
    extendedFormData: PatientExtendedForm | null
  ) {
    let entity

    try {
      entity = await useEntitiesApi().create({
        body: {
          person: {
            ...basicFormData,
            // overrides (transform form data)
            dateOfBirth: DateTime.fromJSDate(basicFormData.dateOfBirth),

            // extra info
            gender: Gender.Unknown,
          },
        },
      })

      const toAwait: Promise<any>[] = [
        usePatientsApi().create({
          body: {
            entityId: entity.entityId,
            insurances:
              entity.entityId &&
              extendedFormData &&
              (extendedFormData.insurancePayerId ||
                extendedFormData.insuranceOtherName)
                ? [
                    {
                      entityId: entity.entityId,
                      groupId: extendedFormData.insuranceGroupId,
                      isPrimary: extendedFormData.insuranceType === 'primary',
                      isActive: true,
                      memberId: extendedFormData.insuranceMemberId,
                      payerId: extendedFormData.insurancePayerId,
                      isManuallyAdded: true,
                      otherInsuranceName: extendedFormData.insuranceOtherName,
                    },
                  ]
                : [],
            contract:
              entity.entityId &&
              extendedFormData &&
              extendedFormData.contractingEntityId &&
              enablePatientContracts.value
                ? {
                    patientId: entity.entityId,
                    contractingEntityId: extendedFormData.contractingEntityId,
                    status: PatientContractStatus.Active,
                    contractExternalId: extendedFormData.contractingId ?? null,
                  }
                : null,
            summaryNote: '',
            ...(extendedFormData && extendedFormData.treatmentStatus
              ? {
                  treatmentStatus:
                    extendedFormData.treatmentStatus.split('__')[0],
                  treatmentSubstatus:
                    extendedFormData.treatmentStatus.split('__')[1],
                }
              : {}),
            ...(extendedFormData && extendedFormData.programStatus
              ? {
                  programStatus: extendedFormData.programStatus.split('__')[0],
                  programSubstatus:
                    extendedFormData.programStatus.split('__')[1],
                }
              : {}),
          },
        }),
      ]
      if (extendedFormData) {
        toAwait.push(
          useMapEntityPhoneNumberApi().upsert({
            body: {
              entityId: entity.entityId,
              phoneNumber: {
                phoneNumber: extendedFormData.phoneNumber.replace(/\D/g, ''),
                countryCode: '1',
              },
              isPrimary: true,
            },
          })
        )
      }
      if (extendedFormData) {
        toAwait.push(
          useAddressesApi().create({
            body: {
              entityId: entity.entityId,
              streetAddressLine1: extendedFormData.streetAddressLine1,
              streetAddressLine2: extendedFormData.streetAddressLine2,
              city: extendedFormData.city,
              state: extendedFormData.state,
              zip: extendedFormData.zip,
              type: 'home',
              note: null,
              county: null,
            },
          })
        )
      }

      await Promise.all(toAwait)
    } catch (err) {
      useNotificationStore().setNotification({
        message: 'Failed to create patient.',
        type: NotificationType.DANGER,
      })
      return
    }

    await router.push({
      name: '#patientProfile',
      params: { patientId: entity.entityId },
    })
    useNotificationStore().setNotification({
      message: 'Successfully created patient.',
      type: NotificationType.SUCCESS,
    })
  }

  return {
    notification,
    cachedBasicForm,
    treatmentStatusChoices,
    programStatusChoices,
    payerChoices,
    contractingEntityChoices,
    extendedFormInitialValues,
    isOtherInsurance,
    shouldAddDetails,
    USState,
    submit,
    submitBasicForm,
    submitExtendedForm,
    stateOptions,
    enablePatientContracts,
  }
}
