import xor from 'lodash/xor'

/**
 * called with array.filter, dedupes arrays of strings
 * @param item
 * @param pos
 * @param list
 * @returns Boolean should remain in list
 */
export function dedupeHelper(item: string, pos: number, list: string[]) {
  return list.indexOf(item) === pos
}

/**
 *
 * @param list
 */
export function uniquesOnly(list: any[]) {
  // See https://github.com/lodash/lodash/issues/4852#issuecomment-666366511
  return xor(...list.map((a) => [a]))
}

/**
 *
 * @param list
 */
export function duplicates(list: any[]) {
  // See https://github.com/lodash/lodash/issues/4852#issuecomment-666366511
  return xor(list, uniquesOnly(list))
}

/**
 * in place object key removal
 * Usage: removeVals({a: 1, b: 2}, (v) => v == 1) --> {b: 2}
 * @param dict any object
 * @param boolFunc removes entries where boolFunc is truthy
 * @returns original dict with entries removed
 */
export function removeVals(dict: Record<any, any>, boolFunc: any) {
  Object.keys(dict).map((key) => {
    if (boolFunc(dict[key])) {
      delete dict[key]
    }
  })
  return dict
}

/**
 * Arrays include same items.
 * WARNING: uses object hash equivalency (obj === obj)
 * @param arr1 Any array possibly null
 * @param arr2 Any array possibly null
 * @returns Boolean arrays are identical
 */
export function arraysHaveSameElements(arr1: any[] | null, arr2: any[] | null) {
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)

  for (const x of set1) {
    if (!set2.has(x)) return false
  }

  for (const x of set2) {
    if (!set1.has(x)) return false
  }

  return true
}
