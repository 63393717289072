import { ModalSize } from '@thyme/nashville/src/types/modals'
import { Tab } from '@thyme/nashville/src/types/tabs'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import { stringToDateTime, utcDatetimeToDate } from '@/legacy/libs/date'
import { toDateDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { getPatientTreatmentWithParts } from '@/pages/PatientProfile/CarePlans/shared/queries'
import { useTreatmentApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { getDisabledButtonStr } from '../shared/format'
import {
  EditOptions,
  editOptionsArray,
  ManageTabLabel,
  PAGE_LENGTH,
  tabLabels,
  TreatmentField,
} from '../shared/types'
import { getPreviousCancerTreatment } from './queries'
import { usePreviousCancerTreatmentApi } from './shared/store'
import { formatTreatmentDuration } from './shared/utils'
import { manageTreatmentProps } from './types'

type PropsType = ExtractPropTypes<typeof manageTreatmentProps>

/**
 * Set up the ManageTreatments component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: currentTreatments, queryMetadata: currentTreatmentMetadata } =
    storeToRefs(useTreatmentApi())
  const { data: previousTreatments } = storeToRefs(
    usePreviousCancerTreatmentApi()
  )

  const pageNumber = ref(1)

  const totalCurrentTreatmentCount = computed(
    () => currentTreatmentMetadata.value?.total ?? 0
  )
  const showMoreCurrentTreatmentDatesButton = computed(
    () =>
      Object.values(currentTreatments.value ?? {}).length <
      totalCurrentTreatmentCount.value
  )
  const currentTreatmentsArray = computed(() =>
    Object.values(currentTreatments.value ?? {})
  )

  const formattedCurrentTreatment = computed(() =>
    currentTreatmentsArray.value.map((treatment) => ({
      patientTreatmentId: treatment.patientTreatmentId,
      treatmentAgentDisplay: treatment.otherTreatmentType
        ? `Other: ${treatment.otherTreatmentType}`
        : treatment.oncologySystematicTherapyReference.agent,
      treatmentBrandsDisplay: treatment.oncologySystematicTherapyReference
        ?.brandNames.length
        ? treatment.oncologySystematicTherapyReference.brandNames.join(', ')
        : 'N/A',
      treatmentDuration: formatTreatmentDuration(treatment),
    }))
  )

  const formattedPreviousTreatment = computed(() => {
    const treatments = previousTreatments.value ?? []
    return treatments.map((treatment) => ({
      patientTreatmentId: treatment.patientTreatmentId,
      treatmentAgentDisplay: `${
        treatment.otherTreatmentType
          ? `Other: ${treatment.otherTreatmentType}`
          : treatment.oncologySystematicTherapyReference.agent
      } (${
        treatment.cancerDiagnosis.otherOncologyDxType ??
        treatment.cancerDiagnosis.oncologyDiagnosisReference?.description
      })`,
      treatmentBrandsDisplay: treatment.oncologySystematicTherapyReference
        ?.brandNames.length
        ? treatment.oncologySystematicTherapyReference.brandNames.join(', ')
        : 'N/A',
      treatmentDuration: formatTreatmentDuration(treatment),
    }))
  })

  const showDeleteModal = ref(false)
  const showAddOrEditModal = ref(false)

  const deleteTreatmentId = ref<string | null>(null)
  const editTreatmentId = ref<string | null>(null)

  const statuses = computed(() => {
    if (!previousTreatments.value?.length) {
      return tabLabels.filter(
        (tabLabel) => tabLabel.value === ManageTabLabel.CURRENT
      )
    }
    return tabLabels
  })

  const selectedStatus = ref(statuses.value[0].value)
  const selectedStatusLabel = ref(statuses.value[0].label)

  const selectStatus = (v: Tab) => (
    (selectedStatus.value = v.value as ManageTabLabel),
    (selectedStatusLabel.value = v.label)
  )

  const treatmentsByStatus = computed(() => {
    if (selectedStatus.value === ManageTabLabel.CURRENT) {
      return formattedCurrentTreatment.value
    }
    if (selectedStatus.value === ManageTabLabel.PREVIOUS) {
      return formattedPreviousTreatment.value
    }
    return []
  })

  const addButtonDisabled = computed(
    () => selectedStatus.value === ManageTabLabel.PREVIOUS
  )
  const addButtonDisabledMessage = computed(() =>
    addButtonDisabled.value
      ? getDisabledButtonStr(TreatmentField.TREATMENT)
      : ''
  )

  const showEditOptionDropdown = computed(
    () => selectedStatus.value === ManageTabLabel.CURRENT
  )

  /**
   *
   * @param str
   * @param treatmentId
   */
  function openModal(str: EditOptions, treatmentId: string) {
    if (str === EditOptions.EDIT) {
      toggleAddOrEditModal(treatmentId)
    } else if (str === EditOptions.DELETE) {
      toggleDeleteModal(treatmentId)
    }
  }

  /**
   *
   * @param treatmentId
   */
  function toggleDeleteModal(treatmentId: string | null = null) {
    showDeleteModal.value = !showDeleteModal.value
    deleteTreatmentId.value = showDeleteModal.value ? treatmentId : null
  }

  /**
   *
   * @param treatmentId
   */
  function toggleAddOrEditModal(treatmentId: string | null = null) {
    showAddOrEditModal.value = !showAddOrEditModal.value
    editTreatmentId.value = treatmentId
  }

  const editModalInitialData = computed(() => {
    if (editTreatmentId.value && currentTreatments.value) {
      const treatmentId = editTreatmentId.value
      return {
        otherTreatmentType:
          currentTreatments.value[treatmentId].otherTreatmentType,
        oncologySystematicTherapyRefId:
          currentTreatments.value[treatmentId].oncologySystematicTherapyRefId,
        treatmentEndDate: currentTreatments.value[treatmentId].treatmentEndDate
          ? utcDatetimeToDate(
              stringToDateTime(
                currentTreatments.value[treatmentId].treatmentEndDate as string
              )
            )
          : undefined,
        treatmentStartDate: currentTreatments.value[treatmentId]
          .treatmentStartDate
          ? utcDatetimeToDate(
              stringToDateTime(
                currentTreatments.value[treatmentId]
                  .treatmentStartDate as string
              )
            )
          : undefined,
      }
    }
    return {}
  })

  // fetch 3 more treatment dates
  const loadMore = async () => {
    if (!props.cancerDxId) {
      return
    }
    pageNumber.value = pageNumber.value + 1
    await getPatientTreatmentWithParts(
      props.cancerDxId,
      PAGE_LENGTH,
      pageNumber.value
    )
  }

  /**
   *
   */
  function close() {
    context.emit('close')
  }

  /**
   * reset variables that determine
   * edit/add/delete modal rendering
   */
  function resetModalVars() {
    showDeleteModal.value = false
    showAddOrEditModal.value = false
    deleteTreatmentId.value = null
    editTreatmentId.value = null
  }

  /**
   *
   */
  async function refetchCurrentTreatment() {
    pageNumber.value = 1
    await getPatientTreatmentWithParts(
      props.cancerDxId,
      PAGE_LENGTH,
      pageNumber.value
    )
    resetModalVars()
  }

  /**
   *
   */
  function onSave() {
    useTreatmentApi().data = {}
    context.emit('refetch')
  }

  onBeforeMount(async () => {
    await getPreviousCancerTreatment(props.cancerDxId, props.patientId)
  })

  return {
    ModalSize,
    // tabs
    statuses,
    selectedStatus,
    selectStatus,
    showEditOptionDropdown,
    addButtonDisabled,
    addButtonDisabledMessage,
    // display value
    treatmentsByStatus,
    toDateDisplay,
    editOptionsArray,
    // actions
    close,
    onSave,
    openModal,
    // delete modal
    toggleDeleteModal,
    deleteTreatmentId,
    showDeleteModal,
    refetchCurrentTreatment,
    // add/edit modal
    toggleAddOrEditModal,
    showAddOrEditModal,
    editTreatmentId,
    editModalInitialData,
    // show more button
    showMoreCurrentTreatmentDatesButton,
    loadMore,
  }
}
