import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { CarePlanUpdate, Domain } from './types'

export const useDomainApi = apiStore<Domain, IdMap<Domain>>(
  'domainApi',
  '/api/domains',
  {
    transformData: (d: { data: Domain[] }) =>
      idMapTransform({}, 'data', 'domainId', d.data),
  }
)

// endpoint is a RETRIEVE not a LIST
// this is intentional as we need to use list query params when
// fetching data for specific patient and/or roles
export const useCarePlanUpdateApi = apiStore<CarePlanUpdate>(
  'carePlanUpdateApi',
  '/api/care_plan/last_update',
  { transformData: (d: CarePlanUpdate) => ({ datum: d }) }
)
