import { createProp } from '@thyme/nashville/src/utils/designTokens'

export enum ButtonType {
  Primary = '', // Default
  Text = 'text',
  Outlined = 'outlined',
  Link = 'link',
}
export const buttonTypeProp = { type: createProp(ButtonType) }

export enum TButtonType {
  Primary = 'primary',
  PrimaryOutline = 'primaryOutline',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  BlackAndWhite = 'blackAndWhite',
  Danger = 'danger',
  Success = 'success',
  Transparent = 'transparent',
  WhiteAndGray = 'whiteAndGray',
  WhiteAndPurple = 'whiteAndPurple',
  WhiteAndPurpleSecondary = 'whiteAndPurpleSecondary',
  WhiteAndPurpleTertiary = 'whiteAndPurpleTertiary',
}

export enum ButtonSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
