import {
  usePlannedCallApi,
  refetchCommsThymeline,
  useCommunicationsStore,
} from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { DirtyPlannedCall } from '@/legacy/types/communications/plannedCalls'
import { NotificationType } from '@/legacy/types/notifications'
import { retrieveCommunicationById } from './communications'

export const SCHEDULED_CALL_TIME_SET_DURATION = 30
export const SCHEDULED_CALL_TIME_NOT_SET_DURATION = 1440

/**
 *
 * Function to get a singular planned call by comm ID using apiBuilder().retrieve().
 * Returns fetched planned call.
 * @param id
 */
export async function getPlannedCall(id: string | null | undefined) {
  if (!id) {
    console.error('Cannot get planned call without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot get planned call without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    const res = await usePlannedCallApi().retrieve({
      ids: [id],
      metaOptions: { bubbleErrorThrow: true },
    })
    return res
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Error fetching planned call for communication: ${id}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to create planned call using apiBuilder().create().
 * Returns created planned call.
 *
 * Fetches communication to attach communication parts.
 * Refetches thymeline to ensure created planned call comm is adeded.
 * @param id
 * @param payload
 */
export async function createPlannedCall(
  id: string | null | undefined,
  payload?: Partial<DirtyPlannedCall>
) {
  if (!id) {
    console.error('Cannot create planned call without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot create planned call without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }
  try {
    const res = await usePlannedCallApi().create({
      body: {
        ...(payload ?? {}),
        communicationId: id,
      },
      metaOptions: { bubbleErrorThrow: true },
    })
    // retrieve all communication parts for updated comm
    // to have planned call part in comms store
    if (res) {
      await retrieveCommunicationById(res.communicationId)
      refetchCommsThymeline()
      return res
    }
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Error creating planned call for communication: ${id}.`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to update planned call using apiBuilder().partialUpdate().
 * Returns updated planned call.
 *
 * Fetches communication to attach communication parts.
 * Refetches thymeline to ensure created planned call communication is updated.
 * @param id
 * @param payload
 */
export async function updatePlannedCall(
  id: string | null | undefined,
  payload: Partial<DirtyPlannedCall>
) {
  if (!id) {
    console.error('Cannot update planned call without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot update without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    const res = await usePlannedCallApi().partialUpdate({
      ids: [id],
      body: payload,
      metaOptions: { bubbleErrorThrow: true },
    })

    if (res) {
      // retrieve all communication parts for updated comm
      // to have planned call part in comms store
      await retrieveCommunicationById(res.communicationId)

      refetchCommsThymeline()
      return res
    }
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Failed to update planned call for communication: ${id}`,
      type: NotificationType.DANGER,
    })
    return
  }
}

/**
 *
 * Function to delete planned call using apiBuilder().delete().
 * Refetches thymeline to ensure deleted planned call communication is removed.
 * Sets the communicationType to null in communications store.
 * @param id
 */
export async function deletePlannedCall(id: string | null) {
  if (!id) {
    console.error('Cannot delete planned call without a communication ID.')
    useNotificationStore().setNotification({
      message: 'Cannot delete planned call without a communication ID.',
      type: NotificationType.DANGER,
    })
    return
  }

  try {
    await usePlannedCallApi().delete({
      ids: [id],
      metaOptions: { bubbleErrorThrow: true },
    })
    refetchCommsThymeline()
    useCommunicationsStore().communicationType = null
  } catch (error) {
    console.error(error)
    useNotificationStore().setNotification({
      message: `Failed to delete planned call for communication: ${id}`,
      type: NotificationType.DANGER,
    })
    return
  }
}
