import { ModalSize } from '@thyme/nashville/src/types/modals'
import { ref } from 'vue'
import { thymeDispatch } from '@/legacy/libs/eventBus'
import { useSocialSummariesApi } from '@/legacy/store/modules/socialSummaries'
import {
  transportationBarrierOptions,
  financialBarrierOptions,
  inHomeSupportOptions,
  housingBarrierOptions,
  socialSupportOptions,
  foodBarrierOptions,
  healthcareProxyOptions,
  caregiverMappingOptions,
  benefitsProgramsServicesOptions,
  DirtySocialSummary,
} from '@/legacy/types/patients/socialSummaries'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const dirtyModel = ref<DirtySocialSummary>({
    transportationBarrier:
      props.patientSocialSummary?.transportationBarrier ?? [],
    foodBarrier: props.patientSocialSummary?.foodBarrier ?? [],
    housingBarrier: props.patientSocialSummary?.housingBarrier ?? [],
    financialBarrier: props.patientSocialSummary?.financialBarrier ?? [],
    inHomeSupport: props.patientSocialSummary?.inHomeSupport ?? [],
    caregiverMapping: props.patientSocialSummary?.caregiverMapping ?? [],
    socialSupport: props.patientSocialSummary?.socialSupport ?? [],
    benefitsProgramsServices:
      props.patientSocialSummary?.benefitsProgramsServices ?? [],
    healthcareProxy:
      props.patientSocialSummary?.healthcareProxy?.toString() ?? undefined,
    additionalContext: props.patientSocialSummary?.additionalContext ?? '',
  })

  /**
   * reset dirty values and close modal
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   */
  function createSocialSummaryPayload() {
    return {
      transportationBarrier: dirtyModel.value.transportationBarrier?.length
        ? dirtyModel.value.transportationBarrier
        : null,
      foodBarrier: dirtyModel.value.foodBarrier?.length
        ? dirtyModel.value.foodBarrier
        : null,
      housingBarrier: dirtyModel.value.housingBarrier?.length
        ? dirtyModel.value.housingBarrier
        : null,
      financialBarrier: dirtyModel.value.financialBarrier?.length
        ? dirtyModel.value.financialBarrier
        : null,
      inHomeSupport: dirtyModel.value.inHomeSupport?.length
        ? dirtyModel.value.inHomeSupport
        : null,
      caregiverMapping: dirtyModel.value.caregiverMapping?.length
        ? dirtyModel.value.caregiverMapping
        : null,
      socialSupport: dirtyModel.value.socialSupport?.length
        ? dirtyModel.value.socialSupport
        : null,
      benefitsProgramsServices: dirtyModel.value.benefitsProgramsServices
        ?.length
        ? dirtyModel.value.benefitsProgramsServices
        : null,
      healthcareProxy:
        dirtyModel.value.healthcareProxy === 'true'
          ? true
          : dirtyModel.value.healthcareProxy === 'false'
          ? false
          : null,
      additionalContext: dirtyModel.value.additionalContext?.length
        ? dirtyModel.value.additionalContext
        : null,
      patientId: props.patientId,
    }
  }

  /**
   *
   */
  async function save() {
    const payload = createSocialSummaryPayload()
    if (!props.patientSocialSummary) {
      await useSocialSummariesApi().create({ body: payload })
    } else {
      await useSocialSummariesApi().partialUpdate({
        body: payload,
        ids: [props.patientSocialSummary.socialSummaryId],
      })
    }
    context.emit('refetchSocialSummary')
    thymeDispatch('care-planV2-update')
    close()
  }

  return {
    transportationBarrierOptions,
    financialBarrierOptions,
    inHomeSupportOptions,
    housingBarrierOptions,
    socialSupportOptions,
    foodBarrierOptions,
    healthcareProxyOptions,
    caregiverMappingOptions,
    benefitsProgramsServicesOptions,
    close,
    save,
    ModalSize,
    dirtyModel,
  }
}
