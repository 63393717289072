import { PropType } from 'vue'
import { object, string, InferType, date } from 'yup'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

export const schema = object({
  lastOncologyAppt: date()
    .nullable()
    .test(
      'is-before-next-appt',
      'Last oncology appointment must not be after the next oncology appointment.',
      function (lastOncologyAppt) {
        const { nextOncologyAppt } = this.parent
        return nextOncologyAppt && lastOncologyAppt
          ? lastOncologyAppt <= nextOncologyAppt
          : true
      }
    ),
  nextOncologyAppt: date().nullable(),
  oncologistName: string().nullable(),
  surgeonName: string().nullable(),
  radiationOncologistName: string().nullable(),
  palliativeMedicineName: string().nullable(),
  primaryCareName: string().nullable(),
  additionalContext: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
})

export type EditMedicalTeamForm = InferType<typeof schema>

export const editMedicalTeamProps = {
  patientId: {
    type: String,
    required: true,
  },
  patientMedTeamId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditMedicalTeamForm>>,
    required: true,
  },
} as const
