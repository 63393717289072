<template>
  <div class="section-bottom-border whitespace-pre-wrap break-inside-avoid">
    <div v-if="hasOffboardingDetails || showOffboardingSummary">
      <h4 class="py-2 print-h2">Offboarding Summary</h4>
      <div v-if="!hasOffboardingDetails" class="print:hidden">
        <TMTertiaryButton
          label="Hide Offboarding Summary"
          class="mb-4"
          @click="toggleOffboardingSummaryEdit"
        />
      </div>
      <div v-if="isEditMode">
        <div class="case-conf-grid-1">
          <TMAutosaveInput
            label="Reason for Offboarding"
            :data="summary?.reasonOffboarding"
            :disabled="!isEditMode"
            :api-call="
              (reasonOffboarding: string) =>
                save(
                  CaseConferenceUpdates.reasonOffboarding,
                  reasonOffboarding,
                  CaseConferenceStrings.reasonOffboarding,
                  reasonOffboardingSaveState
                )
            "
            :save-state="reasonOffboardingSaveState"
            :type="InputType.TEXTAREA"
          />
          <TMAutosaveInput
            label="Reengagement Attempts"
            :data="summary?.reengagementAttemptsDescription"
            :disabled="!isEditMode"
            :api-call="
              (reengagementAttemptsDescription: string) =>
                save(
                  CaseConferenceUpdates.reengagementAttemptsDescription,
                  reengagementAttemptsDescription,
                  CaseConferenceStrings.reengagementAttemptsDescription,
                  reengagementAttemptsSaveState
                )
            "
            :save-state="reengagementAttemptsSaveState"
            :type="InputType.TEXTAREA"
          />
        </div>
      </div>
      <div v-else class="case-conf-grid-1">
        <div>
          <div>
            <label>Reason for Offboarding</label>
          </div>
          <div>
            {{ summary?.reasonOffboarding || 'N/A' }}
          </div>
        </div>
        <div>
          <div>
            <label>Reengagment Attempts</label>
          </div>
          <div>
            {{ summary?.reengagementAttemptsDescription || 'N/A' }}
          </div>
        </div>
      </div>
      <label>Outstanding Tasks</label>
      <div v-if="incompleteSubtasks && incompleteSubtasks.length > 0">
        <div v-for="subtask in incompleteSubtasks" :key="subtask.subtaskId">
          <li>
            <a :href="buildSubtaskUrl(route.path, subtask)">{{
              subtask.title
            }}</a>
          </li>
        </div>
      </div>
      <div v-else>N/A</div>
    </div>
    <div v-else class="print:hidden">
      <TMTertiaryButton
        label="+ Add Offboarding Summary"
        @click="toggleOffboardingSummaryEdit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { InputType } from '@thyme/nashville/src/types/inputs'
import { PropType, computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { buildSubtaskUrl } from '@/legacy/libs/format'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import { useIncompleteSubtaskApi } from '@/legacy/store/modules/subtasks'
import { SaveState, newSaveState } from '@/legacy/types/api/api'
import { Subtask, incompleteStatuses } from '@/legacy/types/pathways/subtasks'
import {
  CaseConferenceUpdates,
  CaseConferenceStrings,
  CarePlanSummary,
  CaseConferenceMode,
} from '@/legacy/types/patients/carePlanSummaries'

export default defineComponent({
  components: {
    TMAutosaveInput,
    TMTertiaryButton,
  },
  props: {
    mode: {
      type: String as PropType<CaseConferenceMode>,
      required: true,
    },
    carePlanSummary: {
      type: Object as PropType<CarePlanSummary | null>,
      default: null,
    },
  },
  emits: ['onSave'],
  setup(props, context) {
    const summary = computed(() => props.carePlanSummary)
    const route = useRoute()
    const incompleteSubtasks = ref<Subtask[] | null>(null)
    const showOffboardingSummary = ref(false)
    const hasOffboardingDetails = computed(
      () =>
        summary.value?.reasonOffboarding ??
        summary.value?.reengagementAttemptsDescription
    )

    const reasonOffboardingSaveState = ref<SaveState>(newSaveState())
    const reengagementAttemptsSaveState = ref<SaveState>(newSaveState())

    onMounted(async () => {
      incompleteSubtasks.value = await fetchIncompleteSubtasks()
    })
    const isEditMode = computed(() => props.mode === CaseConferenceMode.EDIT)

    const save = (
      keyName: string,
      updateString: string,
      fieldName: string,
      saveState: SaveState
    ) =>
      context.emit('onSave', {
        keyName,
        updateString,
        fieldName,
        saveState,
      })

    /**
     * Fetch all incomplete subtasks, sorted by date updated
     */
    async function fetchIncompleteSubtasks() {
      const subtasks = await useIncompleteSubtaskApi().list({
        params: {
          filter_patient_ids: [route.params.patientId as string],
          filter_subtask_status: incompleteStatuses,
          sort_by: 'statusUpdatedAt,asc',
        },
      })

      if (subtasks && subtasks.data.length > 0) {
        return subtasks.data
      }
      return []
    }

    /**
     * Display offboarding summary
     * by changing showOffboardingSummary to true
     * when "Add Offboarding Summary" is clicked
     * and false when "Hide Onboarding Summary" is clicked
     */
    function toggleOffboardingSummaryEdit() {
      showOffboardingSummary.value = !showOffboardingSummary.value
    }

    return {
      InputType,
      summary,
      route,
      incompleteSubtasks,
      hasOffboardingDetails,
      showOffboardingSummary,
      CaseConferenceStrings,
      CaseConferenceUpdates,
      buildSubtaskUrl,
      toggleOffboardingSummaryEdit,
      save,
      reasonOffboardingSaveState,
      reengagementAttemptsSaveState,
      isEditMode,
    }
  },
})
</script>
<style lang="scss">
.case-conf-grid-1 {
  @apply grid grid-cols-1 gap-4 text-nash-neutral800;
}
</style>
