import { SidebarDropdownTypeActions } from '@thyme/nashville/src/types/dropdowns'
import capitalize from 'lodash/capitalize'

import { storeToRefs } from 'pinia'
import { computed, reactive } from 'vue'

import {
  deleteCommunication,
  updateCommunication,
} from '@/legacy/components/patient/communicationV2/lib/communications'
import { useStaffVars } from '@/legacy/components/patient/communicationV2/lib/staffVars'
import { jsDateTimeToString, stringTojsDateTime } from '@/legacy/libs/date'
import router from '@/legacy/router'

import {
  createDefaultDatetime,
  useCommunicationApi,
} from '@/legacy/store/modules/communications'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  CommSidebarActionEnums,
  BaseCommunicationPayload,
  DEFAULT_NOTES,
} from '@/legacy/types/communications/communications'

/**
 * Setup function for NonCallCommunication.vue
 * @param props
 */
export default function (props: any) {
  const { staffIdOptions } = useStaffVars()
  const { patient } = storeToRefs(usePatientStore())
  const { datum: communication, isLoading } = storeToRefs(useCommunicationApi())

  // Communication fields ----------
  const notes = computed(() => communication.value?.notes ?? DEFAULT_NOTES)
  const commId = computed(() => communication.value?.communicationId ?? null)

  const responsibleStaffId = computed(
    () => communication.value?.responsibleStaffId ?? null
  )
  const type = computed(() => communication.value?.type ?? props.nonCallType)
  const completedDatetime = computed(
    () => communication.value?.completedDatetime ?? createDefaultDatetime()
  )

  const patientIds = computed(() => {
    if (communication.value?.patientIds) {
      return communication.value?.patientIds
    }
    if (patient.value) {
      return [patient.value.entityId]
    }
    return []
  })

  const dirtyCommunication: BaseCommunicationPayload = reactive({
    patientIds: patientIds.value,
    type: type.value,
    responsibleStaffId: responsibleStaffId.value,
    notes: notes.value,
    completedDatetime: completedDatetime.value,
  })

  /**
   *
   * Function to update the communication
   * @param updatedField
   */
  async function updateComm(updatedField: string) {
    await updateCommunication(
      commId.value,
      dirtyCommunication,
      updatedField,
      communication.value?.updatedAt
    )
  }
  /**
   *
   * Function to delete the communication
   */
  async function deleteComm() {
    if (
      window.confirm(
        'This action will delete this communication. Do you wish to continue?'
      )
    ) {
      await deleteCommunication(commId.value)
      await router.push({
        query: {
          commId: undefined,
        },
      })
    }
  }
  /**
   *
   * Function to update notes
   * @param val
   */
  function updateNotesForDirtyComm(val: string) {
    dirtyCommunication.notes = val
  }
  return {
    capitalize,
    isLoading,
    staffIdOptions,
    commId,
    deleteComm,
    CommSidebarActionEnums,
    updateComm,
    dirtyCommunication,
    jsDateTimeToString,
    SidebarDropdownTypeActions,
    stringTojsDateTime,
    updateNotesForDirtyComm,
  }
}
