import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { MEDICAL_CONDITIONS_PAGE_LENGTH } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/shared/types'
import { MedicalConditionReference } from '@/pages/PatientProfile/CarePlans/shared/types'
import { useMedicalConditionsApi } from '../store'
import { useMedicalConditionsRefApi } from './store'
import { MedicalConditionPayload, OTHER_MEDICAL_CONDITION_REF } from './types'

/**
 * Retrieve all ~30 medical condition references.
 * Returns null if no data is returned.
 * @param excludeIds
 * @param freeTextSearch
 */
export async function getMedicalConditionRefs(
  excludeIds?: string[] | undefined,
  freeTextSearch?: string | undefined
): Promise<MedicalConditionReference[] | null> {
  const medicalConditionRefsApi = useMedicalConditionsRefApi()
  const medicalConditionsRes = await medicalConditionRefsApi.list({
    params: {
      filter_is_active: true,
      page_length: MEDICAL_CONDITIONS_PAGE_LENGTH,
      ...(excludeIds && excludeIds.length > 0
        ? { exclude_medical_condition_ref_ids: excludeIds }
        : {}),
      ...(freeTextSearch && freeTextSearch.length > 0
        ? { filter_free_text: freeTextSearch }
        : {}),
    },
  })

  if (!medicalConditionsRes?.data?.length) {
    return null
  }

  if (medicalConditionRefsApi.error) {
    const error = medicalConditionRefsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient medical condition refs`,
      type: NotificationType.WARNING,
      error: `Error fetching patient archived medical condition refs: ${error}`,
    })
  }
  // Appending "Other" to the list of medical conditions
  return [...medicalConditionsRes.data, OTHER_MEDICAL_CONDITION_REF]
}

/**
 *
 * @param payloads
 */
export async function addMedicalConditions(
  payloads: MedicalConditionPayload[]
) {
  if (!payloads?.length) {
    useNotificationStore().setNotification({
      message: 'No medical conditions to add',
      type: NotificationType.WARNING,
    })
    return
  }

  const promises: Promise<MedicalConditionReference>[] = []
  try {
    payloads.forEach((payload: MedicalConditionPayload) => {
      promises.push(
        useMedicalConditionsApi().create({
          body: payload,
          metaOptions: { bubbleErrorThrow: true },
        })
      )
    })
    await Promise.all(promises)
    useNotificationStore().setNotification({
      message: 'Medical conditions added successfully',
      type: NotificationType.SUCCESS,
    })
  } catch (e) {
    useNotificationStore().setNotification({
      message: 'Error adding medicalconditions',
      type: NotificationType.DANGER,
    })
  }
}
