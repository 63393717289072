/**
 * lookup for enums
 * TypeScript doesn't provide an easy way to do a find-if-exists lookup in enums,
 * so this is a workaround that loosens type restrictions to do that.
 * See https://thoughtbot.com/blog/the-trouble-with-typescript-enums for
 * more on a similar problem
 * @param enumType enum object
 * @param key possible key in enum object
 * @returns value from enum object at key
 */
export function lookupEnum(
  enumType: object,
  key: string | undefined | null
): any | undefined {
  if (!key) {
    return undefined
  }
  return Object.entries(enumType).find((x) => x[0] === key)?.[1]
}

/**
 * Reverse lookup for enums
 * TypeScript doesn't provide an easy way to do a find-if-exists lookup in enums,
 * so this is a workaround that loosens type restrictions to do that.
 * See https://thoughtbot.com/blog/the-trouble-with-typescript-enums for
 * more on a similar problem
 * @param enumType enum object
 * @param value
 * @returns key from enum object at value
 */
export function enumValueToKey(
  enumType: object,
  value: string | undefined
): string | undefined {
  if (!value) {
    return undefined
  }
  return Object.entries(enumType).find((x) => x[1] === value)?.[0]
}
