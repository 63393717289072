import { defineStore } from 'pinia'
import {
  FeatureFlagValues,
  FlagDefinition,
  FlagKey,
  ThymeSplitClient,
} from './client'

type FlagState<T extends FlagDefinition> = {
  client: ThymeSplitClient<T> | null
  isLoaded: boolean
} & FeatureFlagValues<T>

/**
 * Creates a Pinia store for managing feature flags.
 *
 * The initial values will be the defined fallback values for the flags.
 * In order to use real values from Split, the `init` method must be called
 * with a user key and Split API key. If the user key changes, it is safe
 * to call `init` again with the new key. Once initialized, the store will
 * automatically update the flag values when Split sends new values.
 * @param flagDefinitions
 * @param trafficType
 */
export function createFlagStore<T extends FlagDefinition>(
  flagDefinitions: T,
  trafficType = 'user'
) {
  return defineStore('flags', {
    state: (): FlagState<T> => ({
      client: null,
      isLoaded: false,
      ...ThymeSplitClient.getFallbackValues(flagDefinitions),
    }),
    actions: {
      async init(userKey: string, splitApiKey: string) {
        console.debug('Initializing Split client...')
        console.debug('userKey:', userKey)
        await this.destroy()
        try {
          const client = await ThymeSplitClient.createSplitClient(
            flagDefinitions,
            {
              authorizationKey: splitApiKey,
              trafficKey: userKey,
              trafficType: trafficType,
            }
          )
          // Note: quite possibly due to the generics, `this` is typed
          // incorrectly in the store, so we have had to ignore throughout.
          // @ts-ignore
          this.client = client

          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          this.client.onValuesUpdated(() => {
            this.syncSplitFlags()
          })

          this.syncSplitFlags()
          // @ts-ignore
          this.isLoaded = true
        } catch (e) {
          console.error('Error initializing Split client:', e)
        }
      },

      async destroy() {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        await this.client?.destroy()
        // @ts-ignore
        this.client = null
      },

      syncSplitFlags() {
        // @ts-ignore
        if (!this.client) {
          console.error('Cannot sync flags. Client is not initialized')
          return
        }

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        Object.assign(this.$state, this.client.getFlagValues())
      },
      getFlagConfig(key: FlagKey<T>): object | null {
        // @ts-ignore
        if (!this.client) {
          console.error('Cannot get flag config. Client is not initialized')
          return null
        }

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
        return this.client.getFlagConfig(key)
      },
    },
  })
}
