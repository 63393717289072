<template>
  <TMAddableItem :active="active" @select="select">
    <div class="inner-wrapper">
      <h5>
        {{ taskTemplate.title }}
        {{
          taskTemplate.variantDisplay ? `(${taskTemplate.variantDisplay})` : ''
        }}
      </h5>
      <div class="py-2">
        <TMarkdown :source="taskTemplate.description" />
      </div>
      <div>
        <TMTertiaryButton
          class="pt-2"
          icon-pos="right"
          :icon="showSubtasksIcon"
          :label="totalSubtasksText"
          @click="onShowSubtasks"
        />
      </div>
    </div>
    <template #after>
      <div v-if="active" class="space-y-4 mt-4 max-w-sm">
        <div class="flex gap-3 text-nash-neutral600">
          <TMActionDropdown
            class="bg-nash-neutral000 min-w-fit min-w-155"
            :icon="currentPriorityIcon ?? undefined"
            name="subtask-priority"
            :initial-value="selectedPriorityObj"
            :options="subtaskPriorityOptionsV2"
            option-label="label"
            option-value="val"
            :show-clear="false"
            @change="setPriorityObj"
          />
          <TCalendar
            id="task-due-date"
            placeholder="Final due date"
            name="task-due-date"
            :min-date="minDateToday"
            :unselectable-days-of-week="unselectableDaysOfWeek"
            :show-time="false"
            :clear-button="false"
            :model-value="dueDatetime ?? undefined"
            @update:model-value="setDueDatetime"
          />
        </div>
        <div class="text-nash-neutral600">
          <TInputText
            placeholder="Subject line"
            class="max-w-sm"
            :max-length="MAX_CHAR_SUBTASK_SUBJECT_LINE"
            name="subtask-custom-subject-line"
            :model-value="subjectLine ?? ''"
            @update:model-value="setSubjectLine"
          />
        </div>
      </div>
    </template>
  </TMAddableItem>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent, PropType, ref, computed, onMounted } from 'vue'
import { humanizeRole } from '@/legacy/libs/entity'
import { formatDateToCST } from '@/legacy/libs/format'
import TMActionDropdown from '@/legacy/nashville/dropdown/TMActionDropdown.vue'
import TMAddableItem from '@/legacy/nashville/molecules/TMAddableItem.vue'
import TCalendar from '@/legacy/nashville/popups/TCalendar/TCalendar.vue'

import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'

import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import {
  minDateToday,
  unselectableDaysOfWeek,
} from '@/legacy/types/global/dates'
import { AddModalActions } from '@/legacy/types/pathways/pathways'
import {
  MAX_CHAR_SUBTASK_SUBJECT_LINE,
  SubtaskPriority,
  SubtaskTemplate,
} from '@/legacy/types/pathways/subtasks'
import { CreateTaskInfo, TaskTemplate } from '@/legacy/types/pathways/tasks'
import {
  getPriorityIcon,
  subtaskPriorityOptions,
  subtaskPriorityOptionsV2,
  unsetPriority,
} from './lib/subtask'

export default defineComponent({
  components: {
    TCalendar,
    TInputText,
    TMActionDropdown,
    TMTertiaryButton,
    TMAddableItem,
    TMarkdown,
  },
  props: {
    taskTemplate: {
      type: Object as PropType<TaskTemplate>,
      required: true,
    },
    addedTaskObj: {
      type: Object as PropType<CreateTaskInfo>,
      default: null,
    },
  },
  emits: ['setTaskPayload', 'setCurrentTask', 'add', 'remove'],
  setup(props, context) {
    const active = ref(!!props.addedTaskObj)
    const showSubtasks = ref(false)
    const dueDatetime = ref<Date | null>(
      props.addedTaskObj ? new Date(props.addedTaskObj.dueDatetime) : null
    )
    const subjectLine = ref<string | null | undefined>(
      props.addedTaskObj?.customSubject ?? null
    )

    const selectedPriorityObj = ref<any>(
      props.addedTaskObj?.priority ?? unsetPriority
    )

    const currentPriorityIcon = computed(() =>
      selectedPriorityObj.value
        ? getPriorityIcon(selectedPriorityObj.value.val)
        : null
    )

    onMounted(async () => await fetchSubtaskTemplates())

    /**
     * Function to grab subtask template data for task and set default priority
     */
    async function fetchSubtaskTemplates() {
      if (props.taskTemplate.subtaskTemplateMaps) {
        const subtaskTemplateIdsForTask =
          props.taskTemplate.subtaskTemplateMaps?.map(
            (map) => map.subtaskVariantId
          )
        const results = await useSubtasksStore().getSubtaskTemplatesById({
          filter_variant_ids: subtaskTemplateIdsForTask,
        })

        const allPriorities = results.data.map(
          (subtaskTemplate: SubtaskTemplate) => subtaskTemplate.priority
        )
        const hasAllSamePriorities = allPriorities.every(
          (priority: SubtaskPriority) => priority === allPriorities[0]
        )

        if (!selectedPriorityObj.value.val && hasAllSamePriorities) {
          selectedPriorityObj.value = {
            val: allPriorities[0],
            label:
              SubtaskPriority[allPriorities[0] as keyof typeof SubtaskPriority],
            icon: getPriorityIcon(allPriorities[0]),
          }
        }
      }
    }

    /**
     * Set selected priority
     * @param event
     * @param event.value
     */
    function setPriorityObj(event: any) {
      selectedPriorityObj.value = subtaskPriorityOptionsV2.find(
        (priorityObj) => priorityObj.val === event.value
      )
      if (dueDatetime.value) {
        context.emit('setTaskPayload', {
          customSubject: subjectLine.value,
          dueDatetime: dueDatetime.value,
          variantId: props.taskTemplate.variantId,
          priority: selectedPriorityObj.value.val,
        })
      }
    }

    const select = (activeVal: boolean) => {
      if (activeVal) {
        context.emit(AddModalActions.ADD, props.taskTemplate.variantId)
      } else if (!activeVal) {
        context.emit(AddModalActions.REMOVE, props.taskTemplate.variantId)
        subjectLine.value = null
        dueDatetime.value = null
        selectedPriorityObj.value = unsetPriority
      }
      active.value = activeVal
    }

    /**
     * Function to trigger showing of child subtask templates
     */
    function onShowSubtasks() {
      context.emit('setCurrentTask', props.taskTemplate)
    }

    const showSubtasksIcon = computed(() => {
      return 'chevronRight'
    })
    const totalSubtasksText = computed(() => {
      const totalSubtasks = props.taskTemplate.subtaskTemplateMaps?.length ?? 0
      const hasSingular = totalSubtasks === 1
      return `View ${hasSingular ? ' Subtask' : ' Subtasks'} (${totalSubtasks})`
    })

    /**
     * Set duedatetime for task create
     * @param selectedDate
     */
    function setDueDatetime(selectedDate: Date) {
      const dateTime = formatDateToCST(selectedDate) as string

      dueDatetime.value = new Date(dateTime)

      context.emit('setTaskPayload', {
        customSubject: subjectLine.value,
        dueDatetime: dateTime,
        variantId: props.taskTemplate.variantId,
        priority: selectedPriorityObj.value
          ? selectedPriorityObj.value.val
          : null,
      })
    }

    /**
     * Set customSubject line text
     * @param val
     */
    function setSubjectLine(val: string) {
      subjectLine.value = val
      if (subjectLine.value.length === 0) {
        subjectLine.value = null
      }

      if (dueDatetime.value) {
        context.emit('setTaskPayload', {
          dueDatetime: dueDatetime.value,
          customSubject: subjectLine.value,
          variantId: props.taskTemplate.variantId,
          priority: selectedPriorityObj.value
            ? selectedPriorityObj.value.val
            : null,
        })
      }
    }

    return {
      subjectLine,
      setSubjectLine,
      MAX_CHAR_SUBTASK_SUBJECT_LINE,
      setPriorityObj,
      selectedPriorityObj,
      subtaskPriorityOptionsV2,
      currentPriorityIcon,
      subtaskPriorityOptions,
      minDateToday,
      unselectableDaysOfWeek,
      dueDatetime,
      setDueDatetime,
      active,
      showSubtasks,
      humanizeRole,
      select,
      onShowSubtasks,
      showSubtasksIcon,
      totalSubtasksText,
    }
  },
})
</script>
<style lang="scss">
.inner-wrapper {
  @apply flex flex-col w-full;
}
</style>
