<template>
  <LegacyTButton type="transparent" :class="labelClasses">
    {{ label }}
  </LegacyTButton>
</template>

<script lang="ts">
import { labelClasses, LabelSize } from '@thyme/nashville/src/types/labels'
import { defineComponent } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'

export default defineComponent({
  components: {
    LegacyTButton,
  },
  props: {
    label: { type: String, required: true },
    size: { type: String, default: LabelSize.Medium },
  },
  setup(props) {
    if (props.size === LabelSize.Small) {
      labelClasses.push('text-xs', 'py-1', 'px-2')
    } else {
      labelClasses.push('text-sm', 'py-2', 'px-3')
    }
    return { labelClasses }
  },
})
</script>
