import { storeToRefs } from 'pinia'
import { apiRequest } from '@/legacy/libs/api'
import { useConfigStore } from '@/legacy/store/modules/config'
import { ReportIssueForm } from '@/legacy/types/reporting'

/**
 * Reports user-reported issue to Slack.
 * Returns null if config values are not set.
 * @param reportIssueForm
 */
export async function reportIssueToSlack(reportIssueForm: ReportIssueForm) {
  const { configVals } = storeToRefs(useConfigStore())

  if (!configVals.value || !configVals.value.apiUrl) {
    return null
  }

  if (reportIssueForm.sendToSlack) {
    return apiRequest('POST', '/api/reporting/thymebox_issue', {
      body: {
        route: reportIssueForm.route,
        issueType: reportIssueForm.issueType,
        fullUrl: reportIssueForm.fullUrl ?? '',
        description: reportIssueForm.description,
        persistence: reportIssueForm.persistence,
        severity: reportIssueForm.severity,
        reporter: reportIssueForm.reporter,
      },
    })
  } else {
    return null
  }
}
