<template>
  <div v-if="displayConsents" class="flex flex-col p-3">
    <div
      class="flex justify-between items-center w-full border-b border-nash-neutral500 mb-2"
    >
      <h4>Consents</h4>
      <TMTertiaryButton icon="pencil" @click="toggleConsentsModal" />
    </div>
    <div class="flex flex-col space-y-4">
      <div v-for="(consent, cType) in displayConsents" :key="cType">
        <div class="flex flex-col space-y-0.5">
          <div class="font-bold">{{ formatConsentTitle(cType) }}</div>
          <div
            :class="`inline space-x-2 ${
              consentReceived(consent) ? '' : 'text-nash-brick600'
            }`"
          >
            <span>{{ formatUnconsenttedStatus(consent) }}</span>
            <div v-if="consent?.consentingRelationship" class="inline bullet" />
            <span v-if="consent?.consentingRelationship">{{
              formatRelationship(consent)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <EditConsents
      :is-visible="showEditConsents"
      :display-consents="displayConsents"
      :patient="patient"
      @close="toggleConsentsModal"
      @trigger-refresh="fetchConsents"
    />
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { defineComponent, onMounted, PropType, ref, toRef, computed } from 'vue'
import { enumValueToKey, lookupEnum } from '@/legacy/libs/enum'
import { formatConsentTitle } from '@/legacy/libs/format'
import { useConsentsApi } from '@/legacy/store/modules/consents'
import {
  Consent,
  ConsentType,
  ConsentStatus,
} from '@/legacy/types/patients/consents'
import { NewRelationshipToPatient } from '@/legacy/types/patients/contacts'
import { NormalizedPatient } from '@/legacy/types/patients/patients'
import EditConsents from './modals/EditConsents.vue'

export default defineComponent({
  components: {
    EditConsents,
    TMTertiaryButton,
  },
  props: {
    patient: {
      type: Object as PropType<NormalizedPatient>,
      required: true,
    },
  },
  setup(props) {
    const { data: consents, isLoading } = storeToRefs(useConsentsApi())
    const showEditConsents = ref(false)
    const patient = toRef(props, 'patient')
    const displayTypes: ConsentType[] = [
      enumValueToKey(
        ConsentType,
        ConsentType.THYME_CARE_SERVICES
      ) as ConsentType,
      enumValueToKey(ConsentType, ConsentType.PROVIDER_SERVICES) as ConsentType,
    ].filter((c) => {
      return c
    })

    /**
     * Function to show/hide consents modal
     */
    function toggleConsentsModal() {
      showEditConsents.value = !showEditConsents.value
    }

    /**
     * function to format the consenting person relationship
     * to patient
     * @param consent
     */
    function formatRelationship(consent: Consent) {
      const relationshipStr = lookupEnum(
        NewRelationshipToPatient,
        consent?.consentingRelationship
      )
      if (relationshipStr) {
        if (consent?.consentingName) {
          return `Signed by ${consent.consentingName} (${relationshipStr})`
        } else {
          return relationshipStr
        }
      }
    }

    /**
     * function to sort consents by createdAt
     * @param a
     * @param b
     */
    function sortByCreated(a: Consent, b: Consent) {
      const aDate = new Date(a.createdAt),
        bDate = new Date(b.createdAt)
      if (aDate < bDate) return -1
      if (aDate > bDate) return 1
      return 0
    }

    /**
     * patients may not yet have a DB record for each consent type
     * but we want to be sure we still render a section for each
     * desired consent type (determined by displayTypes). This returns
     * and IdMap object where the Id is the consent type and the value is an
     * optional consent record for that type
     */
    const displayConsents = computed(() => {
      const consentMap = {} as Record<ConsentType, Consent | null>

      displayTypes.forEach((displayType) => {
        consentMap[displayType] =
          consents.value
            ?.sort(sortByCreated)
            .find((consent) => consent.consentType === displayType) ?? null
      })
      return consentMap
    })

    /**
     * function to format potential signedAt
     * value on consent
     * @param consent
     */
    function formatSignedAt(consent: Consent) {
      if (!consent.signedAt) {
        return null
      }

      /**
      parse the UTC timestamp from the db, convert to user's local time, then convert to a date,
      this ensures timestamps around the UTC boundry are converted to the correct day.
       
      Note: this has timezone implications, if a user submits a formsort consent in Eastern TZ
      at 1:00AM on Jan 2, but a Care Team user is viewing the application and is in Pacific TZ, the
      Signed Date would read Jan 1.
       */
      return DateTime.fromISO(consent.signedAt, { zone: 'utc' })
        .toLocal()
        .toLocaleString(DateTime.DATE_SHORT)
    }

    /**
     * function to display unconsented status
     * no conesnt: Not Received
     * consent exists: show prettified version of enum value
     * @param consent
     */
    function formatUnconsenttedStatus(consent: Consent | null) {
      if (consent) {
        return `${lookupEnum(ConsentStatus, consent.status)} ${
          formatSignedAt(consent) ?? ''
        }`
      } else {
        return 'Not Received'
      }
    }

    /**
     * function to determine if consent is given or not
     * Consent record must be present, and satus must be COMPLETE
     * @param consent
     */
    function consentReceived(consent: Consent | null) {
      return (
        consent?.status ===
        enumValueToKey(ConsentStatus, ConsentStatus.COMPLETED)
      )
    }

    /**
     * function to fetch patient consents
     */
    function fetchConsents() {
      void useConsentsApi().list({
        params: {
          filter_patient_ids: patient.value.entityId,
          filter_consent_types: displayTypes,
        },
      })
    }

    onMounted(() => {
      fetchConsents()
    })

    return {
      toggleConsentsModal,
      showEditConsents,
      consents,
      isLoading,
      displayConsents,
      formatConsentTitle,
      formatRelationship,
      consentReceived,
      formatSignedAt,
      fetchConsents,
      formatUnconsenttedStatus,
    }
  },
})
</script>
