<template>
  <div>
    <!-- @vue-ignore Submit generics not compatible with specifics -->
    <VForm
      v-slot="{ values, setFieldValue }"
      :initial-values="initialValues"
      @submit="submit"
    >
      <button
        v-if="data"
        class="flex w-full flex-row-reverse font-bold text-nash-purple500"
        name="edit-patient-address"
      >
        Save
      </button>
      <div class="flex flex-col space-y-2 mb-5">
        <label class="font-bold"> Address Type </label>
        <TDropdown
          v-model="values.type"
          class="w-full bg-white"
          input-id="type"
          option-label="label"
          option-value="value"
          :options="addressTypeOptions"
          :show-clear="false"
          @update:model-value="
                (v: any) => setFieldValue('type', v)
              "
        />
        <TInputText
          :model-value="initialValues.streetAddressLine1"
          rules="required"
          name="streetAddressLine1"
          label="Street address"
        />

        <TInputText
          :model-value="initialValues.streetAddressLine2"
          name="streetAddressLine2"
          label="Apartment, suite, etc"
          help="(optional)"
        />

        <TInputText
          :model-value="initialValues.city"
          rules="required"
          name="city"
          label="City"
        />

        <TInputText
          :model-value="initialValues.county"
          name="county"
          label="County"
        />

        <label class="font-bold"> State </label>
        <TDropdown
          v-model="values.state"
          class="w-full bg-white"
          input-id="state"
          option-label="label"
          option-value="value"
          :options="stateOptions"
          :show-clear="false"
          @update:model-value="
                (v: any) => setFieldValue('state', v)
              "
        />

        <TInputText
          :model-value="initialValues.zip"
          rules="required"
          name="zip"
          label="ZIP code"
        />
      </div>
      <div v-if="!data" class="mt-2 flex flex-row-reverse">
        <button class="p-button p-component mt-2" name="save-patient-address">
          Save
        </button>
      </div>
    </VForm>
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { storeToRefs } from 'pinia'
import { Form as VForm } from 'vee-validate'
import { defineComponent, ref, PropType, computed } from 'vue'
import { splitTitleCaseWithSpaces } from '@/legacy/libs/string'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'

import { useAddressesApi } from '@/legacy/store/modules/addresses'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import {
  Address as AddressType,
  AddressType as AddressTypeEnum,
  PatientAddressFormData,
} from '@/legacy/types/entities/addresses'
import { USState } from '@/legacy/types/entities/entities'
import { NotificationType } from '@/legacy/types/notifications'

export default defineComponent({
  components: {
    TInputText,
    VForm,
    TDropdown,
  },
  props: {
    data: {
      type: Object as PropType<AddressType>,
      default: null,
    },
  },
  emits: ['exitCreateOrEdit'],
  setup(props, context) {
    const { patient, addresses } = storeToRefs(usePatientStore())
    const initialValues = ref<PatientAddressFormData>(
      (() => {
        let initialForm: PatientAddressFormData = emptyForm()
        if (props.data) {
          initialForm = { ...props.data }
        }
        return initialForm
      })()
    )

    const stateOptions = computed(() => {
      return Object.entries(USState).map(([k, v]) => {
        return { value: k, label: v }
      })
    })

    /**
     *
     */
    function emptyForm() {
      return {
        streetAddressLine1: '',
        streetAddressLine2: '',
        city: '',
        county: '',
        state: '',
        zip: '',
        type: '',

        // not in use yet
        note: '',
      }
    }

    /**
     *
     * @param data
     */
    async function submit(data: PatientAddressFormData) {
      if (!patient.value) {
        return
      }

      const toUpsert = { ...data, entityId: patient.value.entityId }
      let upserted: AddressType

      if (props.data) {
        upserted = await useAddressesApi().partialUpdate({
          ids: props.data.addressId,
          body: toUpsert,
        })
      } else {
        upserted = await useAddressesApi().create({ body: toUpsert })
      }

      try {
        const combinedAddresses = Object.values({
          ...(props.data ? { [props.data.addressId]: props.data } : {}),
          ...(addresses.value ?? {}),
          [upserted.addressId]: upserted,
        })
        usePatientStore().updateAddresses(combinedAddresses)
      } catch (err) {
        useNotificationStore().setNotification({
          message: 'Failed to save address.',
          type: NotificationType.DANGER,
          error: err as string,
        })
        return
      }
      useNotificationStore().setNotification({
        message: 'Successfully saved address.',
        type: NotificationType.SUCCESS,
      })
      context.emit('exitCreateOrEdit')
    }

    const addressTypeOptions = computed(() => {
      return Object.entries(AddressTypeEnum).map(([k, v]) => ({
        value: v,
        label: splitTitleCaseWithSpaces(k) as string,
      }))
    })

    return {
      addressTypeOptions,
      addresses,
      submit,
      initialValues,
      USState,
      stateOptions,
    }
  },
})
</script>
