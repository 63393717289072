// TYPES --------------------
export type ChangeVerb = {
  past: string
  present: string
}

// ENUMS --------------------
export enum Charset {
  HEX = '0123456789abcdef',
  ASCII_LOWERCASE = 'abcdefghijklmnopqrstuvwxyz',
  ASCII_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  DIGITS = '0123456789',
}

// CONSTANTS -----------------
export const NULL_STRING = 'null'
