<template>
  <TModal
    :is-visible="isVisible"
    :flex="true"
    :size="ModalSize.LG"
    :allow-overflow="true"
    @close="close"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <LegacyTButton
          name="back-add-diagnosis"
          icon="chevronLeft"
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="goBack"
        />
        <span class="font-bold text-2xl">Add Diagnoses</span>
      </div>
      <div>
        <LegacyTButton
          name="close-add-diagnosis"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="close"
        />
      </div>
    </div>
    <div v-if="isLoading">Loading...</div>
    <div class="overflow-auto space-y-4 h-full">
      <AddDiagnosisInner
        v-for="diagnosisRef in diagnosisReferenceArray"
        :key="diagnosisRef.diagnosisRefId"
        :diagnosis-reference="diagnosisRef"
        @add="addDiagnosis(diagnosisRef.diagnosisRefId)"
        @remove="removeDiagnosis(diagnosisRef.diagnosisRefId)"
        @set-diagnosis-payload="setDiagnosisPayload"
      />
      <TMTertiaryButton
        v-if="
          totalDiagnosesReferences &&
          totalDiagnosesReferences - activeRefs.length >
            diagnosisReferenceArray.length
        "
        label="Load more"
        @click="loadMore"
      />
    </div>
    <template #actions>
      <TMQuaternaryButton label="Cancel" @click="cancelAdd" />
      <TMPrimaryButton label="Save" :disabled="isLoading" @click="saveAdd" />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import { ModalSize } from '@thyme/nashville/src/types/modals'
import { map } from 'lodash'
import remove from 'lodash/remove'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, watch, ref, onMounted } from 'vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'

import {
  useDiagnosesRefStore,
  useDiagnosisRefApi,
} from '@/legacy/store/modules/diagnosesReferences'
import { useDiagnosesApi } from '@/legacy/store/modules/patient'
import { IdMap } from '@/legacy/types/api/store'
import {
  Diagnosis,
  DiagnosisReference,
  DxCodeType,
} from '@/legacy/types/patients/diagnoses'

import AddDiagnosisInner from './AddDiagnosisInner.vue'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    LegacyTButton,
    TModal,
    TMTertiaryButton,
    TMPrimaryButton,
    AddDiagnosisInner,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'back', 'cancel', 'save'],
  setup(props, context) {
    const { data: diagnosisCatalog, isLoading } = storeToRefs(
      useDiagnosisRefApi()
    )
    const { data: diagnoses } = storeToRefs(useDiagnosesApi())

    const addedDiagnosisInfo = ref<Partial<Diagnosis>[]>([])
    const addedDiagnoses = ref<string[]>([])
    const perPage = 10
    const pageNumber = ref(1)
    const totalDiagnosesReferences = ref(0)

    const fetchDiagnosisRefs = async () => {
      const results = await useDiagnosesRefStore().fetchDiagnosisRefs({
        page_length: perPage,
        page_number: pageNumber.value,
        sort_by: 'description,asc',
        filter_code_types: [DxCodeType.THYMECARE_CATEGORY],
      })
      totalDiagnosesReferences.value = results?.queryMetadata.total
    }

    onMounted(fetchDiagnosisRefs)
    watch(props, async () => {
      if (!diagnosisCatalog.value && props.isVisible) {
        await fetchDiagnosisRefs()
      }
    })

    const loadMore = async () => {
      pageNumber.value = pageNumber.value + 1
      await fetchDiagnosisRefs()
    }

    const activeRefs = computed(() =>
      map(diagnoses.value ?? {}, (dx) => dx.diagnosisRefId)
    )

    const diagnosisReferenceArray = computed(() => {
      return Object.values(diagnosisCatalog.value as IdMap<DiagnosisReference>)
        .filter((ref) => activeRefs.value.indexOf(ref.diagnosisRefId) < 0)
        .sort((a, b) => (a.description < b.description ? -1 : 1))
    })

    const addDiagnosis = (diagnosisRefId: string) => {
      if (!addedDiagnoses.value.includes(diagnosisRefId)) {
        addedDiagnoses.value.push(diagnosisRefId)
      }
    }

    const removeDiagnosis = (diagnosisRefId: string) => {
      remove(addedDiagnoses.value, (v) => v === diagnosisRefId)
      remove(
        addedDiagnosisInfo.value,
        (v) => v.diagnosisRefId === diagnosisRefId
      )
    }

    const resetAll = () => {
      addedDiagnoses.value = []
      addedDiagnosisInfo.value = []
      pageNumber.value = 1
      totalDiagnosesReferences.value = 0
    }

    const close = async () => {
      context.emit('close')
      resetAll()
      await fetchDiagnosisRefs()
    }
    const goBack = async () => {
      context.emit('back')
      resetAll()
      await fetchDiagnosisRefs()
    }
    const saveAdd = async () => {
      context.emit('save', addedDiagnosisInfo.value)

      resetAll()
      await fetchDiagnosisRefs()
    }
    const cancelAdd = async () => {
      context.emit('cancel')
      resetAll()
      await fetchDiagnosisRefs()
    }

    const setDiagnosisPayload = (diagnosisPayload: Partial<Diagnosis>) => {
      const match = addedDiagnosisInfo.value.findIndex(
        ({ diagnosisRefId }: any) =>
          diagnosisRefId === diagnosisPayload.diagnosisRefId
      )
      if (match !== -1) {
        addedDiagnosisInfo.value[match] = diagnosisPayload
      } else {
        addedDiagnosisInfo.value.push(diagnosisPayload)
      }
    }

    return {
      ButtonSize,
      ModalSize,
      activeRefs,
      totalDiagnosesReferences,
      addDiagnosis,
      diagnosisReferenceArray,
      cancelAdd,
      close,
      goBack,
      removeDiagnosis,
      saveAdd,
      isLoading,
      loadMore,
      setDiagnosisPayload,
    }
  },
})
</script>
