import { Form } from '@/legacy/types/forms/forms'
import { UUID } from '../../global/base'
import { SubtaskTemplateBase } from '../subtasks'
import { TaskTemplateBase } from '../tasks'

// TYPES --------------------

export type TriggerInput = {
  inputId: UUID
  inputKey: string
  inputType: TriggerInputType
  description: string

  inputForm?: TriggerInputForm
  inputTask?: TriggerInputTask
  inputSubtask?: TriggerInputSubtask
}

export type TriggerInputForm = {
  triggerInputFormId: UUID
  inputId: UUID
  formId: UUID

  answerVariable: string | null
  answerType: string | null
  expectedAnswer: string | null
  form: Form
}

export type TriggerInputTask = {
  triggerInputTaskId: UUID
  inputId: UUID
  taskTemplateId: UUID
  taskBase: TaskTemplateBase
}

export type TriggerInputSubtask = {
  triggerInputSubtaskId: UUID
  inputId: UUID
  subtaskTemplateId: UUID
  subtaskBase: SubtaskTemplateBase
}

// ENUMS --------------------
export enum TriggerInputType {
  SUBTASK_COMPLETION = 'SUBTASK_COMPLETION',

  TASK_UNBLOCKED = 'TASK_UNBLOCKED',
  TASK_COMPLETION = 'TASK_COMPLETION',

  SUBTASK_COMPLETION_FORM = 'SUBTASK_COMPLETION_FORM',
  FORM_COMPLETION = 'FORM_COMPLETION',

  WAREHOUSE = 'WAREHOUSE',
}
