import { DatadogRumConfig } from '@thyme/libs/src/datadog/config'
import { ApiStatePartial } from '@/legacy/types/api/apiBuilder'
import type { datadogLogs } from '@datadog/browser-logs'
import type { datadogRum } from '@datadog/browser-rum'

// TYPES --------------------
export type ConfigState = {
  configVals: Config | null
  rumClient: typeof datadogRum | null
  logsClient: typeof datadogLogs | null
} & ApiStatePartial

// INTERFACES --------------------

export interface Auth0Config {
  domain: string
  clientId: string
  organizationId: string
  apiAudience: string
}
export interface Config {
  env: 'dev' | 'staging' | 'training' | 'prod'
  apiUrl: string
  auth0: Auth0Config
  splitApiKey: string
  textingSubtasksUnknownNumberPatientId: string
  datadog: {
    // if true, enables Datadog RUM and Logs
    enabled: boolean
  } & DatadogRumConfig
}

// CONSTANTS --------------------
export const CONFIG_FILE_NAME = 'config.json'
