<template>
  <TSpinner
    v-if="showSpinner"
    :full-page="true"
    class="print:hidden"
    name="app-full-page"
  />
  <div v-else-if="configVals" id="app">
    <TWarningBanner class="print:hidden" />
    <TopNavBar v-if="!hideNav && (isAuthenticated || !isAuthPage)" />

    <!-- using the apiUrl as the key reloads the page on change -->
    <router-view :key="configVals.apiUrl" class="page" />
  </div>

  <div v-else class="flex items-center justify-center h-screen">
    <div
      class="border border-nash-brick600 text-center rounded-lg shadow-lg p-10"
    >
      <div class="text-nash-brick600 text-lg font-bold">
        Oops! There was an error loading the application.
      </div>
      <div class="text-nash-brick600 text-md mt-2">
        Please refresh the page to try again.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { storeToRefs } from 'pinia'

import { computed, defineComponent, onBeforeUnmount, watch } from 'vue'
import { useRoute } from 'vue-router'
import TopNavBar from '@/legacy/components/TopNavBar.vue'
import TWarningBanner from '@/legacy/nashville/menu/TWarningBanner/TWarningBanner.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'

import { useConfigStore } from '@/legacy/store/modules/config'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'

export default defineComponent({
  components: {
    TSpinner,
    TopNavBar,
    TWarningBanner,
  },
  setup() {
    const configStore = useConfigStore()
    const flagStore = useFlagStore()
    const { configVals, isLoading } = storeToRefs(configStore)
    const { isLoaded } = storeToRefs(flagStore)
    const profileStore = useProfileStore()
    const route = useRoute()
    // Initial config fetch.
    void configStore.get()

    const isAuthPage = computed(() => !!route.meta.requiresAuth)
    const hideNav = computed(() => !!route.meta.hideNav)

    const auth = useAuth0()
    watch(auth.user, async (newVal) => {
      const val = newVal?.sub ? newVal : null
      await profileStore.updateProfile({ update: val })
    })

    const showSpinner = computed(() => {
      if (isLoading.value) {
        return true
      }
      if (isAuthPage.value) {
        return !isLoaded.value || !auth.isAuthenticated.value
      }
      return false
    })

    onBeforeUnmount(async () => {
      await flagStore.destroy()
    })

    return {
      isAuthenticated: auth.isAuthenticated,
      configVals,
      isAuthPage,
      hideNav,
      showSpinner,
    }
  },
})
</script>

<style scoped lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply h-screen flex-col flex;
}

.page {
  @apply h-full overflow-hidden;
}
</style>
