<template>
  <div>
    <div class="flex justify-between items-center">
      <h2>Communications</h2>
      <TMPrimaryButton
        icon="close"
        name="close-communications-sidebar"
        @click="closeSidebar"
      />
    </div>
    <component
      :is="sidebarType"
      v-if="sidebarType && queryCommId"
      :comm-save-state="commSaveState"
    />
    <CommunicationsMenu
      v-else-if="!queryCommId"
      @scheduled-call-modal-open="scheduledCallModalOpen = true"
    />
    <div v-if="queryCommId">
      <SubtaskSidebarData
        :subtask-ids="computedLinkedSubtaskIds"
        :open-subtask-menu="!computedLinkedSubtaskIds?.length"
        @remove-side-effect="delinkAndSetMappedSubtasksWithFormsortSubtasks"
        @add-side-effect="linkAndSetMappedSubtasks"
        @add-open-formsort-forms="addSubtasksWithFormsortForms"
        @refresh-linked-subtasks="refreshLinkedSubtasks"
      >
        <template #additionalActions>
          <div v-if="isCallType">
            <TMSecondaryButton
              v-if="computedLinkedSubtaskIds.length"
              v-tooltip.bottom="'Reattempt All'"
              icon="counterclockArrow"
              class="ml-3"
              @click="
                openReattemptRescheduleModal(
                  ModalType.REATTEMPT,
                  computedLinkedSubtaskIds,
                  communication
                )
              "
            />
            <TMSecondaryButton
              v-tooltip.top="
                computedLinkedSubtaskIds.length
                  ? 'Reattempt and Reschedule'
                  : 'Reschedule'
              "
              icon="calendarDays"
              class="ml-3"
              @click="
                openReattemptRescheduleModal(
                  ModalType.RESCHEDULE,
                  computedLinkedSubtaskIds,
                  communication
                )
              "
            />
          </div>
        </template>
      </SubtaskSidebarData>
    </div>
    <ScheduledCommunicationModal
      :is-open="scheduledCallModalOpen"
      :member-name="memberName"
      @close="scheduledCallModalOpen = false"
    />
    <RescheduleAndReattemptModal
      v-if="communication?.communicationId"
      :is-open="reattemptRescheduleModalIsOpen"
      :reattempt="isReattempt"
      :reschedule="isReschedule"
      :callee-entity-id="calleeEntityId"
      :communication-id="communication.communicationId"
      :subtask-ids="computedLinkedSubtaskIds"
      @save="saveReattempt"
      @close="closeReattemptRescheduleModal"
    />
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import RescheduleAndReattemptModal from '@/legacy/components/patient/communicationV2/modals/RescheduleAndReattemptModal/RescheduleAndReattemptModal.vue'
import ScheduledCommunicationModal from '@/legacy/components/patient/communicationV2/modals/ScheduledCommunicationModal/ScheduledCommunicationModal.vue'
import CallDisposition from '@/legacy/components/patient/communicationV2/sidebar/calls/CallDisposition/CallDisposition.vue'
import PlannedCall from '@/legacy/components/patient/communicationV2/sidebar/calls/PlannedCall/PlannedCall.vue'
import UnplannedCall from '@/legacy/components/patient/communicationV2/sidebar/calls/UnplannedCall/UnplannedCall.vue'
import CommunicationsMenu from '@/legacy/components/patient/communicationV2/sidebar/CommunicationsMenu/CommunicationsMenu.vue'
import EmailAddress from '@/legacy/components/patient/communicationV2/sidebar/nonCalls/EmailAddress/EmailAddress.vue'
import FaxCommunication from '@/legacy/components/patient/communicationV2/sidebar/nonCalls/FaxCommunication/FaxCommunication.vue'
import OtherCommunication from '@/legacy/components/patient/communicationV2/sidebar/nonCalls/OtherCommunication/OtherCommunication.vue'
import SubtaskSidebarData from '@/legacy/components/patient/pathways/SubtaskSidebarData.vue'

import setup from './controller'

export default defineComponent({
  components: {
    // sidebar UI ---
    TMPrimaryButton,
    TMSecondaryButton,
    CommunicationsMenu,
    // sidebar types ---
    CallDisposition,
    PlannedCall,
    UnplannedCall,
    EmailAddress,
    FaxCommunication,
    OtherCommunication,
    // subtask ---
    SubtaskSidebarData,
    // modals ---
    ScheduledCommunicationModal,
    RescheduleAndReattemptModal,
  },
  setup,
})
</script>
