<template>
  <div>
    <div class="flex flex-col w-full">
      <div>
        <TMTertiaryButton
          label="External Providers"
          class="pt-2 mb-3"
          icon-position="right"
          :icon="showPatientAppointments ? 'chevronUp' : 'chevronDown'"
          @click="showPatientAppointments = !showPatientAppointments"
        />
        <PatientAppointments
          v-if="showPatientAppointments"
          :patient-id="`${$route.params.patientId}`"
        />
      </div>
      <div>
        <TMTertiaryButton
          class="pt-2 mb-5"
          icon-position="right"
          :icon="showAppointmentsTable ? 'chevronUp' : 'chevronDown'"
          label="Thyme Care Provider Group"
          @click="showAppointmentsTable = !showAppointmentsTable"
        />
        <AppointmentsTable v-if="showAppointmentsTable" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import PatientAppointments from '@/legacy/components/patient/medical/PatientAppointments/PatientAppointments.vue'
import AppointmentsTable from '@/legacy/components/patient/thymeline/appointments/AppointmentsTable/AppointmentsTable.vue'
import setup from './controller'

export default defineComponent({
  components: {
    TMTertiaryButton,
    AppointmentsTable,
    PatientAppointments,
  },
  setup,
})
</script>

<style lang="scss" scoped>
:deep(.tab) {
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom-color: #4a4a4a;
  }
  &:hover {
    background-color: rgba(#ccc, 0.2);
  }
}
</style>
