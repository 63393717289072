<template>
  <div class="nav-report-wrapper">
    <div class="page-block">
      <div class="content-block">
        <div class="flex justify-between mb-6">
          <img class="w-48" :src="logo" alt="Thyme Care" />
          <TOPrintButton />
        </div>
        <h1>Navigation Report | {{ titleDisplayDate }}</h1>
        <div>
          <b>Patient Name: </b>
          {{ formatName(person?.firstName, person?.lastName) }}
        </div>
        <div>
          <b>DOB: </b>
          {{ formatCleanDateTime(stringToDateTime(person?.dateOfBirth)) }}
        </div>
        <div v-if="reportType === 'full'" class="subtext mt-2">
          This comprehensive navigation report details all navigation activities
          in the first performance period undertaken by Thyme Care for this
          patient. With any questions, call Thyme Care at
          {{ READABLE_CARE_TEAM_PHONE }} or email {{ CARE_TEAM_EMAIL }}.
        </div>
        <div v-else class="subtext mt-2">
          This navigation report summarizes the current navigation care plan and
          the past month of navigation activities completed for this patient by
          Thyme Care. With any questions, call Thyme Care at
          {{ READABLE_CARE_TEAM_PHONE }} or email {{ CARE_TEAM_EMAIL }}.
        </div>
      </div>

      <div class="content-block">
        <h2>Care Plan</h2>
        <div v-if="goals && goals.length" class="subtext">
          Last updated:
          {{ formatCleanDateTime(stringToDateTime(latestUpdate)) }}
        </div>
        <div class="section-box space-x-8">
          <div>
            <div class="subtext">Treatment Status</div>
            <div>{{ treatmentStatus }}</div>
          </div>
          <div>
            <div class="subtext">Treatment Intent</div>
            <div>{{ treatmentIntent }}</div>
          </div>
        </div>
      </div>

      <div class="content-block">
        <div
          v-for="(goal, index) in goals"
          :key="index"
          class="section-box flex-col items-start"
        >
          <h4>Goal {{ index + 1 }}</h4>
          <h3>
            {{
              goal.freeTextTitle ??
              goal.goalReference?.titleDisplay ??
              'Ongoing Support'
            }}
          </h3>
          <div>{{ goal.timeline }}</div>
          <div>{{ goal.details }}</div>
          <div
            v-if="goalToPathwayMap[goal.goalId] && pathways"
            class="flex gap-4 w-full flex-wrap"
          >
            <PatientTreatmentPlans
              v-for="pathwayId in goalToPathwayMap[goal.goalId]"
              :key="pathwayId"
              class="!w-2/5"
              :pathway="pathways[pathwayId]"
              @click.capture.prevent.stop
            />
          </div>
        </div>
      </div>
      <div v-if="carePlanSummaries?.length" class="content-block">
        <CaseConference
          :care-plan-summary="carePlanSummaries[0]"
          :mode="CaseConferenceMode.NAV_REPORT_VIEW"
        />
      </div>
    </div>
    <div class="page-block">
      <div class="content-block">
        <h2>Patient Communications</h2>
        <div v-if="!communications.length">
          No Communications done in this time period
        </div>
        <ul v-else>
          <li
            v-for="(comm, index) in communications"
            :key="index"
            class="list-disc ml-4 space-x-1"
          >
            <span v-if="comm.completedDatetime">{{
              formatCleanDateTime(stringToDateTime(comm.completedDatetime))
            }}</span>
            <span v-else>planned</span>
            <span>{{ comm.type }}</span>
          </li>
        </ul>
      </div>
      <div class="content-block">
        <h2>Tasks Completed</h2>
        <div v-if="!shownTasks.length">No Tasks done in this time period</div>
        <ul v-else>
          <li
            v-for="task in shownTasks"
            :key="task.taskId"
            class="list-disc ml-4 space-x-1"
          >
            <span>{{
              formatCleanDateTime(stringToDateTime(task.statusUpdatedAt))
            }}</span>
            <span>{{ task.title }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import get from 'lodash/get'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import { computed, defineComponent } from 'vue'
import CaseConference from '@/legacy/components/patient/carePlans/CaseConference.vue'
import PatientTreatmentPlans from '@/legacy/components/patient/pathways/PatientTreatmentPlans.vue'
import { getDataByTimeframe } from '@/legacy/components/reporting/navigationReportData'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatName, formatCleanDateTime } from '@/legacy/libs/format'
import TOPrintButton from '@/legacy/nashville/button/TOPrintButton.vue'
import {
  CARE_TEAM_EMAIL,
  READABLE_CARE_TEAM_PHONE,
} from '@/legacy/types/global/thymeInfo'
import { CaseConferenceMode } from '@/legacy/types/patients/carePlanSummaries'
import {
  treatmentStatusMap,
  treatmentIntentMap,
} from '@/legacy/types/patients/patients'

enum ReportType {
  FULL = 'full',
  CURRENT_MONTHLY = 'current',
}

export default defineComponent({
  components: { CaseConference, TOPrintButton, PatientTreatmentPlans },
  props: {
    patientId: {
      type: String,
      required: true,
    },
    reportType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const logo = '/assets/images/ThymeCare-Logo-Wordmark-Plum.jpg'
    const endDate = reportTypeToEndDate()
    const startDate =
      props.reportType === ReportType.FULL
        ? null
        : (endDate as DateTime).minus({ months: 1 })

    const {
      patient,
      person,
      communications,
      tasks,
      pathways,
      goals,
      goalToPathwayMap,
      carePlanSummaries,
    } = getDataByTimeframe(props.patientId, startDate, endDate)

    const titleDisplayDate = computed(() =>
      props.reportType === ReportType.FULL
        ? 'All Time'
        : `${(startDate as DateTime).toFormat('LLLL y')} - ${(
            endDate as DateTime
          ).toFormat('LLLL y')}`
    )

    const treatmentStatus = computed(() =>
      get(
        treatmentStatusMap,
        `${patient.value?.treatmentStatus}.${patient.value?.treatmentSubstatus}`
      )
    )
    const treatmentIntent = computed(() =>
      get(treatmentIntentMap, `${patient.value?.treatmentIntent}`)
    )

    const shownTasks = computed(() =>
      tasks.value
        ?.filter((task) => task.status === 'COMPLETED')
        .sort((a, b) => (a.statusUpdatedAt < b.statusUpdatedAt ? -1 : 1))
    )

    /**
     * convert report type url parameter to date range for report
     * options are:
     *   FULL (all data)
     *   CURRENT_MONTHLY (last 30 days)
     *   ISO (30 days back from any iso date)
     */
    function reportTypeToEndDate() {
      if (props.reportType === ReportType.FULL) {
        return null
      } else if (props.reportType === ReportType.CURRENT_MONTHLY) {
        return DateTime.now()
      }
      return DateTime.fromISO(props.reportType)
    }

    const latestUpdate = computed(
      () =>
        map(goals.value ?? [], 'updatedAt').sort((a, b) =>
          b.localeCompare(a)
        )[0]
    )

    return {
      communications,
      goals,
      goalToPathwayMap,
      latestUpdate,
      logo,
      pathways,
      patient,
      person,
      carePlanSummaries,
      shownTasks,
      titleDisplayDate,
      treatmentStatus,
      treatmentIntent,
      formatName,
      formatCleanDateTime,
      stringToDateTime,
      CARE_TEAM_EMAIL,
      READABLE_CARE_TEAM_PHONE,
      CaseConferenceMode,
    }
  },
})
</script>

<style lang="scss">
.nav-report-wrapper h1 {
  @apply mb-2;
}
.nav-report-wrapper h3 {
  margin: 0 0 0.5rem 0 !important;
}
.nav-report-wrapper h4 {
  @apply mb-2 text-nash-neutral700;
}

.nav-report-wrapper {
  @apply flex flex-col items-center h-full;
}

.subtext {
  @apply text-nash-neutral700 text-sm;
}

.content-block {
  @apply mb-6;
}

.section-box {
  @apply flex py-4 border-t border-solid border-nash-neutral300 break-inside-avoid;
}

.page-block {
  @apply bg-nash-neutral000 p-16 print:p-0 w-9/12 print:w-full max-w-4xl print:max-w-full print:block;
}

.page {
  overflow: auto !important;
}

@media print {
  .page-break {
    page-break-after: always;
  }
  .page {
    height: auto !important;
    overflow: visible !important;
  }
}
</style>
