<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between pr-4">
      <div class="w-11/12">
        <div class="grid-container">
          <label class="t-grid-item">Fall in the last 12 months</label>
          <div class="t-grid-item">
            {{ fallInTheLastTwelveMonthsDisplay }}
          </div>
          <label class="t-grid-item">Unsteady With Standing or Walking</label>
          <div class="t-grid-item">
            {{ unsteadyWithStandingOrWalkingDisplay }}
          </div>
          <label class="t-grid-item">Fear of Falling</label>
          <div class="t-grid-item">
            {{ fearOfFallingDisplay }}
          </div>
          <label class="t-grid-item">Uses DME to Assist with Ambulation</label>
          <div class="t-grid-item">
            {{ usesDMEToAssistWithAmbulationDisplay }}
          </div>
          <label class="t-grid-item">Peripheral Neuropathy</label>
          <div class="t-grid-item">
            {{ peripheralNeuropathyDisplay }}
          </div>
          <label class="t-grid-item">Anticoagulation</label>
          <div class="t-grid-item">
            {{ anticoagulationDisplay }}
          </div>
          <label class="t-grid-item">Sedating Medications</label>
          <div class="t-grid-item">
            {{ sedatingMedicationsDisplay }}
          </div>
          <label class="t-grid-item">Additional Information</label>
          <TMarkdown
            v-if="additionalInformationDisplay"
            class="t-grid-item whitespace-pre-wrap"
            :source="additionalInformationDisplay"
          />
          <div v-else class="t-grid-item whitespace-pre-wrap">-</div>
          <label class="t-grid-item">Last update</label>
          <div class="t-grid-item">
            {{ lastUpdatedDisplay }}
          </div>
        </div>
      </div>
      <div v-tooltip.left="'Edit fall risk'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="toggleFallRiskEdit"
        />
      </div>
    </div>
  </div>
  <EditFallRisk
    v-if="showEditModal"
    :patient-id="patientId"
    :fall-risk-id="fallRiskId"
    :initial-data="editModalInitialData"
    @close="toggleFallRiskEdit"
    @refetch="refetchFallRisk"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import { setup } from './controller'
import EditFallRisk from './EditFallRisk/EditFallRisk.vue'

export default defineComponent({
  components: {
    EditFallRisk,
    TMSecondaryButton,
    TMarkdown,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
