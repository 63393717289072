<template>
  <Menubar :model="items">
    <template #start>
      <router-link
        :to="{ path: '/' }"
        class="t-navbar-item w-16 mr-2 py-1 rounded"
      >
        <img :src="logo" alt="Thyme Care" />
      </router-link>
    </template>
    <template #item="{ label, item, props, root, hasSubmenu }">
      <router-link v-if="item.url" v-slot="routerProps" :to="item.url" custom>
        <a :href="item.url" v-bind="props.action" @click="routerProps.navigate">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span
            :class="[
              hasSubmenu &&
                (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
            ]"
            v-bind="props.submenuicon"
          />
        </a>
      </router-link>
      <span v-else v-bind="props.action">
        <span v-bind="props.icon" />
        <span v-bind="props.label">{{ label }}</span>
        <span
          :class="[
            hasSubmenu &&
              (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
          ]"
          v-bind="props.submenuicon"
        />
      </span>
    </template>
    <template #end>
      <div class="space-x-4">
        <TestVpnButton class="t-navbar-item" />
        <TMNavDialer class="t-navbar-item" />
        <div v-if="authenticated" class="t-navbar-item">
          <TMTertiaryButton
            label="Log Out"
            :icon-clickable="true"
            icon-pos="left"
            icon="arrowRightCircle"
            class="!text-nash-neutral000"
            @click="logout"
          />
        </div>
        <TMTertiaryButton
          v-if="showReportIssue"
          label="Report Issue"
          :icon-clickable="true"
          icon-pos="left"
          icon="wrench"
          class="!text-nash-neutral000"
          @click="isReportIssueModalOpen = true"
        />
      </div>
    </template>
  </Menubar>
  <ReportIssueModal
    :is-open="isReportIssueModalOpen"
    @close="isReportIssueModalOpen = false"
  />
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { MenuItem } from '@thyme/nashville/src/types/navbar'
import { storeToRefs } from 'pinia'
import Menubar from 'primevue/menubar'
import { PropType, defineComponent, ref } from 'vue'
import { auth0 } from '@/auth'
import ReportIssueModal from '@/legacy/components/ReportIssueModal/ReportIssueModal.vue'
import TestVpnButton from '@/legacy/components/TestVpnButton.vue'
import TMNavDialer from '@/legacy/nashville/popups/TMNavDialer.vue'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'

export default defineComponent({
  components: {
    Menubar,
    TestVpnButton,
    TMNavDialer,
    TMTertiaryButton,
    ReportIssueModal,
  },
  props: {
    items: {
      type: Array as PropType<MenuItem[]>,
      default: () => [],
    },
  },
  setup() {
    const { showReportIssue } = storeToRefs(useFlagStore())
    const isReportIssueModalOpen = ref(false)

    const logo = '/assets/images/Thyme-Logo-Wordmark-OffWhite.png'
    /**
     * send logout command to auth0
     */
    async function logout() {
      await auth0().logout()
    }

    return {
      logo,
      authenticated: auth0().isAuthenticated,
      logout,
      isReportIssueModalOpen,
      showReportIssue,
    }
  },
})
</script>

<style lang="scss" scoped>
.t-navbar-item {
  @apply inline-block align-middle relative;
}

a.t-navbar-item:hover,
a.t-navbar-item:focus,
a.t-navbar-item:focus-within,
a.t-navbar-item.is-active {
  @apply text-nash-neutral000 font-bold;
  background: rgba(255, 255, 255, 0.1);
}
</style>
