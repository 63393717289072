import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { PrioritySegment } from '@/legacy/types/patients/prioritySegments'

export const usePrioritySegmentApi = apiStore<
  PrioritySegment,
  IdMap<PrioritySegment>
>('prioritySegmentApi', '/api/patients/priority_segments', {
  transformData: (d: { data: PrioritySegment[] }) =>
    idMapTransform({}, 'data', 'prioritySegmentId', d.data),
})
