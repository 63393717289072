<template>
  <div id="profile">
    <table class="content">
      <thead>
        <tr>
          <th>Claim</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>UI Version</td>
          <td>{{ DEPLOY_VERSION }}</td>
        </tr>
        <!-- @vue-ignore -->
        <tr v-for="[claim, value] in claims" :key="claim">
          <td>{{ claim }}</td>
          <td>{{ value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineComponent } from 'vue'

import { useProfileStore } from '@/legacy/store/modules/profile'
import { DEPLOY_VERSION } from '@/version'

export default defineComponent({
  setup() {
    const { auth0Profile } = storeToRefs(useProfileStore())

    const claims = computed(() =>
      auth0Profile.value ? Object.entries(auth0Profile.value) : {}
    )

    return {
      claims,
      DEPLOY_VERSION,
    }
  },
})
</script>
