import { Auth0VueClient } from '@auth0/auth0-vue'
import { unref } from 'vue'
import { RouteLocation } from 'vue-router'
import { auth0, loginWithRedirect } from '@/auth'
import { watchEffectOnceAsync } from '@/legacy/libs/watch'

// References https://github.com/auth0/auth0-vue/blob/main/src/guard.ts
/**
 *
 * @param client
 * @param to
 */
async function createGuardHandler(
  client: Auth0VueClient,
  to: RouteLocation
): Promise<boolean> {
  const fn = async () => {
    if (!unref(client.isAuthenticated)) {
      // Try to silently auth if we can
      await client.checkSession()
    }
    if (unref(client.isAuthenticated)) {
      return true
    }

    await loginWithRedirect({ target: to.fullPath })
    return false
  }

  // Wait until the client is not loading.
  if (!unref(client.isLoading)) {
    return fn()
  }

  await watchEffectOnceAsync(() => !unref(client.isLoading))

  return fn()
}

/**
 * Creates an auth guard that requires auth0 auth to access the route.
 * @returns the auth guard
 */
export function createAuthGuard() {
  return async (to: RouteLocation): Promise<boolean> => {
    if (to.meta.requiresAuth) {
      return createGuardHandler(auth0(), to)
    }

    return true
  }
}
