import apiStore from '@/legacy/store/modules/apiBuilder'
import { SymptomIdHistoryResponse } from '@/legacy/types/pathways/symptomIdHistory'

export const useSymptomIdHistoryApi = apiStore<SymptomIdHistoryResponse>(
  'symptomIdHistoryApi',
  '/api/symptom_id_history',
  {
    // We're storing response content in "datum" instead of "data"
    // Because this endpoint doesn't accept an id in the path
    transformData: (d: SymptomIdHistoryResponse) => ({ datum: d }),
  }
)
