import apiStore from '@/legacy/store/modules/apiBuilder'
import { MedicalConditionReference } from '@/pages/PatientProfile/CarePlans/shared/types'
import { OTHER_MEDICAL_CONDITION_REF } from './types'

export const useMedicalConditionsRefApi = apiStore<MedicalConditionReference>(
  'medicalConditionsRefApi',
  '/api/medical_conditions/reference',
  {
    transformData: (d: { data: MedicalConditionReference[] }) => {
      d.data = [...d.data, OTHER_MEDICAL_CONDITION_REF]
      return d
    },
  }
)
