import { ModalSize } from '@thyme/nashville/src/types/modals'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, ExtractPropTypes } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { editOptionsArray } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/PatientTreatment/shared/types'
import { useRadiationApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { deleteRadiation } from './queries'
import { deleteRadiationProps } from './types'

type PropsType = ExtractPropTypes<typeof deleteRadiationProps>

/**
 * Set up the DeleteRadiation component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: radiationsData } = storeToRefs(useRadiationApi())
  const radiation = computed(() =>
    radiationsData.value ? radiationsData.value[props.radiationId] : null
  )

  const radiationDate = computed(() => {
    if (radiation.value) {
      return stringToDateTime(radiation.value.radiationDate).toLocaleString(
        DateTime.DATE_SHORT
      )
    }
    return ''
  })

  /**
   * close delete modal
   */
  function close() {
    context.emit('close')
  }

  /**
   * delete radiation
   */
  async function onDelete() {
    if (radiation.value) {
      await deleteRadiation(props.radiationId)
      context.emit('refetch')
    }
  }

  return {
    editOptionsArray,
    ModalSize,
    radiationDate,
    radiation,
    onDelete,
    close,
  }
}
