<template>
  <div class="overflow-visible">
    <header>
      <p>Notes</p>
    </header>
    <div>
      <TMAutosaveInput
        :data="practice.summaryNote"
        :api-call="submit"
        :type="InputType.TEXTAREA"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { InputType } from '@thyme/nashville/src/types/inputs'
import { defineComponent, PropType } from 'vue'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePracticeApi } from '@/legacy/store/modules/practice'
import { DataType } from '@/legacy/types/api/apiBuilder'
import { NotificationType } from '@/legacy/types/notifications'
import { Practice } from '@/legacy/types/practices'

export default defineComponent({
  components: {
    TMAutosaveInput,
  },
  props: {
    practice: {
      type: Object as PropType<Practice>,
      required: true,
    },
  },
  setup(props) {
    const practiceApi = usePracticeApi()
    /**
     *
     * @param summaryNote
     */
    async function submit(summaryNote: string) {
      try {
        practiceApi.setSuccess(
          await practiceApi.partialUpdate({
            ids: props.practice.entityId,
            body: { summaryNote },
          }),
          DataType.SINGULAR
        )
      } catch (err) {
        useNotificationStore().setNotification({
          message: 'Failed to update practice summary.',
          type: NotificationType.DANGER,
        })
      }
      useNotificationStore().setNotification({
        message: 'Successfully updated practice summary.',
        type: NotificationType.SUCCESS,
      })
    }

    return {
      InputType,
      submit,
    }
  },
})
</script>
