import { defineStore } from 'pinia'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { OncologyTreatmentReference } from '@/legacy/types/patients/oncologyTreatments'

export const useOncologyTreatmentRefStore = defineStore(
  'oncologyTreatmentReferences',
  {
    state: () => ({}),
    actions: {
      async fetchOncologyTreatmentRefs(
        filter_oncology_treatment_ref_ids: string[] = []
      ) {
        await useOncologyTreatmentRefApi().listAll({
          params: {
            filter_oncology_treatment_ref_ids:
              filter_oncology_treatment_ref_ids,
          },
        })
      },
    },
  }
)

export const useOncologyTreatmentRefApi = apiStore<
  OncologyTreatmentReference,
  IdMap<OncologyTreatmentReference>
>('oncologyTreatmentReferenceApi', '/api/oncology_treatments/reference', {
  transformData: (d: { data: OncologyTreatmentReference[] }) =>
    idMapTransform({}, 'data', 'oncologyTreatmentRefId', d.data),
})
