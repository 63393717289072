<template>
  <TModal
    :is-visible="isOpen"
    :flex="true"
    :size="ModalSize.SM"
    class="text-nash-neutral900"
    @close="close"
  >
    <div>
      <div class="flex justify-between">
        <h2>{{ modalTitle }}</h2>
        <LegacyTButton
          name="close-reattempt-modal"
          icon="close"
          inline
          type="blackAndWhite"
          class="text-nash-neutral600"
          :size="ButtonSize.MD"
          @click="close"
        />
      </div>
      <div class="flex flex-col w-full space-y-5 pt-5">
        <div class="flex w-full">
          <div class="border py-2 px-3 w-full rounded">
            <SubtaskRow
              :id="`subtask-${subtask.subtaskId}`"
              :key="subtask.subtaskId"
              :display-type="SubtaskRowDisplayType.STATUS_UPDATE_MODAL"
              :subtask="subtask"
            />
          </div>
        </div>
        <p class="text-neutral-800 pb-2">
          {{ actionText }}
        </p>
        <TDropdown
          v-model="selectedSubStatus"
          :options="subStatusLabels"
          class="w-full"
          option-label="label"
          option-value="value"
          :show-clear="false"
        />
      </div>
    </div>
    <template #actions>
      <LegacyTButton
        type="blackAndWhite"
        value="Cancel"
        name="cancel"
        @click="close"
      />
      <LegacyTButton
        value="Confirm"
        name="confirm"
        :disabled="!confirmEnabled"
        @click="confirm"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import SubtaskRow from '@/legacy/components/patient/pathways/SubtaskRow.vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { Subtask, SubtaskStatus } from '@/legacy/types/pathways/subtasks'
import setup from './controller'

export default defineComponent({
  components: {
    TDropdown,
    SubtaskRow,
    LegacyTButton,
    TModal,
  },
  props: {
    subtask: {
      type: Object as PropType<Subtask>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String as PropType<SubtaskStatus>,
      required: true,
    },
  },
  emits: ['close', 'callbackFn'],
  setup,
})
</script>
