import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import { createApp } from 'vue'

// register rules
import '@/legacy/libs/validation'
import { initializeDatadog } from '@/datadog'
import router from '@/legacy/router'
import { useConfigStore } from '@/legacy/store/modules/config'
import App from './App.vue'

import '@/scss/app.scss' // thymebox scss

import { auth0 } from './auth'

const app = createApp({
  ...App,
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(Oruga, bulmaConfig)
app.use(PrimeVue)
app.directive('tooltip', Tooltip)

/**
 * v-focus directive
 *
 * Focuses an input as soon as it is added to the page.
 */
app.directive('focus', {
  mounted: (el) => {
    if (el.nodeName === 'LABEL') {
      // focus the label's control, if there is one
      ;(el as HTMLLabelElement).control?.focus()
    } else if (el.nodeName === 'INPUT') {
      // focus the input
      el.focus()
    }

    // otherwise, do nothing
  },
})

/**
 * v-hover directive
 *
 * Use in place of v-if/v-show when an element should continue to take up space
 * on the page even when hidden.
 */
app.directive('visible', {
  mounted(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden'
  },
  beforeUpdate(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden'
  },
})

app.directive('click-outside', {
  // bind
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event: MouseEvent) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },

  // unbind
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})
useConfigStore()
  .get()
  .then(() => {
    // Only put things here that need to happen after the config
    // has been loaded (e.g. Auth0 needs the clientId from the config)
    initializeDatadog()
    app.use(router)
    app.use(auth0())
    app.mount('#app')
    return
  })
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  .catch((error) => console.error(error))
