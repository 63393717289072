<template>
  <div class="overflow-visible px-5">
    <h3 class="py-2">Summary</h3>
    <TSpinner
      v-if="patientLoading"
      name="patient-summary"
      :partial-page="true"
    />

    <div v-else-if="patientDisplay">
      <TMAutosaveInput
        :auto-resize="false"
        :data="patientDisplay.summary"
        :api-call="submit"
        :warn-unsaved-changes="true"
        :save-state="saveState"
        :type="InputType.TEXTAREA"
      />
    </div>
    <div v-else class="p-5">Error loading patient summary details.</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TMAutosaveInput from '@/legacy/nashville/input/TMAutosaveInput.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    TSpinner,
    TMAutosaveInput,
  },
  setup,
})
</script>
