<template>
  <div class="flex justify-center h-full is-marginless">
    <TSpinner
      v-if="!patient || !person"
      name="patient-profile-print"
      :partial-page="true"
    />
    <div
      v-else
      class="bg-nash-neutral000 max-w-3xl overflow-auto print:max-w-none p-4"
    >
      <div class="hidden print:block page-break">
        <div class="flex justify-between">
          <img class="w-96" :src="logo" alt="Thyme Care" />
          <div>
            <div><b>To: </b>{{ to }}, {{ facility }}</div>
            <div><b>Fax: </b>{{ toFax }}</div>
            <br />
            <div><b>From: </b>{{ from }}</div>
            <div><b>Phone: </b>(201) 526-8484</div>
            <div><b>Fax: </b>(615) 610-0749</div>
          </div>
        </div>
        <br />
        <p class="mt-24">
          Healthcare information is personal and sensitive. This is being faxed
          to you after appropriate authorization from our member or under
          circumstances which do not require authorization. You, the recipient,
          are obligated to maintain it in a safe, secure and confidential
          manner. Redisclosure without additional consent as permitted by law is
          prohibited. Unauthorized redisclosure or failure to maintain
          confidentiality could subject you to penalties described in federal
          and state law.
        </p>
        <br />
        <p class="text-xs italic print:absolute print:bottom-0">
          <b>IMPORTANT WARNING:</b> The information contained in this facsimile
          transmission is privileged and confidential intended for the use of
          the addressee listed on the cover page. The authorized recipient of
          this information is prohibited from disclosing this information to any
          other party and is required to destroy the information after its
          stated need has been fulfilled. If you are not the intended recipient,
          you are hereby notified that any disclosure, copying, distribution, or
          action taken in reliance on the contents of these documents is
          strictly prohibited (Federal Regulation 42 CFR, Part 2, and 45 CFR,
          Part 160). If you have received this fax in error, please notify the
          sender immediately by calling the phone number above to arrange for
          the return of these documents.
        </p>
      </div>
      <div class="overflow-y-auto print:overflow-y-visible">
        <div class="flex justify-between items-center">
          <img class="w-16" :src="logo" alt="Thyme Care" />
          <h1>Cancer Care Navigation Report</h1>
          <div>
            <LegacyTButton class="print:hidden" @click="print"
              >Print</LegacyTButton
            >
          </div>
        </div>
        <div
          class="items-center rounded bg-nash-neutral300 print:bg-nash-neutral300 p-1 hidden print:flex"
        >
          <div class="text-sm">
            For questions about this patient or to provide Thyme Care’s service
            to new patients, contact Thyme Care’s oncology-trained care team at
            <b>(201) 526-8484</b> or
            <b>providers@thymecare.com</b>
          </div>
          <img class="w-36" :src="QRCode" />
        </div>
        <!-- To/From -->
        <div class="print-section flex justify-between">
          <div class="print:px-2">
            <div>
              <b>To: </b>
              <TInputText
                v-model="to"
                class="inline-block print:hidden w-80"
                name="To"
                placeholder="PCP Name & Title"
              />
              <span class="hidden print:inline">{{ to }}</span>
            </div>
            <div class="print:hidden flex">
              <b>Facility: </b>
              <TInputText
                v-model="facility"
                class="inline-block print:hidden w-80"
                name="Facility"
                placeholder="PCP Facility"
              />
            </div>
            <div class="print:hidden flex">
              <b>Fax: </b>
              <TInputText
                v-model="toFax"
                class="inline-block print:hidden w-80"
                name="Fax"
                placeholder="PCP Fax Number"
              />
            </div>
          </div>
          <div class="print:px-2">
            <b>From: </b>
            <TInputText
              v-model="from"
              class="inline-block print:hidden w-80"
              name="From"
              placeholder="Your Name & Title"
            />
            <span class="hidden print:inline">{{ from }}</span>
          </div>
        </div>
        <div class="flex">
          <div class="w-7/12">
            <!-- Patient Info -->
            <div class="print-section">
              <div class="text-2xl">
                {{ formatName(person.firstName, person.lastName) }}
              </div>
              <div class="ml-2">
                <b>DOB: </b
                >{{ formatDateTime(stringToDateTime(person?.dateOfBirth)) }}
              </div>
              <div class="ml-2">
                <b>Language: </b>{{ person?.preferredLanguage ?? 'Unknown' }}
              </div>
              <div class="ml-2"><b>Gender: </b>{{ person.gender }}</div>
              <div class="ml-2">
                <b>Phone: </b
                >{{ displayedPhoneNumbers?.join(', ') ?? 'Unknown' }}
              </div>
              <div v-if="patient?.firstEnrollmentDate" class="ml-2">
                <b>Enrollment Date: </b
                >{{
                  formatDateTime(stringToDateTime(patient?.firstEnrollmentDate))
                }}
              </div>
            </div>
            <!-- Summary -->
            <div class="print-section">
              <div class="text-2xl">Summary</div>
              <textarea
                v-model="summary"
                rows="6"
                class="resize-none p-2 h-64 w-full border border-solid border-nash-neutral500 print:overflow-hidden print:border-none"
              />
              <LegacyTButton
                class="print:hidden"
                :size="ButtonSize.SM"
                @click="summary = ''"
                >Clear</LegacyTButton
              >
            </div>
            <!-- Activities -->
            <div class="print-section">
              <div class="text-2xl">Thyme Care Subtasks</div>
              <div class="ml-2">
                {{ displayFullName }} has interacted with Thyme Care
                {{ subtasks?.length }} time{{
                  subtasks?.length === 1 ? '' : 's'
                }}
                since enrollment in the program and the following outcomes have
                been achieved:
              </div>
              <div
                v-if="!outcomes?.length"
                class="ml-4 my-2 italic print:hidden"
              >
                No Outcomes
              </div>
              <LegacyTCheckbox
                v-for="outcome in outcomes"
                :key="outcome!.outcomeId"
                class="p-1 my-1 ml-2 print:hidden"
                :checked="selectedOutcomes.includes(outcome?.outcomeId ?? '')"
                :label="`${getOutcomeName(
                  outcome!.outcomeRefId
                )} ${formatDateTime(stringToDateTime(outcome!.createdAt))}`"
                @update:checked="toggleOutcomeSelect(outcome ?? null)"
              />
              <div
                v-for="outcome in outcomes"
                :key="outcome!.outcomeId"
                class="p-1 my-1 ml-2 hidden"
                :class="{
                  'print:block': selectedOutcomes.includes(
                    outcome?.outcomeId ?? ''
                  ),
                }"
              >
                {{ getOutcomeName(outcome!.outcomeRefId) }}
                {{ formatDateTime(stringToDateTime(outcome!.createdAt)) }}
              </div>
              <div
                v-if="!selectedOutcomes.length"
                class="hidden print:block ml-4 my-2 italic"
              >
                No Outcomes
              </div>
            </div>
          </div>
          <div class="w-5/12">
            <!-- Contacts -->
            <div v-if="displayedContacts?.length" class="print-section">
              <div class="text-2xl">Contacts</div>
              <div class="grid grid-cols-2 gap-2 ml-2">
                <div
                  v-for="contact in displayedContacts"
                  :key="contact.contactId"
                >
                  <div>{{ formatNameFromEntity(contact.contactEntity) }}</div>
                  <div>
                    {{ contact.relationshipToPatient }}
                    {{
                      contact.contactEntity.person?.preferredLanguage
                        ? '| '
                        : ''
                    }}
                    {{ contact.contactEntity.person?.preferredLanguage ?? '' }}
                  </div>
                  <div v-if="contact.contactEntity.phoneNumbers?.length">
                    {{
                      formatPhone(
                        contact.contactEntity.phoneNumbers[0].phoneNumber
                          .phoneNumber
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Oncology Provider -->
            <div v-if="displayedProviders?.length" class="print-section">
              <div class="text-2xl">Oncology Provider(s)</div>
              <div
                v-for="provider in displayedProviders"
                :key="provider.providerId"
              >
                <div>{{ getProviderName(provider.providerId) }}</div>
                <div class="italic text-sm">
                  {{ getFromProvider(provider.providerId, 'primarySpecialty') }}
                </div>
              </div>
            </div>
            <!-- Diagnoses & Treatment -->
            <div class="print-section">
              <div class="text-2xl">Diagnoses & Treatment</div>
              <div class="flex">
                <div>
                  <!-- Oncology diagnoses -->
                  <div
                    class="p-1 m-1"
                    :class="{ 'print:hidden': !selectedDiagnoses.length }"
                  >
                    <div class="text-sm"><b>Oncology diagnoses </b></div>
                    <div
                      v-for="diagnosis in displayedDiagnoses"
                      :key="diagnosis.diagnosisId"
                      class="flex"
                    >
                      <LegacyTCheckbox
                        :key="diagnosis.diagnosisId"
                        label=""
                        class="p-1 my-1 ml-2 print:hidden"
                        :checked="
                          selectedDiagnoses.includes(diagnosis.diagnosisId)
                        "
                        @update:checked="
                          toggleDiagnosisSelect(diagnosis ?? null)
                        "
                      />
                      <div
                        :class="{
                          'print:block': selectedDiagnoses.includes(
                            diagnosis.diagnosisId
                          ),
                          'print:hidden': !selectedDiagnoses.includes(
                            diagnosis.diagnosisId
                          ),
                        }"
                      >
                        <div class="text-sm">
                          {{ getDiagnosisName(diagnosis.diagnosisRefId) }}
                        </div>
                        <div v-if="diagnosis.stage" class="text-xs italic">
                          {{ getStage(diagnosis.stage) }}
                        </div>
                        <div
                          v-if="diagnosis.diagnosisDate"
                          class="text-xs italic"
                        >
                          Diagnosed
                          {{
                            formatDateTimeUTC(
                              stringToDateTime(diagnosis.diagnosisDate)
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Oncology treatment -->
                  <div class="p-1 m-1">
                    <div class="text-sm"><b>Oncology treatment </b></div>
                    <div
                      v-for="treatment in displayedTreatments"
                      :key="treatment.oncologyTreatmentId"
                    >
                      <div v-if="treatmentIsRecent(treatment)">
                        <div class="text-sm">
                          {{
                            getTreatmentName(treatment.oncologyTreatmentRefId)
                          }}
                        </div>
                        <div
                          v-if="treatment.lastClaimDate"
                          class="text-xs italic"
                        >
                          Latest claim
                          {{
                            formatDateTimeUTC(
                              stringToDateTime(treatment.lastClaimDate)
                            )
                          }}
                        </div>
                        <div
                          v-else-if="treatment.firstClaimDate"
                          class="text-xs italic"
                        >
                          First claim
                          {{
                            formatDateTimeUTC(
                              stringToDateTime(treatment.firstClaimDate)
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div v-if="!displayedTreatments?.length">Unknown</div>
                  </div>
                </div>
                <div>
                  <!-- Comorbidities -->
                  <div class="p-1 m-1">
                    <div class="text-sm"><b>Comorbidities</b></div>
                    <div
                      v-for="comorbidity in displayComorbidities"
                      :key="comorbidity.comorbidityId"
                    >
                      <div class="text-sm">
                        {{ getComorbidityName(comorbidity.comorbidityRefId) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-xs hidden print:block">
          All data shown in this report is from available claims,
          authorizations, and Thyme Care generated information and should be
          used for informational purposes. You should always consider patient
          needs when using this report.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// IMPROVE-ME(MT-989) Unhide or remove this component
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { ButtonSize } from '@thyme/nashville/src/types/buttons'
import flatMap from 'lodash/flatMap'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { jsDateToString, stringToDateTime } from '@/legacy/libs/date'
import { lookupEnum } from '@/legacy/libs/enum'
import {
  formatDateTime,
  formatDateTimeUTC,
  formatNameFromEntity,
  formatName,
  formatPhone,
} from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import LegacyTButton from '@/legacy/nashville/LegacyTButton.vue'
import LegacyTCheckbox from '@/legacy/nashville/LegacyTCheckbox.vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'

import { useComorbidityRefApi } from '@/legacy/store/modules/comorbiditiesReferences'
import { useDiagnosisRefApi } from '@/legacy/store/modules/diagnosesReferences'
import { useOncologyTreatmentRefApi } from '@/legacy/store/modules/oncologyTreatmentsReferences'
import { useOutcomeRefApi } from '@/legacy/store/modules/outcomesReferences'
import {
  useComorbiditiesApi,
  useDiagnosesApi,
  useOncologyTreatmentApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { useProvidersApi } from '@/legacy/store/modules/provider'

import { useSubtasksStore } from '@/legacy/store/modules/subtasks'
import { useTaskApi, useTasksStore } from '@/legacy/store/modules/tasks'
import { Person } from '@/legacy/types/entities/people'
import { Outcome } from '@/legacy/types/outcomes'
import { Diagnosis, Stage } from '@/legacy/types/patients/diagnoses'
import { OncologyTreatment } from '@/legacy/types/patients/oncologyTreatments'
import { Provider } from '@/legacy/types/providers'

const summaryTemplate =
  'FIRST NAME is an AGE GENDER with diagnosis of DIAGNOSIS who has partnered with Thyme Care since ENROLLMENT DATE. HE/SHE/THEY has been treated with CHEMOTHERAPY/RADIATION THERAPY. We have supported FIRST NAME so far in HIS/HER/THEIR cancer journey mostly through ACTIVITY 1 and ACTIVITY 2'

export default defineComponent({
  components: { TSpinner, LegacyTButton, LegacyTCheckbox, TInputText },
  setup() {
    /**
     *
     */
    function print() {
      window.print()
    }

    const logo = '/assets/images/ThymeCare-Logo-Wordmark-Plum.jpg'
    const QRCode = '/assets/images/thymecareQR.svg'

    const patientStore = usePatientStore()
    const { contacts, entity, patient, person, phoneNumbers, providers } =
      storeToRefs(patientStore)

    const { data: oncologyTreatments } = storeToRefs(useOncologyTreatmentApi())
    const { data: diagnoses } = storeToRefs(useDiagnosesApi())
    const { data: tasks } = storeToRefs(useTaskApi())
    const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
    const { data: diagnosisCatalog } = storeToRefs(useDiagnosisRefApi())
    const { data: comorbidityCatalog } = storeToRefs(useComorbidityRefApi())
    const { data: oncologyTreatmentCatalog } = storeToRefs(
      useOncologyTreatmentRefApi()
    )
    const { data: outcomeCatalog } = storeToRefs(useOutcomeRefApi())

    const { auth0Profile } = storeToRefs(useProfileStore())
    const { data: providerCatalog } = storeToRefs(useProvidersApi())
    const route = useRoute()
    const summary = ref(summaryTemplate)
    const to = ref('')
    const toFax = ref('')
    const facility = ref('')
    const from = ref(`${auth0Profile.value?.name ?? ''}, Staff @ Thyme Care`)
    const currentDate = formatDateTime(
      stringToDateTime(jsDateToString(new Date()))
    )

    const currentDateTime = formatDateTime(
      stringToDateTime(jsDateToString(new Date())),
      DateTime.DATETIME_SHORT
    )

    if (patientStore.patient?.entityId !== route.params.patientId) {
      patientStore.$reset()
      void patientStore.fetchPatient(route.params.patientId as string)
      void useSubtasksStore().getSubtasks({
        filter_patient_ids: [route.params.patientId as string],
      })
      void useTasksStore().getTasks({
        filter_patient_ids: [route.params.patientId as string],
      })
    }

    const outcomes = computed(() =>
      tasks.value
        ? flatMap(Object.values(tasks.value), ({ outcomes }) => outcomes)
        : []
    )

    const subtasks = computed(() =>
      tasks.value
        ? flatMap(Object.values(tasks.value), ({ subtasks }) => subtasks)
        : []
    )

    const selectedOutcomes = ref<string[]>([])
    const selectedDiagnoses = ref<string[]>([])

    const toggleOutcomeSelect = (outcome: Outcome | null) => {
      if (!outcome?.outcomeId) {
        return
      }
      const selectedIndex = selectedOutcomes.value.indexOf(outcome.outcomeId)
      if (selectedIndex >= 0) {
        selectedOutcomes.value.splice(selectedIndex, 1)
      } else {
        selectedOutcomes.value.push(outcome.outcomeId)
      }
    }

    const toggleDiagnosisSelect = (diagnosis: Diagnosis | null) => {
      if (!diagnosis?.diagnosisId) {
        return
      }
      const selectedIndex = selectedDiagnoses.value.indexOf(
        diagnosis.diagnosisId
      )
      if (selectedIndex >= 0) {
        selectedDiagnoses.value.splice(selectedIndex, 1)
      } else {
        selectedDiagnoses.value.push(diagnosis.diagnosisId)
      }
    }

    const displayComorbidities = computed(() =>
      comorbidities.value ? Object.values(comorbidities.value) : []
    )

    const displayedContacts = computed(
      () =>
        contacts.value &&
        Object.values(contacts.value)
          .sort((a, b) =>
            a.isPrimaryCaregiver
              ? 1
              : b.isPrimaryCaregiver
              ? -1
              : formatNameFromEntity(a.contactEntity).localeCompare(
                  formatNameFromEntity(b.contactEntity)
                )
          )
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          .slice(0, 4)
    )

    const displayedDiagnoses = computed(
      () =>
        diagnoses.value &&
        uniqBy(Object.values(diagnoses.value), (diagnosis) =>
          getDiagnosisName(diagnosis.diagnosisRefId)
        ).sort((a, b) => (a.diagnosisDate > b.diagnosisDate ? 1 : -1))
    )

    const displayedTreatments = computed(
      () =>
        oncologyTreatments.value &&
        sortBy(
          oncologyTreatments.value,
          (a) => a.lastClaimDate || a.firstClaimDate
        ).reverse()
    )

    const displayedProviders = computed(
      () =>
        providers.value &&
        Object.values(providers.value)
          .filter(({ isPcp }) => !isPcp)
          .sort((a, b) =>
            (
              (getFromProvider(a.providerId, 'primarySpecialty') as string) ??
              ''
            ).localeCompare(
              (getFromProvider(b.providerId, 'primarySpecialty') as string) ??
                ''
            )
          )
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          .slice(0, 2)
    )

    const displayFullName = computed(() =>
      formatName(person.value?.firstName, person.value?.lastName)
    )

    const pcp = computed(() => {
      const pcps =
        (providers.value &&
          Object.values(providers.value).filter(({ isPcp }) => isPcp)) ??
        []
      if (pcps.length) {
        return getProviderName(pcps[0].providerId)
      }
      return ''
    })

    watch(pcp, (newValue) => (to.value = newValue ?? to.value))

    const displayedPhoneNumbers = computed(() => {
      if (phoneNumbers.value && entity.value) {
        const numbers = Object.values(phoneNumbers.value)
          .filter(({ phoneNumberId }) =>
            entity.value?.phoneNumbers?.includes(phoneNumberId)
          )
          .sort(({ isPrimary }) => (isPrimary ? -1 : 1))
          .map(({ phoneNumber }) => formatPhone(phoneNumber.phoneNumber))
        return numbers ?? null
      }
      return null
    })

    const treatmentIsRecent = (treatment: OncologyTreatment) => {
      const oneYearAgo = new Date()
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
      return (
        new Date(treatment.lastClaimDate ?? treatment.firstClaimDate) >=
        oneYearAgo
      )
    }

    const getOutcomeName = (outcomeRefId: string) =>
      safeLookup(outcomeRefId, outcomeCatalog.value)?.title ?? ''

    const getDiagnosisName = (diagnosisRefId: string) =>
      (
        safeLookup(diagnosisRefId, diagnosisCatalog.value)?.description ?? ''
      ).split(': ')[0]

    const getComorbidityName = (comorbidityRefId: string) =>
      safeLookup(comorbidityRefId, comorbidityCatalog.value)?.description ?? ''

    const getTreatmentName = (oncologyTreatmentId: string) =>
      safeLookup(oncologyTreatmentId, oncologyTreatmentCatalog.value)
        ?.description ?? ''

    const getFromProvider = (providerId: string, key: keyof Provider) => {
      const providerRef = safeLookup(providerId, providerCatalog.value)
      return providerRef ? providerRef[key] : null
    }

    const getProviderName = (providerId: string) => {
      const person =
        (getFromProvider(providerId, 'person') as Person) ??
        ({} as Partial<Person>)
      return `${formatName(person.firstName, person.lastName)} ${
        person.suffix ?? ''
      }`
    }

    const getStage = (stageEnum: string) =>
      `Stage ${lookupEnum(Stage, stageEnum)}`

    return {
      patient,
      person,
      subtasks,
      currentDate,
      currentDateTime,
      displayComorbidities,
      displayedContacts,
      displayedDiagnoses,
      displayFullName,
      displayedPhoneNumbers,
      displayedProviders,
      displayedTreatments,
      from,
      logo,
      outcomes,
      QRCode,
      selectedOutcomes,
      selectedDiagnoses,
      summary,
      to,
      toFax,
      facility,
      formatName,
      formatDateTime,
      formatDateTimeUTC,
      formatPhone,
      formatNameFromEntity,
      getComorbidityName,
      getDiagnosisName,
      getFromProvider,
      getProviderName,
      getOutcomeName,
      getStage,
      getTreatmentName,
      stringToDateTime,
      toggleOutcomeSelect,
      toggleDiagnosisSelect,
      treatmentIsRecent,
      print,
      ButtonSize,
    }
  },
})
</script>

<style lang="scss">
.print-section {
  @apply border border-nash-neutral300 rounded m-1 p-1;
}

@media print {
  .page-break {
    page-break-after: always;
  }
  .page {
    height: auto !important;
    overflow: visible !important;
  }
}
</style>
