<template>
  <TMQuaternaryButton v-bind="$attrs" class="p-button-table" outlined />
</template>

<script lang="ts">
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TMQuaternaryButton,
  },
  inheritAttrs: false,
  setup() {
    return {}
  },
})
</script>
