import { array, boolean, date, InferType, object, string } from 'yup'
import { IdMap } from '@/legacy/types/api/store'
import { Nullable, UUID } from '@/legacy/types/global/base'
import { ADDITIONAL_INFO_MAX_CHAR } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'
import { CarePlanTransitionsOfCare } from '@/pages/PatientProfile/CarePlans/shared/types'

export const tocActions = [
  { value: 'edit', label: 'Edit' },
  { value: 'delete', label: 'Delete' },
]

export const manageTransitionsOfCareProps = {
  patientId: {
    type: String,
    required: true,
  },
} as const

export type ManageTocDisplay = {
  dateDisplay: string
  metadataDisplay: Nullable<string>
  transitionsOfCareId: UUID
} & CarePlanTransitionsOfCare

export interface ManageCarePlanTransitionsOfCareDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<CarePlanTransitionsOfCare>
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

export const schema = object({
  admissionDate: date()
    .required()
    .test(
      'is-not-future-date',
      'Admission date must not be in the future.',
      function (admissionDate) {
        return admissionDate < new Date()
      }
    ),
  dischargeDate: date()
    .nullable()
    .test(
      'is-after-admission date',
      'Discharge date must not be before admission date.',
      function (dischargeDate) {
        const { admissionDate } = this.parent
        return admissionDate && dischargeDate
          ? dischargeDate >= admissionDate
          : true
      }
    ),
  eventType: string().required(),
  dischargeDiagnosis: string().nullable(),
  dischargeLocation: string().nullable(),
  isReadmissionRisk: string().nullable(),
  hasNewDmeOnDischarge: boolean().nullable(),
  servicesOnDischarge: array().of(string()).nullable(),
  moreThanThreeAdmissionsInLastSixMonths: boolean().nullable(),
  summaryOfEvents: string()
    .nullable()
    .max(
      ADDITIONAL_INFO_MAX_CHAR,
      ({ value }) =>
        `Maximum length is ${ADDITIONAL_INFO_MAX_CHAR} characters, you have entered ${value.length} characters`
    ),
  hasElectiveAdmission: boolean().nullable(),
})
export type AddOrEditTransitionsOfCareForm = InferType<typeof schema>

export const PAGE_LENGTH = 5
