import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import { Insurance } from '@/legacy/types/patients/insurances'

export const useInsurancesApi = apiStore<Insurance, IdMap<Insurance>>(
  'insurancesApi',
  '/api/patients/insurances',
  {
    transformData: (d: { data: Insurance[] }) =>
      idMapTransform({}, 'data', 'insuranceId', d.data),
  }
)
