// CONST
const DEFAULT_DEBOUNCE_INTERVAL_MS = 300

/**
 * Returns a debounced function.
 *
 * The function can be triggered immediately by calling `.immediate()`
 * @param f
 * @param timeoutMs
 */
export function debounce(
  f: (...args: any[]) => any,
  timeoutMs = DEFAULT_DEBOUNCE_INTERVAL_MS
) {
  let timer: ReturnType<typeof setTimeout>

  const debounced = (...args: any[]) => {
    clearTimeout(timer)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    timer = setTimeout(() => f.apply(context, args), timeoutMs)
  }

  debounced.immediate = f

  return debounced
}
