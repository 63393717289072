import { PropType } from 'vue'
import { object, string, InferType, array, date, boolean } from 'yup'
import { OTHER_STRING } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

// add modal schema
export const schema = object({
  cancerDiagnosisId: string().nullable(),
  oncologySystematicTherapyRefId: string().nullable(),
  otherTreatmentType: string()
    .nullable()
    .when(
      ['oncologySystematicTherapyRefId', 'removed'],
      (keys: any[], schema: { [k: string]: any }) => {
        if (keys[1]) {
          return schema.nullable()
        }
        return keys[0] === OTHER_STRING
          ? schema.required(
              'Other type is required if "Other" option is selected.'
            )
          : schema
      }
    ),
  treatmentStartDate: date().nullable(),
  treatmentEndDate: date().nullable(),
  removed: boolean().nullable(),
})

export const listTreatmentSchemas = object({
  treatmentPayload: array().of(schema).required(),
})

export type AddOrEditTreatmentsForm = InferType<typeof listTreatmentSchemas>
export type AddOrEditTreatmentForm = InferType<typeof schema>

export const addOrEditTreatmentProps = {
  cancerDiagnosisId: {
    type: String,
    required: true,
  },
  treatmentId: {
    type: String,
    default: '',
  },
  initialData: {
    type: Object as PropType<Partial<AddOrEditTreatmentForm>>,
    default: () => ({}),
  },
  showAdd: {
    type: Boolean,
    default: false,
  },
} as const
