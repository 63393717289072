import { PropType } from 'vue'
import { object, string, InferType, array, date, boolean } from 'yup'
import { OTHER_STRING } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'

// add modal schema
export const schema = object({
  otherSurgeryType: string()
    .nullable()
    .when(
      ['surgicalProcedureRefId', 'removed'],
      (keys: any[], schema: { [k: string]: any }) => {
        if (keys[1]) {
          return schema.nullable()
        }
        return keys[0] === OTHER_STRING
          ? schema.required(
              'Other type is required if "Other" option is selected.'
            )
          : schema
      }
    ),
  surgicalProcedureRefId: string().nullable(),
  surgeryDate: date().nullable(),
  cancerDiagnosisId: string().nullable(),
  removed: boolean().nullable(),
})

export const listSurgerySchemas = object({
  surgeryPayload: array().of(schema).required(),
})

export type AddOrEditSurgeriesForm = InferType<typeof listSurgerySchemas>
export type AddOrEditSurgeryForm = InferType<typeof schema>

export const addOrEditSurgeryProps = {
  cancerDiagnosisId: {
    type: String,
    required: true,
  },
  surgeryId: {
    type: String,
    default: '',
  },
  initialData: {
    type: Object as PropType<Partial<AddOrEditSurgeryForm>>,
    default: () => ({}),
  },
  showAdd: {
    type: Boolean,
    default: false,
  },
} as const

export const SURGICAL_REFERENCES_PAGE_LENGTH = 50
