<template>
  <TMAddableItem :active="active" @select="selectDiagnosis">
    <div class="flex flex-col">
      <h5>
        {{ diagnosisReference.description }}
      </h5>
    </div>
    <template #after>
      <div v-if="active" class="space-y-4 mt-4 max-w-lg">
        <div class="flex gap-3 text-nash-neutral600">
          <TDropdown
            class="bg-nash-neutral000 min-w-fit w-1/3"
            placeholder="Type"
            name="diagnosis-type"
            :options="typeOptions"
            option-label="label"
            option-value="value"
            @update:model-value="setType($event)"
          />
          <TDropdown
            class="bg-nash-neutral000 min-w-fit w-1/3"
            placeholder="Stage"
            name="diagnosis-stage"
            :options="stageOptions"
            option-label="label"
            option-value="value"
            @update:model-value="setStage($event)"
          />
          <TDatePicker
            placeholder="Diagnosis date"
            name="diagnosis-date"
            :max-date="today"
            :is-datetime="false"
            :hug-content="true"
            @update:model-value="setDiagnosisDate"
          />
        </div>
      </div>
    </template>
  </TMAddableItem>
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { computed, defineComponent, PropType, ref } from 'vue'
import TDropdown from '@/legacy/nashville/dropdown/TDropdown.vue'
import TMAddableItem from '@/legacy/nashville/molecules/TMAddableItem.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import { today } from '@/legacy/types/global/dates'
import { AddModalActions } from '@/legacy/types/pathways/pathways'
import {
  DiagnosisReference,
  Stage,
  DxType,
} from '@/legacy/types/patients/diagnoses'
export default defineComponent({
  components: { TMAddableItem, TDatePicker, TDropdown },
  props: {
    diagnosisReference: {
      type: Object as PropType<DiagnosisReference>,
      required: true,
    },
  },
  emits: ['add', 'remove', 'setDiagnosisPayload'],
  setup(props, context) {
    const active = ref(false)
    const diagnosisDate = ref<string | null | undefined>(null)
    const dxStage = ref<string | null | undefined>(null)
    const dxType = ref<string | null | undefined>(null)

    const typeOptions = [
      { label: 'Primary', value: DxType.PRIMARY },
      { label: 'Secondary', value: DxType.SECONDARY },
    ]
    const stageOptions = [
      { label: Stage.stage_0, value: 'stage_0' },
      { label: Stage.stage_i, value: 'stage_i' },
      { label: Stage.stage_ii, value: 'stage_ii' },
      { label: Stage.stage_iii, value: 'stage_iii' },
      { label: Stage.stage_iv, value: 'stage_iv' },
    ]

    const getDirtyDiagnosisPayload = computed(() => {
      return {
        diagnosisRefId: props.diagnosisReference.diagnosisRefId,
        source: 'thymebox',
        stage: dxStage.value,
        isPrimary: dxType.value,
        diagnosisDate: diagnosisDate.value,
      }
    })

    const selectDiagnosis = (activeVal: boolean) => {
      if (activeVal) {
        context.emit(
          AddModalActions.ADD,
          props.diagnosisReference.diagnosisRefId
        )
        context.emit('setDiagnosisPayload', {
          ...getDirtyDiagnosisPayload.value,
        })
      } else if (!activeVal) {
        context.emit(
          AddModalActions.REMOVE,
          props.diagnosisReference.diagnosisRefId
        )
        dxStage.value = null
        diagnosisDate.value = null
        dxType.value = null
      }
      active.value = activeVal
    }

    /**
     *
     * @param val
     */
    function setType(val: string) {
      dxType.value = val
      context.emit('setDiagnosisPayload', {
        ...getDirtyDiagnosisPayload.value,
      })
    }
    /**
     *
     * @param val
     */
    function setStage(val: string) {
      dxStage.value = val
      context.emit('setDiagnosisPayload', {
        ...getDirtyDiagnosisPayload.value,
      })
    }

    /**
     * Set datetime for diagnosis date
     * @param selectedDate
     */
    function setDiagnosisDate(selectedDate: Date | null) {
      if (selectedDate) {
        const dateTime = (
          DateTime.fromJSDate(selectedDate).toSQLDate() as string
        ).toString()
        diagnosisDate.value = dateTime
      } else {
        diagnosisDate.value = null
      }

      context.emit('setDiagnosisPayload', {
        ...getDirtyDiagnosisPayload.value,
      })
    }

    return {
      active,
      typeOptions,
      stageOptions,
      today,
      setType,
      setStage,
      setDiagnosisDate,
      selectDiagnosis,
    }
  },
})
</script>
