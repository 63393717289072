import {
  FeatureFlagValues,
  Flag,
  ThymeSplitClient,
} from '@thyme/libs/src/split/client'

// TYPES --------------------
/**
 * Feature flag definitions and supporting types. Add new feature flags in this
 * module.
 * @module
 */

/**
 * Source of truth for Split flags that we use in the app.
 *
 * Values for all flags here will be made available as properties on FlagState.
 * To add a Split flag, declare its type here and define default values in
 * featureFlagDefinitions.
 *
 * To use a flag reactively:
 * `...mapState(useFlagStore, ['myFlagName'])`
 *
 * To subscribe to updates on a flag value from a component:
 * ```
 * this.unwatchFn = flagStore.subscribe((mutation, state) => {
      // Your logic here
    })
 * ```
 */
export type _FeatureFlagDefinition = {
  exampleBooleanFlag: Flag<boolean>
  exampleStringFlag: Flag<string>
  showOncologyOnlyFilter: Flag<boolean> //  CLEANUP(sc/2100)
  showPracticePageLink: Flag<boolean> // CLEANUP(sc/2098)
  canValidateOutcomes: Flag<boolean> // Semi-permanent permission substitute
  showPCPFax: Flag<boolean> // CLEANUP(sc/3146)
  devMode: Flag<string> // CLEANUP(never)
  showZoomCallData: Flag<boolean> //CLEANUP(MT-2193)
  showZusProfileLinks: Flag<boolean> // CLEANUP(MT-2691)
  enableCreateTasksDirectlyFromCommunication: Flag<boolean> // CLEANUP(MT-2873)
  enablePatientContracts: Flag<boolean> // CLEANUP (MT-3032)
  useTcopGeorouting: Flag<boolean> // CLEANUP(MT-2873)
  showSuggestedPlaybooks: Flag<boolean> // CLEANUP(MT-2961)
  envAllowsDirectPlaybookAdminEditing: Flag<boolean> // CLEANUP(never)
  showDuplicatePatientWarning: Flag<boolean> // CLEANUP(MT-3104)
  sortEnrollmentQueueByToc: Flag<boolean> // CLEANUP(MT-3050)
  showHistoricSymptomId: Flag<boolean> // CLEANUP(MT-3081)
  showLastSuccessfulCall: Flag<boolean> //CLEANUP(MT-3107)
  showReportIssue: Flag<boolean> // CLEANUP(MT-3133)
  showNewVersionAvailableBanner: Flag<boolean> // CLEANUP(MT-3170)
  showInformationBanner: Flag<boolean>
  showSocialSummary: Flag<boolean> // CLEANUP(MT-3224)
  showSurfacedStatusReason: Flag<boolean> // CLEANUP(MT-3286)
  showClinicalSummary: Flag<boolean> // CLEANUP(MT-3426)
  showMeosChargeSelection: Flag<boolean> // CLEANUP(SPC-1449)
  useThymelineV2Api: Flag<boolean> // CLEANUP(MT-3404)
  overrideViewAllTasks: Flag<boolean> // CLEANUP(MT-3618)
  showCoolDownFeature: Flag<boolean> // CLEANUP(MT-3707)
  showNewCarePlanReport: Flag<boolean> // CLEANUP(MT-3711)
  showCarePlanImprovements: Flag<boolean> // CLEANUP()
  hideSubtaskEstimatedCompletionTime: Flag<boolean> // CLEANUP(MT-3752)
  showScheduledCallNoTime: Flag<boolean> // CLEANUP(MT-3799)
  showHumanaEligibilityCheckPage: Flag<boolean> // CLEANUP(-----)
  showEnrollmentQueueEnhancements: Flag<boolean> // CLEANUP(MT-3864)
  useStacksIncident121225: Flag<boolean> // CLEANUP(MT-3952)
}

/**
 * The Split client, plus all of the feature flag properties declared in
 * FeatureFlagDefinition mapped to their Flag types (e.g. boolean or string).
 */
export type FlagState = {
  client?: ThymeSplitClient<_FeatureFlagDefinition> | null
  isLoaded: boolean
} & FeatureFlagValues<_FeatureFlagDefinition>

// CONSTANTS --------------------
export const SPLIT_TRAFFIC_TYPE = 'user'

export const _featureFlagDefinitions: Readonly<_FeatureFlagDefinition> = {
  exampleBooleanFlag: {
    local: true,
    fallback: false,
  },
  exampleStringFlag: {
    local: 'local',
    fallback: 'fallback',
  },
  showOncologyOnlyFilter: {
    local: true,
    fallback: false,
  },
  showPracticePageLink: {
    local: true,
    fallback: false,
  },
  canValidateOutcomes: {
    local: true,
    fallback: false,
  },
  showPCPFax: {
    local: true,
    fallback: false,
  },
  devMode: {
    local: '',
    fallback: '',
  },
  showZoomCallData: {
    local: true,
    fallback: false,
  },
  showZusProfileLinks: {
    local: true,
    fallback: false,
  },
  enableCreateTasksDirectlyFromCommunication: {
    local: true,
    fallback: false,
  },
  useTcopGeorouting: {
    local: true,
    fallback: false,
  },
  showSuggestedPlaybooks: {
    local: true,
    fallback: false,
  },
  envAllowsDirectPlaybookAdminEditing: {
    local: true,
    fallback: false,
  },
  sortEnrollmentQueueByToc: {
    local: true,
    fallback: false,
  },
  enablePatientContracts: {
    local: true,
    fallback: false,
  },
  showDuplicatePatientWarning: {
    local: true,
    fallback: false,
  },
  showHistoricSymptomId: {
    local: true,
    fallback: false,
  },
  showLastSuccessfulCall: {
    local: true,
    fallback: false,
  },
  showReportIssue: {
    local: true,
    fallback: false,
  },
  showNewVersionAvailableBanner: {
    local: false,
    fallback: false,
  },
  showInformationBanner: {
    local: false,
    fallback: false,
  },
  showSocialSummary: {
    local: true,
    fallback: false,
  },
  showSurfacedStatusReason: {
    local: true,
    fallback: false,
  },
  showClinicalSummary: {
    local: true,
    fallback: false,
  },
  showMeosChargeSelection: {
    local: true,
    fallback: false,
  },
  useThymelineV2Api: {
    local: true,
    fallback: false,
  },
  overrideViewAllTasks: {
    local: true,
    fallback: false,
  },
  showCoolDownFeature: {
    local: true,
    fallback: false,
  },
  showNewCarePlanReport: {
    local: true,
    fallback: false,
  },
  showCarePlanImprovements: {
    local: true,
    fallback: false,
  },
  hideSubtaskEstimatedCompletionTime: {
    local: true,
    fallback: false,
  },
  showScheduledCallNoTime: {
    local: true,
    fallback: false,
  },
  showHumanaEligibilityCheckPage: {
    local: true,
    fallback: false,
  },
  showEnrollmentQueueEnhancements: {
    local: true,
    fallback: false,
  },
  useStacksIncident121225: {
    local: false,
    fallback: false,
  },
}
