<template>
  <div>
    <div
      class="cursor-pointer flex justify-center items-center space-x-1 text-nash-neutral000"
      @click="togglePopover"
    >
      <TIcon icon="phone" />
      <span>Call</span>
    </div>
    <OverlayPanel ref="showPopover">
      <TDialpad
        @dial="
          (phoneNumber) => {
            makeCall(phoneNumber)
            togglePopover(phoneNumber)
          }
        "
      />
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import TIcon from '@nashville/icon/TIcon.vue'
import OverlayPanel from 'primevue/overlaypanel'
import { defineComponent, ref } from 'vue'
import { dial } from '@/legacy/libs/call'
import TDialpad from '@/legacy/nashville/TDialpad.vue'

export default defineComponent({
  components: {
    TDialpad,
    OverlayPanel,
    TIcon,
  },
  setup() {
    const showPopover = ref()
    const togglePopover = (event: Event) => {
      showPopover.value.toggle(event)
    }
    const makeCall = async (phoneNumber: string) => await dial({ phoneNumber })

    return {
      showPopover,
      togglePopover,
      makeCall,
    }
  },
})
</script>
