import apiStore from '@/legacy/store/modules/apiBuilder'
import { MapOncDxRefSurgicalProcedureRef } from '@/pages/PatientProfile/CarePlans/shared/types'

export const useMapOncDxRefSurgicalProcedureRefApi =
  apiStore<MapOncDxRefSurgicalProcedureRef>(
    'mapOncDxRefSurgicalProcedureRefApi',
    '/api/map_onc_dx_ref_surgical_procedure_ref',
    {
      transformData: (d: { data: MapOncDxRefSurgicalProcedureRef[] }) => d,
    }
  )
