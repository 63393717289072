<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5 override-padding"
  >
    <div class="flex justify-between">
      <div class="w-full relative">
        <TSpinner
          v-if="isLoading"
          name="loading-treatments"
          :partial-page="true"
        />
        <div class="grid-container">
          <!-- Treatment Section -->
          <div class="grid-item section-label">
            <label>Treatment</label>
          </div>
          <div class="grid-item">
            <div>
              <div v-for="(treatment, index) in treatmentDisplay" :key="index">
                {{ treatment.agent }}
                <span v-if="treatment.brands !== '-'">• </span>
                <span
                  v-if="treatment.agent !== '-'"
                  class="text-nash-neutral700"
                >
                  Brands: {{ treatment.brands }}
                </span>
              </div>
            </div>
            <div>
              <TMTertiaryButton
                v-if="showMoreTreatmentsButton"
                name="treatmentShowMore"
                label="Show More"
                class="pt-2 mb-3"
                icon-pos="right"
                :icon="'chevronDown'"
                @click="loadMore(TreatmentField.TREATMENT)"
              />
            </div>
          </div>
          <div class="grid-item edit-button">
            <div v-tooltip.left="'Manage Treatment'">
              <TMSecondaryButton
                icon="pencil"
                :disabled="!cancerDetailsId"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleManageModal(TreatmentField.TREATMENT)"
              />
            </div>
          </div>

          <!-- Surgery Section -->
          <div class="grid-item section-label">
            <label>Surgery</label>
          </div>
          <div class="grid-item">
            <div v-for="(surgery, index) in surgeryDisplay" :key="index">
              {{ surgery }}
            </div>
            <div>
              <TMTertiaryButton
                v-if="showMoreSurgeriesButton"
                label="Show More"
                class="pt-2 mb-3"
                icon-pos="right"
                :icon="'chevronDown'"
                @click="loadMore(TreatmentField.SURGERY)"
              />
            </div>
          </div>
          <div class="grid-item edit-button">
            <div v-tooltip.left="'Manage Surgery'">
              <TMSecondaryButton
                icon="pencil"
                :disabled="!cancerDetailsId"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleManageModal(TreatmentField.SURGERY)"
              />
            </div>
          </div>

          <!-- Radiation Section -->
          <div class="grid-item section-label">
            <label>Radiation</label>
          </div>
          <div class="grid-item">
            <div v-for="(radiation, index) in radiationDisplay" :key="index">
              <div class="flex">
                {{ radiation.radDate }}
                <span v-if="radiation.additionalInfo !== '-'" class="mx-1">
                  •
                </span>
                <TMarkdown
                  v-if="radiation.additionalInfo !== '-'"
                  :source="radiation.additionalInfo"
                />
              </div>
            </div>
            <div>
              <TMTertiaryButton
                v-if="showMoreRadiationDatesButton"
                label="Show More"
                class="pt-2 mb-3"
                icon-pos="right"
                :icon="'chevronDown'"
                @click="loadMore(TreatmentField.RADIATION)"
              />
            </div>
          </div>
          <div class="grid-item edit-button">
            <div v-tooltip.left="'Manage Radiation'">
              <TMSecondaryButton
                icon="pencil"
                :disabled="!cancerDetailsId"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleManageModal(TreatmentField.RADIATION)"
              />
            </div>
          </div>
          <!-- Radiation Last Updated Row -->
          <div class="grid-item section-label">
            <label>Last update</label>
          </div>
          <div class="grid-item last-updated">
            {{ lastUpdatedDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <ManageRadiations
    v-if="showManageRadiationsModal && cancerDetailsId"
    :cancer-dx-id="cancerDetailsId"
    :patient-id="patientId"
    @close="toggleManageModal(TreatmentField.RADIATION)"
    @refetch="refetchRadiations"
  />
  <ManageSurgeries
    v-if="showManageSurgeryModal && cancerDetailsId"
    :cancer-dx-id="cancerDetailsId"
    :patient-id="patientId"
    @close="toggleManageModal(TreatmentField.SURGERY)"
    @refetch="refetchSurgeries"
  />
  <ManageTreatments
    v-if="showManageTreatmentModal && cancerDetailsId"
    :cancer-dx-id="cancerDetailsId"
    :patient-id="patientId"
    @close="toggleManageModal(TreatmentField.TREATMENT)"
    @refetch="refetchTreatments"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import TMarkdown from '@/legacy/nashville/text/TMarkdown.vue'
import { setup } from './controller'
import ManageRadiations from './ManageRadiations/ManageRadiations.vue'
import ManageSurgeries from './ManageSurgeries/ManageSurgeries.vue'
import ManageTreatments from './ManageTreatments/ManageTreatments.vue'

export default defineComponent({
  components: {
    TSpinner,
    TMarkdown,
    TMTertiaryButton,
    TMSecondaryButton,
    ManageRadiations,
    ManageSurgeries,
    ManageTreatments,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 4fr 0.5fr;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0px;
}

.grid-item:nth-child(6n + 1),
.grid-item:nth-child(6n + 2) {
  @apply bg-nash-neutral100;
}

.grid-item:nth-child(6n + 2),
.grid-item:nth-child(9n + 2),
.grid-item:nth-child(5) {
  @apply pl-14;
}

.edit-button {
  display: flex;
  justify-content: end;
}
</style>
