import map from 'lodash/map'
import { computed, Prop } from 'vue'

/** shared Prop setup & utility */

// use property and component name to set the respective PV class
export const setPVClass = (componentName: string, property: string) =>
  property ? `p-${componentName}-${property}` : ''

// convert prop enums to pv class strings automatically
// used with props created by createProp function
export const tokenizedComponentClasses = (
  componentName: string,
  props: { [key: string]: any },
  propsToConvert: string[]
) => {
  return computed(() =>
    map(propsToConvert, (v) => setPVClass(componentName, props[v]))
  )
}

// standard boilerplate for PV string props
export const createProp = (
  allowedVals: { [key: string]: string },
  defaultVal = ''
): Prop<string> => ({
  type: String,
  default: defaultVal,
  validator: (v: string) =>
    !v || Object.values<string>(allowedVals).includes(v),
  // @ts-ignore
  options: allowedVals,
})
