import apiStore from '@/legacy/store/modules/apiBuilder'
import { Treatment } from '@/pages/PatientProfile/CarePlans/shared/types'

export const usePreviousCancerTreatmentApi = apiStore<Treatment>(
  'previousCancerTreatmentApi',
  '/api/care_plan/patient_treatments',
  {
    transformData: (d: { data: Treatment[] }) => d,
  }
)
