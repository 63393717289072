<template>
  <div class="flex justify-stretch gap-2 mb-4">
    <TInputText
      name="search-bar"
      class="grow"
      :placeholder="searchPlaceholder"
      @update:model-value="updateSearch"
    />
    <LegacyTDropdown
      v-if="filterOptions?.length"
      icon="wrench"
      label="Filter"
      name="filter-dropdown"
      class="border border-nash-neutral300"
      size="md"
      :model-value="dirtyFilterValue"
      :multiple="true"
      :options="filterOptions"
      :show-elements-selected="true"
      :expanded="false"
      :rounded="false"
      :unmask="true"
      @update:model-value="updateFilter"
    />
  </div>
</template>

<script lang="ts">
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent, PropType, ref } from 'vue'
import LegacyTDropdown from '@/legacy/nashville/LegacyTDropdown.vue'

export default defineComponent({
  components: { LegacyTDropdown, TInputText },
  props: {
    filterOptions: { type: Array as PropType<string[][]>, default: null },
    searchPlaceholder: { type: String, default: 'Search...' },
  },
  emits: ['updateSearch', 'updateFilter'],
  setup(props, context) {
    const dirtyFilterValue = ref('')

    const updateSearch = (newSearch: string) => {
      context.emit('updateSearch', newSearch)
    }

    const updateFilter = (newFilters: string[]) => {
      context.emit('updateFilter', newFilters)
    }

    return {
      dirtyFilterValue,
      updateFilter,
      updateSearch,
    }
  },
})
</script>
