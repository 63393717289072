import { NO_CACHE } from '@thyme/libs/src/api/types/api'
import { apiRequest } from '@/legacy/libs/api'
import { FormResponse } from '@/legacy/types/forms/formSort'

/**
 *
 * @param subtaskId
 */
export async function getFormResponse(
  subtaskId: string
): Promise<FormResponse | null> {
  return apiRequest('GET', `/api/forms/response/${subtaskId}`, {
    headers: {
      'Cache-Control': NO_CACHE,
    },
  })
}
