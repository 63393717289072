<template>
  <label>
    <div
      v-if="label"
      v-tooltip.top="tooltip"
      :class="` ${boldedLabel ? 'font-bold' : ''} mb-1.5 text-nash-neutral700`"
      data-cy="input-label"
    >
      {{ label }}
    </div>
    <div v-if="label && help" data-cy="input-help">{{ help }}</div>
    <PVTextarea
      class="overflow-scroll"
      :class="{ 'no-border': noBorder, 'overflow-scroll': true }"
      :rows="rows"
      :cols="cols"
      :auto-resize="autoResize"
      :placeholder="placeholder"
      :disabled="disabled"
      :model-value="modelValue"
      :data-cy="`${kebabCase(name)}-textarea`"
      @input="onInput"
      @blur="
        (e) => {
          handleBlur(e)
          $emit('blur', e)
        }
      "
      @keyup="(e) => $emit('keyup', e)"
    />
    <ErrorMessage :name="name" data-cy="warning" class="text-red-500 text-sm" />
  </label>
</template>

<script lang="ts">
import kebabCase from 'lodash/kebabCase'
import Textarea from 'primevue/textarea'
import { ErrorMessage, useField } from 'vee-validate'
import { defineComponent, watch } from 'vue'

export default defineComponent({
  components: {
    PVTextarea: Textarea,
    ErrorMessage,
  },
  props: {
    noBorder: { type: Boolean, default: false },
    rows: { type: Number, default: 5 },
    cols: { type: Number, default: 30 },
    autoResize: { type: Boolean, default: true },
    name: { type: String, required: true },
    modelValue: { type: String, default: '' },
    label: { type: String, default: '' },
    help: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    rules: { type: String, default: '' },
    tooltip: { type: String, default: '' },
    disabled: {
      type: Boolean,
      default: false,
    },
    boldedLabel: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['blur', 'keyup', 'update:modelValue'],
  setup(props, context) {
    const {
      value: inputValue,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.modelValue,
    })

    const onInput = (e: any) => {
      handleChange(e)
      context.emit('update:modelValue', inputValue.value)
    }

    /*
      This watch function ensures updates happen outside component.
      Without this, if updating a TInput that is sharing values with
      another TInput in a different parent component, it'll only
      update for the current TInput in current parent component
      */
    watch(
      () => props.modelValue,
      (modelValue) => handleChange(modelValue)
    )

    return {
      inputValue,
      handleBlur,
      onInput,
      meta,
      kebabCase,
    }
  },
})
</script>
<style>
.second-border:focus {
  box-shadow: 0 0 0 2px #e7c5da;
}

input::placeholder {
  @apply text-nash-neutral700;
}
</style>
