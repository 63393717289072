<template>
  <div
    :data-cy="name && `${name}-grouped-select`"
    class="flex flex-col flex-initial gap-3 overflow-auto h-full"
  >
    <div v-if="multiple && selectAllOption" class="mb-3">
      <TCheckbox
        :model-value="allSelected ? [true] : []"
        :value="true"
        input-id="all"
        data-cy="grouped-select-checkbox-all"
        @click="selectAllClicked"
      />
      <label class="ml-2 cursor-pointer">All</label>
    </div>
    <div v-for="opt in options" :key="opt.label + opt.value">
      <TCheckbox
        v-if="multiple"
        :model-value="data"
        :input-id="opt.value"
        :data-cy="`grouped-select-checkbox-${opt.value}`"
        :value="opt.value"
        :disabled="isDisabled(opt.value)"
        @update:model-value="update"
      />
      <TRadioButton
        v-else
        :model-value="data"
        :input-id="opt.label"
        :data-cy="`grouped-select-radio-${opt.value}`"
        :value="opt.value"
        @update:model-value="update"
      />
      <label v-if="opt.value" :for="opt.value" class="ml-2 cursor-pointer">{{
        opt.label
      }}</label>
    </div>
  </div>
</template>

<script lang="ts">
import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import { paddingProp } from '@thyme/nashville/src/types/shared'
import { PropType, computed, defineComponent } from 'vue'
import TCheckbox from './checkbox/TCheckbox.vue'
import TRadioButton from './radio/TRadioButton.vue'
const sharedProps = {
  ...paddingProp,
}

export default defineComponent({
  components: {
    TCheckbox,
    TRadioButton,
  },
  props: {
    ...sharedProps,
    data: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<DropdownKV[]>,
      required: true,
    },
    selectAllOption: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Object as PropType<string[]>,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const optionsExcludingDisabled = computed(() =>
      props.disabled
        ? props.options.filter((x) => !isDisabled(x.value))
        : props.options
    )

    const selectAllClicked = () => {
      if (allSelected.value) {
        update([])
      } else {
        update(optionsExcludingDisabled.value.map((x) => x.value))
      }
    }
    const allSelected = computed(() => {
      if (!props.selectAllOption || !props.multiple || !props.data) {
        return false
      }
      return props.data.length === optionsExcludingDisabled.value.length
    })
    const update = (newVal: any) => {
      context.emit('update:modelValue', newVal)
    }

    const isDisabled = (val: string | null) =>
      !!val && !!props.disabled?.find((disabledVal) => disabledVal === val)

    return {
      isDisabled,
      update,
      selectAllClicked,
      allSelected,
    }
  },
})
</script>
