import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { initDatadogLogs } from '@thyme/libs/src/datadog/logs'
import { initDatadogRum } from '@thyme/libs/src/datadog/rum'
import { useConfigStore } from '@/legacy/store/modules/config'
import { DEPLOY_VERSION } from '@/version'

/**
 *
 */
export function initializeDatadog() {
  const configStore = useConfigStore()

  if (!configStore?.configVals?.datadog?.enabled) {
    console.debug('Skipping Datadog initialization...')
    return
  }

  const { apiUrl, datadog } = configStore.configVals

  initDatadogLogs(datadog, {
    version: DEPLOY_VERSION,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  })

  initDatadogRum(datadog, {
    version: DEPLOY_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'allow',
    // enable collection of additional data
    allowedTracingUrls: [apiUrl, (url) => url.startsWith(`${apiUrl}/api/`)],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ['clickmap'],
  })

  // persist the client objects in the config store
  configStore.rumClient = datadogRum
  configStore.logsClient = datadogLogs
}
