import { DateTime } from 'luxon'
import { stringToDateTime } from '@/legacy/libs/date'

/**
 *
 * @param arr
 * returns null if array is empty
 * otherwise return original array
 */
export function checkForEmptyArr(arr: string[] | null | undefined) {
  if (arr && arr.length) {
    return arr
  }
  return null
}

/**
 *
 * @param dateStr
 */
export function toDateDisplay(dateStr: string) {
  return stringToDateTime(dateStr).toLocaleString(DateTime.DATE_SHORT)
}
