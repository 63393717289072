import { IdMap } from '@/legacy/types/api/store'
import { UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'
import { SubtaskStatus } from '@/legacy/types/pathways/subtasks'
import { Task, TaskTemplate } from '@/legacy/types/pathways/tasks'
import {
  AUTO_TEXTING_LABEL,
  AUTO_TRIGGER_LABEL,
  MANUALLY_SET_LABEL,
} from '@/legacy/types/pathways/types'
import { CommonTemplateBase, StatusReasonMetadata } from './common'
import { openSubtaskStatuses } from './subtasks'

// TYPES --------------------
export type PathwayPayload = {
  notes: string
  status: PathwayStatus
  goalIds: string[]
}

export type MapPathwayTask = {
  mappingId: UUID
  mappingTemplateId: UUID
  pathwayId: UUID
  taskId: UUID
  sortNumber: number
}

export type ApiPathway = {
  description: string
  goalIds: string[]
  memberId: string
  notes: string | null
  pathwayId: string
  pathwayVariantId: string
  status: string
  statusReason: StatusReason | null
  statusReasonJson: StatusReasonMetadata | null
  statusUpdatedAt: string
  title: string
  generatingTriggerId: UUID | null
  tasks?: Task[]
  taskMaps?: MapPathwayTask[]
} & Timestamps

export type Pathway = {
  description: string
  goalIds: string[]
  memberId: string
  notes: string | null
  pathwayId: string
  pathwayVariantId: string
  status: string
  statusReason: StatusReason | null
  statusReasonJson: StatusReasonMetadata | null
  statusUpdatedAt: string
  title: string
  generatingTriggerId: UUID | null
  tasks?: Task[]
  taskIds?: string[]
  taskMaps?: MapPathwayTask[]
  variantDisplay?: string
} & Timestamps

export type PathwayTemplate = {
  description: string
  pathwayKey: string
  pathwayTemplateId: string
  baseTemplateId: string
  taskTemplates?: TaskTemplate[]
  templateStatus: string
  title: string
  variantId: string
  variantKey: string | null
  variantDisplay: string
} & Timestamps

export type PathwayTemplateBase = {
  escalationTrigger?: string
  metric?: string
  processMap?: string
  trainingDeck?: string
  faqs?: string
  pathwayKey: string
  title: string
  variants?: PathwayTemplate[]
} & CommonTemplateBase

// INTERFACES --------------------
export interface PathwayState {
  openPathwayMenu: boolean
}

export interface PathwayDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<Pathway> | null
  isLoaded: boolean
}

export interface PathwayTemplateDataState {
  isLoading: boolean
  error: Error | null
  data: IdMap<PathwayTemplate> | null
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}

// ENUMS --------------------
export enum AddModalActions {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum StatusReason {
  MANUALLY_SET = 'MANUALLY_SET',
  PROPEGATED_FROM_PARENT = 'PROPEGATED_FROM_PARENT',
  BLOCKED_BY_UPSTREAM = 'BLOCKED_BY_UPSTREAM',
  BLOCKED_BY_MIN_TIME = 'BLOCKED_BY_MIN_TIME',
  AUTOTRIGGER = 'AUTOTRIGGER',
  STALE_CLEANUP = 'STALE_CLEANUP',
  AUTOTEXTING = 'AUTOTEXTING',
  POINTER_COMM_STATUS_UPDATED = 'POINTER_COMM_STATUS_UPDATED',
  CREATED = 'CREATED',
  UNBLOCKED_BY_MIN_TIME = 'UNBLOCKED_BY_MIN_TIME',
  UNBLOCKED_BY_UPSTREAM = 'UNBLOCKED_BY_UPSTREAM',
}

export enum TemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum TaskStatus {
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  BLOCKED = 'BLOCKED',
}

export enum SubStatusReason {
  DUPLICATE = 'DUPLICATE',
  EXPIRED = 'EXPIRED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  MEMBER_REQUEST = 'MEMBER_REQUEST',
  FAILED_TO_CONNECT = 'FAILED_TO_CONNECT',
  OTHER = 'OTHER',
  DO_AT_LATER_DATE = 'DO_AT_LATER_DATE',
}

export enum PathwayStatus {
  ACTIVE = 'ACTIVE',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  BLOCKED = 'BLOCKED',
  SUGGESTED = 'SUGGESTED',
  SUGGESTION_REJECTED = 'SUGGESTION_REJECTED',
}

export enum VariantKey {
  High = 'high_acuity',
  Medium = 'medium_acuity',
  Low = 'low_acuity',
}

// CONSTANTS --------------------
export const DIARRHEA_PATHWAY_NAME = 'Diarrhea'

export const MAX_PATHWAYS = 4

export const GENERIC_COMPLETED_STATUS = 'COMPLETED'

export const pathwayStatusLabels = [
  { label: 'Current', value: PathwayStatus.ACTIVE },
  { label: 'Blocked', value: PathwayStatus.BLOCKED },
  { label: 'On Hold', value: PathwayStatus.ON_HOLD },
  { label: 'Completed', value: PathwayStatus.COMPLETED },
  { label: 'Canceled', value: PathwayStatus.CANCELLED },
  { label: 'Suggested', value: PathwayStatus.SUGGESTED },
  { label: 'Suggestion Rejected', value: PathwayStatus.SUGGESTION_REJECTED },
]

export const taskStatusLabels = [
  { label: 'Current', value: TaskStatus.ACTIVE },
  { label: 'Blocked', value: TaskStatus.BLOCKED },
  { label: 'On Hold', value: TaskStatus.ON_HOLD },
  { label: 'Completed', value: TaskStatus.COMPLETED },
  { label: 'Canceled', value: TaskStatus.CANCELLED },
]

export const subStatusLabels = [
  {
    label: 'Duplicate',
    value: SubStatusReason.DUPLICATE,
    status: SubtaskStatus.CANCELLED,
  },
  {
    label: "Won't Do - Expired",
    value: SubStatusReason.EXPIRED,
    status: SubtaskStatus.CANCELLED,
  },
  {
    label: "Won't Do - Not Required",
    value: SubStatusReason.NOT_REQUIRED,
    status: SubtaskStatus.CANCELLED,
  },
  {
    label: "Won't Do - Member Request",
    value: SubStatusReason.MEMBER_REQUEST,
    status: SubtaskStatus.CANCELLED,
  },
  {
    label: 'Failed to Connect',
    value: SubStatusReason.FAILED_TO_CONNECT,
    status: SubtaskStatus.CANCELLED,
  },
  {
    label: 'To Do At Later Date',
    value: SubStatusReason.DO_AT_LATER_DATE,
    status: SubtaskStatus.ON_HOLD,
  },
  {
    label: 'Other',
    value: SubStatusReason.OTHER,
    status: SubtaskStatus.ON_HOLD,
  },
]

export const closedTaskStatuses = [TaskStatus.CANCELLED, TaskStatus.COMPLETED]

export const closedPathwayStatuses = [
  PathwayStatus.CANCELLED,
  PathwayStatus.COMPLETED,
  PathwayStatus.SUGGESTION_REJECTED,
]

export const suggestionPathwayStatuses = [
  PathwayStatus.SUGGESTED,
  PathwayStatus.SUGGESTION_REJECTED,
]

export type StatusDetails = {
  text: string
  icon: string
  value: SubtaskStatus
  fillType: string | null
  statusOptions: SubtaskStatus[]
}

type SubStatusMap = {
  [key in SubStatusReason]?: string
}

type StatusReasonMap = {
  [key in StatusReason]?: string | SubStatusMap
}

type SubtaskStatusMap = {
  [key in SubtaskStatus]: StatusReasonMap
}
export const subtaskStatusMapping: SubtaskStatusMap = {
  [SubtaskStatus.BLOCKED]: {
    [StatusReason.BLOCKED_BY_MIN_TIME]: 'Blocked by time delay within playbook',
    [StatusReason.BLOCKED_BY_UPSTREAM]: 'Blocked by preceding subtask',
    [StatusReason.PROPEGATED_FROM_PARENT]: 'Set by ThymeBox',
  },
  [SubtaskStatus.CANCELLED]: {
    [StatusReason.AUTOTEXTING]: 'Canceled due to member ineligibility to text',
    [StatusReason.AUTOTRIGGER]: AUTO_TRIGGER_LABEL,
    [StatusReason.BLOCKED_BY_UPSTREAM]: 'Canceled due to preceding subtask',
    [StatusReason.PROPEGATED_FROM_PARENT]:
      'Parent task or playbook was moved to canceled, remaining subtasks canceled',
    [StatusReason.STALE_CLEANUP]: 'Automated stale subtask clean-up',
    [StatusReason.MANUALLY_SET]: {
      [SubStatusReason.DUPLICATE]: 'Duplicate',
      [SubStatusReason.EXPIRED]: "Won't Do - Expired",
      [SubStatusReason.NOT_REQUIRED]: "Won't Do - Not Required",
      [SubStatusReason.MEMBER_REQUEST]: "Won't Do - Member Request",
      [SubStatusReason.FAILED_TO_CONNECT]: 'Failed to Connect',
    },
  },
  [SubtaskStatus.CLOSED_IN_ERROR]: {
    [StatusReason.MANUALLY_SET]: 'Closed in error by Care Team',
  },
  [SubtaskStatus.COMPLETED]: {
    [StatusReason.AUTOTEXTING]: 'Autotext Sent',
    [StatusReason.AUTOTRIGGER]: AUTO_TRIGGER_LABEL,
    [StatusReason.MANUALLY_SET]: 'Completed by Care Team',
    [StatusReason.POINTER_COMM_STATUS_UPDATED]:
      'Associated Communication was updated',
  },
  [SubtaskStatus.IN_PROGRESS]: {
    [StatusReason.AUTOTEXTING]: AUTO_TEXTING_LABEL,
    [StatusReason.MANUALLY_SET]: MANUALLY_SET_LABEL,
  },
  [SubtaskStatus.ON_HOLD]: {
    [StatusReason.AUTOTEXTING]: AUTO_TEXTING_LABEL,
    [StatusReason.POINTER_COMM_STATUS_UPDATED]:
      'Associated Communication was updated',
    [StatusReason.PROPEGATED_FROM_PARENT]: 'Set by ThymeBox',
    [StatusReason.MANUALLY_SET]: {
      [SubStatusReason.DO_AT_LATER_DATE]: 'To Do At Later Date',
      [SubStatusReason.OTHER]: 'Other',
    },
  },
  [SubtaskStatus.OPEN_ASSIGNED]: {
    [StatusReason.MANUALLY_SET]: MANUALLY_SET_LABEL,
  },
  [SubtaskStatus.OPEN_UNASSIGNED]: {
    [StatusReason.AUTOTRIGGER]: AUTO_TRIGGER_LABEL,
    [StatusReason.CREATED]: '',
    [StatusReason.MANUALLY_SET]: MANUALLY_SET_LABEL,
    [StatusReason.PROPEGATED_FROM_PARENT]: '',
    [StatusReason.UNBLOCKED_BY_MIN_TIME]:
      'Unblocked due to timing within playbook',
    [StatusReason.UNBLOCKED_BY_UPSTREAM]: 'Unblocked by preceding subtask',
  },
  [SubtaskStatus.REATTEMPT]: {},
}

export const SubtaskStatuses: { [Key: string]: StatusDetails } = {
  OPEN_UNASSIGNED: {
    text: 'Open Unassigned',
    icon: 'openStatus',
    value: SubtaskStatus.OPEN_UNASSIGNED,
    fillType: 'nash-neutral600',
    statusOptions: [
      ...openSubtaskStatuses,
      SubtaskStatus.CANCELLED,
      SubtaskStatus.ON_HOLD,
    ],
  },
  OPEN_ASSIGNED: {
    text: 'Open Assigned',
    icon: 'openStatus',
    value: SubtaskStatus.OPEN_ASSIGNED,
    fillType: 'nash-neutral600',
    statusOptions: [
      ...openSubtaskStatuses,
      SubtaskStatus.COMPLETED,
      SubtaskStatus.CANCELLED,
      SubtaskStatus.ON_HOLD,
    ],
  },
  IN_PROGRESS: {
    text: 'In Progress',
    icon: 'inProgressStatus',
    value: SubtaskStatus.IN_PROGRESS,
    fillType: 'nash-neutral600',
    statusOptions: [
      SubtaskStatus.REATTEMPT,
      SubtaskStatus.IN_PROGRESS,
      SubtaskStatus.OPEN_ASSIGNED,
      SubtaskStatus.COMPLETED,
      SubtaskStatus.CANCELLED,
      SubtaskStatus.ON_HOLD,
    ],
  },
  REATTEMPT: {
    text: 'Reattempt',
    icon: 'reattempt',
    value: SubtaskStatus.REATTEMPT,
    fillType: 'nash-teal700',
    statusOptions: [
      SubtaskStatus.REATTEMPT,
      SubtaskStatus.IN_PROGRESS,
      SubtaskStatus.OPEN_ASSIGNED,
      SubtaskStatus.COMPLETED,
      SubtaskStatus.CANCELLED,
      SubtaskStatus.ON_HOLD,
    ],
  },
  COMPLETED: {
    text: 'Completed',
    icon: 'completedStatus',
    value: SubtaskStatus.COMPLETED,
    fillType: 'nash-fern600',
    statusOptions: [SubtaskStatus.CLOSED_IN_ERROR, SubtaskStatus.COMPLETED],
  },
  ON_HOLD: {
    text: 'On Hold',
    icon: 'onHoldStatus',
    value: SubtaskStatus.ON_HOLD,
    fillType: 'nash-purple500',
    statusOptions: [
      SubtaskStatus.IN_PROGRESS,
      SubtaskStatus.OPEN_ASSIGNED,
      SubtaskStatus.CANCELLED,
      SubtaskStatus.ON_HOLD,
    ],
  },
  BLOCKED: {
    text: 'Blocked',
    icon: 'blockedStatus',
    value: SubtaskStatus.BLOCKED,
    fillType: 'nash-brick700',
    statusOptions: [SubtaskStatus.BLOCKED, SubtaskStatus.CANCELLED],
  },
  CANCELLED: {
    text: 'Canceled',
    icon: 'cancelledStatus',
    value: SubtaskStatus.CANCELLED,
    fillType: 'nash-neutral600',
    statusOptions: [SubtaskStatus.CLOSED_IN_ERROR, SubtaskStatus.CANCELLED],
  },
  CLOSED_IN_ERROR: {
    text: 'Closed In Error',
    icon: 'cancelledStatus',
    value: SubtaskStatus.CLOSED_IN_ERROR,
    fillType: 'nash-neutral600',
    statusOptions: [SubtaskStatus.CLOSED_IN_ERROR],
  },
}
