<template>
  <div class="pb-4 px-2 space-y-4 mr-2">
    <h4 class="font-semibold">Admin Tools</h4>
    <div v-if="shouldAllowApiUrlSwitch">
      <TSelect
        v-model="apiUrl"
        label="API URL"
        :options="ApiUrls"
        name="api-url-select"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'

import { computed, defineComponent } from 'vue'
import TSelect from '@/legacy/nashville/TSelect.vue'

import { useConfigStore } from '@/legacy/store/modules/config'

export default defineComponent({
  components: {
    TSelect,
  },
  setup() {
    const { configVals, shouldAllowApiUrlSwitch } = storeToRefs(
      useConfigStore()
    )

    const ApiUrls = [
      'http://localhost:5001',
      'https://api.staging.thymecare.health',
    ]

    const apiUrl = computed({
      get(): string {
        return configVals.value?.apiUrl ?? ''
      },
      set(url: string) {
        void useConfigStore().setApiUrl(url)
      },
    })

    return {
      shouldAllowApiUrlSwitch,
      apiUrl,
      ApiUrls,
    }
  },
})
</script>
