import { ApiError } from '@thyme/libs/src/api/apiRequest'

// TYPES --------------------
export type Notification = {
  message: string | ApiError | Error | null
  description?: string
  type: NotificationType
  error?: string | Error | undefined
}

export type NotificationState = {
  notification: Notification | null
}

// ENUMS --------------------
export enum NotificationType {
  DANGER = 'danger',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum NotificationActions {
  CREATE = 'creating',
  UPDATE = 'updating',
  DELETE = 'deleting',
}

// CONSTANTS --------------------
export const notificationClasses = [
  'font-bold',
  'w-full',
  'items-center',
  'flex',
  'justify-between',
]

export const notificationTypeToIcon = {
  [NotificationType.DANGER]: 'heroicons:x-circle',
  [NotificationType.INFO]: 'heroicons:information-circle',
  [NotificationType.SUCCESS]: 'heroicons:check-20-solid',
  [NotificationType.WARNING]: 'heroicons:exclamation-triangle',
  '': '',
}

export const notificationTypeToColor = {
  [NotificationType.DANGER]: 'bg-nash-brick600',
  [NotificationType.INFO]: 'bg-nash-midnightBlue700',
  [NotificationType.SUCCESS]: 'bg-nash-fern600',
  [NotificationType.WARNING]: 'bg-nash-tigerlily800',
  '': '',
}
