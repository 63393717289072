import {
  formatNameFromEntity,
  formatNameFromPerson,
} from '@/legacy/libs/format'
import { nullifyIfEmptyString } from '@/legacy/libs/string'
import { isTextableAndOptedIn } from '@/legacy/store/modules/lib/phoneNumbers'
import { IdMap, Normalized } from '@/legacy/types/api/store'
import { Entity } from '@/legacy/types/entities/entities'
import { Person } from '@/legacy/types/entities/people'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { Contact } from '@/legacy/types/patients/contacts'

export type PhoneDropdownValue = {
  phoneNumberId?: string | null
  entityId?: string | null
}

/**
 *
 * @param phones
 * @param mustBeTextable
 */
export function preferredPhoneId(
  phones: MapEntityPhoneNumber[],
  mustBeTextable = false
) {
  if (mustBeTextable) {
    phones = phones.filter((map) => isTextableAndOptedIn(map.phoneNumber))
  }
  const preferred = phones.find((p) => p.isPrimary)
  if (preferred) {
    return preferred.phoneNumberId
  }
  if (phones.length) {
    return phones[0].phoneNumberId
  }
  return null
}

/**
 *
 * @param idMap
 */
export function idMapToString(idMap: PhoneDropdownValue) {
  return `${idMap.entityId ?? ''},${idMap.phoneNumberId ?? ''}`
}

/**
 *
 * @param selection
 */
export function idMapFromString(selection: string) {
  const [entityId, phoneNumberId] = selection.split(',')
  return {
    entityId: nullifyIfEmptyString(entityId),
    phoneNumberId: nullifyIfEmptyString(phoneNumberId),
  }
}

/**
 *
 * @param contacts
 * @param patientEntity
 * @param patientPhoneNumbers
 * @param patientPerson
 */
export function patientAndContactEntities(
  contacts: IdMap<Contact>,
  patientEntity: Normalized<Entity, 'phoneNumbers' | 'addresses'>,
  patientPhoneNumbers: IdMap<MapEntityPhoneNumber>,
  patientPerson: Person
): Entity[] {
  let entities: Entity[] = [
    {
      ...patientEntity,
      person: patientPerson,
      phoneNumbers: Object.values(patientPhoneNumbers),
    } as Entity,
  ]
  const contactEntities = Object.values(contacts ?? {}).map(
    (c) => c.contactEntity
  )
  entities = entities.concat(contactEntities)
  return entities
}

export const MANUAL_CALL_ID = 'MANUAL_CALL'

/**
 *
 * @param contacts
 * @param patientEntity
 * @param patientPerson
 */
export function patientAndContactIdDropdown(
  contacts: IdMap<Contact>,
  patientEntity: Normalized<Entity, 'phoneNumbers' | 'addresses'>,
  patientPerson: Person
) {
  return [
    {
      value: patientEntity.entityId,
      label: formatNameFromPerson(patientPerson),
    },
    ...Object.values(contacts ?? {}).map((c) => ({
      value: c.contactEntity.entityId,
      label: `${formatNameFromEntity(c.contactEntity)} (${
        c.relationshipToPatient ?? 'contact'
      })`,
    })),
    {
      value: MANUAL_CALL_ID,
      label: 'N/A (Manual Call)',
    },
  ]
}
