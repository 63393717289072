import {
  Client as ConversationsClient,
  Conversation,
  Message,
  Participant,
} from '@twilio/conversations'
import { cloneDeep, filter } from 'lodash'
import { defineStore, storeToRefs } from 'pinia'
import { markRaw } from 'vue'
import {
  LIMIT_PER_PHONE_NUMBER,
  SMS_COMMS_FOR_ACTIVE_THREAD_SORT_BY,
  SMS_COMM_BY_PHONE_NUMBER_ID_SORT_BY,
  getContactsPhoneNumbersList,
  getConvosForSmsComms,
  getMediaFromMessages,
  getMessagesForConvos,
  getOpenPointerSubtasks,
  getPatientPhoneNumbersList,
  getPointerSubtaskByCommunicationId,
} from '@/legacy/components/texting/lib/sharedTextingParts'
import { idMapTransform, toIdMap } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useCommunicationsStore } from '@/legacy/store/modules/communications'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useSmsThreadsApi } from '@/legacy/store/modules/smsThreads'
import { ApiState } from '@/legacy/types/api/apiBuilder'
import { IdMap } from '@/legacy/types/api/store'
import {
  CommunicationType,
  CommunicationParts,
  TextCommunication,
} from '@/legacy/types/communications/communications'
import {
  SmsCommunicationResponse,
  TextingStoreState,
  TokenResponse,
} from '@/legacy/types/communications/texting'
import {
  MapEntityPhoneNumberPersonSms,
  PhoneNumberType,
} from '@/legacy/types/entities/phoneNumbers'
import {
  Subtask,
  SubtaskState,
  SubtaskStatus,
} from '@/legacy/types/pathways/subtasks'

// CONST
const POINTER_SUBTASK_FETCH_TIMEOUT = 1000
export const DEFAULT_PER_PAGE = 5
export const DEFAULT_PAGE_NUM = 1
export const SMS_PARTS = [
  CommunicationParts.patients,
  CommunicationParts.smsThread,
]

const initialState = (): TextingStoreState => ({
  // Twilio
  _conversationsClient: null,
  conversationsClientError: null,
  conversationsById: {},
  clientLoading: false,

  // Active Conversation View
  activeConversation: null,
  activeCommunication: null,
  smsCommsForActiveConversation: null,
  messages: [],
  conversations: [],
  messageToSend: '',
  mediaLinks: {},
  messagesLoading: false,
  conversationsLoading: false,
  messageDeliveryStatusMapBySid: {},
  conversationsError: null,
  messagesError: null,

  // Texting Inbox View
  smsCommsByPhoneNumberId: null,
  messageMapByConvoSid: {},
  inboxMessagesLoading: false,
  inboxMessagesError: null,
  inboxConversationsError: null,

  // Shared Data
  textableIndividuals: [],
  allTextablePhoneNumberIds: [],
})

export const useTextingStore = defineStore('textingV2', {
  state: () => cloneDeep(initialState()),
  actions: {
    initConversationsClient(conversationsClient: ConversationsClient | null) {
      this.conversationsClientError = null
      /**
       * Skip converting the ConversationsClient to a proxy. Prevents Vue's deep reactivity,
       * but avoids errors caused by converting readonly properties of ConversationsClient.
       * See more: https://v3.vuejs.org/api/basic-reactivity.html#toraw
       */
      // @ts-ignore
      this._conversationsClient = conversationsClient
        ? markRaw(conversationsClient)
        : conversationsClient
    },
    setConversationsClientError(error: Error | null) {
      this.conversationsClientError = error
    },
    setConversations(conversation: Conversation) {
      const foundIndex = this.conversations?.findIndex(
        (convo) => convo.sid === conversation.sid
      )
      if (foundIndex) {
        this.conversations[foundIndex] = conversation
      } else {
        this.conversations = [...this.conversations, conversation]
      }
    },
    removeConversation(thisConversation: any) {
      this.conversations = [
        ...this.conversations.filter(
          (conversation: any) => conversation !== thisConversation
        ),
      ]
    },
    async getConversation(conversationSid: string) {
      return (
        (await this._conversationsClient?.getConversationBySid(
          conversationSid
        )) ?? null
      )
    },
    async setActiveConversation(communication: TextCommunication) {
      this.activeCommunication = communication

      const twilioConversationIds =
        communication.smsThread.twilioConversationIds

      if (!twilioConversationIds.length) {
        this.conversationsError = `Error: No conversation IDs found for this communication ${communication.communicationId}`
      }

      try {
        if (twilioConversationIds.length === 1) {
          this.activeConversation = await this.getConversation(
            twilioConversationIds[0]
          )
        } else {
          const conversations = await Promise.all(
            twilioConversationIds.map(
              async (conversationSid: string) =>
                await this.getConversation(conversationSid)
            )
          )
          const sortedConversations = conversations.sort(
            (a: any, b: any) =>
              b?.lastMessage?.dateCreated - a?.lastMessage?.dateCreated
          )
          if (sortedConversations[0]) {
            this.activeConversation = sortedConversations[0]
          }
        }
        if (this.activeConversation) {
          this.activeConversation.on(
            'messageAdded',
            async (message: Message) => {
              // For testing in prod/staging: Log when a message is added to a conversation
              console.debug(
                `TWILIO: message ${message.sid} added for Conversation ${message.conversation.sid}`
              )
              await this.markActiveSmsThreadIncludesManualTexts()
            }
          )
          void this.activeConversation.setAllMessagesRead()
        }
      } catch (err) {
        console.error('Error: Cannot set active conversation ... ', err)
        return
      }
    },
    async refreshActiveCommunication() {
      const communicationId = this.activeCommunication?.communicationId
      if (communicationId) {
        const communication =
          await useCommunicationsStore().fetchCommunicationById(communicationId)
        this.activeCommunication = communication
      }
    },

    async markActiveSmsThreadIncludesManualTexts() {
      const communicationId = this.activeCommunication?.communicationId
      if (
        communicationId &&
        !this.activeCommunication?.smsThread?.includesManualTexts
      ) {
        await useSmsThreadsApi().partialUpdate({
          ids: [communicationId],
          body: { includesManualTexts: true },
        })
        await this.refreshActiveCommunication()
      }
    },

    async init() {
      // if there's a working conversationsClient, don't reinitialize
      if (this._conversationsClient && !this.conversationsClientError) {
        console.warn('Existing Twilio conversationsClient, skipping reinit...')
        return
      }

      console.warn('Initializing Twilio conversationsClient...')
      this.clientLoading = true
      let conversationsClient
      try {
        const token = await this.getToken()
        conversationsClient = new ConversationsClient(token as string)
      } catch (err) {
        if (err instanceof Error) {
          this.setConversationsClientError(err)
        }
        return
      }

      this.initConversationsClient(conversationsClient)
      conversationsClient.on(
        'conversationUpdated',
        async ({ conversation, updateReasons }: any) => {
          // For testing in prod/staging: Log when a conversation is updated
          console.debug('TWILIO: Conversation updated', conversation.sid)
          console.debug('TWILIO: Update reasons', updateReasons)

          // reset any errors on successful registration
          this.setConversationsClientError(null)
          this.setConversations(conversation)

          if (updateReasons?.length) {
            const messageAdded = updateReasons.includes('lastMessage')
            if (messageAdded) {
              await this.fetchAndSetSMSCommByPhoneNumberIds()
            }
          }
        }
      )
      this.clientLoading = false

      conversationsClient.on(
        'participantJoined',
        async (participant: Participant) => {
          // For testing in prod/staging: Log when a participant joins the conversation
          // e.g. updating responsible staff ID
          console.debug(
            'TWILIO: participant joined',
            participant.conversation.sid
          )
          // Re-fetch data when changing responsible staff / owner for SMS communications
          await this.fetchAndSetSMSCommByPhoneNumberIds()
        }
      )

      // IMPROVEME(MT-2776): Ensure fetchAndSetSMSCommByPhoneNumberIds() called for inbound SMS
      conversationsClient.on(
        'conversationJoined',
        async (conversation: Conversation) => {
          // For testing in prod/staging: Log when a conversation is joined
          console.debug('TWILIO: Conversation joined', conversation.sid)
          this.setConversationsClientError(null)
          this.setConversations(conversation)
        }
      )

      conversationsClient.on(
        'conversationLeft',
        async (conversation: Conversation) => {
          // For testing in prod/staging: Log when a conversation is left
          console.debug('TWILIO: Conversation left', conversation.sid)
          console.debug('TWILIO: Conversation left convo', conversation)
          console.debug(
            'TWILIO: Conversation left comm',
            this.activeCommunication
          )

          console.debug('--Before settimeout--')
          setTimeout(this.callPointerSubtasks, POINTER_SUBTASK_FETCH_TIMEOUT)
          console.debug('--After settimeout--')

          // reset any errors on successful registration
          this.setConversationsClientError(null)
          this.removeConversation(conversation)
        }
      )

      conversationsClient.on('connectionError', (err: any) => {
        console.error('A Twilio conversationsClient error has occurred:', err)
        this.setConversationsClientError(err)
      })

      conversationsClient.on(
        'messageUpdated',
        async ({ message, updateReasons }) => {
          // For testing in prod/staging: Log when a message is updated
          console.debug('TWILIO: Message updated', message, updateReasons)
        }
      )
    },
    async callPointerSubtasks() {
      if (
        this.activeCommunication &&
        this.activeCommunication.patients?.length
      ) {
        const currentCommId = this.activeCommunication.communicationId
        const patientId = this.activeCommunication.patients[0].entityId
        try {
          await getPointerSubtaskByCommunicationId(currentCommId, patientId)
          await getOpenPointerSubtasks(patientId)
        } catch (error) {
          console.error('Error fetching pointer subtasks:', error)
        }
      }
    },
    async getToken() {
      let data: TokenResponse | null
      try {
        data = await useTwilioTokenApi().list({})
      } catch (err) {
        console.error(err)
        if (err instanceof Error) {
          this.conversationsClientError = err
        }
        return
      }
      return data?.token
    },
    /**
     * Below fetch method has several implicit params.
     * This function is used to fetch most recent sms
     * communication per phone number id passed in query.
     *
     * It will always grab 1 sms communication per phone number
     * and sorted by most recently updated sms thread; thus,
     * phone number ids are always required.
     * @param root0
     * @param root0.phoneNumberIds
     * @param root0.entityIds
     * @param root0.perPage
     * @param root0.pageNumber
     * @param root0.fetchAll
     */
    async getSmsCommByPhoneNumberIds({
      phoneNumberIds,
      entityIds,
      perPage = DEFAULT_PER_PAGE,
      pageNumber = DEFAULT_PAGE_NUM,
      fetchAll = false,
    }: {
      phoneNumberIds: string[]
      entityIds?: null | string[] | undefined
      perPage?: null | number | undefined
      pageNumber?: null | number | undefined
      fetchAll?: boolean
    }) {
      let results
      if (fetchAll) {
        results = await useSmsCommByPhoneNumberIdApi().listAll({
          params: {
            limit_per_phone_number: LIMIT_PER_PHONE_NUMBER,
            filter_phone_number_ids: phoneNumberIds,
            filter_types: CommunicationType.Text,
            sort_by: SMS_COMM_BY_PHONE_NUMBER_ID_SORT_BY,
            parts: SMS_PARTS,
            ...(entityIds ? { filter_patient_ids: entityIds } : {}),
          },
        })
      } else {
        results = await useSmsCommByPhoneNumberIdApi().list({
          params: {
            limit_per_phone_number: LIMIT_PER_PHONE_NUMBER,
            filter_phone_number_ids: phoneNumberIds,
            filter_types: CommunicationType.Text,
            sort_by: SMS_COMM_BY_PHONE_NUMBER_ID_SORT_BY,
            page_length: perPage,
            page_number: pageNumber,
            parts: SMS_PARTS,
            ...(entityIds ? { filter_patient_ids: entityIds } : {}),
          },
        })
      }
      if (results?.data.length) {
        this.smsCommsByPhoneNumberId = results
        await this.setMessageMapByConvoSid()
        return results.data
      }
      return null
    },
    /**
     * This function is used to fetch first 10 communications
     * for current selected individual in texting inbox UI.
     * @param root0
     * @param root0.phoneNumberIds
     * @param root0.entityIds
     * @param root0.perPage
     * @param root0.pageNumber
     */
    async getSmsCommsForActiveThread({
      phoneNumberIds,
      entityIds,
      perPage = DEFAULT_PER_PAGE,
      pageNumber = DEFAULT_PAGE_NUM,
    }: {
      phoneNumberIds?: null | string[] | undefined
      entityIds?: null | string[] | undefined
      perPage?: null | number | undefined
      pageNumber?: null | number | undefined
    }) {
      const results = await useTextingCommsApiV2().list({
        params: {
          page_length: perPage,
          page_number: pageNumber,
          filter_types: CommunicationType.Text,
          sort_by: SMS_COMMS_FOR_ACTIVE_THREAD_SORT_BY,
          parts: SMS_PARTS,
          ...(entityIds ? { filter_patient_ids: entityIds } : {}),
          ...(phoneNumberIds
            ? { filter_phone_number_ids: phoneNumberIds }
            : {}),
        },
      })
      if (results?.data.length) {
        this.smsCommsForActiveConversation = results
        await this.setActiveConversation(results.data[0])
        return {
          data: results.data,
          total: results.queryMetadata.total,
        }
      }
      return null
    },
    /**
     * This function is used to fetch additional sets of
     * 10 sms communications for current selected individual in texting inbox UI.
     * This method is triggered if "Load more" is clicked in the
     * AllSms.vue component.
     * @param root0
     * @param root0.phoneNumberIds
     * @param root0.entityIds
     * @param root0.perPage
     * @param root0.pageNumber
     */
    async loadMoreSmsCommsForActiveThread({
      phoneNumberIds,
      entityIds,
      perPage = DEFAULT_PER_PAGE,
      pageNumber = DEFAULT_PAGE_NUM,
    }: {
      phoneNumberIds: null | string[]
      entityIds: null | string[]
      pageNumber: null | number
      perPage?: null | number | undefined
    }) {
      const results = await useTextingCommsApiV2().list({
        params: {
          page_length: perPage,
          page_number: pageNumber,
          filter_types: CommunicationType.Text,
          sort_by: SMS_COMMS_FOR_ACTIVE_THREAD_SORT_BY,
          parts: SMS_PARTS,
          ...(entityIds ? { filter_patient_ids: entityIds } : {}),
          ...(phoneNumberIds
            ? { filter_phone_number_ids: phoneNumberIds }
            : {}),
        },
      })
      if (
        results?.data.length &&
        this.smsCommsForActiveConversation?.data.length
      ) {
        this.smsCommsForActiveConversation.data.push(...results.data)
      }
    },
    /**
     *
     * Helper method to set up a customized list of data from
     * contacts, phone numbers and person data.
     * This data is shared across all texting thymeline child components
     * in order to best identify which textable individual is currently
     * selected for texting.
     */
    setUpTextableIndividualsAndNumbers() {
      const { contacts, phoneNumbers, person } = storeToRefs(usePatientStore())
      let patientPhoneNumbersList: MapEntityPhoneNumberPersonSms[] = []
      let contactsPhoneNumbersList: MapEntityPhoneNumberPersonSms[] = []
      if (phoneNumbers.value && person.value) {
        // Set up patient's phone numbers list using person and phone number data
        // from patient store: [{ person, ...phoneNumber1 }, { person, ...phoneNumber2 },... ]
        patientPhoneNumbersList = getPatientPhoneNumbersList(
          phoneNumbers.value,
          person.value
        )
      }
      if (contacts.value) {
        /**
         *
         * If patient has contacts, set up list of contacts and their phone numbers list:
         * [
         *   { contactPerson1, ...phoneNumber1 },
         *   { contactPerson1, ...phoneNumber2 },
         *   { contactPerson2, ...phoneNumber1 },
         *   ...
         * ]
         * Each contact data will have additional info `relationshipToPatient`
         */
        contactsPhoneNumbersList = getContactsPhoneNumbersList(contacts.value)
      }

      // Join both sets of data to a list of specific data:
      // person, phone number and contact information
      const allEntities = [
        ...patientPhoneNumbersList,
        ...contactsPhoneNumbersList,
      ]

      // Set the store's value for `textableIndividuals` while filtering out
      // any phone numbers that are of type LANDLINE
      this.textableIndividuals = filter(
        allEntities,
        (entity: MapEntityPhoneNumberPersonSms) =>
          entity.phoneNumber.type !== PhoneNumberType.LANDLINE &&
          entity.phoneNumber.type !== PhoneNumberType.OTHER &&
          entity.phoneNumber.type !== PhoneNumberType.VOIP
      )

      // Using above data, set all textable phone number ids as a string array
      // for easy access across components
      this.allTextablePhoneNumberIds = this.textableIndividuals.map(
        (textable) => textable.phoneNumberId
      )
    },
    /**
     *
     * Function that fetches most recent sms communications
     * per phone number ids passed as query param.
     * Also sets the smsThreadByPhoneNumberMap ref:
     * e.g. { <phoneNumberId>: <smsThread>  }
     * @param patientId
     */
    async fetchAndSetSMSCommByPhoneNumberIds(patientId?: string | undefined) {
      useTextingStore().setUpTextableIndividualsAndNumbers()
      if (this.allTextablePhoneNumberIds) {
        await useTextingStore().getSmsCommByPhoneNumberIds({
          phoneNumberIds: this.allTextablePhoneNumberIds,
          entityIds: [patientId ?? usePatientStore().patient?.entityId ?? ''],
        })
      }
    },
    /**
     *
     * Function that gets conversations for fetched sms communications and each conversation's messages.
     * When messages are successfully fetched, sets messageMapByConvoSid:
     * e.g. { <conversationSid>: [Message, Message, Message]  }
     *
     * Also sets textingInboxDataError ref if any errors occurred when fetching conversations or messages
     */
    async setMessageMapByConvoSid() {
      if (this.smsCommsByPhoneNumberId) {
        this.conversationsLoading = true
        const conversationData = await getConvosForSmsComms(
          this.smsCommsByPhoneNumberId.data
        )
        if (conversationData?.convos?.length) {
          for (let i = 0; i < conversationData.convos.length; i++) {
            const convo = conversationData.convos[i]
            // IMPROVEME(MT-2755): find a way to parallelize Twilio calls if it's even possible
            this.inboxMessagesLoading = true
            const convoMessagesData = await getMessagesForConvos([convo])
            if (convoMessagesData?.messages.length) {
              this.messageMapByConvoSid[convo.sid] = convoMessagesData.messages
            }
            if (convoMessagesData?.error) {
              this.inboxMessagesError = convoMessagesData.error
            }
          }
        } else if (conversationData?.error) {
          this.inboxConversationsError = conversationData.error
        }

        this.conversationsLoading = false
        this.inboxMessagesLoading = false
      }
    },
    /**
     * Function that gets all conversations for given sms communications
     * and sets the conversations ref and messagesError ref
     */
    async getAndSetConversationsForIndividual() {
      if (this.smsCommsForActiveConversation) {
        this.conversationsLoading = true
        this.messagesLoading = true
        const conversationData = await getConvosForSmsComms(
          this.smsCommsForActiveConversation.data
        )
        if (conversationData) {
          this.conversations = conversationData.convos
          this.conversationsError = conversationData.error
        }
      }
      this.conversationsLoading = false
    },
    /**
     *
     * Function that retrieves all messages for passed conversations list
     * and also sets:
     * - messages ref --> all messages for all conversations
     * - messagesError ref --> any errors that occurred when fetching messages
     * - messagesMappedByDate ref --> mapping of messages to date string of sent date
     */
    async getAndSetMessagesForIndividual() {
      if (this.conversations.length > 0) {
        const messagesData = await getMessagesForConvos(
          this.conversations as Conversation[]
        )
        this.messages = messagesData?.messages ?? []
        this.messagesError = messagesData?.error ?? null
      }
      this.messagesLoading = false
      await this.getAndSetMessageMediaForIndividual()
    },
    /**
     *
     * Function that retrieves all media from passed messages list
     * and sets the mediaLinks ref
     */
    async getAndSetMessageMediaForIndividual() {
      this.mediaLinks = await getMediaFromMessages(
        this.messages as Message[],
        this.mediaLinks
      )
    },

    /**
     *
     * Function to create a mapping of message sid to delivery status
     * e.g. { <MESSAGE_SID>: [DeliveryReceipt]  }
     */
    async mapMessagesToDeliveryStatus() {
      const mapping: { [key: string]: Message[] } = {}
      for (const message of this.messages) {
        const sid = message.sid
        if (!mapping[sid]) {
          mapping[sid] = await message.getDetailedDeliveryReceipts()
        }
      }
      this.messageDeliveryStatusMapBySid = mapping
    },
    /**
     *
     * Function to clear all refs
     */
    clearSetConvosAndMessages() {
      this.conversations = []
      this.messages = []
      this.mediaLinks = {}
      this.activeCommunication = null
      this.activeConversation = null
      this.smsCommsForActiveConversation = null
    },
    clearErrorStates() {
      this.messagesError = null
      this.conversationsError = null
      this.inboxConversationsError = null
      this.inboxMessagesError = null
    },
    clearMessageToSend() {
      this.messageToSend = ''
    },
    reset() {
      this.clearSetConvosAndMessages()
      this.clearErrorStates()
      this.clearMessageToSend()

      this.textableIndividuals = []
      this.allTextablePhoneNumberIds = []

      this.smsCommsByPhoneNumberId = null
      this.messageMapByConvoSid = {}
      this.messageDeliveryStatusMapBySid = {}

      this._conversationsClient = null
      this.conversationsClientError = null
      this.conversationsById = {}
    },
  },
})

const transformSmsCommunications = ({
  data,
  queryMetadata,
}: SmsCommunicationResponse): Partial<
  ApiState<TextCommunication, IdMap<TextCommunication>>
> => {
  return {
    data: data?.length ? toIdMap(data, 'communicationId') : null,
    queryMetadata: queryMetadata,
  }
}

export const useTextingCommsApiV2 = apiStore<
  TextCommunication,
  IdMap<TextCommunication>
>('textCommunicationsApiV2', '/api/communications', {
  transformData: (d: SmsCommunicationResponse) => transformSmsCommunications(d),
})

export const useSmsCommByPhoneNumberIdApi = apiStore<
  TextCommunication,
  IdMap<TextCommunication>
>('smsCommByPhoneNumberIdApi', '/api/communications', {
  transformData: (d: SmsCommunicationResponse) => transformSmsCommunications(d),
})

export const useTwilioTokenApi = apiStore<TokenResponse>(
  'twilioTokenApi',
  '/api/communications/texting/token',
  {}
)

// pointer subtasks ------

// take subtask data and only return subtasks pointed at open text comms
const transformPointerSubtaskData = (
  data: Subtask[]
): Partial<SubtaskState> => {
  const filteredSubtasks = data.filter(
    (subtask: Subtask) => subtask.pointerToCommId
  )
  return {
    ...idMapTransform({}, 'data', 'pointerToCommId', filteredSubtasks),
  }
}

export const useOpenTextingSubtaskApi = apiStore<Subtask, IdMap<Subtask>>(
  'openTextingSubtaskApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) =>
      transformPointerSubtaskData(d.data),
    params: {
      filter_subtask_status: [
        SubtaskStatus.OPEN_ASSIGNED,
        SubtaskStatus.OPEN_UNASSIGNED,
      ],
    },
  }
)

export const useTextingSubtaskByIdApi = apiStore<Subtask, IdMap<Subtask>>(
  'textingSubtaskByIdApi',
  '/api/subtasks',
  {
    transformData: (d: { data: Subtask[] }) =>
      transformPointerSubtaskData(d.data),
  }
)
