import { defineStore } from 'pinia'
import apiStore from '@/legacy/store/modules/apiBuilder'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  NotificationType,
  NotificationActions,
} from '@/legacy/types/notifications'
import {
  GoalReference,
  GoalReferencePayload,
} from '@/legacy/types/pathways/goals'

export const useGoalRefStore = defineStore('goalReferences', {
  state: () => ({}),
  getters: {},
  actions: {
    setError(error: string | Error, action: string) {
      useNotificationStore().setNotification({
        message: `Error ${action} goal reference(s)`,
        type: NotificationType.DANGER,
        error: `Error ${action} goal reference: ${error}`,
      })
    },

    async setSuccess(action: string) {
      useNotificationStore().setNotification({
        message: `Success ${action} goal reference(s)`,
        type: NotificationType.SUCCESS,
      })
    },

    async updateGoalRef(
      goalRefId: string,
      payload: Partial<GoalReferencePayload>
    ) {
      const data = await useGoalRefApi().partialUpdate({
        body: payload,
        ids: goalRefId,
      })
      const error = useGoalRefApi().error
      if (error) {
        this.setError(error, NotificationActions.UPDATE)
        return
      }
      if (data) {
        await this.setSuccess(NotificationActions.UPDATE)
      }
    },
  },
})

export const useGoalRefApi = apiStore<GoalReference>(
  'goalRefApi',
  '/api/goals/references',
  {
    transformData: (d: { data: GoalReference[] }) => d,
  }
)
