import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useRadiationApi } from '@/pages/PatientProfile/CarePlans/shared/store'

/**
 *
 * @param radiationId
 */
export async function deleteRadiation(radiationId: string) {
  const radiationApi = useRadiationApi()
  let deleted
  try {
    deleted = await radiationApi.delete({
      ids: [radiationId],
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to delete radiation.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully deleted radiation.',
    type: NotificationType.SUCCESS,
  })

  const radiationData = radiationApi.data
  if (radiationData && radiationData[radiationId]) {
    delete radiationData[radiationId]
  }
  return deleted
}
