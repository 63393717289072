import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { ECOGStatus } from './types'

/**
 *
 * @param ecogStatus
 */
export function addECOGStatusLevel(ecogStatus: ECOGStatus) {
  if (ecogStatus === ECOGStatus.FULLY_ACTIVE) {
    return `0 - ${startCase(camelCase(ecogStatus))}`
  } else if (ecogStatus === ECOGStatus.LIGHT_WORK_ONLY) {
    return '1 - Light House/Office Work Only'
  } else if (ecogStatus === ECOGStatus.UNABLE_TO_WORK_UP_OVER_50_PERCENT) {
    return '2 - Unable to Work: Up >50% of day'
  } else if (ecogStatus === ECOGStatus.IN_BED_OR_CHAIR_OVER_50_PERCENT) {
    return '3 - In Bed or Chair >50% of day'
  } else if (ecogStatus === ECOGStatus.FULLY_DEPENDENT) {
    return `4 - ${startCase(camelCase(ecogStatus))}`
  } else {
    return 'Unknown ECOG Status'
  }
}
