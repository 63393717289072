<template>
  <div>
    <div class="flex justify-between">
      <h3 id="comorbids" data-cy="comorbidities-header" class="my-2 pb-1">
        Comorbidities
      </h3>
      <div v-tooltip.left="'Edit Comorbidities'">
        <TMSecondaryButton
          v-if="canEditPatientComorbidities"
          name="edit-comorbidities"
          size="xs"
          icon="pencil"
          @click="isModalOpen = true"
        />
      </div>
    </div>
    <div
      v-for="comorbidity in sortedComorbidities"
      :key="comorbidity.comorbidityId"
      class="flex gap-2 py-1"
    >
      {{ getComorbidityDisplayName(comorbidity.comorbidityRefId) }}
      <div
        v-tooltip.top="
          'Sensitive diagnosis &mdash; do not disclose without explicit consent'
        "
      >
        <TIcon
          v-if="getIsSensitive(comorbidity.comorbidityRefId)"
          icon="sensitive"
          size="sm"
        />
      </div>
    </div>
    <ManageComorbidities
      :is-visible="isModalOpen"
      @close="isModalOpen = false"
      @refresh="getData"
      @mark-accuracy="markAccuracy"
      @save="saveComorbidities"
    />
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import difference from 'lodash/difference'
import map from 'lodash/map'

import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import { safeLookup } from '@/legacy/libs/lookup'

import {
  useComorbidityRefApi,
  useComorbidityRefStore,
} from '@/legacy/store/modules/comorbiditiesReferences'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import {
  useComorbiditiesApi,
  usePatientStore,
} from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { NotificationType } from '@/legacy/types/notifications'
import { Comorbidity } from '@/legacy/types/patients/comorbidities'
import ManageComorbidities from './ManageComorbidities.vue'

export default defineComponent({
  components: { TMSecondaryButton, TIcon, ManageComorbidities },
  setup() {
    const isModalOpen = ref(false)
    const { data: comorbidities } = storeToRefs(useComorbiditiesApi())
    const { data: comorbidityReferences } = storeToRefs(useComorbidityRefApi())
    const { canEditPatientComorbidities } = storeToRefs(useProfileStore())
    const route = useRoute()

    onBeforeMount(async () => {
      await usePatientStore().fetchComorbidities(
        route.params.patientId as string
      )
      if (comorbidities.value) {
        const refIdsToBeFetched = getComorbidityRefIdsToFetch()
        if (refIdsToBeFetched) {
          void useComorbidityRefStore().fetchAllComorbidityRefs(
            refIdsToBeFetched
          )
        }
      }
    })

    const getComorbidityRefIdsToFetch = () => {
      const comorbidityRefIds = map(comorbidities.value, 'comorbidityRefId')
      const storeComorbidityRefIds = Object.keys(
        comorbidityReferences.value ?? {}
      )
      return difference(comorbidityRefIds, storeComorbidityRefIds)
    }

    const getComorbidityDisplayName = (comorbidityRefId: string) =>
      safeLookup(comorbidityRefId, comorbidityReferences.value)?.description

    const getIsSensitive = (comorbidityRefId: string) =>
      safeLookup(comorbidityRefId, comorbidityReferences.value)?.isSensitive ??
      false

    const sortedComorbidities = computed(() =>
      Object.values(comorbidities.value ?? {})
        .filter((comorbidity) => !comorbidity.isInaccurate)
        .sort((a, b) =>
          (getComorbidityDisplayName(a.comorbidityRefId) ?? '').localeCompare(
            getComorbidityDisplayName(b.comorbidityRefId) ?? ''
          )
        )
    )

    const getData = async () => {
      void usePatientStore().fetchPatient(route.params.patientId as string)
      void usePatientStore().fetchComorbidities(
        route.params.patientId as string
      )
      if (comorbidities.value) {
        const refIdsToBeFetched = getComorbidityRefIdsToFetch()
        if (refIdsToBeFetched) {
          void useComorbidityRefStore().fetchAllComorbidityRefs(
            refIdsToBeFetched
          )
        }
      }
    }

    const markAccuracy = async (comorbidity: Comorbidity) => {
      try {
        await usePatientStore().updateComorbidity(
          comorbidity.comorbidityId,
          comorbidity
        )
        useNotificationStore().setNotification({
          message: 'Comorbidity updated successfully',
          type: NotificationType.SUCCESS,
        })
      } catch (e) {
        useNotificationStore().setNotification({
          message: 'Error marking accuracy on comorbidity',
          type: NotificationType.DANGER,
        })
      }
      void getData()
    }

    const saveComorbidities = async (addedComorbidityRefIds: string[]) => {
      const promises: Promise<Comorbidity>[] = []
      try {
        addedComorbidityRefIds.forEach((refId: string) => {
          promises.push(
            usePatientStore().createComorbidity({
              comorbidityRefId: refId,
              value: true,
              source: 'thymebox',
              isInaccurate: false,
              patientId: route.params.patientId as string,
            })
          )
        })
        await Promise.all(promises)
        useNotificationStore().setNotification({
          message: 'Comorbidities added successfully',
          type: NotificationType.SUCCESS,
        })
        void getData()
      } catch (e) {
        useNotificationStore().setNotification({
          message: 'Error adding comorbidities',
          type: NotificationType.DANGER,
        })
      }
    }

    return {
      comorbidities,
      sortedComorbidities,
      isModalOpen,
      canEditPatientComorbidities,
      getComorbidityDisplayName,
      getIsSensitive,
      getData,
      markAccuracy,
      saveComorbidities,
    }
  },
})
</script>
